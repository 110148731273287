import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SurchargeListItemResponse } from '../../../../../api';
import { BaseButtonSize } from '../../../../shared/components/button/base-button.component';
import { baseAdministrationSelectors, BaseCoreState } from '../../../../_store';
import { TempEmploymentFormInterface } from '../../_interfaces/base-terms-of-contract.interface';

@Component({
  selector: 'base-terms-of-contract',
  templateUrl: './base-terms-of-contract.component.html',
  styleUrls: ['./base-terms-of-contract.component.scss'],
})
export class BaseTermsOfContractComponent implements OnInit {
  @Input() cancellationPeriodForm: FormGroup<TempEmploymentFormInterface>;
  @Input() isSurchargesEditable = false;
  @Input() surchargesEditableValue = false;
  @Input() disabled = false;
  @Output() surchargesEditable = new EventEmitter<boolean>();
  @Output() updateCancellationPeriod = new EventEmitter<boolean>();
  @Output() isEdited = new EventEmitter<boolean>();
  buttonSizes = BaseButtonSize;
  isCancellationPeriodEdited = false;
  surcharges$: Observable<SurchargeListItemResponse[]>;
  initValue: string;

  submitted = false;

  constructor(private readonly store: Store<BaseCoreState>) {}

  ngOnInit(): void {
    this.initValue = this.cancellationPeriodForm.get('cancellationPeriod')?.getRawValue();

    this.surcharges$ = this.store
      .select(baseAdministrationSelectors.getSurcharges)
      .pipe(map((state) => state.data as SurchargeListItemResponse[]));
  }

  changeIsEditable(): void {
    this.surchargesEditable.emit(this.surchargesEditableValue);
  }

  changeCancPeriod(): void {
    if (this.isCancellationPeriodEdited) {
      this.submitted = true;
      this.cancellationPeriodForm.markAllAsTouched();
      if (this.cancellationPeriodForm.valid) {
        this.updateCancellationPeriod.emit(true);
        this.isCancellationPeriodEdited = false;
        this.submitted = false;
        this.initValue = this.cancellationPeriodForm.get('cancellationPeriod')?.getRawValue();
      }
    } else {
      this.isCancellationPeriodEdited = !this.isCancellationPeriodEdited;
    }
    this.isEdited.emit(this.isCancellationPeriodEdited);
  }

  cancelChangePeriod() {
    this.submitted = false;
    this.isCancellationPeriodEdited = false;
    this.isEdited.emit(this.isCancellationPeriodEdited);
    this.cancellationPeriodForm.get('cancellationPeriod')?.setValue(this.initValue);
  }
}
