import produce from 'immer';
import { PspDashboardForecastResponse, PspDashboardResponse } from '../../../api';
import { basePspDashboardActions } from '../actions';

export interface PspDashboardState {
  data: PspDashboardResponse | undefined;
  currentAssignments: {
    items: {
      data: undefined | any[];
      ok: undefined | boolean;
    };
    chartData: any;
  };
  assignmentsFilter: number;
  salesForecast: {
    data: PspDashboardForecastResponse[] | undefined;
    ok: boolean | undefined;
  };
}

export const PSP_DASHBOARD_INITIAL_STATE: PspDashboardState = {
  data: undefined,
  currentAssignments: {
    items: {
      data: undefined,
      ok: undefined,
    },
    chartData: {},
  },
  assignmentsFilter: -1,
  salesForecast: {
    data: undefined,
    ok: undefined,
  },
};

export function BasePspDashboardReducer(state: PspDashboardState = PSP_DASHBOARD_INITIAL_STATE, action: any): any {
  return produce(state, (draftState) => {
    switch (action.type) {
      case basePspDashboardActions.loadPspDashboardData.type:
        draftState.data = undefined;
        return;
      case basePspDashboardActions.setPspDashboardData.type:
        draftState.data = action.payload;
        return;
      case basePspDashboardActions.loadPspBranches.type:
        draftState.currentAssignments.items.data = action.payload;
        return;
      case basePspDashboardActions.loadPspBranchesSuccess.type:
        draftState.currentAssignments.items.ok = true;
        return;
      case basePspDashboardActions.loadPspBranchesError.type:
        draftState.currentAssignments.items.ok = false;
        return;
      case basePspDashboardActions.pspBranchesFilterChanged.type:
        draftState.assignmentsFilter = action.payload;
        return;
      case basePspDashboardActions.loadPspSalesForecast.type:
        draftState.salesForecast.ok = undefined;
        return;
      case basePspDashboardActions.setPspSalesForecast.type:
        draftState.salesForecast.data = action.payload;
        return;
      case basePspDashboardActions.successPspSalesForecast.type:
        draftState.salesForecast.ok = true;
        return;
      case basePspDashboardActions.errorPspSalesForecast.type:
        draftState.salesForecast.ok = false;
        return;
    }
  });
}
