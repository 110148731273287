import { createAction, props } from '@ngrx/store';
import {
  PspAcceptRequest,
  MetaPagination,
  OrganizationFilterListResponse,
  UpdateWorkTimesRequest,
  PspCreateAbsence,
  CustomerCreateAbsence,
  AssignmentUnplannedListItemResponse,
  CreateAssignmentDateRequest,
} from '../../../api';
import { BaseTimeRecordingItemInterface } from '../../modules/time-recordings/_interfaces/base-time-recordings.interface';
import { BaseTimeRecordExportFiltersInterface } from '../../modules/time-recordings/_interfaces/base-time-record-export.interface';

// Time records list
export const loadTimeRecordsList = createAction('[Time Records] Load time records list');
export const setTimeRecordsList = createAction(
  '[Time Records] Set time records list',
  props<{ payload: Array<BaseTimeRecordingItemInterface> }>()
);
export const setTimeRecordsFiltersAndPagination = createAction(
  '[Time Records] Set time records filters and pagination',
  props<{ payload: { pagination: MetaPagination; filters: OrganizationFilterListResponse | undefined } }>()
);
export const successTimeRecordsList = createAction('[Time Records] Success time records list');
export const errorTimeRecordsList = createAction('[Time Records] Error time records list');

export const acceptTimeRecord = createAction(
  '[Time Records] Accept time record',
  props<{ payload: { body: PspAcceptRequest } }>()
);
export const acceptTimeRecordSuccess = createAction('[Time Records] Accept time record success');
export const acceptTimeRecordError = createAction('[Time Records] Accept time record error');

export const createTimeRecord = createAction(
  '[Time Records] Create Time Record',
  props<{ payload: { assignment: number; body: CreateAssignmentDateRequest } }>()
);

export const updateTimeRecord = createAction(
  '[Time Records] Update Time Record',
  props<{ payload: { assignment: number; assignmentDate: number; body: UpdateWorkTimesRequest } }>()
);
export const successTimeRecordCRUD = createAction('[Time Records] Success Time Record CRUD');
export const errorTimeRecordCRUD = createAction('[Time Records] Error Time Record CRUD');

export const updateSelectedTimeRecords = createAction(
  '[Time Records] Update selected time records',
  props<{
    payload: {
      ids: number[];
      isSelected: boolean;
    };
  }>()
);

export const createTimeRecordAbsence = createAction(
  '[Time Records] Create Time Record Absence',
  props<{ payload: { assignment: number; body: PspCreateAbsence | CustomerCreateAbsence } }>()
);

export const successCreateTimeRecordAbsence = createAction('[Time Records] Success Create Time Record Absence');
export const errorCreateTimeRecordAbsence = createAction('[Time Records] Error Create Time Record Absence');

// Download time records
export const downloadTimeRecords = createAction(
  '[Time Records] Download Time Records',
  props<{ payload: BaseTimeRecordExportFiltersInterface }>()
);
export const successDownloadTimeRecords = createAction('[Time Records] Success Download Time Records');
export const errorDownloadTimeRecords = createAction('[Time Records]] Error Download Time Records');

// Assignment List for Time Record Creation
export const loadAssignmentListForTimeRecordCreation = createAction(
  '[Time Records] Load Assignment List for Time Record Creation'
);
export const setAssignmentListForTimeRecordCreation = createAction(
  '[Time Records] Set Assignment List for Time Record Creation',
  props<{ payload: AssignmentUnplannedListItemResponse[] }>()
);
export const successAssignmentListForTimeRecordCreation = createAction(
  '[Time Records] Success Assignment List for Time Record Creation'
);
export const errorAssignmentListForTimeRecordCreation = createAction(
  '[Time Records] Error Assignment List for Time Record Creation'
);
