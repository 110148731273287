import produce from 'immer';
import {
  BranchListItemResponse,
  Category,
  CustomerBranchListItemResponse,
  CustomerDepartmentQualificationsListResponse,
  DepartmentListItemResponse,
  DocumentCategoryResponse,
  MetaPagination,
  NotificationsListResponse,
  PspCustomerSurchargeResponse,
  QualificationPricingListItemResponse,
  QualificationShortInfoResponse,
  QualificationsListItemResponse,
  RequirementCategoryResponse,
  ShiftTimesResponse,
  UserItemResponse,
  UserProfileResponse,
  IndustrySurcharge,
} from '../../../api';
import { BaseToastAlertInfoInterface } from '../../shared/interfaces/base-toast-alert-info.interface';
import { BaseCountry } from '../../shared/services/base-country.service';
import { baseSharedActions } from '../actions';

export interface SharedState {
  countryList: {
    data: BaseCountry[];
    ok: boolean | undefined;
  };
  qualifications: {
    data: undefined | Array<QualificationShortInfoResponse>;
    ok: undefined | boolean;
  };
  qualificationCategories: {
    data: undefined | Array<Category>;
    ok: undefined | boolean;
  };
  customerDepartmentQualifications: {
    data: undefined | Array<CustomerDepartmentQualificationsListResponse>;
    ok: undefined | boolean;
  };
  branches: {
    data: undefined | Array<CustomerBranchListItemResponse | BranchListItemResponse>;
    ok: undefined | boolean;
  };
  profileData: {
    data: UserProfileResponse | undefined;
    ok: boolean | undefined;
  };
  requirements: {
    data: Array<RequirementCategoryResponse> | undefined;
    ok: boolean | undefined;
  };
  departments: {
    data: DepartmentListItemResponse[] | undefined;
    ok: boolean | undefined;
  };
  departmentUsers: {
    data: UserItemResponse[] | undefined;
    ok: boolean | undefined;
  };
  documentCategories: {
    data: DocumentCategoryResponse | undefined;
    ok: boolean | undefined;
  };
  pspPriceList: {
    data: QualificationPricingListItemResponse[];
    ok: boolean | undefined;
  };
  defaultShiftTimes: {
    data: ShiftTimesResponse | undefined;
    ok: boolean | undefined;
  };
  pspCustomerSurcharges: {
    data: PspCustomerSurchargeResponse | undefined;
    ok: boolean | undefined;
  };
  userNotifications: {
    data: NotificationsListResponse | undefined;
    pagination: MetaPagination | undefined;
    ok: boolean | undefined;
  };
  appRouter: {
    data: {
      previousUrl: string;
      currentUrl: string;
    };
  };
  customerDepartmentFavouriteQualifications: {
    data: QualificationsListItemResponse[];
    ok: boolean | undefined;
  };
  language: {
    current: string;
  };
  systemAlerts: {
    data: BaseToastAlertInfoInterface[];
  };
  organisationFilter: {
    filterActive: boolean;
  };
  selectedLocation: {
    id: number | undefined;
  };
  currentBranchUsers: {
    data: Array<UserItemResponse & { fullName: string }> | undefined;
    message: string | undefined;
    ok: boolean | undefined;
  };
  isSidebarInitialOpening: boolean;
  industrySurcharges: {
    data: IndustrySurcharge[] | undefined;
    ok: boolean | undefined;
  };
}

export const INITIAL_SHARED_STATE: SharedState = {
  countryList: {
    data: [],
    ok: undefined,
  },
  qualifications: {
    data: undefined,
    ok: undefined,
  },
  qualificationCategories: {
    data: undefined,
    ok: undefined,
  },
  customerDepartmentQualifications: {
    data: [],
    ok: undefined,
  },
  branches: {
    data: undefined,
    ok: undefined,
  },
  profileData: {
    data: undefined,
    ok: undefined,
  },
  requirements: {
    data: undefined,
    ok: undefined,
  },
  departments: {
    data: undefined,
    ok: undefined,
  },
  departmentUsers: {
    data: undefined,
    ok: undefined,
  },
  documentCategories: {
    data: undefined,
    ok: undefined,
  },
  pspPriceList: {
    data: [],
    ok: undefined,
  },
  defaultShiftTimes: {
    data: undefined,
    ok: undefined,
  },
  pspCustomerSurcharges: {
    data: undefined,
    ok: undefined,
  },
  userNotifications: {
    data: undefined,
    pagination: undefined,
    ok: undefined,
  },
  appRouter: {
    data: {
      previousUrl: '',
      currentUrl: '',
    },
  },
  customerDepartmentFavouriteQualifications: {
    data: [],
    ok: undefined,
  },
  language: {
    current: '',
  },
  systemAlerts: {
    data: [] as BaseToastAlertInfoInterface[],
  },
  organisationFilter: {
    filterActive: true,
  },
  selectedLocation: {
    id: undefined,
  },
  currentBranchUsers: {
    data: undefined,
    message: undefined,
    ok: undefined,
  },
  isSidebarInitialOpening: true,
  industrySurcharges: {
    data: [],
    ok: undefined,
  },
};

export function baseSharedReducer(state: SharedState = INITIAL_SHARED_STATE, action: any): any {
  return produce(state, (draftState) => {
    switch (action.type) {
      // Country List
      case baseSharedActions.loadCountryList.type:
        draftState.countryList.ok = undefined;
        return;

      case baseSharedActions.setCountryList.type:
        draftState.countryList.data = action.payload;
        return;

      case baseSharedActions.successCountryList.type:
        draftState.countryList.ok = true;
        return;

      case baseSharedActions.errorCountryList.type:
        draftState.countryList.ok = false;
        return;

      case baseSharedActions.getQualifications.type:
        draftState.qualifications.ok = undefined;
        return;

      case baseSharedActions.setQualifications.type:
        draftState.qualifications.data = action.payload;
        return;

      case baseSharedActions.successLoadQualifications.type:
        draftState.qualifications.ok = true;
        return;

      case baseSharedActions.errorLoadQualifications.type:
        draftState.qualifications.ok = false;
        return;

      case baseSharedActions.getQualificationCategories.type:
        draftState.qualificationCategories.ok = undefined;
        return;

      case baseSharedActions.loadQualificationCategories.type:
        draftState.qualificationCategories.data = action.payload;
        return;

      case baseSharedActions.successLoadQualificationCategories.type:
        draftState.qualificationCategories.ok = true;
        return;

      case baseSharedActions.errorLoadQualificationCategories.type:
        draftState.qualificationCategories.ok = false;
        return;

      case baseSharedActions.getBranches.type:
        draftState.branches.ok = undefined;
        return;

      case baseSharedActions.loadBranches.type:
        draftState.branches.data = action.payload;
        return;

      case baseSharedActions.successLoadBranches.type:
        draftState.branches.ok = true;
        return;

      case baseSharedActions.errorLoadBranches.type:
        draftState.branches.ok = false;
        return;

      case baseSharedActions.loadProfileData.type:
        draftState.profileData.ok = undefined;
        return;

      case baseSharedActions.setProfileData.type:
        draftState.profileData.data = action.payload;
        return;

      case baseSharedActions.successProfileData.type:
        draftState.profileData.ok = true;
        return;

      case baseSharedActions.errorProfileData.type:
        draftState.profileData.ok = false;
        return;

      case baseSharedActions.getRequirements.type:
        draftState.requirements.ok = undefined;
        return;

      case baseSharedActions.loadRequirements.type:
        draftState.requirements.data = action.payload;
        return;

      case baseSharedActions.successLoadRequirements.type:
        draftState.requirements.ok = true;
        return;

      case baseSharedActions.errorLoadRequirements.type:
        draftState.requirements.ok = false;
        return;

      case baseSharedActions.loadCustomerDepartmentList.type:
        draftState.departments.ok = undefined;
        return;

      case baseSharedActions.setCustomerDepartmentList.type:
        draftState.departments.data = action.payload;
        return;

      case baseSharedActions.successCustomerDepartmentList.type:
        draftState.departments.ok = true;
        return;

      case baseSharedActions.errorCustomerDepartmentList.type:
        draftState.departments.ok = false;
        return;

      case baseSharedActions.getCustomerDepartmentUsers.type:
        draftState.departmentUsers.ok = undefined;
        return;

      case baseSharedActions.loadCustomerDepartmentUsers.type:
        draftState.departmentUsers.data = action.payload;
        return;

      case baseSharedActions.successCustomerDepartmentUsers.type:
        draftState.departmentUsers.ok = true;
        return;

      case baseSharedActions.errorCustomerDepartmentUsers.type:
        draftState.departmentUsers.ok = false;
        return;

      case baseSharedActions.clearCustomerDepartmentUsers.type:
        draftState.departmentUsers.ok = undefined;
        draftState.departmentUsers.data = undefined;
        return;

      // Document categories
      case baseSharedActions.loadDocumentCategories.type:
        draftState.documentCategories.ok = undefined;
        return;

      case baseSharedActions.setDocumentCategories.type:
        draftState.documentCategories.data = action.payload;
        return;

      case baseSharedActions.successDocumentCategories.type:
        draftState.documentCategories.ok = true;
        return;

      case baseSharedActions.errorDocumentCategories.type:
        draftState.documentCategories.ok = false;
        return;

      // Customer Department Qualifications
      case baseSharedActions.loadCustomerDepartmentQualificationsList.type:
        draftState.customerDepartmentQualifications.ok = undefined;
        return;

      case baseSharedActions.setCustomerDepartmentQualificationsList.type:
        draftState.customerDepartmentQualifications.data = action.payload;
        return;

      case baseSharedActions.successCustomerDepartmentQualificationsList.type:
        draftState.customerDepartmentQualifications.ok = true;
        return;

      case baseSharedActions.errorCustomerDepartmentQualificationsList.type:
        draftState.customerDepartmentQualifications.ok = false;
        return;

      // PSP price list
      case baseSharedActions.loadPspPriceList.type:
        draftState.pspPriceList.ok = undefined;
        return;

      case baseSharedActions.setPspPriceList.type:
        draftState.pspPriceList.data = action.payload;
        return;

      case baseSharedActions.successPspPriceList.type:
        draftState.pspPriceList.ok = true;
        return;

      case baseSharedActions.errorPspPriceList.type:
        draftState.pspPriceList.ok = false;
        return;

      case baseSharedActions.fetchDefaultShiftTimes.type:
        draftState.defaultShiftTimes.ok = undefined;
        return;

      case baseSharedActions.loadDefaultShiftTimes.type:
        draftState.defaultShiftTimes.data = action.payload;
        return;

      case baseSharedActions.loadDefaultShiftTimesSuccess.type:
        draftState.defaultShiftTimes.ok = true;
        return;

      case baseSharedActions.loadDefaultShiftTimesError.type:
        draftState.defaultShiftTimes.ok = false;
        return;

      // PSP Customer Surcharges
      case baseSharedActions.loadPSPCustomerSurcharges.type:
        draftState.pspCustomerSurcharges.ok = undefined;
        return;

      case baseSharedActions.setPSPCustomerSurcharges.type:
        draftState.pspCustomerSurcharges.data = action.payload;
        return;

      case baseSharedActions.successPSPCustomerSurcharges.type:
        draftState.pspCustomerSurcharges.ok = true;
        return;

      case baseSharedActions.errorPSPCustomerSurcharges.type:
        draftState.pspCustomerSurcharges.ok = false;
        return;

      case baseSharedActions.setSidebarInitialOpening.type:
        draftState.isSidebarInitialOpening = action.payload;
        return;

      case baseSharedActions.clearSharedState.type:
        // clear branches state they are different for psp and customers
        draftState.branches = INITIAL_SHARED_STATE.branches;
        draftState.departments = INITIAL_SHARED_STATE.departments;
        draftState.userNotifications = INITIAL_SHARED_STATE.userNotifications;
        return;

      // User Notifications
      case baseSharedActions.loadUserNotifications.type:
        draftState.userNotifications.ok = undefined;
        return;

      case baseSharedActions.setUserNotifications.type:
        draftState.userNotifications.data = action.payload.data;
        draftState.userNotifications.pagination = action.payload.meta;
        return;

      case baseSharedActions.successUserNotifications.type:
        draftState.userNotifications.ok = true;
        return;

      case baseSharedActions.errorUserNotifications.type:
        draftState.userNotifications.ok = false;
        return;

      // App router state
      case baseSharedActions.setCurrentAndPreviousRouteUrls.type:
        draftState.appRouter.data = action.payload;
        return;

      // Customer Department Favourite Qualifications List
      // case baseSharedActions.loadCustomerDepartmentFavouriteQualificationList.type:
      //   draftState.customerDepartmentFavouriteQualifications.ok = undefined;
      //   return;
      case baseSharedActions.setCustomerDepartmentFavouriteQualificationList.type:
        draftState.customerDepartmentFavouriteQualifications.data = action.payload;
        return;
      case baseSharedActions.successCustomerDepartmentFavouriteQualificationList.type:
        draftState.customerDepartmentFavouriteQualifications.ok = true;
        return;
      case baseSharedActions.errorCustomerDepartmentFavouriteQualificationList.type:
        draftState.customerDepartmentFavouriteQualifications.ok = false;
        return;
      case baseSharedActions.setLanguageSuccess.type:
        draftState.language.current = action.language;
        return;

      case baseSharedActions.loadBranchUsers.type:
        draftState.currentBranchUsers.ok = undefined;
        return;

      case baseSharedActions.setBranchUsers.type:
        draftState.currentBranchUsers.data = action.payload.data;
        draftState.currentBranchUsers.message = action.payload.message;
        return;

      case baseSharedActions.resetBranchUsers.type:
        draftState.currentBranchUsers.data = undefined;
        draftState.currentBranchUsers.message = '';
        draftState.currentBranchUsers.ok = undefined;
        return;

      case baseSharedActions.successBranchUsers.type:
        draftState.currentBranchUsers.ok = true;
        return;

      case baseSharedActions.errorBranchUsers.type:
        draftState.currentBranchUsers.ok = false;
        return;

      // System Alerts
      case baseSharedActions.addSystemAlert.type:
        const alertToAdd: BaseToastAlertInfoInterface = {
          ...action.payload,
          id: action.payload.id ? action.payload.id : Math.floor(Math.random() * Date.now()),
          duration: action.payload.duration ? action.payload.duration : 5000,
        };
        draftState.systemAlerts.data.push(alertToAdd);
        return;
      case baseSharedActions.deleteSystemAlert.type:
        draftState.systemAlerts.data = draftState.systemAlerts.data.filter((alert) => alert.id !== action.payload);
        return;
      case baseSharedActions.setInitState.type:
        draftState.profileData = INITIAL_SHARED_STATE.profileData;
        return;
      case baseSharedActions.setOrganisationInactiveFilter.type:
        draftState.organisationFilter.filterActive = action.filterActive;
        return;
      case baseSharedActions.setSelectedLocationId.type:
        draftState.selectedLocation.id = action.locationId;
        return;
      case baseSharedActions.clearSelectedLocationId.type:
        draftState.selectedLocation.id = undefined;
        return;

      // Industry Surcharges
      case baseSharedActions.loadIndustrySurcharges.type:
        draftState.industrySurcharges.ok = undefined;
        return;
      case baseSharedActions.setIndustrySurcharges.type:
        draftState.industrySurcharges.data = action.payload;
        return;
      case baseSharedActions.successIndustrySurcharges.type:
        draftState.industrySurcharges.ok = true;
        return;
      case baseSharedActions.errorIndustrySurcharges.type:
        draftState.industrySurcharges.ok = false;
        return;
    }
  });
}
