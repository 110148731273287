import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
  selector: 'base-image-cropper',
  templateUrl: './base-image-cropper.component.html',
})
export class BaseImageCropperComponent {
  @Input() imageFile: File;
  @Input() aspectRatio = 1;
  @Input() maintainAspectRatio = true;
  @Input() roundCropper = true;
  @Output() imageCropped: EventEmitter<ImageCroppedEvent> = new EventEmitter<ImageCroppedEvent>();
}
