import {
  AfterViewInit,
  Component,
  DoCheck,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  NgbDate,
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbDatepickerI18n,
  NgbDateStruct,
} from '@ng-bootstrap/ng-bootstrap';
import { baseDatepickerValidator } from '../../validators/base-datepicker.validator';
import { AbstractFormComponent } from '../_abstract-classes/abstract-form-component';
import { BaseCustomAdapter, BaseCustomDateParserFormatter } from './base-datepicker.adapter';
import { BaseCustomDatepickerI18n } from './base-datepicker.i18n';
import { BaseDateService } from '../../services/base-date.service';

@Component({
  selector: 'base-datepicker',
  templateUrl: './base-datepicker.template.html',
  providers: [
    { provide: NgbDateAdapter, useClass: BaseCustomAdapter },
    { provide: NgbDateParserFormatter, useClass: BaseCustomDateParserFormatter },
    { provide: NgbDatepickerI18n, useClass: BaseCustomDatepickerI18n },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: BaseDatepickerComponent,
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useValue: baseDatepickerValidator,
      multi: true,
    },
  ],
})
export class BaseDatepickerComponent
  extends AbstractFormComponent
  implements ControlValueAccessor, DoCheck, AfterViewInit
{
  @ViewChild('input')
  public element: ElementRef;
  model: NgbDateStruct | null | undefined | string = null;
  @Input() isInvalid = false;
  @Input() placeholder = 'dd.mm.yyyy';
  @Input() disabled = false;
  @Input() label = '';
  @Input() unlimitedCheck = false;
  @Output() unlimitedChecked = new EventEmitter();
  @Output() selectedDate = new EventEmitter();
  @Input() tabIndex = 0;
  @Input() errorText = '';
  @Input() isRequired = false;
  @Input() isPreview = false;
  @Input() minDate: NgbDateStruct = { year: 1900, month: 1, day: 1 };
  @Input() maxDate: NgbDateStruct = { year: 2200, month: 12, day: 31 };
  @Input() placement = 'auto';
  @Input() isFilter = false;
  @Input() readonly = false;
  @Input() disabledDates: NgbDateStruct[] = [];

  constructor(private dateAdapter: NgbDateAdapter<string>, protected renderer: Renderer2) {
    super(renderer);
  }

  public ngDoCheck(): void {
    this.setNgClass();
  }

  public ngAfterViewInit(): void {
    this.setNgClass();
  }

  onChange = (date?: string | null) => {
    this.model = date;
  };

  onTouched = () => {};

  isDateDisabled = (date: NgbDate) => {
    return this.disabledDates.some((d) => d.year === date.year && d.month === date.month && d.day === date.day);
  };

  writeValue(value: any): void {
    this.model = value || null;
  }

  registerOnChange(fn: (date: string | undefined | null) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onDateSelect(value: NgbDateStruct): void {
    this.onChange(this.dateAdapter.toModel(value));
  }

  onDateChange(event: any): void {
    this.onChange(BaseDateService.toServerFormatString(event.target.value));
  }

  onUnlimitedClick(event: any): void {
    this.unlimitedChecked.emit(event.target.checked);
  }
}
