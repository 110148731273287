<div
  class="k-switch"
  [ngClass]="{ 'position-right': positionRight }"
>
  <input
    type="checkbox"
    [class]="color"
    [ngClass]="{ reversed: reverseValue }"
    [tabindex]="tabindex"
    [(ngModel)]="checked"
    (click)="isConfirmModal ? handleSwitchClick($event) : null"
    [disabled]="isDisabled"
    [ngbTooltip]="tooltip | translate"
    #kSwitch
  />
  <div><div></div></div>
  <label
    [ngClass]="checked ? 'text-primary' + ' ' + labelClasses : 'text-dark-gray' + ' ' + labelClasses"
    (click)="kSwitch.click()"
    >{{ label | translate }}</label
  >
</div>
