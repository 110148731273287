import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { filter, map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { baseInquiryActions, baseInquirySelectors, BaseCoreState } from '../../../_store';

@Injectable()
export class BaseInquiryCustomerGuard {
  constructor(private readonly store: Store<BaseCoreState>) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    this.store.dispatch(baseInquiryActions.loadCustomerInquiry({ payload: route.params.inquiry }));

    return this.store.select(baseInquirySelectors.getCustomerInquiry).pipe(
      filter((response) => response.ok !== undefined),
      map((response) => !!response.ok)
    );
  }
}
