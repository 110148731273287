import { createAction, props } from '@ngrx/store';
import { SafeUrl } from '@angular/platform-browser';
import {
  CustomerAssignmentFilterListsResponse,
  CustomerDepartmentResponse,
  CustomerInquiryListItemResponse,
  InquiryCreateRequest,
  InquiryOfferAcceptRequest,
  InquiryOfferCreateRequest,
  InquiryOfferDeclineRequest,
  InquiryOfferPositionRequest,
  InquiryOfferUpdateRequest,
  InquiryResponse,
  MetaPagination,
  OffersCountResponse,
  PspBranchShortInfoResponse,
  QualificationPricingResponse,
  RevocationRequest,
  ShiftTimeResponse,
  SurchargeListItemResponse,
  UserShortInfoResponse,
} from '../../../api';
import { BaseInquiryShiftsWithFlex, BaseWeekDays } from '../../modules/inquiry/_enums/base-inquiry.enum';
import {
  BaseInquiryOfferOrderDirection,
  BaseInquiryOfferOrderKey,
  BaseInquiryOfferStatus,
  BaseStaffRequestFormInterface,
  NewInquiryFormInterface,
} from '../../modules/inquiry/_interfaces/base-inquiry.interface';
import { CustomerInquiryOrderKeyEnum } from '../../shared/enums/customer-inquiry-order-key.enum';
import { BaseOrderDirectionEnum } from '../../shared/enums/base-order-direction.enum';
import { PspInquryOrderKeyEnum } from '../../shared/enums/psp-inqury-order-key.enum';
import {
  BaseInquiryCandidateBubbleSelectPayload,
  BaseInquiryCandidateInterface,
} from '../../modules/inquiry/_interfaces/base-inquiry-candidate.interface';
import { BasePspInquiryOfferInterface } from '../../modules/inquiry/_interfaces/base-psp-inquiry-offer.interface';
import {
  BaseCustomerInquiryOfferInterface,
  BaseCustomerOfferBubbleSelectPayload,
  BaseCustomerOfferListFiltersPayload,
} from '../../modules/inquiry/_interfaces/base-cusomer-inquiry-offer.interface';
import {
  ShiftPlanCalendarDateInterface,
  ShiftPlanCalendarRangeInterface,
  SimilarInquiriesPayloadInterface,
  SimilarInquiryMappedInterface,
} from '../../modules/inquiry/_interfaces/base-shift-plan.interface';
import { FormGroup } from '@angular/forms';
import { FormRawValue } from '../../modules/inquiry/_interfaces/base-form.interface';
import { BaseInquiryOffersInterface } from '../../modules/inquiry/_interfaces/base-inquiry-offers.interface';

export const getInquiries = createAction(
  '[Inquiry] Get inquiries',
  props<{
    payload: {
      page?: number;
      size?: number;
      'statuses[]'?: string[];
      'mainQualifications[]'?: number[];
      'customerDepartments[]'?: number[];
      includeDates?: boolean;
      current?: boolean;
      search?: string;
      createdBy?: number;
      orderKey?: CustomerInquiryOrderKeyEnum;
      orderDirection?: BaseOrderDirectionEnum;
    };
  }>()
);
export const loadInquiries = createAction('[Inquiry] Load inquiries', props<{ payload: any }>());
export const successLoadInquiries = createAction('[Inquiry] Success load inquiries');
export const errorLoadInquiries = createAction('[Inquiry] Error load inquiries');

export const getPSPInquiries = createAction(
  '[Inquiry] Get psp inquiries',
  props<{
    payload: {
      page?: number;
      size?: number;
      status?: string;
      'customers[]'?: number[];
      'customerDepartments[]'?: number[];
      PSPBranch?: number;
      'mainQualifications[]'?: number[];
      orderKey?: PspInquryOrderKeyEnum;
      orderDirection?: BaseOrderDirectionEnum;
    };
  }>()
);

export const createInquiry = createAction('[Inquiry] Create inquiry', props<{ payload: InquiryCreateRequest }>());
export const createAndPublishInquiry = createAction(
  '[Inquiry] Create and publish inquiry',
  props<{ payload: InquiryCreateRequest }>()
);
export const successInquiryCRUD = createAction('[Inquiry] Success create inquiry ');
export const errorInquiryCRUD = createAction('[Inquiry] Error create inquiry');
export const clearInquiryCRUDStatus = createAction('[Inquiry] Clear inquiry CRUD status');

export const clearInquiryCustomerData = createAction('[Inquiry] clear inquiry customer data');

export const updateInquiryProps = createAction('[Inquiry] Update inquiry prop', props<{ payload: any }>());
export const resetInquiryProps = createAction('[Inquiry] Reset inquiry prop');

export const fetchDepartment = createAction('[Inquiry] Fetch department', props<{ payload: number }>());
export const loadDepartment = createAction(
  '[Inquiry] Load department',
  props<{ payload: CustomerDepartmentResponse }>()
);
export const loadDepartmentSuccess = createAction('[Inquiry] Load department success');
export const loadDepartmentError = createAction('[Inquiry] Load department error');

// PSP Inquiry
export const loadPSPInquiry = createAction('[Inquiry] Load PSP inquiry', props<{ payload: number }>());
export const setPSPInquiry = createAction(
  '[Inquiry] Set PSP inquiry',
  props<{ payload: InquiryResponse & { weekdays: Record<BaseWeekDays, boolean> } }>()
);
export const successPSPInquiry = createAction('[Inquiry] Success PSP inquiry');
export const errorPSPInquiry = createAction('[Inquiry] Error PSP inquiry');

// Responsible User list
export const loadResponsibleUserList = createAction(
  '[Inquiry] Load Responsible User list',
  props<{ payload: number }>()
);
export const setResponsibleUserList = createAction(
  '[Inquiry] Set Responsible User list',
  props<{ payload: (UserShortInfoResponse & { fullName: string })[] }>()
);
export const successResponsibleUserList = createAction('[Inquiry] Success Responsible User list');
export const errorResponsibleUserList = createAction('[Inquiry] Error Responsible User list');

// PSP Customer Qualification price
export const loadPSPCustomerQualificationPrice = createAction(
  '[Inquiry] Load PSP Customer Qualification price',
  props<{ payload: { customer: number; qualification: number; locationId: number } }>()
);
export const setPSPCustomerQualificationPrice = createAction(
  '[Inquiry] Set PSP Customer Qualification price',
  props<{ payload: QualificationPricingResponse }>()
);
export const successPSPCustomerQualificationPrice = createAction('[Inquiry] Success PSP Customer Qualification price');
export const errorPSPCustomerQualificationPrice = createAction('[Inquiry] Error PSP Customer Qualification price');

// Surcharges for create offer popup
export const setSurchargesForOfferCreation = createAction(
  '[Inquiry] Set Surcharges for Create Inquiry Offer',
  props<{ payload: SurchargeListItemResponse[] }>()
);

// Create Inquiry Offer
export const createInquiryOffer = createAction(
  '[Inquiry] Create Inquiry Offer',
  props<{ payload: { inquiry: number; body: InquiryOfferCreateRequest; isUnlimited?: boolean } }>()
);

// Update Inquiry Offer
export const updateInquiryOffer = createAction(
  '[Inquiry] Update Inquiry Offer',
  props<{
    payload: { offer: number; inquiry: number; body: InquiryOfferUpdateRequest; filters?: Record<string, any> };
  }>()
);

export const loadCustomerInquiry = createAction('[Inquiry] Load customer inquiry', props<{ payload: number }>());
export const setCustomerInquiry = createAction('[Inquiry] Set customer inquiry', props<{ payload: InquiryResponse }>());
export const successCustomerInquiry = createAction('[Inquiry] Success customer inquiry');
export const errorCustomerInquiry = createAction('[Inquiry] Error customer inquiry');

export const clearOfferManagementPage = createAction('[Inquiry] clear offer management page');
export const calculateInquiryOfferAmount = createAction(
  '[Inquiry] Calculate offer amount',
  props<{ payload: { inquiry: number; offer: number; body: InquiryOfferAcceptRequest } }>()
);
export const acceptOffer = createAction(
  '[Inquiry] Accept offer',
  props<{
    payload: {
      inquiry: number;
      offer: number;
      offerPositions: InquiryOfferPositionRequest[];
      filters?: BaseCustomerOfferListFiltersPayload;
    };
  }>()
);
export const acceptOfferSuccessful = createAction('[Inquiry] Accept offer successful');

// Download PspCustomer Contract Template File
export const downloadPspCustomerContractTemplateFile = createAction(
  '[Inquiry] Download PspCustomer Contract Template File',
  props<{ payload: number }>()
);
export const downloadPspCustomerContractTemplateFileSuccess = createAction(
  '[Inquiry] Download PspCustomer Contract Template File success'
);
export const downloadPspCustomerContractTemplateFileError = createAction(
  '[Inquiry] Download PspCustomer Contract Template File error'
);

// Download Customer contract document
export const downloadCustomerContractDocument = createAction('[Inquiry] Download Customer contract document file');
export const downloadCustomerContractDocumentSuccess = createAction(
  '[Inquiry] Download Customer contract document file success'
);
export const downloadCustomerContractDocumentError = createAction(
  '[Inquiry] Download Customer contract document file error'
);

// Download offer document
export const downloadInquiryOfferDocument = createAction(
  '[Inquiry] Download offer document file',
  props<{
    payload: { inquiry: number; offer: number; documentUuid: string; documentType: string; openInModal: boolean };
  }>()
);
export const downloadInquiryOfferDocumentSuccess = createAction('[Inquiry] Download offer document file success');
export const downloadInquiryOfferDocumentError = createAction('[Inquiry] Download offer document file error');
export const clearDownloadInquiryOfferDocumentFile = createAction('[Inquiry] Clear download inquiry offer file');

export const setDownloadInquiryOfferDocumentFile = createAction(
  '[Inquiry] Set Download offer document file',
  props<{ payload: { document: Blob | SafeUrl } }>()
);

// Decline offer customer
export const declineOfferCustomer = createAction(
  '[Inquiry] Decline offer customer',
  props<{
    payload: {
      offer: number;
      inquiry: number;
      body: InquiryOfferDeclineRequest;
      filters?: BaseCustomerOfferListFiltersPayload;
    };
  }>()
);
export const declineOfferCustomerSuccess = createAction('[Inquiry] Offer declined successfully');
export const declineOfferCustomerError = createAction('[Inquiry] Offer not declined because of error');

// Revoke/Remove offer
export const revokeOffer = createAction(
  '[Inquiry] Revoke offer',
  props<{
    payload: {
      inquiry: number;
      offer: number;
      body: RevocationRequest;
      isOffersAndBookings?: boolean;
      filters?: Record<string, any>;
    };
  }>()
);
export const revokeOfferSuccess = createAction('[Inquiry] Revoke offer successfully');
export const revokeOfferError = createAction('[Inquiry] Revoke offer error');

// Update inquiry status
export const updateInquiryStatus = createAction(
  '[Inquiry] Update inquiry status',
  props<{
    payload: {
      inquiry: number;
      status: 1 | 2 | 3;
      route?: string;
      closedNote?: string;
      loadUnpublishedInquiries?: boolean;
    };
  }>()
);
export const updateInquiryStatusSuccess = createAction('[Inquiry] Update inquiry status success');
export const updateInquiryStatusError = createAction('[Inquiry] Update inquiry status error');

// Update inquiry
export const updateCustomerInquiry = createAction(
  '[Inquiry] Update customer inquiry',
  props<{ payload: { inquiry: string; body: InquiryCreateRequest; route?: string } }>()
);
export const updateCustomerInquirySuccess = createAction('[Inquiry] Update customer inquiry success');
export const updateCustomerInquiryError = createAction('[Inquiry] Update customer inquiry error');

// Inquiry branches
export const loadInquiryBranches = createAction(
  '[Inquiry] Load inquiry branches',
  props<{ payload: { inquiry: number } }>()
);
export const setInquiryBranches = createAction(
  '[Inquiry] Set inquiry branches',
  props<{ payload: PspBranchShortInfoResponse[] }>()
);
export const successLoadInquiryBranches = createAction('[Inquiry] Success load inquiry branches');
export const errorLoadInquiryBranches = createAction('[Inquiry] Error load inquiry branches');

// Inquiry Candidates List
export const loadInquiryCandidatesList = createAction(
  '[Inquiry] Load inquiry candidates list',
  props<{
    payload: {
      inquiry: number;
      isUnlimited?: boolean;
      toLimitLeft?: number;
      size?: number;
      page?: number;
      orderKey?: any;
      orderDirection?: any;
      isIncludeSorting?: boolean;
    };
  }>()
);
export const setInquiryCandidatesList = createAction(
  '[Inquiry] Set inquiry candidates list',
  props<{ payload: BaseInquiryCandidateInterface[] }>()
);

export const addInquiryCandidatesPage = createAction(
  '[Inquiry] Add inquiry candidates page',
  props<{
    payload: {
      data: BaseInquiryCandidateInterface[];
      pagination: MetaPagination;
    };
  }>()
);

export const setInitialInquiryCandidatesList = createAction(
  '[Inquiry] Set initial inquiry candidates list',
  props<{
    payload: {
      data: BaseInquiryCandidateInterface[];
      pagination: MetaPagination;
    };
  }>()
);

export const defineVisibleInquiryCandidatesItems = createAction('[Inquiry] Defined visible inquiry candidates items');
export const resetInquiryCandidatesItems = createAction('[Inquiry] Reset inquiry candidates items');
export const setInquiryCandidatesListSuccess = createAction('[Inquiry] Set inquiry candidates list success');
export const setInquiryCandidatesListError = createAction('[Inquiry] Set inquiry candidates list error');

// Candidate selection
export const selectCandidate = createAction(
  '[Inquiry] Select candidate for inquiry',
  props<{ payload: { candidateId: number } }>()
);

export const selectCandidateBubble = createAction(
  '[Inquiry] Select candidate bubble',
  props<{ payload: BaseInquiryCandidateBubbleSelectPayload }>()
);

export const candidateFlexShiftsSelection = createAction(
  '[Inquiry] Select/deselect all flex shifts for a candidate',
  props<{ payload: { candidateId: number } }>()
);

export const candidateFixedShiftsSelection = createAction(
  '[Inquiry] Select/deselect all fixed shifts by shift type for a candidate',
  props<{ payload: { candidateId: number; shiftType: BaseInquiryShiftsWithFlex } }>()
);

// Shift Plan
export const shiftPlanCreateData = createAction(
  '[Inquiry] Shift plan inquiry create data',
  props<{
    payload: {
      calendar: FormRawValue<FormGroup<BaseStaffRequestFormInterface>>;
      inquiries: SimilarInquiriesPayloadInterface;
    };
  }>()
);

export const openInquiryInEditMode = createAction(
  '[Inquiry] Open inquiry in edit mode',
  props<{
    payload: {
      inquiry: InquiryResponse;
      similarInquiriesPayload: SimilarInquiriesPayloadInterface;
    };
  }>()
);

export const shiftPlanUpdateData = createAction(
  '[Inquiry] Shift plan update data',
  props<{
    payload: {
      data: FormRawValue<FormGroup<NewInquiryFormInterface>>;
      rangeId?: string;
    };
  }>()
);

export const setInquiryShiftViewRangeList = createAction(
  '[Inquiry] Set inquiry shift view range list',
  props<{
    payload: ShiftPlanCalendarRangeInterface[];
  }>()
);

export const setShiftPlanInquiryList = createAction(
  '[Inquiry] Set shift plan inquiry list',
  props<{
    payload: {
      data: ShiftPlanCalendarDateInterface[];
      shiftTimes: Array<ShiftTimeResponse>;
    };
  }>()
);

export const updateShiftPlanInquiryList = createAction(
  '[Inquiry] Update shift plan inquiry list',
  props<{
    payload: ShiftPlanCalendarDateInterface[];
  }>()
);

export const removeShiftPlanRange = createAction(
  '[Inquiry] Remove shift plan range',
  props<{
    payload: string;
  }>()
);

export const resetShiftPlanData = createAction('[Inquiry] Reset shift plan data');

// Similar inquiries
export const loadSimilarInquiriesList = createAction(
  '[Inquiry] Load similar inquiries list',
  props<{
    payload: {
      calendar: ShiftPlanCalendarDateInterface[];
      inquiries: SimilarInquiriesPayloadInterface;
    };
  }>()
);
export const setSimilarInquiriesList = createAction(
  '[Inquiry] Set similar inquiries list',
  props<{ payload: CustomerInquiryListItemResponse[] }>()
);
export const successSetSimilarInquiriesList = createAction('[Inquiry] Success set similar inquiries list');
export const errorSetSimilarInquiriesList = createAction('[Inquiry] Error set similar inquiries list');

export const setMappedSimilarInquiries = createAction(
  '[Inquiry] Set mapped similar inquiries',
  props<{ payload: SimilarInquiryMappedInterface[] }>()
);

// PSP Inquiry Offers
export const loadPspInquiryOffersList = createAction(
  '[Inquiry] Load inquiry psp offers list',
  props<{
    payload: BaseInquiryOffersInterface;
  }>()
);
export const setPspInquiryOffersList = createAction(
  '[Inquiry] Set inquiry psp offers list',
  props<{ payload: BasePspInquiryOfferInterface[] }>()
);
export const addPspInquiryOffersListNextPage = createAction(
  '[Inquiry] Add inquiry psp offers next page items',
  props<{ payload: BasePspInquiryOfferInterface[] }>()
);

export const setPspInquiryOffersListPagination = createAction(
  '[Inquiry] Set inquiry psp offers list pagination',
  props<{ payload: MetaPagination }>()
);

export const setPspInquiryOffersCount = createAction(
  '[Inquiry] Set inquiry psp offers count',
  props<{ payload: OffersCountResponse }>()
);

export const setPspInquiryOffersListSuccess = createAction('[Inquiry] Set inquiry psp offers list success');
export const setPspInquiryOffersListError = createAction('[Inquiry] Set inquiry psp offers list error');

// PSP Inquiry Offer selection
export const selectPspInquiryOffer = createAction(
  '[Inquiry] Select PSP inquiry offer',
  props<{ payload: { offerId: number } }>()
);

// Customer Inquiry Offers
export const loadCustomerInquiryOffersList = createAction(
  '[Inquiry] Load inquiry customer offers list',
  props<{
    payload: {
      inquiry: number;
      search?: string;
      profileAverageRating?: number;
      status?: BaseInquiryOfferStatus;
      mainQualifications?: number[];
      pspBranches?: number[];
      experience?: boolean;
      orderKey?: BaseInquiryOfferOrderKey;
      orderDirection?: BaseInquiryOfferOrderDirection;
      loadNextPage?: boolean;
      page?: number;
      size?: number;
    };
  }>()
);
export const setCustomerInquiryOffersList = createAction(
  '[Inquiry] Set inquiry customer offers list',
  props<{ payload: BaseCustomerInquiryOfferInterface[] }>()
);
export const addCustomerInquiryOffersListNextPage = createAction(
  '[Inquiry] Add inquiry customer offers list next page',
  props<{ payload: BaseCustomerInquiryOfferInterface[] }>()
);
export const setCustomerInquiryOffersFiltersAndPagination = createAction(
  '[Inquiry] Set inquiry customer offers filters and pagination',
  props<{ payload: { pagination: MetaPagination; filters: CustomerAssignmentFilterListsResponse } }>()
);

export const setCustomerInquiryOffersCount = createAction(
  '[Inquiry] Set inquiry customer offers count',
  props<{ payload: OffersCountResponse }>()
);

export const setCustomerInquiryOffersListSuccess = createAction('[Inquiry] Set inquiry customer offers list success');
export const setCustomerInquiryOffersListError = createAction('[Inquiry] Set inquiry customer offers list error');

export const selectCustomerInquiryOffer = createAction(
  '[Inquiry] Select Customer inquiry offer',
  props<{ payload: { offerId: number } }>()
);

export const selectCustomerOfferBubble = createAction(
  '[Inquiry] Select customer offer bubble',
  props<{ payload: BaseCustomerOfferBubbleSelectPayload }>()
);

export const customerOfferFlexShiftsSelection = createAction(
  '[Inquiry] Select/deselect all flex shifts for customer offer',
  props<{ payload: { offerId: number } }>()
);

export const customerOfferFixedShiftsSelection = createAction(
  '[Inquiry] Select/deselect all fixed shifts by shift type for a customer offer',
  props<{ payload: { offerId: number; shiftType: BaseInquiryShiftsWithFlex } }>()
);

export const customerOfferAllShiftsSelection = createAction(
  '[Inquiry] Select/deselect all flex and fixe shifts for customer offer',
  props<{ payload: { offerId: number; isShiftsDeselectionMode: boolean } }>()
);

export const customerInquiryOfferPreselection = createAction(
  '[Inquiry] Preselect/unselect customer inquiry offer',
  props<{ payload: { inquiry: number; offer: number; preselect: boolean } }>()
);

export const successCustomerInquiryOfferPreselection = createAction(
  '[Inquiry] Preselect/unselect customer inquiry offer success'
);

export const errorCustomerInquiryOfferPreselection = createAction(
  '[Inquiry] Preselect/unselect customer inquiry offer error'
);
