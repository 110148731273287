<hr />
<div class="d-flex gap-3">
  <base-horizontal-nav
    [tabs]="tabs"
    [(current)]="activeTab"
    [tabSize]="buttonSize.xs"
    [showBadge]="true"
    (currentChange)="selectMenuItem($event)"
  ></base-horizontal-nav>
</div>

<div class="listview container-fluid">
  <div
    class="listview-header"
    *ngIf="!!absences.length"
  >
    <div class="row">
      <div
        [ngClass]="activeTab.id === absenceTypes.all ? 'col-4' : 'col-7'"
        baseTruncate
      >
        {{ 'inquiry.period' | translate }}
      </div>
      <div
        class="col-3"
        *ngIf="activeTab.id === absenceTypes.all"
        baseTruncate
      >
        {{ 'type' | translate }}
      </div>
      <div
        class="col-5"
        baseTruncate
      >
        {{ 'comment' | translate }}
      </div>
    </div>
  </div>

  <div
    class="listview-item bg-lightest"
    *ngFor="let absence of absences"
  >
    <div class="row">
      <div [ngClass]="activeTab.id === absenceTypes.all ? 'col-4' : 'col-7'">
        <span
          baseTruncate
          class="fw-semi-bold"
        >
          {{ absence.startDate | baseFormatDate }} - {{ absence.endDate | baseFormatDate }}
        </span>
      </div>
      <div
        class="col-3"
        *ngIf="activeTab.id === absenceTypes.all"
      >
        <span
          baseTruncate
          class="fw-semi-bold"
        >
          {{ 'work.' + absence.type | translate }}
        </span>
      </div>
      <div class="col-3">
        <span
          baseTruncate
          class="fw-semi-bold"
        >
          {{ absence.note }}
        </span>
      </div>
      <div class="col-2">
        <div class="d-flex justify-content-end gap-2">
          <base-button
            color="dark"
            icon="fa-solid fa-pen"
            [iconSize]="buttonSize.md"
            stateClass="pe-1"
            tooltip="action.edit"
            (btnClick)="openEditModal(absence)"
          ></base-button>
          <base-button
            color="dark"
            icon="fa-solid fa-trash"
            [iconSize]="buttonSize.md"
            stateClass="pe-1"
            tooltip="action.delete"
            (btnClick)="deleteAbsence(absence)"
          ></base-button>
        </div>
      </div>
    </div>
  </div>

  <div
    class="pt-5"
    *ngIf="!absences.length"
  >
    <base-empty-list-row
      message="work.absences.empty"
      [hideBackgroundImage]="true"
      [backgroundSecondary]="true"
    ></base-empty-list-row>
  </div>
</div>
<div class="pagination">
  <base-pagination
    [page]="pageNumber"
    [size]="size"
    [pageSize]="pageSize"
    [noFilters]="true"
    (pageChange)="changePage($event)"
  ></base-pagination>
</div>

<ng-template #editAbsenceModal>
  <growth-add-absence
    (cancel)="closeModal()"
    [profile]="profile"
    [absencePagination]="{ page: this.pageNumber, size: this.pageSize }"
    [absenceToEdit]="absenceToEdit"
    [inquiryId]="inquiryId"
    [reloadOffersAndCandidates]="reloadOffersAndCandidates"
    [activeTabId]="activeTab.id"
  ></growth-add-absence>
</ng-template>
