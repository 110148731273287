import { ControlValueAccessor, NgModel, ValidationErrors, Validator } from '@angular/forms';
import { NgSelectConfig } from '@ng-select/ng-select';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';

type SearchFilterType = { [key: string]: string };

export class DropdownFoundation implements ControlValueAccessor, Validator {
  compareFn(c1: any, c2: any): boolean {
    return c1 && c2
      ? c1.id && c2.id
        ? c1.id === c2.id
        : c1.id && c2.value
        ? c1.id === +c2.value
        : c1.id
        ? c1.id === c2
        : c1.party
        ? c1.party === (c2?.party || c2)
        : c1.value === c2.value
      : c1 === c2;
  }

  _onChange = (_: any) => {};
  _onChangeValidation = () => {};
  _onTouch = () => {};
  _value: NgModel;
  _disabled: boolean;

  constructor(protected ngSelectConfig: NgSelectConfig, protected translate: TranslateService) {
    // settings for ng-select
    this.ngSelectConfig.loadingText = this.translate.instant('loading');
    this.ngSelectConfig.typeToSearchText = this.translate.instant('typeToSearch');
    this.ngSelectConfig.notFoundText = this.translate.instant('noEntriesFound');
  }

  trackByFn(index: number, item: any): any {
    return item?.id || item?.party || index;
  }

  registerOnChange(fn: (value: any) => any) {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void) {
    this._onTouch = fn;
  }

  writeValue(val: any) {
    this._value = val === '' ? null : val;
    if (this._value) {
      this.change(this._value);
    }
  }

  setDisabledState(isDisabled: boolean) {
    this._disabled = isDisabled;
  }

  change(value: any) {
    this._onChange(value);
    this._onTouch();
    this._onChangeValidation();
  }

  registerOnValidatorChange(fn: () => void) {
    this._onChangeValidation = fn;
  }

  validate(): ValidationErrors | null {
    return this._value?.errors;
  }

  /**
   * Service that return filtered list of items.
   * @param items List of items
   * @param searchString Search term
   * @param searchProperty Search property
   * @protected
   */
  protected searchService(items: any[], searchString: string, searchProperty: string) {
    return of(items).pipe(
      map((list: any[]) => {
        if (!list?.length) {
          return [];
        }
        return this.filter(list, searchString, searchProperty);
      })
    );
  }

  /**
   * Filters a list of items based on a search string and a specified property.
   *
   * @param {any[]} list - The array of items to be filtered.
   * @param {any} searchString - The search string to match against.
   * @param {string} searchProperty - The property name to be used for filtering.
   * @returns {any[]} The filtered items matching the search criteria.
   */
  protected filter(list: any[], searchString: any, searchProperty: string) {
    return list.filter((x: SearchFilterType | string) => {
      const dataSource = this.getSearchFilterDataSource(searchProperty, x);
      if (typeof dataSource === 'object') {
        return false;
      }
      return dataSource?.toLowerCase().includes(searchString.toLowerCase());
    });
  }

  /**
   * Prepares the data for the type of filtering required.
   * The way in which this data is prepared depends on the type of property "searchProperty".
   * With a comma-separated string, several properties can be searched in the specified order.
   * A space is inserted after each property.
   * @param searchProperty Search property
   * @param dataSource Object data source
   * @protected
   */
  protected getSearchFilterDataSource(searchProperty: string, dataSource: SearchFilterType | string): string {
    if (searchProperty?.includes(',')) {
      const searchProperties = searchProperty.split(',');
      let concatSearchString = '';
      searchProperties.forEach((val, index) => {
        concatSearchString += this.getNestedProperty(dataSource as SearchFilterType, val);
        if (index + 1 !== searchProperties.length) {
          concatSearchString += ' ';
        }
      });
      return concatSearchString;
    } else if (dataSource.hasOwnProperty(searchProperty)) {
      return (dataSource as SearchFilterType)[searchProperty].toString();
    } else {
      return dataSource.toString();
    }
  }

  /**
   * This method is used to retrieve the value of the property from the data source,
   * whether it's a top-level property or a nested one.
   *
   * @param searchProperty Search property
   * @param dataSource Object data source
   * @protected
   */
  protected getNestedProperty(dataSource: any, searchProperty: string): string {
    return searchProperty.split('.').reduce((obj, prop) => (obj ? obj[prop] : null), dataSource);
  }

  /**
   * Fetches and filters additional items based on search criteria and buffering.
   *
   * @param {any[]} items - The array of items to be filtered.
   * @param {any[]} itemsBuffer - The buffer array containing previously fetched items.
   * @param {number} itemsBufferSize - The desired size of the buffer for new items.
   * @param {string} searchProperty - The property name to be used for filtering.
   * @param {string} [searchValue=''] - The value to search for (default is an empty string).
   * @returns {any[]} The filtered and buffered items to be added to the display.
   */
  protected fetchMore(
    items: any[],
    itemsBuffer: any[],
    itemsBufferSize: number,
    searchProperty: string,
    searchValue: string = ''
  ) {
    const itemsBufferLength = itemsBuffer.length;
    return items
      .filter((x: SearchFilterType | string) => {
        const dataSource = this.getSearchFilterDataSource(searchProperty, x);
        if (typeof dataSource === 'object') {
          return false;
        }
        return dataSource?.toLowerCase().includes(searchValue.toLowerCase());
      })
      .slice(itemsBufferLength, itemsBufferSize + itemsBufferLength);
  }
}
