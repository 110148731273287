import { Component, Input, OnInit } from '@angular/core';
import { PermissionItemResponse } from '../../../../../../api';
import { BaseRolesService } from '../../../../../core/services/base-roles.service';

@Component({
  selector: 'base-permission-checkbox-template',
  templateUrl: './base-permission-checkbox-template.component.html',
})
export class BasePermissionCheckboxTemplateComponent implements OnInit {
  @Input() permission: PermissionItemResponse & { isSelected?: boolean };

  isPsp: boolean;
  permissionLabelKey: string;
  permissionDescriptionKey: string;

  constructor(private rolesService: BaseRolesService) {}

  ngOnInit() {
    this.isPsp = this.rolesService.isPsp();
    this.permissionLabelKey = `permission.${this.isPsp ? 'psp' : 'customer'}.label.${this.permission.key}`;
    this.permissionDescriptionKey = `permission.${this.isPsp ? 'psp' : 'customer'}.description.${this.permission.key}`;
  }
}
