import { Component, Input } from '@angular/core';

@Component({
  selector: 'base-delete-document-confirmation-template',
  templateUrl: './base-delete-document-confirmation-template.component.html',
})
export class BaseDeleteDocumentConfirmationTemplateComponent {
  @Input() documentName: string;
  @Input() isHintVisible = false;
}
