import { AbstractControl } from '@angular/forms';

export const baseNumberTimeValidator = () => {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    const numberTimeSchema = new RegExp('^[0-9]{1,3}h?$');
    if (control.value && !numberTimeSchema.test(control.value)) {
      return { numberTime: true };
    }
    return null;
  };
};
