<nav class="vertical-nav">
  <ul class="nav">
    <li
      *ngFor="let tab of tabs"
      [ngClass]="{ 'arrow-nav-item': withArrow }"
      class="nav-item"
      (click)="onTabSelect(tab)"
    >
      <a
        [routerLink]="tab.url"
        [queryParams]="tab.queryParams"
        [replaceUrl]="true"
        routerLinkActive="active"
        [routerLinkActiveOptions]="routerLinkActiveOptions"
        class="nav-link d-flex align-items-center"
      >
        <i
          *ngIf="tab.icon"
          [ngClass]="[tab.icon, isResponsive ? 'me-xxxl-2' : 'me-3']"
        ></i>
        <span [ngClass]="{ 'd-none d-xxxl-block': isResponsive }">{{
          isUppercaseTitle ? (tab.title | translate | uppercase) : (tab.title | translate)
        }}</span>
        <span
          *ngIf="tab.badge"
          class="nav-item-badge ms-auto"
          >{{ tab.badge }}</span
        >
      </a>
    </li>
  </ul>
</nav>
