import { AbstractControl } from '@angular/forms';

export const baseWebsiteValidator = () => {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    const urlRegex = new RegExp(
      /^(?:https?:\/\/)?(?:www\.)?(?!www\.)([a-zA-Z0-9][a-zA-Z0-9-]+\.[a-zA-Z]{2,})(?:\/\S*)?$/
    );
    if (control.value && !urlRegex.test(control.value)) {
      return { websiteInvalid: true };
    }
    return null;
  };
};
