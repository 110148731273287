import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import {
  CustomerBusinessInviteRequest,
  CustomerMedicalInviteRequest,
  DepartmentListItemResponse,
  GeneralApiResponse,
  TerminalCustomerLocationListItemResponse,
  LocationListItemResponse,
  LocationResponse,
  PspInviteRequest,
  UserUpdateRequest,
} from '../../../api';
import { BaseRolesService } from '../../core/services/base-roles.service';
import { BaseAdministrationService } from '../../modules/administration/base-administration.service';
import { BaseToastAlertClasses } from '../../shared/enums/base-toast-alert-classes.enum';
import { BaseTokenInterface } from '../../shared/interfaces/base-token-service.interface';
import { BaseSharedService } from '../../shared/services/base-shared.service';
import { baseAdministrationActions, baseAuthActions, baseSharedActions, baseUserActions } from '../actions';
import { BaseCoreState } from '../reducers';
import { baseAuthSelectors, baseSharedSelectors } from '../selectors';
import { BaseEnvironmentService } from '../../core/services/base-environment.service';
import { BaseApplication } from '../../shared/enums/base-application.enum';
import { BaseAvailableFileTypeEnum } from '../../shared/enums/base-available-file-type.enum';

@Injectable()
export class BaseAdministrationEffects {
  loadUserProfile$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseAdministrationActions.loadUserProfileData),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((state) => state.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        const source$ = this.roleService.isPsp()
          ? this.administrationService.getPspUserProfile(user.organizationId, action.payload.user)
          : this.administrationService.getCustomerUserProfile(user.organizationId, action.payload.user);
        return source$.pipe(
          mergeMap((response) => [
            baseAdministrationActions.setUserProfileData({ payload: response.data }),
            baseAdministrationActions.successUserProfileData(),
          ]),
          catchError(() => of(baseAdministrationActions.errorUserProfileData()))
        );
      })
    );
  });

  updateUserProfile$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(baseAdministrationActions.updateUserProfileData),
        withLatestFrom(
          this.store.select(baseAuthSelectors.getUserInfo).pipe(map((state) => state.data as BaseTokenInterface))
        ),
        switchMap(([action, user]) => {
          const body = this.sharedService.prepareUserProfileUpdateData<UserUpdateRequest>(action.payload.body);
          const source$ = this.roleService.isPsp()
            ? this.administrationService.updatePspUserProfile(user.organizationId, action.payload.user, body)
            : this.administrationService.updateCustomerUserProfile(action.payload.user, user.organizationId, body);
          return source$.pipe(
            tap(() => {
              if (user.id === action.payload.user) {
                this.store.dispatch(baseAuthActions.navigateToLoginPage());
              } else {
                this.store.dispatch(baseAdministrationActions.loadUserProfileData({ payload: { ...action.payload } }));
                if (action.payload.loadList) {
                  this.store.dispatch(
                    baseUserActions.loadAdminAndUserList({ payload: { id: user.organizationId.toString() } })
                  );
                }
              }
              this.store.dispatch(
                baseSharedActions.addSystemAlert({
                  payload: {
                    class: BaseToastAlertClasses.success,
                    body: 'auth.profileRegarding.updatedSuccessfully',
                  },
                })
              );
            }),
            catchError(() => of(baseAdministrationActions.errorUserProfileData()))
          );
        })
      );
    },
    { dispatch: false }
  );

  updateCustomerInfo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseAdministrationActions.updateCustomerInfo),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((state) => state.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) =>
        this.administrationService.updateCustomerInfo(user.organizationId, action.payload).pipe(
          mergeMap(() => {
            return [
              baseAdministrationActions.successUpdateCustomerInfo(),
              baseSharedActions.addSystemAlert({
                payload: {
                  body: 'administration.generalInfoUpdatedSuccessfully',
                  class: BaseToastAlertClasses.success,
                },
              }),
              baseAdministrationActions.loadCustomerData(),
            ];
          }),
          catchError(() => of(baseAdministrationActions.errorUpdateCustomerInfo()))
        )
      )
    );
  });

  loadCompanyInfo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseAdministrationActions.loadCompanyInfo),
      switchMap((action) => {
        if (this.roleService.isCustomer()) {
          return this.administrationService.fetchCustomerInfo(action.payload.orgID).pipe(
            mergeMap((response: any) => {
              return [
                baseAdministrationActions.setCompanyInfo({ payload: response }),
                baseAdministrationActions.successCompanyInfo(),
              ];
            }),
            catchError(() => {
              return of(baseAdministrationActions.errorCompanyInfo());
            })
          );
        }
        if (this.roleService.isPsp()) {
          return this.administrationService.fetchPspInfo(action.payload.orgID).pipe(
            mergeMap((response: any) => {
              return [
                baseAdministrationActions.setCompanyInfo({ payload: response.data }),
                baseAdministrationActions.successCompanyInfo(),
              ];
            }),
            catchError(() => {
              return of(baseAdministrationActions.errorCompanyInfo());
            })
          );
        } else {
          return of(baseAdministrationActions.errorCompanyInfo());
        }
      })
    );
  });

  updatePspInfo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseAdministrationActions.updatePspInfo),
      withLatestFrom(this.store.select(baseAuthSelectors.getUserInfo)),
      switchMap((data) =>
        this.administrationService.updatePspInfo(data[1].data ? data[1].data.organizationId : -1, data[0].payload).pipe(
          mergeMap((_) => {
            return [
              baseAdministrationActions.successUpdatePspInfo(),
              baseSharedActions.addSystemAlert({
                payload: {
                  body: 'administration.generalInfoUpdatedSuccessfully',
                  class: BaseToastAlertClasses.success,
                },
              }),
              baseAdministrationActions.loadPspData(),
            ];
          }),
          catchError(() => of(baseAdministrationActions.errorUpdatePspInfo()))
        )
      )
    );
  });

  loadCustomerData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseAdministrationActions.loadCustomerData),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((state) => state.data as BaseTokenInterface))
      ),
      switchMap(([_, user]) => {
        return this.administrationService.fetchCustomerInfo(user.organizationId).pipe(
          mergeMap((response: any) => {
            return [
              baseAdministrationActions.setCustomerData({ payload: response }),
              baseAdministrationActions.successCustomerData(),
            ];
          }),
          catchError(() => {
            return of(baseAdministrationActions.errorCustomerData());
          })
        );
      })
    );
  });

  loadPSPData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseAdministrationActions.loadPspData),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(
          filter((user) => !!user?.data?.organizationId),
          map((state) => state.data as BaseTokenInterface)
        )
      ),
      switchMap(([_, user]) => {
        return this.administrationService.fetchPspInfo(user.organizationId).pipe(
          mergeMap((response: any) => {
            return [
              baseAdministrationActions.setPspData({ payload: response }),
              baseAdministrationActions.successPspData(),
            ];
          }),
          catchError(() => {
            return of(baseAdministrationActions.errorPspData());
          })
        );
      })
    );
  });

  inviteUser$ = createEffect(() => {
    let orgId = -1;
    let fullList = false;
    return this.actions$.pipe(
      ofType(baseAdministrationActions.inviteUser),
      tap((action) => {
        orgId = action.payload.id as unknown as number;
        fullList = !!action.payload.fullList;
      }),
      switchMap((action) => {
        const application = this.environmentService.getLocalValue('application');
        return this.roleService.isPsp()
          ? this.administrationService.invitePspUser(
              action.payload.id as number,
              action.payload.body as PspInviteRequest
            )
          : application.app === BaseApplication.business
          ? this.administrationService.inviteCustomerBusinessUser(
              action.payload.id as number,
              action.payload.body as CustomerBusinessInviteRequest
            )
          : this.administrationService.inviteCustomerMedicalUser(
              action.payload.id as number,
              action.payload.body as CustomerMedicalInviteRequest
            );
      }),
      mergeMap(() => {
        return [
          baseAdministrationActions.userInvited(),
          baseSharedActions.addSystemAlert({
            payload: {
              body: 'successMessage.sendEmailInvitation',
              class: BaseToastAlertClasses.success,
            },
          }),
          fullList
            ? baseUserActions.loadAdminAndUserList({ payload: { id: orgId as unknown as string } })
            : baseUserActions.loadUserList({ payload: { id: orgId as unknown as string } }),
        ];
      })
    );
  });

  getPermissions$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseAdministrationActions.loadPermissions),
      switchMap(() => {
        return this.administrationService.getPermissions().pipe(
          mergeMap((response) => [
            baseAdministrationActions.setPermissions({ payload: response.data }),
            baseAdministrationActions.successPermissions(),
          ]),
          catchError(() => of(baseAdministrationActions.errorPermissions))
        );
      })
    );
  });

  getCatalogQualifications$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseAdministrationActions.loadCatalogQualifications),
      switchMap(() => {
        return this.administrationService.getCatalogQualifications().pipe(
          mergeMap((response) => [
            baseAdministrationActions.setCatalogQualifications({ payload: response.data.qualifications }),
            baseAdministrationActions.successCatalogQualifications(),
          ]),
          catchError(() => of(baseAdministrationActions.errorCatalogQualifications()))
        );
      })
    );
  });

  loadSurcharges$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseAdministrationActions.loadSurcharges),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((state) => state.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        const source$ = this.roleService.isPsp()
          ? this.administrationService.getPspSurcharges(user.organizationId)
          : this.administrationService.getCustomerSurcharges(user.organizationId, action.payload.location as number);
        return source$.pipe(
          mergeMap((response) => [
            baseAdministrationActions.setSurcharges({ payload: response.data ? response.data : [] }),
            baseAdministrationActions.successSurcharges(),
          ]),
          catchError(() => of(baseAdministrationActions.errorSurcharges()))
        );
      })
    );
  });

  updateSurcharge$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseAdministrationActions.updateSurcharge),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((state) => state.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        const source$ = this.roleService.isPsp()
          ? this.administrationService.updatePspSurcharge(
              action.payload.surcharge,
              user.organizationId,
              action.payload.body
            )
          : this.administrationService.updateCustomerSurcharge(
              action.payload.surcharge,
              user.organizationId,
              action.payload.location as number,
              action.payload.body
            );
        return source$.pipe(
          mergeMap(() => [
            baseAdministrationActions.successUpdateSurcharge(),
            baseSharedActions.addSystemAlert({
              payload: {
                body: 'successMessage.changesSaved',
                class: BaseToastAlertClasses.success,
              },
            }),
            baseAdministrationActions.loadSurcharges({ payload: { location: action.payload.location } }),
          ]),
          catchError(() => of(baseAdministrationActions.errorUpdateSurcharge()))
        );
      })
    );
  });

  PspLogoUpdate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseAdministrationActions.updatePspLogoAction),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((state) => state.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        return this.administrationService.updatePspLogo(user.organizationId, action.payload.body).pipe(
          mergeMap(() => [
            baseAdministrationActions.successPspLogoUpdate(),
            baseAdministrationActions.loadPspData(),
            baseAdministrationActions.loadCompanyInfo({ payload: { orgID: user.organizationId } }),
          ]),
          catchError(() => of(baseAdministrationActions.errorPspLogoUpdate()))
        );
      })
    );
  });

  customerLogoUpdate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseAdministrationActions.updateCustomerLogoAction),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((state) => state.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        return this.administrationService.updateCustomerLogo(user.organizationId, action.payload.body).pipe(
          mergeMap(() => [
            baseAdministrationActions.successCustomerLogoUpdate(),
            baseAdministrationActions.loadCustomerData(),
            baseAdministrationActions.loadCompanyInfo({ payload: { orgID: user.organizationId } }),
          ]),
          catchError(() => of(baseAdministrationActions.errorCustomerLogoUpdate()))
        );
      })
    );
  });

  profileAvatarUpdate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseAdministrationActions.updateProfileAvatarAction),
      switchMap((action) => {
        return this.administrationService.updateUserAvatar(action.payload.body).pipe(
          mergeMap(() => [
            baseSharedActions.loadProfileData(),
            baseAdministrationActions.successProfileAvatarLogoUpdate(),
          ]),
          catchError(() => of(baseAdministrationActions.errorProfileAvatarUpdate()))
        );
      })
    );
  });

  downloadContractTemplate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseAdministrationActions.downloadContractTemplate),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((user) => user.data as BaseTokenInterface))
      ),
      switchMap(([_, user]) => {
        return this.administrationService.getContractTemplate(user.organizationId).pipe(
          tap((response) => {
            const blob = new Blob([response], { type: BaseAvailableFileTypeEnum.applicationPDF });
            const objectUrl = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.setAttribute('href', objectUrl);
            link.setAttribute('download', 'Document');
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          })
        );
      })
    );
  });

  updateProfilePassword$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseAdministrationActions.updateProfilePassword),
      switchMap((action) =>
        this.administrationService.updateProfilePassword(action.payload.body).pipe(
          mergeMap(() => [
            baseAdministrationActions.successProfilePasswordUpdate(),
            baseSharedActions.addSystemAlert({
              payload: {
                class: BaseToastAlertClasses.success,
                body: 'auth.password.updatedSuccessfully',
              },
            }),
          ]),
          catchError(() => of(baseAdministrationActions.errorProfilePasswordUpdate()))
        )
      )
    );
  });

  updateUserPassword$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseAdministrationActions.updateUserPassword),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((state) => state.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        const source$ = this.roleService.isPsp()
          ? this.administrationService.updatePspUserPassword({ ...action.payload, psp: user.organizationId })
          : this.administrationService.updateCustomerUserPassword({ ...action.payload, customer: user.organizationId });
        return source$.pipe(
          mergeMap(() => [
            baseAdministrationActions.successUserPasswordUpdate(),
            baseSharedActions.addSystemAlert({
              payload: {
                class: BaseToastAlertClasses.success,
                body: 'auth.password.userUpdatedSuccessfully',
              },
            }),
          ]),
          catchError(() => of(baseAdministrationActions.errorUserPasswordUpdate()))
        );
      })
    );
  });

  loadLocationsData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseAdministrationActions.loadLocationsData),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((state) => state.data as BaseTokenInterface)),
        this.store.select(baseSharedSelectors.getOrganisationFilterState)
      ),
      switchMap(([_, user, isActive]) => {
        return this.administrationService.getLocations(user.organizationId, isActive ? true : undefined).pipe(
          mergeMap((response: LocationListItemResponse[]) => {
            return [baseAdministrationActions.setLocationsData({ payload: response })];
          }),
          catchError(() => {
            return of(baseAdministrationActions.errorLocationsData());
          })
        );
      })
    );
  });

  activateDeactivateLocation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseAdministrationActions.locationActivationAction),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((state) => state.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        const source$ = action.payload.isActivation
          ? this.administrationService.activateLocation({
              customer: user.organizationId,
              location: action.payload.locationId,
            })
          : this.administrationService.deactivateLocation({
              customer: user.organizationId,
              location: action.payload.locationId,
            });
        return source$.pipe(
          mergeMap(() => {
            return [
              baseAdministrationActions.successLocationActivationAction(),
              baseAdministrationActions.loadLocationsData(),
            ];
          }),
          catchError(() => {
            return of(baseAdministrationActions.errorLocationActivationAction());
          })
        );
      })
    );
  });

  updateLocationData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseAdministrationActions.updateLocationData),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((state) => state.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        return this.administrationService.updateLocation(user.organizationId, action.locationId, action.body).pipe(
          mergeMap(() => {
            return [
              baseAdministrationActions.successUpdateLocationData(),
              baseSharedActions.addSystemAlert({
                payload: {
                  body: 'location.locationUpdatedSuccessfully',
                  class: BaseToastAlertClasses.success,
                },
              }),
              baseAdministrationActions.loadLocationData({ locationId: action.locationId }),
              baseAdministrationActions.loadLocationsData(),
            ];
          }),
          catchError(() => {
            return of(baseAdministrationActions.errorUpdateLocationData());
          })
        );
      })
    );
  });

  createLocation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseAdministrationActions.createLocation),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((state) => state.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        return this.administrationService.createLocation(user.organizationId, action.body).pipe(
          mergeMap((response) => {
            return [
              baseAdministrationActions.successCreateLocation(),
              baseSharedActions.addSystemAlert({
                payload: {
                  body: 'location.locationCreatedSuccessfully',
                  class: BaseToastAlertClasses.success,
                },
              }),
              baseAdministrationActions.setCreatedLocationId({ payload: response.data.id }),
              baseAdministrationActions.loadLocationData({ locationId: response.data.id }),
              baseAdministrationActions.loadLocationsData(),
            ];
          }),
          catchError(() => {
            return of(baseAdministrationActions.errorCreateLocation());
          })
        );
      })
    );
  });

  loadLocationData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseAdministrationActions.loadLocationData),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((state) => state.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        return this.administrationService.getLocation(user.organizationId, action.locationId).pipe(
          mergeMap(
            (
              response: GeneralApiResponse & {
                data?: LocationResponse;
              }
            ) => {
              return [
                baseAdministrationActions.setLocationData({ payload: response.data }),
                baseAdministrationActions.successLoadLocationData(),
              ];
            }
          ),
          catchError(() => {
            return of(baseAdministrationActions.errorLoadLocationData());
          })
        );
      })
    );
  });

  loadLocationDepartmentsData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseAdministrationActions.loadLocationDepartmentsData),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((user) => user.data as BaseTokenInterface)),
        this.store.select(baseSharedSelectors.getOrganisationFilterState)
      ),
      switchMap(([action, userInfo, isActive]) => {
        return this.administrationService
          .getDepartmentLocations(userInfo.organizationId, action.locationId, isActive ? true : undefined)
          .pipe(
            mergeMap((response: DepartmentListItemResponse[]) => {
              return [
                baseAdministrationActions.setLocationDepartmentsData({
                  locationId: +action.locationId,
                  payload: response,
                }),
              ];
            }),
            catchError(() => {
              return of(baseAdministrationActions.errorLocationDepartmentsData());
            })
          );
      })
    );
  });

  activateDeactivateLocationDepartment$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseAdministrationActions.locationDepartmentActivationAction),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(
          filter((state) => !!state.ok),
          map((state) => state.data as BaseTokenInterface)
        )
      ),
      switchMap(([action, user]) => {
        const source$ = action.payload.isActivation
          ? this.administrationService.activateDepartmentLocation({
              customer: user.organizationId,
              location: action.payload.locationId,
              department: action.payload.department,
            })
          : this.administrationService.deactivateDepartmentLocation({
              customer: user.organizationId,
              location: action.payload.locationId,
              department: action.payload.department,
            });
        return source$.pipe(
          mergeMap(() => {
            return [
              baseAdministrationActions.successLocationDepartmentActivationAction(),
              baseAdministrationActions.loadLocationDepartmentsData({
                locationId: action.payload.locationId,
              }),
            ];
          }),
          catchError(() => {
            return of(baseAdministrationActions.errorLocationDepartmentActivationAction());
          })
        );
      })
    );
  });

  loadCustomerLocationTerminalsList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseAdministrationActions.loadCustomerLocationTerminalsList),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((state) => state.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        return this.administrationService
          .customerLocationTerminalsList({ customer: user.organizationId, location: action.payload.location })
          .pipe(
            mergeMap((response: TerminalCustomerLocationListItemResponse[]) => {
              return [
                baseAdministrationActions.setCustomerLocationTerminalsList({ payload: response }),
                baseAdministrationActions.successCustomerLocationTerminalsList(),
              ];
            }),
            catchError(() => {
              return of(baseAdministrationActions.errorCustomerLocationTerminalsList());
            })
          );
      })
    );
  });

  customerLocationTerminalUsageActivation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseAdministrationActions.customerLocationTerminalUsageActivation),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((state) => state.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        return this.administrationService
          .customerLocationTerminalUsageActivation({
            customer: user.organizationId,
            location: action.payload.location,
            body: action.payload.body,
          })
          .pipe(
            mergeMap(() => {
              return [
                baseAdministrationActions.successCustomerLocationTerminalUsageActivation(),
                baseAdministrationActions.loadLocationData({ locationId: action.payload.location }),
              ];
            }),
            catchError(() => {
              return of(baseAdministrationActions.errorCustomerLocationTerminalUsageActivation());
            })
          );
      })
    );
  });

  updateCustomerLocationTerminal$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseAdministrationActions.updateCustomerLocationTerminal),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((state) => state.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        return this.administrationService
          .updateCustomerLocationTerminal({
            customer: user.organizationId,
            location: action.payload.location,
            terminal: action.payload.terminal,
            body: action.payload.body,
          })
          .pipe(
            mergeMap(() => {
              return [
                baseAdministrationActions.successCustomerLocationTerminalCRUD(),
                baseAdministrationActions.loadCustomerLocationTerminalsList({
                  payload: { location: action.payload.location },
                }),
              ];
            }),
            catchError(() => {
              return of(baseAdministrationActions.errorCustomerLocationTerminalCRUD());
            })
          );
      })
    );
  });

  deleteCustomerLocationTerminal$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseAdministrationActions.deleteCustomerLocationTerminal),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((state) => state.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        return this.administrationService
          .deleteCustomerLocationTerminal({
            customer: user.organizationId,
            location: action.payload.location,
            terminal: action.payload.terminal,
          })
          .pipe(
            mergeMap(() => {
              return [
                baseAdministrationActions.successCustomerLocationTerminalCRUD(),
                baseAdministrationActions.loadCustomerLocationTerminalsList({
                  payload: { location: action.payload.location },
                }),
              ];
            }),
            catchError(() => {
              return of(baseAdministrationActions.errorCustomerLocationTerminalCRUD());
            })
          );
      })
    );
  });

  createCustomerLocationTerminal$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseAdministrationActions.createCustomerLocationTerminal),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((state) => state.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        return this.administrationService
          .createCustomerLocationTerminal({
            customer: user.organizationId,
            location: action.payload.location,
            body: action.payload.body,
          })
          .pipe(
            mergeMap((data) => {
              return [
                baseAdministrationActions.successCustomerLocationTerminalCRUD(),
                baseAdministrationActions.loadCustomerLocationTerminalsList({
                  payload: { location: action.payload.location },
                }),
                baseAdministrationActions.downloadTerminalAPIKey({
                  payload: { location: action.payload.location, terminal: data.id, openInModal: true },
                }),
              ];
            }),
            catchError(() => {
              return of(baseAdministrationActions.errorCustomerLocationTerminalCRUD());
            })
          );
      })
    );
  });

  downloadTerminalAPIKey$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseAdministrationActions.downloadTerminalAPIKey),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((state) => state.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        return this.administrationService
          .customerLocationTerminalQRCode({
            customer: user.organizationId,
            location: action.payload.location,
            terminal: action.payload.terminal,
          })
          .pipe(
            !action.payload.openInModal
              ? map(() => baseAdministrationActions.successDownloadTerminalAPIKey())
              : mergeMap((response) => [
                  baseAdministrationActions.setTerminalAPIKeyView({
                    payload: {
                      document: new Blob([response.body], { type: BaseAvailableFileTypeEnum.applicationPDF }),
                      fileName: response.fileName,
                      terminalId: action.payload.terminal,
                    },
                  }),
                  baseAdministrationActions.successDownloadTerminalAPIKey(),
                ]),
            catchError(() => of(baseAdministrationActions.errorDownloadTerminalAPIKey()))
          );
      })
    );
  });

  constructor(
    private actions$: Actions,
    private store: Store<BaseCoreState>,
    private router: Router,
    private readonly administrationService: BaseAdministrationService,
    private readonly sharedService: BaseSharedService,
    private readonly roleService: BaseRolesService,
    private environmentService: BaseEnvironmentService
  ) {}
}
