import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { baseOffersAndBookingsReducer, baseOffersAndBookingsEffects } from '../../_store';
import { BaseOffersAndBookingsService } from './base-offers-and-bookings.service';

@NgModule({
  imports: [
    StoreModule.forFeature('offersAndBookings', baseOffersAndBookingsReducer.BaseOffersAndBookingsReducer),
    EffectsModule.forFeature([baseOffersAndBookingsEffects.BaseOffersAndBookingsEffects]),
  ],
  providers: [BaseOffersAndBookingsService],
})
export class BaseOffersAndBookingsModule {}
