import { Injectable } from '@angular/core';
import { BehaviorSubject, timer } from 'rxjs';
import { BaseBuildDetailsService } from './base-build-details.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { BuildDetails } from '../../shared/model/base-build-details';
import { BASE_BUILD_DETAILS_URL } from '../constants/base-build-details-url';

@Injectable({ providedIn: 'root' })
export class BaseUpToDateBuildService {
  buildIsUpToDateSubject = new BehaviorSubject<boolean>(true);
  isReloadRequired = new BehaviorSubject<boolean>(false);
  private readonly buildNumberAtStartup: string;

  constructor(buildDetailsService: BaseBuildDetailsService, private httpClient: HttpClient) {
    this.buildNumberAtStartup = buildDetailsService.buildDetails.buildNumber;
    this.pollForBuildNumber();
  }

  setIsReloadRequired(value: boolean): void {
    this.isReloadRequired.next(value);
  }

  private pollForBuildNumber(): void {
    const pollInterval = 120000;

    const httpOptions = {
      headers: new HttpHeaders({
        'Cache-Control': 'no-cache',
      }),
    };

    timer(pollInterval, pollInterval).subscribe(() => {
      this.httpClient.get<BuildDetails>(BASE_BUILD_DETAILS_URL, httpOptions).subscribe((response) => {
        if (response?.buildNumber && this.buildNumberAtStartup !== response.buildNumber) {
          this.buildIsUpToDateSubject.next(false);
        }
      });
    });
  }
}
