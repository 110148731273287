import { Component, Input } from '@angular/core';
import { IndustrySurcharge2 } from '../../../../api';
import { BaseIndustrySurchargesDateType } from '../../../shared/enums/base-industry-surcharges.enum';

@Component({
  selector: 'base-industry-surcharges',
  templateUrl: './base-industry-surcharges.component.html',
})
export class BaseIndustrySurchargesComponent {
  @Input() surcharges: IndustrySurcharge2;
  @Input() showCategoryAndMethod = false;
  dateUnitType = BaseIndustrySurchargesDateType;
}
