<div class="listview-item no-pointer bg-lightest mb-3">
  <div class="row d-flex flex-wrap flex-md-nowrap">
    <div
      class="col-3 d-flex align-items-center pointer"
      (click)="downloadDocument()"
    >
      <i
        class="fa-solid fs-4 me-2"
        [ngbTooltip]="
          (document.document.type === baseAvailableFileTypes.pdf ? 'tooltip.pdf' : 'tooltip.image') | translate
        "
        [ngClass]="document.document.type === baseAvailableFileTypes.pdf ? 'fa-file-pdf' : 'fa-file-image'"
      ></i>
      <span baseTruncate>{{ document.document.name | baseIsEmpty }}</span>
    </div>
    <div
      data-before="category"
      class="col-3"
    >
      <span baseTruncate>{{ document.categoryLabel | baseIsEmpty }}</span>
    </div>
    <div class="col-2">
      <span baseTruncate>{{
        document.document.validUntil
          ? (document.document.validUntil | baseFormatDate)
          : ('dateTime.indefinitely' | translate)
      }}</span>
    </div>
    <div class="col-2">
      <span
        baseTruncate
        *ngIf="!readonly"
        >{{ document.document.createdAt | baseFormatDate }}</span
      >
    </div>
    <div class="col-2 d-flex justify-content-end gap-2 flex-nowrap flex-md-wrap">
      <base-button
        *ngIf="!readonly"
        [size]="buttonSizes.md"
        [iconSize]="buttonSizes.md"
        color="dark"
        icon="fa-solid fa-pen"
        stateClass="pe-1"
        tooltip="action.edit"
        (btnClick)="openEditDocumentModal()"
      ></base-button>
      <base-button
        [iconSize]="buttonSizes.md"
        color="dark"
        stateClass="pe-1"
        icon="fa-solid fa-download"
        tooltip="action.download"
        (btnClick)="downloadDocument()"
      ></base-button>
      <base-button
        [iconSize]="buttonSizes.md"
        color="dark"
        stateClass="pe-1"
        icon="fa-solid fa-eye"
        tooltip="action.open"
        (btnClick)="openDocument()"
      ></base-button>
      <base-button
        *ngIf="!readonly"
        [size]="buttonSizes.md"
        [iconSize]="buttonSizes.md"
        color="dark"
        stateClass="pe-1"
        icon="fa-solid fa-trash"
        tooltip="action.delete"
        (btnClick)="deleteDocument()"
      ></base-button>
    </div>
  </div>
</div>

<ng-template #documentModalBody>
  <base-documentupload-modal
    [documentUploadForm]="documentForm"
    [submitted]="documentFormSubmitted"
    (openExistingDocument)="openDocument()"
  ></base-documentupload-modal>
</ng-template>

<ng-template #documentViewerTemplate>
  <base-document-viewer
    *ngIf="activeDocument"
    [src]="activeDocumentSrc"
    [title]="activeDocument.name"
    [fileName]="activeDocument.originalFilename"
    [fileType]="activeDocument.type"
    [modalRef]="documentViewerModalRef"
    (downloadImage)="downloadDocument()"
  ></base-document-viewer>
</ng-template>

<ng-template #deleteDocumentModalTemplate>
  <base-delete-document-confirmation-template
    [documentName]="document.document.name"
  ></base-delete-document-confirmation-template>
</ng-template>
