import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import {
  AvatarUpdateRequest,
  CandidateProfileService,
  CreatedResponse,
  CreatedResponseWithBody,
  DocumentCreationResponse,
  DocumentUpdateRequest,
  EmptySuccessfulResponse,
  GeneralApiResponse,
  ListApiResponse,
  PaginatedApiResponse,
  ProfileAbsenceConflictsListItemResponse,
  ProfileAbsenceListItemResponse,
  ProfileAbsenceResponse,
  ProfileAbsencesTypeSummaryResponse,
  ProfileCreateAbsenceRequest,
  ProfileCreateRequest,
  ProfileDocumentResponse,
  ProfileLeavingDateConflictsListResponse,
  ProfileLeavingDateUpdateRequest,
  ProfileListResponse,
  ProfileShowResponse,
  ProfileUpdateAbsenceRequest,
  ProfileUserShowResponse,
  PspService,
  UpdatedResponse,
  PspProfileUpdateRequest,
  UserItemResponse,
} from '../../../api';
import { BaseSharedHelperService } from '../../shared/services/base-shared-helper.service';
import { BaseToastAlertClasses } from '../../shared/enums/base-toast-alert-classes.enum';
import { BaseCoreState, baseSharedActions } from '../../_store';
import { Store } from '@ngrx/store';

@Injectable()
export class BaseCandidatesService {
  constructor(
    private readonly candidatesService: CandidateProfileService,
    private readonly pspService: PspService,
    private store: Store<BaseCoreState>
  ) {}

  updateAvatar(psp: number, profile: number, body: AvatarUpdateRequest): Observable<UpdatedResponse> {
    return this.candidatesService.putAppApiWebV1PspProfileAvatarUpdateIndex({ psp, profile, body });
  }

  loadCandidates(params: any): Observable<PaginatedApiResponse & { data?: ProfileListResponse[] }> {
    return this.candidatesService.getAppApiWebV1PspProfileListIndex(params);
  }

  updateCandidate(psp: number, profile: number, body: PspProfileUpdateRequest): Observable<UpdatedResponse> {
    return this.candidatesService.patchAppApiWebV1PspProfileUpdateIndex({ psp, profile, body });
  }

  getCandidate(psp: number, profile: number): Observable<ProfileShowResponse> {
    return this.candidatesService
      .getAppApiWebV1PspProfileShowIndex({ psp, profile })
      .pipe(map((response) => response.data));
  }

  createCandidate(psp: number, body: ProfileCreateRequest): Observable<CreatedResponseWithBody> {
    return this.candidatesService.postAppApiWebV1PspProfileCreateIndex({ psp, body }).pipe(take(1));
  }

  uploadDocument(psp: number, file: File): Observable<{ message: string; data: DocumentCreationResponse }> {
    return this.candidatesService.postProfleDocumentAdd({ psp, body: { file } });
  }

  updateDocument(
    psp: number,
    profile: number,
    document: string,
    body: DocumentUpdateRequest
  ): Observable<EmptySuccessfulResponse> {
    return this.candidatesService.putProfileDocumentUpdate({ psp, profile, document, body });
  }

  deleteDocument(psp: number, profile: number, document: string): Observable<EmptySuccessfulResponse> {
    return this.candidatesService.deleteProfileDocumentDelete({ psp, profile, document });
  }

  getAbsences(
    psp: number,
    profile: number,
    page: number,
    type: any,
    size?: number
  ): Observable<PaginatedApiResponse & { data?: Array<ProfileAbsenceListItemResponse> }> {
    return this.candidatesService.getProfileAbsenceList({ profile, page, size, type, psp });
  }

  getAbsenceSummary(
    psp: number,
    profile: number
  ): Observable<GeneralApiResponse & { data?: ProfileAbsencesTypeSummaryResponse }> {
    return this.candidatesService.getProfileAbsenceSummary({ profile, psp });
  }

  getAbsence(
    psp: number,
    profile: number,
    absence: number
  ): Observable<GeneralApiResponse & { data?: ProfileAbsenceResponse }> {
    return this.candidatesService.getProfileAbsenceGet({ profile, absence, psp });
  }

  createAbsence(psp: number, profile: number, body: ProfileCreateAbsenceRequest): Observable<CreatedResponse> {
    return this.candidatesService.postProfileAbsenceCreate({ profile, psp, body });
  }

  updateAbsence(
    psp: number,
    profile: number,
    absence: number,
    body: ProfileUpdateAbsenceRequest
  ): Observable<UpdatedResponse> {
    return this.candidatesService.putProfileAbsenceUpdate({ profile, absence, psp, body });
  }

  deleteAbsence(psp: number, profile: number, absence: number): Observable<EmptySuccessfulResponse> {
    return this.candidatesService.deleteProfileAbsenceDelete({ profile, absence, psp });
  }

  getProfileAccountInformation(
    psp: number,
    profile: number
  ): Observable<GeneralApiResponse & { data?: ProfileUserShowResponse }> {
    return this.candidatesService.getAppApiWebV1PspProfileUserListIndex({ psp, profile });
  }

  reactivateProfileAccount(psp: number, profile: number): Observable<EmptySuccessfulResponse> {
    return this.candidatesService.postAppApiWebV1PspProfileMobileaccessReactivateIndex({ psp, profile });
  }

  activateMobileAccess(psp: number, profile: number): Observable<EmptySuccessfulResponse> {
    return this.candidatesService.postAppApiWebV1PspProfileMobileaccessActivateIndex({ psp, profile });
  }

  deactivateMobileAccess(psp: number, profile: number): Observable<EmptySuccessfulResponse> {
    return this.candidatesService.deleteAppApiWebV1PspProfileMobileaccessDeactivateIndex({ psp, profile });
  }

  getAbsenceConflicts(
    profile: number,
    startDate: any,
    endDate: any,
    psp: number
  ): Observable<{ data?: Array<ProfileAbsenceConflictsListItemResponse> } & ListApiResponse> {
    return this.candidatesService.getProfileAbsenceConflicts({ profile, startDate, endDate, psp });
  }

  getAssignmentTimes(
    psp: number,
    params: {
      'branches[]'?: any;
      'qualificationCategory[]'?: any;
      'assignmentDateStatuses[]'?: any;
      mainQualification?: any;
      active?: any;
      search?: any;
      location?: any;
      radius?: any;
      from: string;
      to: string;
      'candidates[]'?: any;
    }
  ): Observable<any> {
    return this.candidatesService.getAppApiWebV1PspProfileExportWorktimeExportIndex$Response({ ...params, psp }).pipe(
      map((response) => {
        const contentDispositionValue = response.headers.get('Content-Disposition');
        const fileName = BaseSharedHelperService.fileNameFromHeaderValue(contentDispositionValue, '=');
        return {
          body: response.body,
          fileName,
        };
      })
    );
  }

  downloadCandidateDocumentFile(params: { profile: number; document: string; psp: number }): Observable<any> {
    return this.candidatesService.getProfileDocumentDownload$Response(params).pipe(
      map((response) => {
        const contentDispositionValue = response.headers.get('Content-Disposition');
        const fileName = BaseSharedHelperService.fileNameFromHeaderValue(contentDispositionValue, '=');
        return {
          body: response.body,
          fileName,
        };
      })
    );
  }

  downloadContracts(
    psp: number,
    params: {
      'branches[]'?: any;
      'qualificationCategory[]'?: any;
      mainQualification?: any;
      active?: any;
      search?: any;
      location?: any;
      radius?: any;
      from: string;
      to: string;
      'candidates[]'?: any;
    }
  ): Observable<any> {
    return this.candidatesService.getAppApiWebV1PspProfileExportContractExportIndex$Response({ ...params, psp }).pipe(
      map((response) => {
        if (response.body.type === 'application/json') {
          const fileReader = new FileReader();
          fileReader.onload = (event) => {
            const messageObj = JSON.parse(((event.target as FileReader).result as ArrayBuffer).toString());
            this.store.dispatch(
              baseSharedActions.addSystemAlert({
                payload: {
                  body: messageObj.message,
                  class: BaseToastAlertClasses.warning,
                },
              })
            );
          };
          fileReader.readAsText(response.body);
          return;
        }
        const contentDispositionValue = response.headers.get('Content-Disposition');
        const fileName = BaseSharedHelperService.fileNameFromHeaderValue(contentDispositionValue, '=');
        return {
          body: response.body,
          fileName,
        };
      })
    );
  }

  responsibleUsersList(orgId: number): Observable<(UserItemResponse & { fullName?: string })[]> {
    return this.pspService.getPspsUsersList({ psp: Number(orgId), active: true }).pipe(
      map((list) =>
        list.data.map((user: UserItemResponse & { fullName?: string }) => {
          user.fullName = `${user.firstName} ${user.lastName}`;
          return user;
        })
      )
    );
  }

  leavingDateConflicts(params: {
    psp: number;
    profile: number;
    leavingDate: string;
  }): Observable<ProfileLeavingDateConflictsListResponse[]> {
    return this.candidatesService
      .getAppApiWebV1PspProfileLeavingdateConflictsIndex(params)
      .pipe(map((response) => response.data));
  }

  setCandidateProfileLeavingDate(params: {
    psp: number;
    profile: number;
    body: ProfileLeavingDateUpdateRequest;
  }): Observable<EmptySuccessfulResponse> {
    return this.candidatesService.putAppApiWebV1PspProfileLeavingdateUpdateIndex(params);
  }

  reactivateCandidateProfile(params: { psp: number; profile: number }): Observable<EmptySuccessfulResponse> {
    return this.candidatesService.putAppApiWebV1PspProfileLeavingdateReactivateIndex(params);
  }

  candidateProfileDocumentList(params: { psp: number; profile: number }): Observable<ProfileDocumentResponse[]> {
    return this.candidatesService.getPspProfileDocumentList(params).pipe(map((response) => response.data));
  }
}
