<div
  [class]="bgColorClasses"
  [ngStyle]="{ width }"
>
  <i class="fa-solid fa-search fa-flip-horizontal"></i>
  <input
    #input
    (keyup.enter)="onEnter()"
    [(ngModel)]="changes"
    [ngClass]="{ 'rounded-pill': rounded, 'no-validation': noValidation }"
    [placeholder]="placeholder | translate"
    class="form-control"
    type="text"
  />
  <i
    (click)="clearFilter($event)"
    *ngIf="input.value"
    class="pointer fa-solid fa-circle-xmark fa-fw"
  ></i>
</div>
