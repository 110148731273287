import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { noop, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { DocumentCategoryResponse } from '../../../../api';
import { BaseCoreState, baseDocumentActions, baseDocumentSelectors } from '../../../_store';
import { BaseModalService } from '../../../shared/services/base-modal.service';
import { DocumentFormInterface } from '../../_interfaces/base-document-uploadmodal.interface';
import { BaseAvailableFileTypeEnum } from '../../../shared/enums/base-available-file-type.enum';

@Component({
  selector: 'base-documentupload-modal',
  templateUrl: './base-documentupload-modal.component.html',
  styleUrls: ['./base-documentupload-modal.component.scss'],
})
export class BaseDocumentuploadModalComponent implements OnInit, OnDestroy {
  @ViewChild('documentViewerTemplate') documentViewerTemplate: ElementRef;
  @Input() documentUploadForm: FormGroup<DocumentFormInterface>;
  @Input() submitted = false;
  @Input() isFileExisting = false;
  @Input() editCandidate = false;
  @Input() fixedCategory = false;
  @Output() openExistingDocument = new EventEmitter();
  destroy$ = new Subject();
  documentCategories: { label: string; value: number }[];
  isValidIndefinitely = false;
  activeDocumentSrc: Blob | SafeUrl;
  activeDocument: string | File;
  documentViewerModalRef: NgbModalRef;
  baseAvailableFileTypes = BaseAvailableFileTypeEnum;

  constructor(
    private store: Store<BaseCoreState>,
    private modalService: BaseModalService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.documentUploadForm
      .get('file')
      ?.valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        this.documentUploadForm.get('existingFile')?.setValue(value);
      });
    this.isValidIndefinitely = !this.documentUploadForm.controls.validUntil.value;
    this.onValidIndefinitelyChange(this.isValidIndefinitely);
    this.store
      .select(baseDocumentSelectors.getDocumentCategories)
      .pipe(
        takeUntil(this.destroy$),
        map((result) => result.data)
      )
      .subscribe((documentCategories) => {
        this.documentCategories = Object.entries(documentCategories as DocumentCategoryResponse).reduce(
          (collector, item) => {
            collector.push({ label: item[1], value: +item[0] });

            return collector;
          },
          [] as { label: string; value: number }[]
        );
        const documentUploadFormValue = this.documentUploadForm.getRawValue();

        if (documentUploadFormValue.category && !this.fixedCategory) {
          const category = this.documentCategories.find((cat) => cat.value === documentUploadFormValue.category?.value);
          this.documentUploadForm.patchValue({ category });
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }

  onValidIndefinitelyChange(isChecked: boolean): void {
    this.isValidIndefinitely = isChecked;
    const validUntilControl = this.documentUploadForm.controls.validUntil;
    if (this.isValidIndefinitely) {
      validUntilControl.setValue(null);
      validUntilControl.clearValidators();
      validUntilControl?.disable();
    } else {
      validUntilControl?.enable();
      validUntilControl.setValidators(Validators.required);
      validUntilControl.setValue(validUntilControl.value ? validUntilControl.value : '');
    }
  }

  deleteExistingFile(): void {
    const fileControl = this.documentUploadForm.controls.file;

    fileControl.reset('');
    fileControl?.addValidators(Validators.required);
    fileControl?.updateValueAndValidity();
  }

  viewDocument(isDocumentExist?: boolean): void {
    if (isDocumentExist) {
      this.openExistingDocument.emit(isDocumentExist);
    } else {
      const file = this.documentUploadForm.getRawValue().file;
      this.openDocumentViewer(file);
    }
  }

  downloadImage() {
    if (
      (this.activeDocument as File).type !== this.baseAvailableFileTypes.pdf &&
      (this.activeDocument as File).type !== this.baseAvailableFileTypes.applicationPDF
    ) {
      const objectUrl = URL.createObjectURL(this.activeDocument.slice() as Blob);
      const link = document.createElement('a');
      link.setAttribute('href', objectUrl);
      link.setAttribute('download', (this.activeDocument as File).name);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  private openDocumentViewer(document: File | string): void {
    this.activeDocument = document as File;
    this.activeDocumentSrc =
      this.activeDocument.type === this.baseAvailableFileTypes.pdf ||
      this.activeDocument.type === this.baseAvailableFileTypes.applicationPDF
        ? (document.slice() as Blob)
        : this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(document.slice() as Blob));
    this.documentViewerModalRef = this.modalService.open(
      {
        bodyTemplate: this.documentViewerTemplate,
        cancelButtonDisplayed: false,
        okButtonDisplayed: false,
        hideHeader: true,
        hideFooter: true,
      },
      { size: 'xl' }
    );
    this.documentViewerModalRef.result.then(() => {
      this.store.dispatch(baseDocumentActions.clearDownloadDocument());
    }, noop);
  }
}
