import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en-GB';
import localeDeExtra from '@angular/common/locales/extra/de';
import localeEnExtra from '@angular/common/locales/extra/en-GB';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgSelectModule } from '@ng-select/ng-select';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CommonModule, registerLocaleData } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  ApiModule,
  AppsSharedModule,
  BASE_META_REDUCERS,
  BASE_REDUCERS,
  BaseBuildDetailsHttpService,
  BaseModule,
  BaseSharedModule,
  baseTranslateLoaderFactory,
  ENVIRONMENT,
  ENVIRONMENTER,
} from 'base';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { SharedModule } from './modules/shared/shared.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

registerLocaleData(localeDe, 'de', localeDeExtra);
registerLocaleData(localeEn, 'en', localeEnExtra);

function fetchBuildDetails(buildDetailsService: BaseBuildDetailsHttpService): any {
  return () => buildDetailsService.fetchBuildDetails();
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BaseModule,
    AppRoutingModule,
    NgSelectModule,
    InfiniteScrollModule,
    ApiModule.forRoot({ rootUrl: environment.global.apiUrl }),
    SharedModule.forRoot(),
    AppsSharedModule.forRoot(),
    BaseSharedModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: baseTranslateLoaderFactory,
        deps: [HttpClient],
      },
      defaultLanguage: 'de',
    }),
    StoreModule.forRoot(BASE_REDUCERS, {
      // @ts-ignore
      metaReducers: BASE_META_REDUCERS,
      runtimeChecks: { strictStateImmutability: true, strictActionImmutability: true },
    }),
    StoreRouterConnectingModule.forRoot(),
    EffectsModule.forRoot([]),
    !environment.global.production
      ? StoreDevtoolsModule.instrument({
          maxAge: 25, //  Retains last 25 states
        })
      : [],
  ],
  providers: [
    { provide: ENVIRONMENT, useValue: environment },
    { provide: ENVIRONMENTER, useValue: environment },
    { provide: LOCALE_ID, useValue: 'de' },
    environment.global.production || environment.global.staging || environment.global.demo
      ? { provide: APP_INITIALIZER, useFactory: fetchBuildDetails, deps: [BaseBuildDetailsHttpService], multi: true }
      : [],
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
