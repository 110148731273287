<div
  class="listview-item bg-lightest mb-3"
  *ngIf="user"
  [ngClass]="{ 'cursor-auto': !isCurrentlyLoggedInUser }"
  (click)="onItemClick()"
>
  <div class="row">
    <div class="col-3 d-flex flex-nowrap align-items-center">
      <base-avatar
        [size]="avatarSizes.medium"
        [image]="user.avatar"
        class="me-2"
      ></base-avatar>
      <span
        class="fw-bold"
        baseTruncate
        >{{ fullName | baseIsEmpty }}</span
      >
    </div>
    <div
      class="col-2"
      baseTruncate
    >
      {{ user.email | baseIsEmpty }}
    </div>
    <div
      class="col-2"
      baseTruncate
    >
      {{ user.group?.name ? user.group.name : user.admin ? ('permission.isAdmin' | translate) : '-' }}
    </div>
    <div
      class="col-2"
      baseTruncate
    >
      {{ user.position | baseIsEmpty }}
    </div>
    <div class="col-1">
      <p
        *ngIf="user.invited"
        class="fw-bold text-primary m-0"
        baseTruncate
      >
        {{ 'states.invited' | translate }}
      </p>
      <base-switch
        *ngIf="!user.invited && !isCurrentlyLoggedInUser"
        [(ngModel)]="isActive"
        [tooltip]="isActive ? 'tooltip.deactivate' : 'tooltip.activate'"
        (ngModelChange)="changeIsActive($event)"
      ></base-switch>
    </div>
    <div class="col-2 d-flex gap-2 justify-content-end">
      <base-button
        *ngIf="!isCurrentlyLoggedInUser"
        color="dark"
        icon="fa-solid fa-pen"
        [size]="buttonSizes.md"
        [iconSize]="buttonSizes.md"
        (click)="openUserEditModal($event)"
        tooltip="action.edit"
        stateClass="pe-1"
      ></base-button>
      <base-button
        *ngIf="user.invited"
        color="dark"
        icon="fa-solid fa-trash"
        [size]="buttonSizes.md"
        [iconSize]="buttonSizes.md"
        (click)="openDeleteInvitationModal($event)"
        tooltip="action.delete"
        stateClass="pe-1"
      ></base-button>
      <base-button
        *ngIf="user.invited"
        color="dark"
        icon="fa-solid fa-arrows-rotate"
        [size]="buttonSizes.md"
        [iconSize]="buttonSizes.md"
        (click)="resendInvitation($event, user)"
        tooltip="action.resendInvitation"
        stateClass="pe-1"
      ></base-button>
    </div>
  </div>
</div>

<ng-template #editUserModalTemplate>
  <base-edit-user-form
    [form]="editUserForm"
    [isFormSubmitted]="isEditUserFormSubmitted"
    [groups]="groups"
    [user]="user"
    (editUser)="editUser()"
    (cancelUserEdit)="cancelUserEdit()"
  ></base-edit-user-form>
</ng-template>

<ng-template #deactivationConfirmationTemplate>
  <p [innerHTML]="'user.deactivateUser.confirmationQuestion' | translate: { user: fullName }"></p>
</ng-template>
