<div
  *ngIf="!isPreview"
  class="form-inline k-datepicker"
>
  <label
    *ngIf="label"
    [ngClass]="{ required: isRequired }"
    >{{ label | translate }}</label
  >
  <div
    class="input-group"
    [ngClass]="{ invalid: errorText, 'k-datepicker-filter': isFilter }"
  >
    <input
      [ngClass]="{ 'form-control': !isFilter, 'form-select': isFilter, 'no-pointer': readonly }"
      [placeholder]="placeholder"
      [(ngModel)]="model"
      [disabled]="disabled"
      [readOnly]="readonly"
      [footerTemplate]="footerTemplate"
      [tabIndex]="tabIndex"
      [minDate]="minDate"
      [maxDate]="maxDate"
      (dateSelect)="onDateSelect($event)"
      (input)="onDateChange($event)"
      #input
      [placement]="placement"
      ngbDatepicker
      baseMaskDate
      [markDisabled]="isDateDisabled"
      #d="ngbDatepicker"
      (click)="d.toggle()"
    />
    <button
      type="button"
      (click)="d.toggle()"
      [disabled]="disabled"
      class="btn"
      *ngIf="!isFilter"
    >
      <i class="fa-solid fa-calendar-days"></i>
    </button>
  </div>
  <p class="invalid-feedback">{{ errorText ? (errorText | translate) : '' }}</p>
</div>
<base-form-field-preview
  *ngIf="isPreview"
  [label]="label"
  [value]="(model | baseFormatDate) || placeholder"
  class="form-field-preview"
></base-form-field-preview>

<ng-template #footerTemplate>
  <div
    *ngIf="unlimitedCheck"
    class="k-datepicker-footer"
  >
    <base-checkbox
      [label]="'unlimited' | translate"
      (click)="onUnlimitedClick($event)"
    ></base-checkbox>
  </div>
</ng-template>
