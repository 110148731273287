import { Component, Input, OnInit } from '@angular/core';
import { getValueByNestedPath } from '../../../../helpers/base-get-nested-value';
import { BaseAvatarSize } from '../../../avatar/base-avatar.component';

@Component({
  selector: 'base-dropdown-custom-label',
  templateUrl: './custom-label.component.html',
})
export class CustomLabelComponent implements OnInit {
  @Input() item: Record<string, any>;
  @Input() bindOptionLabel: string = 'name';
  @Input() bindOptionSubLabel: string;
  @Input() isAssignmentView: boolean = false;

  avatarSize = BaseAvatarSize;
  label: string;
  subLabel: string;

  ngOnInit() {
    this.label = getValueByNestedPath(this.item, this.bindOptionLabel);

    if (this.bindOptionSubLabel) {
      this.subLabel = getValueByNestedPath(this.item, this.bindOptionSubLabel);
    }
  }

  protected readonly BaseAvatarSize = BaseAvatarSize;
}
