import produce from 'immer';
import { basePspBranchActions } from '../actions';
import { BranchListItemResponse, PermissionItemResponse, PspBranchResponse, PspResponse } from '../../../api';

export interface PspBranchState {
  pspData: {
    data: { message: string; data: PspResponse } | undefined;
    ok: boolean | undefined;
    updateCrud: boolean | undefined;
  };
  pspBranch: {
    data: { message: string; data: Array<BranchListItemResponse> } | undefined;
    ok: boolean | undefined;
    crud: boolean | undefined;
  };
  pspBranchUsers: {
    data: { message: string; data: Array<PermissionItemResponse> } | undefined;
    ok: boolean | undefined;
  };
  currentBranch: {
    data: PspBranchResponse | undefined;
    ok: boolean | undefined;
  };
  branchFilter: {
    filterActive: boolean;
  };
}

const INITIAL_PSP_BRANCH_STATE: PspBranchState = {
  pspData: {
    data: undefined,
    ok: undefined,
    updateCrud: undefined,
  },
  pspBranch: {
    data: undefined,
    ok: undefined,
    crud: undefined,
  },
  pspBranchUsers: {
    data: undefined,
    ok: undefined,
  },
  currentBranch: {
    data: undefined,
    ok: undefined,
  },
  branchFilter: {
    filterActive: true,
  },
};

export function BasePspBranchReducer(state: PspBranchState = INITIAL_PSP_BRANCH_STATE, action: any): any {
  return produce(state, (draftState) => {
    switch (action.type) {
      case basePspBranchActions.loadPspBranchList.type:
        draftState.pspBranch.ok = undefined;
        return;

      case basePspBranchActions.setPspBranchList.type:
        draftState.pspBranch.data = action.payload;
        return;

      case basePspBranchActions.successPspBranchList.type:
        draftState.pspBranch.ok = true;
        return;

      case basePspBranchActions.errorPspBranchList.type:
        draftState.pspBranch.ok = false;
        return;

      case basePspBranchActions.setPspBranchActiveCrud.type:
        draftState.pspBranch.crud = undefined;
        return;

      case basePspBranchActions.successPspBranchCrud.type:
        draftState.pspBranch.crud = true;
        return;

      case basePspBranchActions.errorPspBranchCrud.type:
        draftState.pspBranch.crud = false;
        return;

      case basePspBranchActions.loadPspUserList.type:
        draftState.pspBranchUsers.ok = undefined;
        return;

      case basePspBranchActions.setPspUserList.type:
        draftState.pspBranchUsers.data = action.payload;
        return;

      case basePspBranchActions.successPspUserList.type:
        draftState.pspBranchUsers.ok = true;
        return;

      case basePspBranchActions.errorPspUserList.type:
        draftState.pspBranchUsers.ok = false;
        return;

      case basePspBranchActions.loadBranch.type:
        draftState.currentBranch.ok = undefined;
        return;

      case basePspBranchActions.setBranch.type:
        draftState.currentBranch.data = action.payload;
        return;

      case basePspBranchActions.successBranch.type:
        draftState.currentBranch.ok = true;
        return;

      case basePspBranchActions.errorBranch.type:
        draftState.currentBranch.ok = false;
        return;

      case basePspBranchActions.resetBranch.type:
        draftState.currentBranch.data = undefined;
        draftState.currentBranch.ok = undefined;
        return;

      case basePspBranchActions.setBranchInactiveFilter.type:
        draftState.branchFilter.filterActive = action.filterActive;
        return;
    }
  });
}
