import { createAction, props } from '@ngrx/store';
import { GroupListItemResponse, PermissionItemResponse } from '../../../api';

// Admin list
export const loadAdminList = createAction(
  '[User Administration] Load admins list',
  props<{ payload: { id: string | undefined } }>()
);
export const setAdminList = createAction(
  '[User Administration] Set admins list',
  props<{ payload: PermissionItemResponse[] | undefined }>()
);
export const successAdminList = createAction('[User Administration] Success admins list');
export const errorAdminList = createAction('[User Administration] Error admins list');

// User list
export const loadUserList = createAction(
  '[User Administration] Load user list',
  props<{ payload: { id: string | undefined; search?: string } }>()
);
export const setUserList = createAction(
  '[User Administration] Set user list',
  props<{ payload: PermissionItemResponse[] | undefined }>()
);
export const successUserList = createAction('[User Administration] Success user list');
export const errorUserList = createAction('[User Administration] Error user list');

// Admin and User list
export const loadAdminAndUserList = createAction(
  '[User Administration] Load admin and user list',
  props<{ payload: { id: string | undefined; search?: string } }>()
);
export const setAdminAndUserList = createAction(
  '[User Administration] Set admin and user list',
  props<{ payload: PermissionItemResponse[] | undefined }>()
);
export const successAdminAndUserList = createAction('[User Administration] Success admin and user list');
export const errorAdminAndUserList = createAction('[User Administration] Error admin and user list');

// User Groups
export const loadUserGroups = createAction(
  '[Administration: User groups] Load user groups',
  props<{ payload: number | undefined }>()
);
export const setUserGroups = createAction(
  '[Administration: User groups] Store user groups',
  props<{ payload: Array<GroupListItemResponse> | undefined }>()
);
export const successUserGroups = createAction('[Administration: User groups] User groups fetched');
export const errorUserGroups = createAction('[Administration: User groups] User groups fetch error');

// User Group
export const loadGroup = createAction('[Administration] Load group', props<{ payload: any }>());
export const setGroup = createAction('[Administration] Set group', props<{ payload: any }>());
export const successGroup = createAction('[Administration] Success group');
export const errorGroup = createAction('[Administration] Error group');
export const deleteUsersGroup = createAction('[Administration] Delete user group', props<{ payload: number }>());
export const deleteUsersGroupSuccessfully = createAction('[Administration] Delete user group successfully');

// User Group CRUD
export const createGroupCrud = createAction('[Administration] Create group', props<{ payload: any }>());
export const updateGroupCrud = createAction('[Administration] Update group', props<{ payload: any }>());
export const successGroupCrud = createAction('[Administration] Success group crud');
export const errorGroupCrud = createAction('[Administration] Error group crud');

// User activation
export const activateUser = createAction(
  '[Administration] Activate user',
  props<{ payload: number; loadCommonList?: boolean }>()
);
export const deactivateUser = createAction(
  '[Administration] Deactivate user',
  props<{ payload: number; loadCommonList?: boolean }>()
);

// User activation filter
export const setUserInactiveFilter = createAction(
  '[Administration] Set filter for inactive users',
  props<{ filterActive: boolean }>()
);

// Delete Invited User
export const deleteInvitedUser = createAction(
  '[Users] Delete invited user',
  props<{ payload: number; fullList?: boolean }>()
);
export const successDeleteInvitedUser = createAction('[Users] Success delete invited user');
export const errorDeleteInvitedUser = createAction('[Users] Error delete invited user');

// Resend User Invitation
export const resendUserInvitation = createAction(
  '[Users] Resend User Invitation',
  props<{ payload: { userId: number; userName: string } }>()
);
export const resendUserInvitationSuccess = createAction('[Users] Resend User Invitation Success');
export const resendUserInvitationError = createAction('[Users] Resend User Invitation Error');
