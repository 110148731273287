import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { BaseCoreState } from '../reducers';
import { BaseRelatedCustomerLocationsService } from '../../modules/related-customer-locations/base-related-customer-locations.service';
import { BaseTokenInterface } from '../../shared/interfaces/base-token-service.interface';
import { baseRelatedCustomerLocationsActions, baseSharedActions } from '../actions';
import { baseAuthSelectors } from '../selectors';
import { BaseAvailableFileTypeEnum } from '../../shared/enums/base-available-file-type.enum';

@Injectable()
export class BaseRelatedCustomerLocationsEffects {
  loadRelatedCustomerLocations$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseRelatedCustomerLocationsActions.loadRelatedCustomerLocations),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((user) => user.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        return this.relatedCustomerLocationsService
          .relatedCustomerLocationList({
            psp: user.organizationId,
            page: action.payload?.page,
            size: action.payload?.size,
            search: action.payload?.search,
            'branches[]': action.payload?.branches,
          })
          .pipe(
            mergeMap((response: any) => [
              baseRelatedCustomerLocationsActions.setRelatedCustomerLocations({ payload: response }),
              baseRelatedCustomerLocationsActions.successRelatedCustomerLocations(),
            ]),
            catchError(() => of(baseRelatedCustomerLocationsActions.errorRelatedCustomerLocations()))
          );
      })
    );
  });

  loadRelatedCustomerLocationDetails$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseRelatedCustomerLocationsActions.loadRelatedCustomerLocationDetails),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((user) => user.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        return this.relatedCustomerLocationsService
          .relatedCustomerLocationDetails({
            psp: user.organizationId,
            customer: action.payload.customer,
            location: action.payload.location,
          })
          .pipe(
            mergeMap((response: any) => [
              baseRelatedCustomerLocationsActions.setRelatedCustomerLocationDetails({ payload: response }),
              baseRelatedCustomerLocationsActions.successRelatedCustomerLocationDetails(),
            ]),
            catchError(() => of(baseRelatedCustomerLocationsActions.errorRelatedCustomerLocationDetails()))
          );
      })
    );
  });

  loadRelatedCustomerLocationDocuments$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseRelatedCustomerLocationsActions.loadRelatedCustomerLocationDocuments),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((user) => user.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        return this.relatedCustomerLocationsService
          .documentsList({
            psp: user.organizationId,
            customer: action.payload.customer,
            location: action.payload.location,
          })
          .pipe(
            mergeMap((response: any) => [
              baseRelatedCustomerLocationsActions.setRelatedCustomerLocationDocuments({ payload: response }),
              baseRelatedCustomerLocationsActions.successRelatedCustomerLocationDocuments(),
            ]),
            catchError(() => of(baseRelatedCustomerLocationsActions.errorRelatedCustomerLocationDocuments()))
          );
      })
    );
  });

  downloadRelatedCustomerLocationDocument$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseRelatedCustomerLocationsActions.downloadRelatedCustomerLocationDocument),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((user) => user.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        return this.relatedCustomerLocationsService
          .downloadDocument({ ...action.payload, ...{ psp: user.organizationId } })
          .pipe(
            tap((response) => {
              if (!action.payload?.openInModal) {
                const blob = new Blob([response.body], { type: BaseAvailableFileTypeEnum.applicationPDF });
                const objectUrl = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.setAttribute('href', objectUrl);
                link.setAttribute('download', response.fileName);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
              }
            }),
            !action.payload?.openInModal
              ? map(() => baseRelatedCustomerLocationsActions.successDownloadRelatedCustomerLocationDocument())
              : mergeMap((response) => [
                  baseRelatedCustomerLocationsActions.setRelatedCustomerLocationDocument({
                    payload: {
                      document:
                        action.payload.documentType === BaseAvailableFileTypeEnum.pdf
                          ? new Blob([response.body], { type: BaseAvailableFileTypeEnum.applicationPDF })
                          : this.sanitizer.bypassSecurityTrustUrl(
                              URL.createObjectURL(
                                new Blob([response.body], { type: `image/${action.payload.documentType}` })
                              )
                            ),
                    },
                  }),
                  baseRelatedCustomerLocationsActions.successDownloadRelatedCustomerLocationDocument(),
                ]),
            catchError(() => of(baseRelatedCustomerLocationsActions.errorDownloadRelatedCustomerLocationDocument()))
          );
      })
    );
  });

  loadRelatedCustomerLocationDepartments$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseRelatedCustomerLocationsActions.loadDepartments),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((user) => user.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        return this.relatedCustomerLocationsService
          .departmentsList({
            psp: user.organizationId,
            customer: action.payload.customer,
            location: action.payload.location,
          })
          .pipe(
            mergeMap((response: any) => [
              baseRelatedCustomerLocationsActions.setDepartments({ payload: response }),
              baseRelatedCustomerLocationsActions.successDepartments(),
            ]),
            catchError(() => of(baseRelatedCustomerLocationsActions.errorDepartments()))
          );
      })
    );
  });

  loadRelatedCustomerLocationPriceList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseRelatedCustomerLocationsActions.loadRelatedCustomerLocationPriceList),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((user) => user.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        return this.relatedCustomerLocationsService
          .priceList({
            psp: user.organizationId,
            customer: action.payload.customer,
            tag: action.payload.tag,
            location: action.payload.location,
          })
          .pipe(
            mergeMap((response: any) => [
              baseRelatedCustomerLocationsActions.setRelatedCustomerLocationPriceList({ payload: response }),
              baseRelatedCustomerLocationsActions.successRelatedCustomerLocationPriceList(),
            ]),
            catchError(() => of(baseRelatedCustomerLocationsActions.errorRelatedCustomerLocationPriceList()))
          );
      })
    );
  });

  updateRelatedCustomerLocationPriceList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseRelatedCustomerLocationsActions.updateRelatedCustomerLocationPriceList),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((user) => user.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        return this.relatedCustomerLocationsService
          .updatePriceList({
            psp: user.organizationId,
            customer: action.payload.customer,
            location: action.payload.location,
            body: action.payload.body,
          })
          .pipe(
            mergeMap(() => [
              baseRelatedCustomerLocationsActions.loadRelatedCustomerLocationPriceList({
                payload: {
                  customer: action.payload.customer,
                  location: action.payload.location,
                  tag: action.payload.tag ? action.payload.tag : undefined,
                },
              }),
              baseSharedActions.loadPspPriceList({
                payload: {},
              }),
            ])
          );
      })
    );
  });

  loadRelatedCustomerLocationSurcharges$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseRelatedCustomerLocationsActions.loadSurcharges),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((user) => user.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        return this.relatedCustomerLocationsService
          .surchargesList({
            psp: user.organizationId,
            customer: action.payload.customer,
            location: action.payload.location,
          })
          .pipe(
            mergeMap((response: any) => [
              baseRelatedCustomerLocationsActions.setSurcharges({ payload: response }),
              baseRelatedCustomerLocationsActions.successSurcharges(),
            ]),
            catchError(() => of(baseRelatedCustomerLocationsActions.errorSurcharges()))
          );
      })
    );
  });

  constructor(
    private actions$: Actions,
    private store: Store<BaseCoreState>,
    private relatedCustomerLocationsService: BaseRelatedCustomerLocationsService,
    private sanitizer: DomSanitizer
  ) {}
}
