import { Component, Input, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { BaseInquiryScrollService } from '../../_services/base-inquiry-scroll.service';

@Component({
  selector: 'base-inquiry-horizontal-scroll',
  templateUrl: './base-inquiry-horizontal-scroll.component.html',
})
export class BaseInquiryHorizontalScrollComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() itemsLength = 0;
  currentDayPosition = 0;
  maxValue = 0;

  private scrollDebouncer: Subject<number> = new Subject<number>();
  private destroy$ = new Subject();

  constructor(private baseInquiryScrollService: BaseInquiryScrollService) {
    this.scrollDebouncer.pipe(debounceTime(200)).subscribe((newValue: number) => {
      this.onScrollTimeRange(newValue);
    });
  }

  ngOnInit() {
    this.baseInquiryScrollService.scrollOffset$.pipe(takeUntil(this.destroy$)).subscribe((offset) => {
      this.currentDayPosition = offset;
    });
  }

  ngAfterViewInit() {
    this.baseInquiryScrollService.visibleDatesQty$.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      setTimeout(() => {
        this.maxValue = this.itemsLength - value;
      }, 0);
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }

  onInputChange(event: Event) {
    const newValue = parseInt((event.target as HTMLInputElement).value, 10);
    this.scrollDebouncer.next(newValue);
  }

  onScrollTimeRange(newValue: number) {
    this.baseInquiryScrollService.setScrollOffset(newValue);
  }
}
