import produce from 'immer';
import { BaseTokenInterface } from '../../shared/interfaces/base-token-service.interface';
import { baseAuthActions } from '../actions';

export interface BaseAuthState {
  user: {
    data: BaseTokenInterface | undefined;
    ok: boolean | undefined;
  };
  refresh: boolean | undefined;
  isFirstTimeLogin: boolean | undefined;
  isCustomizedUser: boolean | undefined;
}

export const BASE_INITIAL_AUTH_STATE: BaseAuthState = {
  user: {
    data: undefined,
    ok: undefined,
  },
  refresh: undefined,
  isFirstTimeLogin: undefined,
  isCustomizedUser: undefined,
};

export function baseAuthReducer(state: BaseAuthState = BASE_INITIAL_AUTH_STATE, action: any): any {
  return produce(state, (draftState) => {
    switch (action.type) {
      // Login
      case baseAuthActions.loadToken.type:
        draftState.user.ok = undefined;
        return;
      case baseAuthActions.setUserInfoFromToken.type:
        draftState.user.data = action.payload;
        return;
      case baseAuthActions.successUserInfo.type:
        draftState.user.ok = true;
        return;
      case baseAuthActions.errorUserInfo.type:
        draftState.user.ok = false;
        return;
      case baseAuthActions.setInitState.type:
        /*
            Update each part of auth state separately, since
            draftState = INITIAL_AUTH_STATE;
            does not refresh state to initial value
        */
        draftState.user = BASE_INITIAL_AUTH_STATE.user;
        draftState.refresh = BASE_INITIAL_AUTH_STATE.refresh;
        return;
      case baseAuthActions.refreshUser.type:
        draftState.refresh = undefined;
        return;
      case baseAuthActions.successRefreshUser.type:
        draftState.refresh = true;
        return;
      case baseAuthActions.errorRefreshUser.type:
        draftState.refresh = false;
        return;

      case baseAuthActions.setFirstLoginIndicator.type:
        draftState.isFirstTimeLogin = action.payload;
        return;

      case baseAuthActions.setCustomizedUser.type:
        draftState.isCustomizedUser = action.payload;
        return;
    }
  });
}
