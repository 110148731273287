import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular-ivy';
import { EnvironmentUtils, BaseEnvironment } from 'base';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (EnvironmentUtils.getEnvironment() !== BaseEnvironment.dev) {
  Sentry.init({
    dsn: 'https://ad28fcd337f045798a6f1cc0f544c927@o462534.ingest.sentry.io/6522260',
    maxBreadcrumbs: 50,
    maxValueLength: 350,
    sendClientReports: false,
    integrations: [
      // new Sentry.BrowserTracing({
      //   tracePropagationTargets: [EnvironmentUtils.getStazzleBaseUrl(BaseApplication.growth)],
      //   routingInstrumentation: Sentry.routingInstrumentation,
      // }),
      new Sentry.Integrations.Breadcrumbs({
        // privacy protection remove xhr and history events
        xhr: EnvironmentUtils.getSentryXhrLogging(),
        history: EnvironmentUtils.getSentryHistoryLogging(),
      }),
    ],
    denyUrls: ['localhost:4202'],
    ignoreErrors: ['Non-Error exception captured', 'ResizeObserver loop limit exceeded'],
    environment: environment.env,
    release: environment.global.release,
    tracesSampleRate: 1.0,
    sampleRate: EnvironmentUtils.getEnvironment() === BaseEnvironment.prod ? 0.1 : 1.0,
  });
}

if (environment.global.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
