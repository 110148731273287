<div class="contract-viewer-toolbar">
  <div class="d-flex w-100">
    <h3
      class="fa-2x"
      baseTruncate
    >
      {{ fileName }}
    </h3>
    <div class="d-flex flex-grow-1 justify-content-between">
      <div
        *ngIf="isContractValid"
        class="d-flex d-flex px-4"
      >
        <base-button
          color="dark"
          icon="fa-solid fa-download"
          iconClass="btn-icon"
          (btnClick)="downloadContract()"
          [size]="buttonSizes.md"
          [iconSize]="buttonSizes.md"
          tooltip="action.download"
        ></base-button>

        <base-button
          *ngIf="isShowRegenerateButton"
          class="ps-2"
          color="dark"
          icon="fa-solid fa-arrows-rotate"
          iconClass="btn-icon"
          (btnClick)="handleContractRegeneration()"
          [size]="buttonSizes.md"
          [iconSize]="buttonSizes.md"
          tooltip="contractRegeneration.regenerateTooltip"
        ></base-button>
      </div>
      <div class="flex-grow-1 d-flex justify-content-end pe-6">
        <div>
          <base-customer-contract-dropdown
            [items]="contractsList"
            (changeHandler)="contractListChange($event)"
            [value]="selectedContract"
          ></base-customer-contract-dropdown>
        </div>
      </div>
    </div>
    <i
      class="fa-solid fa-circle-xmark pointer fa-2x ms-auto pt-2"
      (click)="modalRef?.close()"
    ></i>
  </div>
  <div class="mt-3 headline-16">
    <p
      class="m-0"
      *ngIf="isContractValid"
    >
      {{ 'contractRegeneration.validContractMessage' | translate }}
    </p>
    <p
      class="m-0 contract-message"
      [innerHTML]="createdByMessage"
    ></p>
  </div>
</div>

<ng-template #regenerateWarningRef>
  <div>{{ 'contractRegeneration.warning' | translate }}</div>

  <div
    class="collapsable-content mt-2 py-2"
    [ngClass]="{ 'is-collapsed': !isShowContractPreview }"
  >
    <div
      class="collapsable-header arrow-start mb-0 p-3"
      (click)="toggleContractPreview()"
    >
      <div>{{ 'contractRegeneration.contractPreviewMessage' | translate }}</div>
    </div>
    <div class="collapsable-body bg-lightest px-2">
      <iframe
        id="embedded"
        [src]="sanitizedSrc"
        class="w-100 vh-100"
      ></iframe>
    </div>
  </div>
</ng-template>
