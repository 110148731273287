import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BaseAvailableFileTypeEnum } from '../../../../enums/base-available-file-type.enum';
import { PdfSrcType } from '../../../../types/base-pdf-src.type';

@Component({
  selector: 'base-document-viewer',
  templateUrl: './base-document-viewer.component.html',
})
export class BaseDocumentViewerComponent {
  @Input() src: PdfSrcType = '';
  @Input() title: string | undefined = '';
  @Input() fileName = '';
  @Input() fileType: BaseAvailableFileTypeEnum | string = BaseAvailableFileTypeEnum.pdf;
  @Input() modalRef: NgbModalRef;
  @Input() isCustomBodyText = false;
  @Input() customBodyText: string;
  @Input() hideBorder = false;
  @Input() height = '90vh';

  @Output() downloadImage = new EventEmitter();

  baseAvailableFileTypeEnum = BaseAvailableFileTypeEnum;
}
