import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of, timer } from 'rxjs';
import { catchError, map, mergeMap, switchMap, take, takeUntil, tap, withLatestFrom, filter } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import {
  CustomerDepartmentQualificationsListResponse,
  DepartmentListItemResponse,
  DocumentCategoryResponse,
  NotificationsListResponse,
  PaginatedApiResponse,
  QualificationsListItemResponse,
  UserItemResponse,
  UserProfileResponse,
  UserProfileUpdateRequest,
} from '../../../api';
import { BaseLocalStorageService } from '../../core/services/base-local-storage.service';
import { BaseRolesService } from '../../core/services/base-roles.service';
import { baseAuthActions, baseSharedActions } from '../actions';
import { BaseCoreState } from '../reducers';
import { baseAuthSelectors, baseSharedSelectors } from '../selectors';
import { BaseToastAlertClasses } from '../../shared/enums/base-toast-alert-classes.enum';
import { BaseTokenInterface } from '../../shared/interfaces/base-token-service.interface';
import { BaseCountryService } from '../../shared/services/base-country.service';
import { BaseSharedService } from '../../shared/services/base-shared.service';

@Injectable()
export class BaseSharedEffects {
  loadCountryList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(baseSharedActions.loadCountryList),
      switchMap(() => {
        return this.countryService.list().pipe(
          mergeMap((response) => {
            return [baseSharedActions.setCountryList({ payload: response }), baseSharedActions.successCountryList()];
          }),
          catchError(() => {
            return of(baseSharedActions.errorCountryList());
          })
        );
      })
    )
  );

  loadQualifications$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseSharedActions.getQualifications),
      switchMap((action) => {
        return this.sharedService.loadQualifications(action.application).pipe(
          mergeMap((result) => [
            baseSharedActions.setQualifications({ payload: result }),
            baseSharedActions.successLoadQualifications(),
          ]),
          catchError(() => of(baseSharedActions.errorLoadQualifications()))
        );
      })
    );
  });

  loadQualificationCategories$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseSharedActions.getQualificationCategories),
      switchMap((action) => {
        return this.sharedService.loadQualificationsCategories(action.application).pipe(
          mergeMap((result) => [
            baseSharedActions.loadQualificationCategories({ payload: result }),
            baseSharedActions.successLoadQualificationCategories(),
          ]),
          catchError(() => of(baseSharedActions.errorLoadQualificationCategories()))
        );
      })
    );
  });

  loadCustomerDepartmentQualificationsList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseSharedActions.loadCustomerDepartmentQualificationsList),
      withLatestFrom(this.store.select(baseAuthSelectors.getUserInfo)),
      switchMap(([action, user]) => {
        return this.sharedService
          .getCustomerDepartmentQualificationsList(
            user.data!.organizationId,
            action.payload.department,
            action.payload.category,
            action.payload.tag
          )
          .pipe(
            mergeMap((response: CustomerDepartmentQualificationsListResponse[]) => [
              baseSharedActions.setCustomerDepartmentQualificationsList({ payload: response }),
              baseSharedActions.successCustomerDepartmentQualificationsList(),
            ]),
            catchError(() => of(baseSharedActions.errorCustomerDepartmentQualificationsList()))
          );
      })
    );
  });

  loadBranches$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseSharedActions.getBranches),
      withLatestFrom(this.store.select(baseAuthSelectors.getUserInfo)),
      switchMap(([action, user]) => {
        const source$ = this.rolesService.isPsp()
          ? this.sharedService.getPspBranches(user.data!.organizationId)
          : this.sharedService.getCustomerBranches(user.data!.organizationId);
        return source$.pipe(
          mergeMap((response) => [
            baseSharedActions.loadBranches({ payload: response }),
            baseSharedActions.successLoadBranches(),
          ]),
          catchError(() => of(baseSharedActions.errorLoadBranches()))
        );
      })
    );
  });

  loadProfile$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseSharedActions.loadProfileData),
      switchMap(() => {
        return this.sharedService.getProfile().pipe(
          mergeMap((response: UserProfileResponse | undefined) => [
            baseSharedActions.setProfileData({ payload: response }),
            baseSharedActions.successProfileData(),
          ]),
          catchError(() => of(baseSharedActions.errorProfileData()))
        );
      })
    );
  });

  updateProfile$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseSharedActions.updateProfileData),
      switchMap((action) => {
        const preparedData = this.sharedService.prepareUserProfileUpdateData<UserProfileUpdateRequest>(action.payload);
        return this.sharedService.updateProfile(preparedData).pipe(
          mergeMap(() => {
            let translationKey = 'auth.profileRegarding.updatedSuccessfully';
            if (!!preparedData.password) {
              translationKey = 'auth.password.updatedSuccessfully';
            }
            return [
              baseSharedActions.loadProfileData(),
              baseSharedActions.addSystemAlert({
                payload: {
                  class: BaseToastAlertClasses.success,
                  body: translationKey,
                },
              }),
            ];
          }),
          catchError(() => of(baseSharedActions.errorProfileData()))
        );
      })
    );
  });

  updateProfileSettings$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseSharedActions.updateProfileSettings),
      switchMap((action) => {
        return this.sharedService.updateUserSettings(action.payload).pipe(
          map(() => baseSharedActions.loadProfileData()),
          catchError(() => of(baseSharedActions.errorProfileData()))
        );
      })
    );
  });

  loadRequirements$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseSharedActions.getRequirements),
      switchMap((action) => {
        return this.sharedService.loadRequirements(action.application).pipe(
          take(1),
          mergeMap((requirements) => [
            baseSharedActions.loadRequirements({ payload: requirements }),
            baseSharedActions.successLoadRequirements(),
          ]),
          catchError(() => of(baseSharedActions.errorLoadRequirements()))
        );
      })
    );
  });

  loadCustomerDepartmentList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseSharedActions.loadCustomerDepartmentList),
      withLatestFrom(this.store.select(baseAuthSelectors.getUserInfo)),
      switchMap(([action, user]) => {
        return this.sharedService.getCustomerDepartmentList(user.data!.organizationId, action.payload.active).pipe(
          mergeMap((response: DepartmentListItemResponse[]) => [
            baseSharedActions.setCustomerDepartmentList({ payload: response }),
            baseSharedActions.successCustomerDepartmentList(),
          ]),
          catchError(() => of(baseSharedActions.errorCustomerDepartmentList()))
        );
      })
    );
  });

  loadCustomerDepartmentUsers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseSharedActions.getCustomerDepartmentUsers),
      withLatestFrom(this.store.select(baseAuthSelectors.getUserInfo)),
      switchMap(([action, { data }]) => {
        return this.sharedService.getCustomerDepartmentUsers(data?.organizationId || -1, action.payload).pipe(
          mergeMap((response: UserItemResponse[]) => [
            baseSharedActions.loadCustomerDepartmentUsers({ payload: response }),
            baseSharedActions.successCustomerDepartmentUsers(),
          ]),
          catchError(() => of(baseSharedActions.errorCustomerDepartmentUsers()))
        );
      })
    );
  });

  loadDocumentCategories$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseSharedActions.loadDocumentCategories),
      switchMap(() => {
        return this.sharedService.getDocumentCategories().pipe(
          mergeMap((response: DocumentCategoryResponse) => [
            baseSharedActions.setDocumentCategories({ payload: response }),
            baseSharedActions.successDocumentCategories(),
          ]),
          catchError(() => of(baseSharedActions.errorDocumentCategories()))
        );
      })
    );
  });

  loadPspPriceList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseSharedActions.loadPspPriceList),
      withLatestFrom(this.store.select(baseAuthSelectors.getUserInfo)),
      switchMap(([action, user]) => {
        return this.sharedService
          .getPspPriceList({
            psp: user.data?.organizationId as number,
            tag: action.payload.tag,
          })
          .pipe(
            mergeMap((response) => {
              return [
                baseSharedActions.setPspPriceList({ payload: response }),
                baseSharedActions.successPspPriceList(),
              ];
            }),
            catchError(() => {
              return of(baseSharedActions.errorPspPriceList());
            })
          );
      })
    );
  });

  loadDefaultShiftTimes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(baseSharedActions.fetchDefaultShiftTimes),
      switchMap(() => {
        return this.sharedService.getDefaultShiftsTimes().pipe(
          mergeMap((response) => [
            baseSharedActions.loadDefaultShiftTimes({ payload: response }),
            baseSharedActions.loadDefaultShiftTimesSuccess(),
          ]),
          catchError(() => of(baseSharedActions.loadDefaultShiftTimesError()))
        );
      })
    )
  );

  loadPSPCustomerSurcharges$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseSharedActions.loadPSPCustomerSurcharges),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((user) => user?.data as BaseTokenInterface))
      ),
      filter(([action, user]) => !!user),
      switchMap(([action, user]) => {
        return this.sharedService
          .pspCustomerSurchargesList({
            psp: user.organizationId,
            customer: action.payload.customerId,
            location: action.payload.locationId,
          })
          .pipe(
            mergeMap((response: any) => [
              baseSharedActions.setPSPCustomerSurcharges({ payload: response }),
              baseSharedActions.successPSPCustomerSurcharges(),
            ]),
            catchError(() => of(baseSharedActions.errorPSPCustomerSurcharges()))
          );
      })
    );
  });

  loadUserNotifications$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseSharedActions.loadUserNotifications),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((user) => user?.data as BaseTokenInterface))
      ),
      switchMap(([action, _user]) => {
        return this.sharedService.loadUserNotifications(action.payload).pipe(
          mergeMap((response: PaginatedApiResponse & { data?: NotificationsListResponse[] }) => [
            baseSharedActions.setUserNotifications({ payload: response }),
            baseSharedActions.successUserNotifications(),
          ]),
          catchError(() => of(baseSharedActions.errorUserNotifications()))
        );
      })
    );
  });

  setNotificationsReadState$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseSharedActions.setNotificationReadState),
      switchMap((action) => {
        return this.sharedService
          .setNotificationReadState([action.payload.notification])
          .pipe(
            map((_) => baseSharedActions.loadUserNotifications({ payload: { page: action.page, size: action.size } }))
          );
      })
    );
  });

  setAllUserNotificationsAsRead$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseSharedActions.setAllUserNotificationsAsRead),
      switchMap(() => {
        return this.sharedService
          .setAllUserNotificationsReadStatus()
          .pipe(map((_) => baseSharedActions.loadUserNotifications({ payload: {} })));
      })
    );
  });

  refreshNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(baseAuthActions.successUserInfo, baseSharedActions.successUserNotifications),
      switchMap(() => {
        return timer(120000).pipe(
          mergeMap(() => [baseSharedActions.loadUserNotifications({ payload: {} })]),
          takeUntil(
            this.actions$.pipe(
              ofType(
                baseAuthActions.setInitState,
                baseAuthActions.logout,
                baseAuthActions.errorRefreshUser,
                baseSharedActions.errorUserNotifications
              )
            )
          )
        );
      })
    )
  );

  loadCustomerDepartmentFavouriteQualificationList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseSharedActions.loadCustomerDepartmentFavouriteQualificationList),
      withLatestFrom(this.store.select(baseAuthSelectors.getUserInfo)),
      switchMap(([action, user]) => {
        return this.sharedService
          .customerDepartmentFavouriteQualificationList({
            department: action.payload.department,
            location: action.payload.location,
            customer: user.data?.organizationId as number,
          })
          .pipe(
            mergeMap((response: QualificationsListItemResponse[]) => [
              baseSharedActions.setCustomerDepartmentFavouriteQualificationList({ payload: response }),
              baseSharedActions.successCustomerDepartmentFavouriteQualificationList(),
            ]),
            catchError(() => of(baseSharedActions.errorCustomerDepartmentFavouriteQualificationList()))
          );
      })
    );
  });

  setLanguage = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(baseSharedActions.setLanguage),
        tap((action) => {
          this.localStorageService.set('language', action.language);
          this.translateService.use(action.language);
          this.document.documentElement.lang = action.language;
          this.store.dispatch(baseSharedActions.setLanguageSuccess({ language: action.language }));
        })
      );
    },
    { dispatch: false }
  );

  addUniqueSystemAlert = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(baseSharedActions.addUniqueSystemAlert),
        withLatestFrom(this.store.select(baseSharedSelectors.getSystemAlertsData)),
        tap(([action, alerts]) => {
          if (action.payload.unique) {
            const duplicatedAlert = alerts.find(
              (item) => item.unique && item.body === action.payload.body && item.class === action.payload.class
            );

            if (!duplicatedAlert) {
              this.store.dispatch(baseSharedActions.addSystemAlert({ payload: action.payload }));
            }
          }
        })
      );
    },
    { dispatch: false }
  );

  loadBranchUsers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseSharedActions.loadBranchUsers),
      withLatestFrom(this.store.select(baseAuthSelectors.getUserInfo)),
      switchMap(([action, userInfo]) => {
        return this.sharedService.getPspBranchUsers(userInfo.data!.organizationId, action.payload.branch).pipe(
          mergeMap((response) => [
            baseSharedActions.setBranchUsers({ payload: response }),
            baseSharedActions.successBranchUsers(),
          ]),
          catchError(() => of(baseSharedActions.errorBranchUsers()))
        );
      })
    );
  });

  loadIndustrySurcharges$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseSharedActions.loadIndustrySurcharges),
      switchMap(() => {
        return this.sharedService.getIndustrySurchargesList().pipe(
          mergeMap((response) => [
            baseSharedActions.setIndustrySurcharges({ payload: response }),
            baseSharedActions.successIndustrySurcharges(),
          ]),
          catchError(() => of(baseSharedActions.errorIndustrySurcharges()))
        );
      })
    );
  });

  constructor(
    private actions$: Actions,
    private store: Store<BaseCoreState>,
    private countryService: BaseCountryService,
    private rolesService: BaseRolesService,
    private readonly sharedService: BaseSharedService,
    private readonly localStorageService: BaseLocalStorageService,
    private readonly translateService: TranslateService,
    @Inject(DOCUMENT) private document: Document
  ) {}
}
