import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Category, QualificationPricingListItemResponse } from '../../../api';
import { BasePspPriceListState } from '../reducers/base-psp-price-list.reducer';

const getPspPriceListState = createFeatureSelector('pspPriceList');

export const getPspPriceList = createSelector(getPspPriceListState, (state: any): BasePspPriceListState => state);

export const getPriceListCategories = createSelector(
  getPspPriceList,
  (state: BasePspPriceListState): { data: Category[]; ok: boolean | undefined } => state.categories
);

export const getCategoryQualifications = createSelector(
  getPspPriceList,
  (state: BasePspPriceListState): { data: QualificationPricingListItemResponse[]; ok: boolean | undefined } =>
    state.qualifications
);
