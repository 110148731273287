import { Component, OnDestroy, OnInit } from '@angular/core';
import { InquiryResponse, ShiftTimeResponse } from '../../../../../api';
import { Subject } from 'rxjs';
import {
  ShiftTimeAndFlexResponse,
  BaseShiftIconsEnum,
  BaseInquiryShiftsWithFlex,
} from '../../_enums/base-inquiry.enum';
import { BaseInquiryShiftService } from '../../_services/base-inquiry-shift.service';
import { BaseInquiryService } from '../../_services/base-inquiry.service';
import { BaseInquiryDate } from '../../_models/base-inquiry-date';
import { BaseInquiryDateService } from '../../_services/base-inquiry-date.service';
import { takeUntil } from 'rxjs/operators';
import { MIN_CALENDAR_COLLAPSE_HEIGHT } from '../../../../shared/constants/base-inquiry-viewport.const';

@Component({
  selector: 'base-inquiry-calendar',
  templateUrl: 'base-inquiry-calendar.component.html',
})
export class BaseInquiryCalendarComponent implements OnInit, OnDestroy {
  isSticky = false;
  datesArray: BaseInquiryDate[];
  inquiry: Partial<InquiryResponse> = {};
  shiftTimes: (ShiftTimeResponse & { index: number; label?: string })[] = [];
  shiftTimesWithFlex: (ShiftTimeAndFlexResponse & { index: number; label?: string })[] = [];
  isVisibleCalendar = false;

  baseShiftIconsEnum = BaseShiftIconsEnum;
  inquiryShiftTypes = BaseInquiryShiftsWithFlex;

  private destroy$ = new Subject();

  constructor(
    private baseInquiryShiftService: BaseInquiryShiftService,
    private baseInquiryService: BaseInquiryService,
    private baseInquiryDateService: BaseInquiryDateService
  ) {}

  ngOnInit() {
    this.getInquiry();
    this.getDates();
    this.getShiftTimesWithFlex();
    this.setViewportHeight();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private getInquiry() {
    this.baseInquiryService.inquirySubject$
      .pipe(takeUntil(this.destroy$))
      .subscribe((inquiry: InquiryResponse) => (this.inquiry = inquiry));
  }

  private getShiftTimesWithFlex() {
    this.baseInquiryShiftService.shiftTimesWithFlexSubject$
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (shiftTimesWithFlex: (ShiftTimeAndFlexResponse & { index: number; label?: string })[]) =>
          (this.shiftTimesWithFlex = shiftTimesWithFlex)
      );
  }

  private getDates() {
    this.baseInquiryDateService.datesArraySubject$
      .pipe(takeUntil(this.destroy$))
      .subscribe((datesArray: BaseInquiryDate[]) => (this.datesArray = datesArray));
  }

  toggleCalendar() {
    this.isVisibleCalendar = !this.isVisibleCalendar;
  }

  private setViewportHeight() {
    const viewportHeight = window.innerHeight || document.documentElement.clientHeight;
    this.isVisibleCalendar = viewportHeight > MIN_CALENDAR_COLLAPSE_HEIGHT;
  }
}
