import { Injectable } from '@angular/core';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class BaseSpinnerService {
  constructor(private loadingBarService: LoadingBarService) {}

  private loadingCount = 0;
  private showSpinnerSource: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public showSpinner$ = this.showSpinnerSource.asObservable();

  public startLoading(): void {
    if (this.loadingCount === 0) {
      this.loadingCount++;
      this.showSpinnerSource.next(true);
      this.loadingBarService.start();
      return;
    }
    this.loadingCount++;
  }

  public stopLoading(): void {
    this.loadingCount--;
    if (this.loadingCount === 0) {
      this.loadingBarService.complete();
      this.showSpinnerSource.next(false);
    }
  }
}
