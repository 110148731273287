import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbAccordionModule, NgbModalModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { baseAdministrationEffects, baseAdministrationReducer } from '../../_store';
import { AppsSharedModule } from '../../apps-shared/apps-shared.module';
import { BaseSharedModule } from '../../shared/base-shared.module';
import { BaseAdministrationShiftFormComponent } from './_components/base-administration-shift-form/base-administration-shift-form.component';
import { BaseAdministrationComponent } from './_components/base-administration/base-administration.component';
import { BaseAddressFormComponent } from './_components/base-master-data/base-address-form/base-address-form.component';
import { BaseGeneralInfoFormComponent } from './_components/base-master-data/base-general-info-form/base-general-info-form.component';
import { BaseMasterDataDocumentComponent } from './_components/base-master-data/base-master-data-document/base-master-data-document.component';
import { BaseOrganisationManagementListHeadlineComponent } from './_components/base-organisation-management/base-organisation-management-list-headline/base-organisation-management-list-headline.component';
import { BaseOrganisationManagementListItemComponent } from './_components/base-organisation-management/base-organisation-management-list-item/base-organisation-management-list-item.component';
import { BaseOrganisationManagementComponent } from './_components/base-organisation-management/base-organisation-management/base-organisation-management.component';
import { BaseProfileManagementComponent } from './_components/base-profile-management/base-profile-management.component';
import { BaseTermsOfContractSurchargeComponent } from './_components/base-terms-of-contract/base-terms-of-contract-surcharge/base-terms-of-contract-surcharge.component';
import { BaseTermsOfContractComponent } from './_components/base-terms-of-contract/base-terms-of-contract.component';
import { BaseChangeUserPasswordFormComponent } from './_components/base-user-management/base-change-user-password-form/base-change-user-password-form.component';
import { BaseEditPermissionGroupsFormComponent } from './_components/base-user-management/base-edit-permission-groups-form/base-edit-permission-groups-form.component';
import { BaseEditUserFormComponent } from './_components/base-user-management/base-edit-user-form/base-edit-user-form.component';
import { BaseInviteUserFormComponent } from './_components/base-user-management/base-invite-user-form/base-invite-user-form.component';
import { BasePermissionGroupsComponent } from './_components/base-user-management/base-permission-groups/base-permission-groups.component';
import { BaseUserListItemComponent } from './_components/base-user-management/base-user-list-item/base-user-list-item.component';
import { BaseUserManagementComponent } from './_components/base-user-management/base-user-management/base-user-management.component';
import { BaseLocationAddEditComponent } from './_components/cust-organization-management/_modals/base-location-edit/base-location-add-edit.component';
import { BaseLocationDepartmentItemComponent } from './_components/cust-organization-management/base-location-department-item/base-location-department-item.component';
import { BaseLocationItemComponent } from './_components/cust-organization-management/base-location-item/base-location-item.component';
import { BaseDepartmentModalComponent } from './_components/cust-organization-management/_modals/base-department-modal/base-department-modal.component';
import { BaseDepartmentFavoritesComponent } from './_components/cust-organization-management/_modals/base-department-modal/components/base-department-favorites/base-department-favorites.component';
import { BaseFavoritesListItemComponent } from './_components/cust-organization-management/_modals/base-department-modal/components/base-department-favorites/components/base-favorites-list-item/base-favorites-list-item.component';
import { BaseDepartmentMasterDataComponent } from './_components/cust-organization-management/_modals/base-department-modal/components/base-department-master-data/base-department-master-data.component';
import { BaseDepartmentOperationInformationComponent } from './_components/cust-organization-management/_modals/base-department-modal/components/base-department-operation-information/base-department-operation-information.component';
import { BaseDepartmentUserComponent } from './_components/cust-organization-management/_modals/base-department-modal/components/base-department-user/base-department-user.component';
import { BaseDepartmentUserItemComponent } from './_components/cust-organization-management/_modals/base-department-modal/components/base-department-user/base-user-item/base-department-user-item.component';
import { BaseLocationComponent } from './_components/cust-organization-management/base-location/base-location.component';
import { BaseOrganizationManagementGuard } from './_guards/base-organization-management.guard';
import { BasePermissionsGuard } from './_guards/base-permissions.guard';
import { BaseAdministrationFormComponent } from './_models/base-administration-form';
import { BaseAdministrationService } from './base-administration.service';
import { BaseLocationFormComponent } from './_components/cust-organization-management/_modals/base-location-edit/base-location-form/base-location-form.component';
import { BaseEmployeeLeaseComponent } from './_components/cust-organization-management/_modals/base-location-edit/base-employee-lease-form/base-employee-lease.component';
import { BaseContractTermsComponent } from './_components/base-contract-terms/base-contract-terms.component';
import { BaseSurchargeItemComponent } from './_components/base-contract-terms/base-surcharge-item/base-surcharge-item.component';
import { BaseLocationDocumentsListComponent } from './_components/cust-organization-management/_modals/base-location-edit/base-location-documents-list/base-location-documents-list.component';
import { BaseLocationDocumentComponent } from './_components/cust-organization-management/_modals/base-location-edit/base-location-documents-list/base-location-document/base-location-document.component';
import { BaseDigitalSignatureComponent } from './_components/base-digital-signature/base-digital-signature.component';
import { BaseAssignmentsModule } from '../assignments/base-assignments.module';
import { BasePermissionCheckboxTemplateComponent } from './_components/base-user-management/base-permission-checkbox-template/base-permission-checkbox-template.component';
import { BaseLocationTimeRecordingComponent } from './_components/cust-organization-management/_modals/base-location-edit/base-location-time-recording/base-location-time-recording.component';
import { BaseCreateEditLocationTerminalComponent } from './_components/cust-organization-management/_modals/base-location-edit/base-location-time-recording/base-create-edit-location-terminal/base-create-edit-location-terminal.component';
import { BaseLocationTerminalComponent } from './_components/cust-organization-management/base-location-terminal/base-location-terminal.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    BaseSharedModule,
    BaseAssignmentsModule,
    AppsSharedModule,
    NgbAccordionModule,
    NgbTooltipModule,
    NgbModalModule,
    StoreModule.forFeature('administration', baseAdministrationReducer.baseAdministrationReducer),
    EffectsModule.forFeature([baseAdministrationEffects.BaseAdministrationEffects]),
  ],
  declarations: [
    BaseAdministrationFormComponent,
    BaseAdministrationComponent,
    BaseUserManagementComponent,
    BasePermissionGroupsComponent,
    BaseUserListItemComponent,
    BaseInviteUserFormComponent,
    BaseEditUserFormComponent,
    BaseEditPermissionGroupsFormComponent,
    BaseTermsOfContractComponent,
    BaseContractTermsComponent,
    BaseTermsOfContractSurchargeComponent,
    BaseSurchargeItemComponent,
    BaseProfileManagementComponent,
    BaseGeneralInfoFormComponent,
    BaseAddressFormComponent,
    BaseMasterDataDocumentComponent,
    BaseLocationComponent,
    BaseLocationItemComponent,
    BaseAdministrationShiftFormComponent,
    BaseOrganisationManagementComponent,
    BaseOrganisationManagementListItemComponent,
    BaseOrganisationManagementListHeadlineComponent,
    BaseChangeUserPasswordFormComponent,
    BaseLocationDepartmentItemComponent,
    BaseLocationAddEditComponent,
    BaseDepartmentModalComponent,
    BaseDepartmentFavoritesComponent,
    BaseFavoritesListItemComponent,
    BaseDepartmentMasterDataComponent,
    BaseDepartmentOperationInformationComponent,
    BaseDepartmentUserComponent,
    BaseDepartmentUserItemComponent,
    BaseLocationFormComponent,
    BaseEmployeeLeaseComponent,
    BaseLocationDocumentsListComponent,
    BaseLocationDocumentComponent,
    BaseDigitalSignatureComponent,
    BasePermissionCheckboxTemplateComponent,
    BaseLocationTimeRecordingComponent,
    BaseCreateEditLocationTerminalComponent,
    BaseLocationTerminalComponent,
  ],
  exports: [
    BaseAdministrationFormComponent,
    BaseAdministrationComponent,
    BaseUserManagementComponent,
    BasePermissionGroupsComponent,
    BaseUserListItemComponent,
    BaseInviteUserFormComponent,
    BaseEditUserFormComponent,
    BaseEditPermissionGroupsFormComponent,
    BaseProfileManagementComponent,
    BaseTermsOfContractComponent,
    BaseTermsOfContractSurchargeComponent,
    BaseLocationComponent,
    BaseLocationItemComponent,
    BaseAdministrationShiftFormComponent,
    BaseGeneralInfoFormComponent,
    BaseAddressFormComponent,
    BaseMasterDataDocumentComponent,
    BaseOrganisationManagementComponent,
    BaseOrganisationManagementListItemComponent,
    BaseOrganisationManagementListHeadlineComponent,
    BaseChangeUserPasswordFormComponent,
    BaseDepartmentModalComponent,
    BaseDepartmentFavoritesComponent,
    BaseFavoritesListItemComponent,
    BaseDepartmentMasterDataComponent,
    BaseDepartmentOperationInformationComponent,
    BaseDepartmentUserComponent,
    BaseDepartmentUserItemComponent,
    BaseDigitalSignatureComponent,
    BasePermissionCheckboxTemplateComponent,
    BaseLocationTimeRecordingComponent,
    BaseCreateEditLocationTerminalComponent,
  ],
  providers: [BaseAdministrationService, BasePermissionsGuard, BaseOrganizationManagementGuard],
})
export class BaseAdministrationModule {}
