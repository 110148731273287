import { Component } from '@angular/core';

@Component({
  selector: 'base-imprint-en',
  template: `
    <h1 class="headline-36 mb-4">Imprint</h1>
    <div>
      <h2 class="headline-24">Publisher and responsible for the content:</h2>
      <p>
        HR Software Solutions GmbH<br />
        Adenauerplatz 1<br />
        33602 Bielefeld
      </p>

      <p><b class="fw-bold">E-mail:</b> kontakt@stazzle.de</p>
      <p><b class="fw-bold">Phone:</b> +49 5218 9880 20</p>
      <p><b class="fw-bold">Management:</b> Marco Schmidt</p>
      <p><b class="fw-bold">Responsible for the content:</b> Marco Schmidt</p>
      <p><b class="fw-bold">Register court:</b> Local court Bielefeld</p>
      <p><b class="fw-bold">Registration number:</b> HRB 43485</p>
      <p><b class="fw-bold">Sales tax identification number:</b> DE325481466</p>

      <p>
        <b class="fw-bold">Liability Notice:</b> <br />
        Despite careful control of the content, we assume no liability for the content of external links. The operators
        of the linked pages are solely responsible for their content.
      </p>

      <p>
        For reasons of better readability, we have refrained from consistently using the masculine and feminine forms.
        Of course, all genders are meant.
      </p>

      <p><b class="fw-bold">Out-of-court dispute resolution:</b></p>

      <p>
        We do not participate in dispute resolution proceedings before a consumer arbitration board. Copyright The
        content and works created by the operator of this site on these web pages are subject to German copyright law.
        All contributions by third parties are marked as such. Duplication, processing, distribution, or any form of
        commercialization of such material beyond the scope of the copyright law shall require the prior written consent
        of its respective author or creator. Copies from these pages are only permitted for private use, but not for
        commercial purposes.
      </p>

      <p><b class="fw-bold">Image credits:</b></p>

      <p>Copyright HR Software Solutions GmbH</p>
    </div>
  `,
})
export class ImprintEnComponent {}
