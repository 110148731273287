import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { BaseRelatedPspBranchDetailsGuard } from './_guards/base-related-psp-branch-details.guard';
import { BaseRelatedPspBranchListGuard } from './_guards/base-related-psp-branch-list.guard';
import { baseRelatedPspBranchesEffects, baseRelatedPspBranchReducer } from '../../_store';
import { BaseRelatedPspBranchesService } from './base-related-psp-branches.service';
import { BaseActiveLocationListGuard } from './_guards/base-active-location-list.guard';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('relatedPspBranches', baseRelatedPspBranchReducer.BaseRelatedPspBranchesReducer),
    EffectsModule.forFeature([baseRelatedPspBranchesEffects.BaseRelatedPspBranchesEffects]),
  ],
  providers: [
    BaseRelatedPspBranchesService,
    BaseRelatedPspBranchListGuard,
    BaseRelatedPspBranchDetailsGuard,
    BaseActiveLocationListGuard,
  ],
})
export class BaseRelatedPspBranchesModule {}
