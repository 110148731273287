import { Component, Output, EventEmitter, Input } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'base-document-viewer-toolbar',
  templateUrl: './base-document-viewer-toolbar.component.html',
})
export class BaseDocumentViewerToolbarComponent {
  @Input() title: string | undefined = '';
  @Input() modalRef: NgbModalRef;
  @Output() downloadImage = new EventEmitter<any>();
}
