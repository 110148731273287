import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[baseRemoveMailTo]',
})
/**
 * Simple directive for input elements. If the input value contain the "mailto:"
 * then the string "mailto:" will be removed when the user types or pastes in the field<br/>
 * Available inputs: <br/>
 * - baseRemoveMailTo
 *  <br/>
 *  Please see description of each input to read about usage information
 */
export class BaseRemoveMailtoDirective {
  /**
   * Is it email input field?; <br/>
   */
  @Input() baseRemoveMailTo: boolean;
  constructor(private elementRef: ElementRef<HTMLInputElement>, private control: NgControl) {}

  @HostListener('input') onInput() {
    const value = this.elementRef.nativeElement.value.toLowerCase();
    if (value.includes('mailto:') && this.baseRemoveMailTo) {
      this.control.control?.setValue(value.replace('mailto:', ''));
    }
  }
}
