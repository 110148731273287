import { createAction, props } from '@ngrx/store';
import {
  CustomerDepartmentAddFavoriteQualificationsRequest,
  CustomerDepartmentAddRequirementsRequest,
  CustomerDepartmentCreateRequest,
  CustomerDepartmentQualificationCreateRequest,
  CustomerDepartmentResponse,
  CustomerDepartmentUpdateRequest,
  CustomerDepartmentUpdateShiftTimesRequest,
  UpdateUsersRequest,
  DepartmentListItemResponse,
  RequirementResponse,
  UserItemResponse,
  PermissionItemResponse,
} from '../../../api';
import { BaseApplicationTagType } from '../../shared/enums/base-application.enum';

// Department
export const loadCustomerDepartment = createAction(
  '[Customer Department] Load department info',
  props<{ payload: { department: number; customer: number } }>()
);
export const setCustomerDepartment = createAction(
  '[Customer Department] Set department info',
  props<{ payload: CustomerDepartmentResponse }>()
);
export const successCustomerDepartment = createAction('[Customer Department] Success department info');
export const errorCustomerDepartment = createAction('[Customer Department] Error department info');

// Customer users
export const loadCustomerUsers = createAction(
  '[Customer Department] Load customer users',
  props<{ payload: number }>()
);
export const setCustomerUsers = createAction(
  '[Customer Department] Set customer users',
  props<{ payload: UserItemResponse[] }>()
);
export const successCustomerUsers = createAction('[Customer Department] Success customer users');
export const errorCustomerUsers = createAction('[Customer Department] Error customer users');

// Active customer user list
export const loadCustomerActiveUserList = createAction(
  '[Customer Department] Load customer active user list',
  props<{ payload?: { search?: string } }>()
);
export const setCustomerActiveUserList = createAction(
  '[Customer Department] Set active user list',
  props<{ payload: PermissionItemResponse[] | undefined }>()
);
export const successCustomerActiveUserList = createAction('[Customer Department] Success customer active user list');
export const errorCustomerActiveUserList = createAction('[Customer Department] Error customer active user list');

// Update users
export const updateUsersToCustomerDepartment = createAction(
  '[Customer Department] Update users',
  props<{
    payload: { department: number; customer: number; location: number; state?: boolean; body: UpdateUsersRequest };
  }>()
);

// Update master data
export const updateCustomerDepartmentMasterData = createAction(
  '[Customer Department] Update master data',
  props<{
    payload: {
      department: number;
      customer: number;
      location: number;
      state?: boolean;
      body: CustomerDepartmentUpdateRequest;
    };
  }>()
);

// Update shift times
export const updateCustomerDepartmentShiftTimes = createAction(
  '[Customer Department] Update shift times',
  props<{
    payload: {
      department: number;
      customer: number;
      location: number;
      state?: boolean;
      body: CustomerDepartmentUpdateShiftTimesRequest;
    };
  }>()
);

// Add requirements
export const addCustomerDepartmentRequirements = createAction(
  '[Customer Department] Add requirements',
  props<{
    payload: {
      department: number;
      customer: number;
      location: number;
      state?: boolean;
      body: CustomerDepartmentAddRequirementsRequest;
    };
  }>()
);

// Delete requirement
export const deleteCustomerDepartmentRequirement = createAction(
  '[Customer Department] Delete requirement',
  props<{ payload: { department: number; customer: number; location: number; state?: boolean; requirement: number } }>()
);

// Requirement categories
export const loadRequirementCategories = createAction(
  '[Customer Department] Load requirement categories',
  props<{ payload: { tag?: BaseApplicationTagType } }>()
);
export const setRequirementCategories = createAction(
  '[Customer Department] Set requirement categories',
  props<{ payload: RequirementResponse[] }>()
);
export const successRequirementCategories = createAction('[Customer Department] Success requirement categories');
export const errorRequirementCategories = createAction('[Customer Department] Error requirement categories');

// Create department
export const createDepartmentCrud = createAction(
  '[Customer Department] Create department',
  props<{ payload: { customer: number; location: number; state?: boolean; body: CustomerDepartmentCreateRequest } }>()
);
export const setDepartmentCrud = createAction(
  '[Customer Department] Set department',
  props<{ payload: DepartmentListItemResponse }>()
);
export const successDepartmentCrud = createAction('[Customer Department] Success create department');
export const errorDepartmentCrud = createAction('[Customer Department] Error create department');

// Add favourite qualifications to department
export const addFavouriteQualifications = createAction(
  '[Customer Department] Add favourite qualifications to department',
  props<{
    payload: {
      department: number;
      location: number;
      body: CustomerDepartmentAddFavoriteQualificationsRequest;
    };
  }>()
);
export const addFavouriteQualificationsSuccess = createAction(
  '[Customer Department] Success add favourite qualifications to department'
);
export const addFavouriteQualificationsError = createAction(
  '[Customer Department] Error add favourite qualifications to department'
);

// Delete favourite qualification from department
export const deleteFavouriteQualification = createAction(
  '[Customer Department] Delete favourite qualification from department',
  props<{ payload: { department: number; qualification: number; location: number } }>()
);
export const deleteFavouriteQualificationSuccess = createAction(
  '[Customer Department] Success delete favourite qualification from department'
);
export const deleteFavouriteQualificationError = createAction(
  '[Customer Department] Error delete favourite qualification from departmentt'
);

// Update customer department qualifications job description
export const updateDepartmentQualificationDescription = createAction(
  '[Customer Department] Update customer department qualifications job description',
  props<{
    payload: {
      qualification: number;
      department: number;
      body: CustomerDepartmentQualificationCreateRequest;
      location: number;
    };
  }>()
);
