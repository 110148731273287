import { release } from './release';

export const globalEnvironment = {
  production: true,
  staging: false,
  demo: false,
  apiUrl: 'https://api2.stazzle.de',
  storage: 'LocalStorage',
  defaultLanguage: 'de',
  release: release,
  sendLogs: true,
};
