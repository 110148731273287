import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import {
  ApplyRequest,
  AuthenticationSuccessfulResponse,
  AuthService,
  CreatedResponse,
  CustomerRegistrationRequest,
  CustomerService,
  EmptySuccessfulResponse,
  PspRegistrationRequest,
  PspService,
  ResetRequest,
  UserRegisterCheckResponse,
  UserRegistrationRequest,
  UserService,
} from '../../../api';
import { BaseApplication } from '../../shared/enums/base-application.enum';
import { AuthenticationRequestApp } from './_models/athentication-request-app.model';

@Injectable()
export class BaseAuthenticationService {
  constructor(
    private authService: AuthService,
    private customerService: CustomerService,
    private pspService: PspService,
    private userService: UserService
  ) {}

  login(data: AuthenticationRequestApp): Observable<AuthenticationSuccessfulResponse> {
    switch (data?.application) {
      case BaseApplication.medical:
      default:
        return this.authService.postAppApiAuthAuthMedicallogin({
          body: { username: data.username, password: data.password },
        });
      case BaseApplication.growth:
        return this.authService.postAppApiAuthAuthPsplogin({
          body: { username: data.username, password: data.password },
        });
      case BaseApplication.business:
        return this.authService.postAppApiAuthAuthBusinesslogin({
          body: { username: data.username, password: data.password },
        });
    }
  }

  refresh(token: string): Observable<AuthenticationSuccessfulResponse> {
    return this.authService.postAppApiAuthAuthTokenrefreshing({
      body: { refresh_token: token },
    });
  }

  registerPSP(data: PspRegistrationRequest): Observable<CreatedResponse> {
    return this.pspService.postPspCreate({ body: data });
  }

  registerCustomer(data: CustomerRegistrationRequest): Observable<CreatedResponse> {
    return this.customerService.postAppApiWebV1CustomerMedicalCreateIndex({ body: data });
  }

  registerBusinessCustomer(data: CustomerRegistrationRequest): Observable<CreatedResponse> {
    return this.customerService.postAppApiWebV1CustomerBusinessCreateIndex({ body: data });
  }

  registerUser(body: UserRegistrationRequest): Observable<CreatedResponse> {
    return this.userService.postUsersRegistrationSubmit({ body });
  }

  resetPassword(data: ApplyRequest, token: string): Observable<EmptySuccessfulResponse> {
    return this.userService.putAppApiWebV1UserResetpasswordApply({ token, body: data });
  }

  resetPasswordEmail(data: ResetRequest): Observable<EmptySuccessfulResponse> {
    return this.userService.postAppApiWebV1UserResetpasswordReset({ body: data });
  }

  verify(id: number, token: string, type: 'customer' | 'psp'): Observable<EmptySuccessfulResponse> {
    return (
      type === 'customer'
        ? this.customerService.postCustomerVerification({ customer: id, body: { token } })
        : this.pspService.postPspVerification({ psp: id, body: { token } })
    ).pipe(take(1));
  }

  validateInviteLink(tokenString: string): Observable<UserRegisterCheckResponse> {
    return this.userService.getUsersRegistrationCheck({ tokenString });
  }

  logout(token: string): Observable<any> {
    return this.authService.postAppApiAuthAuthLogout({
      body: { refresh_token: token },
    });
  }

  openContactsPage(): void {
    window.open('https://stazzle.de/#kontakt', '_blank');
  }
}
