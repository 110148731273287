import { Pipe, PipeTransform } from '@angular/core';
import { NgModel } from '@angular/forms';
import { BaseCountry } from '../services/base-country.service';

@Pipe({
  name: 'baseCountryPreviewValue',
})
export class BaseCountryPreviewValuePipe implements PipeTransform {
  transform(items: BaseCountry[], value: NgModel): any {
    return items.find((baseCountry) => baseCountry.code === value?.toString())?.name || '';
  }
}
