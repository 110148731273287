<div class="sign-modal d-flex flex-column align-items-start mt-5">
  <div class="d-flex justify-content-between w-100">
    <img
      class="logo"
      src="assets-global/images/powered-by-namirial.svg"
      alt="Powered by Namirial"
    />
    <i
      class="fa-solid fa-circle-xmark pointer fa-2x"
      (click)="closeModal()"
    ></i>
  </div>
  <h5 class="my-5">{{ 'namirialSigningProcess.infoDescription' | translate }}</h5>
  <div class="d-flex justify-content-center w-100 mb-3">
    <base-sign-contracts-info-card
      class="w-60"
      [assignment]="currentAssignment"
    ></base-sign-contracts-info-card>
  </div>
  <div class="d-flex justify-content-between w-100 my-5">
    <base-button
      label="action.cancel"
      color="secondary"
      (click)="closeModal()"
    ></base-button>
    <base-button
      label="action.confirm"
      color="primary"
      (btnClick)="submit()"
    ></base-button>
  </div>
</div>
