import { createFeatureSelector, createSelector } from '@ngrx/store';

const getPspDashboardState = createFeatureSelector('pspDashboard');

export const getPspDashboardData = createSelector(getPspDashboardState, (state: any) => state.data);
export const getPspDashboardCurrentAssignments = createSelector(
  getPspDashboardState,
  (state: any) => state.currentAssignments
);
export const getPspDashboardBranchFilter = createSelector(
  getPspDashboardState,
  (state: any) => state.assignmentsFilter
);
export const getPspSalesForecast = createSelector(getPspDashboardState, (state: any) => state.salesForecast);
