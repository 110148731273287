import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'baseRelativeDate',
})
export class RelativeDateTimePipe implements PipeTransform {
  private intervals = [
    { label: 'year', seconds: 31536000 },
    { label: 'month', seconds: 2592000 },
    { label: 'day', seconds: 86400 },
    { label: 'hour', seconds: 3600 },
    { label: 'minute', seconds: 60 },
    { label: 'second', seconds: 1 },
  ];

  constructor(private translate: TranslateService) {}

  public transform(date: string | Date): string {
    if (!(date instanceof Date)) {
      date = new Date(date);
    }
    const seconds = Math.floor((Date.now() - date.getTime()) / 1000);
    const interval: any = this.intervals.find((i) => i.seconds < seconds);
    const count = Math.floor(seconds / interval.seconds);
    const translateKey = 'relativeTime.' + interval.label + (count !== 1 ? 's' : '');

    return `${this.translate.instant('relativeTime.prefix')} ${count} ${this.translate.instant(
      translateKey
    )} ${this.translate.instant('relativeTime.suffix')}`;
  }
}
