import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, withLatestFrom, tap } from 'rxjs/operators';
import { BaseRolesService } from '../../core/services/base-roles.service';
import { BaseCoreState } from '../reducers';
import { baseTimeRecordsActions } from '../actions';
import { baseAuthSelectors, baseTimeRecordsSelectors } from '../selectors';
import { BaseTokenInterface } from '../../shared/interfaces/base-token-service.interface';
import { BaseTimeRecordingsService } from '../../modules/time-recordings/base-time-recordings.service';
import { BaseQueryParamService } from '../../shared/services/base-query-param.service';
import { BaseFilterService } from '../../shared/services/base-filter.service';
import { BaseTimeRecordingItemInterface } from '../../modules/time-recordings/_interfaces/base-time-recordings.interface';
import { BASE_TIME_RECORDINGS_PAGE_SIZE } from '../../modules/time-recordings/_constants/base-time-recordings-pagination.const';
import { AssignmentUnplannedListItemResponse } from '../../../api';
import { createTimeRecord } from '../actions/base-time-records.actions';

@Injectable()
export class BaseTimeRecordsEffects {
  loadTimeRecords$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseTimeRecordsActions.loadTimeRecordsList),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((state) => state.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        const activeFilters = this.baseFilterService.getTimeRecordsFilters(this.queryParamsService.getAllQueryParams());
        const page = this.queryParamsService.getQueryParamByName('page');
        const source$ = this.roleService.isPsp()
          ? this.baseTimeRecordingsService.getPspTimeRecordsList(
              user.organizationId,
              activeFilters,
              page,
              BASE_TIME_RECORDINGS_PAGE_SIZE
            )
          : this.baseTimeRecordingsService.getCustomerTimeRecordsList(
              user.organizationId,
              activeFilters,
              page,
              BASE_TIME_RECORDINGS_PAGE_SIZE
            );
        return source$.pipe(
          mergeMap((response) => {
            return [
              baseTimeRecordsActions.setTimeRecordsList({
                payload: this.baseTimeRecordingsService.prepareTimeRecordsList(
                  (response.data as BaseTimeRecordingItemInterface[]) || []
                ),
              }),
              baseTimeRecordsActions.setTimeRecordsFiltersAndPagination({
                payload: { filters: response.filters, pagination: response.meta },
              }),
              baseTimeRecordsActions.successTimeRecordsList(),
            ];
          }),
          catchError(() => {
            return of(baseTimeRecordsActions.errorTimeRecordsList());
          })
        );
      })
    );
  });

  acceptTimeRecord$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseTimeRecordsActions.acceptTimeRecord),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((state) => state.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        const source$ = this.roleService.isPsp()
          ? this.baseTimeRecordingsService.acceptPspTimeRecord({ psp: user.organizationId, body: action.payload.body })
          : this.baseTimeRecordingsService.acceptCustomerTimeRecord({
              customer: user.organizationId,
              body: action.payload.body,
            });
        return source$.pipe(
          mergeMap((response: any) => {
            return [baseTimeRecordsActions.loadTimeRecordsList(), baseTimeRecordsActions.acceptTimeRecordSuccess()];
          }),
          catchError(() => of(baseTimeRecordsActions.acceptTimeRecordError()))
        );
      })
    );
  });

  updateTimeRecord$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseTimeRecordsActions.updateTimeRecord),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((state) => state.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        const source$ = this.roleService.isPsp()
          ? this.baseTimeRecordingsService.updatePspTimeRecord({ psp: user.organizationId, ...action.payload })
          : this.baseTimeRecordingsService.updateCustomerTimeRecord({
              customer: user.organizationId,
              ...action.payload,
            });
        return source$.pipe(
          mergeMap((response: any) => {
            return [baseTimeRecordsActions.successTimeRecordCRUD(), baseTimeRecordsActions.loadTimeRecordsList()];
          }),
          catchError(() => {
            return of(baseTimeRecordsActions.errorTimeRecordCRUD());
          })
        );
      })
    );
  });

  createTimeRecord$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseTimeRecordsActions.createTimeRecord),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((state) => state.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        const source$ = this.roleService.isPsp()
          ? this.baseTimeRecordingsService.createPspAssignmentDate({ ...action.payload, psp: user.organizationId })
          : this.baseTimeRecordingsService.createCustomerAssignmentDate({
              ...action.payload,
              customer: user.organizationId,
            });
        return source$.pipe(
          mergeMap((response: any) => {
            return [baseTimeRecordsActions.successTimeRecordCRUD(), baseTimeRecordsActions.loadTimeRecordsList()];
          }),
          catchError(() => {
            return of(baseTimeRecordsActions.errorTimeRecordCRUD());
          })
        );
      })
    );
  });

  updateSelectedTimeRecords$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseTimeRecordsActions.updateSelectedTimeRecords),
      withLatestFrom(this.store.select(baseTimeRecordsSelectors.getTimeRecordsList)),
      mergeMap(([action, timeRecordsList]) => [
        baseTimeRecordsActions.setTimeRecordsList({
          payload: this.baseTimeRecordingsService.timeRecordsListToggleSelection(
            action.payload.ids,
            action.payload.isSelected,
            timeRecordsList.data
          ),
        }),
      ])
    );
  });

  createTimeRecordAbsence$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseTimeRecordsActions.createTimeRecordAbsence),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((state) => state.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        const source$ = this.roleService.isPsp()
          ? this.baseTimeRecordingsService.createPspTimeRecordAbsence({
              psp: user.organizationId,
              assignment: action.payload.assignment,
              body: action.payload.body,
            })
          : this.baseTimeRecordingsService.createCustomerTimeRecordAbsence({
              customer: user.organizationId,
              assignment: action.payload.assignment,
              body: action.payload.body,
            });
        return source$.pipe(
          mergeMap((response: any) => {
            return [
              baseTimeRecordsActions.loadTimeRecordsList(),
              baseTimeRecordsActions.successCreateTimeRecordAbsence(),
            ];
          }),
          catchError(() => of(baseTimeRecordsActions.errorCreateTimeRecordAbsence()))
        );
      })
    );
  });

  downloadTimeRecords$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseTimeRecordsActions.downloadTimeRecords),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((state) => state.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        const source$ = this.roleService.isPsp()
          ? this.baseTimeRecordingsService.exportPspTimeRecords({
              ...action.payload,
              psp: user.organizationId,
            })
          : this.baseTimeRecordingsService.exportCustomerTimeRecords({
              ...action.payload,
              customer: user.organizationId,
            });

        return source$.pipe(
          tap((response: any) => {
            const blob = new Blob([response.body], { type: 'application/zip' });
            const objectUrl = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.setAttribute('href', objectUrl);
            link.setAttribute('download', response.fileName);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }),
          map(() => baseTimeRecordsActions.successDownloadTimeRecords()),
          catchError(() => of(baseTimeRecordsActions.errorDownloadTimeRecords()))
        );
      })
    );
  });

  loadAssignmentListForTimeRecordCreation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseTimeRecordsActions.loadAssignmentListForTimeRecordCreation),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((state) => state.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        const source$ = this.roleService.isPsp()
          ? this.baseTimeRecordingsService.pspAssignmentsForTimeRecordCreation(user.organizationId)
          : this.baseTimeRecordingsService.customerAssignmentsForTimeRecordCreation(user.organizationId);
        return source$.pipe(
          mergeMap((response: AssignmentUnplannedListItemResponse[]) => {
            return [
              baseTimeRecordsActions.setAssignmentListForTimeRecordCreation({ payload: response }),
              baseTimeRecordsActions.successAssignmentListForTimeRecordCreation(),
            ];
          }),
          catchError(() => {
            return of(baseTimeRecordsActions.errorAssignmentListForTimeRecordCreation());
          })
        );
      })
    );
  });

  constructor(
    private actions$: Actions,
    private store: Store<BaseCoreState>,
    private roleService: BaseRolesService,
    private baseTimeRecordingsService: BaseTimeRecordingsService,
    private queryParamsService: BaseQueryParamService,
    private baseFilterService: BaseFilterService
  ) {}
}
