import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BaseChangePasswordFormInterface } from '../../../_interfaces/base-user-management.interface';

@Component({
  selector: 'base-change-user-password-form',
  templateUrl: './base-change-user-password-form.component.html',
  styleUrls: ['./base-change-user-password-form.component.scss'],
})
export class BaseChangeUserPasswordFormComponent {
  @Input() form: FormGroup<BaseChangePasswordFormInterface>;
  @Input() isFormSubmitted: boolean;
  @Output() changePassword: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() cancelChangePassword: EventEmitter<boolean> = new EventEmitter<boolean>();

  confirmPasswordChange(): void {
    this.changePassword.emit();
  }
  cancelPasswordChange(): void {
    this.cancelChangePassword.emit();
  }
}
