import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { PspService } from '../../../api';
import { BaseTokenInterface } from '../../shared/interfaces/base-token-service.interface';
import { BaseDashboardService } from '../../modules/dashboard/base-dashboard.service';
import { basePspDashboardActions } from '../actions';
import { BaseCoreState } from '../reducers';
import { baseAuthSelectors } from '../selectors';

@Injectable()
export class BasePspDashboardEffects {
  loadPspDashboardData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(basePspDashboardActions.loadPspDashboardData),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((state) => state.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        return this.pspService
          .getPspDashboardGet({
            psp: user.organizationId,
            assignmentBranch: action.payload,
          })
          .pipe(
            mergeMap((response) => [basePspDashboardActions.setPspDashboardData({ payload: response?.data })]),
            catchError(() => of(basePspDashboardActions.errorPspDashboard()))
          );
      })
    );
  });

  loadPspSalesForecast$ = createEffect(() =>
    this.actions$.pipe(
      ofType(basePspDashboardActions.loadPspSalesForecast),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((state) => state.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        return this.baseDashboardService
          .getPspSalesForecast(user.organizationId, action.payload.from, action.payload.to)
          .pipe(
            mergeMap((response) => [
              basePspDashboardActions.setPspSalesForecast({ payload: response }),
              basePspDashboardActions.successPspSalesForecast(),
            ]),
            catchError(() => of(basePspDashboardActions.errorPspSalesForecast()))
          );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store<BaseCoreState>,
    private pspService: PspService,
    private baseDashboardService: BaseDashboardService
  ) {}
}
