import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Params } from '@angular/router';
import { BaseQueryParamService } from './base-query-param.service';
import { TimeRecordsFiltersEnum } from '../../modules/time-recordings/_enums/time-records-filters.enum';
import { BaseOrderDirectionEnum } from '../enums/base-order-direction.enum';

@Injectable()
export class BaseFilterService {
  constructor(private queryParamService: BaseQueryParamService) {}

  /**
   * Set initial filter values for stazzle filter logic.
   * @param filterValues
   * @param nameReplacements
   * @param notArrayKeys
   */
  setInitialFilterValuesByQueryParams(
    filterValues: { [key in any]: any },
    nameReplacements: any = {},
    notArrayKeys: string[] = []
  ): { [key in any]: any } {
    if (!filterValues) {
      return {};
    }

    const queryParams = this.queryParamService.getAllQueryParams(notArrayKeys);
    Object.entries(filterValues).forEach(([key, _value]) => {
      if (!(key in queryParams)) {
        return;
      }
      if (notArrayKeys.includes(key)) {
        filterValues[key] = queryParams[key];
        return;
      }

      if (queryParams[key].length) {
        filterValues[key] = [];
        queryParams[key]?.forEach((val: any) => {
          filterValues[key].push({
            value: val,
            name: nameReplacements[key] && val in nameReplacements[key] ? nameReplacements[key][val] : val,
          });
        });
      }
    });

    return filterValues;
  }

  /**
   * Check if given filter values have contents.
   * @param filterValues
   */
  hasFilterValues(filterValues: { [key in any]: any }): boolean {
    let hasContent = false;
    Object.entries(filterValues).forEach(([_key, value]) => {
      if ((typeof value !== 'object' && !value) || !value?.length) {
        return;
      } else if (typeof value === 'object' && !Object.entries(filterValues).length) {
        return;
      } else {
        hasContent = true;
        return;
      }
    });

    return hasContent;
  }

  formatFilters(filters: { [key in any]: any }, currentFilters: { [key in any]: any }): any {
    let activeFilters: any = { ...currentFilters };
    Object.keys(filters as any).forEach((key: string) => {
      let value = filters[key as any];
      if (value && !Array.isArray(value) && typeof value === 'object') {
        value = [value];
      }
      activeFilters[key as any] = Array.isArray(value)
        ? value?.map((item: any) => {
            if (item.value === false) {
              item.value = item.value = 'false';
            }
            return item.value || item.id;
          })
        : value?.id || value?.value?.toString() || value;
    });

    return activeFilters;
  }

  convertArrayValuesToFilterString(array: any[]): any {
    if (!Array.isArray(array) && typeof array === 'object') {
      array = [array];
    }
    return array?.length
      ? array
          ?.map((dep: any) => {
            if (dep?.value === false) {
              dep.value = 'false';
            }
            return dep?.value || dep?.id;
          })
          .join(',') || null
      : null;
  }

  updateActiveFilters(values: any[]): any[] {
    return Object.entries(values)
      .reduce((acc: any[], item: [string, any]): any[] => {
        if (Array.isArray(item[1])) {
          acc = [
            ...acc,
            ...item[1].map((val) =>
              typeof val === 'object' ? { ...val, filter: item[0] } : { filter: item[0], value: val }
            ),
          ];
        } else {
          acc.push({ filter: item[0], value: item[1] });
        }

        return acc;
      }, [])
      .filter((item) => !!item.value || !!item.id) as any[];
  }

  removeFilter(filtersForm: UntypedFormGroup, filter: { filter: string; value?: any; id?: any }): void {
    let updatedFilter = filtersForm.controls[filter.filter].value;

    if (Array.isArray(updatedFilter)) {
      updatedFilter = updatedFilter.filter(
        (item) => (item.value || item.id || item) !== filter.value && (item.value || item.id || item) !== filter.id
      );
    } else {
      updatedFilter = '';
    }

    filtersForm.controls[filter.filter].setValue(updatedFilter);
  }

  /**
   * Pick order parameters from query parameters.
   * Schema:
   * key: order (This is always the query params key for list order)
   * values: listOrderKey,listOrderDirection,activeKey (These both fields will be returned as string array always in this order.
   * The active key is always equal to listOrderKey).
   */
  setOrderParametersByQueryParameters(): any[] {
    const order = this.queryParamService.getQueryParam('order');
    if (!order) {
      return [];
    }

    let orderParams: string[] = order.split(',');
    if (orderParams.length !== 2) {
      return [];
    }

    orderParams.push(orderParams[0]);
    return orderParams;
  }

  /**
   * Convert an array of objects into a key => value object based on the defined index and value key.
   * @param array
   * @param indexKey
   * @param valueKey
   */
  convertArrayToKeyValueObject(array: any[], indexKey: string, valueKey: string | string[]): Object {
    if (!array?.length) {
      return {};
    }

    const object: Record<string, string> = {};
    array.forEach((value: any) => {
      let objectValue = '';
      if (typeof valueKey === 'string') {
        objectValue = valueKey.split('.').reduce((o, i) => o[i], value);
      } else {
        let i = 0;
        valueKey.forEach((objVal: string) => {
          objectValue += (i ? ' ' : '') + objVal.split('.').reduce((o, i) => o[i], value);
          i++;
        });
      }
      if (!objectValue) {
        return;
      }
      object[value[indexKey]] = objectValue;
    });

    return object;
  }

  /**
   * Returns an object with query parameters for list filtering
   * @param selected filters
   */
  getPspAssignmentFilters(filters: Params) {
    return {
      fromDate: !!filters.fromDate ? filters.fromDate : undefined,
      'statuses[]': !!filters.status?.length ? filters.status.join(',') : undefined,
      'mainQualifications[]': !!filters.mainQualification?.length ? filters.mainQualification.join(',') : undefined,
      'openContracts[]': !!filters.openContract?.length ? filters.openContract.join(',') : undefined,
      'pspBranches[]': !!filters.pspBranch?.length ? filters.pspBranch.join(',') : undefined,
      'customers[]': !!filters.customer?.length ? filters.customer.join(',') : undefined,
    };
  }

  getTimeRecordsFilters(filters: Params) {
    if (!Object.keys(filters).length) return;

    if (!!filters.period) {
      filters.period = { from: filters.period[0], to: filters.period[1] };
    }
    return {
      'statuses[]': !!filters.statuses?.length ? filters.statuses.join(',') : undefined,
      dateFrom: !!filters.period?.from ? filters.period.from : undefined,
      dateTo: !!filters.period?.to ? filters.period.to : undefined,
      'pspBranches[]': !!filters.pspBranches?.length ? filters.pspBranches.join(',') : undefined,
      'profiles[]': !!filters.profiles?.length ? filters.profiles.join(',') : undefined,
      'customerLocations[]': !!filters.locations?.length ? filters.locations.join(',') : undefined,
      search: !!filters.search ? filters[TimeRecordsFiltersEnum.search] : undefined,
    };
  }

  getInquiryCandidateListFilters(filters: Params, isIncludeSorting: boolean | undefined) {
    if (!Object.keys(filters).length) return;
    let activeSortOrderDirection: BaseOrderDirectionEnum;
    let activeSortOrderKey: 'profileLastName' | 'profileMainQualificationName' | undefined;

    [activeSortOrderKey, activeSortOrderDirection] = this.setOrderParametersByQueryParameters();

    return {
      qualifications: !!filters.qualifications?.length ? filters.qualifications.join() : undefined,
      search: filters.search,
      orderDirection: isIncludeSorting && activeSortOrderDirection ? activeSortOrderDirection : undefined,
      orderKey: isIncludeSorting && activeSortOrderKey ? activeSortOrderKey : undefined,
    };
  }

  getInquiryOffersListFilters(filters: Params, isIncludeSorting: boolean | undefined) {
    if (!Object.keys(filters).length) return;
    let activeSortOrderDirection: BaseOrderDirectionEnum;
    let activeSortOrderKey: 'profileLastName' | 'qualificationName' | undefined;

    [activeSortOrderKey, activeSortOrderDirection] = this.setOrderParametersByQueryParameters();

    return {
      status: filters.status,
      experience: filters.experience,
      qualifications: !!filters.qualifications?.length ? filters.qualifications.join() : undefined,
      search: filters.search,
      orderDirection: isIncludeSorting && activeSortOrderDirection ? activeSortOrderDirection : undefined,
      orderKey: isIncludeSorting && activeSortOrderKey ? activeSortOrderKey : undefined,
    };
  }
}
