import { AbstractControl } from '@angular/forms';

export const baseEmailValidator = () => {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    const emailSchema = new RegExp('^\\S+@\\S+\\.\\S+$');
    if (control.value && !emailSchema.test(control.value)) {
      return { emailInvalid: true };
    }
    return null;
  };
};

export const baseEmailRequired = () => {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    return control.value === null || control.value.length === 0 ? { emailRequired: true } : null;
  };
};
