import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { BaseCoreState, baseSharedActions, baseSharedSelectors } from '../../_store';

@Injectable()
export class BaseProfileGuard {
  constructor(private readonly store: Store<BaseCoreState>) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    this.store
      .select(baseSharedSelectors.getProfile)
      .pipe(
        map((result) => result.data),
        take(1)
      )
      .subscribe((data) => {
        if (!data) {
          this.store.dispatch(baseSharedActions.loadProfileData());
        }
      });

    return this.store.select(baseSharedSelectors.getProfile).pipe(
      filter((result) => result.ok !== undefined),
      map((result) => !!result.ok)
    );
  }
}
