<form
  class="d-flex flex-column"
  [formGroup]="form"
  [ngClass]="{ 'was-validated': isFormSubmitted }"
>
  <p class="pre-line">{{ 'administration.invitation' | translate }}</p>

  <div class="d-flex gap-5 my-4">
    <div class="w-30 pt-2">
      <label class="required">{{ 'title' | translate }}</label>
    </div>
    <div class="w-50">
      <base-dropdown
        [items]="genderOptions"
        [translateItems]="true"
        formControlName="gender"
        [requiredLabel]="true"
        [searchable]="false"
        bindOptionLabel="label"
        baseFieldValidationArgs="title"
        placeholder="pleaseSelect"
      >
      </base-dropdown>
    </div>
  </div>

  <div class="d-flex gap-5 my-4">
    <div class="w-30 pt-2">
      <label class="required">{{ 'firstName' | translate }}</label>
    </div>
    <div class="w-70 ms-2">
      <base-input
        label=""
        formControlName="firstName"
        placeholder=""
        [isRequired]="true"
        [isInvalid]="!!form.get('firstName')?.errors"
        errorMessage="validation.firstName"
      ></base-input>
    </div>
  </div>

  <div class="d-flex gap-5 my-4">
    <div class="w-30 pt-2">
      <label class="required">{{ 'lastName' | translate }}</label>
    </div>
    <div class="w-70 ms-2">
      <base-input
        label=""
        formControlName="lastName"
        placeholder=""
        [isRequired]="true"
        [isInvalid]="!!form.get('lastName')?.errors"
        errorMessage="validation.lastName"
      ></base-input>
    </div>
  </div>

  <div class="d-flex gap-5 my-4">
    <div class="w-30 pt-2">
      <label class="required">{{ 'email' | translate }}</label>
    </div>
    <div class="w-70 ms-2">
      <base-input
        label=""
        formControlName="email"
        placeholder="sampleEmail"
        [isRequired]="true"
        [isEmailField]="true"
        [isInvalid]="!!form.get('email')?.errors"
        errorMessage="validation.email"
      ></base-input>
    </div>
  </div>

  <div class="d-flex gap-5 my-4">
    <div class="w-30 pt-2">
      <label class="required">{{ 'permission.group' | translate }}</label>
    </div>
    <div class="w-50">
      <base-dropdown
        [items]="groups"
        formControlName="group"
        [searchable]="false"
        [requiredLabel]="true"
        baseFieldValidationArgs="permissionGroup"
        placeholder="pleaseSelect"
      >
      </base-dropdown>
    </div>
  </div>

  <div
    class="d-flex gap-5 my-4"
    *ngIf="isPSP"
  >
    <div class="w-30 pt-2">
      <label class="required">{{ 'branch.plural' | translate }}</label>
    </div>
    <div class="w-50">
      <base-dropdown
        [searchable]="false"
        [items]="pspBranches"
        [requiredLabel]="true"
        bindOptionLabel="primaryData.name"
        baseFieldValidationArgs="branchRequired"
        [multiple]="true"
        formControlName="pspBranches"
        placeholder="pleaseSelect"
        outputModel="id"
      >
      </base-dropdown>
    </div>
  </div>

  <div
    class="d-flex gap-5 my-4"
    *ngIf="!isPSP"
  >
    <div class="w-30 pt-2">
      <label class="required">{{ 'department.plural' | translate }}</label>
    </div>
    <div class="w-50">
      <base-dropdown
        [searchable]="false"
        [items]="customerDepartments"
        [requiredLabel]="true"
        bindOptionLabel="primaryData.name"
        bindOptionSubLabel="location.name"
        baseFieldValidationArgs="selectDepartment"
        [multiple]="true"
        [customLayout]="true"
        formControlName="customerDepartments"
        placeholder="pleaseSelect"
        outputModel="id"
      ></base-dropdown>
    </div>
  </div>

  <div class="p-0 mb-5 mt-3 d-flex justify-content-between">
    <base-button
      [size]="buttonSizes.md"
      color="secondary"
      label="action.cancel"
      (btnClick)="cancelSendingInvitation()"
    ></base-button>
    <base-button
      [size]="buttonSizes.md"
      color="primary"
      label="action.sendInvitation"
      (btnClick)="confirmSendingInvitation()"
    ></base-button>
  </div>
</form>
