import produce from 'immer';
import { CustomerDashboardResponse, CustomerAssignmentDateListItemResponse } from '../../../api';
import { baseCustomerDashboardActions } from '../actions';

export interface CustomerDashboardState {
  data: CustomerDashboardResponse | undefined;
  offerData: CustomerAssignmentDateListItemResponse[] | undefined;
}

export const CUSTOMER_DASHBOARD_INITIAL_STATE: CustomerDashboardState = {
  data: undefined,
  offerData: undefined,
};

export function BaseCustomerDashboardReducer(
  state: CustomerDashboardState = CUSTOMER_DASHBOARD_INITIAL_STATE,
  action: any
): any {
  return produce(state, (draftState) => {
    switch (action.type) {
      case baseCustomerDashboardActions.loadCustomerDashboardData.type:
        draftState.data = undefined;
        return;
      case baseCustomerDashboardActions.setCustomerDashboardData.type:
        draftState.data = action.payload;
        break;
      case baseCustomerDashboardActions.loadCustomerOfferData.type:
        draftState.offerData = undefined;
        return;
      case baseCustomerDashboardActions.setCustomerOfferData.type:
        draftState.offerData = action.payload;
        break;
    }
  });
}
