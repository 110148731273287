import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseTabInterface } from '../../../shared/interfaces/base-tab.interface';
import { IsActiveMatchOptions } from '@angular/router';

@Component({
  selector: 'base-vertical-nav',
  templateUrl: './base-vertical-nav.component.html',
  styleUrls: ['./base-vertical-nav.component.scss'],
})
export class BaseVerticalNavComponent {
  private value: Partial<BaseTabInterface> = {} as BaseTabInterface;
  @Input() tabs: Array<BaseTabInterface> = [];
  @Input() withArrow = false;
  @Input() isResponsive = false;
  @Input() isUppercaseTitle = true;
  @Input()
  get current(): Partial<BaseTabInterface> {
    return this.value;
  }
  set current(value) {
    this.value = value;

    if (this.value) {
      this.currentChange.emit(this.value);
    }
  }
  @Output() currentChange: EventEmitter<Partial<BaseTabInterface>> = new EventEmitter<Partial<BaseTabInterface>>();
  routerLinkActiveOptions: IsActiveMatchOptions = {
    queryParams: 'ignored',
    matrixParams: 'exact',
    paths: 'exact',
    fragment: 'exact',
  };

  onTabSelect(tab: BaseTabInterface): void {
    this.current = tab;
  }
}
