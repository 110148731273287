import { Component, Input } from '@angular/core';
import { Validators, NonNullableFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { BaseButtonSize } from '../../../../shared/components/button/base-button.component';
import { BaseApplicationType } from '../../../../shared/types/base-application.type';
import { baseMatchValuesValidator } from '../../../../shared/validators/base-match-values.validator';
import { basePasswordValidator } from '../../../../shared/validators/base-password.validator';
import { baseAuthActions, BaseCoreState } from '../../../../_store';

@Component({
  selector: 'base-reset-password',
  templateUrl: './base-reset-password.component.html',
  styleUrls: ['./base-reset-password.component.scss'],
})
export class BaseResetPasswordComponent {
  @Input() application: BaseApplicationType;

  buttonSize = BaseButtonSize;
  submitted = false;
  resetForm = this.fb.group({
    password: ['', [Validators.required, basePasswordValidator()]],
    repeatPassword: ['', [Validators.required, baseMatchValuesValidator('password')]],
  });
  appLogoUrls = {
    medical: 'assets-global/images/stazzle-medical.svg',
    business: 'assets-global/images/stazzle-business.svg',
    growth: 'assets-global/images/stazzle-growth.svg',
  };

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private store: Store<BaseCoreState>,
    private fb: NonNullableFormBuilder
  ) {}

  submit(): void {
    this.submitted = true;
    this.resetForm.markAllAsTouched();
    if (this.resetForm.valid) {
      this.store.dispatch(
        baseAuthActions.resetPasswordConfirmation({
          payload: {
            body: this.resetForm.getRawValue(),
            token: this.activatedRoute.snapshot.params.token,
          },
        })
      );
      this.submitted = false;
    }
  }

  cancel(): void {
    this.store.dispatch(baseAuthActions.navigateToLoginPage());
  }
}
