<cdk-virtual-scroll-viewport
  *ngIf="datesArray && inquiryDates && shiftTimesWithFlex"
  [itemSize]="dayColumnWidth"
  [maxBufferPx]="calendarBufferSize"
  [minBufferPx]="calendarBufferSize"
  class="inquiry-scrollable-days"
  [ngClass]="{ 'h-auto': !isVisibleCalendar }"
  orientation="horizontal"
  baseInquiryHorizontalScroll
  [isCalendar]="true"
>
  <div
    *cdkVirtualFor="let bubbleListItem of bubbleList; trackBy: trackByDay"
    [ngClass]="{ 'calendar-weekend': bubbleListItem.dateItem.isWeekend, today: bubbleListItem.dateItem.isToday }"
    class="day-container"
  >
    <div class="calendar-day">
      <span
        *ngIf="bubbleListItem.dateItem.firstDayOfMonth"
        class="inquiry-month"
        >{{ bubbleListItem.dateItem.monthLong }} {{ bubbleListItem.dateItem.date | date: 'yyyy' }}</span
      >
      <span
        *ngIf="bubbleListItem.dateItem.calendarWeek"
        class="inquiry-week"
      >
        <span>{{ 'days.calendarWeek' | translate }} {{ bubbleListItem.dateItem.calendarWeek }}</span>
      </span>
      {{ bubbleListItem.dateItem.day }}
      <span class="inquiry-week-day">{{ bubbleListItem.dateItem.weekdayShort }}</span>
    </div>

    <div
      *ngFor="let bubble of bubbleListItem.bubbles; trackBy: trackByBubble"
      class="calendar-column"
      [ngClass]="{ 'd-none': !isVisibleCalendar }"
    >
      <base-inquiry-bubble-new [bubble]="bubble"></base-inquiry-bubble-new>
    </div>
  </div>
</cdk-virtual-scroll-viewport>

<base-inquiry-horizontal-scroll
  class="form-range-weeks"
  [itemsLength]="bubbleList.length"
></base-inquiry-horizontal-scroll>
