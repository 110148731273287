import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { CustomerResponse, PspResponse } from '../../../../api';
import { BaseAvatarSize } from '../../../shared/components/avatar/base-avatar.component';
import { BASE_PERMISSIONS } from '../../../core/constants/base-permissions';
import { BaseRolesService } from '../../../core/services/base-roles.service';
import { BaseTabInterface } from '../../../shared/interfaces/base-tab.interface';
import { SharedState } from '../../../_store/reducers/base-shared.reducer';
import { baseAdministrationActions, baseAdministrationSelectors, baseSharedSelectors } from '../../../_store';
import { BaseEnvironmentService } from '../../../core/services/base-environment.service';
import { BaseApplication } from '../../../shared/enums/base-application.enum';
import { BASE_ROLES } from '../../../core/constants/base-roles';

@Component({
  selector: 'base-user-nav',
  templateUrl: './base-user-nav.component.html',
})
export class BaseUserNavComponent implements OnInit, OnDestroy {
  @Input() userMenu: Array<BaseTabInterface & { permissionsAllowed: BASE_PERMISSIONS[]; rolesAllowed: BASE_ROLES[] }>;
  isOpen = false;
  userName = '';
  userAvatar: string | undefined = '';
  avatarSize = BaseAvatarSize.intermediate;
  userMenuItems: Array<BaseTabInterface & { permissionsAllowed: BASE_PERMISSIONS[]; rolesAllowed: BASE_ROLES[] }> = [];
  private destroy$ = new Subject();
  position: string | undefined;
  companyData: CustomerResponse | PspResponse | undefined;
  externalFaqLink: string = '';
  signDigital: boolean | undefined;

  constructor(
    private readonly roleService: BaseRolesService,
    private readonly store: Store<SharedState>,
    private environmentService: BaseEnvironmentService
  ) {}

  ngOnInit(): void {
    const application = this.environmentService.getLocalValue('application');

    switch (application.app) {
      case BaseApplication.growth:
        this.externalFaqLink = 'https://wiki.stazzle.de/knowledge/stazzlegrowth';
        break;
      case BaseApplication.medical:
        this.externalFaqLink = 'https://wiki.stazzle.de/knowledge/stazzlemedical';
        break;
      case BaseApplication.business:
        this.externalFaqLink = 'https://wiki.stazzle.de/knowledge/stazzlebusiness';
        break;
      default:
        this.externalFaqLink = '';
    }

    this.userMenuItems = this.userMenu?.filter((item) => {
      return this.roleService.isPsp()
        ? !item.permissionsAllowed.length ||
            item.permissionsAllowed.some((permission) => this.roleService.hasPspFeaturePermission(permission))
        : !item.rolesAllowed.length || item.rolesAllowed.some((role) => this.roleService.hasRole(role));
    });

    this.store
      .select(baseSharedSelectors.getProfileData)
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        if (value) {
          this.userName = value.firstName + ' ' + value.lastName;
          this.userAvatar = value.avatar?.data;
          this.position = value.position;
        }
      });

    if (this.roleService.isPsp()) {
      this.store
        .select(baseAdministrationSelectors.getPspData)
        .pipe(
          takeUntil(this.destroy$),
          filter((data) => data !== undefined),
          map((data) => data.data)
        )
        .subscribe((value) => {
          if (value) {
            this.companyData = value.data;
            this.signDigital = value.data.signDigital;
          } else {
            this.store.dispatch(baseAdministrationActions.loadPspData());
          }
        });
    } else {
      this.store
        .select(baseAdministrationSelectors.getCustomerDataData)
        .pipe(takeUntil(this.destroy$))
        .subscribe((value) => {
          if (value) {
            this.companyData = value;
            this.signDigital = value.signDigital;
          }
        });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  toggle(): void {
    this.isOpen = !this.isOpen;
  }

  close(): void {
    this.isOpen = false;
  }
}
