<span class="d-flex align-items-center">
  <ng-container *ngIf="!bindOptionSubLabel && !isAssignmentView">
    <base-avatar
      [image]="item['avatar']"
      [size]="avatarSize.medium"
    ></base-avatar>
    <div class="d-flex ms-2 flex-column overflow-hidden">
      <p class="fw-bold mb-0">{{ item?.firstName }} {{ item?.lastName }}</p>
      <p class="fw-normal mb-0">{{ item?.position }}</p>
    </div>
  </ng-container>

  <ng-container *ngIf="bindOptionSubLabel && !isAssignmentView">
    <div class="d-flex flex-column overflow-hidden">
      <p
        class="fw-bold mb-0"
        *ngIf="label"
      >
        {{ label }}
      </p>
      <p
        class="fw-normal text-dark-gray location-name mb-0"
        *ngIf="subLabel"
      >
        {{ subLabel }}
      </p>
    </div>
  </ng-container>

  <ng-container *ngIf="isAssignmentView">
    <div class="d-flex flex-column overflow-hidden">
      <p class="fw-bold mb-0">{{ item.profile.firstName }} {{ item.profile.lastName }}</p>
      <p class="fw-normal mb-0">{{ item.startDate | baseFormatDate }} - {{ item.endDate | baseFormatDate }}</p>
      <p class="fw-bold mb-0">
        {{ item.customerDepartment.name }}
      </p>
    </div>
  </ng-container>
</span>
