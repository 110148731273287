import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MetaPagination, OverlapProfileResponse, ProfileShowResponse } from '../../../api';

const getCandidatesState = createFeatureSelector('candidates');

export const getCandidates = createSelector(
  getCandidatesState,
  (
    state: any
  ): {
    data: (OverlapProfileResponse & { active: boolean })[] | undefined;
    pagination: MetaPagination | undefined;
    ok: boolean | undefined;
  } => state.candidates
);
export const getCandidateFilter = createSelector(getCandidatesState, (state: any): any => state.filter);

export const getCandidateProfile = createSelector(
  getCandidatesState,
  (state: any): { data: ProfileShowResponse | undefined; ok: boolean | undefined } => state.candidate
);
export const getCreateCandidateStatus = createSelector(
  getCandidatesState,
  (state: any): boolean => state.candidate.createCrud
);
export const getCreateCandidateId = createSelector(getCandidatesState, (state: any): number => state.candidate.id);

export const getDocument = createSelector(getCandidatesState, (state: any): string => state.document);
export const getDocumentId = createSelector(getCandidatesState, (state: any): string => state.document.id);
export const getUploadStatus = createSelector(getCandidatesState, (state: any): boolean => state.document.uploadCrud);
export const getDeleteStatus = createSelector(getCandidatesState, (state: any): boolean => state.document.deleteCrud);
export const getDownloadDocument = createSelector(
  getCandidatesState,
  (state: any): { data: Blob | undefined } => state.downloadDocument
);

export const getAbsences = createSelector(getCandidatesState, (state: any) => state.absences.list);
export const getAbsencesData = createSelector(getCandidatesState, (state: any) => state.absences.list.data);
export const getAbsenceSummary = createSelector(getCandidatesState, (state: any) => state.absences.summary);
export const getAbsence = createSelector(getCandidatesState, (state: any) => state.absences.instance);
export const getAbsenceCrudStatus = createSelector(getCandidatesState, (state: any) => state.absences.crud);
export const getAbsenceConflicts = createSelector(getCandidatesState, (state: any) => state.absences.conflicts);

export const getProfileAccountInformation = createSelector(getCandidatesState, (state: any) => state.account);
export const getProfileAccountInformationData = createSelector(getCandidatesState, (state: any) => state.account.data);
export const getProfileAccountReactivatedStatus = createSelector(
  getCandidatesState,
  (state: any) => state.account.reactivated
);
export const getCandidateResponsibleUserList = createSelector(
  getCandidatesState,
  (state: any) => state.responsibleUserList
);
export const getCandidateLeavingDateConflicts = createSelector(
  getCandidatesState,
  (state: any) => state.leavingDateConflicts
);
export const getCandidateProfileDocuments = createSelector(
  getCandidatesState,
  (state: any) => state.candidateProfileDocuments
);
