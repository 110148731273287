import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AuthService, UserService } from '../../../api';
import { BaseSpinnerService } from '../../shared/system/spinner/base-spinner.service';
import { BASE_BUILD_DETAILS_URL } from '../constants/base-build-details-url';

@Injectable()
export class BaseSpinnerInterceptor implements HttpInterceptor {
  constructor(private waitingSpinner: BaseSpinnerService, private router: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (
      req.url.includes(AuthService.PostAppApiAuthAuthTokenrefreshingPath) ||
      req.url.includes(BASE_BUILD_DETAILS_URL) ||
      req.url.includes(UserService.GetNotificationsGetListPath)
    ) {
      return next.handle(req);
    }

    const duplicate = req.clone();
    this.waitingSpinner.startLoading();
    return next.handle(duplicate).pipe(finalize(() => this.waitingSpinner.stopLoading()));
  }
}
