/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AuthenticationRequest } from '../models/authentication-request';
import { AuthenticationSuccessfulResponse } from '../models/authentication-successful-response';
import { LogoutSuccessfulResponse } from '../models/logout-successful-response';
import { TokenRefreshingRequest } from '../models/token-refreshing-request';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation postAppApiAuthAuthMedicallogin
   */
  static readonly PostAppApiAuthAuthMedicalloginPath = '/api/auth/medical/login';

  /**
   * Medical User login.
   *
   * **Accessible only for users with role 'ROLE_MEDICAL_USER'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postAppApiAuthAuthMedicallogin()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postAppApiAuthAuthMedicallogin$Response(params: {
    context?: HttpContext

    /**
     * Medical Users credentials to login
     */
    body: AuthenticationRequest
  }
): Observable<StrictHttpResponse<AuthenticationSuccessfulResponse>> {

    const rb = new RequestBuilder(this.rootUrl, AuthService.PostAppApiAuthAuthMedicalloginPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AuthenticationSuccessfulResponse>;
      })
    );
  }

  /**
   * Medical User login.
   *
   * **Accessible only for users with role 'ROLE_MEDICAL_USER'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postAppApiAuthAuthMedicallogin$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postAppApiAuthAuthMedicallogin(params: {
    context?: HttpContext

    /**
     * Medical Users credentials to login
     */
    body: AuthenticationRequest
  }
): Observable<AuthenticationSuccessfulResponse> {

    return this.postAppApiAuthAuthMedicallogin$Response(params).pipe(
      map((r: StrictHttpResponse<AuthenticationSuccessfulResponse>) => r.body as AuthenticationSuccessfulResponse)
    );
  }

  /**
   * Path part for operation postAppApiAuthAuthBusinesslogin
   */
  static readonly PostAppApiAuthAuthBusinessloginPath = '/api/auth/business/login';

  /**
   * Business User login.
   *
   * **Accessible only for users with roles 'ROLE_BUSINESS_USER'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postAppApiAuthAuthBusinesslogin()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postAppApiAuthAuthBusinesslogin$Response(params: {
    context?: HttpContext

    /**
     * Businnes Users credentials to login
     */
    body: AuthenticationRequest
  }
): Observable<StrictHttpResponse<AuthenticationSuccessfulResponse>> {

    const rb = new RequestBuilder(this.rootUrl, AuthService.PostAppApiAuthAuthBusinessloginPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AuthenticationSuccessfulResponse>;
      })
    );
  }

  /**
   * Business User login.
   *
   * **Accessible only for users with roles 'ROLE_BUSINESS_USER'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postAppApiAuthAuthBusinesslogin$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postAppApiAuthAuthBusinesslogin(params: {
    context?: HttpContext

    /**
     * Businnes Users credentials to login
     */
    body: AuthenticationRequest
  }
): Observable<AuthenticationSuccessfulResponse> {

    return this.postAppApiAuthAuthBusinesslogin$Response(params).pipe(
      map((r: StrictHttpResponse<AuthenticationSuccessfulResponse>) => r.body as AuthenticationSuccessfulResponse)
    );
  }

  /**
   * Path part for operation postAppApiAuthAuthPsplogin
   */
  static readonly PostAppApiAuthAuthPsploginPath = '/api/auth/growth/login';

  /**
   * User login.
   *
   * **Accessible only for users with roles 'ROLE_PSP_USER'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postAppApiAuthAuthPsplogin()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postAppApiAuthAuthPsplogin$Response(params: {
    context?: HttpContext

    /**
     * PSP Users credentials to login
     */
    body: AuthenticationRequest
  }
): Observable<StrictHttpResponse<AuthenticationSuccessfulResponse>> {

    const rb = new RequestBuilder(this.rootUrl, AuthService.PostAppApiAuthAuthPsploginPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AuthenticationSuccessfulResponse>;
      })
    );
  }

  /**
   * User login.
   *
   * **Accessible only for users with roles 'ROLE_PSP_USER'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postAppApiAuthAuthPsplogin$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postAppApiAuthAuthPsplogin(params: {
    context?: HttpContext

    /**
     * PSP Users credentials to login
     */
    body: AuthenticationRequest
  }
): Observable<AuthenticationSuccessfulResponse> {

    return this.postAppApiAuthAuthPsplogin$Response(params).pipe(
      map((r: StrictHttpResponse<AuthenticationSuccessfulResponse>) => r.body as AuthenticationSuccessfulResponse)
    );
  }

  /**
   * Path part for operation postAppApiAuthAuthLogout
   */
  static readonly PostAppApiAuthAuthLogoutPath = '/api/auth/logout';

  /**
   * Invalidate JWT refresh token.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postAppApiAuthAuthLogout()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postAppApiAuthAuthLogout$Response(params: {
    context?: HttpContext

    /**
     * Provides valid refresh_token value
     */
    body: TokenRefreshingRequest
  }
): Observable<StrictHttpResponse<LogoutSuccessfulResponse>> {

    const rb = new RequestBuilder(this.rootUrl, AuthService.PostAppApiAuthAuthLogoutPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LogoutSuccessfulResponse>;
      })
    );
  }

  /**
   * Invalidate JWT refresh token.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postAppApiAuthAuthLogout$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postAppApiAuthAuthLogout(params: {
    context?: HttpContext

    /**
     * Provides valid refresh_token value
     */
    body: TokenRefreshingRequest
  }
): Observable<LogoutSuccessfulResponse> {

    return this.postAppApiAuthAuthLogout$Response(params).pipe(
      map((r: StrictHttpResponse<LogoutSuccessfulResponse>) => r.body as LogoutSuccessfulResponse)
    );
  }

  /**
   * Path part for operation postAppApiAuthAuthTokenrefreshing
   */
  static readonly PostAppApiAuthAuthTokenrefreshingPath = '/api/auth/token-refreshing';

  /**
   * Refresh token.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postAppApiAuthAuthTokenrefreshing()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postAppApiAuthAuthTokenrefreshing$Response(params: {
    context?: HttpContext

    /**
     * Provides valid refresh_token value
     */
    body: TokenRefreshingRequest
  }
): Observable<StrictHttpResponse<AuthenticationSuccessfulResponse>> {

    const rb = new RequestBuilder(this.rootUrl, AuthService.PostAppApiAuthAuthTokenrefreshingPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AuthenticationSuccessfulResponse>;
      })
    );
  }

  /**
   * Refresh token.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postAppApiAuthAuthTokenrefreshing$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postAppApiAuthAuthTokenrefreshing(params: {
    context?: HttpContext

    /**
     * Provides valid refresh_token value
     */
    body: TokenRefreshingRequest
  }
): Observable<AuthenticationSuccessfulResponse> {

    return this.postAppApiAuthAuthTokenrefreshing$Response(params).pipe(
      map((r: StrictHttpResponse<AuthenticationSuccessfulResponse>) => r.body as AuthenticationSuccessfulResponse)
    );
  }

}
