import { globalEnvironment } from '../../../../environments/environment.prod';
import { BaseApplication, BaseApplicationLogo } from 'base';

export const environment = {
  global: globalEnvironment,
  env: 'prod',
  application: {
    project: 'Stazzle',
    app: BaseApplication.growth,
    logo: BaseApplicationLogo.growth,
  },
};
