import { Injectable } from '@angular/core';
import {
  CreatedResponse,
  CustomerService,
  EmptySuccessfulResponse,
  GeneralApiResponse,
  GroupCreateRequest,
  GroupItemResponse,
  GroupListItemResponse,
  GroupUpdateRequest,
  PspService,
  UserItemResponse,
} from '../../../api';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable()
export class BaseUsersService {
  constructor(private pspService: PspService, private customerService: CustomerService) {}

  getUserList(params: {
    id: string;
    role?: string;
    isPSP: boolean;
    search?: string;
    active?: boolean;
  }): Observable<UserItemResponse[]> {
    const source$ = params.isPSP
      ? this.pspService.getPspsUsersList({
          psp: Number(params.id),
          'role[]': params.role,
          search: params.search,
          active: params.active,
        })
      : this.customerService.getCustomersUsersList({
          customer: Number(params.id),
          'role[]': params.role,
          search: params.search,
          active: params.active,
        });

    return source$.pipe(map((response) => (response.data?.length ? response.data : [])));
  }

  getPspUserGroups(psp: any): Observable<Array<GroupListItemResponse> | undefined> {
    return this.pspService.getPspGroupsList({ psp }).pipe(
      take(1),
      map((response: { message?: string; data?: Array<GroupListItemResponse> }) =>
        response.data?.length ? response.data : []
      )
    );
  }

  getCustomerUserGroups(customer: any): Observable<Array<GroupListItemResponse> | undefined> {
    return this.customerService.getCustomerGroupsList({ customer }).pipe(
      take(1),
      map((response: { message?: string; data?: Array<GroupListItemResponse> }) =>
        response.data?.length ? response.data : []
      )
    );
  }

  getPspGroup(groupId: number, pspId: number): Observable<GeneralApiResponse & { data?: GroupItemResponse }> {
    return this.pspService.getPspGroupsShow({ group: groupId, psp: pspId });
  }

  getCustomerGroup(groupId: number, customerId: number): Observable<GeneralApiResponse & { data?: GroupItemResponse }> {
    return this.customerService.getCustomerGroupsShow({ customer: customerId, group: groupId });
  }

  createPspGroup(pspId: number, body: GroupCreateRequest): Observable<CreatedResponse> {
    return this.pspService.postPspGroupCreate({ psp: pspId, body });
  }

  updatePspGroup(groupId: number, pspId: number, body: GroupUpdateRequest): Observable<EmptySuccessfulResponse> {
    return this.pspService.putPspGroupsUpdate({ group: groupId, psp: pspId, body });
  }

  createCustomerGroup(customerId: number, body: GroupCreateRequest): Observable<CreatedResponse> {
    return this.customerService.postCustomerGroupCreate({ customer: customerId, body });
  }

  updateCustomerGroup(
    groupId: number,
    customerId: number,
    body: GroupUpdateRequest
  ): Observable<EmptySuccessfulResponse> {
    return this.customerService.putCustomerGroupsUpdate({ group: groupId, customer: customerId, body });
  }

  deletePspUsersGroup(psp: number, group: number): Observable<EmptySuccessfulResponse> {
    return this.pspService.deletePspGroupsDelete({ psp, group });
  }

  deleteCustomerUsersGroup(customer: number, group: number): Observable<EmptySuccessfulResponse> {
    return this.customerService.deleteCustomerGroupsDelete({ customer, group });
  }

  deletePspUserInvitation(params: { psp: number; user: number }): Observable<EmptySuccessfulResponse> {
    return this.pspService.deletePspsUserInviteDelete(params);
  }

  deleteCustomerUserInvitation(params: { customer: number; user: number }): Observable<EmptySuccessfulResponse> {
    return this.customerService.deleteCustomersUserInviteDelete(params);
  }

  resendPspUserInvitation(params: { psp: number; user: number }): Observable<CreatedResponse> {
    return this.pspService.postPspsUsersReinvitation(params);
  }

  resendCustomerUserInvitation(params: { customer: number; user: number }): Observable<CreatedResponse> {
    return this.customerService.postCustomersUserReinvitation(params);
  }

  activatePSPUser(params: { user: number; psp: number }): Observable<EmptySuccessfulResponse> {
    return this.pspService.postPspsUsersActivate(params);
  }

  deactivatePSPUser(params: { user: number; psp: number }): Observable<EmptySuccessfulResponse> {
    return this.pspService.deletePspsUsersDeactivate(params);
  }

  activateCustomerUser(params: { user: number; customer: number }): Observable<EmptySuccessfulResponse> {
    return this.customerService.postCustomersUsersActivate(params);
  }

  deactivateCustomerUser(params: { user: number; customer: number }): Observable<EmptySuccessfulResponse> {
    return this.customerService.deleteCustomersUsersDeactivate(params);
  }
}
