import { Injectable } from '@angular/core';
import {
  BasePspInquiryOfferDateInterface,
  BasePspInquiryOfferInterface,
} from '../_interfaces/base-psp-inquiry-offer.interface';
import { InquiryDateResponse, InquiryResponse, PspInquiryOfferListItemResponse } from '../../../../api';
import { BaseDateService } from '../../../shared/services/base-date.service';
import {
  BASE_INQUIRY_CALENDAR_MAX_DAYS_TO_SCROLL,
  BASE_INQUIRY_CALENDAR_ORDERED_SHIFTS,
} from '../_const/base-inquiry-calendar.const';
import { BaseInquiryCandidateShiftInterface } from '../_interfaces/base-inquiry-candidate.interface';
import { BaseInquiryShiftsWithFlexType, BaseOfferStatus } from '../_enums/base-inquiry.enum';
import { BaseInquiryBubbleColorAndValueInterface } from '../_interfaces/base-inquiry-bubble-color-and-value.interface';
import { BaseInquiryBubbleValues } from '../_enums/base-inquiry-bubble-values.enum';
import { BaseInquiryBubbleColors } from '../_enums/base-inquiry-bubble-colors.enum';
import { BaseInquiryService } from './base-inquiry.service';
import {
  getClosestDayOfLastWeek,
  getDaysArrayWithWeekdays,
} from '../../../shared/helpers/base-get-dates-array-between-two-dates';

@Injectable()
export class BasePspInquiryOfferService {
  constructor(private baseInquiryService: BaseInquiryService) {}
  preparePspInquiryOfferBubblesList(
    offers: PspInquiryOfferListItemResponse[],
    inquiry: InquiryResponse
  ): BasePspInquiryOfferInterface[] {
    const currentDate = BaseDateService.toDate(inquiry.dateFrom as string);

    return offers.map((offer) => ({
      data: { ...offer, isSelected: false },
      dates: this.prepareInquiryDates(inquiry, currentDate, offer),
    }));
  }

  selectPspInquiryOffer(
    offers: BasePspInquiryOfferInterface[],
    selectedOfferId: number
  ): BasePspInquiryOfferInterface[] {
    return offers.map((offer: BasePspInquiryOfferInterface) => {
      return {
        data: {
          ...offer.data,
          isSelected: offer.data.id === selectedOfferId ? !offer.data.isSelected : false,
        },
        dates: offer.dates,
      };
    });
  }

  private prepareInquiryDates(
    inquiry: InquiryResponse,
    currentDate: Date,
    offer: PspInquiryOfferListItemResponse
  ): BasePspInquiryOfferDateInterface[] {
    const inquiryDateTimes = inquiry.dates
      ? inquiry.dates.map((newInquiryDate: InquiryDateResponse) => new Date(newInquiryDate.date).getTime())
      : [];
    const maxDate = new Date(
      Math.max(
        ...inquiryDateTimes,
        BaseDateService.addDays(currentDate, BASE_INQUIRY_CALENDAR_MAX_DAYS_TO_SCROLL).getTime()
      )
    );
    const inquiryLength = BaseDateService.differenceInWeeks(currentDate, maxDate);
    let weekAmount = inquiryLength + (maxDate.getDay() > 1 && maxDate.getDay() <= 6 ? 2 : 1);

    const closestMonday = getClosestDayOfLastWeek('Mon', currentDate);
    const dates: string[] = getDaysArrayWithWeekdays(
      closestMonday,
      BaseDateService.addDays(closestMonday, weekAmount * 7 - 1)
    );

    return this.addDataToPspInquiryOfferDatesArray(dates, inquiry, offer);
  }

  private addDataToPspInquiryOfferDatesArray(
    dates: string[],
    inquiry: InquiryResponse,
    offer: PspInquiryOfferListItemResponse
  ): BasePspInquiryOfferDateInterface[] {
    if (!dates.length) {
      return [];
    }

    let datesArray: BasePspInquiryOfferDateInterface[] = [];
    const getPspOfferBubbleColorAndValue = (
      date: string,
      shift: BaseInquiryShiftsWithFlexType
    ): BaseInquiryBubbleColorAndValueInterface => {
      return this.setPspOfferBubbleColorAndValue(date, shift, offer);
    };

    const createShift = (shift: BaseInquiryShiftsWithFlexType, shiftIndex: number, dateIndex: number): any => {
      const bubbleColorAndValue = getPspOfferBubbleColorAndValue(dates[dateIndex], shift);

      return {
        shift,
        index: shiftIndex,
        color: bubbleColorAndValue.color,
        currentValue: bubbleColorAndValue.value,
        showDot: false,
        class: `position-relative`,
      };
    };

    dates.forEach((date, dateIndex) => {
      const shifts: BaseInquiryCandidateShiftInterface[] = BASE_INQUIRY_CALENDAR_ORDERED_SHIFTS.map(
        (shift, shiftIndex) => createShift(shift, shiftIndex, dateIndex)
      );

      let inquiryDate: BasePspInquiryOfferDateInterface = {
        date,
        isToday: BaseDateService.isToday(new Date(date)),
        isWeekend: BaseDateService.isWeekendDay(new Date(date)),
        shifts,
      };

      inquiryDate.shifts.map((item) => {
        item.showDot = this.baseInquiryService.isBubbleDotVisible(
          inquiryDate.shifts,
          item.shift as BaseInquiryShiftsWithFlexType
        );
        item.isFlexibleShift = inquiryDate.shifts[0].currentValue === BaseInquiryBubbleValues.Available;
        if (item.color) {
          item.class += ` ${item.color}`;
        }
        if (item.currentValue && !item.showDot) {
          item.class += ` bubble`;
        }
        if (!item.currentValue) {
          item.class += ` empty-bubble`;
        }
        if (item.showDot) {
          item.class += ` bubble-dot`;
        }
      });
      datesArray.push(inquiryDate);
    });

    return datesArray;
  }

  private setPspOfferBubbleColorAndValue(
    currentDate: string,
    currentShift: string,
    offer: PspInquiryOfferListItemResponse
  ): BaseInquiryBubbleColorAndValueInterface {
    const isLeavingDateAvailable = offer.profile.leavingDate
      ? BaseDateService.isEqualOrAfter(new Date(currentDate), new Date(offer.profile.leavingDate))
      : false;

    const defaultColorAndValue = {
      color: BaseInquiryBubbleColors.Default,
      value: BaseInquiryBubbleValues.Default,
    };

    const shiftIsNotAvailable =
      !this.baseInquiryService.checkAvailabilityByDirectDay(currentDate, offer.profile.candidateAvailability) ||
      !this.baseInquiryService.checkAvailabilityForDirectShift(currentShift, offer.profile.candidateAvailability) ||
      this.baseInquiryService.isAbsentForDate(currentDate, offer.profile.absences);

    const isIll = this.baseInquiryService.isIllForDate(currentDate, offer.profile.absences);
    const isHoliday = this.baseInquiryService.isHolidayForDate(currentDate, offer.profile.absences);
    const isOther = this.baseInquiryService.isOtherForDate(currentDate, offer.profile.absences);
    const isTimeOff = this.baseInquiryService.isTimeOffForDate(currentDate, offer.profile.absences);
    const isFreeShift = this.baseInquiryService.isFreeShiftForDate(currentDate, offer.profile.absences);
    const isUnexcused = this.baseInquiryService.isUnexcusedForDate(currentDate, offer.profile.absences);
    const isAssignedOutside = this.baseInquiryService.isAssignedOutsideForDate(currentDate, offer.profile.absences);

    const shiftIsSent = this.baseInquiryService.createdOfferPositionExists(currentDate, offer.positions, currentShift);
    const shiftIsConfirmed = this.baseInquiryService.acceptedOfferPositionExists(currentDate, offer, currentShift);
    const shiftNeedsConfirmation = this.baseInquiryService.offerPositionIsAcceptedButNotConfirmed(
      currentDate,
      offer,
      currentShift
    );

    if (!shiftIsConfirmed && shiftIsSent && offer.status === BaseOfferStatus.declined) {
      return { color: BaseInquiryBubbleColors.RedRejected, value: BaseInquiryBubbleValues.Rejected };
    } else if (isLeavingDateAvailable) {
      return { color: BaseInquiryBubbleColors.RedWithdrawn, value: BaseInquiryBubbleValues.Unavailable };
    } else if (
      shiftIsNotAvailable &&
      !shiftIsSent &&
      !shiftIsConfirmed &&
      !isIll &&
      !isHoliday &&
      !isOther &&
      !isFreeShift &&
      !isTimeOff &&
      !isAssignedOutside &&
      !isUnexcused
    ) {
      return { color: BaseInquiryBubbleColors.DarkBlue, value: BaseInquiryBubbleValues.Unavailable };
    } else if (
      shiftIsNotAvailable &&
      !shiftIsSent &&
      !shiftIsConfirmed &&
      isIll &&
      !isHoliday &&
      !isOther &&
      !isFreeShift &&
      !isTimeOff &&
      !isAssignedOutside &&
      !isUnexcused
    ) {
      return { color: BaseInquiryBubbleColors.PinkSick, value: BaseInquiryBubbleValues.Sick };
    } else if (
      shiftIsNotAvailable &&
      !shiftIsSent &&
      !shiftIsConfirmed &&
      !isIll &&
      isHoliday &&
      !isOther &&
      !isFreeShift &&
      !isTimeOff &&
      !isAssignedOutside &&
      !isUnexcused
    ) {
      return { color: BaseInquiryBubbleColors.PinkHoliday, value: BaseInquiryBubbleValues.Vacation };
    } else if (
      shiftIsNotAvailable &&
      !shiftIsSent &&
      !shiftIsConfirmed &&
      !isIll &&
      !isHoliday &&
      isOther &&
      !isFreeShift &&
      !isTimeOff &&
      !isAssignedOutside &&
      !isUnexcused
    ) {
      return { color: BaseInquiryBubbleColors.PinkOther, value: BaseInquiryBubbleValues.Other };
    } else if (
      shiftIsNotAvailable &&
      !shiftIsSent &&
      !shiftIsConfirmed &&
      !isIll &&
      !isHoliday &&
      !isOther &&
      !isFreeShift &&
      !isUnexcused &&
      !isAssignedOutside &&
      isTimeOff
    ) {
      return { color: BaseInquiryBubbleColors.PinkTimeOff, value: BaseInquiryBubbleValues.TimeOff };
    } else if (
      shiftIsNotAvailable &&
      !shiftIsSent &&
      !shiftIsConfirmed &&
      !isIll &&
      !isHoliday &&
      !isOther &&
      !isTimeOff &&
      !isUnexcused &&
      !isAssignedOutside &&
      isFreeShift
    ) {
      return { color: BaseInquiryBubbleColors.PinkFreeShift, value: BaseInquiryBubbleValues.FreeShift };
    } else if (
      shiftIsNotAvailable &&
      !shiftIsSent &&
      !shiftIsConfirmed &&
      !isIll &&
      !isHoliday &&
      !isOther &&
      !isTimeOff &&
      !isFreeShift &&
      !isAssignedOutside &&
      isUnexcused
    ) {
      return { color: BaseInquiryBubbleColors.PinkUnexcused, value: BaseInquiryBubbleValues.Unexcused };
    } else if (
      shiftIsNotAvailable &&
      !shiftIsSent &&
      !shiftIsConfirmed &&
      !isIll &&
      !isHoliday &&
      !isOther &&
      !isTimeOff &&
      !isFreeShift &&
      !isUnexcused &&
      isAssignedOutside
    ) {
      return { color: BaseInquiryBubbleColors.PinkAssignedOutside, value: BaseInquiryBubbleValues.AssignedOutside };
    } else if (shiftIsConfirmed) {
      return { color: BaseInquiryBubbleColors.Green, value: BaseInquiryBubbleValues.Booked };
    } else if (shiftNeedsConfirmation) {
      return { color: BaseInquiryBubbleColors.Yellow, value: BaseInquiryBubbleValues.Offered };
    } else if (shiftIsSent && offer.status === BaseOfferStatus.needsUpdate) {
      return { color: BaseInquiryBubbleColors.Purple, value: BaseInquiryBubbleValues.Offered };
    } else if (
      shiftIsSent &&
      offer.status !== BaseOfferStatus.confirmed &&
      offer.status !== BaseOfferStatus.needsConfirmation
    ) {
      return { color: BaseInquiryBubbleColors.DarkGrey, value: BaseInquiryBubbleValues.Offered };
    } else {
      return defaultColorAndValue;
    }
  }
}
