export enum BaseApplication {
  growth = 'Growth',
  medical = 'Medical',
  business = 'Business',
}

export type BaseApplicationTagType = 'medical' | 'business' | undefined;

// for now these remain undefined, because every qualification should be available in every other;
// the api currently awaits BaseApplicationTagType for the tags
export const businessTag: BaseApplicationTagType = 'business';
export const medicalTag: BaseApplicationTagType = 'medical';

export enum BaseApplicationLogo {
  growth = 'assets-global/images/stazzle-growth.svg',
  medical = 'assets-global/images/stazzle-medical.svg',
  business = 'assets-global/images/stazzle-business.svg',
}
