import { createAction, props } from '@ngrx/store';
import {
  AvatarUpdateRequest,
  CreateCustomerLocationRequest,
  CustomerBusinessInviteRequest,
  CustomerResponse,
  DepartmentListItemResponse,
  TerminalCustomerLocationListItemResponse,
  LocationListItemResponse,
  LocationResponse,
  LogoUpdateRequest,
  ProfilePasswordUpdateRequest,
  PspInviteRequest,
  PspResponse,
  SurchargeListItemResponse,
  SurchargeUpdateRequest,
  TerminalCreateRequest,
  TerminalUpdateRequest,
  TerminalUsageRequest,
  CustomerLocationUpdateRequest,
  UserPasswordUpdateRequest,
  UserProfileUpdateRequest,
} from '../../../api';

// User
export const loadUserProfileData = createAction(
  '[Administration: User profile] Load user profile',
  props<{ payload: { user: number } }>()
);
export const setUserProfileData = createAction(
  '[Administration: User profile] Store user profile',
  props<{ payload: any }>()
);
export const successUserProfileData = createAction('[Administration: User profile] User profile fetched');
export const errorUserProfileData = createAction('[Administration: User profile] User profile fetch error');
export const updateUserProfileData = createAction(
  '[Administration: User profile] Update user profile',
  props<{ payload: { user: number; body: UserProfileUpdateRequest; loadList?: boolean } }>()
);

// Company info
export const loadCompanyInfo = createAction(
  '[User Administration] Load company info',
  props<{ payload: { orgID: number } }>()
);
export const setCompanyInfo = createAction(
  '[User Administration] Set company info',
  props<{ payload: CustomerResponse | PspResponse }>()
);
export const successCompanyInfo = createAction('[User Administration] Success company info');
export const errorCompanyInfo = createAction('[User Administration] Error company info');

// Update company info
export const updatePspInfo = createAction('[Administration] Update psp info', props<{ payload: any }>());
export const successUpdatePspInfo = createAction('[Administration] Update psp info success');
export const errorUpdatePspInfo = createAction('[Administration] Update psp info error');

// PSP data
export const loadPspData = createAction('[PSP Administration] Load PSP data');
export const setPspData = createAction('[PSP Administration] Set PSP data', props<{ payload: PspResponse }>());
export const successPspData = createAction('[PSP Administration] Success PSP data');
export const errorPspData = createAction('[PSP Administration] Error PSP data');

// Customer data
export const loadCustomerData = createAction('[Customer Administration] Load customer data');
export const setCustomerData = createAction(
  '[Customer Administration] Set customer data',
  props<{ payload: CustomerResponse }>()
);
export const successCustomerData = createAction('[Customer Administration] Success customer data');
export const errorCustomerData = createAction('[Customer Administration] Error customer data');

// User invitation
export const inviteUser = createAction(
  '[Administration] Invite customer',
  props<{
    payload: {
      id: number | string | undefined;
      body: CustomerBusinessInviteRequest | PspInviteRequest;
      fullList?: boolean;
    };
  }>()
);
export const userInvited = createAction('[Administration] User invited');

export const loadPermissions = createAction('[Administration] Load permissions');
export const setPermissions = createAction('[Administration] Set permissions', props<{ payload: any }>());
export const successPermissions = createAction('[Administration] Success permissions');
export const errorPermissions = createAction('[Administration] Error permissions');

export const loadCatalogQualifications = createAction('[Catalog] Load category qualifications');
export const setCatalogQualifications = createAction(
  '[Catalog] Set category qualifications',
  props<{ payload: any }>()
);
export const successCatalogQualifications = createAction('[Catalog] Success category qualifications');
export const errorCatalogQualifications = createAction('[Catalog] Error category qualifications');

// Surcharges
export const loadSurcharges = createAction('[Customer] Load surcharges', props<{ payload: { location?: number } }>());
export const setSurcharges = createAction(
  '[Customer] Set surcharges',
  props<{ payload: SurchargeListItemResponse[] }>()
);
export const successSurcharges = createAction('[Customer] Success surcharges');
export const errorSurcharges = createAction('[Customer] Error surcharges');
export const updateSurcharge = createAction(
  '[Customer] Update surcharge',
  props<{ payload: { surcharge: number; location?: number; body: SurchargeUpdateRequest } }>()
);
export const successUpdateSurcharge = createAction('[Customer] Success update surcharge');
export const errorUpdateSurcharge = createAction('[Customer] Error update surcharge');

// update customer info
export const updateCustomerInfo = createAction('[Customer] Update info', props<{ payload: any }>());
export const successUpdateCustomerInfo = createAction('[Customer] Success update customer info');
export const errorUpdateCustomerInfo = createAction('[Customer] Error update customer info');

// Psp Logo update
export const updatePspLogoAction = createAction(
  '[PSP Administration] Update logo',
  props<{ payload: { body: LogoUpdateRequest } }>()
);
export const successPspLogoUpdate = createAction('[PSP Administration] Success psp logo update');
export const errorPspLogoUpdate = createAction('[PSP Administration] Error psp logo update');

// Customer Logo update
export const updateCustomerLogoAction = createAction(
  '[Customer] Update logo',
  props<{ payload: { body: LogoUpdateRequest } }>()
);
export const successCustomerLogoUpdate = createAction('[Customer] Success customer logo update');
export const errorCustomerLogoUpdate = createAction('[Customer] Error customer logo update');

// Profile Avatar update
export const updateProfileAvatarAction = createAction(
  '[Profile] Update avatar',
  props<{ payload: { body: AvatarUpdateRequest } }>()
);
export const successProfileAvatarLogoUpdate = createAction('[Profile] Success customer avatar update');
export const errorProfileAvatarUpdate = createAction('[Profile] Error customer avatar update');
export const downloadContractTemplate = createAction('[Customer] Download contract template');

// Profile Password update
export const updateProfilePassword = createAction(
  '[Profile] Update password',
  props<{ payload: { body: ProfilePasswordUpdateRequest } }>()
);
export const successProfilePasswordUpdate = createAction('[Profile] Success profile password update');
export const errorProfilePasswordUpdate = createAction('[Profile] Error profile password update');

// Update User Password
export const updateUserPassword = createAction(
  '[User] Update user password',
  props<{ payload: { user: number; body: UserPasswordUpdateRequest } }>()
);
export const successUserPasswordUpdate = createAction('[User] Success user password update');
export const errorUserPasswordUpdate = createAction('[User] Error user password update');

export const setInitState = createAction('[User] Set Init State');

export const loadLocationsData = createAction('[Location] Load locations data');
export const setLocationsData = createAction(
  '[Location] Set locations data',
  props<{ payload: LocationListItemResponse[] }>()
);

export const setCreatedLocationId = createAction(
  '[Location] Set created location id data',
  props<{ payload: number }>()
);

export const clearCreatedLocationId = createAction('[Location] Clear created location id data');
export const errorLocationsData = createAction('[Location] Error loading locations data');

export const updateLocationData = createAction(
  '[Location] Update location data',
  props<{ locationId: number; body: CustomerLocationUpdateRequest }>()
);
export const successUpdateLocationData = createAction('[Location] Successfully updated location data');
export const errorUpdateLocationData = createAction('[Location] Error updating location data');

export const locationActivationAction = createAction(
  '[Location] Activate/deactivate location',
  props<{ payload: { locationId: number; isActivation: boolean } }>()
);
export const successLocationActivationAction = createAction('[Location] Success Activation/deactivation of location');
export const errorLocationActivationAction = createAction('[Location] Error Activation/deactivation of location');

export const createLocation = createAction(
  '[Location] Create location',
  props<{ body: CreateCustomerLocationRequest }>()
);
export const successCreateLocation = createAction('[Location] Successfully created location');
export const errorCreateLocation = createAction('[Location] Error creating location');

export const loadLocationData = createAction('[Location] Load location data', props<{ locationId: number }>());
export const setLocationData = createAction('[Location] Set location data', props<{ payload: LocationResponse }>());
export const resetLocationData = createAction('[Location] Reset location data');
export const successLoadLocationData = createAction('[Location] Successfully load location data');
export const errorLoadLocationData = createAction('[Location] Error load location data');

export const loadLocationDepartmentsData = createAction(
  '[Location] Load location department data',
  props<{ locationId: number }>()
);
export const setLocationDepartmentsData = createAction(
  '[Location] Set location department data',
  props<{ locationId: number; payload: DepartmentListItemResponse[] }>()
);
export const errorLocationDepartmentsData = createAction('[Location] Error loading location department data');

export const locationDepartmentActivationAction = createAction(
  '[Location] Activate/deactivate location department',
  props<{ payload: { locationId: number; department: number; isActivation: boolean; listStatus?: boolean } }>()
);
export const successLocationDepartmentActivationAction = createAction(
  '[Location] Success Activation/deactivation of location department'
);
export const errorLocationDepartmentActivationAction = createAction(
  '[Location] Error Activation/deactivation of location department'
);

// Customer Location Terminals List
export const loadCustomerLocationTerminalsList = createAction(
  '[Administration] Load Customer Location Terminals List',
  props<{ payload: { location: number } }>()
);
export const setCustomerLocationTerminalsList = createAction(
  '[Administration] Set Customer Location Terminals List',
  props<{ payload: TerminalCustomerLocationListItemResponse[] }>()
);
export const successCustomerLocationTerminalsList = createAction(
  '[Administration] Success Customer Location Terminals List'
);
export const errorCustomerLocationTerminalsList = createAction(
  '[Administration] Error Customer Location Terminals List'
);
export const customerLocationTerminalUsageActivation = createAction(
  '[Administration] Customer Location Terminal Usage Activation',
  props<{ payload: { location: number; body: TerminalUsageRequest } }>()
);
export const successCustomerLocationTerminalUsageActivation = createAction(
  '[Administration] Success Customer Location Terminal Usage Activation'
);
export const errorCustomerLocationTerminalUsageActivation = createAction(
  '[Administration] Error Customer Location Terminal Usage Activation'
);
export const updateCustomerLocationTerminal = createAction(
  '[Administration] Update Customer Location Terminals',
  props<{ payload: { location: number; terminal: number; body: TerminalUpdateRequest } }>()
);
export const deleteCustomerLocationTerminal = createAction(
  '[Administration] Delete Customer Location Terminals',
  props<{ payload: { location: number; terminal: number } }>()
);
export const createCustomerLocationTerminal = createAction(
  '[Administration] Create Customer Location Terminals',
  props<{ payload: { location: number; body: TerminalCreateRequest } }>()
);

export const successCustomerLocationTerminalCRUD = createAction(
  '[Administration] Success Customer Location Terminal CRUD'
);
export const errorCustomerLocationTerminalCRUD = createAction('[Administration] Error Customer Location Terminal CRUD');

export const downloadTerminalAPIKey = createAction(
  '[Administration] Download terminal QR Code',
  props<{ payload: { location: number; terminal: number; openInModal?: boolean } }>()
);
export const successDownloadTerminalAPIKey = createAction('[Administration] Success Download terminal QR Code');
export const errorDownloadTerminalAPIKey = createAction('[Administration] Error Download terminal QR Code');

export const setTerminalAPIKeyView = createAction(
  '[Administration] Set Terminal API Key view',
  props<{ payload: { document: Blob; terminalId: number; fileName: string } }>()
);
export const clearTerminalAPIKeyView = createAction('[Administration] Clear Terminal API Key view');
