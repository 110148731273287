import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import {
  CatalogService,
  DocumentCategoryResponse,
  DocumentCreationResponse,
  DocumentListItemResponse,
  DocumentRequest,
  DocumentResponse,
  EmptySuccessfulResponse,
  PspService,
} from '../../../api';
import { BaseSharedHelperService } from '../../shared/services/base-shared-helper.service';

@Injectable()
export class BasePspDocumentsService {
  constructor(private readonly pspService: PspService, private readonly catalogService: CatalogService) {}

  getDocuments(psp: number): Observable<Array<DocumentListItemResponse>> {
    return this.pspService.getPspDocumentListOfDocuments({ psp }).pipe(
      take(1),
      map((response: { message: string; data: Array<DocumentListItemResponse> }) => response.data)
    );
  }

  createDocument(psp: number, file: Blob): Observable<DocumentCreationResponse> {
    return this.pspService.postPspDocumentAdd({ psp, body: { file } }).pipe(
      take(1),
      map((response: { message: string; data: DocumentCreationResponse }) => response.data)
    );
  }

  getDocumentInfo(psp: number, document: string): Observable<DocumentResponse> {
    return this.pspService.getPspDocumentShow({ psp, document }).pipe(
      take(1),
      map((response: { message: string; data: DocumentResponse }) => response.data)
    );
  }

  updateDocument(psp: number, document: string, body: DocumentRequest): Observable<EmptySuccessfulResponse> {
    return this.pspService.putPspDocumentUpdate({ psp, document, body }).pipe(take(1));
  }

  changeDocumentFile(psp: number, document: string, file: Blob): Observable<EmptySuccessfulResponse> {
    return this.pspService.postPspDocumentUpdateFile({ psp, document, body: { file } }).pipe(take(1));
  }

  deleteDocument(psp: number, document: string): Observable<EmptySuccessfulResponse> {
    return this.pspService.deletePspDocumentDelete({ psp, document }).pipe(take(1));
  }

  downloadDocument(psp: number, document: string): Observable<any> {
    return this.pspService.getPspDocumentDownload$Response({ psp, document }).pipe(
      map((response) => {
        const contentDispositionValue = response.headers.get('Content-Disposition');
        const fileName = BaseSharedHelperService.fileNameFromHeaderValue(contentDispositionValue, '=');
        return {
          body: response.body,
          fileName,
        };
      })
    );
  }

  getDocumentCategories(): Observable<DocumentCategoryResponse> {
    return this.catalogService.getDocumentsCategories().pipe(
      take(1),
      map((result) => result.data)
    );
  }
}
