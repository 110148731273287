import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { baseAuthActions, BaseCoreState } from '../../../../_store';

@Component({
  selector: 'base-logout',
  template: '',
})
export class BaseLogoutComponent implements OnInit {
  constructor(private store: Store<BaseCoreState>) {}

  ngOnInit(): void {
    this.store.dispatch(baseAuthActions.logout());
  }
}
