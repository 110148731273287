import { AbstractControl } from '@angular/forms';

export const basePasswordValidator = () => {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    const length = new RegExp('(?=\\S{8,100}$)');
    const lowerCaseLetter = new RegExp('(?=\\S*[a-z])');
    const upperCaseLetter = new RegExp('(?=\\S*[A-Z])');
    const number = new RegExp('(?=\\S*[\\d])');
    const specialCharacter = new RegExp('(?=\\S*[\\W])');
    let errors: any = {};

    if (control.value && !length.test(control.value)) {
      errors['passwordInvalidLength'] = true;
    }

    if (control.value && !lowerCaseLetter.test(control.value)) {
      errors['passwordInvalidLowerCaseLetter'] = true;
    }

    if (control.value && !upperCaseLetter.test(control.value)) {
      errors['passwordInvalidUpperCaseLetter'] = true;
    }

    if (control.value && !number.test(control.value)) {
      errors['passwordInvalidNumber'] = true;
    }

    if (control.value && !specialCharacter.test(control.value)) {
      errors['passwordInvalidSpecialCharacter'] = true;
    }

    return errors ?? null;
  };
};
