<div class="listview-empty d-flex flex-column">
  <div
    *ngIf="message"
    class="teaser"
    [ngClass]="{ 'bg-lightest': backgroundSecondary }"
  >
    <p class="m-0 fw-bold">{{ message | translate }}</p>
  </div>
  <div
    *ngIf="!hideBackgroundImage"
    class="d-flex justify-content-center listview-empty-image-section"
  >
    <img
      class="img-fluid"
      [src]="imageSource"
      [ngClass]="{ 'large-image': largeImage }"
      alt=""
    />
  </div>
</div>
