import { Modified } from '../_models/base-inquiry-dates-form';
import { ShiftTimeResponse } from '../../../../api';

export enum BaseCustomerInquiryFilters {
  department = 'customerDepartments',
  mainQualification = 'mainQualifications',
  status = 'status',
  createdBy = 'createdBy',
  search = 'search',
}

export enum BaseInquiryFilters {
  customerDepartments = 'customerDepartments',
  mainQualifications = 'mainQualifications',
  status = 'status',
  PspBranch = 'PSPBranch',
  customers = 'customers',
  createdBy = 'createdBy',
  psp = 'psp',
}

export enum BaseInquiryOffersFilters {
  status = 'status',
  rating = 'rating',
  experience = 'experience',
  PSPBranch = 'PSPBranch',
  mainQualifications = 'mainQualifications',
}

export enum BaseOfferAndCandidatesFilters {
  qualifications = 'qualifications',
  status = 'status',
  experience = 'experience',
  search = 'search',
}

export enum BaseInquiryStatus {
  unpublished = 1,
  released = 2,
  closed = 3,
}

export enum BaseOfferStatus {
  open = 0,
  declined = 2,
  confirmed = 3,
  statusRevoked = 4,
  needsConfirmation = 5,
  needsUpdate = 6,
  preselected = 100,
}

export enum BaseInquiryShifts {
  FD = 'fd',
  ZD = 'zd',
  SD = 'sd',
  ND = 'nd',
}

export enum BaseInquiryShiftsWithFlex {
  FLEX = 'flex',
  FD = 'fd',
  ZD = 'zd',
  SD = 'sd',
  ND = 'nd',
}

export type BaseInquiryShiftsWithFlexType = 'fd' | 'zd' | 'sd' | 'nd' | 'flex';

export enum BaseShiftTimeIndexes {
  fd = 0,
  zd = 1,
  sd = 2,
  nd = 3,
}

export enum BaseShiftTimeLabelByValue {
  flex = 'flexShift',
  fd = 'shiftFd',
  zd = 'shiftZd',
  sd = 'shiftSd',
  nd = 'shiftNd',
}

export enum BaseWeekDays {
  MO = 'mo',
  TU = 'tu',
  WE = 'we',
  TH = 'th',
  FR = 'fr',
  SA = 'sa',
  SU = 'su',
}

export enum BaseWeekDaysAlt {
  mo = 'mon',
  tu = 'tue',
  we = 'wed',
  th = 'thu',
  fr = 'fri',
  sa = 'sat',
  su = 'sun',
}

export enum BaseInquiryColors {
  Black = '#093957',
  Yellow = '#FFB800',
  Green = '#5AC852',
  Purple = '#6C63B8',
  LightPurple = 'rgba(108, 99, 184, 0.5)',
  Gray = '#d1d1d1',
  Red = '#ff6464',
  Blue = '#55a9e1',
  Brown = '#8c5000',
  Default = '#e8e8e8',
}

export enum BaseInquiryCandidateStatus {
  Offered = 'Offered',
  Available = 'Available',
}

export enum BaseInquiryType {
  STATUS_CREATED = 1,
  STATUS_PUBLISHED = 2,
  STATUS_CLOSED = 3,
}

export enum BaseBubbleInfoTypes {
  offered = 'offered',
  booked = 'booked',
  absent = 'absent',
}

export enum BaseInquiryShiftsBooked {
  fd = 'shiftFdBooked',
  zd = 'shiftZdBooked',
  sd = 'shiftSdBooked',
  nd = 'shiftNdBooked',
}

export enum BaseInquiryShiftsUpperCase {
  fd = 'shiftFd',
  zd = 'shiftZd',
  sd = 'shiftSd',
  nd = 'shiftNd',
}

export type ShiftTimeAndFlexResponse = Modified<
  ShiftTimeResponse,
  {
    type?: 'fd' | 'zd' | 'sd' | 'nd' | 'flex';
  }
>;

export enum BaseShiftIconsEnum {
  fd = 'fa-sunrise',
  zd = 'fa-sun-bright',
  sd = 'fa-clock',
  nd = 'fa-moon-stars',
}

export enum BaseShiftIconsWithFlexEnum {
  fd = 'fa-sunrise',
  zd = 'fa-sun-bright',
  sd = 'fa-clock',
  nd = 'fa-moon-stars',
  flex = 'fa-user-clock',
}

export enum BaseShiftPersonsEnum {
  fd = 'fdPersons',
  zd = 'zdPersons',
  sd = 'sdPersons',
  nd = 'ndPersons',
}
