<div class="rounded bg-lightest">
  <div class="px-5 py-4">
    <base-candidate-list-profile
      [profile]="assignment.profile"
      [mainQualification]="assignment.mainQualification"
      [department]="assignment.customerDepartment"
      [distance]="assignment.distance"
      [hourlyAmount]="assignment.hourlyRate"
      [hideBadges]="true"
    ></base-candidate-list-profile>
  </div>
  <div class="border-top d-flex align-items-center px-5 py-3">
    <i class="fa-solid fa-building"></i>
    <div class="d-flex flex-column ms-4">
      <span class="fw-bold"> {{ isPsp ? assignment.customer?.name : customerLocationName }} </span>
      <span> {{ assignment.customerDepartment.name }} </span>
      <span class="light-font">
        {{ 'namirialLoginModal.dateStart' | translate }} {{ assignment.startDate | baseFormatDate }}</span
      >
    </div>
  </div>
  <div
    class="pointer border-top d-flex align-items-center px-5 py-3"
    (click)="onDownload()"
    *ngIf="assignment.contractFileName !== contractFileType.NO_FILE"
  >
    <i class="fa-solid fa-file-pdf"></i>
    <div class="d-flex flex-column ms-4">
      <span class="fw-bold">
        <ng-container *ngIf="validContract?.createdAt; else employeeLeaseContractTitle">
          {{ 'namirialLoginModal.dateFrom' | translate }} {{ validContract?.createdAt | baseFormatDate }}
        </ng-container>
      </span>
      <span class="light-font">{{ assignment.contractFileName }}</span>
    </div>
  </div>
</div>

<ng-template #employeeLeaseContractTitle>
  {{ 'namirialLoginModal.employeeLeaseContract' | translate }}
</ng-template>

<ng-template #documentViewerTemplate>
  <base-document-viewer
    *ngIf="contractFile"
    [src]="contractFile.data"
    [title]="contractFile.fileName"
    [modalRef]="documentViewerModalRef"
  ></base-document-viewer>
</ng-template>
