import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { baseAdministrationActions, baseAdministrationSelectors, BaseCoreState } from '../../../_store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable()
export class BaseOrganizationManagementGuard {
  constructor(private store: Store<BaseCoreState>) {}

  canActivate(): Observable<boolean> {
    this.store.dispatch(baseAdministrationActions.loadLocationsData());

    return this.store.select(baseAdministrationSelectors.getLocationsData).pipe(
      filter((state) => !!state.ok),
      map((state) => state.ok as boolean)
    );
  }
}
