import { ElementRef, Injectable } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { TranslatePipe } from '@ngx-translate/core';
import { ContractRequest, AssignmentPspResponse, AssignmentCustomerResponse } from '../../../api';
import { BaseSignContractOptions } from '../../modules/assignments/_enums/base-sign-contract-options.enum';
import { baseAssignmentsActions, BaseCoreState, baseAssignmentsSelectors, baseSharedActions } from '../../_store';
import { BaseToastAlertClasses } from '../enums/base-toast-alert-classes.enum';
import { BaseModalService } from './base-modal.service';
import { filter, map, take } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class BaseSignatureService {
  modalRef: NgbModalRef;
  digitalSignCredentials: ContractRequest;
  baseSignContractsInfoCardIsInitSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private modalService: BaseModalService,
    private store: Store<BaseCoreState>,
    private translatePipe: TranslatePipe
  ) {}

  getAssignmentForSignUp(id: number): Promise<AssignmentPspResponse | AssignmentCustomerResponse> {
    this.store.dispatch(baseAssignmentsActions.loadAssignment({ payload: { assignment: id } }));
    return this.store
      .select(baseAssignmentsSelectors.getAssignment)
      .pipe(
        filter((assignmentState) => assignmentState.ok === true),
        map((assignmentState) => assignmentState.data),
        take(1)
      )
      .toPromise();
  }

  openSignContractModal(addSignSelectionPopup: ElementRef): NgbModalRef {
    return this.modalService.open(
      {
        headText: 'operations.signContract',
        bodyTemplate: addSignSelectionPopup,
        cancelButtonDisplayed: false,
        okButtonDisplayed: false,
        hideFooter: true,
      },
      {
        size: 'lg',
      }
    );
  }

  dispatchAssignContractAction(
    assignmentId: number,
    contractId: number,
    signingType: BaseSignContractOptions | undefined
  ): void {
    this.store.dispatch(
      baseAssignmentsActions.signContract({
        payload: {
          assignment: assignmentId,
          contract: contractId,
          signingType: signingType as BaseSignContractOptions,
        },
      })
    );
  }

  openNamirialLoginModal(loginPopup: ElementRef): NgbModalRef {
    return this.modalService.open(
      {
        bodyTemplate: loginPopup,
        cancelButtonDisplayed: false,
        okButtonDisplayed: false,
        hideHeader: true,
        hideFooter: true,
      },
      {
        size: 'lg',
      }
    );
  }

  openNamirialSignModal(signModal: ElementRef): NgbModalRef {
    return this.modalService.open(
      {
        bodyTemplate: signModal,
        cancelButtonDisplayed: false,
        okButtonDisplayed: false,
        hideCloseIcon: true,
        hideHeader: true,
        hideFooter: true,
      },
      {
        scrollable: false,
        backdrop: 'static',
        size: 'lg',
      }
    );
  }

  saveDigitalSignCredentials(event: ContractRequest): void {
    this.digitalSignCredentials = event;
  }

  showSuccessfulDigitalSigningAlert() {
    const alert = `
          <h3 class="headline-20">${this.translatePipe.transform('namirialSigningProcess.signCompletedTitle')}</h3>
          ${this.translatePipe.transform('namirialSigningProcess.signCompletedDescription')}
        `;

    this.store.dispatch(
      baseSharedActions.addSystemAlert({
        payload: {
          class: BaseToastAlertClasses.success,
          body: alert,
          closeManually: true,
        },
      })
    );
  }
}
