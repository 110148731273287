import { Injectable } from '@angular/core';
import { HttpContext } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import {
  BestMatchingProfileListItemResponse,
  CandidateAvailability,
  CreatedResponse,
  CustomerAssignmentFilterListsResponse,
  CustomerInquiryListItemResponse,
  CustomerInquiryOfferListItemResponse,
  CustomerInquiryService,
  CustomerService,
  EmptySuccessfulResponse,
  InquiryCreateRequest,
  InquiryDate,
  InquiryDateResponse,
  InquiryOfferAcceptRequest,
  InquiryOfferCreateRequest,
  InquiryOfferDeclineRequest,
  InquiryOfferUpdateRequest,
  InquiryResponse,
  InquiryUpdateStatusRequest,
  OfferPositionResponse,
  PaginatedApiResponse,
  ProfileAbsenceResponse,
  ProfileOfferPositionResponse,
  PspBranchShortInfoResponse,
  PspCustomerService,
  PspInquiryListItemResponse,
  PspInquiryOfferListItemResponse,
  PspInquiryService,
  PspOfferService,
  QualificationPricingListResponse,
  RequirementCategoryResponse,
  RequirementResponse,
  RevocationRequest,
  UserShortInfoResponse,
} from '../../../../api';
import { BaseDateService } from '../../../shared/services/base-date.service';
import { BaseSharedHelperService } from '../../../shared/services/base-shared-helper.service';
import { CustomerInquiryOrderKeyEnum } from '../../../shared/enums/customer-inquiry-order-key.enum';
import { BaseOrderDirectionEnum } from '../../../shared/enums/base-order-direction.enum';
import {
  BaseInquiryOfferOrderDirection,
  BaseInquiryOfferOrderKey,
  BaseInquiryOfferStatus,
} from '../_interfaces/base-inquiry.interface';
import { PspInquryOrderKeyEnum } from '../../../shared/enums/psp-inqury-order-key.enum';
import {
  BaseInquiryShifts,
  BaseInquiryShiftsUpperCase,
  BaseInquiryShiftsWithFlex,
  BaseInquiryShiftsWithFlexType,
  BaseOfferStatus,
  BaseWeekDays,
} from '../_enums/base-inquiry.enum';
import { BaseAbsencePayloadTypeEnum } from '../../../shared/enums/base-absence-type.enum';
import { BasePspInquiryOfferShiftInterface } from '../_interfaces/base-psp-inquiry-offer.interface';
import { BaseInquiryCandidateShiftInterface } from '../_interfaces/base-inquiry-candidate.interface';
import { getWeekDayFromDate } from '../../../shared/helpers/base-get-weekday-from-date';

@Injectable()
export class BaseInquiryService {
  toggleInquirySidebarSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  inquirySubject$: BehaviorSubject<Partial<InquiryResponse>> = new BehaviorSubject<Partial<InquiryResponse>>({});

  constructor(
    private customerInquiryService: CustomerInquiryService,
    private customerService: CustomerService,
    private pspInquiryService: PspInquiryService,
    private pspOfferService: PspOfferService,
    private pspCustomerService: PspCustomerService
  ) {}

  getInquiries(body: {
    customer: number;
    page?: number;
    size?: number;
    'statuses[]'?: string[];
    'mainQualifications[]'?: number[];
    'customerDepartments[]'?: number[];
    includeDates?: boolean;
    current?: boolean;
    search?: string;
    createdBy?: any;
    orderDirection?: BaseOrderDirectionEnum;
    orderKey?: CustomerInquiryOrderKeyEnum;
  }): Observable<PaginatedApiResponse & { data?: CustomerInquiryListItemResponse[] }> {
    return this.customerInquiryService.getCustomerListOfInquiries(body);
  }

  createInquiry(customer: number, body: InquiryCreateRequest): Observable<CreatedResponse> {
    return this.customerInquiryService.postInquiryCreate({ customer, body }).pipe(take(1));
  }

  getPSPInquiries(body: {
    page?: number;
    size?: number;
    status?: any;
    'customers[]'?: number[];
    'customerDepartments[]'?: number[];
    PSPBranch?: number;
    'mainQualifications[]'?: number[];
    psp: number;
    orderDirection?: BaseOrderDirectionEnum;
    orderKey?: PspInquryOrderKeyEnum;
  }): Observable<PaginatedApiResponse & { data?: PspInquiryListItemResponse[] }> {
    return this.pspInquiryService.getPspListOfInquiries(body);
  }

  ungroupRequirements(groupedRequirements: RequirementCategoryResponse[] = []): RequirementResponse[] {
    let result: RequirementResponse[] = [];
    groupedRequirements.forEach((item: RequirementCategoryResponse) => {
      result = [...result, ...item.requirements];
    });
    return result;
  }

  getInquiryWeekdays(dates: InquiryDateResponse[]): Record<BaseWeekDays, boolean> {
    const weekdays = {
      [BaseWeekDays.MO]: false,
      [BaseWeekDays.TU]: false,
      [BaseWeekDays.WE]: false,
      [BaseWeekDays.TH]: false,
      [BaseWeekDays.FR]: false,
      [BaseWeekDays.SA]: false,
      [BaseWeekDays.SU]: false,
    };

    let trueCount = 0;

    for (const dateInfo of dates) {
      const day = getWeekDayFromDate(dateInfo.date);

      if (!weekdays[day as BaseWeekDays]) {
        weekdays[day as BaseWeekDays] = true;
        trueCount++;
      }

      if (trueCount === 7) break;
    }

    return weekdays;
  }

  getBestCandidatesByInquiry(
    inquiry: number,
    psp: number,
    size: number,
    page?: number,
    orderKey?: 'profileLastName' | 'profileMainQualificationName',
    orderDirection?: BaseOrderDirectionEnum,
    filters?: any
  ): Observable<
    PaginatedApiResponse & {
      data?: Array<BestMatchingProfileListItemResponse>;
    }
  > {
    return this.pspInquiryService.getAppApiWebV1PspInquiryProfileListIndex({
      psp,
      inquiry,
      search: filters?.search,
      page,
      size,
      'mainQualifications[]': filters?.qualifications,
      orderKey,
      orderDirection,
    });
  }

  getOffersByInquiry(
    inquiry: number,
    psp: number,
    size?: number,
    page?: number,
    orderKey?: 'profileLastName' | 'qualificationName',
    orderDirection?: 'ASC' | 'DESC',
    filters?: any
  ): Observable<
    PaginatedApiResponse & {
      data?: Array<PspInquiryOfferListItemResponse>;
    }
  > {
    return this.pspOfferService.getPspInquiryOffersList({
      inquiry,
      psp,
      search: filters?.search,
      page,
      size,
      status: filters?.status,
      'mainQualifications[]': filters?.qualifications,
      experience: filters?.experience,
      orderKey,
      orderDirection,
    });
  }

  publishInquiry(inquiry: number, customer: number, body: InquiryUpdateStatusRequest): Observable<any> {
    return this.customerInquiryService.patchCustomerInquiryUpdateStatus({
      inquiry,
      customer,
      body,
    });
  }

  getPSPInquiry(params: { inquiry: number; psp: number }): Observable<InquiryResponse> {
    return this.pspInquiryService.getPspInquiryShow(params).pipe(map((response) => response.data));
  }

  createdOfferPositionExists(
    date: string,
    offerPositions: OfferPositionResponse[] = [],
    currentShift: string
  ): boolean {
    const offerPosition = offerPositions.find((pos) => pos.date === date);
    return !!offerPosition && this.checkShiftType(currentShift, offerPosition);
  }

  acceptedOfferPositionExists(date: string, offer: PspInquiryOfferListItemResponse, currentShift: string): boolean {
    const isOfferConfirmed = offer.status === BaseOfferStatus.confirmed;
    return this.areOfferPositionsExist(date, offer, currentShift) && isOfferConfirmed;
  }

  offerPositionIsAcceptedButNotConfirmed(
    date: string,
    offer: PspInquiryOfferListItemResponse,
    currentShift: string
  ): boolean {
    const isOfferNeedsConfirmation = offer.status === BaseOfferStatus.needsConfirmation;
    return this.areOfferPositionsExist(date, offer, currentShift) && isOfferNeedsConfirmation;
  }

  checkAvailabilityByDirectDay(currentDate: string, availability: CandidateAvailability): boolean {
    return !!availability[
      BaseDateService.format(new Date(currentDate), 'EEE').toLowerCase() as keyof CandidateAvailability
    ];
  }

  checkAvailabilityByDateAndBookedPositions(currentDate: string, availability: CandidateAvailability): boolean {
    return !!availability[
      BaseDateService.format(new Date(currentDate), 'EEE').toLowerCase() as keyof CandidateAvailability
    ];
  }

  checkAvailabilityForDirectShift(shift: string, availability: CandidateAvailability): boolean {
    if (shift === BaseInquiryShiftsWithFlex.FLEX) {
      return true;
    }
    return !!availability[shift as keyof CandidateAvailability];
  }

  isHolidayForDate(date: string, absences: Array<ProfileAbsenceResponse>): boolean {
    return this.isAbsentForDateByAbsenceType(BaseAbsencePayloadTypeEnum.holiday, date, absences);
  }

  isOtherForDate(date: string, absences: Array<ProfileAbsenceResponse>): boolean {
    return this.isAbsentForDateByAbsenceType(BaseAbsencePayloadTypeEnum.other, date, absences);
  }

  isTimeOffForDate(date: string, absences: Array<ProfileAbsenceResponse>): boolean {
    return this.isAbsentForDateByAbsenceType(BaseAbsencePayloadTypeEnum.timeOffCompensation, date, absences);
  }

  isUnexcusedForDate(date: string, absences: Array<ProfileAbsenceResponse>): boolean {
    return this.isAbsentForDateByAbsenceType(BaseAbsencePayloadTypeEnum.unexcusedAbsence, date, absences);
  }

  isFreeShiftForDate(date: string, absences: Array<ProfileAbsenceResponse>): boolean {
    return this.isAbsentForDateByAbsenceType(BaseAbsencePayloadTypeEnum.freeShift, date, absences);
  }

  isAssignedOutsideForDate(date: string, absences: Array<ProfileAbsenceResponse>): boolean {
    return this.isAbsentForDateByAbsenceType(BaseAbsencePayloadTypeEnum.offline, date, absences);
  }

  isIllForDate(date: string, absences: Array<ProfileAbsenceResponse>): boolean {
    return this.isAbsentForDateByAbsenceType(BaseAbsencePayloadTypeEnum.illness, date, absences);
  }

  isAbsentForDate(date: string, absences: Array<ProfileAbsenceResponse>): boolean {
    return !!absences.find((absence) => {
      return (
        (BaseDateService.isBefore(new Date(date), new Date(absence.endDate)) &&
          BaseDateService.isAfter(new Date(date), new Date(absence.startDate))) ||
        date === absence.startDate ||
        date === absence.endDate
      );
    });
  }

  offerPositionExistsAndSelected(
    date: string,
    offerPositions: ProfileOfferPositionResponse[] = [],
    currentShift: string,
    inquiryId: number,
    _inquiryDates: InquiryDate[]
  ): boolean {
    const offerPosition = offerPositions.find((pos) => pos.date === date);

    // BE SHOULD IMPROVE OFFER POSITION AND ADD SELECTED PROP TO IT
    // @ts-ignore
    return (
      !!offerPosition &&
      offerPosition.selectedShift &&
      this.checkShiftType(currentShift, offerPosition) &&
      offerPosition.inquiryId?.toString() === inquiryId.toString()
    );
  }

  offerPositionExistsAndSelectedForOtherInquiry(
    date: string,
    offerPositions: ProfileOfferPositionResponse[] = [],
    currentShift: string,
    inquiryId: number
  ): boolean {
    const offerPosition = offerPositions.find((pos) => pos.date === date);

    return (
      !!offerPosition &&
      !!offerPosition.selectedShift &&
      this.checkShiftType(currentShift, offerPosition) &&
      offerPosition.inquiryId?.toString() !== inquiryId.toString()
    );
  }

  inquiryDateExists(
    date: string,
    inquiryDates: InquiryDateResponse[],
    shift: BaseInquiryShiftsWithFlexType,
    deselectedItems: { shift: string; date: string }[]
  ): boolean {
    const optimizedDeselectedItems = deselectedItems.filter((item) => item.shift === shift);

    if (shift === BaseInquiryShiftsWithFlex.FLEX) {
      return Object.values(BaseInquiryShiftsUpperCase).some(
        (item) => inquiryDates.find((value) => value.date === date && value.flexShift)?.[item] ?? false
      );
    } else {
      return !!inquiryDates.find((inquiryDate) => {
        return (
          inquiryDate.date === date &&
          !!inquiryDate[BaseInquiryShiftsUpperCase[shift]] &&
          !optimizedDeselectedItems.find((item) => item.date === inquiryDate.date)
        );
      });
    }
  }

  getCustomerInquiryOffers(
    customer: number,
    inquiry: number,
    profileAverageRating?: number,
    status?: BaseInquiryOfferStatus,
    mainQualifications?: number[],
    pspBranches?: number[],
    experience?: boolean,
    orderKey?: BaseInquiryOfferOrderKey,
    orderDirection?: BaseInquiryOfferOrderDirection,
    search?: string,
    page?: number,
    size?: number
  ): Observable<
    PaginatedApiResponse & {
      data?: Array<CustomerInquiryOfferListItemResponse>;
      filters?: CustomerAssignmentFilterListsResponse;
    }
  > {
    let params = {
      customer,
      inquiry,
      profileAverageRating,
      status,
      'mainQualifications[]': mainQualifications,
      'pspBranches[]': pspBranches,
      experience,
      orderKey,
      orderDirection,
      page,
      size,
    };
    if (search) {
      params = Object.assign({}, params, { search });
    }

    return this.customerInquiryService.getCustomerInquiryOffersList(params);
  }

  updateInquiryStatus(inquiry: number, customer: number, status: 1 | 2 | 3, closedNote?: string): Observable<any> {
    return this.customerInquiryService.patchCustomerInquiryUpdateStatus({
      inquiry,
      customer,
      body: { status, closedNote },
    });
  }

  getCustomerInquiry(customer: number, inquiry: number): Observable<InquiryResponse> {
    return this.customerInquiryService
      .getCustomerInquiryShow({ customer, inquiry })
      .pipe(map((response) => response.data));
  }

  getContractDocument(customer: number): Observable<any> {
    return this.customerService.getCustomerContractTemplateDownload$Response({ customer }).pipe(
      map((response) => {
        const contentDispositionValue = response.headers.get('Content-Disposition');
        const fileName = BaseSharedHelperService.fileNameFromHeaderValue(contentDispositionValue, '=');
        return {
          body: response.body,
          fileName,
        };
      })
    );
  }

  getCustomerInquiryOfferDocumentFileDownload(params: {
    customer: number;
    inquiry: number;
    offer: number;
    document: string;
    context?: HttpContext;
  }): Observable<any> {
    return this.customerInquiryService.getCustomerInquiryOfferDocumentFileDownload$Response(params).pipe(
      map((response) => {
        const contentDispositionValue = response.headers.get('Content-Disposition');
        const fileName = BaseSharedHelperService.fileNameFromHeaderValue(contentDispositionValue, '=');
        return {
          body: response.body,
          fileName,
        };
      })
    );
  }

  responsibleUsersList(params: {
    psp: number;
    inquiry: number;
  }): Observable<(UserShortInfoResponse & { fullName: string })[]> {
    return this.pspInquiryService.getPspInquiryResponsibleList(params).pipe(
      map((response) =>
        (response.data || []).map((user: any) => {
          user.fullName = `${user.firstName} ${user.lastName}`;
          return user;
        })
      )
    );
  }

  createInquiryOffer(params: {
    psp: number;
    inquiry: number;
    body: InquiryOfferCreateRequest;
  }): Observable<CreatedResponse> {
    return this.pspOfferService.postPspInquiryOffersCreate(params);
  }

  updateInquiryOffer(params: {
    offer: number;
    psp: number;
    inquiry: number;
    body: InquiryOfferUpdateRequest;
  }): Observable<EmptySuccessfulResponse> {
    return this.pspOfferService.putPspInquiryOffersUpdate(params);
  }

  pspCustomerQualificationPrice(params: {
    psp: number;
    customer: number;
    qualification: number;
    location: number;
  }): Observable<QualificationPricingListResponse> {
    return this.pspCustomerService
      .getAppApiWebV1PspCustomerPriceQualificationListIndex(params)
      .pipe(map((response) => response.data));
  }

  acceptOffer(
    offer: number,
    customer: number,
    inquiry: number,
    body: InquiryOfferAcceptRequest
  ): Observable<EmptySuccessfulResponse> {
    return this.customerInquiryService.patchCustomerInquiryOfferAccept({
      offer,
      customer,
      inquiry,
      body,
    });
  }

  getPspCustomerContractTemplateFile(params: { psp: number; customer: number }): Observable<any> {
    return this.pspCustomerService.getPspCustomerContractTemplateDownload$Response(params).pipe(
      map((response) => {
        const contentDispositionValue = response.headers.get('Content-Disposition');
        const fileName = BaseSharedHelperService.fileNameFromHeaderValue(contentDispositionValue, '=');
        return {
          body: response.body,
          fileName,
        };
      })
    );
  }

  declineOfferCustomer(params: {
    customer: number;
    offer: number;
    inquiry: number;
    body: InquiryOfferDeclineRequest;
  }): Observable<EmptySuccessfulResponse> {
    return this.customerInquiryService.putCustomerInquiryOfferDecline(params);
  }

  revokeOffer(
    psp: number,
    inquiry: number,
    offer: number,
    body: RevocationRequest
  ): Observable<EmptySuccessfulResponse> {
    return this.pspOfferService.postPspInquiryOffersRevoke({
      psp,
      inquiry,
      offer,
      body,
    });
  }

  updateCustomerInquiry(params: {
    customer: number;
    inquiry: string;
    body: InquiryCreateRequest;
  }): Observable<EmptySuccessfulResponse> {
    return this.customerInquiryService.putCustomerInquiryUpdate(params);
  }

  getPspInquiryBranchList(orgId: number, inquiry: number): Observable<Array<PspBranchShortInfoResponse>> {
    return this.pspInquiryService
      .getPspInquiryBranchList({ psp: orgId, inquiry })
      .pipe(map((response) => response.data));
  }

  checkIsFlexShiftsExist(positions: OfferPositionResponse[] | InquiryDateResponse[]): boolean {
    return (positions || []).some((position) => position.flexShift === true);
  }

  checkIsShiftsExist(
    shiftType: BaseInquiryShifts,
    positions: OfferPositionResponse[] | InquiryDateResponse[],
    isFlex = false
  ): boolean {
    return positions.some((position) => {
      return isFlex
        ? !position.flexShift && position[BaseInquiryShiftsUpperCase[shiftType]]
        : position[BaseInquiryShiftsUpperCase[shiftType]];
    });
  }

  isBubbleDotVisible(
    shifts: BaseInquiryCandidateShiftInterface[] | BasePspInquiryOfferShiftInterface[],
    shiftType: BaseInquiryShiftsWithFlexType
  ): boolean {
    const currentShift = shifts.find((item) => item.shift === shiftType);
    if (!currentShift?.index) {
      return false;
    }
    const bubblesToCheck = shifts.filter((item) => (item.index as number) < (currentShift.index as number));
    return (
      bubblesToCheck.some((bubble) => bubble.currentValue === currentShift.currentValue) ||
      (shiftType !== BaseInquiryShiftsWithFlex.FLEX && shifts.every((item) => item.isFlexibleShift))
    );
  }

  isTwoDatesAreSimilar(inquiryDate1: InquiryDateResponse, inquiryDate2: InquiryDateResponse): boolean {
    if (inquiryDate1.flexShift !== inquiryDate2.flexShift) {
      return false;
    }

    return Object.values(BaseInquiryShiftsUpperCase).every((shift) => inquiryDate1[shift] === inquiryDate2[shift]);
  }

  preselectCustomerInquiryOffer(params: {
    customer: number;
    inquiry: number;
    offer: number;
  }): Observable<EmptySuccessfulResponse> {
    return this.customerInquiryService.postCustomerInquiryOfferPreSelection(params);
  }

  unselectCustomerInquiryOffer(params: {
    customer: number;
    inquiry: number;
    offer: number;
  }): Observable<EmptySuccessfulResponse> {
    return this.customerInquiryService.deleteCustomerInquiryOfferUnselection(params);
  }

  private isAbsentForDateByAbsenceType(
    type: BaseAbsencePayloadTypeEnum,
    date: string,
    absences: Array<ProfileAbsenceResponse>
  ): boolean {
    return !!absences.find((absence) => {
      return (
        (absence.type === type &&
          BaseDateService.isBefore(new Date(date), new Date(absence.endDate)) &&
          BaseDateService.isAfter(new Date(date), new Date(absence.startDate))) ||
        (absence.type === type && date === absence.startDate) ||
        (absence.type === type && date === absence.endDate)
      );
    });
  }

  private checkShiftType(
    currentShift: string,
    inquiryDate: OfferPositionResponse | ProfileOfferPositionResponse
  ): boolean {
    switch (currentShift) {
      case BaseInquiryShiftsWithFlex.FD:
        return !!inquiryDate?.shiftFd;
      case BaseInquiryShiftsWithFlex.ND:
        return !!inquiryDate?.shiftNd;
      case BaseInquiryShiftsWithFlex.SD:
        return !!inquiryDate?.shiftSd;
      case BaseInquiryShiftsWithFlex.ZD:
        return !!inquiryDate?.shiftZd;
      case BaseInquiryShiftsWithFlex.FLEX:
        if (inquiryDate?.flexShift) {
          return !!inquiryDate?.shiftFd || !!inquiryDate?.shiftNd || !!inquiryDate?.shiftSd || !!inquiryDate?.shiftZd;
        } else {
          return false;
        }
      default:
        return false;
    }
  }

  private areOfferPositionsExist(date: string, offer: PspInquiryOfferListItemResponse, currentShift: string): boolean {
    const offerPositions: OfferPositionResponse[] = offer.positions || [];

    const offerPosition = offerPositions.find((pos) => pos.date === date);
    return (
      !!offerPosition &&
      this.checkShiftType(currentShift, offerPosition) &&
      (offerPosition.selectedShift === currentShift || (!!offerPosition.flexShift && !!offerPosition.selectedShift))
    );
  }
}
