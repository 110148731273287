export enum BaseCustomerEmployeesFiltersEnum {
  status = 'status',
  mainQualification = 'mainQualification',
  openContract = 'openContract',
  fromDate = 'fromDate',
  pspBranch = 'pspBranch',
  department = 'department',
  search = 'search',
}

export type BaseCustomerEmployeesFiltersType =
  | BaseCustomerEmployeesFiltersEnum.status
  | BaseCustomerEmployeesFiltersEnum.mainQualification
  | BaseCustomerEmployeesFiltersEnum.openContract
  | BaseCustomerEmployeesFiltersEnum.fromDate
  | BaseCustomerEmployeesFiltersEnum.search
  | BaseCustomerEmployeesFiltersEnum.pspBranch
  | BaseCustomerEmployeesFiltersEnum.department;
