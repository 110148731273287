import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { NonNullableFormBuilder } from '@angular/forms';
import { Store } from '@ngrx/store';
import { SurchargeListItemResponse } from '../../../../../../api';
import { BaseHelpersService } from '../../../../../core/services/base-helpers.service';
import { BaseButtonSize } from '../../../../../shared/components/button/base-button.component';
import { BaseCoreState, baseAdministrationActions } from '../../../../../_store';
import { baseNumberTimeValidator } from '../../../../../shared/validators/base-number-time.validator';
import { InitialFormModel } from '../../../_models/base-terms-of-contract.model';

@Component({
  selector: 'base-terms-of-contract-surcharge',
  templateUrl: './base-terms-of-contract-surcharge.component.html',
  styleUrls: ['./base-terms-of-contract-surcharge.component.scss'],
})
export class BaseTermsOfContractSurchargeComponent implements OnChanges {
  @Input() surcharge: SurchargeListItemResponse;
  @Input() disabled = false;
  @Output() editChange = new EventEmitter<boolean>();
  isEdited = false;
  buttonSizes = BaseButtonSize;
  surchargeForm = this.fb.group({
    fromTime: [''],
    toTime: [''],
    rate: [null as number | null, [baseNumberTimeValidator()]],
    cumulative: [false],
  });
  initialFormValue: InitialFormModel = new InitialFormModel({});
  submitted = false;

  constructor(private fb: NonNullableFormBuilder, private store: Store<BaseCoreState>) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.surcharge) {
      this.surchargeForm.patchValue({
        fromTime: changes.surcharge.currentValue.fromTime,
        toTime: changes.surcharge.currentValue.toTime,
        rate: changes.surcharge.currentValue.rate,
        cumulative: changes.surcharge.currentValue.cumulative,
      });
    }
    this.initialFormValue = this.surchargeForm.getRawValue();
  }

  handleSurchargeForm(): void {
    this.submitted = this.isEdited;
    const isFormChanged = !BaseHelpersService.objectsAreEqual(this.initialFormValue, this.surchargeForm.value);
    if (this.isEdited && isFormChanged && this.surchargeForm.valid) {
      const surchargeFormValue = this.surchargeForm.getRawValue();
      const body = {
        cumulative: surchargeFormValue.cumulative,
        fromTime: surchargeFormValue.fromTime,
        toTime: surchargeFormValue.toTime,
        rate: surchargeFormValue.rate || 0,
      };

      this.store.dispatch(
        baseAdministrationActions.updateSurcharge({
          payload: { surcharge: this.surcharge.id, body },
        })
      );
    }
    this.isEdited = isFormChanged && !this.surchargeForm.valid ? true : !this.isEdited;
    this.editChange.emit(this.isEdited);
  }

  cancelSurchargeEdit() {
    this.isEdited = false;
    this.submitted = false;
    this.surchargeForm.patchValue(this.initialFormValue);
    this.editChange.emit(this.isEdited);
  }
}
