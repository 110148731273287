import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { BaseCoreState } from '../reducers';
import { baseAuthSelectors } from '../selectors';
import { baseInquiryActions, baseOffersAndBookingsActions } from '../actions';
import { BaseOffersAndBookingsService } from '../../modules/offers-and-bookings/base-offers-and-bookings.service';

@Injectable()
export class BaseOffersAndBookingsEffects {
  constructor(
    private actions$: Actions,
    private store: Store<BaseCoreState>,
    private offersAndBookingsService: BaseOffersAndBookingsService
  ) {}

  loadOfferOverlapsList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseOffersAndBookingsActions.loadOfferOverlapsList),
      withLatestFrom(this.store.select(baseAuthSelectors.getUserInfo)),
      switchMap(([action, user]) => {
        const page = action.payload?.page ? action.payload.page : null;
        return this.offersAndBookingsService
          .getPspOfferOverlapList({
            psp: user.data?.organizationId as number,
            page: action.payload.page,
            size: action.payload.size,
            profile: action.payload.profile,
            customer: action.payload.customer,
          })
          .pipe(
            mergeMap((response) => [
              baseOffersAndBookingsActions.setOfferOverlapsList({ payload: response }),
              baseOffersAndBookingsActions.successOfferOverlapsList(),
            ]),
            catchError(() => of(baseOffersAndBookingsActions.errorOfferOverlapsList()))
          );
      })
    );
  });

  confirmPspInquiryOffer$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseOffersAndBookingsActions.confirmPspInquiryOffer),
      withLatestFrom(this.store.select(baseAuthSelectors.getUserInfo)),
      switchMap(([action, user]) => {
        return this.offersAndBookingsService
          .confirmPspInquiryOffer({
            psp: user.data?.organizationId as number,
            inquiry: action.payload.inquiry,
            offer: action.payload.offer,
            body: action.payload.body,
          })
          .pipe(
            mergeMap((response) => {
              return action.payload.isInquiryDetailsScreen
                ? [
                    baseOffersAndBookingsActions.confirmPspInquiryOfferSuccess(),
                    baseInquiryActions.loadPspInquiryOffersList({
                      payload: { inquiry: action.payload.inquiry, ...action.payload.filters },
                    }),
                    baseInquiryActions.loadInquiryCandidatesList({
                      payload: { inquiry: action.payload.inquiry },
                    }),
                    baseInquiryActions.loadPSPInquiry({ payload: action.payload.inquiry }),
                  ]
                : [
                    baseOffersAndBookingsActions.confirmPspInquiryOfferSuccess(),
                    baseOffersAndBookingsActions.loadOfferOverlapsList({ payload: {} }),
                  ];
            }),
            catchError(() => of(baseOffersAndBookingsActions.confirmPspInquiryOfferError()))
          );
      })
    );
  });

  withdrawPspInquiryOffer$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseOffersAndBookingsActions.withdrawPspInquiryOffer),
      withLatestFrom(this.store.select(baseAuthSelectors.getUserInfo)),
      switchMap(([action, user]) => {
        return this.offersAndBookingsService
          .withdrawPspInquiryOffer({
            psp: user.data?.organizationId as number,
            inquiry: action.payload.inquiry,
            offer: action.payload.offer,
          })
          .pipe(
            mergeMap(() =>
              action.payload.isInquiryOffersPage
                ? [
                    baseOffersAndBookingsActions.withdrawPspInquiryOfferSuccess(),
                    baseInquiryActions.loadPspInquiryOffersList({
                      payload: { inquiry: action.payload.inquiry },
                    }),
                    baseInquiryActions.loadInquiryCandidatesList({
                      payload: { inquiry: action.payload.inquiry },
                    }),
                    baseInquiryActions.loadPSPInquiry({ payload: action.payload.inquiry }),
                  ]
                : [
                    baseOffersAndBookingsActions.withdrawPspInquiryOfferSuccess(),
                    baseOffersAndBookingsActions.loadOfferOverlapsList({ payload: {} }),
                  ]
            ),
            catchError(() => of(baseOffersAndBookingsActions.withdrawPspInquiryOfferError()))
          );
      })
    );
  });
}
