import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import {
  BestMatchingProfileListItemResponse,
  CandidateAvailability,
  InquiryDateResponse,
  InquiryResponse,
  ProfileAbsenceResponse,
  ProfileOfferPositionResponse,
  ProfileOfferResponse,
} from '../../../../api';
import { BaseDateService, BASE_DATE_SERVICE_FORMAT } from '../../../shared/services/base-date.service';
import { BaseInquiryBubbleColorAndValueInterface } from '../_interfaces/base-inquiry-bubble-color-and-value.interface';
import {
  BaseInquiryCandidateBubbleSelectPayload,
  BaseInquiryCandidateDateInterface,
  BaseInquiryCandidateInterface,
  BaseInquiryCandidateShiftInterface,
  BaseUnlimitedInquiryCandidateInterface,
} from '../_interfaces/base-inquiry-candidate.interface';
import { BaseInquiryBubbleColors } from '../_enums/base-inquiry-bubble-colors.enum';
import { BaseInquiryBubbleValues } from '../_enums/base-inquiry-bubble-values.enum';
import {
  BaseInquiryShiftsWithFlex,
  BaseInquiryShiftsWithFlexType,
  BaseOfferStatus,
  BaseShiftTimeLabelByValue,
  BaseWeekDaysAlt,
} from '../_enums/base-inquiry.enum';
import {
  BASE_INQUIRY_CALENDAR_MAX_DAYS_TO_SCROLL,
  BASE_INQUIRY_CALENDAR_ORDERED_SHIFTS,
} from '../_const/base-inquiry-calendar.const';
import { BaseInquiryService } from './base-inquiry.service';
import { BaseInquiryBubbleClasses } from '../_enums/base-inquiry-bubble-classes.enum';
import { BaseToastAlertClasses } from '../../../shared/enums/base-toast-alert-classes.enum';
import { baseSharedActions, BaseCoreState } from '../../../_store';
import { BASE_INQUIRY_CANDIDATES_VISIBLE_LIMIT } from '../_const/base-inquiry-candidates-visible-limit.const';
import {
  getClosestDayOfLastWeek,
  getDaysArrayWithWeekdays,
} from '../../../shared/helpers/base-get-dates-array-between-two-dates';
import { baseIsDeepEqual } from '../../../shared/helpers/base-is-deep-equal';

@Injectable()
export class BaseInquiryCandidateService {
  constructor(
    private baseInquiryService: BaseInquiryService,
    private translateService: TranslateService,
    private store: Store<BaseCoreState>
  ) {}

  prepareCandidateBubblesList<T>(
    candidates: BestMatchingProfileListItemResponse[],
    inquiry: InquiryResponse,
    inquiryId: number,
    toLimitLeft = 0,
    isUnlimited = false
  ): T[] {
    const currentDate = BaseDateService.toDate(inquiry.dateFrom as string);

    if (isUnlimited) {
      const candidatesList: BaseUnlimitedInquiryCandidateInterface[] = candidates.map((candidate, index) => {
        const dates: BaseInquiryCandidateDateInterface[] = this.prepareCandidateDates(
          inquiry,
          currentDate,
          candidate,
          inquiryId
        );
        const isOfferedElsewhere: boolean = dates.some((dateItem) => dateItem.isCandidateOfferedElsewhereForDate);
        const candidateEnrichmentResponse = this.enrichCandidates(candidate, inquiry);
        return {
          data: {
            ...candidate,
            isSelected: false,
            isOfferedElsewhere,
            isVisible: index < (toLimitLeft || BASE_INQUIRY_CANDIDATES_VISIBLE_LIMIT),
            availableFrom: candidateEnrichmentResponse[0] as string,
            otherOffers: candidateEnrichmentResponse[1] as ProfileOfferResponse[] | undefined,
            absencesInRange: candidateEnrichmentResponse[2] as ProfileAbsenceResponse[] | undefined,
          },
          dates,
        };
      });

      return candidatesList as T[];
    }

    const candidatesList: BaseInquiryCandidateInterface[] = candidates.map((candidate, index) => {
      const dates: BaseInquiryCandidateDateInterface[] = this.prepareCandidateDates(
        inquiry,
        currentDate,
        candidate,
        inquiryId
      );
      const isOfferedElsewhere: boolean = dates.some((dateItem) => dateItem.isCandidateOfferedElsewhereForDate);

      return {
        data: {
          ...candidate,
          isSelected: false,
          isOfferedElsewhere,
          isVisible: index < (toLimitLeft || BASE_INQUIRY_CANDIDATES_VISIBLE_LIMIT),
        },
        dates,
      };
    });

    return candidatesList as T[];
  }

  updateCandidatesListWithSelectedCandidate(
    candidates: BaseInquiryCandidateInterface[],
    candidateId: number
  ): BaseInquiryCandidateInterface[] {
    const updatedList = this.selectCandidateInTheList(candidates, candidateId);
    return this.updateShiftsDuringCandidateSelection(updatedList);
  }

  updateCandidatesListWithSelectedBubble(
    candidates: BaseInquiryCandidateInterface[],
    payload: BaseInquiryCandidateBubbleSelectPayload
  ): BaseInquiryCandidateInterface[] {
    const selectedCandidateIndex: number = candidates.findIndex(
      (candidate) => candidate.data.id === payload.candidateId
    );

    const updatedCandidates = candidates.slice(); // a shallow copy to make list editable

    const updatedDates = updatedCandidates[selectedCandidateIndex].dates.map((dateItem) => {
      let isDeselectionPrevented = false;
      if (dateItem.date === payload.date && dateItem.shifts.every((shift) => shift.isFlexibleShift)) {
        const isDeselectAction = !!dateItem.shifts.find((item) => item.shift === payload.shift.shift)?.isSelected;
        const numberOfSelectedFlexShiftForDate = dateItem.shifts.filter(
          (item) =>
            item.shift !== BaseInquiryShiftsWithFlex.FLEX &&
            item.currentValue === BaseInquiryBubbleValues.Available &&
            item.isSelected
        ).length;
        isDeselectionPrevented =
          isDeselectAction &&
          numberOfSelectedFlexShiftForDate <= 2 &&
          payload.shift.shift !== BaseInquiryShiftsWithFlex.FLEX;
      }

      if (isDeselectionPrevented) {
        this.store.dispatch(
          baseSharedActions.addUniqueSystemAlert({
            payload: {
              class: BaseToastAlertClasses.warning,
              body: 'inquiry.flexShiftSelectError',
              unique: true,
            },
          })
        );
      }

      return dateItem.date === payload.date && !isDeselectionPrevented
        ? {
            ...dateItem,
            shifts: dateItem.shifts.map((shiftItem) => this.updateShift(shiftItem, payload.shift, dateItem.shifts)),
          }
        : dateItem;
    });

    updatedCandidates[selectedCandidateIndex] = { ...updatedCandidates[selectedCandidateIndex], dates: updatedDates };

    return updatedCandidates;
  }

  allFlexShiftsSelection(
    candidates: BaseInquiryCandidateInterface[],
    candidateId: number
  ): BaseInquiryCandidateInterface[] {
    return candidates.map((candidate) => {
      const isSelectedCandidate = candidate.data.id === candidateId;
      return isSelectedCandidate ? this.updateCandidateFlexShifts(candidate) : candidate;
    });
  }

  allFixedShiftsByTypeSelection(
    candidates: BaseInquiryCandidateInterface[],
    candidateId: number,
    shiftType: BaseInquiryShiftsWithFlex
  ): BaseInquiryCandidateInterface[] {
    return candidates.map((candidate) => {
      const isSelectedCandidate = candidate.data.id === candidateId;
      return isSelectedCandidate ? this.updateCandidateFixedShifts(candidate, shiftType) : candidate;
    });
  }

  private updateCandidateFixedShifts(
    candidate: BaseInquiryCandidateInterface,
    shiftType: BaseInquiryShiftsWithFlex
  ): BaseInquiryCandidateInterface {
    let fixedItemsToSelect = 0;
    let fixedItemsToDeselect = 0;
    const availableDatesByShiftType = candidate.dates.filter((dateItem) =>
      dateItem.shifts.some(
        (shiftItem) =>
          !shiftItem.isFlexibleShift &&
          shiftItem.shift === shiftType &&
          shiftItem.currentValue === BaseInquiryBubbleValues.Available
      )
    );

    const areAllFixedShiftsForTypeSelected = availableDatesByShiftType.every((dateItem) => {
      return !!dateItem.shifts.find((item) => item.shift === shiftType)?.isSelected;
    });

    if (areAllFixedShiftsForTypeSelected) {
      fixedItemsToDeselect = availableDatesByShiftType.length;
    } else {
      fixedItemsToSelect = availableDatesByShiftType.filter((dateItem) => {
        return !!dateItem.shifts.find(
          (item) =>
            item.shift === shiftType && item.currentValue === BaseInquiryBubbleValues.Available && !item.isSelected
        );
      }).length;
    }

    let updatedCandidate = { ...candidate };
    updatedCandidate.dates = candidate.dates.map((dateItem) => ({
      ...dateItem,
      shifts: dateItem.shifts.map((shiftItem) => {
        const isAvailableFixedShiftForType =
          !shiftItem.isFlexibleShift &&
          shiftItem.currentValue === BaseInquiryBubbleValues.Available &&
          shiftItem.shift === shiftType;
        const isSelected =
          shiftItem.shift === shiftType && shiftItem.currentValue === BaseInquiryBubbleValues.Available
            ? !areAllFixedShiftsForTypeSelected
            : shiftItem.isSelected;

        const selectedClass = BaseInquiryBubbleClasses.AvailableActive;
        const deselectedClass = BaseInquiryBubbleClasses.AvailableDeselectedFixed;

        return isAvailableFixedShiftForType
          ? {
              ...shiftItem,
              isSelected,
              class: isSelected ? selectedClass : deselectedClass,
              color: isSelected ? BaseInquiryBubbleColors.Green : BaseInquiryBubbleColors.DarkGreyFramed,
            }
          : shiftItem;
      }),
    }));

    if (fixedItemsToSelect > 0 || fixedItemsToDeselect > 0) {
      this.store.dispatch(
        baseSharedActions.addSystemAlert({
          payload: {
            class: BaseToastAlertClasses.success,
            body: fixedItemsToSelect > 0 ? 'inquiry.shiftSelectNotification' : 'inquiry.shiftDeselectNotification',
            bodyTranslateParams: {
              quantity: fixedItemsToSelect > 0 ? fixedItemsToSelect : fixedItemsToDeselect,
              shift: this.translateService.instant(`shiftTimes.labelLong.${shiftType}`),
            },
          },
        })
      );
    }

    return updatedCandidate;
  }

  private updateCandidateFlexShifts(candidate: BaseInquiryCandidateInterface): BaseInquiryCandidateInterface {
    let flexItemsToSelect = 0;
    let flexItemsToDeselect = 0;
    const flexShiftDates = candidate.dates.filter((dateItem) =>
      dateItem.shifts.every((shift) => shift.isFlexibleShift)
    );
    const areAllFlexShiftsSelected = flexShiftDates.every((dateItem) =>
      dateItem.shifts.every(
        (shift) =>
          (shift.currentValue === BaseInquiryBubbleValues.Available && shift.isSelected) ||
          shift.currentValue !== BaseInquiryBubbleValues.Available
      )
    );
    if (areAllFlexShiftsSelected) {
      flexItemsToDeselect = flexShiftDates.length;
    } else {
      flexItemsToSelect =
        flexShiftDates.length -
        flexShiftDates.filter(
          (dateItem) =>
            // Check only flex shift (dateItem.shifts[0]) here to calculate number for notification message
            dateItem.shifts[0].currentValue === BaseInquiryBubbleValues.Available && dateItem.shifts[0].isSelected
        ).length;
    }

    let updatedCandidate = { ...candidate };
    updatedCandidate.dates = candidate.dates.map((dateItem) => ({
      ...dateItem,
      shifts: dateItem.shifts.map((shiftItem) => {
        const isAvailableShift =
          shiftItem.currentValue === BaseInquiryBubbleValues.Available && shiftItem.isFlexibleShift;

        const isSelected =
          shiftItem.currentValue === BaseInquiryBubbleValues.Available
            ? !areAllFlexShiftsSelected
            : shiftItem.isSelected;

        const selectedClass =
          shiftItem.shift === BaseInquiryShiftsWithFlex.FLEX
            ? BaseInquiryBubbleClasses.AvailableActive
            : BaseInquiryBubbleClasses.AvailableActiveDot;

        const deselectedClass =
          shiftItem.shift === BaseInquiryShiftsWithFlex.FLEX
            ? BaseInquiryBubbleClasses.AvailableDeselectedFlex
            : BaseInquiryBubbleClasses.AvailableDeselectedFlexCircle;

        const selectedColor = BaseInquiryBubbleColors.Green;

        const deselectedColor =
          shiftItem.shift === BaseInquiryShiftsWithFlex.FLEX
            ? BaseInquiryBubbleColors.DarkGrey
            : BaseInquiryBubbleColors.DarkGreyCircle;

        return isAvailableShift
          ? {
              ...shiftItem,
              isSelected,
              class: isSelected ? selectedClass : deselectedClass,
              color: isSelected ? selectedColor : deselectedColor,
            }
          : shiftItem;
      }),
    }));

    if (flexItemsToSelect > 0 || flexItemsToDeselect > 0) {
      this.store.dispatch(
        baseSharedActions.addSystemAlert({
          payload: {
            class: BaseToastAlertClasses.success,
            body:
              flexItemsToSelect > 0
                ? 'inquiry.fixedShiftsSelectNotification'
                : 'inquiry.fixedShiftsDeselectNotification',
            bodyTranslateParams: {
              quantity: flexItemsToSelect > 0 ? flexItemsToSelect : flexItemsToDeselect,
              shift: this.translateService.instant('shiftTimes.flex'),
            },
          },
        })
      );
    }

    return updatedCandidate;
  }

  private updateShift(
    shiftItem: BaseInquiryCandidateShiftInterface,
    selectedShift: BaseInquiryCandidateShiftInterface,
    dateShifts: BaseInquiryCandidateShiftInterface[]
  ): BaseInquiryCandidateShiftInterface {
    const otherAvailableShiftsForDate = dateShifts.filter(
      (shiftItem) =>
        shiftItem.shift !== BaseInquiryShiftsWithFlex.FLEX &&
        shiftItem.currentValue === BaseInquiryBubbleValues.Available
    );

    const allOtherShiftsForDateSelected = otherAvailableShiftsForDate.every((shiftItem) => shiftItem.isSelected);

    const isAvailableShift =
      shiftItem.shift === selectedShift.shift ||
      (selectedShift.shift === BaseInquiryShiftsWithFlex.FLEX &&
        shiftItem.shift !== selectedShift.shift &&
        shiftItem.currentValue === BaseInquiryBubbleValues.Available);

    const selectedFlexClass =
      shiftItem.shift === BaseInquiryShiftsWithFlex.FLEX
        ? BaseInquiryBubbleClasses.AvailableActive
        : BaseInquiryBubbleClasses.AvailableActiveDot;

    const deselectedFlexClass =
      shiftItem.shift === BaseInquiryShiftsWithFlex.FLEX
        ? BaseInquiryBubbleClasses.AvailableDeselectedFlex
        : BaseInquiryBubbleClasses.AvailableDeselectedFlexCircle;

    let updatedColor =
      shiftItem.isSelected && shiftItem.shift === selectedShift.shift
        ? BaseInquiryBubbleColors.DarkGreyFramed
        : !shiftItem.isSelected && shiftItem.shift === selectedShift.shift
        ? BaseInquiryBubbleColors.Green
        : shiftItem.color;

    let updatedClass: string = '';

    if (shiftItem.isFlexibleShift) {
      if (selectedShift.shift === BaseInquiryShiftsWithFlex.FLEX) {
        updatedClass = !isAvailableShift
          ? shiftItem.class
          : allOtherShiftsForDateSelected
          ? deselectedFlexClass
          : selectedFlexClass;

        updatedColor = !isAvailableShift
          ? shiftItem.color
          : allOtherShiftsForDateSelected
          ? BaseInquiryBubbleColors.DarkGreyFramed
          : BaseInquiryBubbleColors.Green;
      } else {
        updatedClass =
          shiftItem.isSelected && isAvailableShift
            ? deselectedFlexClass
            : !shiftItem.isSelected &&
              (isAvailableShift ||
                (shiftItem.shift === BaseInquiryShiftsWithFlex.FLEX && !allOtherShiftsForDateSelected))
            ? selectedFlexClass
            : shiftItem.class;
        updatedColor =
          shiftItem.isSelected && isAvailableShift
            ? BaseInquiryBubbleColors.DarkGreyFramed
            : !shiftItem.isSelected &&
              (isAvailableShift ||
                (shiftItem.shift === BaseInquiryShiftsWithFlex.FLEX && !allOtherShiftsForDateSelected))
            ? BaseInquiryBubbleColors.Green
            : shiftItem.color;
      }
    } else {
      updatedClass =
        shiftItem.isSelected && shiftItem.shift === selectedShift.shift
          ? BaseInquiryBubbleClasses.AvailableDeselectedFixed
          : !shiftItem.isSelected && shiftItem.shift === selectedShift.shift
          ? BaseInquiryBubbleClasses.AvailableActive
          : shiftItem.class;
    }

    return {
      ...shiftItem,
      isSelected: updatedColor === BaseInquiryBubbleColors.Green,
      color: updatedColor,
      class: updatedClass,
    };
  }

  private selectCandidateInTheList(
    candidates: BaseInquiryCandidateInterface[],
    candidateId: number
  ): BaseInquiryCandidateInterface[] {
    return candidates.map((candidate: BaseInquiryCandidateInterface) => {
      return {
        data: {
          ...candidate.data,
          isSelected: candidate.data.id === candidateId ? !candidate.data.isSelected : false,
        },
        dates: candidate.dates,
      };
    });
  }

  private updateShiftsDuringCandidateSelection(
    candidates: BaseInquiryCandidateInterface[]
  ): BaseInquiryCandidateInterface[] {
    return candidates.map((candidateInfo) => {
      return {
        data: candidateInfo.data,
        dates: candidateInfo.dates.map((date: BaseInquiryCandidateDateInterface) => {
          return {
            ...date,
            shifts: date.shifts.map((shiftItem: BaseInquiryCandidateShiftInterface) => {
              const isCandidateSelected = candidateInfo.data.isSelected;
              const isDotVisible = this.baseInquiryService.isBubbleDotVisible(
                date.shifts,
                shiftItem.shift as BaseInquiryShiftsWithFlexType
              );
              let selectedCandidateAvailableBubbleClass =
                shiftItem.isFlexibleShift && shiftItem.shift !== BaseInquiryShiftsWithFlex.FLEX
                  ? BaseInquiryBubbleClasses.AvailableActiveDot
                  : BaseInquiryBubbleClasses.AvailableActive;

              let deselectedCandidateAvailableBubbleClass = isDotVisible
                ? BaseInquiryBubbleClasses.AvailableInactiveDot
                : BaseInquiryBubbleClasses.AvailableInactive;

              return shiftItem.currentValue === BaseInquiryBubbleValues.Available
                ? {
                    ...shiftItem,
                    showDot: isCandidateSelected && !shiftItem.isFlexibleShift ? false : isDotVisible,
                    color: isCandidateSelected ? BaseInquiryBubbleColors.Green : BaseInquiryBubbleColors.DarkGrey,
                    class: isCandidateSelected
                      ? selectedCandidateAvailableBubbleClass
                      : deselectedCandidateAvailableBubbleClass,
                    isSelected: isCandidateSelected,
                  }
                : shiftItem;
            }),
          };
        }),
      };
    });
  }

  private prepareCandidateDates(
    inquiry: InquiryResponse,
    currentDate: Date,
    candidate: BestMatchingProfileListItemResponse,
    inquiryId: number
  ): BaseInquiryCandidateDateInterface[] {
    const inquiryDateTimes = inquiry.dates
      ? inquiry.dates.map((newInquiryDate: InquiryDateResponse) => new Date(newInquiryDate.date).getTime())
      : [];
    const maxDate = new Date(
      Math.max(
        ...inquiryDateTimes,
        BaseDateService.addDays(currentDate, BASE_INQUIRY_CALENDAR_MAX_DAYS_TO_SCROLL).getTime()
      )
    );
    const inquiryLength = BaseDateService.differenceInWeeks(currentDate, maxDate);
    let weekAmount = inquiryLength + (maxDate.getDay() > 1 && maxDate.getDay() <= 6 ? 2 : 1);

    const closestMonday = getClosestDayOfLastWeek('Mon', currentDate);
    const dates: string[] = getDaysArrayWithWeekdays(
      closestMonday,
      BaseDateService.addDays(closestMonday, weekAmount * 7 - 1)
    );

    return this.addDataToCandidateDatesArray(dates, inquiry, candidate, inquiryId);
  }

  private addDataToCandidateDatesArray(
    dates: string[],
    inquiry: InquiryResponse,
    candidate: BestMatchingProfileListItemResponse,
    inquiryId: number
  ): BaseInquiryCandidateDateInterface[] {
    if (!dates.length) {
      return [];
    }

    let datesArray: BaseInquiryCandidateDateInterface[] = [];
    const getCandidateBubbleColorAndValue = (
      date: string,
      shift: BaseInquiryShiftsWithFlexType
    ): BaseInquiryBubbleColorAndValueInterface => {
      return this.setCandidateBubbleColorAndValue(
        date,
        shift,
        candidate.candidateAvailability as CandidateAvailability,
        candidate.absences || [],
        inquiry.dates || [],
        candidate.offerPositions || [],
        inquiryId,
        false,
        candidate.leavingDate
      );
    };

    const createShift = (shift: BaseInquiryShiftsWithFlexType, shiftIndex: number, dateIndex: number): any => {
      const bubbleColorAndValue = getCandidateBubbleColorAndValue(dates[dateIndex], shift);

      return {
        shift,
        index: shiftIndex,
        color: bubbleColorAndValue.color,
        currentValue: bubbleColorAndValue.value,
        isSelected: false,
        showDot: false,
        class: `position-relative`,
      };
    };

    dates.forEach((date, dateIndex) => {
      const shifts: BaseInquiryCandidateShiftInterface[] = BASE_INQUIRY_CALENDAR_ORDERED_SHIFTS.map(
        (shift, shiftIndex) => createShift(shift, shiftIndex, dateIndex)
      );

      const candidateOfferPositions: ProfileOfferPositionResponse[] =
        candidate.offerPositions?.filter((offerPosition) => {
          const isOfferOpen = offerPosition.offer?.status === BaseOfferStatus.open;
          const isOfferNeedsConfirmation = offerPosition.offer?.status === BaseOfferStatus.needsConfirmation;
          const isOfferNeedsUpdate = offerPosition.offer?.status === BaseOfferStatus.needsUpdate;

          return offerPosition.date === date && (isOfferOpen || isOfferNeedsConfirmation || isOfferNeedsUpdate);
        }) || [];

      const isCandidateOfferedElsewhereForDate = candidateOfferPositions.length > 0;

      let inquiryDate: BaseInquiryCandidateDateInterface = {
        date,
        isToday: BaseDateService.isToday(new Date(date)),
        isWeekend: BaseDateService.isWeekendDay(new Date(date)),
        isCandidateOfferedElsewhereForDate,
        candidateOfferPositions,
        shifts,
      };

      inquiryDate.shifts.map((item) => {
        item.showDot = this.baseInquiryService.isBubbleDotVisible(
          inquiryDate.shifts,
          item.shift as BaseInquiryShiftsWithFlexType
        );
        item.isFlexibleShift = inquiryDate.shifts[0].currentValue === BaseInquiryBubbleValues.Available;
        if (item.color) {
          item.class += ` ${item.color}`;
        }
        if (item.currentValue && !item.showDot) {
          item.class += ` bubble`;
        }
        if (!item.currentValue) {
          item.class += ` empty-bubble`;
        }
        if (item.showDot) {
          item.class += ` bubble-dot`;
        }
      });
      datesArray.push(inquiryDate);
    });

    return datesArray;
  }

  private setCandidateBubbleColorAndValue(
    currentDate: string,
    currentShift: BaseInquiryShiftsWithFlexType,
    availability: CandidateAvailability,
    absences: ProfileAbsenceResponse[],
    inquiryDates: InquiryDateResponse[],
    offerPositions: ProfileOfferPositionResponse[],
    inquiryId: number,
    selected: boolean = false,
    leavingDate?: string
  ): BaseInquiryBubbleColorAndValueInterface {
    const defaultColorAndValue: BaseInquiryBubbleColorAndValueInterface = {
      color: BaseInquiryBubbleColors.Default,
      value: BaseInquiryBubbleValues.Default,
    };

    const isLeavingDateAvailable = leavingDate
      ? BaseDateService.isEqualOrAfter(new Date(currentDate), new Date(leavingDate))
      : false;

    if (isLeavingDateAvailable) {
      return { color: BaseInquiryBubbleColors.RedWithdrawn, value: BaseInquiryBubbleValues.Unavailable };
    }

    if (
      this.baseInquiryService.offerPositionExistsAndSelected(
        currentDate,
        offerPositions,
        currentShift,
        inquiryId,
        inquiryDates
      )
    ) {
      return { color: BaseInquiryBubbleColors.Green, value: BaseInquiryBubbleValues.Booked };
    }

    if (
      this.baseInquiryService.offerPositionExistsAndSelectedForOtherInquiry(
        currentDate,
        offerPositions,
        currentShift,
        inquiryId
      )
    ) {
      const currentDateOfferPosition: ProfileOfferPositionResponse[] = offerPositions.filter(
        (position) => position.date === currentDate
      );

      if (currentDateOfferPosition.length) {
        const hasConfirmedPositions = currentDateOfferPosition.some(
          (position) =>
            position.offer?.status === BaseOfferStatus.confirmed && !!position[BaseShiftTimeLabelByValue[currentShift]]
        );

        if (hasConfirmedPositions) {
          return { color: BaseInquiryBubbleColors.LightGreen, value: BaseInquiryBubbleValues.Booked };
        }
      } else {
        return { color: BaseInquiryBubbleColors.LightGreen, value: BaseInquiryBubbleValues.Booked };
      }
    }

    if (this.baseInquiryService.isIllForDate(currentDate, absences)) {
      return { color: BaseInquiryBubbleColors.PinkSick, value: BaseInquiryBubbleValues.Sick };
    }

    if (this.baseInquiryService.isHolidayForDate(currentDate, absences)) {
      return { color: BaseInquiryBubbleColors.PinkHoliday, value: BaseInquiryBubbleValues.Vacation };
    }

    if (this.baseInquiryService.isOtherForDate(currentDate, absences)) {
      return { color: BaseInquiryBubbleColors.PinkOther, value: BaseInquiryBubbleValues.Other };
    }

    if (this.baseInquiryService.isAssignedOutsideForDate(currentDate, absences)) {
      return { color: BaseInquiryBubbleColors.PinkAssignedOutside, value: BaseInquiryBubbleValues.AssignedOutside };
    }

    if (this.baseInquiryService.isTimeOffForDate(currentDate, absences)) {
      return { color: BaseInquiryBubbleColors.PinkTimeOff, value: BaseInquiryBubbleValues.TimeOff };
    }

    if (this.baseInquiryService.isUnexcusedForDate(currentDate, absences)) {
      return { color: BaseInquiryBubbleColors.PinkUnexcused, value: BaseInquiryBubbleValues.Unexcused };
    }

    if (this.baseInquiryService.isFreeShiftForDate(currentDate, absences)) {
      return { color: BaseInquiryBubbleColors.PinkFreeShift, value: BaseInquiryBubbleValues.FreeShift };
    }

    if (
      !this.baseInquiryService.checkAvailabilityByDirectDay(currentDate, availability) ||
      !this.baseInquiryService.checkAvailabilityForDirectShift(currentShift, availability) ||
      this.baseInquiryService.isAbsentForDate(currentDate, absences)
    ) {
      return { color: BaseInquiryBubbleColors.DarkBlue, value: BaseInquiryBubbleValues.Unavailable };
    }

    if (
      selected &&
      !this.isCandidateBookedForDate(currentDate, offerPositions) &&
      this.baseInquiryService.inquiryDateExists(currentDate, inquiryDates, currentShift, [])
    ) {
      return { color: BaseInquiryBubbleColors.Green, value: BaseInquiryBubbleValues.Available };
    }

    if (
      !selected &&
      !this.isCandidateBookedForDate(currentDate, offerPositions) &&
      this.baseInquiryService.inquiryDateExists(currentDate, inquiryDates, currentShift, [])
    ) {
      return { color: BaseInquiryBubbleColors.DarkGrey, value: BaseInquiryBubbleValues.Available };
    }

    return defaultColorAndValue;
  }

  defineVisibleCandidates(candidates: BaseInquiryCandidateInterface[]): BaseInquiryCandidateInterface[] {
    let itemsSize = 0;

    return candidates.map((item) => {
      if (item.data.isVisible) {
        return item;
      }

      itemsSize++;

      if (itemsSize <= BASE_INQUIRY_CANDIDATES_VISIBLE_LIMIT) {
        return {
          ...item,
          data: {
            ...item.data,
            isVisible: true,
          },
        };
      } else {
        return item;
      }
    });
  }

  enrichCandidates(candidate: BestMatchingProfileListItemResponse, inquiry: InquiryResponse) {
    const inquiryInterval = {
      start: inquiry.dateFrom ? new Date(inquiry.dateFrom) : 0,
      end: inquiry.dateTo ? new Date(inquiry.dateTo) : 0,
    };
    let offers: string[] = [];

    candidate.offerPositions?.forEach((i) => {
      if (i.offer?.dateFrom && i.offer?.dateTo) {
        const offerPositionToStr = JSON.stringify(i.offer);
        if (offers.indexOf(offerPositionToStr) === -1) {
          offers.push(offerPositionToStr);
        }
      }
    });

    const offerPositionToObj = offers.map((position) => JSON.parse(position));

    const firstAvailableDay = this.getFirstAvailableDay(
      inquiryInterval,
      candidate.absences,
      offerPositionToObj,
      candidate.candidateAvailability as CandidateAvailability
    );

    const otherOffers = this.getOtherOffersInRange(candidate, inquiryInterval);
    const otherAbsences = this.getAbsencesInRange(candidate, inquiryInterval);

    return [firstAvailableDay, otherOffers, otherAbsences];
  }

  getFirstAvailableDay(
    inquiryInterval: { start: number | Date; end: number | Date },
    absences: ProfileAbsenceResponse[] | undefined,
    offers: ProfileOfferResponse[],
    availability: CandidateAvailability
  ): string {
    let currentDate = BaseDateService.startOfDay(new Date(inquiryInterval.start));
    let endDate = BaseDateService.startOfDay(new Date(inquiryInterval.end));

    while (!BaseDateService.isAfter(currentDate, endDate)) {
      if (
        absences
          ? absences.some((absence) =>
              BaseDateService.isWithinInterval(currentDate, {
                start: BaseDateService.startOfDay(new Date(absence.startDate)),
                end: BaseDateService.startOfDay(new Date(absence.endDate)),
              })
            )
          : false
      ) {
        currentDate = BaseDateService.addDays(currentDate, 1);

        continue;
      }

      if (
        offers
          ? offers.some(
              (offer) =>
                offer.status === BaseOfferStatus.confirmed &&
                BaseDateService.isWithinInterval(currentDate, {
                  start: BaseDateService.startOfDay(new Date(offer.dateFrom as string)),
                  end: BaseDateService.startOfDay(new Date(offer.dateTo as string)),
                })
            )
          : false
      ) {
        currentDate = BaseDateService.addDays(currentDate, 1);

        continue;
      }

      if (
        availability
          ? !availability[BaseDateService.format(currentDate, 'EEE').toLowerCase() as BaseWeekDaysAlt]
          : false
      ) {
        currentDate = BaseDateService.addDays(currentDate, 1);

        continue;
      }

      break;
    }

    return BaseDateService.format(currentDate, BASE_DATE_SERVICE_FORMAT);
  }

  getOtherOffersInRange(candidate: BestMatchingProfileListItemResponse, inquiryInterval: Interval) {
    if (!candidate.offerPositions?.length) {
      return [];
    }

    const offerArray: Array<ProfileOfferResponse> = candidate.offerPositions
      .map((offerPosition) => offerPosition.offer)
      .filter((offer): offer is ProfileOfferResponse => !!offer);

    const uniqueOfferArray: ProfileOfferResponse[] = [];

    for (let i = 0; i < offerArray.length; i++) {
      const { dateFrom, dateTo } = offerArray[i];
      const isWithinRange = (date: string | undefined) =>
        date ? BaseDateService.isWithinInterval(new Date(date), inquiryInterval) : false;

      if (dateFrom && dateTo && !isWithinRange(dateFrom) && !isWithinRange(dateTo)) {
        continue;
      }

      if (i > 0 && baseIsDeepEqual(offerArray[i], offerArray[i - 1])) {
        continue;
      }

      uniqueOfferArray.push(offerArray[i]);
    }

    return uniqueOfferArray;
  }

  getAbsencesInRange(candidate: BestMatchingProfileListItemResponse, inquiryInterval: Interval) {
    const absencesArray = [];
    if (!candidate.absences) {
      return;
    }
    for (let absence of candidate.absences) {
      if (!absence) {
        return;
      }
      if (
        !(
          BaseDateService.isWithinInterval(new Date(absence.startDate), inquiryInterval) ||
          BaseDateService.isWithinInterval(new Date(absence.endDate), inquiryInterval)
        )
      ) {
      } else {
        absencesArray.push(absence);
      }
    }
    return absencesArray;
  }

  private isCandidateBookedForDate(currentDate: string, offerPositions: ProfileOfferPositionResponse[]): boolean {
    return offerPositions.some(
      ({ date, offer }) => date === currentDate && offer?.status === BaseOfferStatus.confirmed
    );
  }
}
