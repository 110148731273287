import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { baseAssignmentsActions, baseAssignmentsSelectors, BaseCoreState } from '../../../_store';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable()
export class BaseAssignmentListGuard {
  constructor(private store: Store<BaseCoreState>) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    this.store.dispatch(
      baseAssignmentsActions.loadAssignmentList({ payload: { type: route.routeConfig?.path as string, page: 1 } })
    );

    return this.store
      .select(baseAssignmentsSelectors.getAssignmentListStatus)
      .pipe(filter((status) => status !== undefined));
  }
}
