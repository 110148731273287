import { Injectable } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import {
  addDays,
  addHours,
  addMonths,
  differenceInDays,
  differenceInMinutes,
  differenceInWeeks,
  format,
  isAfter,
  isBefore,
  isEqual,
  isMonday,
  isTuesday,
  isWednesday,
  isThursday,
  isFriday,
  isSaturday,
  isSunday,
  isToday,
  isValid,
  lastDayOfMonth,
  parse,
  startOfDay,
  getWeek,
  subDays,
  isWithinInterval,
  eachDayOfInterval,
} from 'date-fns';
import { InquiryDateResponse } from '../../../api';

export const BASE_DATE_SERVICE_FORMAT = 'yyyy-MM-dd';
export const BASE_DATE_CLIENT_FORMAT = 'dd.MM.yyyy';

export enum BaseDateFormat {
  DateServiceFormat = 'yyyy-MM-dd',
  DateClientFormat = 'dd.MM.yyyy',
}

@Injectable()
export class BaseDateService {
  static isValid(date: string): boolean {
    return isValid(date);
  }

  static format(date: Date, formatPattern: string = BASE_DATE_CLIENT_FORMAT): string {
    return format(date, formatPattern);
  }

  static isBefore(date1: Date, date2: Date): boolean {
    return isBefore(date1, date2);
  }

  static isAfter(date1: Date, date2: Date): boolean {
    return isAfter(date1, date2);
  }

  static isEqualOrAfter(date1: Date, date2: Date) {
    return isEqual(date1, date2) || isAfter(date1, date2);
  }

  static isInsideAPeriod(date: Date, periodStart: Date, periodEnd: Date): boolean {
    return (
      (isEqual(date, periodStart) || isAfter(date, periodStart)) &&
      (isEqual(date, periodEnd) || isBefore(date, periodEnd))
    );
  }

  static addMonths(date: Date, months: number): Date {
    return addMonths(date, months);
  }

  static addDays(date: Date, days: number): Date {
    return addDays(date, days);
  }

  static addHours(date: Date, hours: number): Date {
    return addHours(date, hours);
  }

  static isEqual(date1: Date, date2: Date): boolean {
    return isEqual(date1, date2);
  }

  static isToday(date: Date): boolean {
    return isToday(date);
  }

  static differenceInDays(date1: Date, date2: Date): number {
    return differenceInDays(date2, date1);
  }

  static differenceInWeeks(date1: Date, date2: Date): number {
    return differenceInWeeks(date2, date1);
  }

  static differenceInMinutes(date1: Date, date2: Date): number {
    return differenceInMinutes(date1, date2);
  }

  static startOfDay(date: Date): Date {
    return startOfDay(date);
  }

  static toDate(date: string, formatString = BASE_DATE_SERVICE_FORMAT): Date {
    return parse(date, formatString, new Date());
  }

  static toServerFormatString(date: string): string {
    return date?.indexOf('.') > -1 ? date.split('.').reverse().join('-') : date;
  }

  static formatExportTime(month: { id: number; value: string }, year: number, toLastDayOfMonth = false): string {
    const newDate = BaseDateService.toDate(`${year}-${month?.id + 1}-01`);

    return month && year
      ? format(toLastDayOfMonth ? lastDayOfMonth(newDate) : newDate, BaseDateFormat.DateServiceFormat)
      : '';
  }

  static getDateDaysAgo(days: number): Date {
    return subDays(new Date(), days);
  }

  static getPreviousDay(date?: Date): Date {
    const dateToCompare: Date = date ? date : new Date();
    const previousDay = new Date(dateToCompare.getTime());
    previousDay.setDate(dateToCompare.getDate() - 1);

    return previousDay;
  }

  static getNextDay(date?: Date): Date {
    const dateToCompare: Date = date ? date : new Date();
    const nextDay = new Date(dateToCompare.getTime());
    nextDay.setDate(dateToCompare.getDate() + 1);

    return nextDay;
  }

  static isMonday(datePositions: InquiryDateResponse[]) {
    return datePositions.findIndex((position) => isMonday(new Date(position.date))) !== -1;
  }

  static isTuesday(datePositions: InquiryDateResponse[]) {
    return datePositions.findIndex((position) => isTuesday(new Date(position.date))) !== -1;
  }

  static isWednesday(datePositions: InquiryDateResponse[]) {
    return datePositions.findIndex((position) => isWednesday(new Date(position.date))) !== -1;
  }

  static isThursday(datePositions: InquiryDateResponse[]) {
    return datePositions.findIndex((position) => isThursday(new Date(position.date))) !== -1;
  }

  static isFriday(datePositions: InquiryDateResponse[]) {
    return datePositions.findIndex((position) => isFriday(new Date(position.date))) !== -1;
  }

  static isSaturday(datePositions: InquiryDateResponse[]) {
    return datePositions.findIndex((position) => isSaturday(new Date(position.date))) !== -1;
  }

  static isSunday(datePositions: InquiryDateResponse[]) {
    return datePositions.findIndex((position) => isSunday(new Date(position.date))) !== -1;
  }

  /**
   * Convert a time string into a Date object from today with given
   * hours, minutes, seconds and milliseconds.
   * The given string needs to be in format HH:mm:ss:ms (e.g.: "18:25:15:999"), seperated by ":".
   * All parts that are missing from the end are replaced with a 0 value.
   * @param timeString
   */
  static setDateTimeByString(timeString: string): Date {
    const date = new Date();
    const time: number[] = timeString.split(':').map(Number);

    if (time.length >= 1) {
      date.setHours(time[0]);
      date.setMinutes(time[1] ? time[1] : 0);
      date.setSeconds(time[2] ? time[2] : 0);
      date.setMilliseconds(time[3] ? time[3] : 0);
    }

    return date;
  }

  static isBreakTimeWithinWorkingHours(
    breakStartString: string,
    breakEndString: string,
    workStartString: string,
    workEndString: string
  ): boolean {
    let breakStartDate = BaseDateService.setDateTimeByString(breakStartString);
    const breakStartNextDayDate = new Date(breakStartDate);
    breakStartNextDayDate.setDate(breakStartDate.getDate() + 1);
    let breakEndDate = BaseDateService.setDateTimeByString(breakEndString);
    const breakEndNextDayDate = new Date(breakEndDate);
    breakEndNextDayDate.setDate(breakEndDate.getDate() + 1);
    const workStartDate = BaseDateService.setDateTimeByString(workStartString);
    let workEndDate = BaseDateService.setDateTimeByString(workEndString);
    let workEndNextDayDate = new Date(workEndDate);
    workEndNextDayDate.setDate(workEndDate.getDate() + 1);

    // Check if the end time is on the next day and adjust it
    if (
      workEndDate < workStartDate &&
      breakStartDate < workStartDate &&
      breakStartString >= '00:00' &&
      breakStartString <= workEndString
    ) {
      breakStartDate = breakStartNextDayDate;
    }
    if (breakEndDate < breakStartDate) {
      breakEndDate = breakEndNextDayDate;
    }
    if (workEndDate < workStartDate) {
      workEndDate = workEndNextDayDate;
    }

    return breakStartDate >= workStartDate && breakEndDate <= workEndDate;
  }

  static isWeekendDay(date: Date) {
    if (!date) {
      return false;
    }

    return date.getDay() === 0 || date.getDay() === 6;
  }

  static weekNumber(date: string): number {
    return getWeek(new Date(date), { weekStartsOn: 1, firstWeekContainsDate: 4 });
  }

  static isWithinInterval(date: Date, interval: Interval): boolean {
    return isWithinInterval(date, interval);
  }

  static eachDayOfInterval(start: Date, end: Date): Date[] {
    return eachDayOfInterval({ start, end });
  }

  static convertServerFormatToNgbDateStruct(date: string): NgbDateStruct {
    const [year, month, day] = date.split('-').map((part) => parseInt(part, 10));
    return { year, month, day };
  }
}
