import { Injectable } from '@angular/core';
import { BaseDateService } from '../../../shared/services/base-date.service';
import { CustomerInquiryListItemResponse, CustomerInquiryDateResponse } from '../../../../api';
import {
  ShiftPlanCalendarDateInterface,
  SimilarInquiryMappedInterface,
  SimilarInquiriesDateInterface,
  ShiftPlanListBubbleItemInterface,
  BaseInquiryShiftBooleanInterface,
} from '../_interfaces/base-shift-plan.interface';
import { BaseInquiryShiftsWithFlex, BaseInquiryShiftsUpperCase } from '../_enums/base-inquiry.enum';

@Injectable()
export class BaseSimilarInquiriesService {
  constructor() {}

  generateSimilarInquiries(
    inquiryList: CustomerInquiryListItemResponse[],
    calendar: ShiftPlanCalendarDateInterface[]
  ): SimilarInquiryMappedInterface[] {
    const inquiryShiftViewItems: SimilarInquiryMappedInterface[] = [];

    for (let k = 0; k < inquiryList.length; k++) {
      const dates: SimilarInquiriesDateInterface[] = [];
      let availableShifts: BaseInquiryShiftBooleanInterface = {
        flex: false,
        fd: false,
        zd: false,
        sd: false,
        nd: false,
      };

      for (let i = 0; i < calendar.length; i++) {
        const dateString = calendar[i].dateItem.dateString;
        const date = BaseDateService.toDate(dateString);
        const currentInquiryDate = (inquiryList[k].dates as CustomerInquiryDateResponse[]).find(
          (item) => item.date === dateString
        );

        dates.push({
          date: calendar[i].dateItem.dateString,
          isToday: BaseDateService.isToday(date),
          isWeekend: BaseDateService.isWeekendDay(date),
          bubbles: this.generateBubbles(currentInquiryDate),
        });

        if (currentInquiryDate) {
          availableShifts = this.defineVisibleShifts(currentInquiryDate, availableShifts);
        }
      }

      inquiryShiftViewItems.push({
        data: inquiryList[k],
        dates: dates,
        availableShifts,
      });
    }

    return inquiryShiftViewItems;
  }

  private generateBubbles(inquiryDate: CustomerInquiryDateResponse | undefined): ShiftPlanListBubbleItemInterface[] {
    const bubbles: ShiftPlanListBubbleItemInterface[] = [];

    Object.values(BaseInquiryShiftsWithFlex).forEach((type) => {
      let bubble: ShiftPlanListBubbleItemInterface;

      if (inquiryDate) {
        if (type === BaseInquiryShiftsWithFlex.FLEX) {
          if (inquiryDate.flexShift) {
            bubble = {
              shift: type,
              showDot: false,
              value: (inquiryDate.shiftFd ||
                inquiryDate.shiftSd ||
                inquiryDate.shiftNd ||
                inquiryDate.shiftZd) as number,
            };
          } else {
            bubble = {
              shift: type,
              showDot: false,
              value: 0,
            };
          }
        } else {
          if (inquiryDate.flexShift) {
            bubble = {
              shift: type,
              showDot: (inquiryDate[BaseInquiryShiftsUpperCase[type]] as number) > 0,
              value: 0,
            };
          } else {
            bubble = {
              shift: type,
              showDot: false,
              value: inquiryDate[BaseInquiryShiftsUpperCase[type]] || 0,
            };
          }
        }
      } else {
        bubble = {
          shift: type,
          showDot: false,
          value: 0,
        };
      }

      if (bubble) {
        bubbles.push(bubble);
      }
    });

    return bubbles;
  }

  defineVisibleShifts(
    inquiryDate: CustomerInquiryDateResponse,
    visibleShifts: BaseInquiryShiftBooleanInterface
  ): BaseInquiryShiftBooleanInterface {
    return {
      flex: !visibleShifts.flex && inquiryDate?.flexShift ? inquiryDate?.flexShift : visibleShifts.flex,
      fd: !visibleShifts.fd && inquiryDate?.shiftFd ? inquiryDate?.shiftFd > 0 : visibleShifts.fd,
      zd: !visibleShifts.zd && inquiryDate?.shiftZd ? inquiryDate?.shiftZd > 0 : visibleShifts.zd,
      sd: !visibleShifts.sd && inquiryDate?.shiftSd ? inquiryDate?.shiftSd > 0 : visibleShifts.sd,
      nd: !visibleShifts.nd && inquiryDate?.shiftNd ? inquiryDate?.shiftNd > 0 : visibleShifts.nd,
    };
  }
}
