import { BaseApplication } from '../../shared/enums/base-application.enum';
import { BaseEnvironment } from '../../shared/enums/base-environment.enum';

enum DemoUrls {
  medicalDemo = 'app-demo.stazzle-medical.de',
  businessDemo = 'app-demo.stazzle-business.de',
  growthDemo = 'app-demo.stazzle-growth.de',
  medicalProd = 'app.stazzle-medical.de',
  businessProd = 'app.stazzle-business.de',
  growthProd = 'app.stazzle-growth.de',
  medicalStage = 'stazzle-medical.applicants.live',
  businessStage = 'stazzle-business.applicants.live',
  growthStage = 'stazzle-growth.applicants.live',
}

export class EnvironmentUtils {
  static getEnvironment(): BaseEnvironment {
    let environment = BaseEnvironment.dev;
    if (window && window.location && window.location.hostname) {
      const hostname = window.location.hostname.toLowerCase();
      const demoHosts = [DemoUrls.medicalDemo, DemoUrls.businessDemo, DemoUrls.growthDemo];
      const liveHosts = [DemoUrls.medicalProd, DemoUrls.businessProd, DemoUrls.growthProd];
      const stageHosts = [DemoUrls.medicalStage, DemoUrls.businessStage, DemoUrls.growthStage];

      if (liveHosts.indexOf(hostname as DemoUrls) !== -1) {
        environment = BaseEnvironment.prod;
      } else if (stageHosts.indexOf(hostname as DemoUrls) !== -1) {
        environment = BaseEnvironment.stage;
      } else if (demoHosts.indexOf(hostname as DemoUrls) !== -1) {
        environment = BaseEnvironment.demo;
      }
    }

    return environment;
  }

  public static getStazzleBaseUrl(application: BaseApplication): string {
    return EnvironmentUtils.getApplicationDomain(this.getEnvironment(), application);
  }

  public static getSentryXhrLogging(): boolean {
    // privacy protection remove xhr logging in production (live env)
    return EnvironmentUtils.getEnvironment() !== 'prod';
  }

  public static getSentryHistoryLogging(): boolean {
    // privacy protection remove history logging in production (live env)
    return EnvironmentUtils.getEnvironment() !== 'prod';
  }

  private static getApplicationDomain(environment: BaseEnvironment, application: BaseApplication): string {
    if (environment === BaseEnvironment.dev) {
      switch (application) {
        case BaseApplication.medical:
        default:
          return 'http://localhost:4201';
        case BaseApplication.growth:
          return 'http://localhost:4202';
        case BaseApplication.business:
          return 'http://localhost:4200';
      }
    }

    if (environment === BaseEnvironment.demo) {
      switch (application) {
        case BaseApplication.medical:
        default:
          return 'https://' + DemoUrls.medicalDemo;
        case BaseApplication.growth:
          return 'https://' + DemoUrls.growthDemo;
        case BaseApplication.business:
          return 'https://' + DemoUrls.businessDemo;
      }
    }

    if (environment === BaseEnvironment.prod) {
      switch (application) {
        case BaseApplication.medical:
        default:
          return 'https://' + DemoUrls.medicalProd;
        case BaseApplication.growth:
          return 'https://' + DemoUrls.growthProd;
        case BaseApplication.business:
          return 'https://' + DemoUrls.businessProd;
      }
    }

    if (environment === BaseEnvironment.stage) {
      switch (application) {
        case BaseApplication.medical:
        default:
          return 'https://' + DemoUrls.medicalStage;
        case BaseApplication.growth:
          return 'https://' + DemoUrls.growthStage;
        case BaseApplication.business:
          return 'https://' + DemoUrls.businessStage;
      }
    }

    // Backup domain
    return 'https://' + DemoUrls.medicalStage;
  }
}
