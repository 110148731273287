import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { BaseSharedService } from '../../../shared/services/base-shared.service';

@Component({
  selector: 'base-legal-document-links',
  templateUrl: './base-legal-document-links.component.html',
})
export class BaseLegalDocumentLinksComponent {
  @Input() isOffcanvasSidebar = false;

  constructor(
    private baseSharedService: BaseSharedService,
    private offcanvasService: NgbOffcanvas,
    private router: Router
  ) {}

  redirectToImprintPage() {
    this.router.navigate(['/auth/imprint']);

    if (this.isOffcanvasSidebar) {
      this.offcanvasService.dismiss();
    }
  }

  downloadLegalDocument(isPrivacyPolicy?: boolean): void {
    this.baseSharedService.downloadLegalDocument(isPrivacyPolicy);
  }
}
