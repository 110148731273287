import { AbstractControl } from '@angular/forms';
import { BASE_MAX_DOCUMENT_SIZE } from '../constants/base-max-filesizes';

export const baseFilesizeValidator = (maxSize?: number) => {
  const size = maxSize ? maxSize : BASE_MAX_DOCUMENT_SIZE;
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    const files = control.value;
    if (files) {
      const fileSize = files.length ? files[files.length - 1].size : files.size;
      const fileSizeInKB = Math.round(fileSize / 1024);
      if (fileSizeInKB >= size) {
        return {
          fileSizeTooBig: true,
        };
      } else {
        return null;
      }
    }
    return null;
  };
};
