<div class="d-flex flex-column my-6 mt-lg-0">
  <div class="d-flex flex-wrap justify-content-start align-items-center">
    <h6
      class="mb-0 me-4"
      *ngIf="headline"
    >
      {{ headline | translate | titlecase }}
    </h6>
    <base-button
      *ngIf="isPaymentAddressForm && isEditMode"
      [size]="buttonSizes.xs"
      color="dark"
      label="administration.paymentAddress.fromSame"
      (btnClick)="copyFromBusinessAddress()"
    ></base-button>
  </div>
  <p
    *ngIf="subTitle"
    class="mb-0 mt-1 fs-8 text-dark-gray"
  >
    {{ subTitle | translate }}
  </p>
</div>
<form
  [formGroup]="form"
  [ngClass]="{ 'was-validated': submitted }"
>
  <div class="d-flex flex-column flex-lg-row gap-4 mb-0 mb-lg-7 me-7">
    <div class="col-12 col-md-8 col-lg-4">
      <base-input
        label="streetHouseNumber"
        [isInvalid]="!!form.get('street')?.errors"
        [errorMessage]="form.get('street')?.errors ? 'validation.streetHouseNumber' : ''"
        placeholder=""
        formControlName="street"
        [isPreview]="!isEditMode"
        [isRequired]="true"
      ></base-input>
    </div>
    <div class="col-12 col-md-8 col-lg-4">
      <base-input
        label="zip"
        [isInvalid]="!!form.get('postCode')?.errors"
        [errorMessage]="form.get('postCode')?.errors ? 'validation.zipCode' : ''"
        placeholder=""
        formControlName="postCode"
        [isPreview]="!isEditMode"
        [isRequired]="true"
      ></base-input>
    </div>
    <div class="col-12 col-md-8 col-lg-4">
      <base-input
        label="city"
        [isInvalid]="!!form.get('city')?.errors"
        [errorMessage]="form.get('city')?.errors ? 'validation.city' : ''"
        placeholder=""
        formControlName="city"
        [isPreview]="!isEditMode"
        [isRequired]="true"
      ></base-input>
    </div>
  </div>
  <div class="d-flex flex-column flex-lg-row gap-4 mb-0 mt-3 me-7">
    <div class="col-12 col-md-8 col-lg-4">
      <base-country-dropdown
        label="country"
        formControlName="countryCode"
        [preview]="!isEditMode"
        [requiredLabel]="true"
      ></base-country-dropdown>
    </div>
    <div class="d-none d-lg-block col-lg-4"></div>
    <div class="d-none d-lg-block col-lg-4"></div>
  </div>
</form>
