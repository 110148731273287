/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CustomerOfferListItemResponse } from '../models/customer-offer-list-item-response';
import { ListApiResponse } from '../models/list-api-response';

@Injectable({
  providedIn: 'root',
})
export class DashboardService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getCustomerOffersList
   */
  static readonly GetCustomerOffersListPath = '/api/web/v1/customers/{customer}/offers';

  /**
   * Get Customer Offers List.
   *
   * **Accessible only for users with role 'ROLE_CUSTOMER_USER'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerOffersList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerOffersList$Response(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Page number in pagination.
     */
    page?: any;

    /**
     * Size number in pagination.
     */
    size?: any;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ListApiResponse & {
'data'?: Array<CustomerOfferListItemResponse>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, DashboardService.GetCustomerOffersListPath, 'get');
    if (params) {
      rb.path('customer', params.customer, {});
      rb.query('page', params.page, {});
      rb.query('size', params.size, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListApiResponse & {
        'data'?: Array<CustomerOfferListItemResponse>;
        }>;
      })
    );
  }

  /**
   * Get Customer Offers List.
   *
   * **Accessible only for users with role 'ROLE_CUSTOMER_USER'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCustomerOffersList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerOffersList(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Page number in pagination.
     */
    page?: any;

    /**
     * Size number in pagination.
     */
    size?: any;
    context?: HttpContext
  }
): Observable<ListApiResponse & {
'data'?: Array<CustomerOfferListItemResponse>;
}> {

    return this.getCustomerOffersList$Response(params).pipe(
      map((r: StrictHttpResponse<ListApiResponse & {
'data'?: Array<CustomerOfferListItemResponse>;
}>) => r.body as ListApiResponse & {
'data'?: Array<CustomerOfferListItemResponse>;
})
    );
  }

}
