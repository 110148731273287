import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { baseAuthActions, baseAuthSelectors, BaseCoreState } from '../../_store';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { BaseRolesService } from '../services/base-roles.service';
import { BaseTokenService } from '../services/base-token.service';
import { BaseLocalStorageService } from '../services/base-local-storage.service';

@Injectable()
export class BaseRefreshGuard {
  constructor(
    private tokenService: BaseTokenService,
    private localStorageService: BaseLocalStorageService,
    private router: Router,
    private rolesService: BaseRolesService,
    private store: Store<BaseCoreState>
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    if (!this.tokenService.getToken()) {
      // Do not refresh token if it is not currently existing in local storage (user was not logged in before)

      return true;
    }

    if (this.tokenService.getToken() && this.tokenService.isValid(this.tokenService.getToken())) {
      // Do not refresh token if it exists in local storage and valid
      // Load and set all necessary information only instead

      const decodedToken = this.tokenService.decodeToken(this.tokenService.getToken());
      this.store.dispatch(baseAuthActions.setUserInfoFromToken({ payload: decodedToken }));
      this.rolesService.setRolesAndPermissions(decodedToken.roles, decodedToken.permissions);
      this.rolesService.setPermissions(decodedToken.permissions);
      this.store.dispatch(
        baseAuthActions.setFirstLoginIndicator({ payload: !this.localStorageService.get('login-id') })
      );
      this.store.dispatch(baseAuthActions.successUserInfo());
      this.store.dispatch(baseAuthActions.successRefreshUser());

      return true;
    }

    this.store
      .select(baseAuthSelectors.getRefreshInfo)
      .pipe(take(1))
      .subscribe((status) => {
        if (status === undefined) {
          this.store.dispatch(baseAuthActions.refreshUser());
        }
      });

    return this.store.select(baseAuthSelectors.getRefreshInfo).pipe(
      filter((status) => status !== undefined),
      map((status) => {
        if (!status) {
          this.tokenService.deleteToken();
        }
        return true;
      })
    );
  }
}
