import { Pipe, PipeTransform } from '@angular/core';

const PADDING = '000000';
const DECIMAL_SEPARATOR = ',';
const THOUSANDS_SEPARATOR = '.';

@Pipe({ name: 'baseFormatDecimal' })
export class BaseFormatDecimalPipe implements PipeTransform {
  transform(value: number | string | undefined | null): string {
    if (value === null || value === undefined) {
      return '';
    }

    if (isNaN(parseFloat(value.toString())) || !isFinite(parseFloat(value.toString()))) {
      return value.toString();
    }

    const isFormatted = value.toString().includes(DECIMAL_SEPARATOR);
    let [integer, fraction = ''] = value.toString().split(isFormatted ? DECIMAL_SEPARATOR : '.');
    fraction = DECIMAL_SEPARATOR + (fraction + PADDING).substring(0, 2);
    integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, THOUSANDS_SEPARATOR);

    return integer + fraction;
  }

  parse(value: string): string {
    if (value === null || value === undefined) {
      return '';
    }

    if (isNaN(parseFloat(value.toString())) || !isFinite(parseFloat(value.toString()))) {
      return value.toString();
    }
    const isFormatted = value.toString().includes(DECIMAL_SEPARATOR);
    let [integer, fraction = ''] = value.toString().split(isFormatted ? DECIMAL_SEPARATOR : '.');
    integer = integer.split(THOUSANDS_SEPARATOR).join('');
    fraction = fraction && fraction.length > 0 ? `.${fraction}` : '';

    return integer + fraction;
  }
}
