/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AddUsersToBranchRequest } from '../models/add-users-to-branch-request';
import { BranchListItemResponse } from '../models/branch-list-item-response';
import { CreatedResponse } from '../models/created-response';
import { EmptySuccessfulResponse } from '../models/empty-successful-response';
import { GeneralApiResponse } from '../models/general-api-response';
import { ListApiResponse } from '../models/list-api-response';
import { PspBranchCreateRequest } from '../models/psp-branch-create-request';
import { PspBranchResponse } from '../models/psp-branch-response';
import { PspBranchUpdateRequest } from '../models/psp-branch-update-request';
import { PspBranchUpdateUsersRequest } from '../models/psp-branch-update-users-request';
import { UserItemResponse } from '../models/user-item-response';

@Injectable({
  providedIn: 'root',
})
export class PspBranchService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation postPspBranchActivateBranch
   */
  static readonly PostPspBranchActivateBranchPath = '/api/web/v1/psps/{psp}/branches/{branch}/activation';

  /**
   * Activate PSP Branch.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_BRANCHES'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postPspBranchActivateBranch()` instead.
   *
   * This method doesn't expect any request body.
   */
  postPspBranchActivateBranch$Response(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * Branch Id.
     */
    branch: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EmptySuccessfulResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PspBranchService.PostPspBranchActivateBranchPath, 'post');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.path('branch', params.branch, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmptySuccessfulResponse>;
      })
    );
  }

  /**
   * Activate PSP Branch.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_BRANCHES'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postPspBranchActivateBranch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  postPspBranchActivateBranch(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * Branch Id.
     */
    branch: number;
    context?: HttpContext
  }
): Observable<EmptySuccessfulResponse> {

    return this.postPspBranchActivateBranch$Response(params).pipe(
      map((r: StrictHttpResponse<EmptySuccessfulResponse>) => r.body as EmptySuccessfulResponse)
    );
  }

  /**
   * Path part for operation deletePspBranchDeactivateBranch
   */
  static readonly DeletePspBranchDeactivateBranchPath = '/api/web/v1/psps/{psp}/branches/{branch}/activation';

  /**
   * Deactivate PSP Branch.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_BRANCHES'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deletePspBranchDeactivateBranch()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePspBranchDeactivateBranch$Response(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * Branch Id.
     */
    branch: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EmptySuccessfulResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PspBranchService.DeletePspBranchDeactivateBranchPath, 'delete');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.path('branch', params.branch, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmptySuccessfulResponse>;
      })
    );
  }

  /**
   * Deactivate PSP Branch.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_BRANCHES'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deletePspBranchDeactivateBranch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePspBranchDeactivateBranch(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * Branch Id.
     */
    branch: number;
    context?: HttpContext
  }
): Observable<EmptySuccessfulResponse> {

    return this.deletePspBranchDeactivateBranch$Response(params).pipe(
      map((r: StrictHttpResponse<EmptySuccessfulResponse>) => r.body as EmptySuccessfulResponse)
    );
  }

  /**
   * Path part for operation getPspBranchListOfBranches
   */
  static readonly GetPspBranchListOfBranchesPath = '/api/web/v1/psps/{psp}/branches';

  /**
   * List PSP Branches.
   *
   * **Accessible only for users with permissions 'FEATURE_ADMIN_BRANCHES', 'FEATURE_VIEW_BRANCHES'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPspBranchListOfBranches()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPspBranchListOfBranches$Response(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * Filter active or inactive PSP Branches
     */
    active?: any;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ListApiResponse & {
'data'?: Array<BranchListItemResponse>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, PspBranchService.GetPspBranchListOfBranchesPath, 'get');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.query('active', params.active, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListApiResponse & {
        'data'?: Array<BranchListItemResponse>;
        }>;
      })
    );
  }

  /**
   * List PSP Branches.
   *
   * **Accessible only for users with permissions 'FEATURE_ADMIN_BRANCHES', 'FEATURE_VIEW_BRANCHES'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPspBranchListOfBranches$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPspBranchListOfBranches(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * Filter active or inactive PSP Branches
     */
    active?: any;
    context?: HttpContext
  }
): Observable<ListApiResponse & {
'data'?: Array<BranchListItemResponse>;
}> {

    return this.getPspBranchListOfBranches$Response(params).pipe(
      map((r: StrictHttpResponse<ListApiResponse & {
'data'?: Array<BranchListItemResponse>;
}>) => r.body as ListApiResponse & {
'data'?: Array<BranchListItemResponse>;
})
    );
  }

  /**
   * Path part for operation postPspBranchCreate
   */
  static readonly PostPspBranchCreatePath = '/api/web/v1/psps/{psp}/branches';

  /**
   * Create PSP Branch.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_BRANCHES'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postPspBranchCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPspBranchCreate$Response(params: {

    /**
     * PSP Id.
     */
    psp: number;
    context?: HttpContext
    body: PspBranchCreateRequest
  }
): Observable<StrictHttpResponse<CreatedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PspBranchService.PostPspBranchCreatePath, 'post');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CreatedResponse>;
      })
    );
  }

  /**
   * Create PSP Branch.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_BRANCHES'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postPspBranchCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPspBranchCreate(params: {

    /**
     * PSP Id.
     */
    psp: number;
    context?: HttpContext
    body: PspBranchCreateRequest
  }
): Observable<CreatedResponse> {

    return this.postPspBranchCreate$Response(params).pipe(
      map((r: StrictHttpResponse<CreatedResponse>) => r.body as CreatedResponse)
    );
  }

  /**
   * Path part for operation getPspBranchShow
   */
  static readonly GetPspBranchShowPath = '/api/web/v1/psps/{psp}/branches/{branch}';

  /**
   * Get PSP Branch data.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_BRANCHES'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPspBranchShow()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPspBranchShow$Response(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * Branch Id.
     */
    branch: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<GeneralApiResponse & {
'data'?: PspBranchResponse;
}>> {

    const rb = new RequestBuilder(this.rootUrl, PspBranchService.GetPspBranchShowPath, 'get');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.path('branch', params.branch, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GeneralApiResponse & {
        'data'?: PspBranchResponse;
        }>;
      })
    );
  }

  /**
   * Get PSP Branch data.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_BRANCHES'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPspBranchShow$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPspBranchShow(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * Branch Id.
     */
    branch: number;
    context?: HttpContext
  }
): Observable<GeneralApiResponse & {
'data'?: PspBranchResponse;
}> {

    return this.getPspBranchShow$Response(params).pipe(
      map((r: StrictHttpResponse<GeneralApiResponse & {
'data'?: PspBranchResponse;
}>) => r.body as GeneralApiResponse & {
'data'?: PspBranchResponse;
})
    );
  }

  /**
   * Path part for operation putPspBranchUpdate
   */
  static readonly PutPspBranchUpdatePath = '/api/web/v1/psps/{psp}/branches/{branch}';

  /**
   * Update PSP Branch.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_BRANCHES'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putPspBranchUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putPspBranchUpdate$Response(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * Branch Id.
     */
    branch: number;
    context?: HttpContext
    body: PspBranchUpdateRequest
  }
): Observable<StrictHttpResponse<EmptySuccessfulResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PspBranchService.PutPspBranchUpdatePath, 'put');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.path('branch', params.branch, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmptySuccessfulResponse>;
      })
    );
  }

  /**
   * Update PSP Branch.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_BRANCHES'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putPspBranchUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putPspBranchUpdate(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * Branch Id.
     */
    branch: number;
    context?: HttpContext
    body: PspBranchUpdateRequest
  }
): Observable<EmptySuccessfulResponse> {

    return this.putPspBranchUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<EmptySuccessfulResponse>) => r.body as EmptySuccessfulResponse)
    );
  }

  /**
   * Path part for operation getPspsBranchUsersList
   */
  static readonly GetPspsBranchUsersListPath = '/api/web/v1/psps/{psp}/branches/{branch}/users';

  /**
   * List users for specified PSP Branch.
   *
   * **Accessible only for users with permissions 'FEATURE_ADMIN_OFFERS', FEATURE_ADMIN_BRANCHES**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPspsBranchUsersList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPspsBranchUsersList$Response(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * Branch Id.
     */
    branch: number;

    /**
     * User roles to filter them by. Possible values: user, admin
     */
    'role[]'?: any;

    /**
     * Search user by first-last name and email.
     */
    search?: any;

    /**
     * Filter active or inactive users.
     */
    active?: boolean;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ListApiResponse & {
'data'?: Array<UserItemResponse>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, PspBranchService.GetPspsBranchUsersListPath, 'get');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.path('branch', params.branch, {});
      rb.query('role[]', params['role[]'], {"style":"form"});
      rb.query('search', params.search, {});
      rb.query('active', params.active, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListApiResponse & {
        'data'?: Array<UserItemResponse>;
        }>;
      })
    );
  }

  /**
   * List users for specified PSP Branch.
   *
   * **Accessible only for users with permissions 'FEATURE_ADMIN_OFFERS', FEATURE_ADMIN_BRANCHES**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPspsBranchUsersList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPspsBranchUsersList(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * Branch Id.
     */
    branch: number;

    /**
     * User roles to filter them by. Possible values: user, admin
     */
    'role[]'?: any;

    /**
     * Search user by first-last name and email.
     */
    search?: any;

    /**
     * Filter active or inactive users.
     */
    active?: boolean;
    context?: HttpContext
  }
): Observable<ListApiResponse & {
'data'?: Array<UserItemResponse>;
}> {

    return this.getPspsBranchUsersList$Response(params).pipe(
      map((r: StrictHttpResponse<ListApiResponse & {
'data'?: Array<UserItemResponse>;
}>) => r.body as ListApiResponse & {
'data'?: Array<UserItemResponse>;
})
    );
  }

  /**
   * Path part for operation putPspBranchUpdateUsers
   */
  static readonly PutPspBranchUpdateUsersPath = '/api/web/v1/psps/{psp}/branches/{branch}/users';

  /**
   * Update PSP Branch Users.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_BRANCHES'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putPspBranchUpdateUsers()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putPspBranchUpdateUsers$Response(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * Branch Id.
     */
    branch: number;
    context?: HttpContext
    body: PspBranchUpdateUsersRequest
  }
): Observable<StrictHttpResponse<EmptySuccessfulResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PspBranchService.PutPspBranchUpdateUsersPath, 'put');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.path('branch', params.branch, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmptySuccessfulResponse>;
      })
    );
  }

  /**
   * Update PSP Branch Users.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_BRANCHES'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putPspBranchUpdateUsers$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putPspBranchUpdateUsers(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * Branch Id.
     */
    branch: number;
    context?: HttpContext
    body: PspBranchUpdateUsersRequest
  }
): Observable<EmptySuccessfulResponse> {

    return this.putPspBranchUpdateUsers$Response(params).pipe(
      map((r: StrictHttpResponse<EmptySuccessfulResponse>) => r.body as EmptySuccessfulResponse)
    );
  }

  /**
   * Path part for operation patchPspBranchAddUsers
   */
  static readonly PatchPspBranchAddUsersPath = '/api/web/v1/psps/{psp}/branches/{branch}/users';

  /**
   * Add user to PSP Branch.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_BRANCHES'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchPspBranchAddUsers()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchPspBranchAddUsers$Response(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * Branch Id.
     */
    branch: number;
    context?: HttpContext
    body: AddUsersToBranchRequest
  }
): Observable<StrictHttpResponse<EmptySuccessfulResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PspBranchService.PatchPspBranchAddUsersPath, 'patch');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.path('branch', params.branch, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmptySuccessfulResponse>;
      })
    );
  }

  /**
   * Add user to PSP Branch.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_BRANCHES'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchPspBranchAddUsers$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchPspBranchAddUsers(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * Branch Id.
     */
    branch: number;
    context?: HttpContext
    body: AddUsersToBranchRequest
  }
): Observable<EmptySuccessfulResponse> {

    return this.patchPspBranchAddUsers$Response(params).pipe(
      map((r: StrictHttpResponse<EmptySuccessfulResponse>) => r.body as EmptySuccessfulResponse)
    );
  }

  /**
   * Path part for operation deletePspBranchDeleteUser
   */
  static readonly DeletePspBranchDeleteUserPath = '/api/web/v1/psps/{psp}/branches/{branch}/users/{user}';

  /**
   * Remove a user from PSP Branch.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_BRANCHES'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deletePspBranchDeleteUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePspBranchDeleteUser$Response(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * Branch Id.
     */
    branch: number;

    /**
     * User Id.
     */
    user: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EmptySuccessfulResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PspBranchService.DeletePspBranchDeleteUserPath, 'delete');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.path('branch', params.branch, {});
      rb.path('user', params.user, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmptySuccessfulResponse>;
      })
    );
  }

  /**
   * Remove a user from PSP Branch.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_BRANCHES'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deletePspBranchDeleteUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePspBranchDeleteUser(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * Branch Id.
     */
    branch: number;

    /**
     * User Id.
     */
    user: number;
    context?: HttpContext
  }
): Observable<EmptySuccessfulResponse> {

    return this.deletePspBranchDeleteUser$Response(params).pipe(
      map((r: StrictHttpResponse<EmptySuccessfulResponse>) => r.body as EmptySuccessfulResponse)
    );
  }

}
