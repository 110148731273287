import { Component, Input, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ViewportScroller } from '@angular/common';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { noop, Subject } from 'rxjs';
import { MetaPagination, NotificationResponse, NotificationsListResponse } from '../../../../api';
import { BaseButtonSize } from '../../../shared/components/button/base-button.component';
import { baseSharedActions, baseSharedSelectors, BaseCoreState } from '../../../_store';
import { BaseModalService } from '../../../shared/services/base-modal.service';

interface QueryParamInterface {
  [key: string]: any;
}

@Component({
  selector: 'base-notifications-modal',
  templateUrl: './base-notifications-modal.component.html',
})
export class BaseNotificationsModalComponent implements OnInit, OnDestroy {
  @ViewChild('notificationsContainer', { static: true }) notificationsContainer: ElementRef;

  @Input() modalRef: NgbModalRef;
  @Input() activeNotificationId: number;

  notifications: Array<NotificationResponse> = [];
  buttonSize = BaseButtonSize;
  page = 0;
  pageSize = 0;
  size = 0;
  isListLoaded = false;
  allNotificationsRead = false;

  private destroy$ = new Subject();

  constructor(
    private elRef: ElementRef,
    public scroller: ViewportScroller,
    private store: Store<BaseCoreState>,
    private router: Router,
    private modalService: BaseModalService
  ) {}

  ngOnInit() {
    this.store.dispatch(baseSharedActions.loadUserNotifications({ payload: {} }));
    this.modalRef.shown.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.scrollToActiveNotification();
    });

    this.store
      .select(baseSharedSelectors.getUserNotifications)
      .pipe(
        takeUntil(this.destroy$),
        filter((state) => !!state.ok)
      )
      .subscribe(
        ({
          data,
          pagination,
        }: {
          data: NotificationsListResponse | undefined;
          pagination: MetaPagination | undefined;
        }) => {
          this.notifications = data?.notifications as NotificationResponse[];
          this.isListLoaded = true;
          this.allNotificationsRead = data?.unRead === 0;
          this.page = pagination?.page.current || 0;
          this.pageSize = pagination?.page.size || 0;
          this.size = pagination?.page.itemsCount || 0;
        }
      );
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  pageChanged(value: number): void {
    if (value !== this.page) {
      this.page = value;
      this.store.dispatch(
        baseSharedActions.loadUserNotifications({ payload: { page: this.page, size: this.pageSize } })
      );
    }
    this.notificationsContainer.nativeElement.scrollIntoView();
  }

  getRelativeUrl(url: string): { path: string; queryParams: QueryParamInterface } | null {
    if (!url) {
      return null;
    }

    let currentUrl: string;

    if (/(http(s?)):\/\//i.test(url)) {
      currentUrl = new URL(url).pathname;
    } else {
      currentUrl = url;
    }

    const [path, ...rest] = currentUrl.split(/[?&]/);

    return {
      path,
      queryParams: rest.reduce<QueryParamInterface>((acc, query) => {
        const dividedQuery = query.split('=');
        acc[dividedQuery[0]] = dividedQuery[1];
        return acc;
      }, {}),
    };
  }

  markNotificationAsRead(event: Event, notification: NotificationResponse): void {
    event.preventDefault();

    const urlData = this.getRelativeUrl(
      notification.context?.url || notification.messageBody?.match(/href="(.*)"/)?.[1]
    );

    if (urlData) {
      this.goToNotificationLink(urlData, notification);
    } else {
      if (!notification.isRead) {
        this.updateNotificationReadStatus(notification.id);
      }
    }
  }

  goToNotificationLink(
    url: { path: string; queryParams: QueryParamInterface },
    { isRead, id }: { isRead: boolean; id: number }
  ): void {
    this.router
      .navigate([url.path], {
        queryParams: { ...url.queryParams },
      })
      .then(() => {
        if (!isRead) {
          this.store.dispatch(
            baseSharedActions.setNotificationReadState({
              payload: { notification: id },
              page: this.page,
              size: this.pageSize,
            })
          );
        }

        this.modalRef.close();
      });
  }

  scrollToActiveNotification() {
    if (this.activeNotificationId) {
      const currentNotificationElem = document.getElementById(`n${this.activeNotificationId}`);

      if (currentNotificationElem) {
        currentNotificationElem.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }

  markAllAsRead(): void {
    const confirmationModalRef = this.modalService.open(
      {
        headText: 'notification.confirmationModal.headText',
        bodyText: 'notification.confirmationModal.bodyText',
        okText: 'action.okProceed',
      },
      {
        scrollable: true,
        backdrop: 'static',
        size: 'lg',
      }
    );

    confirmationModalRef.result.then(() => {
      this.store.dispatch(baseSharedActions.setAllUserNotificationsAsRead());
    }, noop);
  }

  closeModal(): void {
    this.store.dispatch(baseSharedActions.loadUserNotifications({ payload: { isRead: false } }));
    this.modalRef.close();
  }

  private updateNotificationReadStatus(notificationId: number): void {
    this.store.dispatch(
      baseSharedActions.setNotificationReadState({
        payload: { notification: notificationId },
        page: this.page,
        size: this.pageSize,
      })
    );
  }
}
