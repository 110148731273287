import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BaseDeregisterCandidateModalComponent } from './_components/base-deregister-candidate-modal/base-deregister-candidate-modal.component';
import { BaseWrapperComponent } from './_components/base-wrapper/base-wrapper.component';
import { BaseContactButtonComponent } from './_components/base-contact-button/base-contact-button.component';
import { BaseHeaderComponent } from './_components/base-header/base-header.component';
import { BaseSidebarComponent } from './_components/base-sidebar/base-sidebar.component';
import { BaseUserNavComponent } from './_components/base-user-nav/base-user-nav.component';
import { BaseEmptyListRowComponent } from './_components/base-empty-list-row/base-empty-list-row.component';
import { BaseVerticalNavComponent } from './_components/base-vertical-nav/base-vertical-nav.component';
import { BasePageTitleComponent } from './_components/base-page-title/base-page-title.component';
import { BaseErrorComponent } from './_components/base-error/base-error.component';
import { BaseSharedModule } from '../shared/base-shared.module';
import { BaseAmountCircleComponent } from './_components/base-amount-circle/base-amount-circle.component';
import { BaseAvatarTeaserComponent } from './_components/base-avatar-teaser/base-avatar-teaser.component';
import { BaseHorizontalNavComponent } from './_components/base-horizontal-nav/base-horizontal-nav.component';
import { BaseInlineEditComponent } from './_components/base-inline-edit/base-inline-edit.component';
import { BaseOffersStatusComponent } from './_components/base-offers-status/base-offers-status.component';
import { BaseSortableHeadlineItemComponent } from './_components/base-sortable-headline-item/base-sortable-headline-item.component';
import { BaseNotificationsComponent } from './_components/base-notifications/base-notifications.component';
import { BaseNotificationsModalComponent } from './_components/base-notifications-modal/base-notifications-modal.component';
import { BaseDocumentuploadModalComponent } from './_components/base-documentupload-modal/base-documentupload-modal.component';
import { BaseNotificationsDropdownComponent } from './_components/base-notifications/base-notifications-dropdown/base-notifications-dropdown.component';
import { BaseSurchargesComponent } from './_components/surcharges/base-surcharges.component';
import { BaseUnbrandedWrapperComponent } from './_components/base-unbranded-wrapper/base-unbranded-wrapper.component';
import { BaseLegalDocumentLinksComponent } from './_components/base-legal-document-links/base-legal-document-links.component';
import { BaseDeleteDocumentConfirmationTemplateComponent } from './_components/base-delete-document-confirmation-template/base-delete-document-confirmation-template.component';
import { BaseDatePickerModalComponent } from './_components/base-date-picker-modal/base-date-picker-modal.component';
import { BaseGoBackTeaserComponent } from './_components/base-go-back-teaser/base-go-back-teaser.component';
import { BaseIndustrySurchargesComponent } from './_components/base-industry-surcharges/base-industry-surcharges.component';

@NgModule({
  declarations: [
    BaseWrapperComponent,
    BaseContactButtonComponent,
    BaseDocumentuploadModalComponent,
    BaseHeaderComponent,
    BaseSidebarComponent,
    BaseUserNavComponent,
    BaseEmptyListRowComponent,
    BaseVerticalNavComponent,
    BasePageTitleComponent,
    BaseErrorComponent,
    BaseAmountCircleComponent,
    BaseAvatarTeaserComponent,
    BaseHorizontalNavComponent,
    BaseInlineEditComponent,
    BaseOffersStatusComponent,
    BaseSortableHeadlineItemComponent,
    BaseNotificationsComponent,
    BaseNotificationsModalComponent,
    BaseNotificationsDropdownComponent,
    BaseSurchargesComponent,
    BaseUnbrandedWrapperComponent,
    BaseDeregisterCandidateModalComponent,
    BaseLegalDocumentLinksComponent,
    BaseDeleteDocumentConfirmationTemplateComponent,
    BaseDatePickerModalComponent,
    BaseGoBackTeaserComponent,
    BaseIndustrySurchargesComponent,
  ],
  imports: [CommonModule, RouterModule, BaseSharedModule, NgbModule, ReactiveFormsModule, FormsModule],
  exports: [
    BaseWrapperComponent,
    BaseContactButtonComponent,
    BaseDocumentuploadModalComponent,
    BaseHeaderComponent,
    BaseSidebarComponent,
    BaseUserNavComponent,
    BaseEmptyListRowComponent,
    BaseVerticalNavComponent,
    BasePageTitleComponent,
    BaseErrorComponent,
    BaseAmountCircleComponent,
    BaseAvatarTeaserComponent,
    BaseHorizontalNavComponent,
    BaseInlineEditComponent,
    BaseOffersStatusComponent,
    BaseSortableHeadlineItemComponent,
    BaseSurchargesComponent,
    BaseUnbrandedWrapperComponent,
    BaseDeregisterCandidateModalComponent,
    BaseLegalDocumentLinksComponent,
    BaseDeleteDocumentConfirmationTemplateComponent,
    BaseDatePickerModalComponent,
    BaseGoBackTeaserComponent,
    BaseIndustrySurchargesComponent,
  ],
})
export class AppsSharedModule {
  static forRoot(): ModuleWithProviders<AppsSharedModule> {
    return {
      ngModule: AppsSharedModule,
      providers: [],
    };
  }
}
