import { Injectable } from '@angular/core';
import { I18N_VALUES } from './base-datepicker.const';
import { TranslateService } from '@ngx-translate/core';
import { NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { TranslationWidth } from '@angular/common';

@Injectable()
export class BaseCustomDatepickerI18n extends NgbDatepickerI18n {
  constructor(private translateService: TranslateService) {
    super();
  }

  getWeekdayLabel(weekday: number, width?: TranslationWidth): string {
    return I18N_VALUES[this.translateService.currentLang].weekdays[weekday - 1];
  }

  getMonthShortName(month: number): string {
    return I18N_VALUES[this.translateService.currentLang].months[month - 1];
  }

  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return '';
  }
}
