<div class="inquiries-calendar-meta">
  <div class="inquiries-calendar-navigation">
    <i
      class="fa-solid fa-angles-left pointer"
      [ngbTooltip]="'tooltip.jumpStart' | translate"
      (click)="navigateCalendar(baseInquiryScrollSliderEnum.start)"
    ></i>
    <i
      class="fa-solid fa-chevron-left pointer"
      [ngbTooltip]="'tooltip.backwards' | translate"
      (click)="navigateCalendar(baseInquiryScrollSliderEnum.left)"
    ></i>
    <span class="fw-bold">{{ 'days.calendarWeek' | translate }}</span>
    <i
      class="fa-solid fa-chevron-right pointer"
      [ngbTooltip]="'tooltip.forwards' | translate"
      (click)="navigateCalendar(baseInquiryScrollSliderEnum.right)"
    ></i>
    <i
      class="fa-solid fa-angles-right pointer"
      [ngbTooltip]="'tooltip.jumpEnd' | translate"
      (click)="navigateCalendar(baseInquiryScrollSliderEnum.end)"
    ></i>
  </div>
</div>
