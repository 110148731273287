import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { baseSharedActions, baseSharedSelectors, BaseCoreState } from '../../_store';

@Injectable()
export class BaseRequirementsGuard {
  constructor(private readonly store: Store<BaseCoreState>) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    this.store
      .select(baseSharedSelectors.getRequirements)
      .pipe(
        take(1),
        map((response) => response.data)
      )
      .subscribe((requirements) => {
        if (!requirements) {
          this.store.dispatch(baseSharedActions.getRequirements({ application: route.data.application }));
        }
      });

    return this.store.select(baseSharedSelectors.getRequirements).pipe(
      filter((item) => item.ok !== undefined),
      map((item) => !!item.ok)
    );
  }
}
