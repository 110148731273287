<base-unbranded-wrapper>
  <h1 class="headline-36 font-weight-bold">{{ (is404 ? 'errorPages.404' : 'errorPages.403') | translate }}</h1>
  <img
    height="400"
    class="my-6 error-image"
    [src]="is404 ? 'assets-global/images/404.svg' : 'assets-global/images/403.svg'"
    [alt]="is404 ? '404' : '403'"
  />
  <div class="d-flex align-items-center gap-4 justify-content-end return-back-line">
    <h1 class="headline-36 font-weight-bold">{{ 'errorPages.subtext1' | translate }}</h1>
    <base-button
      [size]="buttonSize.lg"
      label="action.back"
      color="primary"
      (click)="goBack()"
    ></base-button>
    <h1 class="headline-36 font-weight-bold">{{ 'errorPages.subtext2' | translate }}</h1>
  </div>
</base-unbranded-wrapper>
