import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BASE_PERMISSIONS } from '../../../core/constants/base-permissions';
import { BaseRolesService } from '../../../core/services/base-roles.service';

export enum BaseButtonSize {
  'xs' = 'xs',
  'sm' = 'sm',
  'md' = 'md',
  'lg' = 'lg',
}

export enum BaseButtonType {
  'button' = 'button',
  'submit' = 'submit',
  'reset' = 'reset',
}

export type BaseButtonColor = 'dark' | 'primary' | 'secondary' | 'tertiary' | 'white' | 'light' | 'transparent' | '';

@Component({
  selector: 'base-button',
  templateUrl: './base-button.component.html',
})
export class BaseButtonComponent {
  @Input() label = '';
  @Input() outlined = false;
  @Input() color: BaseButtonColor = '';
  @Input() margin = '';
  @Input() size = BaseButtonSize.md;
  @Input() disabled = false;
  @Input() icon = '';
  @Input() iconColor = '';
  @Input() iconSize = BaseButtonSize.sm;
  @Input() type = BaseButtonType.button;
  /* Keep in mind: If the disabled property is set to true, the tooltip property won't work.
   * In this case use ngbTooltip directly on the component */
  @Input() tooltip = '';
  @Input() frontBadge = '';
  @Input() stateClass: string;
  @Input() permissionBased = false;
  @Input() iconClass = '';
  @Output() btnClick = new EventEmitter<MouseEvent>();
  @Input() tabindex: number | null = null;
  @Input() shadow = true;

  constructor(private readonly rolesService: BaseRolesService) {}

  get classes(): string[] {
    const classes = [
      'btn',
      'btn-md-sm',
      'text-truncate',
      this.outlined ? `btn-outline-${this.color}` : `btn-${this.color}`,
      `btn-${this.size}`,
      this.margin,
    ];
    if (this.label.length === 0 && this.icon.length > 0) {
      classes.push(this.iconSize === BaseButtonSize.md && !this.iconClass ? 'btn-icon ps-1' : 'btn-icon');
    }
    if (this.stateClass?.length > 0) {
      classes.push(this.stateClass);
    }
    if (!this.shadow) {
      classes.push('shadow-none');
    }

    if (this.permissionBased && !this.rolesService.hasFeaturePermission(BASE_PERMISSIONS.FEATURE_SIGN_CONTRACT)) {
      classes.push('no-pointer');
    }

    return classes;
  }

  get iconClasses(): string[] {
    return [this.icon, `icon-${this.iconSize}`, this.iconColor];
  }

  get badgeClasses(): string[] {
    return [`btn-badge-${this.color}`];
  }

  clickHandler($event: MouseEvent): void {
    if (this.permissionBased && !this.rolesService.hasFeaturePermission(BASE_PERMISSIONS.FEATURE_SIGN_CONTRACT)) {
      return;
    }
    this.btnClick.emit($event);
  }
}
