import { Component, Input } from '@angular/core';
import {
  BestMatchingProfileListItemResponse,
  CustomerDepartmentShortInfoResponse,
  CustomerInquiryOfferProfileListItemResponse,
  ProfileListItemForPspAssignmentResponse,
  ProfileListItemShortResponse,
  QualificationShortInfoResponse,
} from '../../../../api';
import { BaseAvatarSize } from '../avatar/base-avatar.component';
import { BaseButtonSize } from '../button/base-button.component';

@Component({
  selector: 'base-candidate-list-profile',
  templateUrl: './base-candidate-list-profile.component.html',
})
export class BaseCandidateListProfileComponent {
  @Input() profile:
    | ProfileListItemShortResponse
    | BestMatchingProfileListItemResponse
    | CustomerInquiryOfferProfileListItemResponse
    | ProfileListItemForPspAssignmentResponse;
  @Input() department: CustomerDepartmentShortInfoResponse;
  @Input() mainQualification: QualificationShortInfoResponse;
  @Input() rating: number | string;
  @Input() distance: string;
  @Input() hourlyAmount: number | string;
  @Input() showStatusAlert = false;
  @Input() hideDepartmentInfo = false;
  @Input() hideBadges = false;
  avatarSize = BaseAvatarSize;
  buttonSizes = BaseButtonSize;
}
