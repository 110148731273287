import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject, noop } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import {
  BaseButtonSize,
  baseCandidateActions,
  baseCandidatesSelectors,
  BaseCoreState,
  BaseModalHeaderSizes,
  BaseModalService,
  BaseTabInterface,
  BaseAbsenceTypeEnum,
  ProfileAbsenceListItemResponse,
  ProfileAbsencesTypeSummaryResponse,
  ProfileShowResponse,
  BaseAbsencePayloadTypeEnum,
} from 'base';

export interface AbsencePagination {
  page: number;
  size: number;
}

@Component({
  selector: 'growth-absence-time',
  templateUrl: './absence-time.component.html',
  styleUrls: ['./absence-time.component.scss'],
})
export class AbsenceTimeComponent implements OnInit, OnDestroy {
  @ViewChild('editAbsenceModal') editAbsenceModal: ElementRef;
  @Input() profile: ProfileShowResponse;
  @Input() inquiryId: number;
  @Input() reloadOffersAndCandidates: boolean;
  @Output() pageChange: EventEmitter<any> = new EventEmitter();
  @Output() tabUpdate: EventEmitter<BaseTabInterface> = new EventEmitter();
  modalRef: NgbModalRef;
  absences: ProfileAbsenceListItemResponse[] = [];
  summary: ProfileAbsencesTypeSummaryResponse;
  absenceToEdit: ProfileAbsenceListItemResponse;
  buttonSize = BaseButtonSize;
  absenceTypes = BaseAbsenceTypeEnum;
  absencePayloadTypes = BaseAbsencePayloadTypeEnum;
  tabs: BaseTabInterface[] = [];
  activeTab: BaseTabInterface;
  totalAbsencesNumber: number;
  pageNumber = 1;
  size: number;
  pageSize = 5;
  private destroy$ = new Subject();

  constructor(private store: Store<BaseCoreState>, private modalService: BaseModalService) {}

  ngOnInit() {
    this.tabs = [
      { title: 'all', id: this.absenceTypes.all, url: '#' },
      { title: 'work.holiday', id: this.absenceTypes.holiday, badge: 0, url: '#' },
      { title: 'work.illness', id: this.absenceTypes.illness, badge: 0, url: '#' },
      { title: 'work.offline', id: this.absenceTypes.offline, badge: 0, url: '#' },
      { title: 'work.free_shift', id: this.absenceTypes.freeShift, badge: 0, url: '#' },
      { title: 'work.time_off_compensation', id: this.absenceTypes.timeOffCompensation, badge: 0, url: '#' },
      { title: 'work.unexcused_absence', id: this.absenceTypes.unexcused, badge: 0, url: '#' },
      { title: 'work.other', id: this.absenceTypes.other, badge: 0, url: '#' },
    ];
    this.activeTab = this.tabs[0];
    this.loadSummary();

    this.store
      .select(baseCandidatesSelectors.getAbsenceSummary)
      .pipe(
        takeUntil(this.destroy$),
        filter((state) => state.ok),
        map((state) => state.data)
      )
      .subscribe((summary) => {
        this.totalAbsencesNumber = 0;
        for (const element in summary) {
          this.totalAbsencesNumber += summary[element];
        }
        this.summary = summary;
        this.tabs.forEach((entry) => {
          entry.badge = summary[entry.id as number];
        });
      });

    this.store
      .select(baseCandidatesSelectors.getAbsences)
      .pipe(
        takeUntil(this.destroy$),
        filter((state) => !!state.ok),
        map((state) => state.data)
      )
      .subscribe((absences) => {
        this.absences = absences.data;
        this.size = absences.meta?.page.itemsCount as number;
        this.pageNumber = absences.meta?.page.current;
        this.pageSize = 5;
      });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }

  changePage(event: any) {
    this.pageNumber = event;
    this.loadAbsences();
  }

  selectMenuItem(item: BaseTabInterface) {
    this.tabUpdate.emit(item);
    this.pageNumber = 1;
    this.loadAbsences();
  }

  openEditModal(absence: ProfileAbsenceListItemResponse) {
    this.modalRef = this.modalService.open(
      {
        headText: 'work.absences.edit',
        headerSize: BaseModalHeaderSizes.lg,
        bodyTemplate: this.editAbsenceModal,
        hideFooter: true,
      },
      {
        scrollable: false,
        backdrop: 'static',
        size: 'lg',
      }
    );
    this.modalRef.result.then(noop, noop);
    this.absenceToEdit = absence;
  }

  deleteAbsence(absence: ProfileAbsenceListItemResponse) {
    const deleteModal = this.modalService.open({
      headText: 'work.absences.delete',
      bodyText: 'work.absences.deleteConfirmation',
      okText: 'action.okRemove',
    });

    deleteModal.result.then(() => {
      if (!this.activeTab.id) {
        return;
      }
      const activeTabId = this.activeTab.id?.toString() as BaseAbsenceTypeEnum;

      this.store.dispatch(
        baseCandidateActions.deleteAbsenceAction({
          payload: {
            profile: this.profile?.id as number,
            absence: absence.id,
            inquiry: this.reloadOffersAndCandidates ? this.inquiryId : undefined,
            absenceTab: this.activeTab.id !== this.absenceTypes.all ? this.absencePayloadTypes[activeTabId] : undefined,
          },
        })
      );
    }, noop);
  }

  closeModal() {
    this.modalRef.close();
  }

  private loadSummary() {
    if (!this.profile?.id) {
      return;
    }
    this.store.dispatch(baseCandidateActions.loadAbsenceSummaryAction({ payload: { profile: this.profile.id } }));
  }

  private loadAbsences() {
    if (!this.profile?.id) {
      return;
    }

    const activeTabId = this.activeTab.id?.toString() as BaseAbsenceTypeEnum;
    this.store.dispatch(
      baseCandidateActions.loadAbsencesAction({
        payload: {
          profile: this.profile.id,
          page: this.pageNumber,
          type: this.activeTab.id !== this.absenceTypes.all ? this.absencePayloadTypes[activeTabId] : undefined,
          size: this.pageSize,
        },
      })
    );
  }
}
