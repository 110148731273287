import { AbstractControl } from '@angular/forms';
import { BaseDateService } from '../services/base-date.service';

export const baseExportWorkTimesValidator = () => {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    const startMonthValue = control.value.startMonth;
    const endMonthValue = control.value.endMonth;

    if (!startMonthValue || !endMonthValue) {
      return null;
    }
    const startDate = BaseDateService.formatExportTime(startMonthValue, control.value.startYear.value);
    const endDate = BaseDateService.formatExportTime(endMonthValue, control.value.endYear.value);
    if (BaseDateService.isBefore(new Date(endDate), new Date(startDate))) {
      return { datesInvalid: true };
    }
    return null;
  };
};
