import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FiltersResponse, MetaPagination, SectionResponse } from '../../../api';

const getOffersAndBookingsState = createFeatureSelector('offersAndBookings');

export const getOfferOverlapsList = createSelector(
  getOffersAndBookingsState,
  (
    state: any
  ): {
    data: SectionResponse[] | undefined;
    pagination: MetaPagination | undefined;
    filters: FiltersResponse | undefined;
    ok: boolean | undefined;
  } => state.offerOverlapsList
);
