/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CreatedResponse } from '../models/created-response';
import { DocumentCreationResponse } from '../models/document-creation-response';
import { DocumentListItemResponse } from '../models/document-list-item-response';
import { DocumentRequest } from '../models/document-request';
import { DocumentResponse } from '../models/document-response';
import { EmptySuccessfulResponse } from '../models/empty-successful-response';
import { GeneralApiResponse } from '../models/general-api-response';
import { GroupCreateRequest } from '../models/group-create-request';
import { GroupItemResponse } from '../models/group-item-response';
import { GroupListItemResponse } from '../models/group-list-item-response';
import { GroupUpdateRequest } from '../models/group-update-request';
import { ListApiResponse } from '../models/list-api-response';
import { LogoUpdateRequest } from '../models/logo-update-request';
import { PspAdditionalInfoUpdateRequest } from '../models/psp-additional-info-update-request';
import { PspDashboardForecastResponse } from '../models/psp-dashboard-forecast-response';
import { PspDashboardResponse } from '../models/psp-dashboard-response';
import { PspMainInfoUpdateRequest } from '../models/psp-main-info-update-request';
import { PspRegistrationRequest } from '../models/psp-registration-request';
import { PspResponse } from '../models/psp-response';
import { PspVerificationRequest } from '../models/psp-verification-request';
import { PriceListRequest } from '../models/price-list-request';
import { PspInviteRequest } from '../models/psp-invite-request';
import { QualificationPricingListResponse } from '../models/qualification-pricing-list-response';
import { UpdatedResponse } from '../models/updated-response';
import { UserItemResponse } from '../models/user-item-response';
import { UserPasswordUpdateRequest } from '../models/user-password-update-request';
import { UserResponse } from '../models/user-response';
import { UserUpdateRequest } from '../models/user-update-request';

@Injectable({
  providedIn: 'root',
})
export class PspService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation postPspCreate
   */
  static readonly PostPspCreatePath = '/api/web/v1/psps';

  /**
   * Register new PSP.
   *
   * **Accessible only for users with roles 'ROLE_PSP_USER', 'ROLE_CUSTOMER_USER'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postPspCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPspCreate$Response(params: {
    context?: HttpContext
    body: PspRegistrationRequest
  }
): Observable<StrictHttpResponse<CreatedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PspService.PostPspCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CreatedResponse>;
      })
    );
  }

  /**
   * Register new PSP.
   *
   * **Accessible only for users with roles 'ROLE_PSP_USER', 'ROLE_CUSTOMER_USER'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postPspCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPspCreate(params: {
    context?: HttpContext
    body: PspRegistrationRequest
  }
): Observable<CreatedResponse> {

    return this.postPspCreate$Response(params).pipe(
      map((r: StrictHttpResponse<CreatedResponse>) => r.body as CreatedResponse)
    );
  }

  /**
   * Path part for operation getPspDashboardGetForecast
   */
  static readonly GetPspDashboardGetForecastPath = '/api/web/v1/psps/{psp}/dashboard/forecast';

  /**
   * Get forecaster for dashboard.
   *
   * **Accessible only for users with permission 'FEATURE_VIEW_DASHBOARD'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPspDashboardGetForecast()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPspDashboardGetForecast$Response(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * Start date, including.
     */
    from: string;

    /**
     * End date, including.
     */
    to: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<GeneralApiResponse & {
'data'?: PspDashboardForecastResponse;
}>> {

    const rb = new RequestBuilder(this.rootUrl, PspService.GetPspDashboardGetForecastPath, 'get');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.query('from', params.from, {});
      rb.query('to', params.to, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GeneralApiResponse & {
        'data'?: PspDashboardForecastResponse;
        }>;
      })
    );
  }

  /**
   * Get forecaster for dashboard.
   *
   * **Accessible only for users with permission 'FEATURE_VIEW_DASHBOARD'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPspDashboardGetForecast$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPspDashboardGetForecast(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * Start date, including.
     */
    from: string;

    /**
     * End date, including.
     */
    to: string;
    context?: HttpContext
  }
): Observable<GeneralApiResponse & {
'data'?: PspDashboardForecastResponse;
}> {

    return this.getPspDashboardGetForecast$Response(params).pipe(
      map((r: StrictHttpResponse<GeneralApiResponse & {
'data'?: PspDashboardForecastResponse;
}>) => r.body as GeneralApiResponse & {
'data'?: PspDashboardForecastResponse;
})
    );
  }

  /**
   * Path part for operation getPspDashboardGet
   */
  static readonly GetPspDashboardGetPath = '/api/web/v1/psps/{psp}/dashboard';

  /**
   * Get PSP dashboard data.
   *
   * **Accessible only for users with permission 'FEATURE_VIEW_DASHBOARD'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPspDashboardGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPspDashboardGet$Response(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * PSP Branch to filter Assignments Count by
     */
    assignmentBranch?: any;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<GeneralApiResponse & {
'data'?: PspDashboardResponse;
}>> {

    const rb = new RequestBuilder(this.rootUrl, PspService.GetPspDashboardGetPath, 'get');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.query('assignmentBranch', params.assignmentBranch, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GeneralApiResponse & {
        'data'?: PspDashboardResponse;
        }>;
      })
    );
  }

  /**
   * Get PSP dashboard data.
   *
   * **Accessible only for users with permission 'FEATURE_VIEW_DASHBOARD'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPspDashboardGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPspDashboardGet(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * PSP Branch to filter Assignments Count by
     */
    assignmentBranch?: any;
    context?: HttpContext
  }
): Observable<GeneralApiResponse & {
'data'?: PspDashboardResponse;
}> {

    return this.getPspDashboardGet$Response(params).pipe(
      map((r: StrictHttpResponse<GeneralApiResponse & {
'data'?: PspDashboardResponse;
}>) => r.body as GeneralApiResponse & {
'data'?: PspDashboardResponse;
})
    );
  }

  /**
   * Path part for operation getPspDocumentListOfDocuments
   */
  static readonly GetPspDocumentListOfDocumentsPath = '/api/web/v1/psps/{psp}/documents';

  /**
   * Get list of documents.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_DOCUMENTS'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPspDocumentListOfDocuments()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPspDocumentListOfDocuments$Response(params: {

    /**
     * PSP Id.
     */
    psp: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ListApiResponse & {
'data'?: Array<DocumentListItemResponse>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, PspService.GetPspDocumentListOfDocumentsPath, 'get');
    if (params) {
      rb.path('psp', params.psp, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListApiResponse & {
        'data'?: Array<DocumentListItemResponse>;
        }>;
      })
    );
  }

  /**
   * Get list of documents.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_DOCUMENTS'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPspDocumentListOfDocuments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPspDocumentListOfDocuments(params: {

    /**
     * PSP Id.
     */
    psp: number;
    context?: HttpContext
  }
): Observable<ListApiResponse & {
'data'?: Array<DocumentListItemResponse>;
}> {

    return this.getPspDocumentListOfDocuments$Response(params).pipe(
      map((r: StrictHttpResponse<ListApiResponse & {
'data'?: Array<DocumentListItemResponse>;
}>) => r.body as ListApiResponse & {
'data'?: Array<DocumentListItemResponse>;
})
    );
  }

  /**
   * Path part for operation postPspDocumentAdd
   */
  static readonly PostPspDocumentAddPath = '/api/web/v1/psps/{psp}/documents';

  /**
   * Upload a file and create a document.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_DOCUMENTS'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postPspDocumentAdd()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  postPspDocumentAdd$Response(params: {

    /**
     * PSP Id.
     */
    psp: number;
    context?: HttpContext

    /**
     * Upload a document. There should be a variable with a name "file" which contains file
     */
    body: {
'file'?: Blob;
}
  }
): Observable<StrictHttpResponse<{
'message': string;
'data': DocumentCreationResponse;
}>> {

    const rb = new RequestBuilder(this.rootUrl, PspService.PostPspDocumentAddPath, 'post');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'message': string;
        'data': DocumentCreationResponse;
        }>;
      })
    );
  }

  /**
   * Upload a file and create a document.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_DOCUMENTS'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postPspDocumentAdd$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  postPspDocumentAdd(params: {

    /**
     * PSP Id.
     */
    psp: number;
    context?: HttpContext

    /**
     * Upload a document. There should be a variable with a name "file" which contains file
     */
    body: {
'file'?: Blob;
}
  }
): Observable<{
'message': string;
'data': DocumentCreationResponse;
}> {

    return this.postPspDocumentAdd$Response(params).pipe(
      map((r: StrictHttpResponse<{
'message': string;
'data': DocumentCreationResponse;
}>) => r.body as {
'message': string;
'data': DocumentCreationResponse;
})
    );
  }

  /**
   * Path part for operation getPspDocumentShow
   */
  static readonly GetPspDocumentShowPath = '/api/web/v1/psps/{psp}/documents/{document}';

  /**
   * Get document info.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_DOCUMENTS'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPspDocumentShow()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPspDocumentShow$Response(params: {

    /**
     * PSP Id.
     */
    psp: number;
    document: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<GeneralApiResponse & {
'data'?: DocumentResponse;
}>> {

    const rb = new RequestBuilder(this.rootUrl, PspService.GetPspDocumentShowPath, 'get');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.path('document', params.document, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GeneralApiResponse & {
        'data'?: DocumentResponse;
        }>;
      })
    );
  }

  /**
   * Get document info.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_DOCUMENTS'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPspDocumentShow$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPspDocumentShow(params: {

    /**
     * PSP Id.
     */
    psp: number;
    document: string;
    context?: HttpContext
  }
): Observable<GeneralApiResponse & {
'data'?: DocumentResponse;
}> {

    return this.getPspDocumentShow$Response(params).pipe(
      map((r: StrictHttpResponse<GeneralApiResponse & {
'data'?: DocumentResponse;
}>) => r.body as GeneralApiResponse & {
'data'?: DocumentResponse;
})
    );
  }

  /**
   * Path part for operation putPspDocumentUpdate
   */
  static readonly PutPspDocumentUpdatePath = '/api/web/v1/psps/{psp}/documents/{document}';

  /**
   * Update data in a document.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_DOCUMENTS'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putPspDocumentUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putPspDocumentUpdate$Response(params: {

    /**
     * PSP Id.
     */
    psp: number;
    document: string;
    context?: HttpContext
    body: DocumentRequest
  }
): Observable<StrictHttpResponse<EmptySuccessfulResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PspService.PutPspDocumentUpdatePath, 'put');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.path('document', params.document, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmptySuccessfulResponse>;
      })
    );
  }

  /**
   * Update data in a document.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_DOCUMENTS'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putPspDocumentUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putPspDocumentUpdate(params: {

    /**
     * PSP Id.
     */
    psp: number;
    document: string;
    context?: HttpContext
    body: DocumentRequest
  }
): Observable<EmptySuccessfulResponse> {

    return this.putPspDocumentUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<EmptySuccessfulResponse>) => r.body as EmptySuccessfulResponse)
    );
  }

  /**
   * Path part for operation deletePspDocumentDelete
   */
  static readonly DeletePspDocumentDeletePath = '/api/web/v1/psps/{psp}/documents/{document}';

  /**
   * Delete a document.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_DOCUMENTS'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deletePspDocumentDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePspDocumentDelete$Response(params: {

    /**
     * PSP Id.
     */
    psp: number;
    document: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EmptySuccessfulResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PspService.DeletePspDocumentDeletePath, 'delete');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.path('document', params.document, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmptySuccessfulResponse>;
      })
    );
  }

  /**
   * Delete a document.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_DOCUMENTS'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deletePspDocumentDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePspDocumentDelete(params: {

    /**
     * PSP Id.
     */
    psp: number;
    document: string;
    context?: HttpContext
  }
): Observable<EmptySuccessfulResponse> {

    return this.deletePspDocumentDelete$Response(params).pipe(
      map((r: StrictHttpResponse<EmptySuccessfulResponse>) => r.body as EmptySuccessfulResponse)
    );
  }

  /**
   * Path part for operation getPspDocumentDownload
   */
  static readonly GetPspDocumentDownloadPath = '/api/web/v1/psps/{psp}/documents/{document}/file';

  /**
   * Download a file.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_DOCUMENTS'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPspDocumentDownload()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPspDocumentDownload$Response(params: {

    /**
     * PSP Id.
     */
    psp: number;
    document: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, PspService.GetPspDocumentDownloadPath, 'get');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.path('document', params.document, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'multipart/form-data',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Download a file.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_DOCUMENTS'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPspDocumentDownload$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPspDocumentDownload(params: {

    /**
     * PSP Id.
     */
    psp: number;
    document: string;
    context?: HttpContext
  }
): Observable<Blob> {

    return this.getPspDocumentDownload$Response(params).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Path part for operation postPspDocumentUpdateFile
   */
  static readonly PostPspDocumentUpdateFilePath = '/api/web/v1/psps/{psp}/documents/{document}/file';

  /**
   * Change a file for a document.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_DOCUMENTS'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postPspDocumentUpdateFile()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  postPspDocumentUpdateFile$Response(params: {

    /**
     * PSP Id.
     */
    psp: number;
    document: string;
    context?: HttpContext

    /**
     * Upload a document. There should be a variable with a name "file" which contains file
     */
    body: {
'file'?: Blob;
}
  }
): Observable<StrictHttpResponse<EmptySuccessfulResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PspService.PostPspDocumentUpdateFilePath, 'post');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.path('document', params.document, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmptySuccessfulResponse>;
      })
    );
  }

  /**
   * Change a file for a document.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_DOCUMENTS'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postPspDocumentUpdateFile$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  postPspDocumentUpdateFile(params: {

    /**
     * PSP Id.
     */
    psp: number;
    document: string;
    context?: HttpContext

    /**
     * Upload a document. There should be a variable with a name "file" which contains file
     */
    body: {
'file'?: Blob;
}
  }
): Observable<EmptySuccessfulResponse> {

    return this.postPspDocumentUpdateFile$Response(params).pipe(
      map((r: StrictHttpResponse<EmptySuccessfulResponse>) => r.body as EmptySuccessfulResponse)
    );
  }

  /**
   * Path part for operation getPspGroupsList
   */
  static readonly GetPspGroupsListPath = '/api/web/v1/psps/{psp}/groups';

  /**
   * Get PSP groups.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_USERS'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPspGroupsList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPspGroupsList$Response(params: {

    /**
     * PSP Id.
     */
    psp: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ListApiResponse & {
'data'?: Array<GroupListItemResponse>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, PspService.GetPspGroupsListPath, 'get');
    if (params) {
      rb.path('psp', params.psp, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListApiResponse & {
        'data'?: Array<GroupListItemResponse>;
        }>;
      })
    );
  }

  /**
   * Get PSP groups.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_USERS'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPspGroupsList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPspGroupsList(params: {

    /**
     * PSP Id.
     */
    psp: number;
    context?: HttpContext
  }
): Observable<ListApiResponse & {
'data'?: Array<GroupListItemResponse>;
}> {

    return this.getPspGroupsList$Response(params).pipe(
      map((r: StrictHttpResponse<ListApiResponse & {
'data'?: Array<GroupListItemResponse>;
}>) => r.body as ListApiResponse & {
'data'?: Array<GroupListItemResponse>;
})
    );
  }

  /**
   * Path part for operation postPspGroupCreate
   */
  static readonly PostPspGroupCreatePath = '/api/web/v1/psps/{psp}/groups';

  /**
   * Create new PSP group.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_USERS'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postPspGroupCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPspGroupCreate$Response(params: {

    /**
     * PSP Id.
     */
    psp: number;
    context?: HttpContext
    body: GroupCreateRequest
  }
): Observable<StrictHttpResponse<CreatedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PspService.PostPspGroupCreatePath, 'post');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CreatedResponse>;
      })
    );
  }

  /**
   * Create new PSP group.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_USERS'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postPspGroupCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPspGroupCreate(params: {

    /**
     * PSP Id.
     */
    psp: number;
    context?: HttpContext
    body: GroupCreateRequest
  }
): Observable<CreatedResponse> {

    return this.postPspGroupCreate$Response(params).pipe(
      map((r: StrictHttpResponse<CreatedResponse>) => r.body as CreatedResponse)
    );
  }

  /**
   * Path part for operation getPspGroupsShow
   */
  static readonly GetPspGroupsShowPath = '/api/web/v1/psps/{psp}/groups/{group}';

  /**
   * Get PSP group data.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_USERS'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPspGroupsShow()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPspGroupsShow$Response(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * Group Id.
     */
    group: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<GeneralApiResponse & {
'data'?: GroupItemResponse;
}>> {

    const rb = new RequestBuilder(this.rootUrl, PspService.GetPspGroupsShowPath, 'get');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.path('group', params.group, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GeneralApiResponse & {
        'data'?: GroupItemResponse;
        }>;
      })
    );
  }

  /**
   * Get PSP group data.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_USERS'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPspGroupsShow$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPspGroupsShow(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * Group Id.
     */
    group: number;
    context?: HttpContext
  }
): Observable<GeneralApiResponse & {
'data'?: GroupItemResponse;
}> {

    return this.getPspGroupsShow$Response(params).pipe(
      map((r: StrictHttpResponse<GeneralApiResponse & {
'data'?: GroupItemResponse;
}>) => r.body as GeneralApiResponse & {
'data'?: GroupItemResponse;
})
    );
  }

  /**
   * Path part for operation putPspGroupsUpdate
   */
  static readonly PutPspGroupsUpdatePath = '/api/web/v1/psps/{psp}/groups/{group}';

  /**
   * Update PSP group data.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_USERS'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putPspGroupsUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putPspGroupsUpdate$Response(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * Group Id.
     */
    group: number;
    context?: HttpContext
    body: GroupUpdateRequest
  }
): Observable<StrictHttpResponse<EmptySuccessfulResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PspService.PutPspGroupsUpdatePath, 'put');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.path('group', params.group, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmptySuccessfulResponse>;
      })
    );
  }

  /**
   * Update PSP group data.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_USERS'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putPspGroupsUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putPspGroupsUpdate(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * Group Id.
     */
    group: number;
    context?: HttpContext
    body: GroupUpdateRequest
  }
): Observable<EmptySuccessfulResponse> {

    return this.putPspGroupsUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<EmptySuccessfulResponse>) => r.body as EmptySuccessfulResponse)
    );
  }

  /**
   * Path part for operation deletePspGroupsDelete
   */
  static readonly DeletePspGroupsDeletePath = '/api/web/v1/psps/{psp}/groups/{group}';

  /**
   * Delete PSP group.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_USERS'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deletePspGroupsDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePspGroupsDelete$Response(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * Group Id.
     */
    group: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EmptySuccessfulResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PspService.DeletePspGroupsDeletePath, 'delete');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.path('group', params.group, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmptySuccessfulResponse>;
      })
    );
  }

  /**
   * Delete PSP group.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_USERS'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deletePspGroupsDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePspGroupsDelete(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * Group Id.
     */
    group: number;
    context?: HttpContext
  }
): Observable<EmptySuccessfulResponse> {

    return this.deletePspGroupsDelete$Response(params).pipe(
      map((r: StrictHttpResponse<EmptySuccessfulResponse>) => r.body as EmptySuccessfulResponse)
    );
  }

  /**
   * Path part for operation putPspLogoUpdate
   */
  static readonly PutPspLogoUpdatePath = '/api/web/v1/psps/{psp}/logo';

  /**
   * Update PSP Logo.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_MASTER_DATA'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putPspLogoUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putPspLogoUpdate$Response(params: {

    /**
     * PSP Id.
     */
    psp: number;
    context?: HttpContext
    body: LogoUpdateRequest
  }
): Observable<StrictHttpResponse<EmptySuccessfulResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PspService.PutPspLogoUpdatePath, 'put');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmptySuccessfulResponse>;
      })
    );
  }

  /**
   * Update PSP Logo.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_MASTER_DATA'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putPspLogoUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putPspLogoUpdate(params: {

    /**
     * PSP Id.
     */
    psp: number;
    context?: HttpContext
    body: LogoUpdateRequest
  }
): Observable<EmptySuccessfulResponse> {

    return this.putPspLogoUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<EmptySuccessfulResponse>) => r.body as EmptySuccessfulResponse)
    );
  }

  /**
   * Path part for operation getAppApiWebV1PspPriceListIndex
   */
  static readonly GetAppApiWebV1PspPriceListIndexPath = '/api/web/v1/psps/{psp}/prices';

  /**
   * Get PSP prices.
   *
   * **Accessible only for users with roles 'ROLE_PSP_USER', 'ROLE_CUSTOMER_USER'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAppApiWebV1PspPriceListIndex()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAppApiWebV1PspPriceListIndex$Response(params: {
    psp: number;

    /**
     * Qualification category id
     */
    category?: number;

    /**
     * Filter by tag qualification.
     */
    tag?: 'business' | 'medical';
    context?: HttpContext
  }
): Observable<StrictHttpResponse<GeneralApiResponse & {
'data'?: QualificationPricingListResponse;
}>> {

    const rb = new RequestBuilder(this.rootUrl, PspService.GetAppApiWebV1PspPriceListIndexPath, 'get');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.query('category', params.category, {});
      rb.query('tag', params.tag, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GeneralApiResponse & {
        'data'?: QualificationPricingListResponse;
        }>;
      })
    );
  }

  /**
   * Get PSP prices.
   *
   * **Accessible only for users with roles 'ROLE_PSP_USER', 'ROLE_CUSTOMER_USER'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAppApiWebV1PspPriceListIndex$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAppApiWebV1PspPriceListIndex(params: {
    psp: number;

    /**
     * Qualification category id
     */
    category?: number;

    /**
     * Filter by tag qualification.
     */
    tag?: 'business' | 'medical';
    context?: HttpContext
  }
): Observable<GeneralApiResponse & {
'data'?: QualificationPricingListResponse;
}> {

    return this.getAppApiWebV1PspPriceListIndex$Response(params).pipe(
      map((r: StrictHttpResponse<GeneralApiResponse & {
'data'?: QualificationPricingListResponse;
}>) => r.body as GeneralApiResponse & {
'data'?: QualificationPricingListResponse;
})
    );
  }

  /**
   * Path part for operation postAppApiWebV1PspPriceUpdateIndex
   */
  static readonly PostAppApiWebV1PspPriceUpdateIndexPath = '/api/web/v1/psps/{psp}/prices';

  /**
   * Create or update existent PSP prices.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_PRICE_LIST'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postAppApiWebV1PspPriceUpdateIndex()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postAppApiWebV1PspPriceUpdateIndex$Response(params: {
    psp: number;
    context?: HttpContext
    body: PriceListRequest
  }
): Observable<StrictHttpResponse<EmptySuccessfulResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PspService.PostAppApiWebV1PspPriceUpdateIndexPath, 'post');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmptySuccessfulResponse>;
      })
    );
  }

  /**
   * Create or update existent PSP prices.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_PRICE_LIST'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postAppApiWebV1PspPriceUpdateIndex$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postAppApiWebV1PspPriceUpdateIndex(params: {
    psp: number;
    context?: HttpContext
    body: PriceListRequest
  }
): Observable<EmptySuccessfulResponse> {

    return this.postAppApiWebV1PspPriceUpdateIndex$Response(params).pipe(
      map((r: StrictHttpResponse<EmptySuccessfulResponse>) => r.body as EmptySuccessfulResponse)
    );
  }

  /**
   * Path part for operation getPspShow
   */
  static readonly GetPspShowPath = '/api/web/v1/psps/{psp}';

  /**
   * Get PSP data.
   *
   * **Accessible only for users with roles 'ROLE_PSP_USER', 'ROLE_CUSTOMER_USER'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPspShow()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPspShow$Response(params: {

    /**
     * PSP Id.
     */
    psp: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<GeneralApiResponse & {
'data'?: PspResponse;
}>> {

    const rb = new RequestBuilder(this.rootUrl, PspService.GetPspShowPath, 'get');
    if (params) {
      rb.path('psp', params.psp, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GeneralApiResponse & {
        'data'?: PspResponse;
        }>;
      })
    );
  }

  /**
   * Get PSP data.
   *
   * **Accessible only for users with roles 'ROLE_PSP_USER', 'ROLE_CUSTOMER_USER'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPspShow$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPspShow(params: {

    /**
     * PSP Id.
     */
    psp: number;
    context?: HttpContext
  }
): Observable<GeneralApiResponse & {
'data'?: PspResponse;
}> {

    return this.getPspShow$Response(params).pipe(
      map((r: StrictHttpResponse<GeneralApiResponse & {
'data'?: PspResponse;
}>) => r.body as GeneralApiResponse & {
'data'?: PspResponse;
})
    );
  }

  /**
   * Path part for operation patchPspUpdate
   */
  static readonly PatchPspUpdatePath = '/api/web/v1/psps/{psp}';

  /**
   * Update PSP data.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_MASTER_DATA'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchPspUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchPspUpdate$Response(params: {

    /**
     * PSP Id.
     */
    psp: number;
    context?: HttpContext
    body: (PspMainInfoUpdateRequest | PspAdditionalInfoUpdateRequest)
  }
): Observable<StrictHttpResponse<EmptySuccessfulResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PspService.PatchPspUpdatePath, 'patch');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmptySuccessfulResponse>;
      })
    );
  }

  /**
   * Update PSP data.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_MASTER_DATA'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchPspUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchPspUpdate(params: {

    /**
     * PSP Id.
     */
    psp: number;
    context?: HttpContext
    body: (PspMainInfoUpdateRequest | PspAdditionalInfoUpdateRequest)
  }
): Observable<EmptySuccessfulResponse> {

    return this.patchPspUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<EmptySuccessfulResponse>) => r.body as EmptySuccessfulResponse)
    );
  }

  /**
   * Path part for operation postPspsUsersActivate
   */
  static readonly PostPspsUsersActivatePath = '/api/web/v1/psps/{psp}/users/{user}/activation';

  /**
   * Activate User in specified PSP organization.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_USERS'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postPspsUsersActivate()` instead.
   *
   * This method doesn't expect any request body.
   */
  postPspsUsersActivate$Response(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * User Id.
     */
    user: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EmptySuccessfulResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PspService.PostPspsUsersActivatePath, 'post');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.path('user', params.user, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmptySuccessfulResponse>;
      })
    );
  }

  /**
   * Activate User in specified PSP organization.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_USERS'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postPspsUsersActivate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  postPspsUsersActivate(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * User Id.
     */
    user: number;
    context?: HttpContext
  }
): Observable<EmptySuccessfulResponse> {

    return this.postPspsUsersActivate$Response(params).pipe(
      map((r: StrictHttpResponse<EmptySuccessfulResponse>) => r.body as EmptySuccessfulResponse)
    );
  }

  /**
   * Path part for operation deletePspsUsersDeactivate
   */
  static readonly DeletePspsUsersDeactivatePath = '/api/web/v1/psps/{psp}/users/{user}/activation';

  /**
   * Deactivate User in specified PSP organization.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_USERS'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deletePspsUsersDeactivate()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePspsUsersDeactivate$Response(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * User Id.
     */
    user: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EmptySuccessfulResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PspService.DeletePspsUsersDeactivatePath, 'delete');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.path('user', params.user, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmptySuccessfulResponse>;
      })
    );
  }

  /**
   * Deactivate User in specified PSP organization.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_USERS'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deletePspsUsersDeactivate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePspsUsersDeactivate(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * User Id.
     */
    user: number;
    context?: HttpContext
  }
): Observable<EmptySuccessfulResponse> {

    return this.deletePspsUsersDeactivate$Response(params).pipe(
      map((r: StrictHttpResponse<EmptySuccessfulResponse>) => r.body as EmptySuccessfulResponse)
    );
  }

  /**
   * Path part for operation postPspsUsersInviteCreate
   */
  static readonly PostPspsUsersInviteCreatePath = '/api/web/v1/psps/{psp}/users/invites';

  /**
   * Invite user to PSP organization.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_USERS'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postPspsUsersInviteCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPspsUsersInviteCreate$Response(params: {

    /**
     * PSP Id.
     */
    psp: number;
    context?: HttpContext
    body: PspInviteRequest
  }
): Observable<StrictHttpResponse<CreatedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PspService.PostPspsUsersInviteCreatePath, 'post');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CreatedResponse>;
      })
    );
  }

  /**
   * Invite user to PSP organization.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_USERS'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postPspsUsersInviteCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPspsUsersInviteCreate(params: {

    /**
     * PSP Id.
     */
    psp: number;
    context?: HttpContext
    body: PspInviteRequest
  }
): Observable<CreatedResponse> {

    return this.postPspsUsersInviteCreate$Response(params).pipe(
      map((r: StrictHttpResponse<CreatedResponse>) => r.body as CreatedResponse)
    );
  }

  /**
   * Path part for operation postPspsUsersReinvitation
   */
  static readonly PostPspsUsersReinvitationPath = '/api/web/v1/psps/{psp}/users/{user}/reinvitation';

  /**
   * Invite user to PSP organization.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_USERS'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postPspsUsersReinvitation()` instead.
   *
   * This method doesn't expect any request body.
   */
  postPspsUsersReinvitation$Response(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * User Id.
     */
    user: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<CreatedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PspService.PostPspsUsersReinvitationPath, 'post');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.path('user', params.user, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CreatedResponse>;
      })
    );
  }

  /**
   * Invite user to PSP organization.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_USERS'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postPspsUsersReinvitation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  postPspsUsersReinvitation(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * User Id.
     */
    user: number;
    context?: HttpContext
  }
): Observable<CreatedResponse> {

    return this.postPspsUsersReinvitation$Response(params).pipe(
      map((r: StrictHttpResponse<CreatedResponse>) => r.body as CreatedResponse)
    );
  }

  /**
   * Path part for operation deletePspsUserInviteDelete
   */
  static readonly DeletePspsUserInviteDeletePath = '/api/web/v1/psps/{psp}/users/{user}/invites';

  /**
   * Remove only users with open invitation.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_USERS'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deletePspsUserInviteDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePspsUserInviteDelete$Response(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * User Id.
     */
    user: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EmptySuccessfulResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PspService.DeletePspsUserInviteDeletePath, 'delete');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.path('user', params.user, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmptySuccessfulResponse>;
      })
    );
  }

  /**
   * Remove only users with open invitation.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_USERS'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deletePspsUserInviteDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePspsUserInviteDelete(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * User Id.
     */
    user: number;
    context?: HttpContext
  }
): Observable<EmptySuccessfulResponse> {

    return this.deletePspsUserInviteDelete$Response(params).pipe(
      map((r: StrictHttpResponse<EmptySuccessfulResponse>) => r.body as EmptySuccessfulResponse)
    );
  }

  /**
   * Path part for operation getPspsUsersList
   */
  static readonly GetPspsUsersListPath = '/api/web/v1/psps/{psp}/users';

  /**
   * List users for specified PSP.
   *
   * **Accessible only for users with permissions 'FEATURE_ADMIN_USERS',
   * 'FEATURE_ADMIN_BRANCHES', 'FEATURE_ADMIN_PROFILES'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPspsUsersList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPspsUsersList$Response(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * User roles to filter them by. Possible values: user, admin
     */
    'role[]'?: any;

    /**
     * Filter active or inactive users. Possible values: true, false
     */
    active?: any;

    /**
     * Search user by first-last name and email.
     */
    search?: any;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ListApiResponse & {
'data'?: Array<UserItemResponse>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, PspService.GetPspsUsersListPath, 'get');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.query('role[]', params['role[]'], {"style":"form"});
      rb.query('active', params.active, {"style":"form"});
      rb.query('search', params.search, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListApiResponse & {
        'data'?: Array<UserItemResponse>;
        }>;
      })
    );
  }

  /**
   * List users for specified PSP.
   *
   * **Accessible only for users with permissions 'FEATURE_ADMIN_USERS',
   * 'FEATURE_ADMIN_BRANCHES', 'FEATURE_ADMIN_PROFILES'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPspsUsersList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPspsUsersList(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * User roles to filter them by. Possible values: user, admin
     */
    'role[]'?: any;

    /**
     * Filter active or inactive users. Possible values: true, false
     */
    active?: any;

    /**
     * Search user by first-last name and email.
     */
    search?: any;
    context?: HttpContext
  }
): Observable<ListApiResponse & {
'data'?: Array<UserItemResponse>;
}> {

    return this.getPspsUsersList$Response(params).pipe(
      map((r: StrictHttpResponse<ListApiResponse & {
'data'?: Array<UserItemResponse>;
}>) => r.body as ListApiResponse & {
'data'?: Array<UserItemResponse>;
})
    );
  }

  /**
   * Path part for operation putPspsUsersPasswordUpdate
   */
  static readonly PutPspsUsersPasswordUpdatePath = '/api/web/v1/psps/{psp}/users/{user}/password';

  /**
   * Update password of selected user in specified PSP organization.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_USERS'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putPspsUsersPasswordUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putPspsUsersPasswordUpdate$Response(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * User Id.
     */
    user: number;
    context?: HttpContext
    body: UserPasswordUpdateRequest
  }
): Observable<StrictHttpResponse<UpdatedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PspService.PutPspsUsersPasswordUpdatePath, 'put');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.path('user', params.user, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UpdatedResponse>;
      })
    );
  }

  /**
   * Update password of selected user in specified PSP organization.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_USERS'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putPspsUsersPasswordUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putPspsUsersPasswordUpdate(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * User Id.
     */
    user: number;
    context?: HttpContext
    body: UserPasswordUpdateRequest
  }
): Observable<UpdatedResponse> {

    return this.putPspsUsersPasswordUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<UpdatedResponse>) => r.body as UpdatedResponse)
    );
  }

  /**
   * Path part for operation getPspsUsersShow
   */
  static readonly GetPspsUsersShowPath = '/api/web/v1/psps/{psp}/users/{user}';

  /**
   * Get PSP user data.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_USERS'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPspsUsersShow()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPspsUsersShow$Response(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * User Id.
     */
    user: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<GeneralApiResponse & {
'data'?: UserResponse;
}>> {

    const rb = new RequestBuilder(this.rootUrl, PspService.GetPspsUsersShowPath, 'get');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.path('user', params.user, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GeneralApiResponse & {
        'data'?: UserResponse;
        }>;
      })
    );
  }

  /**
   * Get PSP user data.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_USERS'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPspsUsersShow$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPspsUsersShow(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * User Id.
     */
    user: number;
    context?: HttpContext
  }
): Observable<GeneralApiResponse & {
'data'?: UserResponse;
}> {

    return this.getPspsUsersShow$Response(params).pipe(
      map((r: StrictHttpResponse<GeneralApiResponse & {
'data'?: UserResponse;
}>) => r.body as GeneralApiResponse & {
'data'?: UserResponse;
})
    );
  }

  /**
   * Path part for operation putPspsUsersUpdate
   */
  static readonly PutPspsUsersUpdatePath = '/api/web/v1/psps/{psp}/users/{user}';

  /**
   * Update data of selected user in specified PSP organization.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_USERS'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putPspsUsersUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putPspsUsersUpdate$Response(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * User Id.
     */
    user: number;
    context?: HttpContext
    body: UserUpdateRequest
  }
): Observable<StrictHttpResponse<CreatedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PspService.PutPspsUsersUpdatePath, 'put');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.path('user', params.user, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CreatedResponse>;
      })
    );
  }

  /**
   * Update data of selected user in specified PSP organization.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_USERS'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putPspsUsersUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putPspsUsersUpdate(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * User Id.
     */
    user: number;
    context?: HttpContext
    body: UserUpdateRequest
  }
): Observable<CreatedResponse> {

    return this.putPspsUsersUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<CreatedResponse>) => r.body as CreatedResponse)
    );
  }

  /**
   * Path part for operation postPspVerification
   */
  static readonly PostPspVerificationPath = '/api/web/v1/psps/{psp}/verification';

  /**
   * Verify PSP registration.
   *
   * **Accessible only for users with roles 'ROLE_PSP_USER', 'ROLE_CUSTOMER_USER'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postPspVerification()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPspVerification$Response(params: {

    /**
     * PSP Id.
     */
    psp: number;
    context?: HttpContext
    body: PspVerificationRequest
  }
): Observable<StrictHttpResponse<EmptySuccessfulResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PspService.PostPspVerificationPath, 'post');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmptySuccessfulResponse>;
      })
    );
  }

  /**
   * Verify PSP registration.
   *
   * **Accessible only for users with roles 'ROLE_PSP_USER', 'ROLE_CUSTOMER_USER'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postPspVerification$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPspVerification(params: {

    /**
     * PSP Id.
     */
    psp: number;
    context?: HttpContext
    body: PspVerificationRequest
  }
): Observable<EmptySuccessfulResponse> {

    return this.postPspVerification$Response(params).pipe(
      map((r: StrictHttpResponse<EmptySuccessfulResponse>) => r.body as EmptySuccessfulResponse)
    );
  }

}
