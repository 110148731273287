import produce from 'immer';

import { FiltersResponse, MetaPagination, SectionResponse } from '../../../api';
import { baseOffersAndBookingsActions } from '../actions';

export interface OffersAndBookingsState {
  offerOverlapsList: {
    data: SectionResponse[] | undefined;
    pagination: MetaPagination | undefined;
    filters: FiltersResponse | undefined;
    ok: boolean | undefined;
  };
}

export const OFFERS_AND_BOOKINGS_INITIAL_STATE: OffersAndBookingsState = {
  offerOverlapsList: {
    data: undefined,
    pagination: undefined,
    filters: undefined,
    ok: undefined,
  },
};

export function BaseOffersAndBookingsReducer(
  state: OffersAndBookingsState = OFFERS_AND_BOOKINGS_INITIAL_STATE,
  action: any
): any {
  return produce(state, (draftState) => {
    switch (action.type) {
      // Offer overlaps list
      case baseOffersAndBookingsActions.loadOfferOverlapsList.type:
        draftState.offerOverlapsList.ok = undefined;
        return;

      case baseOffersAndBookingsActions.setOfferOverlapsList.type:
        draftState.offerOverlapsList.data = action.payload.data;
        draftState.offerOverlapsList.filters = action.payload.filters;
        draftState.offerOverlapsList.pagination = action.payload.meta;
        return;

      case baseOffersAndBookingsActions.successOfferOverlapsList.type:
        draftState.offerOverlapsList.ok = true;
        return;

      case baseOffersAndBookingsActions.errorOfferOverlapsList.type:
        draftState.offerOverlapsList.ok = false;
        return;
    }
  });
}
