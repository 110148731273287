import { Injectable } from '@angular/core';
import { InquiryDateResponse, OfferPositionResponse, ShiftType } from '../../../../api';
import {
  BaseInquiryBubble,
  BaseInquiryBubbleListItem,
  BaseInquiryDate,
  InquiryDateMatchedData,
} from '../_models/base-inquiry-date';
import {
  BaseInquiryShiftsBooked,
  BaseInquiryShiftsUpperCase,
  BaseInquiryShiftsWithFlex,
  BaseInquiryShiftsWithFlexType,
  ShiftTimeAndFlexResponse,
} from '../_enums/base-inquiry.enum';
import { BaseInquiryBubbleColors } from '../_enums/base-inquiry-bubble-colors.enum';
import { BaseInquiryBubbleValues } from '../_enums/base-inquiry-bubble-values.enum';
import { BaseDateService } from '../../../shared/services/base-date.service';

const BASE_SHIFTS = Object.values(BaseInquiryShiftsUpperCase);

@Injectable()
export class BaseInquiryCalendarBubbleListService {
  inquiryDates: InquiryDateResponse[] | undefined = [];

  prepareBubbleList(
    datesArray: BaseInquiryDate[],
    shiftTimesWithFlex: (ShiftTimeAndFlexResponse & { index: number; label?: string })[]
  ): BaseInquiryBubbleListItem[] {
    let bubbleList: BaseInquiryBubbleListItem[] = [];
    if (!datesArray?.length || !shiftTimesWithFlex?.length) {
      return [];
    }

    let currentOfferPosition: OfferPositionResponse | undefined = undefined;

    datesArray.forEach((dateItem: BaseInquiryDate) => {
      let bubbles: BaseInquiryBubble[] = [];

      const currentInquiryDate: InquiryDateResponse | undefined = this.inquiryDates?.find(
        (item) => item.date === dateItem.dateString
      );

      let shiftIndex = 0;
      shiftTimesWithFlex.forEach((shiftTimeWithFlex: ShiftTimeAndFlexResponse & { index: number; label?: string }) => {
        let inquiryMatchedData = this.handleInquiryDates(shiftTimeWithFlex.type as ShiftType, currentInquiryDate);

        let bubble: BaseInquiryBubble = {
          shiftTimeWithFlex: shiftTimeWithFlex,
          shift: shiftTimeWithFlex.type as ShiftType,
          color: inquiryMatchedData.color,
          classes: '',
          bookedCustomersValue: inquiryMatchedData.bookedCustomersValue,
          currentValue: inquiryMatchedData.currentValue,
          currentInquiryDate: currentInquiryDate,
          showDot: inquiryMatchedData.showDot,
          isEmptyFlexBubble: false,
          index: shiftIndex,
          content: '',
        };

        dateItem.isToday = BaseDateService.isToday(new Date(dateItem.dateString));
        bubble = this.renderBubble(bubbles, bubble, dateItem.dateString);

        bubbles.push(bubble);

        shiftIndex++;
      });

      const bubbleListItem: BaseInquiryBubbleListItem = {
        dateItem: dateItem,
        bubbles: bubbles,
        currentOfferPosition: currentOfferPosition,
      };
      bubbleList.push(bubbleListItem);
    });

    return bubbleList;
  }

  protected prepareBubbleClasses(bubble: BaseInquiryBubble): string {
    let classes: string[] = bubble.color ? [bubble.color] : [];
    if (bubble.currentValue && !bubble.showDot) {
      classes.push('bubble');
    } else {
      classes = classes.filter((classString) => classString !== 'bubble');
    }

    if (!bubble.currentValue) {
      classes.push('empty-bubble');
    } else {
      classes = classes.filter((classString) => classString !== 'empty-bubble');
    }

    if (bubble.showDot) {
      classes.push('bubble-dot');
    } else {
      classes = classes.filter((classString) => classString !== 'bubble-dot');
    }

    if (bubble.showDot && bubble.currentInquiryDate?.flexShift && bubble.isEmptyFlexBubble) {
      classes.push('bubble-circle');
    } else {
      classes = classes.filter((classString) => classString !== 'bubble-circle');
    }

    classes = classes.filter((classString, index, self) => {
      return index === self.indexOf(classString);
    });

    return classes.join(' ');
  }

  protected handleInquiryDates(
    shift: BaseInquiryShiftsWithFlexType,
    currentInquiryDate: InquiryDateResponse | undefined
  ): InquiryDateMatchedData {
    let currentValue: BaseInquiryBubbleValues | number | string = '';
    let showDot = false;
    let bookedCustomersValue = -1;
    let color: BaseInquiryBubbleColors | string = 'dark-blue';

    if (currentInquiryDate) {
      if (currentInquiryDate.flexShift) {
        if (shift === BaseInquiryShiftsWithFlex.FLEX) {
          const definedShift = BASE_SHIFTS.find(
            (item) =>
              currentInquiryDate &&
              typeof currentInquiryDate[item] === 'number' &&
              (currentInquiryDate[item] as number) > 0
          );
          currentValue = definedShift ? currentInquiryDate[definedShift] || '' : '';
          bookedCustomersValue = currentInquiryDate?.shiftFlexBooked as number;
        } else {
          currentValue = currentInquiryDate[BaseInquiryShiftsUpperCase[shift]] ? -1 : '';
        }
      } else {
        if (shift !== BaseInquiryShiftsWithFlex.FLEX) {
          currentValue = currentInquiryDate[BaseInquiryShiftsUpperCase[shift]] || '';
          bookedCustomersValue = currentInquiryDate[BaseInquiryShiftsBooked[shift]] as number;
        }
      }
    } else {
      currentValue = '';
    }

    if (bookedCustomersValue >= 0) {
      color = bookedCustomersValue >= currentValue ? 'dark-blue' : 'bg-white';
    }
    showDot = currentValue === -1;

    return {
      color: color,
      showDot: showDot,
      currentValue: currentValue,
      bookedCustomersValue: bookedCustomersValue,
    };
  }

  protected setIsEmptyFlexBubble(bubble: BaseInquiryBubble): boolean {
    if (bubble.shift === BaseInquiryShiftsWithFlex.FLEX || !bubble.currentInquiryDate) {
      return false;
    }

    const currentShiftValue: number | undefined = bubble.currentInquiryDate[BaseInquiryShiftsUpperCase[bubble.shift]];

    if (currentShiftValue && bubble.currentInquiryDate?.shiftFlexBooked !== undefined) {
      return bubble.currentInquiryDate?.shiftFlexBooked < currentShiftValue;
    } else {
      return false;
    }
  }

  protected renderBubble(bubbles: BaseInquiryBubble[], bubble: BaseInquiryBubble, _date?: string): BaseInquiryBubble {
    return this.renderInquiryOfferBubble(bubbles, bubble);
  }

  protected renderInquiryOfferBubble(bubbles: BaseInquiryBubble[], bubble: BaseInquiryBubble) {
    bubble.isEmptyFlexBubble = this.setIsEmptyFlexBubble(bubble);
    bubble.classes = this.prepareBubbleClasses(bubble);
    bubble.content = this.prepareDefaultBubbleContent(bubble);

    return bubble;
  }

  private prepareDefaultBubbleContent(bubble: BaseInquiryBubble): string {
    if (bubble.currentValue === -1 || bubble.showDot) {
      return '';
    } else {
      return bubble.currentValue.toString();
    }
  }
}
