import { ElementRef, Renderer2 } from '@angular/core';

export abstract class AbstractFormComponent {
  public element: ElementRef;
  public isInvalid = false;

  constructor(protected renderer: Renderer2) {}

  protected setNgClass(): void {
    if (!this.element) {
      return;
    }
    if (this.isInvalid) {
      this.renderer.addClass(this.element.nativeElement, 'ng-invalid');
      this.renderer.removeClass(this.element.nativeElement, 'ng-valid');
    } else {
      this.renderer.addClass(this.element.nativeElement, 'ng-valid');
      this.renderer.removeClass(this.element.nativeElement, 'ng-invalid');
    }
  }
}
