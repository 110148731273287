import { ElementRef, Injectable, TemplateRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

Injectable();
export class BaseAppWrapperLayoutService {
  private showDefaultHeader = new BehaviorSubject(true);
  private showDefaultSidebar = new BehaviorSubject(true);
  private showCustomHeader = new BehaviorSubject(false);
  private customHeaderTemplate = new BehaviorSubject<TemplateRef<any> | undefined>(undefined);
  showDefaultHeader$ = this.showDefaultHeader.asObservable();
  showDefaultSidebar$ = this.showDefaultSidebar.asObservable();
  showCustomHeader$ = this.showCustomHeader.asObservable();
  customHeaderTemplate$ = this.customHeaderTemplate.asObservable();

  isDefaultHeader(value = true): void {
    this.showDefaultHeader.next(value);
    this.showCustomHeader.next(!value);
    this.customHeaderTemplate.next(undefined);
  }

  isDefaultSidebar(value = true): void {
    this.showDefaultSidebar.next(value);
  }

  isCustomHeaderComponent(template: TemplateRef<any> | undefined, value = false): void {
    this.showDefaultHeader.next(!value);
    this.showCustomHeader.next(value);
    this.customHeaderTemplate.next(template);
  }

  restoreDefaultValues(): void {
    this.showDefaultHeader.next(true);
    this.showDefaultSidebar.next(true);
    this.showCustomHeader.next(false);
    this.customHeaderTemplate.next(undefined);
  }
}
