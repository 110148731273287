import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';
import { BaseTokenInterface } from '../../shared/interfaces/base-token-service.interface';
import { BaseDateService } from '../../shared/services/base-date.service';
import { BaseStoreService } from './base-store.service';
import { AuthenticationSuccessfulResponse } from '../../../api';

export enum BaseTokenKeys {
  token = 'token',
  refreshToken = 'refresh-token',
  refreshTokenExp = 'refresh-token-exp',
}

@Injectable()
export class BaseTokenService {
  constructor(private readonly storeService: BaseStoreService) {}

  setTokens(tokens: AuthenticationSuccessfulResponse): void {
    this.storeService.set<string>(BaseTokenKeys.token, tokens.token);
    this.storeService.set<string>(BaseTokenKeys.refreshToken, tokens.refresh_token);
    this.storeService.set<string>(BaseTokenKeys.refreshTokenExp, tokens.refresh_token_expiration);
  }

  getToken(): string {
    return this.storeService.get<string>(BaseTokenKeys.token);
  }

  getRefreshToken(): string {
    return this.storeService.get<string>(BaseTokenKeys.refreshToken);
  }

  decodeToken(token: string): BaseTokenInterface {
    return jwt_decode(token);
  }

  deleteToken(): void {
    this.storeService.delete(BaseTokenKeys.token);
    this.storeService.delete(BaseTokenKeys.refreshToken);
    this.storeService.delete(BaseTokenKeys.refreshTokenExp);
  }

  isValid(token: string): boolean {
    const expireDate = this.decodeToken(token)?.exp;
    return expireDate ? BaseDateService.isBefore(new Date(), new Date(expireDate * 1000)) : false;
  }

  isRefreshTokenValid(): boolean {
    const expireDate = this.storeService.get<string>(BaseTokenKeys.refreshTokenExp);
    return BaseDateService.isBefore(new Date(), new Date(+expireDate * 1000));
  }
}
