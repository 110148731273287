<div
  *ngIf="!isPreview"
  class="textarea"
>
  <label
    *ngIf="label"
    [ngClass]="{ required: isRequired }"
    >{{ label | translate }}</label
  >
  <textarea
    class="form-control"
    [tabindex]="tabindex"
    name="name"
    #textarea
    [disabled]="isDisabled"
    [placeholder]="placeholder | translate"
    [(ngModel)]="value"
    [rows]="rows"
  ></textarea>
  <p
    class="invalid-feedback"
    *ngIf="isInvalid && errorMessage"
  >
    {{ errorMessage | translate }}
  </p>
</div>
<base-form-field-preview
  *ngIf="isPreview"
  [label]="label"
  [value]="value"
  [previewClass]="previewClass"
  [isMultiline]="true"
></base-form-field-preview>
