<form
  [formGroup]="form"
  [ngClass]="{ 'was-validated': validated }"
  class="add-absence"
>
  <div class="d-flex flex-column gap-5">
    <div class="table-item">
      <div class="table-item-title">
        <label class="required">
          {{ 'work.absences.type' | translate }}
        </label>
      </div>
      <div class="table-item-body">
        <div class="d-flex flex-column gap-4">
          <div
            class="d-flex gap-2 align-items-center"
            *ngFor="let type of types"
          >
            <base-radiobutton
              [value]="type.value"
              formControlName="type"
            ></base-radiobutton>
            <div class="icon">
              <i
                class="text-white"
                [ngClass]="type.icon"
              ></i>
            </div>
            <span>{{ type.label | translate }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="table-item">
      <div class="table-item-title">
        <label class="required">
          {{ 'inquiry.period' | translate }}
        </label>
      </div>
      <div class="table-item-body">
        <base-range-datepicker
          formControlName="range"
          tabindex="1"
          [isInvalid]="!!form.controls.range.errors"
          [errorText]="form.controls.range.errors | baseFieldValidation"
          [isRequired]="true"
        ></base-range-datepicker>
      </div>
    </div>
    <div class="table-item">
      <div class="table-item-title">
        <label>{{ 'remark' | translate }}</label>
      </div>
      <div class="table-item-body">
        <base-textarea
          [rows]="3"
          formControlName="note"
          tabindex="2"
        ></base-textarea>
      </div>
    </div>
    <div class="d-flex justify-content-between pb-5">
      <base-button
        label="action.cancel"
        color="light"
        (btnClick)="closeModal()"
      ></base-button>
      <base-button
        [label]="absenceToEdit ? 'action.save' : 'action.add'"
        color="primary"
        (btnClick)="absenceToEdit ? updateAbsence() : loadAbsenceConflicts()"
      ></base-button>
    </div>
  </div>
</form>

<ng-template #absenceConflictsModal>
  <p class="fw-bold">{{ 'work.absences.conflictsModal.presentConflicts' | translate }}</p>
  <div class="d-flex flex-column gap-2">
    <span
      class="conflict-date border-danger border"
      *ngFor="let conflict of conflicts"
    >
      {{ conflict.date }}
    </span>
  </div>
  <p class="mt-5">{{ 'work.absences.conflictsModal.description' | translate }}</p>
  <p>{{ 'work.absences.conflictsModal.confirmMessage' | translate }}</p>
</ng-template>
