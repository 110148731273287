export class InitialFormModel {
  fromTime: string;
  toTime: string;
  rate: number | null;
  cumulative: boolean;

  constructor(data: any = {}) {
    this.fromTime = data?.fromTime || '';
    this.toTime = data?.toTime || '';
    this.rate = data?.rate || null;
    this.cumulative = data?.cumulative ?? false;
  }
}
