/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ConfirmRequest } from '../models/confirm-request';
import { CreatedResponseWithBody } from '../models/created-response-with-body';
import { EmptySuccessfulResponse } from '../models/empty-successful-response';
import { FiltersResponse } from '../models/filters-response';
import { GeneralApiResponse } from '../models/general-api-response';
import { InquiryOfferAmountRequest } from '../models/inquiry-offer-amount-request';
import { InquiryOfferCreateRequest } from '../models/inquiry-offer-create-request';
import { InquiryOfferUpdateRequest } from '../models/inquiry-offer-update-request';
import { OfferAmountResponse } from '../models/offer-amount-response';
import { PspInquiryOfferListItemResponse } from '../models/psp-inquiry-offer-list-item-response';
import { PaginatedApiResponse } from '../models/paginated-api-response';
import { PaginatedApiResponseWithFilters } from '../models/paginated-api-response-with-filters';
import { RevocationRequest } from '../models/revocation-request';
import { SectionResponse } from '../models/section-response';

@Injectable({
  providedIn: 'root',
})
export class PspOfferService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation postPspInquiryOffersAmount
   */
  static readonly PostPspInquiryOffersAmountPath = '/api/web/v1/psps/{psp}/inquiries/{inquiry}/offers/amount';

  /**
   * Calculate amount.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_OFFERS'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postPspInquiryOffersAmount()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPspInquiryOffersAmount$Response(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * Inquiry Id.
     */
    inquiry: number;
    context?: HttpContext
    body: InquiryOfferAmountRequest
  }
): Observable<StrictHttpResponse<GeneralApiResponse & {
'data'?: OfferAmountResponse;
}>> {

    const rb = new RequestBuilder(this.rootUrl, PspOfferService.PostPspInquiryOffersAmountPath, 'post');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.path('inquiry', params.inquiry, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GeneralApiResponse & {
        'data'?: OfferAmountResponse;
        }>;
      })
    );
  }

  /**
   * Calculate amount.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_OFFERS'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postPspInquiryOffersAmount$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPspInquiryOffersAmount(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * Inquiry Id.
     */
    inquiry: number;
    context?: HttpContext
    body: InquiryOfferAmountRequest
  }
): Observable<GeneralApiResponse & {
'data'?: OfferAmountResponse;
}> {

    return this.postPspInquiryOffersAmount$Response(params).pipe(
      map((r: StrictHttpResponse<GeneralApiResponse & {
'data'?: OfferAmountResponse;
}>) => r.body as GeneralApiResponse & {
'data'?: OfferAmountResponse;
})
    );
  }

  /**
   * Path part for operation postPspInquiryOfferConfirm
   */
  static readonly PostPspInquiryOfferConfirmPath = '/api/web/v1/psps/{psp}/inquiries/{inquiry}/offers/{offer}/confirmation';

  /**
   * Confirm an offer.
   *
   * *Accessible only for users with permission 'FEATURE_ADMIN_OFFERS'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postPspInquiryOfferConfirm()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPspInquiryOfferConfirm$Response(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * Inquiry Id.
     */
    inquiry: number;

    /**
     * Offer Id.
     */
    offer: number;
    context?: HttpContext
    body: ConfirmRequest
  }
): Observable<StrictHttpResponse<EmptySuccessfulResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PspOfferService.PostPspInquiryOfferConfirmPath, 'post');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.path('inquiry', params.inquiry, {});
      rb.path('offer', params.offer, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmptySuccessfulResponse>;
      })
    );
  }

  /**
   * Confirm an offer.
   *
   * *Accessible only for users with permission 'FEATURE_ADMIN_OFFERS'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postPspInquiryOfferConfirm$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPspInquiryOfferConfirm(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * Inquiry Id.
     */
    inquiry: number;

    /**
     * Offer Id.
     */
    offer: number;
    context?: HttpContext
    body: ConfirmRequest
  }
): Observable<EmptySuccessfulResponse> {

    return this.postPspInquiryOfferConfirm$Response(params).pipe(
      map((r: StrictHttpResponse<EmptySuccessfulResponse>) => r.body as EmptySuccessfulResponse)
    );
  }

  /**
   * Path part for operation deletePspInquiryOfferWithdraw
   */
  static readonly DeletePspInquiryOfferWithdrawPath = '/api/web/v1/psps/{psp}/inquiries/{inquiry}/offers/{offer}/confirmation';

  /**
   * Withdraw an offer.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_OFFERS'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deletePspInquiryOfferWithdraw()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePspInquiryOfferWithdraw$Response(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * Inquiry Id.
     */
    inquiry: number;
    offer: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EmptySuccessfulResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PspOfferService.DeletePspInquiryOfferWithdrawPath, 'delete');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.path('inquiry', params.inquiry, {});
      rb.path('offer', params.offer, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmptySuccessfulResponse>;
      })
    );
  }

  /**
   * Withdraw an offer.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_OFFERS'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deletePspInquiryOfferWithdraw$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePspInquiryOfferWithdraw(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * Inquiry Id.
     */
    inquiry: number;
    offer: number;
    context?: HttpContext
  }
): Observable<EmptySuccessfulResponse> {

    return this.deletePspInquiryOfferWithdraw$Response(params).pipe(
      map((r: StrictHttpResponse<EmptySuccessfulResponse>) => r.body as EmptySuccessfulResponse)
    );
  }

  /**
   * Path part for operation getPspInquiryOffersList
   */
  static readonly GetPspInquiryOffersListPath = '/api/web/v1/psps/{psp}/inquiries/{inquiry}/offers';

  /**
   * Get PSP InquiryOffers List.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_OFFERS'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPspInquiryOffersList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPspInquiryOffersList$Response(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * Inquiry Id.
     */
    inquiry: number;

    /**
     * Page number in pagination.
     */
    page?: any;

    /**
     * Size number in pagination.
     */
    size?: any;

    /**
     * Search by profile.firstName, profile.lastName
     */
    search?: string;

    /**
     * Filter by Offer status
     */
    status?: '0' | '2' | '3' | '4' | '5' | '6';

    /**
     * Filter by Main Qualifications
     */
    'mainQualifications[]'?: any;
    experience?: boolean;

    /**
     * Order offer key.
     */
    orderKey?: 'profileLastName' | 'qualificationName';

    /**
     * Order direction. Valid parameters: ASC, DESC
     */
    orderDirection?: 'ASC' | 'DESC';
    context?: HttpContext
  }
): Observable<StrictHttpResponse<PaginatedApiResponse & {
'data'?: Array<PspInquiryOfferListItemResponse>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, PspOfferService.GetPspInquiryOffersListPath, 'get');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.path('inquiry', params.inquiry, {});
      rb.query('page', params.page, {});
      rb.query('size', params.size, {});
      rb.query('search', params.search, {});
      rb.query('status', params.status, {});
      rb.query('mainQualifications[]', params['mainQualifications[]'], {});
      rb.query('experience', params.experience, {});
      rb.query('orderKey', params.orderKey, {});
      rb.query('orderDirection', params.orderDirection, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaginatedApiResponse & {
        'data'?: Array<PspInquiryOfferListItemResponse>;
        }>;
      })
    );
  }

  /**
   * Get PSP InquiryOffers List.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_OFFERS'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPspInquiryOffersList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPspInquiryOffersList(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * Inquiry Id.
     */
    inquiry: number;

    /**
     * Page number in pagination.
     */
    page?: any;

    /**
     * Size number in pagination.
     */
    size?: any;

    /**
     * Search by profile.firstName, profile.lastName
     */
    search?: string;

    /**
     * Filter by Offer status
     */
    status?: '0' | '2' | '3' | '4' | '5' | '6';

    /**
     * Filter by Main Qualifications
     */
    'mainQualifications[]'?: any;
    experience?: boolean;

    /**
     * Order offer key.
     */
    orderKey?: 'profileLastName' | 'qualificationName';

    /**
     * Order direction. Valid parameters: ASC, DESC
     */
    orderDirection?: 'ASC' | 'DESC';
    context?: HttpContext
  }
): Observable<PaginatedApiResponse & {
'data'?: Array<PspInquiryOfferListItemResponse>;
}> {

    return this.getPspInquiryOffersList$Response(params).pipe(
      map((r: StrictHttpResponse<PaginatedApiResponse & {
'data'?: Array<PspInquiryOfferListItemResponse>;
}>) => r.body as PaginatedApiResponse & {
'data'?: Array<PspInquiryOfferListItemResponse>;
})
    );
  }

  /**
   * Path part for operation postPspInquiryOffersCreate
   */
  static readonly PostPspInquiryOffersCreatePath = '/api/web/v1/psps/{psp}/inquiries/{inquiry}/offers';

  /**
   * Create new offer.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_OFFERS'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postPspInquiryOffersCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPspInquiryOffersCreate$Response(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * Inquiry Id.
     */
    inquiry: number;
    context?: HttpContext
    body: InquiryOfferCreateRequest
  }
): Observable<StrictHttpResponse<CreatedResponseWithBody>> {

    const rb = new RequestBuilder(this.rootUrl, PspOfferService.PostPspInquiryOffersCreatePath, 'post');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.path('inquiry', params.inquiry, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CreatedResponseWithBody>;
      })
    );
  }

  /**
   * Create new offer.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_OFFERS'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postPspInquiryOffersCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPspInquiryOffersCreate(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * Inquiry Id.
     */
    inquiry: number;
    context?: HttpContext
    body: InquiryOfferCreateRequest
  }
): Observable<CreatedResponseWithBody> {

    return this.postPspInquiryOffersCreate$Response(params).pipe(
      map((r: StrictHttpResponse<CreatedResponseWithBody>) => r.body as CreatedResponseWithBody)
    );
  }

  /**
   * Path part for operation postPspInquiryOffersRevoke
   */
  static readonly PostPspInquiryOffersRevokePath = '/api/web/v1/psps/{psp}/inquiries/{inquiry}/offers/{offer}/revocations';

  /**
   * Revoke an offer.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_OFFERS'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postPspInquiryOffersRevoke()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPspInquiryOffersRevoke$Response(params: {

    /**
     * PSP Id.
     */
    psp: number;
    inquiry: number;
    offer: number;
    context?: HttpContext
    body: RevocationRequest
  }
): Observable<StrictHttpResponse<EmptySuccessfulResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PspOfferService.PostPspInquiryOffersRevokePath, 'post');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.path('inquiry', params.inquiry, {});
      rb.path('offer', params.offer, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmptySuccessfulResponse>;
      })
    );
  }

  /**
   * Revoke an offer.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_OFFERS'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postPspInquiryOffersRevoke$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPspInquiryOffersRevoke(params: {

    /**
     * PSP Id.
     */
    psp: number;
    inquiry: number;
    offer: number;
    context?: HttpContext
    body: RevocationRequest
  }
): Observable<EmptySuccessfulResponse> {

    return this.postPspInquiryOffersRevoke$Response(params).pipe(
      map((r: StrictHttpResponse<EmptySuccessfulResponse>) => r.body as EmptySuccessfulResponse)
    );
  }

  /**
   * Path part for operation putPspInquiryOffersUpdate
   */
  static readonly PutPspInquiryOffersUpdatePath = '/api/web/v1/psps/{psp}/inquiries/{inquiry}/offers/{offer}';

  /**
   * Update an offer.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_OFFERS'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putPspInquiryOffersUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putPspInquiryOffersUpdate$Response(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * Inquiry Id.
     */
    inquiry: number;

    /**
     * Offer Id.
     */
    offer: number;
    context?: HttpContext
    body: InquiryOfferUpdateRequest
  }
): Observable<StrictHttpResponse<EmptySuccessfulResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PspOfferService.PutPspInquiryOffersUpdatePath, 'put');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.path('inquiry', params.inquiry, {});
      rb.path('offer', params.offer, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmptySuccessfulResponse>;
      })
    );
  }

  /**
   * Update an offer.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_OFFERS'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putPspInquiryOffersUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putPspInquiryOffersUpdate(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * Inquiry Id.
     */
    inquiry: number;

    /**
     * Offer Id.
     */
    offer: number;
    context?: HttpContext
    body: InquiryOfferUpdateRequest
  }
): Observable<EmptySuccessfulResponse> {

    return this.putPspInquiryOffersUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<EmptySuccessfulResponse>) => r.body as EmptySuccessfulResponse)
    );
  }

  /**
   * Path part for operation getPspOfferOverlapList
   */
  static readonly GetPspOfferOverlapListPath = '/api/web/v1/psps/{psp}/offers/overlap';

  /**
   * Get Overlapping Offers.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_OFFERS'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPspOfferOverlapList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPspOfferOverlapList$Response(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * Page number in pagination.
     */
    page?: any;

    /**
     * Size number in pagination.
     */
    size?: any;

    /**
     * Filter by Profile Id.
     */
    profile?: any;

    /**
     * Filter by Customer Id.
     */
    customer?: any;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<PaginatedApiResponseWithFilters & {
'data'?: Array<SectionResponse>;
'filters'?: FiltersResponse;
}>> {

    const rb = new RequestBuilder(this.rootUrl, PspOfferService.GetPspOfferOverlapListPath, 'get');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.query('page', params.page, {});
      rb.query('size', params.size, {});
      rb.query('profile', params.profile, {"style":"form"});
      rb.query('customer', params.customer, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaginatedApiResponseWithFilters & {
        'data'?: Array<SectionResponse>;
        'filters'?: FiltersResponse;
        }>;
      })
    );
  }

  /**
   * Get Overlapping Offers.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_OFFERS'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPspOfferOverlapList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPspOfferOverlapList(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * Page number in pagination.
     */
    page?: any;

    /**
     * Size number in pagination.
     */
    size?: any;

    /**
     * Filter by Profile Id.
     */
    profile?: any;

    /**
     * Filter by Customer Id.
     */
    customer?: any;
    context?: HttpContext
  }
): Observable<PaginatedApiResponseWithFilters & {
'data'?: Array<SectionResponse>;
'filters'?: FiltersResponse;
}> {

    return this.getPspOfferOverlapList$Response(params).pipe(
      map((r: StrictHttpResponse<PaginatedApiResponseWithFilters & {
'data'?: Array<SectionResponse>;
'filters'?: FiltersResponse;
}>) => r.body as PaginatedApiResponseWithFilters & {
'data'?: Array<SectionResponse>;
'filters'?: FiltersResponse;
})
    );
  }

}
