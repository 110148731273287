import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { BaseCoreState, baseInquirySelectors, baseInquiryActions } from '../../_store';
import { ActivatedRouteSnapshot } from '@angular/router';

@Injectable()
export class BaseCustomerInquiryListGuard {
  constructor(private store: Store<BaseCoreState>) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    this.store.dispatch(
      baseInquiryActions.getInquiries({
        payload: {
          page: route.queryParams.page || 1,
          size: route.queryParams.size || 15,
          'statuses[]': route.queryParams.status,
          'customerDepartments[]': route.queryParams.departments,
          'mainQualifications[]': route.queryParams.mainQualifications,
          orderKey: route.queryParams.order?.split(',')[0],
          orderDirection: route.queryParams.order?.split(',')[1],
          includeDates: route.queryParams.includeDates,
          current: route.queryParams.current,
          search: route.queryParams.search,
          createdBy: route.queryParams.createdBy,
        },
      })
    );

    return this.store.select(baseInquirySelectors.getInquiries).pipe(
      filter((result) => result.ok !== undefined),
      map((result) => !!result.ok)
    );
  }
}
