import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'base-contract-viewer',
  templateUrl: './base-contract-viewer.component.html',
})
export class BaseContractViewerComponent {
  @Input() src: Blob | undefined;
  @Input() fileName: string | undefined = '';
  @Input() modalRef: NgbModalRef | undefined;

  @Output() downloadImage = new EventEmitter<any>();

  isContractValid = true;
}
