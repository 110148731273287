import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { baseSharedActions, baseSharedSelectors, BaseCoreState } from '../../../_store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable()
export class BaseInquiryDepartmentsGuard {
  constructor(private readonly store: Store<BaseCoreState>) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (route.params.type === 'psp') {
      return true;
    }

    this.store.dispatch(baseSharedActions.loadCustomerDepartmentList({ payload: { active: true } }));

    return this.store.select(baseSharedSelectors.getDepartments).pipe(
      filter((response) => response.ok !== undefined),
      map((response) => !!response.ok)
    );
  }
}
