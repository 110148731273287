<div class="d-flex flex-column align-items-center gap-3">
  <span *ngIf="currentOffer"
    ><span class="fw-bold">{{ currentOffer.profile.firstName + ' ' + currentOffer.profile.lastName }}</span
    >{{ 'inquiry.offeredFor' | translate }}</span
  >
  <ngb-datepicker
    class="datepicker"
    [dayTemplate]="customDay"
    [startDate]="currentStartDate"
    [minDate]="currentStartDate"
    [maxDate]="currentEndDate"
    navigation="arrows"
  >
  </ngb-datepicker>
</div>

<ng-template
  #customDay
  let-date="date"
>
  <span
    class="d-flex flex-column justify-content-center align-items-center"
    [class.position]="hasPosition(date)"
  >
    <span class="d-block">{{ date.day }}</span>
    <span class="position-dot"></span>
  </span>
</ng-template>
