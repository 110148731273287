<div class="register-popup mt-5">
  <div class="d-flex justify-content-between w-100">
    <img
      class="logo mb-6"
      src="assets-global/images/powered-by-namirial.svg"
      alt="Powered by Namirial"
    />
    <i
      class="fa-solid fa-circle-xmark pointer fa-2x"
      (click)="modalRef.close()"
    ></i>
  </div>
  <form
    *ngIf="!isRegisterFormSubmitted"
    [formGroup]="registerForm"
  >
    <p>{{ 'namirialLoginModal.registration.signContractsInfo' | translate }}:</p>
    <base-checkbox
      formControlName="isConfirm"
      label="namirialLoginModal.registration.confirmAuthOption"
      [labelTranslateParams]="{ signFreeSignatureValue: signFreeSignatureValue }"
    ></base-checkbox>
  </form>
  <div *ngIf="isRegisterFormSubmitted">
    <div [innerHTML]="voucherLinkMessage"></div>
  </div>
  <div class="d-flex justify-content-between mb-3 mt-5 w-100">
    <base-button
      [size]="buttonSizes.md"
      color="secondary"
      label="action.cancel"
      class="me-3"
      (btnClick)="modalRef.close()"
    ></base-button>
    <base-button
      *ngIf="!isRegisterFormSubmitted"
      [size]="buttonSizes.md"
      [disabled]="!registerForm.get('isConfirm')?.value"
      color="primary"
      label="namirialLoginModal.registration.confirmButton"
      (btnClick)="submitRegisterForm()"
    ></base-button>
  </div>
</div>
