import { SafeUrl } from '@angular/platform-browser';
import { createAction, props } from '@ngrx/store';
import {
  AvatarUpdateRequest,
  CancelationRequest,
  DocumentUpdateRequest,
  PaginatedApiResponse,
  ProfileAbsenceConflictsListItemResponse,
  ProfileAbsenceListItemResponse,
  ProfileAbsenceResponse,
  ProfileAbsencesTypeSummaryResponse,
  ProfileCreateAbsenceRequest,
  ProfileCreateRequest,
  ProfileLeavingDateConflictsListResponse,
  ProfileLeavingDateUpdateRequest,
  ProfileListResponse,
  ProfileShowResponse,
  ProfileUpdateAbsenceRequest,
  PspProfileUpdateRequest,
  UserItemResponse,
  RevocationRequest,
  ProfileDocumentResponse,
} from '../../../api';

export const loadCandidates = createAction('[Candidates] Load candidates list');
export const setCandidatesFilter = createAction(
  '[Candidates] Set candidates filter',
  props<{ payload: { params: any } }>()
);
export const setCandidates = createAction(
  '[Candidates] Set candidates list',
  props<{ payload: PaginatedApiResponse & { data?: ProfileListResponse[] } }>()
);
export const successLoadCandidates = createAction('[Candidates] Success load candidates');
export const errorLoadCandidates = createAction('[Candidates] Error load candidates');

// Update candidate
export const updateCandidate = createAction(
  '[Candidates] Update candidate',
  props<{ payload: { profile: number; body: PspProfileUpdateRequest; inquiry?: number } }>()
);
export const successUpdateCandidate = createAction('[Candidates] Success update candidate');
export const errorUpdateCandidate = createAction('[Candidates] Error update candidate');

// Get candidate profile
export const loadCandidateProfile = createAction('[Candidates] Load candidate profile', props<{ payload: number }>());
export const setCandidateProfile = createAction(
  '[Candidates] Set candidate profile',
  props<{ payload: ProfileShowResponse }>()
);
export const successCandidateProfile = createAction('[Candidates] Success candidate profile');
export const errorCandidateProfile = createAction('[Candidates] Error candidate profile');

// Create candidate profile
export const createCandidateProfile = createAction(
  '[Candidates] Create candidate profile',
  props<{ payload: ProfileCreateRequest }>()
);
export const successCreateCandidateProfile = createAction(
  '[Candidates] Success create candidate profile',
  props<{ id: number }>()
);
export const errorCreateCandidateProfile = createAction('[Candidates] Error create candidate profile');

export const resetCandidateProfile = createAction('[Candidates] Reset candidate profile');

// Upload documents
export const uploadDocumentAction = createAction('[Candidates] Upload file', props<{ payload: { file: File } }>());
export const updateDocumentAction = createAction(
  '[Candidates] Update document',
  props<{
    payload: {
      profile: number | undefined;
      document: string;
      body: DocumentUpdateRequest;
      reloadDocumentsOnly?: boolean;
    };
  }>()
);
export const setDocumentAction = createAction('[Candidate] Set document id', props<{ payload: string }>());
export const successUploadDocumentAction = createAction('[Candidates] Success upload document');
export const errorUploadDocumentAction = createAction('[Candidates] Error upload document');
export const deleteDocumentAction = createAction(
  '[Candidate] Delete document',
  props<{ payload: { profile: number | undefined; document: string } }>()
);
export const successDeleteDocumentAction = createAction('[Candidate] Success delete document');
export const errorDeleteDocumentAction = createAction('[Candidate] Error delete document');

// Absence
export const loadAbsencesAction = createAction(
  '[Candidates] Load absences',
  props<{ payload: { profile: number; page?: number; size?: number; type: string | undefined } }>()
);
export const setAbsencesAction = createAction(
  '[Candidates] Set absences',
  props<{ payload: PaginatedApiResponse & { data?: Array<ProfileAbsenceListItemResponse> } }>()
);
export const successAbsencesAction = createAction('[Candidates] Success absences');
export const errorAbsencesAction = createAction('[Candidates] Error absences');
export const loadAbsenceSummaryAction = createAction(
  '[Candidates] Load absence summary',
  props<{ payload: { profile: number } }>()
);
export const setAbsenceSummariesAction = createAction(
  '[Candidates] Set absence summary',
  props<{ payload: ProfileAbsencesTypeSummaryResponse }>()
);
export const successAbsenceSummaryAction = createAction('[Candidates] Success absence summary');
export const errorAbsenceSummaryAction = createAction('[Candidates] Error absence summary');
export const loadAbsenceAction = createAction(
  '[Candidates] Load absence',
  props<{ payload: { profile: number; absence: number } }>()
);
export const setAbsenceAction = createAction('[Candidates] Set absence', props<{ payload: ProfileAbsenceResponse }>());
export const successAbsenceAction = createAction('[Candidates] Success absence');
export const errorAbsenceAction = createAction('[Candidates] Error absence');
export const createAbsenceAction = createAction(
  '[Candidates] Create absence',
  props<{
    payload: {
      profile: number;
      body: ProfileCreateAbsenceRequest;
      inquiry?: number;
      noLoading?: boolean;
      absenceTab?: string;
    };
  }>()
);
export const updateAbsenceAction = createAction(
  '[Candidates] Update absence',
  props<{
    payload: {
      profile: number;
      absence: number;
      body: ProfileUpdateAbsenceRequest;
      inquiry?: number;
      absenceTab?: string;
    };
  }>()
);
export const deleteAbsenceAction = createAction(
  '[Candidates] Delete absence',
  props<{ payload: { profile: number; absence: number; inquiry?: number; absenceTab?: string } }>()
);
export const successAbsenceCrudAction = createAction('[Candidates] Success absence crud');
export const errorAbsenceCrudAction = createAction('[Candidates] Error absence crud');

// Avatar
export const updateAvatarCrudAction = createAction(
  '[Candidates] Avatar update',
  props<{ payload: { profile: number; body: AvatarUpdateRequest } }>()
);

export const successAvatarCrudAction = createAction('[Candidate] Success update avatar');
export const errorAvatarCrudAction = createAction('[Candidate] Error update avatar');

// Profile Account Information
export const loadProfileAccountInformation = createAction(
  '[Candidate] Load profile account info',
  props<{ payload: { profile: number } }>()
);
export const setProfileAccountInformation = createAction(
  '[Candidate] Set profile account information',
  props<{ payload: any }>()
);
export const successLoadProfileAccountInformation = createAction('[Candidate] Success load profile account info');
export const errorLoadProfileAccountInformation = createAction('[Candidate] Error load profile account info');
export const reactivateProfileAccount = createAction(
  '[Candidate] Reactivate profile account',
  props<{ payload: number }>()
);
export const successReactivateProfileAccount = createAction('[Candidate] Success reactivate profile account');
export const activateMobileAccess = createAction(
  '[Candidate] Activate mobile access',
  props<{ payload: number; inquiry?: number }>()
);
export const deactivateMobileAccess = createAction(
  '[Candidate] Deactivate mobile access',
  props<{ payload: number; inquiry?: number }>()
);
export const successProfileAccountCrud = createAction('[Candidate] Success profile account crud');
export const errorProfileAccountCrud = createAction('[Candidate] Error profile account crud');
export const loadAbsenceConflicts = createAction(
  '[Candidates] Load absence conflicts',
  props<{ payload: { profile: number; startDate: any; endDate: any } }>()
);
export const setAbsenceConflicts = createAction(
  '[Candidates] Set absence conflicts',
  props<{ payload: ProfileAbsenceConflictsListItemResponse[] & {}[] }>()
);
export const successAbsenceConflicts = createAction('[Candidates] Success absence conflicts');
export const errorAbsenceConflicts = createAction('[Candidates] Error absence conflicts');

export const downloadAssignmentTimes = createAction(
  '[Candidates] Download Assignment Times',
  props<{ payload: any }>()
);
export const successDownloadAssignmentTimes = createAction('[Candidates] Success Download Assignment Times');
export const errorDownloadAssignmentTimes = createAction('[Candidates] Error Download Assignment Times');

export const downloadCandidateDocumentFile = createAction(
  '[Candidates] Download Candidate Document File',
  props<{ payload: { profile: number | undefined; document: string; documentType: string; openInModal: boolean } }>()
);
export const setDownloadCandidateDocumentFile = createAction(
  '[Candidates] Set Download Candidate Document File',
  props<{ payload: { document: Blob | SafeUrl } }>()
);
export const clearDownloadCandidateDocumentFile = createAction('[Candidates] Clear Download Candidate Document File');
export const successDownloadCandidateDocumentFile = createAction(
  '[Candidates] Success Download Candidate Document File'
);
export const errorDownloadCandidateDocumentFile = createAction('[Candidates] Error Download Candidate Document File');

export const downloadContracts = createAction('[Candidates] Download Contracts', props<{ payload: any }>());
export const successDownloadContracts = createAction('[Candidates] Success Download Contracts');
export const errorDownloadContracts = createAction('[Candidates] Error Download Contracts');

// Responsible Users List
export const loadResponsibleUserList = createAction('[Candidates] Load Responsible User List');
export const setResponsibleUserList = createAction(
  '[Candidates] Set Responsible User List',
  props<{ payload: (UserItemResponse & { fullName?: string })[] }>()
);
export const successResponsibleUserList = createAction('[Candidates] Success Responsible User List');
export const errorResponsibleUserList = createAction('[Candidates] Error Responsible User List');

// Leaving date conflicts
export const loadLeavingDateConflicts = createAction(
  '[Candidates] Load Leaving date conflicts',
  props<{ payload: { profile: number; leavingDate: string } }>()
);
export const setLeavingDateConflicts = createAction(
  '[Candidates] Set Leaving date conflicts',
  props<{ payload: ProfileLeavingDateConflictsListResponse }>()
);
export const successLeavingDateConflicts = createAction('[Candidates] Success Leaving date conflicts');
export const errorLeavingDateConflicts = createAction('[Candidates] Error Leaving date conflicts');

// Set profile leaving date
export const setCandidateProfileLeavingDate = createAction(
  '[Candidates] Set profile leaving date',
  props<{ payload: { profile: number; body: ProfileLeavingDateUpdateRequest } }>()
);
export const successSetCandidateProfileLeavingDate = createAction('[Candidates] Success set profile leaving date');
export const errorSetCandidateProfileLeavingDate = createAction('[Candidates] Error set profile leaving date');

// Candidate assignments cancellation by selected date
export const cancelCandidateAssignmentByDate = createAction(
  '[Candidates] Cancel candidate assignment by selected date',
  props<{ payload: { assignment: number; body: CancelationRequest } }>()
);
export const setCandidateAssignmentCancellationStatus = createAction(
  '[Candidates] Set Candidate Assignment cancellation status',
  props<{ payload: { assignment: number; hasError: boolean } }>()
);

// Candidate offers revocation by selected date
export const revokeCandidateOfferByDate = createAction(
  '[Candidates] Revoke candidate offer by selected date',
  props<{ payload: { inquiry: number; offer: number; body: RevocationRequest } }>()
);
export const setCandidateRevokeOfferStatus = createAction(
  '[Candidates] Revoke candidate offer by selected date success',
  props<{ payload: { offer: number; hasError: boolean } }>()
);

// Candidate Profile Reactivation
export const reactivateCandidateProfile = createAction(
  '[Candidates] Reactivate Profile',
  props<{ payload: { profile: number } }>()
);
export const successCandidateProfileReactivation = createAction('[Candidates] Success profile reactivation');
export const errorCandidateProfileReactivation = createAction('[Candidates] Error profile reactivation');

// Candidate Profile Document List
export const loadCandidateProfileDocuments = createAction(
  '[Candidates] Load candidate profile documents',
  props<{ payload: { profile: number } }>()
);
export const setCandidateProfileDocuments = createAction(
  '[Candidates] Set candidate profile documents',
  props<{ payload: ProfileDocumentResponse[] }>()
);
export const successCandidateProfileDocuments = createAction('[Candidates] Success candidate profile documents');
export const errorCandidateProfileDocuments = createAction('[Candidates] Error candidate profile documents');
