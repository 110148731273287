<div class="d-flex align-items-center justify-content-between py-4">
  <ng-container *ngIf="totalUnreadNotifications; then unreadNotificationsCount; else noUnreadNotifications">
  </ng-container>
  <ng-template #unreadNotificationsCount>
    <span class="fw-bold">
      {{ totalUnreadNotifications }} {{ 'notification.unread' | translate }}
      {{ (totalUnreadNotifications > 1 ? 'notification.plural' : 'notification.singular') | translate }}
    </span>
  </ng-template>
  <ng-template #noUnreadNotifications>
    <span class="fw-bold">{{ 'notification.noUnread' | translate }}</span>
  </ng-template>
  <base-button
    [size]="buttonSize.xs"
    label="showAll"
    color="primary"
    (click)="openNotificationModal($event)"
  ></base-button>
</div>
<ul
  *ngIf="totalUnreadNotifications > 0"
  class="notifications-dropdown-list list-unstyled mb-0"
>
  <li
    *ngFor="let unreadNotification of unreadNotifications | slice: 0:6"
    class="notifications-dropdown-item py-4"
  >
    <a
      href="#"
      (click)="openNotificationModal($event, unreadNotification.id)"
    >
      <div class="fw-bold notification-subject">
        {{ unreadNotification.messageSubject }}
      </div>
      <div
        baseTruncate
        class="fw-normal mb-0 notification-body"
        [innerHTML]="unreadNotification.messageBody"
      ></div>
    </a>
  </li>
</ul>

<ng-template #notificationsPopup>
  <base-notifications-modal
    [modalRef]="modalRef"
    [activeNotificationId]="activeNotificationId"
  ></base-notifications-modal>
</ng-template>
