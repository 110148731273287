import produce from 'immer';
import {
  AssignmentLeavingDateConflictsItemResponse,
  ProfileDocumentResponse,
  MetaPagination,
  OfferLeavingDateConflictsItemResponse,
  ProfileResponse,
  ProfileShowResponse,
  ProfileUserShowResponse,
  UserItemResponse,
} from '../../../api';
import { baseCandidateActions } from '../actions';
import { BaseDateService } from '../../shared/services/base-date.service';

interface CandidatesState {
  candidates: {
    data: undefined | (ProfileResponse & { active: boolean })[];
    pagination: MetaPagination | undefined;
    ok: undefined | boolean;
  };
  filter: any;
  candidate: {
    data: undefined | ProfileShowResponse;
    ok: undefined | boolean;
    createCrud: undefined | boolean;
    id: undefined | number;
  };
  document: {
    id: string | undefined;
    uploadCrud: boolean | undefined;
    deleteCrud: boolean | undefined;
  };
  downloadDocument: {
    data: Blob | undefined;
  };
  absences: {
    list: {
      data: any;
      ok: boolean | undefined;
    };
    instance: {
      data: any;
      ok: boolean | undefined;
    };
    summary: {
      data: any;
      ok: boolean | undefined;
    };
    crud: boolean | undefined;
    conflicts: {
      data: any;
      ok: boolean | undefined;
    };
  };
  account: {
    data: ProfileUserShowResponse | undefined;
    ok: boolean | undefined;
    crud: boolean | undefined;
    reactivated: boolean | undefined;
  };
  responsibleUserList: {
    data: (UserItemResponse & { fullName?: string })[] | undefined;
    ok: boolean | undefined;
  };
  leavingDateConflicts: {
    data:
      | {
          assignments: (AssignmentLeavingDateConflictsItemResponse & { hasCancellationError?: boolean })[];
          offers: (OfferLeavingDateConflictsItemResponse & { hasRevokeError?: boolean })[];
        }
      | undefined;
    ok: boolean | undefined;
  };
  candidateProfileDocuments: {
    data: ProfileDocumentResponse[] | undefined;
    ok: boolean | undefined;
  };
}

export const CANDIDATES_INIT_STATE: CandidatesState = {
  candidates: {
    data: undefined,
    pagination: undefined,
    ok: undefined,
  },
  filter: undefined,
  candidate: {
    data: undefined,
    ok: undefined,
    createCrud: undefined,
    id: undefined,
  },
  document: {
    id: undefined,
    uploadCrud: undefined,
    deleteCrud: undefined,
  },
  downloadDocument: {
    data: undefined,
  },
  absences: {
    list: {
      data: undefined,
      ok: undefined,
    },
    instance: {
      data: undefined,
      ok: undefined,
    },
    summary: {
      data: undefined,
      ok: undefined,
    },
    crud: undefined,
    conflicts: {
      data: undefined,
      ok: undefined,
    },
  },
  account: {
    data: undefined,
    ok: undefined,
    crud: undefined,
    reactivated: undefined,
  },
  responsibleUserList: {
    data: undefined,
    ok: undefined,
  },
  leavingDateConflicts: {
    data: undefined,
    ok: undefined,
  },
  candidateProfileDocuments: {
    data: undefined,
    ok: undefined,
  },
};

export function BaseCandidatesReducer(state: CandidatesState = CANDIDATES_INIT_STATE, action: any): any {
  return produce(state, (draftState) => {
    switch (action.type) {
      case baseCandidateActions.loadCandidates.type:
        draftState.candidates.ok = undefined;
        return;

      case baseCandidateActions.setCandidatesFilter.type:
        draftState.filter = action.payload.params;
        return;

      case baseCandidateActions.setCandidates.type:
        const candidateProfiles: (ProfileResponse & { active: boolean })[] = [];
        action.payload.data.profiles.forEach((item: ProfileResponse) => {
          const isCandidateActive =
            item.leavingDate === null ||
            (!!item.leavingDate && BaseDateService.isAfter(new Date(item.leavingDate), new Date()));
          candidateProfiles.push({ ...item, ...{ active: isCandidateActive } });
        });
        draftState.candidates.data = candidateProfiles;
        draftState.candidates.pagination = action.payload.meta;
        return;

      case baseCandidateActions.successLoadCandidates.type:
        draftState.candidates.ok = true;
        return;

      case baseCandidateActions.errorLoadCandidates.type:
        draftState.candidates.ok = false;
        return;

      case baseCandidateActions.loadCandidateProfile.type:
        draftState.candidate.ok = undefined;
        draftState.candidate.data = action.payload.profile;
        return;

      case baseCandidateActions.setCandidateProfile.type:
        draftState.candidate.data = action.payload;
        return;

      case baseCandidateActions.successCandidateProfile.type:
        draftState.candidate.ok = true;
        return;

      case baseCandidateActions.errorCandidateProfile.type:
        draftState.candidate.ok = false;
        return;

      case baseCandidateActions.createCandidateProfile.type:
        draftState.candidate.createCrud = undefined;
        draftState.candidate.id = undefined;
        return;

      case baseCandidateActions.successCreateCandidateProfile.type:
        draftState.candidate.createCrud = true;
        draftState.candidate.id = action.id;
        return;

      case baseCandidateActions.errorCreateCandidateProfile.type:
        draftState.candidate.createCrud = false;
        draftState.candidate.id = undefined;
        return;

      case baseCandidateActions.resetCandidateProfile.type:
        draftState.candidate.ok = false;
        draftState.candidate.createCrud = false;
        draftState.candidate.id = undefined;
        draftState.candidate.data = undefined;
        return;

      case baseCandidateActions.uploadDocumentAction.type:
        draftState.document.uploadCrud = undefined;
        draftState.document.id = undefined;
        return;

      case baseCandidateActions.setDocumentAction.type:
        draftState.document.id = action.payload;
        return;

      case baseCandidateActions.successUploadDocumentAction.type:
        draftState.document.uploadCrud = true;
        return;

      case baseCandidateActions.errorUploadDocumentAction.type:
        draftState.document.uploadCrud = false;
        return;

      case baseCandidateActions.deleteDocumentAction.type:
        draftState.document.deleteCrud = undefined;
        return;

      case baseCandidateActions.successDeleteDocumentAction.type:
        draftState.document.deleteCrud = true;
        return;

      case baseCandidateActions.errorDeleteDocumentAction.type:
        draftState.document.deleteCrud = false;
        return;

      case baseCandidateActions.setDownloadCandidateDocumentFile.type:
        draftState.downloadDocument.data = action.payload.document;
        return;

      case baseCandidateActions.clearDownloadCandidateDocumentFile.type:
        draftState.downloadDocument.data = undefined;
        return;

      case baseCandidateActions.loadAbsencesAction.type:
        draftState.absences.list.ok = false;
        return;

      case baseCandidateActions.setAbsencesAction.type:
        draftState.absences.list.data = action.payload;
        return;

      case baseCandidateActions.successAbsencesAction.type:
        draftState.absences.list.ok = true;
        return;

      case baseCandidateActions.errorAbsencesAction.type:
        draftState.absences.list.ok = false;
        return;

      case baseCandidateActions.loadAbsenceSummaryAction.type:
        draftState.absences.summary.ok = undefined;
        return;

      case baseCandidateActions.setAbsenceSummariesAction.type:
        draftState.absences.summary.data = action.payload;
        return;

      case baseCandidateActions.successAbsenceSummaryAction.type:
        draftState.absences.summary.ok = true;
        return;

      case baseCandidateActions.errorAbsenceSummaryAction.type:
        draftState.absences.summary.ok = false;
        return;

      case baseCandidateActions.loadAbsenceAction.type:
        draftState.absences.instance.ok = undefined;
        return;

      case baseCandidateActions.setAbsenceAction.type:
        draftState.absences.instance.data = action.payload;
        return;

      case baseCandidateActions.successAbsenceAction.type:
        draftState.absences.instance.ok = true;
        return;

      case baseCandidateActions.errorAbsenceAction.type:
        draftState.absences.instance.ok = false;
        return;

      case baseCandidateActions.createAbsenceAction.type:
      case baseCandidateActions.updateAbsenceAction.type:
      case baseCandidateActions.deleteAbsenceAction.type:
        draftState.absences.crud = undefined;
        return;

      case baseCandidateActions.successAbsenceCrudAction.type:
        draftState.absences.crud = true;
        return;

      case baseCandidateActions.errorAbsenceCrudAction.type:
        draftState.absences.crud = false;
        return;

      case baseCandidateActions.loadProfileAccountInformation.type:
        draftState.account.ok = undefined;
        return;

      case baseCandidateActions.setProfileAccountInformation.type:
        draftState.account.data = action.payload;
        return;

      case baseCandidateActions.successLoadProfileAccountInformation.type:
        draftState.account.ok = true;
        return;

      case baseCandidateActions.errorLoadProfileAccountInformation.type:
        draftState.account.ok = false;
        return;

      case baseCandidateActions.reactivateProfileAccount.type:
      case baseCandidateActions.activateMobileAccess.type:
      case baseCandidateActions.deactivateMobileAccess.type:
        draftState.account.crud = undefined;
        return;

      case baseCandidateActions.successProfileAccountCrud.type:
        draftState.account.crud = true;
        return;

      case baseCandidateActions.errorProfileAccountCrud.type:
        draftState.account.crud = false;
        return;

      case baseCandidateActions.loadAbsenceConflicts.type:
        draftState.absences.conflicts.ok = false;
        return;

      case baseCandidateActions.setAbsenceConflicts.type:
        draftState.absences.conflicts.data = action.payload;
        return;

      case baseCandidateActions.successAbsenceConflicts.type:
        draftState.absences.conflicts.ok = true;
        return;

      case baseCandidateActions.errorAbsenceConflicts.type:
        draftState.absences.conflicts.ok = false;
        return;

      // Responsible Users List
      case baseCandidateActions.loadResponsibleUserList.type:
        draftState.responsibleUserList.ok = undefined;
        return;

      case baseCandidateActions.setResponsibleUserList.type:
        draftState.responsibleUserList.data = action.payload;
        return;
      case baseCandidateActions.successResponsibleUserList.type:
        draftState.responsibleUserList.ok = true;
        return;
      case baseCandidateActions.errorResponsibleUserList.type:
        draftState.responsibleUserList.ok = false;
        return;
      case baseCandidateActions.successReactivateProfileAccount.type:
        draftState.account.reactivated = true;
        return;

      // Leaving date conflicts
      case baseCandidateActions.loadLeavingDateConflicts.type:
        draftState.leavingDateConflicts.ok = undefined;
        return;
      case baseCandidateActions.setLeavingDateConflicts.type:
        draftState.leavingDateConflicts.data = action.payload;
        return;
      case baseCandidateActions.successLeavingDateConflicts.type:
        draftState.leavingDateConflicts.ok = true;
        return;
      case baseCandidateActions.errorLeavingDateConflicts.type:
        draftState.leavingDateConflicts.ok = false;
        return;

      // Cancel candidate assignment status
      case baseCandidateActions.setCandidateAssignmentCancellationStatus.type:
        const assignmentIndex: number = draftState.leavingDateConflicts.data?.assignments.findIndex(
          (assignment) => assignment.id === action.payload.assignment
        ) as number;
        (
          draftState.leavingDateConflicts.data as {
            assignments: (AssignmentLeavingDateConflictsItemResponse & { hasCancellationError?: boolean })[];
            offers: (OfferLeavingDateConflictsItemResponse & { hasRevokeError?: boolean })[];
          }
        ).assignments[assignmentIndex].hasCancellationError = action.payload.hasError;
        return;

      // Withdraw candidate offer status
      case baseCandidateActions.setCandidateRevokeOfferStatus.type:
        const offerIndex: number = draftState.leavingDateConflicts.data?.offers.findIndex(
          (offer) => offer.id === action.payload.offer
        ) as number;
        (
          draftState.leavingDateConflicts.data as {
            assignments: (AssignmentLeavingDateConflictsItemResponse & { hasCancellationError?: boolean })[];
            offers: (OfferLeavingDateConflictsItemResponse & { hasRevokeError?: boolean })[];
          }
        ).offers[offerIndex].hasRevokeError = action.payload.hasError;
        return;

      // Leaving date conflicts
      case baseCandidateActions.loadCandidateProfileDocuments.type:
        draftState.candidateProfileDocuments.ok = undefined;
        return;
      case baseCandidateActions.setCandidateProfileDocuments.type:
        draftState.candidateProfileDocuments.data = action.payload;
        return;
      case baseCandidateActions.successCandidateProfileDocuments.type:
        draftState.candidateProfileDocuments.ok = true;
        return;
      case baseCandidateActions.errorCandidateProfileDocuments.type:
        draftState.candidateProfileDocuments.ok = false;
        return;
    }
  });
}
