import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ContractResponse } from '../../../../../api';
import { BaseRolesService } from '../../../../core/services/base-roles.service';
import { BaseSigningStatusBySide } from '../../_enums/base-signing-status.enum';

@Component({
  selector: 'base-sign-contract-animated-status-button',
  templateUrl: './base-sign-contract-animated-status-button.component.html',
  styleUrls: ['./base-sign-contract-animated-status-button.component.scss'],
})
export class BaseSignContractAnimatedStatusButtonComponent implements OnInit, OnChanges {
  @Input() contract: ContractResponse;

  isPsp: boolean;

  contractSignedByCustomer: string;
  contractSignedAtCustomer: string;
  contractSignedByPSP: string;
  contractSignedAtPSP: string;
  pspSignatureError: boolean;
  customerSignatureError: boolean;
  isInAQueue: boolean;
  isErrorBorder: boolean;
  isWarningBorder: boolean;

  constructor(private rolesService: BaseRolesService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.contract && !changes.contract.firstChange) {
      this.setSignatureStatusProperties();
    }
  }

  ngOnInit() {
    this.isPsp = this.rolesService.isPsp();
    this.setSignatureStatusProperties();
  }

  private setSignatureStatusProperties(): void {
    this.pspSignatureError = this.contract.signatures?.statusPsp === BaseSigningStatusBySide.ERROR;
    this.customerSignatureError = this.contract.signatures?.statusCustomer === BaseSigningStatusBySide.ERROR;

    this.isInAQueue =
      (this.isPsp && this.contract.signatures?.statusPsp === BaseSigningStatusBySide.IN_QUEUE) ||
      (!this.isPsp && this.contract.signatures?.statusCustomer === BaseSigningStatusBySide.IN_QUEUE);

    this.contractSignedByCustomer = this.customerSignatureError
      ? `${this.contract.signedByCustomerUser?.firstName} ${this.contract.signedByCustomerUser?.lastName}`
      : '';
    this.contractSignedAtCustomer = this.customerSignatureError ? (this.contract.signedAtCustomer as string) : '';

    this.contractSignedByPSP = this.pspSignatureError
      ? `${this.contract.signedByPspUser?.firstName} ${this.contract.signedByPspUser?.lastName}`
      : '';
    this.contractSignedAtPSP = this.pspSignatureError ? (this.contract.signedAtPSP as string) : '';

    this.isErrorBorder =
      (this.isPsp &&
        (this.pspSignatureError ||
          (this.contract.signatures?.statusPsp === BaseSigningStatusBySide.UNSIGNED && this.customerSignatureError))) ||
      (!this.isPsp &&
        (this.customerSignatureError ||
          (this.contract.signatures?.statusCustomer === BaseSigningStatusBySide.UNSIGNED && this.pspSignatureError)));

    this.isWarningBorder =
      (this.isPsp &&
        this.contract.signatures?.statusPsp === BaseSigningStatusBySide.SIGNED &&
        this.customerSignatureError) ||
      (!this.isPsp &&
        this.contract.signatures?.statusCustomer === BaseSigningStatusBySide.SIGNED &&
        this.pspSignatureError);
  }
}
