import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { noop } from 'rxjs';
import { BaseModalParams } from '../../interfaces/base-modal-params.model';
import { BaseModalService } from '../../services/base-modal.service';

@Component({
  selector: 'base-switch',
  templateUrl: './base-switch.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BaseSwitchComponent),
      multi: true,
    },
  ],
})
export class BaseSwitchComponent implements ControlValueAccessor {
  private current = false;
  @Input() label = '';
  @Input() positionRight = false;
  @Input() tabindex: number | null = null;
  @Input() isConfirmModal = false;
  @Input() isDisabled = false;
  @Input() confirmModalParams: BaseModalParams = {};
  @Input() color: 'primary' | string = '';
  @Input() labelClasses: string = '';
  @Input() tooltip: string;
  @Input() reverseValue = false;
  private onChange = (_: any) => {};
  private onTouched = () => {};
  modalRef: NgbModalRef;

  constructor(private modalService: BaseModalService) {}

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => {}): void {
    this.onTouched = fn;
  }

  writeValue(outsideValue: boolean): void {
    this.current = outsideValue;
  }

  get checked(): boolean {
    return this.current;
  }
  set checked(value) {
    this.current = value;
    this.onChange(this.current);
    this.onTouched();
  }

  handleSwitchClick($event: Event): void {
    if (this.current) {
      $event.preventDefault();
      this.openConfirmModal();
    }
  }

  openConfirmModal() {
    this.modalRef = this.modalService.open(this.confirmModalParams);
    this.modalRef.result.then(() => {
      this.checked = !this.checked;
    }, noop);
  }
}
