import { AfterViewInit, Component, DoCheck, ElementRef, forwardRef, Input, Renderer2, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AbstractFormComponent } from '../_abstract-classes/abstract-form-component';

@Component({
  selector: 'base-textarea',
  templateUrl: './base-textarea.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BaseTextareaComponent),
      multi: true,
    },
  ],
})
export class BaseTextareaComponent
  extends AbstractFormComponent
  implements ControlValueAccessor, DoCheck, AfterViewInit
{
  @ViewChild('textarea')
  public element: ElementRef;
  private val = '';
  @Input() isDisabled = false;
  @Input() label = '';
  @Input() placeholder = '';
  @Input() name = '';
  @Input() isInvalid = false;
  @Input() isPreview = false;
  @Input() errorMessage: string | null = null;
  @Input() tabindex = 0;
  @Input() isRequired = false;
  @Input() rows: number;
  @Input() previewClass: string;
  constructor(protected renderer: Renderer2) {
    super(renderer);
  }

  ngDoCheck(): void {
    this.setNgClass();
  }

  ngAfterViewInit(): void {
    this.setNgClass();
  }

  private onChange = (_value: any) => {};
  private onTouched = () => {};

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => {}): void {
    this.onTouched = fn;
  }

  writeValue(outsideValue: string): void {
    this.val = outsideValue;
  }

  get value(): string {
    return this.val;
  }

  set value(value: string) {
    this.val = value;
    this.onChange(this.val);
    this.onTouched();
  }
}
