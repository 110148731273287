import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Location } from '@angular/common';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, map, takeUntil, take } from 'rxjs/operators';
import { BaseAuthenticationService } from '../../../modules/auth/base-auth.service';
import { BaseButtonSize } from '../../../shared/components/button/base-button.component';
import { baseAuthSelectors } from '../../../_store';
import { BaseTokenInterface } from '../../../shared/interfaces/base-token-service.interface';

@Component({
  selector: 'base-error',
  templateUrl: './base-error.component.html',
  styleUrls: ['./base-error.component.scss'],
})
export class BaseErrorComponent implements OnDestroy {
  is404 = false;
  destroy$ = new Subject();
  buttonSize = BaseButtonSize;
  isAuth = false;

  constructor(
    private readonly router: Router,
    private readonly authService: BaseAuthenticationService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly store: Store,
    private readonly location: Location
  ) {
    this.router.events
      .pipe(
        takeUntil(this.destroy$),
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        map((route) => route.snapshot)
      )
      .subscribe((routeSnapshot) => {
        this.is404 = routeSnapshot.data?.is404;
      });

    this.store
      .select(baseAuthSelectors.getUserInfo)
      .pipe(
        take(1),
        map((user) => user.data as BaseTokenInterface)
      )
      .subscribe((data) => {
        this.isAuth = !!data;
      });
  }

  goBack() {
    this.router.navigate(['auth/login']);
  }

  ngOnDestroy() {
    this.destroy$.unsubscribe();
  }
}
