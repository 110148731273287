import { createFeatureSelector, createSelector } from '@ngrx/store';
import { BranchListItemResponse, PermissionItemResponse, PspBranchResponse } from '../../../api';
import { PspBranchState } from '../reducers/base-psp-branch.reducer';

const getPspBranchRoot = createFeatureSelector('psp-branch');

export const getPspBranchState = createSelector(getPspBranchRoot, (state: any): PspBranchState => state);

export const getPspBranchListData = createSelector(
  getPspBranchState,
  (state: PspBranchState): { message: string; data: Array<BranchListItemResponse> } | undefined => state.pspBranch.data
);

export const getPspBranchListStatus = createSelector(
  getPspBranchState,
  (state: PspBranchState): boolean | undefined => state.pspBranch.ok
);

export const getPspBranchCrud = createSelector(
  getPspBranchState,
  (state: PspBranchState): boolean | undefined => state.pspBranch.crud
);

export const getPspUserList = createSelector(
  getPspBranchState,
  (
    state: PspBranchState
  ): { data: { message: string; data: Array<PermissionItemResponse> } | undefined; ok: boolean | undefined } =>
    state?.pspBranchUsers
);

export const getCurrentBranch = createSelector(
  getPspBranchState,
  (state: PspBranchState): { data: PspBranchResponse | undefined; ok: boolean | undefined } => state.currentBranch
);

export const getBranchInactiveFilter = createSelector(
  getPspBranchState,
  (state: PspBranchState): boolean => state.branchFilter.filterActive
);
