<div
  class="horizontal-nav"
  [ngClass]="{ 'combined-nav': isCombined }"
>
  <base-button
    *ngFor="let tab of tabs"
    [size]="tabSize"
    [frontBadge]="showBadge && tab.badge !== undefined ? tab.badge + '' : ''"
    [color]="current.id === tab.id ? 'dark' : 'light'"
    [label]="tab.title"
    [disabled]="tab.disabled"
    [ngClass]="tab.disabled ? 'not-allowed' : ''"
    [stateClass]="tabClass"
    (btnClick)="onTabSelect(tab)"
  >
    <ng-container *ngIf="tab.isNgContent">
      <ng-content></ng-content>
    </ng-container>
  </base-button>
</div>
