<div class="d-flex h-100">
  <div class="me-3 d-xxxl-flex flex-column align-items-center">
    <base-avatar
      [size]="avatarSize.large"
      [image]="profile.avatar"
    ></base-avatar>
    <ng-container *ngIf="rating">
      <span class="mt-2">Wertung</span>
      <span class="fw-bold fs-4 lh-1">{{ rating | baseFormatDecimal }}</span>
    </ng-container>
  </div>
  <div class="flex-fill d-flex flex-column overflow-hidden">
    <span
      class="fw-bold"
      baseTruncate
      >{{ profile.firstName + ' ' + profile.lastName }}</span
    >
    <span
      class="text-dark-green"
      baseTruncate
      >{{ mainQualification.name }}</span
    >
    <span
      *ngIf="!hideDepartmentInfo"
      baseTruncate
      >{{ department.businessAddress.postCode + ' ' + department.businessAddress.city }}</span
    >
    <span
      *ngIf="distance"
      baseTruncate
      >{{ distance }} km</span
    >
    <span
      *ngIf="hourlyAmount"
      class="fw-bold"
      baseTruncate
      >{{ hourlyAmount | baseFormatDecimal }} €</span
    >

    <div
      *ngIf="!hideBadges"
      class="mt-auto d-flex mx-2 mt-2 mb-3"
    >
      <base-button
        [size]="buttonSizes.sm"
        color="dark"
        [iconSize]="buttonSizes.sm"
        icon="fa-solid fa-pen"
        class="me-2"
      ></base-button>
      <base-button
        [size]="buttonSizes.sm"
        color="light"
        iconColor="text-success"
        [iconSize]="buttonSizes.lg"
        icon="fa-solid fa-check"
        class="me-1"
      ></base-button>
      <base-button
        [size]="buttonSizes.sm"
        color="light"
        iconColor="text-muted"
        [iconSize]="buttonSizes.lg"
        icon="fa-regular fa-heart"
      ></base-button>
    </div>
  </div>
</div>
