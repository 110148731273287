import { Pipe, PipeTransform } from '@angular/core';
import { format, isValid } from 'date-fns';

@Pipe({ name: 'baseFormatTime' })
export class BaseFormatTimePipe implements PipeTransform {
  transform(value: string, label?: string): string {
    return value && isValid(new Date(value))
      ? !!label
        ? `${format(new Date(value), 'HH:mm')} ${label}`
        : format(new Date(value), 'HH:mm')
      : value;
  }
}
