import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Location } from '@angular/common';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { BaseTokenInterface } from '../../../../shared/interfaces/base-token-service.interface';
import { baseAuthActions, baseAuthSelectors, BaseCoreState } from '../../../../_store';
import { Subject } from 'rxjs';

@Component({
  selector: 'base-auth-page',
  templateUrl: './base-auth-page.component.html',
})
export class BaseAuthPageComponent implements OnInit, OnDestroy {
  isAuth: boolean = false;
  isCustomizedUser = false;

  private destroy$ = new Subject();

  constructor(private store: Store<BaseCoreState>, private router: Router, private location: Location) {}

  ngOnInit() {
    this.store
      .select(baseAuthSelectors.getUserInfo)
      .pipe(
        take(1),
        map((user) => user.data as BaseTokenInterface)
      )
      .subscribe((data) => {
        this.isAuth = !!data;
      });

    this.store
      .select(baseAuthSelectors.getCustomizedUserStatus)
      .pipe(
        takeUntil(this.destroy$),
        filter((status) => status !== undefined),
        map((status) => status as boolean)
      )
      .subscribe((status) => {
        this.isCustomizedUser = status;
      });
  }

  handleAddSignPopup() {
    this.isAuth ? this.location.back() : this.store.dispatch(baseAuthActions.navigateToLoginPage());
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }
}
