import { AbstractControl } from '@angular/forms';

export const basePhoneNumberValidator = () => {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    const phoneNumberPattern = new RegExp(
      '^([+](\\d{1,3})(\\(0\\))?\\s?)?((\\(\\d{3,5}\\)|\\d{3,5})(\\s)?)[0-9\\-]{3,9}?$'
    );
    if (control.value && !phoneNumberPattern.test(control.value)) {
      return { phoneNumberInvalid: true };
    }
    return null;
  };
};
