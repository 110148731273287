<div
  class="d-flex"
  (click)="onOrderChanged(orderKey)"
>
  <span baseTruncate>{{ label | translate }}</span>
  <div
    class="sortable pointer"
    [ngClass]="{
      sortable: _activeKey !== orderKey,
      'sortable-asc': _activeKey === orderKey && orderDirection === directions.asc,
      'sortable-desc': _activeKey === orderKey && orderDirection === directions.desc
    }"
  ></div>
</div>
