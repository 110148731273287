<base-contract-viewer-toolbar
  [modalRef]="modalRef"
  [src]="src"
  [fileName]="fileName"
  [(isContractValid)]="isContractValid"
  (downloadImage)="downloadImage.emit()"
></base-contract-viewer-toolbar>
<div class="contract-viewer-container">
  <base-pdf-viewer
    [src]="src || ''"
    [fileName]="fileName"
  ></base-pdf-viewer>
  <span
    *ngIf="!isContractValid"
    class="invalid-label"
  >
    <span>
      {{ 'contractRegeneration.invalid' | translate }}
    </span>
  </span>
</div>
