import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseModalParams } from '../../interfaces/base-modal-params.model';
import { BaseButtonSize } from '../button/base-button.component';

export enum BaseModalDismissReason {
  empty = '',
  cancel = 'cancel',
  crossClick = 'Cross click',
}

export enum BaseModalHeaderSizes {
  esm = 'esmall-header-title',
  sm = 'small-header-title',
  md = 'medium-header-title',
  lg = 'large-header-title',
}

const INITIAL_PARAMS: BaseModalParams = {
  bodyText: '',
  headText: '',
  headerSize: BaseModalHeaderSizes.md,
  subHeaderSize: BaseModalHeaderSizes.lg,
  subHeadText: '',
  headTextCentered: false,
  okText: 'action.apply',
  cancelText: 'action.cancel',
  okButtonDisplayed: true,
  cancelButtonDisplayed: true,
  okButtonDisabled: false,
  cancelButtonDisabled: false,
  bodyTemplate: null,
  hideHeader: false,
  scrollToTop: false,
  cancelButtonOnly: false,
  confirmButtonOnly: false,
  customConfirmationText: '',
  // tslint:disable-next-line
  canBeClosed: function () {
    return true;
  },
  hideCloseIcon: false,
  hideFooter: false,
  cancelButtonClick(): void {},
};

@Component({
  selector: 'base-modal',
  templateUrl: './base-modal.component.html',
})
export class BaseModalComponent implements OnInit {
  @ViewChild('modalBody') modalBody: ElementRef;
  // Original Data from Open Call
  modalData: any;
  // Data with Default
  modalParams: BaseModalParams = {};
  content: any;
  buttonSize = BaseButtonSize;
  modalDismissReasons = BaseModalDismissReason;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {
    this.modalParams = { ...INITIAL_PARAMS, ...this.modalData };
  }

  subHeadText(): string {
    return typeof this.modalParams.subHeadText === 'function'
      ? this.modalParams.subHeadText()
      : this.modalParams.subHeadText;
  }

  okText(): string {
    return typeof this.modalParams.okText === 'function' ? this.modalParams.okText() : this.modalParams.okText;
  }

  closeModal(reason: string): void {
    // @ts-ignore
    if (this.modalParams.canBeClosed()) {
      this.activeModal.close(reason);
    }
  }

  dismissModal(reason: BaseModalDismissReason): void {
    this.activeModal.dismiss(reason);
  }

  scrollToTopForm(): void {
    if (this.modalParams.scrollToTop) {
      this.modalBody.nativeElement.scrollTop = 0;
    }
  }

  get modalStyles(): any {
    let styles = {};

    if (this.modalParams.maxWidth) {
      styles = { ...styles, 'max-width': this.modalParams.maxWidth };
    }

    return styles;
  }
}
