import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'base-amount-circle',
  templateUrl: './base-amount-circle.component.html',
})
export class BaseAmountCircleComponent implements OnInit {
  @Input() amount: number;
  textSmall = false;

  ngOnInit(): void {
    this.modifyTextSize();
  }

  private modifyTextSize() {
    this.textSmall = this.amount?.toString().length > 2;
  }
}
