import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DocumentsState } from '../reducers/base-documents.reducer';

const getDocumentsRoot = createFeatureSelector('documents');

export const getDocumentsState = createSelector(getDocumentsRoot, (state: any): DocumentsState => state);

export const getDocuments = createSelector(getDocumentsState, (state: DocumentsState) => state.documents);

export const getCurrentDocument = createSelector(
  getDocumentsState,
  (state: DocumentsState) => state.currentDocumentInfo
);

export const getDownloadDocument = createSelector(
  getDocumentsState,
  (
    state: any
  ): {
    data: Blob | undefined;
  } => state.downloadDocument
);

export const getDocumentCategories = createSelector(getDocumentsState, (state: DocumentsState) => state.categories);
