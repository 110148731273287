import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { InquiryDateResponse } from '../../../../../api';
import { ShiftTimeAndFlexResponse } from '../../_enums/base-inquiry.enum';
import { BaseInquiryBubbleListItem, BaseInquiryDate, BaseInquiryBubble } from '../../_models/base-inquiry-date';
import { BaseInquiryDateService } from '../../_services/base-inquiry-date.service';
import { BaseInquiryService } from '../../_services/base-inquiry.service';
import { BaseInquiryShiftService } from '../../_services/base-inquiry-shift.service';
import { BaseInquiryCalendarBubbleListService } from '../../_services/base-inquiry-calendar-bubble-list.service';
import {
  BASE_INQUIRY_CALENDAR_BUFFER_PX_SIZE,
  BASE_INQUIRY_CALENDAR_DAY_COLUMN_WIDTH,
} from '../../_const/base-inquiry-calendar.const';

@Component({
  selector: 'base-calendar-bubbles-list-new',
  templateUrl: './base-calendar-bubbles-list-new.component.html',
})
export class BaseCalendarBubblesListNewComponent implements OnInit, OnDestroy {
  @Input() isVisibleCalendar = false;

  inquiryDates: InquiryDateResponse[] = [];
  shiftTimesWithFlex: (ShiftTimeAndFlexResponse & { index: number; label?: string })[] = [];
  datesArray: BaseInquiryDate[] = [];
  bubbleList: BaseInquiryBubbleListItem[] = [];

  dayColumnWidth = BASE_INQUIRY_CALENDAR_DAY_COLUMN_WIDTH;
  calendarBufferSize = BASE_INQUIRY_CALENDAR_BUFFER_PX_SIZE;

  private destroy$ = new Subject();

  constructor(
    private baseInquiryDateService: BaseInquiryDateService,
    private baseInquiryService: BaseInquiryService,
    private baseInquiryShiftService: BaseInquiryShiftService,
    private baseInquiryCalendarBubbleListService: BaseInquiryCalendarBubbleListService
  ) {}

  ngOnInit() {
    this.getDataAndPrepareBubbleList();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }

  private getDataAndPrepareBubbleList() {
    combineLatest([
      this.baseInquiryService.inquirySubject$,
      this.baseInquiryDateService.datesArraySubject$,
      this.baseInquiryShiftService.shiftTimesWithFlexSubject$,
    ])
      .pipe(
        takeUntil(this.destroy$),
        map(([inquiry, datesArray, shiftTimesWithFlex]) => [inquiry.dates, datesArray, shiftTimesWithFlex])
      )
      .subscribe(([inquiryDates, datesArray, shiftTimesWithFlex]) => {
        this.inquiryDates = inquiryDates as InquiryDateResponse[];
        this.datesArray = datesArray as BaseInquiryDate[];
        this.shiftTimesWithFlex = shiftTimesWithFlex as (ShiftTimeAndFlexResponse & {
          index: number;
          label?: string;
        })[];

        this.prepareBubbleList();
      });
  }

  trackByDay(_: number, item: BaseInquiryBubbleListItem) {
    return item.dateItem.dateString;
  }

  trackByBubble(_: number, item: BaseInquiryBubble) {
    return item.shift;
  }

  private prepareBubbleList() {
    this.baseInquiryCalendarBubbleListService.inquiryDates = this.inquiryDates;
    this.bubbleList = this.baseInquiryCalendarBubbleListService.prepareBubbleList(
      this.datesArray,
      this.shiftTimesWithFlex
    );
  }
}
