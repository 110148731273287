type BaseCustomErrorAlerts = {
  status: number;
  ignoreBySentry?: boolean;
  errorMessage: string;
  message: string;
};

export const BASE_CUSTOM_ERROR_ALERTS: BaseCustomErrorAlerts[] = [
  {
    status: 0,
    errorMessage: '',
    message: 'errorAlerts.internetConnection',
  },
  {
    status: -1,
    ignoreBySentry: true,
    errorMessage: 'isNodeJS is not defined',
    message: '',
  },
  {
    status: -1,
    ignoreBySentry: true,
    errorMessage: 'Invalid token specified',
    message: '',
  },
  {
    status: -1,
    ignoreBySentry: true,
    errorMessage: 'Uncaught (in promise): ChunkLoadError: Loading chunk',
    message: '',
  },
];
