import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { DocumentCategoryResponse, DocumentListItemResponse } from '../../../api';
import { BaseRolesService } from '../../core/services/base-roles.service';
import { BaseTokenInterface } from '../../shared/interfaces/base-token-service.interface';
import { BaseCustomerDocumentsService } from '../../modules/documents/base-customer-documents.service';
import { BasePspDocumentsService } from '../../modules/documents/base-psp-documents.service';
import { baseDocumentActions } from '../actions';
import { BaseCoreState } from '../reducers';
import { baseAuthSelectors } from '../selectors';
import { BASE_ROLES } from '../../core/constants/base-roles';
import { BaseAvailableFileTypeEnum } from '../../shared/enums/base-available-file-type.enum';

@Injectable()
export class BaseDocumentsEffects {
  loadDocuments$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseDocumentActions.loadDocuments),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((state) => state.data as BaseTokenInterface))
      ),
      switchMap(([_action, user]) => {
        const source$ =
          this.roleService.isCustomer() || this.roleService.hasRole(BASE_ROLES.CUSTOMER_LOCATION_ADMIN)
            ? this.customerDocumentsService.getDocuments(user.organizationId, _action.payload.location as number)
            : this.pspDocumentsService.getDocuments(user.organizationId);
        return source$.pipe(
          mergeMap((response: Array<DocumentListItemResponse>) => [
            baseDocumentActions.setDocuments({ payload: response }),
            baseDocumentActions.successLoadDocuments(),
          ]),
          catchError(() => of(baseDocumentActions.errorLoadDocuments()))
        );
      })
    );
  });
  addDocument$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseDocumentActions.createDocumentAction),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((state) => state.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        if (this.roleService.isCustomer()) {
          return this.customerDocumentsService
            .createDocument(user.organizationId, action.payload.location as number, action.payload.file)
            .pipe(
              switchMap((response) => {
                return this.customerDocumentsService
                  .updateDocument(
                    user.organizationId,
                    action.payload.location as number,
                    response.documentId as string,
                    action.payload.body
                  )
                  .pipe(
                    tap((_) =>
                      this.store.dispatch(
                        baseDocumentActions.loadDocuments({ payload: { location: action.payload.location } })
                      )
                    ),
                    map(() => baseDocumentActions.successUpdateDocument()),
                    catchError(() => of(baseDocumentActions.errorUpdateDocument()))
                  );
              })
            );
        } else {
          return this.pspDocumentsService.createDocument(user.organizationId, action.payload.file).pipe(
            switchMap(({ documentId }) => {
              return this.pspDocumentsService
                .updateDocument(user.organizationId, documentId as string, action.payload.body)
                .pipe(
                  tap((_) =>
                    this.store.dispatch(
                      baseDocumentActions.loadDocuments({ payload: { location: action.payload.location } })
                    )
                  ),
                  map(() => baseDocumentActions.successUpdateDocument()),
                  catchError(() => of(baseDocumentActions.errorUpdateDocument()))
                );
            })
          );
        }
      })
    );
  });
  deleteDocument$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseDocumentActions.deleteDocument),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((state) => state.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        const source$ =
          this.roleService.isCustomer() && action.payload.location
            ? this.customerDocumentsService.deleteDocument(
                user.organizationId,
                action.payload.location,
                action.payload.document
              )
            : this.pspDocumentsService.deleteDocument(user.organizationId, action.payload.document);
        return source$.pipe(
          tap((_) =>
            this.store.dispatch(baseDocumentActions.loadDocuments({ payload: { location: action.payload.location } }))
          ),
          map(() => baseDocumentActions.successDeleteDocument()),
          catchError(() => of(baseDocumentActions.errorDeleteDocument()))
        );
      })
    );
  });
  getDocument$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseDocumentActions.getDocumentInfo),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((state) => state.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        const source$ = this.roleService.isCustomer()
          ? this.customerDocumentsService.getDocumentInfo(
              user.organizationId,
              action.payload.location as number,
              action.payload.document
            )
          : this.pspDocumentsService.getDocumentInfo(user.organizationId, action.payload.document);
        return source$.pipe(
          mergeMap((response) => [
            baseDocumentActions.loadDocumentInfo({ payload: response }),
            baseDocumentActions.successLoadDocumentInfo(),
          ]),
          catchError(() => of(baseDocumentActions.errorLoadDocumentInfo()))
        );
      })
    );
  });
  updateDocumentInfo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseDocumentActions.updateDocument),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((state) => state.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        const source$ = this.roleService.isCustomer()
          ? this.customerDocumentsService.updateDocument(
              user.organizationId,
              action.payload.location as number,
              action.payload.document,
              action.payload.body
            )
          : this.pspDocumentsService.updateDocument(user.organizationId, action.payload.document, action.payload.body);
        return source$.pipe(
          mergeMap(() => [
            baseDocumentActions.successUpdateDocument(),
            baseDocumentActions.loadDocuments({ payload: { location: action.payload.location } }),
            baseDocumentActions.getDocumentInfo({
              payload: { document: action.payload.document, location: action.payload.location },
            }),
          ]),
          catchError(() => of(baseDocumentActions.errorUpdateDocument()))
        );
      })
    );
  });
  changeDocumentFile$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseDocumentActions.changeDocumentFile),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((state) => state.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        const source$ = this.roleService.isCustomer()
          ? this.customerDocumentsService.changeDocumentFile(
              user.organizationId,
              action.payload.location as number,
              action.payload.document,
              action.payload.file
            )
          : this.pspDocumentsService.changeDocumentFile(
              user.organizationId,
              action.payload.document,
              action.payload.file
            );
        return source$.pipe(
          map(() => baseDocumentActions.successChangeDocumentFile()),
          catchError(() => of(baseDocumentActions.errorChangeDocumentFile()))
        );
      })
    );
  });
  downloadDocument$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseDocumentActions.downloadDocument),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((state) => state.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        const source$ = this.roleService.isCustomer()
          ? this.customerDocumentsService.downloadDocument(
              user.organizationId,
              action.payload.location as number,
              action.payload.document
            )
          : this.pspDocumentsService.downloadDocument(user.organizationId, action.payload.document);
        return source$.pipe(
          tap((response) => {
            if (!action.payload.openInModal) {
              const blob = new Blob([response.body], { type: BaseAvailableFileTypeEnum.applicationPDF });
              const objectUrl = URL.createObjectURL(blob);
              const link = document.createElement('a');
              link.setAttribute('href', objectUrl);
              link.setAttribute('download', response.fileName);
              link.style.visibility = 'hidden';
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            }
          }),
          !action.payload.openInModal
            ? map(() => baseDocumentActions.successDownloadDocument())
            : mergeMap((response) => [
                baseDocumentActions.setDownloadDocument({
                  payload: {
                    document:
                      action.payload.documentType === BaseAvailableFileTypeEnum.pdf
                        ? new Blob([response.body], { type: BaseAvailableFileTypeEnum.applicationPDF })
                        : this.sanitizer.bypassSecurityTrustUrl(
                            URL.createObjectURL(
                              new Blob([response.body], { type: `image/${action.payload.documentType}` })
                            )
                          ),
                  },
                }),
                baseDocumentActions.successDownloadDocument(),
              ]),
          catchError(() => of(baseDocumentActions.errorDownloadDocument()))
        );
      })
    );
  });
  getDocumentCategories$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseDocumentActions.getDocumentCategories),
      switchMap((_action) => {
        const source$ = this.roleService.isCustomer()
          ? this.customerDocumentsService.getDocumentCategories()
          : this.pspDocumentsService.getDocumentCategories();
        return source$.pipe(
          mergeMap((response: DocumentCategoryResponse) => [
            baseDocumentActions.loadDocumentCategories({ payload: response }),
            baseDocumentActions.successDocumentCategories(),
          ]),
          catchError(() => of(baseDocumentActions.errorDocumentCategories()))
        );
      })
    );
  });

  constructor(
    private actions$: Actions,
    private store: Store<BaseCoreState>,
    private pspDocumentsService: BasePspDocumentsService,
    private customerDocumentsService: BaseCustomerDocumentsService,
    private roleService: BaseRolesService,
    private sanitizer: DomSanitizer
  ) {}
}
