import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { basePspPriceListActions, basePspPriceListSelectors, BaseCoreState } from '../../../_store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable()
export class BasePriceListCategoriesGuard {
  constructor(private store: Store<BaseCoreState>) {}

  canActivate(): Observable<boolean> {
    this.store.dispatch(basePspPriceListActions.loadCategories({ payload: {} }));

    return this.store.select(basePspPriceListSelectors.getPriceListCategories).pipe(
      filter((state) => state.ok !== undefined),
      map((state) => state.ok as boolean)
    );
  }
}
