import { Pipe, PipeTransform } from '@angular/core';
import { OfferPositionResponse, ShiftType } from '../../../../api';

@Pipe({
  name: 'baseFillFlexShifts',
})
export class BaseFillFlexShiftsPipe implements PipeTransform {
  transform(value: Array<OfferPositionResponse>, ...args: any[]): Array<OfferPositionResponse> {
    const result = value.reduce((acc: OfferPositionResponse[], item: OfferPositionResponse) => {
      if (!item.flexShift || !item.selectedShift) {
        acc.push(item);
      } else {
        const updatedItem = { ...item };
        acc = acc.concat(
          ['Fd', 'Nd', 'Sd', 'Zd']
            // @ts-ignore
            .filter((shift) => !!updatedItem[`shift${shift}`])
            .map((shift) => {
              updatedItem.selectedShift = shift.toLowerCase() as ShiftType;
              return { ...updatedItem };
            })
        );
      }

      return acc;
    }, []);

    return result;
  }
}
