import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BaseLoggingService } from './base-logging.service';

enum ErrorTypes {
  'Type' = 'TypeError',
  'Unknown' = 'Unknown',
}

@Injectable()
export class BaseGlobalErrorService {
  private errorsMap = new Map<string, any>([
    [
      ErrorTypes.Type,
      (error: Error) => () => {
        this.loggerService.logError(error);
      },
    ],
    [
      ErrorTypes.Unknown,
      (error: Error) => () => {
        this.loggerService.logError(error);
      },
    ],
  ]);

  constructor(private router: Router, private loggerService: BaseLoggingService) {}

  registerError(error: Error): () => void {
    BaseGlobalErrorService.defaultErrorHandling(error);
    if (this.errorsMap.has(error.name)) {
      return this.errorsMap.get(ErrorTypes.Type)(error);
    } else {
      return this.errorsMap.get(ErrorTypes.Unknown)(error);
    }
  }

  private static defaultErrorHandling(error: Error): void {
    console.error(error);
  }
}
