import { addDays } from 'date-fns';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import {
  InquiryDateResponse,
  InquiryResponse,
  CustomerInquiryOfferListItemResponse,
  OfferPositionResponse,
} from '../../../../api';
import { BaseDateService } from '../../../shared/services/base-date.service';
import { OfferBubbleColors } from '../_enums/offer-bubble-colors.enum';
import { OfferBubbleValues } from '../_enums/offer-bubble-values.enum';
import {
  BaseCustomerInquiryBubbleColorAndValue,
  BaseCustomerInquiryOfferDateInterface,
  BaseCustomerInquiryOfferInterface,
  BaseCustomerInquiryOfferShiftInterface,
  BaseCustomerOfferBubbleSelectPayload,
} from '../_interfaces/base-cusomer-inquiry-offer.interface';
import {
  BASE_INQUIRY_CALENDAR_MAX_DAYS_TO_SCROLL,
  BASE_INQUIRY_CALENDAR_ORDERED_SHIFTS,
} from '../_const/base-inquiry-calendar.const';
import {
  BaseInquiryShiftsUpperCase,
  BaseInquiryShiftsWithFlex,
  BaseInquiryShiftsWithFlexType,
  BaseOfferStatus,
} from '../_enums/base-inquiry.enum';
import { BaseCustomerOfferBubbleClassesEnum } from '../_enums/base-customer-offer-bubble-classes.enum';
import { BaseToastAlertClasses } from '../../../shared/enums/base-toast-alert-classes.enum';
import { baseSharedActions, BaseCoreState } from '../../../_store';
import {
  getClosestDayOfLastWeek,
  getDaysArrayWithWeekdays,
} from '../../../shared/helpers/base-get-dates-array-between-two-dates';

@Injectable()
export class BaseCustomerInquiryOfferService {
  constructor(private translateService: TranslateService, private store: Store<BaseCoreState>) {}

  prepareCustomerInquiryOfferBubblesList(
    offers: CustomerInquiryOfferListItemResponse[],
    inquiry: InquiryResponse
  ): BaseCustomerInquiryOfferInterface[] {
    const currentDate = BaseDateService.toDate(inquiry.dateFrom as string);

    return offers.map((offer) => ({
      data: {
        ...offer,
        isSelected: false,
        order: offer.status === BaseOfferStatus.confirmed ? 1 : offer.status === BaseOfferStatus.declined ? 2 : 0,
      },
      dates: this.prepareInquiryDates(inquiry, currentDate, offer),
    }));
  }

  selectCustomerInquiryOffer(
    offers: BaseCustomerInquiryOfferInterface[],
    offerId: number
  ): BaseCustomerInquiryOfferInterface[] {
    const updatedList = this.selectOfferInTheList(offers, offerId);
    return this.updateShiftsDuringOfferSelection(updatedList);
  }

  updateCustomerOffersListWithSelectedBubble(
    offers: BaseCustomerInquiryOfferInterface[],
    payload: BaseCustomerOfferBubbleSelectPayload
  ): BaseCustomerInquiryOfferInterface[] {
    const selectedOfferIndex: number = offers.findIndex((offer) => offer.data.id === payload.offerId);

    const updatedOffers = offers.slice();

    const updatedDates = updatedOffers[selectedOfferIndex].dates.map((dateItem) => {
      return dateItem.date === payload.date
        ? {
            ...dateItem,
            shifts: dateItem.shifts.map((shiftItem) => this.updateShift(shiftItem, payload.shift)),
          }
        : dateItem;
    });

    updatedOffers[selectedOfferIndex] = { ...updatedOffers[selectedOfferIndex], dates: updatedDates };

    return updatedOffers;
  }

  selectAllFlexShifts(
    offers: BaseCustomerInquiryOfferInterface[],
    offerId: number
  ): BaseCustomerInquiryOfferInterface[] {
    return offers.map((offer) => {
      const isSelectedOffer = offer.data.id === offerId;
      return isSelectedOffer ? this.updateFlexShifts(offer) : offer;
    });
  }

  selectAllFixedShiftsByType(
    offers: BaseCustomerInquiryOfferInterface[],
    offerId: number,
    shiftType: BaseInquiryShiftsWithFlex
  ): BaseCustomerInquiryOfferInterface[] {
    return offers.map((offer) => {
      const isSelectedOffer = offer.data.id === offerId;
      return isSelectedOffer ? this.updateFixedShifts(offer, shiftType) : offer;
    });
  }

  selectAllOfferedShifts(
    offers: BaseCustomerInquiryOfferInterface[],
    offerId: number,
    isShiftsDeselectionMode: boolean
  ): BaseCustomerInquiryOfferInterface[] {
    return offers.map((offer) => {
      const isSelectedOffer = offer.data.id === offerId;
      return isSelectedOffer ? this.updateAllSelectedShifts(offer, isShiftsDeselectionMode) : offer;
    });
  }

  private prepareInquiryDates(
    inquiry: InquiryResponse,
    currentDate: Date,
    offer: CustomerInquiryOfferListItemResponse
  ): BaseCustomerInquiryOfferDateInterface[] {
    const inquiryDateTimes = inquiry.dates
      ? inquiry.dates.map((newInquiryDate: InquiryDateResponse) => new Date(newInquiryDate.date).getTime())
      : [];
    const maxDate = new Date(
      Math.max(
        ...inquiryDateTimes,
        BaseDateService.addDays(currentDate, BASE_INQUIRY_CALENDAR_MAX_DAYS_TO_SCROLL).getTime()
      )
    );
    const inquiryLength = BaseDateService.differenceInWeeks(currentDate, maxDate);
    let weekAmount = inquiryLength + (maxDate.getDay() > 1 && maxDate.getDay() <= 6 ? 2 : 1);

    const closestMonday = getClosestDayOfLastWeek('Mon', currentDate);
    const dates: string[] = getDaysArrayWithWeekdays(closestMonday, addDays(closestMonday, weekAmount * 7 - 1));

    return this.addDataToCustomerInquiryOfferDatesArray(dates, inquiry, offer);
  }

  private addDataToCustomerInquiryOfferDatesArray(
    dates: string[],
    inquiry: InquiryResponse,
    offer: CustomerInquiryOfferListItemResponse
  ): BaseCustomerInquiryOfferDateInterface[] {
    if (!dates.length) {
      return [];
    }

    let datesArray: BaseCustomerInquiryOfferDateInterface[] = [];
    const getCustomerOfferBubbleColorAndValue = (
      date: string,
      shift: BaseInquiryShiftsWithFlexType
    ): BaseCustomerInquiryBubbleColorAndValue => {
      return this.setCustomerOfferBubbleColorAndValue(date, shift, offer);
    };

    const createShift = (shift: BaseInquiryShiftsWithFlexType, shiftIndex: number, dateIndex: number): any => {
      const bubbleColorAndValue = getCustomerOfferBubbleColorAndValue(dates[dateIndex], shift);

      return {
        shift,
        index: shiftIndex,
        color: bubbleColorAndValue.color,
        currentValue: bubbleColorAndValue.value,
        showDot: false,
        class: `position-relative`,
      };
    };

    dates.forEach((date, dateIndex) => {
      const shifts: BaseCustomerInquiryOfferShiftInterface[] = BASE_INQUIRY_CALENDAR_ORDERED_SHIFTS.map(
        (shift, shiftIndex) => createShift(shift, shiftIndex, dateIndex)
      );

      let inquiryDate: BaseCustomerInquiryOfferDateInterface = {
        date,
        isToday: BaseDateService.isToday(new Date(date)),
        isWeekend: BaseDateService.isWeekendDay(new Date(date)),
        shifts,
      };

      inquiryDate.shifts.map((item) => {
        item.showDot = this.isCustomerBubbleDotVisible(inquiryDate.shifts, item.shift as BaseInquiryShiftsWithFlexType);
        item.isFlexibleShift = inquiryDate.shifts[0].currentValue === OfferBubbleValues.OfferedByServiceProvider;
        if (item.color) {
          item.class += ` ${item.color}`;
        }
        if (item.currentValue && !item.showDot) {
          item.class += ` bubble`;
        }
        if (!item.currentValue) {
          item.class += ` empty-bubble`;
        }
        if (item.showDot) {
          item.class += ` bubble-dot`;
        }
      });
      datesArray.push(inquiryDate);
    });

    return datesArray;
  }

  private setCustomerOfferBubbleColorAndValue(
    currentDate: string,
    currentShift: BaseInquiryShiftsWithFlexType,
    offer: CustomerInquiryOfferListItemResponse
  ): BaseCustomerInquiryBubbleColorAndValue {
    const defaultColorAndValue = {
      color: OfferBubbleColors.Default,
      value: OfferBubbleValues.Default,
    };
    let positionExist: OfferPositionResponse | undefined;

    if (currentShift === BaseInquiryShiftsWithFlex.FLEX) {
      positionExist = offer.positions?.find(
        (inquiryDate: any) => inquiryDate.date === currentDate && inquiryDate?.flexShift === true
      );
    } else {
      positionExist = offer.positions?.find(
        (inquiryDate: OfferPositionResponse) =>
          inquiryDate.date === currentDate &&
          inquiryDate[BaseInquiryShiftsUpperCase[currentShift]] &&
          (offer.status !== BaseOfferStatus.confirmed ||
            (offer.status === BaseOfferStatus.confirmed &&
              !inquiryDate.flexShift &&
              inquiryDate.selectedShift === currentShift.toLowerCase()) ||
            (offer.status === BaseOfferStatus.confirmed && inquiryDate.flexShift))
      );
    }

    if (positionExist && offer.status === BaseOfferStatus.open) {
      return {
        color: OfferBubbleColors.LightGrey,
        value: OfferBubbleValues.OfferedByServiceProvider,
      };
    }

    if (positionExist && positionExist.selectedShift && offer.status === BaseOfferStatus.confirmed) {
      return {
        color: OfferBubbleColors.LightBlueFramed,
        value: OfferBubbleValues.Booked,
      };
    }

    if (
      (positionExist &&
        !!positionExist.selectedShift &&
        !positionExist.flexShift &&
        ((positionExist.selectedShift === currentShift && offer.status === BaseOfferStatus.needsConfirmation) ||
          (!positionExist.selectedShift && offer.status === BaseOfferStatus.needsUpdate))) ||
      (positionExist &&
        !!positionExist.selectedShift &&
        ((positionExist.flexShift && offer.status === BaseOfferStatus.needsConfirmation) ||
          (!positionExist.selectedShift && offer.status === BaseOfferStatus.needsUpdate)))
    ) {
      return {
        color: OfferBubbleColors.Yellow,
        value: OfferBubbleValues.NeedsConfirmation,
      };
    }

    if (positionExist && offer.status === BaseOfferStatus.open) {
      return {
        color: OfferBubbleColors.LightGrey,
        value: OfferBubbleValues.OfferedByServiceProvider,
      };
    }

    if (positionExist && offer.status === BaseOfferStatus.declined) {
      return {
        color: OfferBubbleColors.RedFramed,
        value: OfferBubbleValues.Rejected,
      };
    }

    return defaultColorAndValue;
  }

  isCustomerBubbleDotVisible(
    shifts: BaseCustomerInquiryOfferShiftInterface[],
    shiftType: BaseInquiryShiftsWithFlexType
  ): boolean {
    const currentShift = shifts.find((item) => item.shift === shiftType);
    if (!currentShift?.index) {
      return false;
    }
    const bubblesToCheck = shifts.filter((item) => (item.index as number) < (currentShift.index as number));
    return (
      bubblesToCheck.some((bubble) => bubble.currentValue === currentShift.currentValue) ||
      (shiftType !== BaseInquiryShiftsWithFlex.FLEX && shifts.every((item) => item.isFlexibleShift))
    );
  }

  private updateFlexShifts(offer: BaseCustomerInquiryOfferInterface): BaseCustomerInquiryOfferInterface {
    let flexItemsToSelect = 0;
    let flexItemsToDeselect = 0;
    const flexShiftDates = offer.dates.filter((dateItem) => dateItem.shifts.every((shift) => shift.isFlexibleShift));
    const areAllFlexShiftsSelected = flexShiftDates.every((dateItem) =>
      dateItem.shifts.every(
        (shift) =>
          (shift.currentValue === OfferBubbleValues.OfferedByServiceProvider && shift.isSelected) ||
          shift.currentValue !== OfferBubbleValues.OfferedByServiceProvider
      )
    );
    if (areAllFlexShiftsSelected) {
      flexItemsToDeselect = flexShiftDates.length;
    } else {
      flexItemsToSelect =
        flexShiftDates.length -
        flexShiftDates.filter(
          (dateItem) =>
            // Check only flex shift (dateItem.shifts[0]) here to calculate number for notification message
            dateItem.shifts[0].currentValue === OfferBubbleValues.OfferedByServiceProvider &&
            dateItem.shifts[0].isSelected
        ).length;
    }

    let updatedOffer = { ...offer };
    updatedOffer.dates = offer.dates.map((dateItem) => ({
      ...dateItem,
      shifts: dateItem.shifts.map((shiftItem) => {
        const isAvailableShift =
          shiftItem.currentValue === OfferBubbleValues.OfferedByServiceProvider && shiftItem.isFlexibleShift;

        const isSelected =
          shiftItem.currentValue === OfferBubbleValues.OfferedByServiceProvider
            ? !areAllFlexShiftsSelected
            : shiftItem.isSelected;

        const selectedClass =
          shiftItem.shift === BaseInquiryShiftsWithFlex.FLEX
            ? BaseCustomerOfferBubbleClassesEnum.OfferedSelected
            : BaseCustomerOfferBubbleClassesEnum.OfferedSelectedDot;

        const deselectedClass =
          shiftItem.shift === BaseInquiryShiftsWithFlex.FLEX
            ? BaseCustomerOfferBubbleClassesEnum.OfferedAvailableForSelection
            : BaseCustomerOfferBubbleClassesEnum.OfferedAvailableForSelectionDot;

        const selectedColor = OfferBubbleColors.GreenFramed;

        const deselectedColor = OfferBubbleColors.LightGrey;

        return isAvailableShift
          ? {
              ...shiftItem,
              isSelected,
              class: isSelected ? selectedClass : deselectedClass,
              color: isSelected ? selectedColor : deselectedColor,
            }
          : shiftItem;
      }),
    }));

    if (flexItemsToSelect > 0 || flexItemsToDeselect > 0) {
      this.store.dispatch(
        baseSharedActions.addSystemAlert({
          payload: {
            class: BaseToastAlertClasses.success,
            body:
              flexItemsToSelect > 0
                ? 'inquiry.fixedShiftsSelectNotification'
                : 'inquiry.fixedShiftsDeselectNotification',
            bodyTranslateParams: {
              quantity: flexItemsToSelect > 0 ? flexItemsToSelect : flexItemsToDeselect,
              shift: this.translateService.instant('shiftTimes.flex'),
            },
          },
        })
      );
    }

    return updatedOffer;
  }

  private updateFixedShifts(
    offer: BaseCustomerInquiryOfferInterface,
    shiftType: BaseInquiryShiftsWithFlex
  ): BaseCustomerInquiryOfferInterface {
    let fixedItemsToSelect = 0;
    let fixedItemsToDeselect = 0;
    const availableDatesByShiftType = offer.dates.filter((dateItem) =>
      dateItem.shifts.some(
        (shiftItem) =>
          !shiftItem.isFlexibleShift &&
          shiftItem.shift === shiftType &&
          shiftItem.currentValue === OfferBubbleValues.OfferedByServiceProvider
      )
    );

    const areAllFixedShiftsForTypeSelected = availableDatesByShiftType.every((dateItem) => {
      return !!dateItem.shifts.find((item) => item.shift === shiftType)?.isSelected;
    });

    if (areAllFixedShiftsForTypeSelected) {
      fixedItemsToDeselect = availableDatesByShiftType.length;
    } else {
      fixedItemsToSelect = availableDatesByShiftType.filter((dateItem) => {
        return !!dateItem.shifts.find(
          (item) =>
            item.shift === shiftType &&
            item.currentValue === OfferBubbleValues.OfferedByServiceProvider &&
            !item.isSelected
        );
      }).length;
    }

    let updatedOffer = { ...offer };
    updatedOffer.dates = offer.dates.map((dateItem) => ({
      ...dateItem,
      shifts: dateItem.shifts.map((shiftItem) => {
        const isAvailableFixedShiftForType =
          !shiftItem.isFlexibleShift &&
          shiftItem.currentValue === OfferBubbleValues.OfferedByServiceProvider &&
          shiftItem.shift === shiftType;
        const isSelected =
          shiftItem.shift === shiftType && shiftItem.currentValue === OfferBubbleValues.OfferedByServiceProvider
            ? !areAllFixedShiftsForTypeSelected
            : shiftItem.isSelected;

        const selectedClass = BaseCustomerOfferBubbleClassesEnum.OfferedSelected;
        const deselectedClass = BaseCustomerOfferBubbleClassesEnum.OfferedAvailableForSelection;

        return isAvailableFixedShiftForType
          ? {
              ...shiftItem,
              isSelected,
              class: isSelected ? selectedClass : deselectedClass,
              color: isSelected ? OfferBubbleColors.GreenFramed : OfferBubbleColors.LightGrey,
            }
          : shiftItem;
      }),
    }));

    if (fixedItemsToSelect > 0 || fixedItemsToDeselect > 0) {
      this.store.dispatch(
        baseSharedActions.addSystemAlert({
          payload: {
            class: BaseToastAlertClasses.success,
            body: fixedItemsToSelect > 0 ? 'inquiry.shiftSelectNotification' : 'inquiry.shiftDeselectNotification',
            bodyTranslateParams: {
              quantity: fixedItemsToSelect > 0 ? fixedItemsToSelect : fixedItemsToDeselect,
              shift: this.translateService.instant(`shiftTimes.labelLong.${shiftType}`),
            },
          },
        })
      );
    }

    return updatedOffer;
  }

  private updateAllSelectedShifts(
    offer: BaseCustomerInquiryOfferInterface,
    isShiftsDeselectionMode: boolean
  ): BaseCustomerInquiryOfferInterface {
    let updatedOffer = { ...offer };
    updatedOffer.dates = offer.dates.map((dateItem) => ({
      ...dateItem,
      shifts: dateItem.shifts.map((shiftItem) => {
        const isAvailableShift = shiftItem.currentValue === OfferBubbleValues.OfferedByServiceProvider;
        const isSelected = isAvailableShift ? !isShiftsDeselectionMode : shiftItem.isSelected;
        const selectedColor = OfferBubbleColors.GreenFramed;
        const deselectedColor = OfferBubbleColors.LightGrey;

        const selectedClass =
          (shiftItem.isFlexibleShift && shiftItem.shift === BaseInquiryShiftsWithFlex.FLEX) ||
          !shiftItem.isFlexibleShift
            ? BaseCustomerOfferBubbleClassesEnum.OfferedSelected
            : BaseCustomerOfferBubbleClassesEnum.OfferedSelectedDot;

        const deselectedClass =
          (shiftItem.isFlexibleShift && shiftItem.shift === BaseInquiryShiftsWithFlex.FLEX) ||
          !shiftItem.isFlexibleShift
            ? BaseCustomerOfferBubbleClassesEnum.OfferedAvailableForSelection
            : BaseCustomerOfferBubbleClassesEnum.OfferedAvailableForSelectionDot;

        return isAvailableShift
          ? {
              ...shiftItem,
              isSelected,
              class: isSelected ? selectedClass : deselectedClass,
              color: isSelected ? selectedColor : deselectedColor,
            }
          : shiftItem;
      }),
    }));

    return updatedOffer;
  }

  private updateShift(
    shiftItem: BaseCustomerInquiryOfferShiftInterface,
    selectedShift: BaseCustomerInquiryOfferShiftInterface
  ): BaseCustomerInquiryOfferShiftInterface {
    const isOfferedShift =
      shiftItem.shift === selectedShift.shift ||
      (selectedShift.isFlexibleShift &&
        shiftItem.shift !== selectedShift.shift &&
        shiftItem.currentValue === OfferBubbleValues.OfferedByServiceProvider);

    const selectedFlexClass =
      shiftItem.shift === BaseInquiryShiftsWithFlex.FLEX
        ? BaseCustomerOfferBubbleClassesEnum.OfferedSelected
        : BaseCustomerOfferBubbleClassesEnum.OfferedSelectedDot;

    const deselectedFlexClass =
      shiftItem.shift === BaseInquiryShiftsWithFlex.FLEX
        ? BaseCustomerOfferBubbleClassesEnum.OfferedAvailableForSelection
        : BaseCustomerOfferBubbleClassesEnum.OfferedAvailableForSelectionDot;

    let updatedColor =
      shiftItem.isSelected && shiftItem.shift === selectedShift.shift
        ? OfferBubbleColors.LightGrey
        : !shiftItem.isSelected && shiftItem.shift === selectedShift.shift
        ? OfferBubbleColors.GreenFramed
        : shiftItem.color;

    let updatedClass: string = '';

    if (shiftItem.isFlexibleShift) {
      updatedClass = !isOfferedShift ? shiftItem.class : shiftItem.isSelected ? deselectedFlexClass : selectedFlexClass;
      updatedColor = !isOfferedShift
        ? shiftItem.color
        : shiftItem.isSelected
        ? OfferBubbleColors.LightGrey
        : OfferBubbleColors.GreenFramed;
    } else {
      updatedClass =
        shiftItem.isSelected && shiftItem.shift === selectedShift.shift
          ? BaseCustomerOfferBubbleClassesEnum.OfferedAvailableForSelection
          : !shiftItem.isSelected && shiftItem.shift === selectedShift.shift
          ? BaseCustomerOfferBubbleClassesEnum.OfferedSelected
          : shiftItem.class;
    }

    return {
      ...shiftItem,
      isSelected: updatedColor === OfferBubbleColors.GreenFramed,
      color: updatedColor,
      class: updatedClass,
    };
  }

  private selectOfferInTheList(
    offers: BaseCustomerInquiryOfferInterface[],
    offerId: number
  ): BaseCustomerInquiryOfferInterface[] {
    return offers.map((offer: BaseCustomerInquiryOfferInterface) => {
      return {
        data: {
          ...offer.data,
          isSelected: offer.data.id === offerId ? !offer.data.isSelected : false,
        },
        dates: offer.dates,
      };
    });
  }

  private updateShiftsDuringOfferSelection(
    offers: BaseCustomerInquiryOfferInterface[]
  ): BaseCustomerInquiryOfferInterface[] {
    return offers.map((offerInfo) => {
      return {
        data: offerInfo.data,
        dates: offerInfo.dates.map((date: BaseCustomerInquiryOfferDateInterface) => {
          return {
            ...date,
            shifts: date.shifts.map((shiftItem: BaseCustomerInquiryOfferShiftInterface) => {
              const isOfferSelected = offerInfo.data.isSelected;
              const isDotVisible = this.isCustomerBubbleDotVisible(
                date.shifts,
                shiftItem.shift as BaseInquiryShiftsWithFlexType
              );
              let selectedOfferAvailableBubbleClass =
                shiftItem.isFlexibleShift && shiftItem.shift !== BaseInquiryShiftsWithFlex.FLEX
                  ? BaseCustomerOfferBubbleClassesEnum.OfferedSelectedDot
                  : BaseCustomerOfferBubbleClassesEnum.OfferedSelected;

              let deselectedOfferAvailableBubbleClass = isDotVisible
                ? BaseCustomerOfferBubbleClassesEnum.OfferedDeselectedDot
                : BaseCustomerOfferBubbleClassesEnum.OfferedDeselected;

              return shiftItem.currentValue === OfferBubbleValues.OfferedByServiceProvider
                ? {
                    ...shiftItem,
                    showDot: isOfferSelected && !shiftItem.isFlexibleShift ? false : isDotVisible,
                    color: isOfferSelected ? OfferBubbleColors.GreenFramed : OfferBubbleColors.LightGrey,
                    class: isOfferSelected ? selectedOfferAvailableBubbleClass : deselectedOfferAvailableBubbleClass,
                    isSelected: isOfferSelected,
                  }
                : shiftItem;
            }),
          };
        }),
      };
    });
  }
}
