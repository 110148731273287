import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { baseSharedActions, baseSharedSelectors, BaseCoreState } from '../../_store';

@Injectable()
export class BaseQualificationCategoriesGuard {
  constructor(private readonly store: Store<BaseCoreState>) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    this.store
      .select(baseSharedSelectors.getQualificationCategories)
      .pipe(
        take(1),
        map((result) => result.data)
      )
      .subscribe((qualificationCategories) => {
        if (!qualificationCategories) {
          this.store.dispatch(baseSharedActions.getQualificationCategories({ application: route.data.application }));
        }
      });

    return this.store.select(baseSharedSelectors.getQualificationCategories).pipe(
      filter((result) => result.ok !== undefined),
      map((result) => !!result.ok)
    );
  }
}
