import {
  CdkFixedSizeVirtualScroll,
  CdkVirtualForOf,
  CdkVirtualScrollViewport,
  ScrollingModule,
} from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { ImageCropperModule } from 'ngx-image-cropper';
import { baseSharedReducer } from '../_store/reducers';
import { baseSharedEffects } from '../_store/effects';
import { BaseLocalStorageService } from '../core/services/base-local-storage.service';
import { BaseAvatarComponent } from './components/avatar/base-avatar.component';
import { BaseSignContractsInfoCardComponent } from './components/base-assignment-sign-info-card/base-sign-contracts-info-card.component';
import { BaseSignContractsLoginComponent } from './components/base-signature-login/base-sign-contracts-login.component';
import { BaseSignContractsSignComponent } from './components/base-signature-sign/base-sign-contracts-sign.component';
import { BaseToastAlertItemComponent } from './components/base-toast-alert-item/base-toast-alert-item.component';
import { BaseToastAlertsComponent } from './components/base-toast-alerts/base-toast-alerts.component';
import { BaseUpToDateComponent } from './components/base-up-to-date/base-up-to-date.component';
import { BaseButtonComponent } from './components/button/base-button.component';
import { BaseCandidateListProfileComponent } from './components/candidate-list-profile/base-candidate-list-profile.component';
import { BaseCheckboxWeekdaysComponent } from './components/checkbox-weekdays/base-checkbox-weekdays.component';
import { BaseCheckboxComponent } from './components/checkbox/base-checkbox.component';
import { BaseDatepickerComponent } from './components/datepicker/base-datepicker.component';
import { BaseCustomDatepickerI18n } from './components/datepicker/base-datepicker.i18n';
import { BaseDocumentInfoComponent } from './components/document-info/base-document-info.component';
import { BaseDocumentUploadComponent } from './components/document-upload/base-document-upload.component';
import { BaseActionDropdownComponent } from './components/dropdowns/action-dropdown/base-action-dropdown.component';
import { BaseCountryDropdownComponent } from './components/dropdowns/country-dropdown/base-country-dropdown.component';
import { BaseFilterDropdownComponent } from './components/dropdowns/filter-dropdown/base-filter-dropdown.component';
import { BaseDropdownComponent } from './components/dropdowns/base-dropdown/base-dropdown.component';
import { BaseFilterButtonsComponent } from './components/filter-buttons/base-filter-buttons.component';
import { BaseFormFieldPreviewComponent } from './components/form-field-preview/base-form-field-preview.component';
import { BaseImageCropperComponent } from './components/image-cropper/base-image-cropper.component';
import { BaseInputComponent } from './components/input/base-input.component';
import { BaseLanguageSwitchComponent } from './components/language-switch/base-language-switch.component';
import { BaseModalComponent } from './components/modal/base-modal.component';
import { BaseModalBodyComponent } from './components/modal/modal-body/base-modal-body.component';
import { BasePaginationComponent } from './components/pagination/base-pagination.component';
import { BasePasswordComponent } from './components/password/base-password.component';
import { BaseRadiobuttonComponent } from './components/radiobutton/base-radiobutton.component';
import { BaseRangeDatepickerComponent } from './components/range-datepicker/range-datepicker.component';
import { BaseRatingComponent } from './components/rating/base-rating.component';
import { BaseSearchComponent } from './components/search/base-search.component';
import { BaseSignContractSelectionComponent } from './components/sign-contract-selection/base-sign-contract-selection.component';
import { BaseSpecialButtonComponent } from './components/special-button/base-special-button.component';
import { BaseSwitchComponent } from './components/switch/base-switch.component';
import { BaseTextareaComponent } from './components/textarea/base-textarea.component';
import { BaseInfiniteScrollComponent } from './components/infinite-scroll/base-infinite-scroll.component';
import { BaseClickOutsideDirective } from './directives/base-click-outside.directive';
import { BaseCollapsableContentDirective } from './directives/base-collapsable-content.directive';
import { BaseImageFallbackDirective } from './directives/base-image-fallback.directive';
import { BaseIsStickyDirective } from './directives/base-is-sticky.directive';
import { BaseTextTruncateDirective } from './directives/base-text-truncate.directive';
import { BaseMaskDateDirective } from './directives/date-mask.directive';
import { BaseBranchesOrDepartmentsGuard } from './guards/base-branches-or-departments.guard';
import { BaseBranchesGuard } from './guards/base-branches.guard';
import { BaseClearSharedStateGuard } from './guards/base-clear-shared-state.guard';
import { BaseCountryListGuard } from './guards/base-country-list-guard.service';
import { BaseDefaultShiftTimesGuard } from './guards/base-default-shift-times.guard';
import { BaseQualificationCategoriesGuard } from './guards/base-qualification-categories.guard';
import { BaseQualificationsGuard } from './guards/base-qualifications.guard';
import { BaseRequirementsGuard } from './guards/base-requirements.guard';
import { BaseCountryPreviewValuePipe } from './pipes/base-country-preview-value.pipe';
import { BaseDateToMonthNamePipe } from './pipes/base-date-to-month-name.pipe';
import { BaseDaysDifferencePipe } from './pipes/base-days-difference.pipe';
import { BaseFieldValidationPipe } from './pipes/base-field-validation.pipe';
import { FilesizePipe } from './pipes/base-filesize.pipe';
import { BaseFilterPipe } from './pipes/base-filter.pipe';
import { BaseFormControlCastPipe } from './pipes/base-form-control-cast.pipe';
import { BaseFormatDatePipe } from './pipes/base-format-date.pipe';
import { BaseFormatDecimalPipe } from './pipes/base-format-decimal.pipe';
import { BaseFormatPricePipe } from './pipes/base-format-price.pipe';
import { BaseFormatTimePipe } from './pipes/base-format-time.pipe';
import { BaseIsEmptyPipe } from './pipes/base-is-empty.pipe';
import { BaseIsWeekendPipe } from './pipes/base-is-weekend.pipe';
import { BaseLongNamePipe } from './pipes/base-long-name.pipe';
import { RelativeDateTimePipe } from './pipes/base-relative-date-time.pipe';
import { BaseBusinessKeyPipe } from './pipes/base-business-key.pipe';
import { BaseCountryService } from './services/base-country.service';
import { BaseDateService } from './services/base-date.service';
import { BaseEmployeeOperationService } from './services/base-employee-operation.service';
import { BaseEnvironmenter } from './services/base-environmenter.service';
import { BaseFilterService } from './services/base-filter.service';
import { BaseModalService } from './services/base-modal.service';
import { BaseQueryParamService } from './services/base-query-param.service';
import { BaseSharedFormBuilderService } from './services/base-shared-form-builder.service';
import { BaseSharedService } from './services/base-shared.service';
import { BaseSignatureService } from './services/base-signature.service';
import { BaseUnsavedChangesService } from './services/base-unsaved-changes.service';
import { BaseSpinnerComponent } from './system/spinner/base-spinner.component';
import { BaseSpinnerService } from './system/spinner/base-spinner.service';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { BaseDynamicFontSizeDirective } from './directives/base-dynamic-font-size.directive';
import { BaseRemoveMailtoDirective } from './directives/base-remove-mailto.directive';
import { BaseDocumentViewerComponent } from './components/document-viewer/_components/document-viewer/base-document-viewer.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { BaseHoursToDaysPipe } from './pipes/base-hours-to-days.pipe';
import { BaseDepartmentQualificationsByCategoryPipe } from './pipes/base-department-qualifications-by-category.pipe';
import { BaseAnonymizeFullNamePipe } from './pipes/base-anonymize-full-name.pipe';
import { BaseNamirialRegistrationModalComponent } from './components/base-namirial-registration-modal/base-namirial-registration-modal.component';
import { BaseMinutesToHoursPipe } from './pipes/base-minutes-to-hours.pipe';
import { CustomLabelComponent } from './components/dropdowns/base-dropdown/custom-label/custom-label.component';
import { BaseDeviceService } from './services/base-device.service';
import { BaseScrollToInvalidDirective } from './directives/base-scroll-to-invalid.directive';
import { BaseProfileGuard } from './guards/base-profile.guard';
import { BaseDocumentViewerToolbarComponent } from './components/document-viewer/_components/document-viewer-toolbar/base-document-viewer-toolbar.component';
import { BaseContractViewerToolbarComponent } from './components/document-viewer/_components/contract-viewer/contract-viewer-toolbar/base-contract-viewer-toolbar.component';
import { BaseCustomerContractDropdownComponent } from './components/dropdowns/customer-contract-dropdown/base-customer-contract-dropdown.component';
import { BaseContractViewerComponent } from './components/document-viewer/_components/contract-viewer/base-contract-viewer.component';
import { BasePdfViewerComponent } from './components/document-viewer/base-pdf-viewer.component';
import { BaseImageViewerComponent } from './components/document-viewer/_components/image-viewer/base-image-viewer.component';
import { BaseShiftCheckExistingPipe } from './pipes/base-shift-check-existing.pipe';
import { BaseHorizontalScrollDirective } from './directives/base-horizontal-scroll.directive';
import { BaseMinuteInputDirective } from './directives/base-minute-input.directive';
import { BaseShiftRadiobuttonComponent } from './components/base-shift-radiobutton/base-shift-radiobutton.component';
import { BaseBackButtonDirective } from './directives/base-back-button.directive';
import { BaseOrdinalSuffixPipe } from './pipes/base-ordinal-suffix.pipe';
import { BaseExpansionPanelComponent } from './components/base-expansion-panel/base-expansion-panel.component';
import { BasePspInquiryListGuard } from './guards/base-psp-inquiry-list.guard';
import { BaseCustomerInquiryListGuard } from './guards/base-customer-inquiry-list.guard';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    RouterModule,
    TranslateModule,
    StoreModule.forFeature('shared', baseSharedReducer.baseSharedReducer),
    EffectsModule.forFeature([baseSharedEffects.BaseSharedEffects]),
    ScrollingModule,
    LoadingBarModule,
    ImageCropperModule,
    NgSelectModule,
    NgbTooltipModule,
    InfiniteScrollModule,
    NgxExtendedPdfViewerModule,
  ],
  declarations: [
    BaseAvatarComponent,
    BaseModalComponent,
    BaseButtonComponent,
    BaseCheckboxWeekdaysComponent,
    BaseCheckboxComponent,
    BaseDatepickerComponent,
    BaseDocumentUploadComponent,
    BaseDocumentInfoComponent,
    BaseFilterButtonsComponent,
    BaseFilterDropdownComponent,
    BaseCustomerContractDropdownComponent,
    BaseFormFieldPreviewComponent,
    BaseImageCropperComponent,
    BaseInputComponent,
    BasePaginationComponent,
    BasePasswordComponent,
    BaseDocumentViewerComponent,
    BaseDocumentViewerToolbarComponent,
    BaseContractViewerToolbarComponent,
    BaseContractViewerComponent,
    BasePdfViewerComponent,
    BaseImageViewerComponent,
    BaseRadiobuttonComponent,
    BaseSearchComponent,
    BaseSpecialButtonComponent,
    BaseSwitchComponent,
    BaseTextareaComponent,
    BaseModalBodyComponent,
    BaseClickOutsideDirective,
    BaseTextTruncateDirective,
    BaseMaskDateDirective,
    BaseRemoveMailtoDirective,
    BaseScrollToInvalidDirective,
    BaseSpinnerComponent,
    BaseFormatDecimalPipe,
    BaseFormatPricePipe,
    BaseFieldValidationPipe,
    BaseFilterPipe,
    BaseFormatDatePipe,
    BaseFormControlCastPipe,
    BaseLongNamePipe,
    BaseDateToMonthNamePipe,
    BaseIsStickyDirective,
    BaseFormatTimePipe,
    BaseDaysDifferencePipe,
    BaseIsWeekendPipe,
    RelativeDateTimePipe,
    BaseLanguageSwitchComponent,
    BaseSignContractsLoginComponent,
    BaseSignContractsInfoCardComponent,
    BaseSignContractSelectionComponent,
    BaseSignContractsSignComponent,
    BaseToastAlertsComponent,
    BaseToastAlertItemComponent,
    BaseCandidateListProfileComponent,
    BaseRatingComponent,
    FilesizePipe,
    BaseCollapsableContentDirective,
    BaseIsEmptyPipe,
    BaseRangeDatepickerComponent,
    BaseButtonComponent,
    BaseCountryPreviewValuePipe,
    BaseUpToDateComponent,
    BaseImageFallbackDirective,
    BaseDropdownComponent,
    BaseActionDropdownComponent,
    BaseCountryDropdownComponent,
    CustomLabelComponent,
    BaseInfiniteScrollComponent,
    BaseDynamicFontSizeDirective,
    BaseHoursToDaysPipe,
    BaseDepartmentQualificationsByCategoryPipe,
    BaseAnonymizeFullNamePipe,
    BaseNamirialRegistrationModalComponent,
    BaseMinutesToHoursPipe,
    BaseShiftCheckExistingPipe,
    BaseBusinessKeyPipe,
    BaseHorizontalScrollDirective,
    BaseMinuteInputDirective,
    BaseShiftRadiobuttonComponent,
    BaseBackButtonDirective,
    BaseOrdinalSuffixPipe,
    BaseExpansionPanelComponent,
  ],
  providers: [
    BaseEnvironmenter,
    BaseCountryService,
    BaseDateService,
    BaseModalService,
    BaseSharedFormBuilderService,
    BaseSharedService,
    BaseUnsavedChangesService,
    BaseCountryListGuard,
    BaseFormatDatePipe,
    BaseFormatPricePipe,
    BaseFormatDecimalPipe,
    BaseFormatTimePipe,
    BaseDaysDifferencePipe,
    RelativeDateTimePipe,
    BaseClearSharedStateGuard,
    BaseRequirementsGuard,
    BaseBranchesGuard,
    BaseBranchesOrDepartmentsGuard,
    BaseDefaultShiftTimesGuard,
    BaseQualificationsGuard,
    BaseQualificationCategoriesGuard,
    BaseCustomDatepickerI18n,
    BaseIsWeekendPipe,
    BaseLocalStorageService,
    BaseSignatureService,
    TranslatePipe,
    BaseQueryParamService,
    BaseFilterService,
    BaseEmployeeOperationService,
    BaseDeviceService,
    BaseProfileGuard,
    BaseBusinessKeyPipe,
    BasePspInquiryListGuard,
    BaseCustomerInquiryListGuard,
  ],
  exports: [
    BaseAvatarComponent,
    BaseModalComponent,
    BaseButtonComponent,
    BaseCheckboxWeekdaysComponent,
    BaseCheckboxComponent,
    BaseDatepickerComponent,
    BaseDocumentUploadComponent,
    BaseDocumentInfoComponent,
    BaseFilterButtonsComponent,
    BaseFilterDropdownComponent,
    BaseFormFieldPreviewComponent,
    BaseImageCropperComponent,
    BaseInputComponent,
    BasePaginationComponent,
    BasePasswordComponent,
    BaseDocumentViewerComponent,
    BaseContractViewerComponent,
    BasePdfViewerComponent,
    BaseRadiobuttonComponent,
    BaseSearchComponent,
    BaseSpecialButtonComponent,
    BaseSwitchComponent,
    BaseTextareaComponent,
    BaseModalBodyComponent,
    BaseSpinnerComponent,
    BaseClickOutsideDirective,
    BaseFormatPricePipe,
    BaseFormatDecimalPipe,
    BaseFieldValidationPipe,
    BaseFilterPipe,
    BaseFormatDatePipe,
    BaseFormControlCastPipe,
    BaseLongNamePipe,
    BaseIsStickyDirective,
    BaseDateToMonthNamePipe,
    BaseTextTruncateDirective,
    BaseDateToMonthNamePipe,
    BaseFormatTimePipe,
    BaseDaysDifferencePipe,
    BaseIsWeekendPipe,
    BaseLanguageSwitchComponent,
    BaseSignContractSelectionComponent,
    BaseSignContractsSignComponent,
    BaseClickOutsideDirective,
    BaseToastAlertsComponent,
    BaseToastAlertItemComponent,
    BaseSignContractsLoginComponent,
    BaseSignContractsInfoCardComponent,
    BaseCandidateListProfileComponent,
    TranslatePipe,
    BaseRatingComponent,
    FilesizePipe,
    BaseCollapsableContentDirective,
    BaseIsEmptyPipe,
    RelativeDateTimePipe,
    BaseRangeDatepickerComponent,
    BaseCountryPreviewValuePipe,
    BaseUpToDateComponent,
    BaseImageFallbackDirective,
    BaseDropdownComponent,
    BaseActionDropdownComponent,
    BaseCountryDropdownComponent,
    CustomLabelComponent,
    BaseInfiniteScrollComponent,
    BaseDynamicFontSizeDirective,
    BaseHoursToDaysPipe,
    BaseDepartmentQualificationsByCategoryPipe,
    BaseAnonymizeFullNamePipe,
    BaseNamirialRegistrationModalComponent,
    BaseMinutesToHoursPipe,
    BaseShiftCheckExistingPipe,
    BaseScrollToInvalidDirective,
    BaseBusinessKeyPipe,
    CdkFixedSizeVirtualScroll,
    CdkVirtualForOf,
    CdkVirtualScrollViewport,
    BaseHorizontalScrollDirective,
    BaseMinuteInputDirective,
    BaseShiftRadiobuttonComponent,
    BaseBackButtonDirective,
    BaseOrdinalSuffixPipe,
    BaseExpansionPanelComponent,
  ],
})
export class BaseSharedModule {
  static forRoot(): ModuleWithProviders<BaseSharedModule> {
    return {
      ngModule: BaseSharedModule,
      providers: [BaseSpinnerService, BaseCountryListGuard],
    };
  }
}
