import { Injectable } from '@angular/core';
import {
  AssignmentCustomerResponse,
  AssignmentDateListItemResponse,
  AssignmentPspResponse,
  CancelationRequest,
  CandidateProfileService,
  ContractRequest,
  CreatedResponse,
  CustomerAssignmentListItemResponse,
  CustomerAssignmentService,
  CustomerProfileAssignmentItemResponse,
  CustomerProfilePersonalIdRequest,
  CustomerProfileRatingCreateRequest,
  CustomerProfileRatingListResponse,
  CustomerProfileRatingUpdateRequest,
  CustomerResponse,
  CustomerService,
  EmptySuccessfulResponse,
  GeneralApiResponse,
  ListApiResponse,
  PaginatedApiResponse,
  PspAssignmentListItemResponse,
  PspAssignmentService,
  PspCustomerService,
  SigningService,
  CustomerSignVoucherResponse,
  UpdatedResponse,
  UpdateWorkTimesRequest,
  PspSignVoucherResponse,
} from '../../../api';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { BaseSharedHelperService } from '../../shared/services/base-shared-helper.service';
import { ExportCustomerContractsRequestInterface } from './_interfaces/export-customer-contracts-request.interface';
import { ExportPspContractsRequestInterface } from './_interfaces/export-psp-contracts-request.interface';
import { HttpContext } from '@angular/common/http';
import { BaseOrderDirectionEnum } from '../../shared/enums/base-order-direction.enum';
import { BaseOrderKeyEnum } from '../../shared/enums/base-order-key.enum';
import { ExportPspAssignmentInformationRequestInterface } from './_interfaces/export-psp-assignment-information-request.interface';
import { ExportCustomerAssignmentInformationRequestInterface } from './_interfaces/export-customer-assignment-information-request.interface';

@Injectable()
export class BaseAssignmentsService {
  constructor(
    private pspAssignmentService: PspAssignmentService,
    private customerAssignmentService: CustomerAssignmentService,
    private candidateProfileService: CandidateProfileService,
    private customerService: CustomerService,
    private pspCustomerService: PspCustomerService,
    private signingService: SigningService
  ) {}

  getPspAllAssignments(
    psp: number,
    page?: number,
    size?: number,
    filters?: any,
    search?: string,
    orderKey?: string,
    orderDirection?: BaseOrderDirectionEnum
  ): Observable<PaginatedApiResponse & { data?: Array<PspAssignmentListItemResponse> }> {
    return this.pspAssignmentService.getPspAssignmentListAll({
      psp,
      page,
      ...filters,
      search,
      orderKey,
      orderDirection,
    });
  }

  getAllCustomerAssignments(
    customer: number,
    page?: number,
    filters?: any,
    search?: string,
    orderKey?: BaseOrderKeyEnum,
    orderDirection?: BaseOrderDirectionEnum,
    size?: number
  ): Observable<PaginatedApiResponse & { data?: Array<CustomerAssignmentListItemResponse> }> {
    return this.customerAssignmentService.getCustomerAssignmentList({
      page,
      ...filters,
      search,
      customer,
      orderKey,
      orderDirection,
      size,
    });
  }

  exportCustomerContracts(params: ExportCustomerContractsRequestInterface): Observable<any> {
    return this.customerAssignmentService.getCustomerContractExport$Response(params).pipe(
      map((response) => {
        const contentDispositionValue = response.headers.get('Content-Disposition');
        const fileName = BaseSharedHelperService.fileNameFromHeaderValue(contentDispositionValue, '=');
        return {
          body: response.body,
          fileName,
        };
      })
    );
  }

  exportPspContracts(params: ExportPspContractsRequestInterface): Observable<any> {
    return this.candidateProfileService.getPspContractExport$Response(params).pipe(
      map((response) => {
        const contentDispositionValue = response.headers.get('Content-Disposition');
        const fileName = BaseSharedHelperService.fileNameFromHeaderValue(contentDispositionValue, '=');
        return {
          body: response.body,
          fileName,
        };
      })
    );
  }

  getPspAssignment(psp: number, assignment: number): Observable<GeneralApiResponse & { data?: AssignmentPspResponse }> {
    return this.pspAssignmentService.getPspAsignmentAssignment({ psp, assignment });
  }

  getCustomerAssignment(
    customer: number,
    assignment: number
  ): Observable<GeneralApiResponse & { data?: AssignmentCustomerResponse }> {
    return this.customerAssignmentService.getCustomerAsignmentAssignment({ customer, assignment });
  }

  getPspsCustomer(psp: number, customer: number): Observable<GeneralApiResponse & { data?: CustomerResponse }> {
    return this.pspCustomerService.getPspCustomerShow({ psp, customer });
  }

  getCustomersCustomer(customer: number): Observable<GeneralApiResponse & { data?: CustomerResponse }> {
    return this.customerService.getCustomerGet({ customer });
  }

  confirmAssignment(assignment: number, assignmentDate: number, customer: number): Observable<EmptySuccessfulResponse> {
    return this.customerAssignmentService.postAssignmentAssignmentDateConfirmation({
      assignment,
      assignmentDate,
      customer,
    });
  }

  getPspAssignmentDates(
    psp: number,
    assignment: number,
    status: any
  ): Observable<ListApiResponse & { data?: Array<AssignmentDateListItemResponse> }> {
    return this.pspAssignmentService.getPspAsignmentAssignmentDateList({
      psp,
      assignment,
      status,
    });
  }

  getCustomerAssignmentDates(
    customer: number,
    assignment: number,
    status?: any
  ): Observable<ListApiResponse & { data?: Array<AssignmentDateListItemResponse> }> {
    return this.customerAssignmentService.getCustomerAsignmentAssignmentDatesList({
      customer,
      assignment,
      status,
    });
  }

  createUpdateAssignmentTimes(params: {
    psp: number;
    assignment: number;
    assignmentDate: number;
    body: UpdateWorkTimesRequest;
  }): Observable<CreatedResponse> {
    return this.pspAssignmentService.putPspAssignmentDateTimeReplace(params);
  }

  customerSignContractExternal(params: { contract: number; customer: number }): Observable<any> {
    return this.signingService.putCustomerSignatureExternal(params);
  }

  customerSignContractDigitally(params: {
    contract: number;
    customer: number;
    body: ContractRequest;
  }): Observable<any> {
    return this.signingService.putCustomerSignatureDigital(params);
  }

  pspSignContractExternal(params: { contract: number; psp: number }): Observable<any> {
    return this.signingService.putAppApiWebV1PspSignContractSignexternalIndex(params);
  }

  pspSignContractConcretization(params: { contract: number; psp: number }): Observable<any> {
    return this.signingService.putAppApiWebV1PspSignContractSignconcretisationIndex(params);
  }

  pspSignContractDigitally(params: { contract: number; psp: number; body: ContractRequest }): Observable<any> {
    return this.signingService.putAppApiWebV1PspSignContractSigndigitalIndex(params);
  }

  cancelAssignmentCustomer(
    customer: number,
    assignment: number,
    body: CancelationRequest
  ): Observable<EmptySuccessfulResponse> {
    return this.customerAssignmentService
      .postCustomerAsignmentCancelAssignment({ customer, assignment, body })
      .pipe(take(1));
  }

  cancelAssignmentPsp(psp: number, assignment: number, body: CancelationRequest): Observable<EmptySuccessfulResponse> {
    return this.pspAssignmentService
      .postPspAsignmentCancelAssignment({
        psp,
        assignment,
        body,
      })
      .pipe(take(1));
  }

  activateMobileAppAccess(params: { profile: number; psp: number }): Observable<EmptySuccessfulResponse> {
    return this.candidateProfileService.postAppApiWebV1PspProfileMobileaccessReactivateIndex(params);
  }

  fetchCandidateEndedAssignments(
    customer: number,
    profile: number
  ): Observable<Array<CustomerProfileAssignmentItemResponse>> {
    return this.customerAssignmentService
      .getCustomerProfileEndedAssignmentList({
        customer,
        profile,
      })
      .pipe(map((response) => response.data));
  }

  fetchCandidateReviews(customer: number, profile: number): Observable<CustomerProfileRatingListResponse> {
    return this.customerService.getCustomerProfileRatingList({ customer, profile }).pipe(
      take(1),
      map((response) => response.data)
    );
  }

  downloadPspContract(psp: number, assignment: number, contract: number): Observable<any> {
    return this.pspAssignmentService
      .getPspAsignmentContractDownload$Response({
        psp,
        assignment,
        contract,
      })
      .pipe(
        map((response) => {
          const contentDispositionValue = response.headers.get('Content-Disposition');
          let fileName = BaseSharedHelperService.fileNameFromHeaderValue(contentDispositionValue, '=');
          return {
            body: response.body,
            fileName,
          };
        })
      );
  }
  exportPspAssignmentInfo(params: ExportPspAssignmentInformationRequestInterface): Observable<any> {
    return this.pspAssignmentService.getPspAssignmentListExport$Response(params).pipe(
      map((response) => {
        const contentDispositionValue = response.headers.get('Content-Disposition');
        const fileName = BaseSharedHelperService.fileNameFromHeaderValue(contentDispositionValue, '=');
        return {
          body: response.body,
          fileName,
        };
      })
    );
  }

  exportCustomerAssignmentInfo(params: ExportCustomerAssignmentInformationRequestInterface): Observable<any> {
    return this.customerAssignmentService.getCustomerAssignmentListExport$Response(params).pipe(
      map((response) => {
        const contentDispositionValue = response.headers.get('Content-Disposition');
        const fileName = BaseSharedHelperService.fileNameFromHeaderValue(contentDispositionValue, '=');
        return {
          body: response.body,
          fileName,
        };
      })
    );
  }

  downloadCustomerContact(customer: number, assignment: number, contract: number): Observable<any> {
    return this.customerAssignmentService
      .getCustomerAsignmentContractDownload$Response({
        customer,
        assignment,
        contract,
      })
      .pipe(
        map((response) => {
          const contentDispositionValue = response.headers.get('Content-Disposition');
          let fileName = BaseSharedHelperService.fileNameFromHeaderValue(contentDispositionValue, '=');
          return {
            body: response.body,
            fileName,
          };
        })
      );
  }

  createCandidateRating(
    customer: number,
    profile: number,
    body: CustomerProfileRatingCreateRequest
  ): Observable<CreatedResponse> {
    return this.customerService
      .postCustomerProfileRatingCreate({
        customer,
        profile,
        body,
      })
      .pipe(take(1));
  }

  updateCandidateRating(
    customer: number,
    profile: number,
    rating: number,
    body: CustomerProfileRatingUpdateRequest
  ): Observable<EmptySuccessfulResponse> {
    return this.customerService
      .putCustomerProfileRatingUpdate({
        customer,
        profile,
        rating,
        body,
      })
      .pipe(take(1));
  }

  acceptPSPSignCondition(psp: string): Observable<UpdatedResponse> {
    return this.signingService.postPspSignAcceptCondition({ psp });
  }

  acceptCustomerSignCondition(customer: number): Observable<UpdatedResponse> {
    return this.signingService.postCustomerSignAcceptCondition({ customer });
  }

  getPSPSignVoucher(psp: string): Observable<PspSignVoucherResponse> {
    return this.signingService.getPspSignVoucher({ psp }).pipe(map((response) => response.data));
  }

  getCustomerSignVoucher(customer: number): Observable<CustomerSignVoucherResponse> {
    return this.signingService.getCustomerSignVoucher({ customer }).pipe(map((response) => response.data));
  }

  updateCustomerProfileInternalPersonalId(params: {
    customer: number;
    profile: number;
    body: CustomerProfilePersonalIdRequest;
  }): Observable<UpdatedResponse> {
    return this.customerService.putCustomerProfileIdUpdate(params);
  }

  downloadCustomerCandidateDocumentFile(params: {
    assignment: number;
    document: string;
    customer: number;
  }): Observable<any> {
    return this.customerAssignmentService.getCustomerAssignmentDocumentsDownload$Response(params).pipe(
      map((response) => {
        const contentDispositionValue = response.headers.get('Content-Disposition');
        const fileName = BaseSharedHelperService.fileNameFromHeaderValue(contentDispositionValue, '=');
        return {
          body: response.body,
          fileName,
        };
      })
    );
  }

  regenerateCustomerAssignmentContract(params: {
    customer: number;
    assignment: number;
    context?: HttpContext;
  }): Observable<EmptySuccessfulResponse> {
    return this.customerAssignmentService.postCustomerAsignmentContractRegeneration(params);
  }

  regeneratePspAssignmentContract(params: {
    psp: number;
    assignment: number;
    context?: HttpContext;
  }): Observable<EmptySuccessfulResponse> {
    return this.pspAssignmentService.postPspAsignmentContractRegeneration(params);
  }

  regenerateCustomerAssignmentProfileIDCard(params: {
    customer: number;
    assignment: number;
    profile: number;
  }): Observable<Blob> {
    return this.customerAssignmentService.postCustomerAssignmentProfileIdCardRegenerate(params);
  }

  getCustomerAssignmentProfileIDCard(params: {
    customer: number;
    assignment: number;
    profile: number;
  }): Observable<any> {
    return this.customerAssignmentService.getCustomerAssignmentProfileIdCardFileDownload$Response(params).pipe(
      map((response) => {
        const contentDispositionValue = response.headers.get('Content-Disposition');
        const fileName = BaseSharedHelperService.fileNameFromHeaderValue(contentDispositionValue, '=');
        return {
          body: response.body,
          fileName,
        };
      })
    );
  }
}
