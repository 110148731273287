<div class="teaser">
  <span class="h4"
    ><i
      baseBackButton
      class="fa-solid fa-chevron-left me-3 pointer"
    ></i>
    {{ qualificationName }} | {{ dateFrom | baseFormatDate }} -
    {{ dateTo | baseFormatDate }}
  </span>
</div>
