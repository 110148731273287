import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AssignmentUnplannedListItemResponse, MetaPagination, OrganizationFilterListResponse } from '../../../api';
import { BaseTimeRecordingItemInterface } from '../../modules/time-recordings/_interfaces/base-time-recordings.interface';

const getTimeRecordsState = createFeatureSelector('timeRecords');

export const getTimeRecordsList = createSelector(
  getTimeRecordsState,
  (
    state: any
  ): {
    data: Array<BaseTimeRecordingItemInterface>;
    filters: OrganizationFilterListResponse | undefined;
    pagination: MetaPagination | undefined;
    ok: boolean | undefined;
  } => state.list
);

export const getAssignmentListForTimeRecordCreation = createSelector(
  getTimeRecordsState,
  (
    state: any
  ): {
    data: AssignmentUnplannedListItemResponse[];
    ok: boolean | undefined;
  } => state.assignments
);
