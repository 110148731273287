import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Validators, NonNullableFormBuilder } from '@angular/forms';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { Subject, noop } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { BaseButtonSize } from '../../../../shared/components/button/base-button.component';
import { BaseGenderOptions } from '../../../../shared/constants/base-gender-options';
import { BaseModalService } from '../../../../shared/services/base-modal.service';
import { baseEmailValidator } from '../../../../shared/validators/base-email.validator';
import { basePasswordValidator } from '../../../../shared/validators/base-password.validator';
import { baseMatchValuesValidator } from '../../../../shared/validators/base-match-values.validator';
import { BaseModalHeaderSizes } from '../../../../shared/components/modal/base-modal.component';
import { BaseHelpersService } from '../../../../core/services/base-helpers.service';
import { UserProfileResponse, UserProfileUpdateRequest } from '../../../../../api';
import {
  baseAdministrationActions,
  baseSharedActions,
  baseAdministrationSelectors,
  baseSharedSelectors,
  BaseCoreState,
} from '../../../../_store';
import { basePhoneNumberValidator } from '../../../../shared/validators/base-phone-number.validator';

@Component({
  selector: 'base-profile-administration',
  templateUrl: './base-profile-management.component.html',
  styleUrls: ['./base-profile-management.component.scss'],
})
export class BaseProfileManagementComponent implements OnInit, OnDestroy {
  @ViewChild('changePasswordPopup', { static: true }) changePasswordPopup: ElementRef;

  buttonSizes = BaseButtonSize;
  genderOptions = BaseGenderOptions;
  isPreviewMode = true;
  modalRef: NgbModalRef;
  profileFormSubmitted = false;
  changePasswordFormSubmitted = false;
  destroy$ = new Subject();
  initialFormValue: any;
  isInvalidOldPassword = false;
  areEmailNotificationsActive: boolean;

  newPasswordForm = this.fb.group({
    oldPassword: ['', [Validators.required]],
    newPassword: ['', [Validators.required, basePasswordValidator(), Validators.maxLength(30)]],
    newPasswordRepeat: ['', Validators.compose([Validators.required, baseMatchValuesValidator('newPassword')])],
  });
  profileForm = this.fb.group({
    avatar: [''],
    firstName: ['', [Validators.required]],
    lastName: ['', [Validators.required]],
    mobilePhone: ['', [basePhoneNumberValidator()]],
    phone: ['', [basePhoneNumberValidator()]],
    position: '',
    gender: [BaseGenderOptions[0], [Validators.required]],
    email: ['', [Validators.required, baseEmailValidator()]],
  });

  constructor(
    private fb: NonNullableFormBuilder,
    private store: Store<BaseCoreState>,
    private readonly modalService: BaseModalService
  ) {}

  ngOnInit(): void {
    this.listenToPasswordFormChanges();
    this.fillProfileForm();
    this.handlePasswordStatus();
  }

  handlePasswordStatus(): void {
    this.store
      .select(baseAdministrationSelectors.getProfilePasswordUpdateStatus)
      .pipe(takeUntil(this.destroy$))
      .subscribe((status) => {
        if (status.ok) {
          this.changePasswordFormSubmitted = false;
          this.isInvalidOldPassword = false;
          this.newPasswordForm.reset();
          if (this.modalRef) {
            this.modalRef.close();
          }
        } else if (this.newPasswordForm.valid) {
          this.isInvalidOldPassword = true;
        }
      });
  }

  openChangePasswordModal(): void {
    this.modalRef = this.modalService.open(
      {
        headText: 'auth.password.changeTitle',
        headerSize: BaseModalHeaderSizes.sm,
        bodyTemplate: this.changePasswordPopup,
        scrollToTop: true,
        hideFooter: true,
      },
      {
        scrollable: false,
        backdrop: 'static',
        size: 'lg',
      }
    );
    this.modalRef.result.then(noop, () => {
      this.cancelPasswordForm();
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  cancelPasswordForm() {
    this.changePasswordFormSubmitted = false;
    this.isInvalidOldPassword = false;
    this.newPasswordForm.reset();
    if (this.modalRef) {
      this.modalRef.close();
    }
  }

  listenToPasswordFormChanges(): void {
    this.newPasswordForm.controls.newPassword.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      if (this.changePasswordFormSubmitted) {
        this.newPasswordForm.controls.newPasswordRepeat.setValue(this.newPasswordForm.controls.newPasswordRepeat.value);
      }
    });
  }

  submitProfileForm() {
    this.profileFormSubmitted = true;
    this.profileForm.markAllAsTouched();
    const isFormChanged = !BaseHelpersService.objectsAreEqual(this.initialFormValue, this.profileForm.value);
    if (this.profileForm.invalid) {
      return;
    }
    if (isFormChanged) {
      const profileFormValue = this.profileForm.getRawValue();
      const data: UserProfileUpdateRequest & { avatar?: string } = {
        firstName: profileFormValue.firstName,
        gender: profileFormValue.gender.value,
        lastName: profileFormValue.lastName,
      };

      if (profileFormValue.avatar) {
        data.avatar = profileFormValue.avatar;
      }

      if (profileFormValue.mobilePhone) {
        data.mobilePhone = profileFormValue.mobilePhone;
      }

      if (profileFormValue.phone) {
        data.phone = profileFormValue.phone;
      }

      if (profileFormValue.position) {
        data.position = profileFormValue.position;
      }

      this.store.dispatch(baseSharedActions.updateProfileData({ payload: data }));
      this.profileFormSubmitted = false;
    } else {
      this.isPreviewMode = true;
      this.profileFormSubmitted = false;
    }
  }

  submitPasswordForm(): void {
    this.changePasswordFormSubmitted = true;
    this.newPasswordForm.markAllAsTouched();
    if (this.newPasswordForm.valid) {
      this.store.dispatch(
        baseAdministrationActions.updateProfilePassword({
          payload: {
            body: {
              newPassword: this.newPasswordForm.getRawValue().newPassword,
              oldPassword: this.newPasswordForm.getRawValue().oldPassword,
            },
          },
        })
      );
    }
  }

  editProfileForm(): void {
    this.isPreviewMode = false;
  }

  activateEmailNotifications(emailNotificationsStatus: boolean): void {
    if (emailNotificationsStatus) {
      this.store.dispatch(
        baseSharedActions.updateProfileSettings({ payload: { notifications: { email: emailNotificationsStatus } } })
      );
    } else {
      this.modalService
        .open(
          {
            headText: 'administration.notificationSettings.disableEmailNotificationsHeadText',
            bodyText: 'administration.notificationSettings.disableEmailNotificationsBodyText',
          },
          {
            backdrop: 'static',
            size: 'lg',
          }
        )
        .result.then(
          () => {
            this.store.dispatch(
              baseSharedActions.updateProfileSettings({
                payload: { notifications: { email: emailNotificationsStatus } },
              })
            );
          },
          () => {
            this.areEmailNotificationsActive = true;
          }
        );
    }
  }

  private fillProfileForm(): void {
    this.store
      .select(baseSharedSelectors.getProfileData)
      .pipe(
        takeUntil(this.destroy$),
        map((data) => data as UserProfileResponse)
      )
      .subscribe((result) => {
        if (result) {
          this.profileForm.patchValue({
            firstName: result.firstName || '',
            lastName: result.lastName || '',
            email: result.email || '',
            mobilePhone: result.mobilePhone || '',
            phone: result.phone || '',
            position: result.position || '',
            avatar: result?.avatar?.data || '',
            gender: this.genderOptions.find((item) => item.value === result?.gender) || this.genderOptions[0],
          });
          this.isPreviewMode = true;
          this.initialFormValue = this.profileForm.value;
          this.areEmailNotificationsActive = result.settings.notifications.email;
        }
      });
  }
}
