import { BaseInquiryType, BaseOfferStatus } from '../_enums/base-inquiry.enum';

export const BASE_CUSTOMER_INQUIRY_STATUS_OPTIONS = [
  { name: 'states.released', id: BaseInquiryType.STATUS_PUBLISHED, class: 'inquiry-released-customer-filter-state' },
  {
    name: 'states.unpublished',
    id: BaseInquiryType.STATUS_CREATED,
    class: 'inquiry-unpublished-customer-filter-state',
  },
  { name: 'states.closed', id: BaseInquiryType.STATUS_CLOSED, class: 'inquiry-closed-customer-filter-state' },
];

export const BASE_CUSTOMER_OFFER_STATUS_OPTIONS = [
  { name: 'shiftStatus.legend.offered', id: BaseOfferStatus.open, class: 'offer-offered-filter-state' },
  { name: 'action.preselected', id: BaseOfferStatus.preselected, class: 'offer-preselected-filter-state' },
  {
    name: 'action.accepted',
    id: BaseOfferStatus.confirmed,
    class: 'offer-accepted-filter-state',
  },
  { name: 'action.declined', id: BaseOfferStatus.declined, class: 'offer-declined-filter-state' },
];

export const BASE_PSP_INQUIRY_STATUS_OPTIONS = [
  { name: 'inquiry.open', id: BaseInquiryType.STATUS_PUBLISHED, class: 'inquiry-open-filter-state' },
  { name: 'states.closed', id: BaseInquiryType.STATUS_CLOSED, class: 'inquiry-closed-filter-state' },
];

export const BASE_INQUIRY_STATUS_CHANGE_OPTIONS = {
  // created
  status1: [
    { name: 'states.released', id: BaseInquiryType.STATUS_PUBLISHED },
    { name: 'states.closed', id: BaseInquiryType.STATUS_CLOSED },
  ],

  // published
  status2: [{ name: 'states.closed', id: BaseInquiryType.STATUS_CLOSED }],

  // closed
  status3: [],
};
