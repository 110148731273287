import { Component } from '@angular/core';
import { BaseInquiryType } from '../../_enums/base-inquiry.enum';
import { BASE_STICKY_HEADER_SCROLL_DISTANCE } from '../../_const/base-inquiry-calendar.const';

@Component({
  selector: 'base-inquiry-candidates-and-offers',
  templateUrl: 'base-inquiry-candidates-and-offers.component.html',
})
export class BaseInquiryCandidatesAndOffersComponent {
  stickyHeaderScrollDistance = BASE_STICKY_HEADER_SCROLL_DISTANCE;
  isSticky = false;
  baseInquiryType = BaseInquiryType;

  onStickyChange(isSticky: boolean): void {
    this.isSticky = isSticky;
  }
}
