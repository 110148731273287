import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { baseAuthActions, BaseCoreState } from '../../../../_store';

@Component({
  selector: 'base-registration-verification',
  template: ``,
})
export class BaseRegistrationVerificationComponent implements OnInit {
  constructor(private store: Store<BaseCoreState>, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.validateUser();
  }

  private validateUser(): void {
    // @ts-ignore
    const { id, emailToken, type } = { ...this.route.snapshot.params };
    this.store.dispatch(baseAuthActions.verifyRegistration({ payload: { token: emailToken, id, type } }));
  }
}
