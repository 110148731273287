import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { BaseAsideDataInterface } from '../../_interfaces/base-aside-data.interface';
import { BaseButtonSize } from '../../../../shared/components/button/base-button.component';
import { BaseLanguageSwitchColors } from '../../../../shared/enums/base-language-switch-colors.enum';
import { BASE_ASIDE_MODEL } from '../../_models/base-aside.model';
import { BaseSharedService } from '../../../../shared/services/base-shared.service';
import { BaseCustomizedUserParamEnum } from '../../../../shared/enums/base-customized-user-param.enum';

@Component({
  selector: 'base-auth-wrapper',
  templateUrl: './base-auth-wrapper.component.html',
  styleUrls: ['./base-auth-wrapper.component.scss'],
})
export class BaseAuthWrapperComponent implements OnInit, OnDestroy {
  @Input() logo = 'assets-global/images/stazzle-logo-white.svg';
  @Input() backgroundImage = 'assets-global/images/auth-background-image.png';
  @Input() textWhite = true;
  @Input() isCustomizedUser = false;
  asideData: Partial<BaseAsideDataInterface>;
  buttonSize = BaseButtonSize;
  colors = BaseLanguageSwitchColors;
  isImprintPage: boolean;
  isRegisterPage: boolean;

  private destroy$ = new Subject();

  constructor(private router: Router, private baseSharedService: BaseSharedService) {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.destroy$)
      )
      .subscribe((event: any) => {
        if (BASE_ASIDE_MODEL.hasOwnProperty(event.urlAfterRedirects)) {
          this.asideData = BASE_ASIDE_MODEL[event.urlAfterRedirects];
        } else {
          this.asideData = BASE_ASIDE_MODEL.default;
        }
      });
  }

  ngOnInit() {
    this.isImprintPage = this.router.url.indexOf('auth/imprint') > -1;
    this.isRegisterPage = this.router.url.indexOf('auth/register') > -1;

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.destroy$)
      )
      .subscribe((router: any) => {
        this.isImprintPage = router.url.indexOf('auth/imprint') > -1;
        this.isRegisterPage = router.url.indexOf('auth/register') > -1;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  goTo(link: string) {
    this.router.navigate([link], {
      queryParams: { network: this.isCustomizedUser ? BaseCustomizedUserParamEnum.emasters : undefined },
    });
  }

  downloadPrivacyPolicy(): void {
    this.baseSharedService.downloadLegalDocument(true);
  }
}
