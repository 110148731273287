import { AfterViewInit, Component, DoCheck, ElementRef, Input, Renderer2, ViewChild } from '@angular/core';
import { NgbDate, NgbDatepickerI18n, NgbDateStruct, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { AbstractFormComponent } from '../_abstract-classes/abstract-form-component';
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';
import { BaseCustomDatepickerI18n } from '../datepicker/base-datepicker.i18n';
import { BaseDateFormat, BaseDateService } from '../../services/base-date.service';
import { baseRangeDatepickerValidator } from '../../validators/base-range-datepicker.validator';
import { baseDatepickerValidator } from '../../validators/base-datepicker.validator';

export interface DateRange {
  from: string;
  to: string;
}

@Component({
  selector: 'base-range-datepicker',
  providers: [
    { provide: NgbDatepickerI18n, useClass: BaseCustomDatepickerI18n },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: BaseRangeDatepickerComponent,
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useValue: baseRangeDatepickerValidator,
      multi: true,
    },
  ],
  templateUrl: './range-datepicker.component.html',
  styleUrls: ['./range-datepicker.component.scss'],
})
export class BaseRangeDatepickerComponent
  extends AbstractFormComponent
  implements ControlValueAccessor, AfterViewInit, DoCheck
{
  @ViewChild('d') datePicker: NgbInputDatepicker;
  @ViewChild('fromInput') fromInput: ElementRef;
  @ViewChild('toInput') toInput: ElementRef;
  @Input() label: string;
  @Input() isRequired: boolean;
  @Input() errorText: string | null;
  @Input() placeholder = 'dd.mm.yyyy';
  @Input() placement = 'auto';
  @Input() isInvalid: boolean;
  @Input() tabindex: number;
  @Input() disabled: boolean;
  @Input() readonly: boolean;
  @Input() isFilter = false;
  @Input() minDate: NgbDateStruct = { year: 1900, month: 1, day: 1 };
  @Input() maxDate: NgbDateStruct = { year: 2200, month: 12, day: 31 };

  model: DateRange;

  fromDate: NgbDate | null;
  toDate: NgbDate | null;
  hoveredDate: NgbDate;
  currentValueTo: string = '';
  currentValueFrom: string = '';

  constructor(protected renderer: Renderer2) {
    super(renderer);
  }

  ngDoCheck() {
    this.setNgValidationClasses();
  }

  ngAfterViewInit() {
    this.setNgValidationClasses();
  }

  onChange = (range: DateRange) => {
    this.model = range;
  };

  onTouched = () => {};

  writeValue(value: DateRange): void {
    this.model = value || { to: '', from: '' };
    this.fromDate = value?.from ? NgbDate.from(this.fromModel(value.from)) : null;
    this.toDate = value?.to ? NgbDate.from(this.fromModel(value.to)) : null;
  }

  registerOnChange(fn: (date: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onDateSelect(value: NgbDateStruct): void {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = NgbDate.from(value);
    } else if (
      this.fromDate &&
      !this.toDate &&
      value &&
      this.fromDate &&
      this.fromDate.year === value.year &&
      this.fromDate.month === value.month &&
      this.fromDate.day === value.day
    ) {
      this.toDate = this.fromDate;
    } else if (this.fromDate && !this.toDate && NgbDate.from(value)?.after(this.fromDate)) {
      this.toDate = NgbDate.from(value);
    } else {
      this.toDate = null;
      this.fromDate = NgbDate.from(value);
    }
    if (this.fromDate && this.toDate) {
      const range = {
        from: this.toModel(this.fromDate) || '',
        to: this.toModel(this.toDate) || '',
      };
      this.onChange(range);
      this.datePicker.toggle();
    }
  }

  onFromChange(event: any): void {
    this.currentValueFrom = event.target.value;
    const invalid = !!baseDatepickerValidator(new UntypedFormControl(event.target.value));
    const formatted = invalid ? '' : BaseDateService.toServerFormatString(event.target.value);
    this.fromDate = formatted ? NgbDate.from(this.fromModel(formatted)) : null;
    if ((this.minDate && this.fromDate?.before(this.minDate)) || (this.maxDate && this.fromDate?.after(this.maxDate))) {
      this.fromDate = null;
      return;
    }
    const value = { from: formatted, to: this.model.to };
    this.model = {
      from: formatted,
      to: this.model.to,
    };
    this.onChange(value);
  }

  onToChange(event: any): void {
    this.currentValueTo = event.target.value;
    const invalid = !!baseDatepickerValidator(new UntypedFormControl(event.target.value));
    const formatted = invalid ? '' : BaseDateService.toServerFormatString(event.target.value);
    this.toDate = formatted ? NgbDate.from(this.fromModel(formatted)) : null;
    if ((this.minDate && this.toDate?.before(this.minDate)) || (this.maxDate && this.toDate?.after(this.maxDate))) {
      this.toDate = null;
      return;
    }
    const value = { from: this.model.from, to: formatted };
    this.model = {
      from: this.model.from,
      to: formatted,
    };
    this.onChange(value);
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  format(date: NgbDate | null, isFromInput: boolean, isToInput: boolean): string {
    return date
      ? BaseDateService.format(new Date(date.year, date.month - 1, date.day), BaseDateFormat.DateClientFormat)
      : isFromInput && !date
      ? this.currentValueFrom
      : isToInput && !date
      ? this.currentValueTo
      : '';
  }

  private toModel(date: NgbDateStruct | null): string | null {
    return date
      ? BaseDateService.format(new Date(date.year, date.month - 1, date.day), BaseDateFormat.DateServiceFormat)
      : null;
  }

  private fromModel(value: string | undefined): NgbDateStruct | null {
    if (value) {
      const date = value.split('-');
      return {
        year: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        day: parseInt(date[2], 10),
      };
    }
    return null;
  }

  private setNgValidationClasses(): void {
    if (!this.fromInput || !this.toInput) {
      return;
    }
    if (this.isInvalid) {
      this.renderer.addClass(this.fromInput.nativeElement, 'ng-invalid');
      this.renderer.removeClass(this.fromInput.nativeElement, 'ng-valid');
      this.renderer.addClass(this.toInput.nativeElement, 'ng-invalid');
      this.renderer.removeClass(this.toInput.nativeElement, 'ng-valid');
    } else {
      this.renderer.addClass(this.fromInput.nativeElement, 'ng-valid');
      this.renderer.removeClass(this.fromInput.nativeElement, 'ng-invalid');
      this.renderer.addClass(this.toInput.nativeElement, 'ng-valid');
      this.renderer.removeClass(this.toInput.nativeElement, 'ng-invalid');
    }
  }

  onClose() {
    if (this.fromDate && this.toDate === null) {
      this.toDate = NgbDate.from(this.fromDate);
      this.onChange({
        from: this.toModel(this.fromDate) || '',
        to: this.toModel(this.toDate) || '',
      });
    }
  }
}
