import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';

import { BaseBuildDetailsService } from './base-build-details.service';
import { BuildDetails } from '../../shared/model/base-build-details';
import { BASE_BUILD_DETAILS_URL } from '../constants/base-build-details-url';

@Injectable({ providedIn: 'root' })
export class BaseBuildDetailsHttpService {
  constructor(private http: HttpClient, private buildDetailsService: BaseBuildDetailsService) {}

  fetchBuildDetails(): Promise<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Cache-Control': 'no-cache',
      }),
    };

    return new Promise((resolve) => {
      this.http
        .get<BuildDetails>(BASE_BUILD_DETAILS_URL, httpOptions)
        .toPromise()
        .then((response) => {
          this.buildDetailsService.buildDetails = response as BuildDetails;
          resolve({});
        });
    });
  }
}
