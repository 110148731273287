<label *ngIf="label">{{ label | translate }}</label>
<span
  *ngIf="!isMultiline"
  class="form-control form-field-preview"
  [ngClass]="{ 'text-end': rightAligned }"
  [class]="previewClass"
  baseTruncate
  >{{ valueToDisplay }}</span
>
<p
  *ngIf="isMultiline"
  class="form-control form-field-preview"
  [ngClass]="{ 'text-end': rightAligned }"
  [class]="previewClass"
>
  {{ valueToDisplay }}
</p>
