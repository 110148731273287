import { AbstractControl } from '@angular/forms';

export const baseCandidateAvailabilityValidator = () => {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    const { shifts, weekdays }: { shifts: Record<string, boolean>; weekdays: Record<string, boolean> } = control.value;

    return Object.values(shifts).some((shift: boolean) => shift) &&
      Object.values(weekdays).some((weekday: boolean) => weekday)
      ? null
      : { candidateAvailabilityInvalid: true };
  };
};
