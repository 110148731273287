import { Component, OnDestroy } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { BaseHelpersService } from '../../core/services/base-helpers.service';
import { BaseAvatarSize } from '../components/avatar/base-avatar.component';
import { BaseToastAlertClasses } from '../enums/base-toast-alert-classes.enum';
import { BASE_FORM_DEFAULTS } from './base-form-defaults';
import { BaseCoreState, baseSharedActions } from '../../_store';
import { Store } from '@ngrx/store';

@Component({
  template: '',
})
export class BaseCommonFormComponent implements OnDestroy {
  mainForm: UntypedFormGroup = new UntypedFormGroup({});
  avatarSizes = BaseAvatarSize;
  destroy$ = new Subject();
  initState: Array<[string, any]> = [];
  allowedImageTypes = BASE_FORM_DEFAULTS.allowedLogoImageTypes.join(',');

  constructor(public store: Store<BaseCoreState>) {}

  get avatar(): string {
    return this.mainForm.controls.avatar?.value || this.mainForm.controls.logo?.value;
  }

  get formChanged(): boolean {
    return (
      JSON.stringify(this.initState.sort(BaseHelpersService.sortStringKeyTuples)) !==
      JSON.stringify(
        BaseHelpersService.convertObjToTuple(this.mainForm.value).sort(BaseHelpersService.sortStringKeyTuples)
      )
    );
  }

  async logoChanged(event: Event): Promise<void> {
    const target = event.target as HTMLInputElement;
    const files = target.files;

    if (!files || files.length === 0) {
      return;
    }

    const logoControl = this.mainForm.controls.logo ?? this.mainForm.controls.avatar;

    if (!BASE_FORM_DEFAULTS.allowedLogoImageTypes.includes(files[0].type)) {
      this.store.dispatch(
        baseSharedActions.addSystemAlert({
          payload: {
            class: BaseToastAlertClasses.warning,
            body: 'validation.invalidImageFile',
          },
        })
      );
      return;
    }

    const fileDataUrl = await BaseHelpersService.fileToDataUrl(files[0]);
    logoControl.setValue(fileDataUrl);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
