<ngb-toast
  [class]="alert.class"
  [autohide]="false"
  [animation]="true"
>
  <div class="d-flex flex-nowrap justify-content-between">
    <span innerHTML="{{ alert.body | translate: alert.bodyTranslateParams }}"></span>
    <i
      class="fa-solid fa-xmark pointer ms-2 mt-1"
      (click)="closeAlert()"
    ></i>
  </div>
</ngb-toast>
