import { Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BaseModalParams } from '../interfaces/base-modal-params.model';
import { BaseModalComponent } from '../components/modal/base-modal.component';

@Injectable()
export class BaseModalService {
  constructor(private ngbModalService: NgbModal) {}

  open(modalParams: BaseModalParams, options?: NgbModalOptions): NgbModalRef {
    const defaultOptions = { centered: true };
    const modalOptions = { ...defaultOptions, ...options };
    const modalRef = this.ngbModalService.open(BaseModalComponent, modalOptions);

    modalRef.componentInstance.modalData = modalParams;
    modalRef.componentInstance.content = modalParams.bodyTemplate ? modalParams.bodyTemplate : null;

    return modalRef;
  }

  closeAllModals(): void {
    const hasOpenModals = this.ngbModalService.hasOpenModals();
    if (hasOpenModals) {
      this.ngbModalService.dismissAll();
    }
  }
}
