import { Component, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { Location } from '@angular/common';
import { BaseCoreState } from '../../../_store';
import { BaseButtonSize } from '../../../shared/components/button/base-button.component';
import { BaseTabInterface } from '../../../shared/interfaces/base-tab.interface';
import { BASE_PERMISSIONS } from '../../../core/constants/base-permissions';

@Component({
  selector: 'base-wrapper',
  templateUrl: './base-wrapper.component.html',
  styleUrls: ['./base-wrapper.component.scss'],
})
export class BaseWrapperComponent implements OnDestroy {
  @Input() logo: string;
  @Input() applicationSections: Array<BaseTabInterface & { featurePermissionKeys: string[] }>;
  @Input() userMenu: Array<BaseTabInterface & { permissionsAllowed: BASE_PERMISSIONS[] }>;
  buttonSize = BaseButtonSize.lg;
  isFullscreen = false;
  private destroy$ = new Subject();

  constructor(
    public activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly store: Store<BaseCoreState>,
    private location: Location
  ) {
    this.router.events
      .pipe(
        takeUntil(this.destroy$),
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          let route = this.activatedRoute;
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route.snapshot.data;
        })
      )
      .subscribe((routeSnapshotData) => {
        this.isFullscreen = !!(routeSnapshotData.isFullscreen && routeSnapshotData.isFullscreen);
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }
}
