import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseOrderDirectionEnum } from '../../../shared/enums/base-order-direction.enum';
import { CustomerInquiryOrderKeyEnum } from '../../../shared/enums/customer-inquiry-order-key.enum';

@Component({
  selector: 'base-sortable-headline-item',
  templateUrl: './base-sortable-headline-item.component.html',
  styleUrls: ['./base-sortable-headline-item.component.scss'],
})
export class BaseSortableHeadlineItemComponent implements OnInit {
  @Input() label = '';
  @Input() orderKey: CustomerInquiryOrderKeyEnum | string = '';
  @Input() orderDirection: BaseOrderDirectionEnum | undefined;
  @Input() set activeKey(activeKey: string | undefined) {
    if (this.orderKey === activeKey) {
      this._activeKey = activeKey;
    } else {
      this._activeKey = '';
      this.orderDirection = undefined;
    }
  }
  @Output() changeOrder: EventEmitter<{ direction: BaseOrderDirectionEnum; key: CustomerInquiryOrderKeyEnum }> =
    new EventEmitter<{
      direction: BaseOrderDirectionEnum;
      key: CustomerInquiryOrderKeyEnum;
    }>();
  directions = BaseOrderDirectionEnum;
  _activeKey: string;

  ngOnInit() {
    if (this.orderKey !== this._activeKey) {
      this.orderDirection = undefined;
    }
  }

  onOrderChanged(key: string): void {
    this.orderKey = key;
    this.orderDirection =
      !this.orderDirection || this.orderDirection === this.directions.desc ? this.directions.asc : this.directions.desc;
    this.changeOrder.emit({
      key: this.orderKey as CustomerInquiryOrderKeyEnum,
      direction: this.orderDirection,
    });
  }
}
