import { Component, Input } from '@angular/core';
import { BaseAvatarSize } from '../../../../shared/components/avatar/base-avatar.component';

@Component({
  selector: 'base-inquiry-employee-avatar-and-name',
  templateUrl: './base-inquiry-employee-avatar-and-name.component.html',
})
export class BaseInquiryEmployeeAvatarAndNameComponent {
  @Input() avatar: string;
  @Input() firstName: string;
  @Input() lastName: string;
  @Input() anonymous: boolean | undefined = false;
  baseAvatarSize = BaseAvatarSize;
}
