import { AbstractControl, ValidatorFn } from '@angular/forms';
import { BaseDateService } from '../services/base-date.service';

export const baseRangeDatepickerValidator: ValidatorFn = (c: AbstractControl): { [key: string]: boolean } | null => {
  const cvDateRange = { dateRangeInvalid: true };

  if (!c.value) {
    return null;
  }

  if (!c.value['from'] || !c.value['to']) {
    return cvDateRange;
  }

  const from = new Date(BaseDateService.toServerFormatString(c.value.from));
  const to = new Date(BaseDateService.toServerFormatString(c.value.to));

  return BaseDateService.isBefore(to, from) ? cvDateRange : null;
};
