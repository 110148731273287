import { SafeUrl } from '@angular/platform-browser';
import { createAction, props } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
  AssignmentCustomerResponse,
  AssignmentDateListItemResponse,
  AssignmentPspResponse,
  CancelationRequest,
  ContractRequest,
  CustomerAssignmentListItemResponse,
  CustomerProfileAssignmentItemResponse,
  CustomerProfilePersonalIdRequest,
  CustomerProfileRatingCreateRequest,
  CustomerProfileRatingListResponse,
  CustomerProfileRatingUpdateRequest,
  CustomerResponse,
  GeneralApiResponse,
  PaginatedApiResponse,
  PspAssignmentListItemResponse,
  CustomerSignVoucherResponse,
  UpdateWorkTimesRequest,
} from '../../../api';
import { BaseSignContractOptions } from '../../modules/assignments/_enums/base-sign-contract-options.enum';
import { ExportCustomerContractsRequestInterface } from '../../modules/assignments/_interfaces/export-customer-contracts-request.interface';
import { ExportPspContractsRequestInterface } from '../../modules/assignments/_interfaces/export-psp-contracts-request.interface';
import { BaseOrderKeyEnum } from '../../shared/enums/base-order-key.enum';
import { BaseOrderDirectionEnum } from '../../shared/enums/base-order-direction.enum';
import { ExportPspAssignmentInformationRequestInterface } from '../../modules/assignments/_interfaces/export-psp-assignment-information-request.interface';
import { ExportCustomerAssignmentInformationRequestInterface } from '../../modules/assignments/_interfaces/export-customer-assignment-information-request.interface';

// Assignment list
export const loadAssignmentList = createAction(
  '[Assignment list] Load assignment list',
  props<{
    payload: {
      type?: string;
      size?: number;
      page?: number;
      filters?: any;
      search?: string;
      orderKey?: BaseOrderKeyEnum;
      orderDirection?: BaseOrderDirectionEnum;
    };
  }>()
);
export const setAssignmentList = createAction(
  '[Assignment list] Set assignment list',
  props<{
    payload: Observable<
      PaginatedApiResponse & { data?: Array<CustomerAssignmentListItemResponse> | Array<PspAssignmentListItemResponse> }
    >;
  }>()
);
export const errorAssignmentList = createAction('[Assignment list] Error assignment list');

// Customer all assignments
export const loadCustomerAllAssignments = createAction(
  '[Customer Assignments] Load all customer assignments',
  props<{
    payload: {
      page?: number;
      filters?: any;
      search?: string;
      orderKey?: BaseOrderKeyEnum;
      orderDirection?: BaseOrderDirectionEnum;
      size?: number;
    };
  }>()
);
export const setCustomerAllAssignments = createAction(
  '[Customer Assignments] Set all customer assignments',
  props<{
    payload: Observable<PaginatedApiResponse & { data?: Array<CustomerAssignmentListItemResponse> }>;
  }>()
);
export const errorCustomerAllAssignment = createAction('[Customer Assignments] Error assignment list');

export const successCustomerAllAssignment = createAction('[Customer Assignments] Success assignment list');

// Assignment details
export const loadAssignment = createAction(
  '[Assignment] Load assignment',
  props<{ payload: { assignment: number; showDigitalSignStatus?: boolean } }>()
);

export const reloadAssignmentData = createAction(
  '[Assignment] Reload assignment data',
  props<{ payload: { assignment: number } }>()
);

export const resetAssignment = createAction('[Assignment] Reset assignment');

export const clearAssignment = createAction('[Assignment] Clear assignment');

export const successLoadAssignment = createAction(
  '[Assignment] Success load assignment',
  props<{ payload: AssignmentPspResponse | AssignmentCustomerResponse }>()
);

export const errorLoadAssignment = createAction('[Assignment] Error load assignment');

// Confirm assignment
export const confirmAsignment = createAction(
  '[Assignment] Confirm assignment',
  props<{ payload: { assignment: number; assignmentDate: number } }>()
);
export const confirmAsignmentSuccess = createAction('[Assignment] Confirm assignment success');
export const confirmAsignmentError = createAction('[Assignment] Confirm assignment error');

// Assignment dates
export const loadAssignmentDates = createAction(
  '[Assignment details] Load assignment dates',
  props<{ payload: any }>()
);

export const setAssignmentDates = createAction(
  '[Assignment details] Set assignment dates',
  props<{ payload: AssignmentDateListItemResponse[] }>()
);

export const successAssignmentDates = createAction('[Assignment details] Success assignment dates');

export const errorAssignmentDates = createAction('[Assignment details] Error assignment dates');

export const updateAssignmentDate = createAction(
  '[Assignment] Update assignment date',
  props<{ payload: AssignmentDateListItemResponse }>()
);

// Create / update assignment times'
export const createUpdateAssignmentTimes = createAction(
  '[Assignment details] Create or update assignment times',
  props<{
    payload: {
      assignment: number;
      assignmentDate: number;
      body: UpdateWorkTimesRequest;
    };
  }>()
);
export const createUpdateAssignmentTimesSuccess = createAction(
  '[Assignment details] Create or update assignment times success'
);
export const createUpdateAssignmentTimesError = createAction(
  '[Assignment details] Create or update assignment times error'
);

// Sign contract
export const signContract = createAction(
  '[Assignment details] Sign contract',
  props<{
    payload: { assignment: number; contract: number; signingType: BaseSignContractOptions; body?: ContractRequest };
  }>()
);
export const signContractSuccess = createAction('[Assignment details] Sign contract customer success');
export const signContractError = createAction('[Assignment details] Sign contract customer error');
export const clearContractStatus = createAction('[Assignment details] Clear Sign contract customer status');

// Assignment cancellation
export const assignmentCustomerCancellation = createAction(
  '[Customer Assignments] Assignment cancellation',
  props<{ payload: { assignment: number; body: CancelationRequest } }>()
);

export const assignmentCancellation = createAction(
  '[Assignment details] Assignment cancellation',
  props<{ payload: { assignment: number; body: CancelationRequest } }>()
);
export const assignmentCancellationSuccess = createAction('[Assignment details] Assignment cancellation success');
export const assignmentCancellationError = createAction('[Assignment details] Assignment cancellation error');

// Candidate ended assignments
export const getCandidateEndedAssignments = createAction(
  '[Assignment] Get candidate ended assignments',
  props<{ payload: number }>()
);
export const loadCandidateEndedAssignments = createAction(
  '[Assignment] Load candidate ended assignments',
  props<{ payload: Array<CustomerProfileAssignmentItemResponse> }>()
);
export const loadCandidateEndedAssignmentsSuccess = createAction(
  '[Assignment] Load candidate ended assignments success'
);
export const loadCandidateEndedAssignmentsError = createAction('[Assignment] Load candidate ended assignments error');

// Customer reviews
export const getCustomerReviews = createAction('[Assignment] Get customer reviews', props<{ payload: number }>());
export const loadCustomerReviews = createAction(
  '[Assignment] Load customer reviews',
  props<{ payload: CustomerProfileRatingListResponse }>()
);
export const loadCustomerReviewsSuccessfully = createAction('[Assignment] Load customer review successfully');
export const loadCustomerReviewsError = createAction('[Assignment] Load customer review error');
export const clearCustomerReviews = createAction('[Assignment] Clear customer review');

// Rate candidate
export const createCandidateRating = createAction(
  '[Assignment] Create candidate rating',
  props<{
    payload: {
      body: CustomerProfileRatingCreateRequest;
      assignment?: CustomerAssignmentListItemResponse | AssignmentCustomerResponse;
      profileId?: number;
    };
  }>()
);
export const createCandidateRatingSuccess = createAction('[Assignments] Create candidate rating success');
export const createCandidateRatingError = createAction('[Assignment] Create candidate rating error');

export const updateCandidateRating = createAction(
  '[Assignment] Update candidate rating',
  props<{
    payload: {
      rating: number;
      body: CustomerProfileRatingUpdateRequest;
      assignment?: CustomerAssignmentListItemResponse | AssignmentCustomerResponse;
      profileId?: number;
    };
  }>()
);
export const updateCandidateRatingSuccess = createAction('[Assignment] Update candidate rating success');
export const updateCandidateRatingError = createAction('[Assignment] Update candidate rating error');

// Assignment customer
export const loadAssignmentCustomer = createAction(
  '[Assignment customer] Load customer info',
  props<{ payload: { customer: number; psp?: number } }>()
);
export const setAssignmentCustomer = createAction(
  '[Assignment customer] Set customer',
  props<{ payload: GeneralApiResponse & { data?: CustomerResponse } }>()
);
export const errorAssignmentCustomer = createAction('[Assignment customer] Error customer');

// View contract
export const viewContract = createAction(
  '[Assignment] Download contract',
  props<{ payload: { assignment: number; contract: number } }>()
);
export const successDownloadContract = createAction('[Assignment] Download contract success');
export const errorDownloadContract = createAction('[Assignment] Download contract error');

// Digital Sign Process
export const acceptDigitalSignConditions = createAction(
  '[Assignment] Accept digital sign conditions',
  props<{ payload?: { hideSuccessMessage?: boolean } }>()
);

// Digital Sign Voucher
export const loadDigitalSignVoucher = createAction(
  '[Assignment] Load digital sign voucher',
  props<{ payload?: { hideAlert?: boolean } }>()
);
export const setDigitalSignVoucher = createAction(
  '[Assignment] Set digital sign voucher',
  props<{ payload: CustomerSignVoucherResponse }>()
);
export const successDigitalSignVoucher = createAction('[Assignment] Success digital sign voucher');
export const errorDigitalSignVoucher = createAction('[Assignment] Error digital sign voucher');

// Download customer contracts
export const downloadCustomerContracts = createAction(
  '[Assignment] Download Customer Contracts',
  props<{ payload: Partial<ExportCustomerContractsRequestInterface> }>()
);
export const successDownloadCustomerContracts = createAction('[Assignment] Success Download Customer Contracts');
export const errorDownloadCustomerContracts = createAction('[Assignment] Error Download Customer Contracts');

// Download customer assignment information
export const downloadCustomerAssignmentInfo = createAction(
  '[Assignment] Download Customer Assignment Information',
  props<{ payload: Partial<ExportCustomerAssignmentInformationRequestInterface> }>()
);
export const successDownloadCustomerAssignmentInfo = createAction(
  '[Assignment] Success Download Customer Assignment Information'
);
export const errorDownloadCustomerAssignmentInfo = createAction(
  '[Assignment] Error Download Customer Assignment Information'
);

// Update customer profile personal id
export const updateCustomerProfilePersonalId = createAction(
  '[Assignment] Update customer profile personal id',
  props<{ payload: { profile: number; body: CustomerProfilePersonalIdRequest; assignment: number } }>()
);
export const successUpdateCustomerProfilePersonalId = createAction(
  '[Assignment] Success Update customer profile personal id'
);
export const errorUpdateCustomerProfilePersonalId = createAction(
  '[Assignment] Error Update customer profile personal id'
);

// Download PSP contracts
export const downloadPspContracts = createAction(
  '[Assignment] Download PSP Contracts',
  props<{ payload: Partial<ExportPspContractsRequestInterface> }>()
);
export const successDownloadPspContracts = createAction('[Assignment] Success Download PSP Contracts');
export const errorDownloadPspContracts = createAction('[Assignment] Error Download PSP Contracts');
// Download PSP assignment information
export const downloadPspAssignmentInfo = createAction(
  '[Assignment] Download PSP Assignment Information',
  props<{ payload: Partial<ExportPspAssignmentInformationRequestInterface> }>()
);
export const successDownloadPspAssignment = createAction('[Assignment] Success Download PSP Assignment Information');
export const errorDownloadPspAssignment = createAction('[Assignment] Error Download PSP Assignment Information');

// Customer candidate document
export const downloadCustomerCandidateDocument = createAction(
  '[Assignment] Download customer candidate document',
  props<{ payload: { assignment: number; document: string; documentType: string; openInModal?: boolean } }>()
);
export const setDocumentView = createAction(
  '[Assignment] Set document view',
  props<{ payload: { document: Blob | SafeUrl; fileName?: string } }>()
);
export const clearDocumentView = createAction('[Assignment] Clear document view');
export const successDownloadCustomerCandidateDocument = createAction(
  '[Assignment] Download customer candidate document success'
);
export const errorDownloadCustomerCandidateDocument = createAction(
  '[Assignment] Download customer candidate document error'
);

export const regenerateCustomerAssignmentContract = createAction(
  '[Assignment] Regenerate Customer assignment contract',
  props<{ payload: { assignment: number } }>()
);

export const successRegenerateCustomerAssignmentContract = createAction(
  '[Assignment] Regenerate Customer assignment contract success'
);
export const errorRegenerateCustomerAssignmentContract = createAction(
  '[Assignment] Regenerate Customer assignment contract error'
);

export const regeneratePspAssignmentContract = createAction(
  '[Assignment] Regenerate PSP assignment contract',
  props<{ payload: { assignment: number } }>()
);

export const successRegeneratePspAssignmentContract = createAction(
  '[Assignment] Regenerate PSP assignment contract success'
);
export const errorRegeneratePspAssignmentContract = createAction(
  '[Assignment] Regenerate PSP assignment contract error'
);

export const regenerateCustomerAssignmentProfileIDCard = createAction(
  '[Assignment] Regenerate Customer assignment profile ID card',
  props<{ payload: { assignment: number; profile: number } }>()
);

export const successRegenerateCustomerAssignmentProfileIDCard = createAction(
  '[Assignment] Regenerate Customer assignment profile ID card success'
);
export const errorRegenerateCustomerAssignmentProfileIDCard = createAction(
  '[Assignment] Regenerate Customer assignment profile ID card error'
);

export const downloadCustomerAssignmentProfileIDCard = createAction(
  '[Administration] Download Customer assignment profile ID card',
  props<{ payload: { assignment: number; profile: number; openInModal?: boolean; downloadOnly?: boolean } }>()
);
export const successDownloadCustomerAssignmentProfileIDCard = createAction(
  '[Administration] Success Download Customer assignment profile ID card'
);
export const errorDownloadCustomerAssignmentProfileIDCard = createAction(
  '[Administration] Error Download Customer assignment profile ID card'
);

export const setCustomerAssignmentProfileIDCardView = createAction(
  '[Administration] Set Customer assignment profile ID card view',
  props<{ payload: { document: Blob; fileName: string } }>()
);
export const clearCustomerAssignmentProfileIDCardView = createAction(
  '[Administration] Clear Customer assignment profile ID card view'
);
