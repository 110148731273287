<form
  [formGroup]="editPermissionGroupsForm"
  [ngClass]="{ 'was-validated': isFormSubmitted }"
>
  <div formArrayName="permissionGroups">
    <div
      class="teaser bg-lightest my-3"
      *ngFor="let permissionGroup of permissionGroupsFormArray.controls; let i = index"
      [formGroupName]="i"
    >
      <div class="d-flex flex-nowrap justify-content-between align-items-start">
        <div class="w-50">
          <base-input
            label=""
            formControlName="name"
            placeholder=""
            [isPreview]="!permissionGroupsFormArray.controls[i].get('isEditable')?.value"
            [isInvalid]="!!permissionGroupsFormArray.controls[i].get('name')?.errors"
            [errorMessage]="permissionGroupsFormArray.controls[i].get('name')?.errors | baseFieldValidation"
          ></base-input>
        </div>
        <base-button
          color="dark"
          icon="fa-solid fa-pen"
          *ngIf="permissionGroupsFormArray.controls[i].get('isEditable')?.value === false"
          [iconSize]="buttonSizes.md"
          (btnClick)="openGroupEditForm(i)"
          tooltip="action.edit"
          stateClass="pe-1"
        ></base-button>
      </div>

      <div
        class="d-flex flex-column"
        *ngIf="permissionGroupsFormArray.controls[i].get('isEditable')?.value === true"
      >
        <div class="d-flex flex-column">
          <ng-container *ngFor="let permission of selectedGroupPermissions">
            <base-checkbox
              class="pt-3"
              [isChecked]="permission.isSelected"
              [label]="permissionCheckboxLabel"
              (getChange)="changePermission($event, permission.id, i, true)"
            ></base-checkbox>
            <ng-template #permissionCheckboxLabel>
              <base-permission-checkbox-template [permission]="permission"></base-permission-checkbox-template>
            </ng-template>
          </ng-container>
        </div>
      </div>
      <div class="d-flex justify-content-end mt-5">
        <base-button
          *ngIf="permissionGroupsFormArray.controls[i].get('isEditable')?.value === true"
          (btnClick)="onGroupUpdate(i)"
          color="primary"
          label="group.save"
        ></base-button>
      </div>
    </div>
  </div>
</form>
