import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { BaseAdminAndUserListGuard } from './_guards/base-admin-and-user-list.guard';
import { BaseUserGroupsGuard } from './_guards/base-user-groups.guard';
import { baseUserEffects, baseUserReducer } from '../../_store';
import { BaseUsersService } from './base-users.service';
import { BaseActiveAdminAndUserGuard } from './_guards/base-active-users-and-admins.guard';

@NgModule({
  imports: [
    StoreModule.forFeature('users', baseUserReducer.BaseUserReducer),
    EffectsModule.forFeature([baseUserEffects.BaseUserEffects]),
  ],
  providers: [BaseUsersService, BaseAdminAndUserListGuard, BaseUserGroupsGuard, BaseActiveAdminAndUserGuard],
})
export class BaseUsersModule {}
