import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CatalogService } from '../../../api';

export interface BaseCountry {
  code: string;
  name: string;
}

@Injectable()
export class BaseCountryService {
  constructor(private catalogService: CatalogService) {}

  list(): Observable<BaseCountry[]> {
    return this.catalogService.getApiCatalogsIndex().pipe(
      map((response: any) => {
        const list: BaseCountry[] = [];
        for (const item in response.data) {
          if (response.data[item]) {
            list.push({ code: item, name: response.data[item] });
          }
        }
        return list;
      })
    );
  }
}
