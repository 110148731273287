<div class="language-switch">
  <div
    class="k-dropdown"
    (baseClickOutside)="close()"
  >
    <div
      class="align-items-center pointer d-flex ps-0 pe-2 ms-3 py-2"
      (click)="toggle()"
      [ngClass]="'font-' + color"
    >
      <span class="bold-font">{{ activeLanguage | uppercase }}</span>
      <i
        class="fa-solid fa-fw me-1 bold-font"
        [ngClass]="isOpen ? 'fa-chevron-up' : 'fa-chevron-down'"
      ></i>
    </div>
    <div
      class="language-switch-menu"
      *ngIf="isOpen"
      (click)="close()"
    >
      <ng-container *ngTemplateOutlet="menu"></ng-container>
    </div>
  </div>
  <ng-template #menu>
    <ul class="list-unstyled bold-font m-3">
      <li
        (click)="setLanguage(language.value)"
        *ngFor="let language of languages | keyvalue"
      >
        <a
          class="language-switch-hover"
          [ngClass]="{ 'language-active': activeLanguage === language.value }"
          >{{ language.value | uppercase }} | {{ 'language.' + language.value | translate | uppercase }}</a
        >
      </li>
    </ul>
  </ng-template>
</div>
