<div class="k-checkbox">
  <input
    type="checkbox"
    [ngClass]="checkboxClasses"
    [(ngModel)]="isChecked"
    [ngbTooltip]="tooltip | translate"
    (change)="onValueChange(checkbox.checked)"
    #checkbox
    [disabled]="isDisabled"
  />
  <span></span>
  <label
    *ngIf="label && !labelTemplate"
    (click)="checkbox.click()"
    [ngClass]="{ required: isRequired }"
    [class]="labelClasses"
    [innerHTML]="label | translate: labelTranslateParams"
  >
  </label>
  <ng-container
    *ngIf="labelTemplate"
    [ngTemplateOutlet]="labelTemplate"
  >
  </ng-container>
</div>

<p
  *ngIf="isInvalid"
  class="invalid-feedback"
  [innerHTML]="errorMessage && (errorMessage | translate)"
></p>
