<form
  [formGroup]="form"
  [ngClass]="{ 'was-validated': isFormSubmitted }"
>
  <div class="d-flex justify-content-between">
    <div class="d-flex">
      <base-avatar
        [size]="avatarSize?.large"
        [image]="assignment?.profile?.avatar"
      ></base-avatar>
      <div class="d-flex flex-column justify-content-center mx-2">
        <h3 class="headline-20 mb-0">
          {{ assignment?.profile?.firstName + ' ' + assignment?.profile?.lastName }}
        </h3>
        <p class="me-2 mb-0">
          <span>{{ assignment?.customerDepartment?.name }}</span>
          <span *ngIf="assignment?.customerDepartment?.name && assignment?.customerLocation?.name"> | </span>
          <span>{{ assignment?.customerLocation?.name }}</span>
        </p>
        <p class="me-2 mb-0 text-dark-green">{{ assignment.mainQualification.name }}</p>
      </div>
    </div>
    <div class="d-flex align-items-center">
      <i
        class="fa-solid fa-building fs-2"
        [ngbTooltip]="'department.singular' | translate"
      ></i>
      <div class="d-flex flex-column ms-4">
        <span class="fw-bold"> {{ assignment?.psp?.name }} </span>
        <span> {{ assignment?.pspBranch?.name }} </span>
      </div>
    </div>
  </div>
  <div class="row pb-4 mt-7">
    <div class="col-12">
      <p class="fw-bold text-danger">{{ 'operations.deregisterWarning' | translate }}</p>
    </div>
  </div>
  <div class="row pb-4">
    <div class="col-4">
      <label class="fw-bold">{{ 'operations.period' | translate }}</label>
    </div>
    <div class="col-8">
      <span>{{ assignment?.startDate | baseFormatDate }} - {{ assignment?.endDate | baseFormatDate }}</span>
    </div>
  </div>
  <div class="row pb-4">
    <div class="col-4">
      <label class="fw-bold"
        >{{ 'surcharges.cancellationPeriod' | translate }} {{ 'relativeTime.hours' | translate }}</label
      >
    </div>
    <div class="col-8">
      <span>{{ assignment?.cancellationPeriod | baseIsEmpty }}</span>
    </div>
  </div>
  <div class="row d-flex align-items-center">
    <div class="col-4">
      <label class="fw-bold required">{{ 'operations.firstWorkingDay' | translate }}</label>
    </div>
    <div class="col-8">
      <base-datepicker
        formControlName="cancelationDate"
        placement="bottom-right"
        [isInvalid]="!!form.get('cancelationDate')?.errors"
        [isRequired]="true"
        errorText="validation.selectDate"
        [minDate]="minDate"
        [maxDate]="maxDate"
      ></base-datepicker>
    </div>
  </div>
  <div class="row d-flex justify-content-end mt-1">
    <div class="col-8">
      <ng-container *ngIf="form.get('cancelationDate')?.value">
        <span class="fs-7">{{
          isFirstDatesEqual
            ? ('operations.cancelledInfo' | translate)
            : ('operations.newPeriod' | translate) + ' ' + this.newPeriodMessage
        }}</span>
      </ng-container>
    </div>
  </div>
  <div class="row py-4 d-flex align-items-center reason-info">
    <div class="col-4">
      <label class="fw-bold">{{ 'operations.reason' | translate }}</label>
    </div>
    <div class="col-8">
      <base-textarea
        formControlName="note"
        [placeholder]="'inquiry.closeInquiryPlaceholder'"
      ></base-textarea>
    </div>
  </div>
  <div class="row py-4">
    <div class="col-4">
      <label class="fw-bold">{{ 'externalEmployees.masterData.employeeDetails.internalStaffId' | translate }}</label>
    </div>
    <div class="col-8">
      <span>{{ assignment?.profile?.customerProfilePersonalId | baseIsEmpty }}</span>
    </div>
  </div>
  <div class="py-4">
    {{ 'operations.cancellationInfo' | translate }}
  </div>
</form>
