import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { basePspPriceListActions, baseSharedActions } from '../actions';
import { BaseCoreState } from '../reducers';
import { baseAuthSelectors } from '../selectors';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { BasePspPriceListService } from '../../modules/psp-price-list/base-psp-price-list.service';
import { BaseTokenInterface } from '../../shared/interfaces/base-token-service.interface';
import { BaseToastAlertClasses } from '../../shared/enums/base-toast-alert-classes.enum';

@Injectable()
export class BasePspPriceListEffects {
  loadCategories$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(basePspPriceListActions.loadCategories),
      switchMap((action) => {
        return this.priceListService.getCategories({ tag: action.payload.tag }).pipe(
          mergeMap((response) => {
            return [
              basePspPriceListActions.setCategories({ payload: response }),
              basePspPriceListActions.successCategories(),
            ];
          }),
          catchError(() => {
            return of(basePspPriceListActions.errorCategories());
          })
        );
      })
    );
  });

  loadQualifications$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(basePspPriceListActions.loadQualifications),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((user) => user.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        return this.priceListService.getPriceList({ psp: user.organizationId, tag: action.payload.tag }).pipe(
          mergeMap((response) => {
            return [
              basePspPriceListActions.setQualifications({ payload: response }),
              basePspPriceListActions.successQualifications(),
            ];
          }),
          catchError(() => {
            return of(basePspPriceListActions.errorQualifications());
          })
        );
      })
    );
  });

  updateQualifications$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(basePspPriceListActions.updateQualifications),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((user) => user.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        return this.priceListService
          .updatePriceList({
            psp: user.organizationId,
            body: {
              prices: action.payload.prices,
            },
          })
          .pipe(
            mergeMap(() => {
              return [
                baseSharedActions.addSystemAlert({
                  payload: {
                    body: 'successMessage.changesSaved',
                    class: BaseToastAlertClasses.success,
                  },
                }),
                basePspPriceListActions.loadQualifications({
                  payload: {
                    tag: action.payload.tag,
                  },
                }),
              ];
            }),
            catchError(() => {
              return of(basePspPriceListActions.errorQualifications());
            })
          );
      })
    );
  });

  constructor(
    private actions$: Actions,
    private store: Store<BaseCoreState>,
    private router: Router,
    private priceListService: BasePspPriceListService
  ) {}
}
