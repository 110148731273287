import { UntypedFormGroup } from '@angular/forms';

export class BaseHelpersService {
  static convertObjToTuple(obj: { [key: string]: any }): Array<[string, any]> {
    const pairs = Object.entries(obj);
    let result = pairs.filter((pair) => typeof pair[1] !== 'object' || pair[1] == null);
    result = [
      ...result,
      ...pairs
        .filter((pair) => typeof pair[1] === 'object' && pair[1] !== null)
        .map((pair) => BaseHelpersService.convertObjToTuple(pair[1]))
        .reduce((acc: Array<[string, any]>, item: any): Array<[string, any]> => {
          acc = [...acc, ...item];
          return acc;
        }, []),
    ];

    return result;
  }

  static sortStringKeyTuples(a: [string, any], b: [string, any]): number {
    return a[0].localeCompare(b[0]);
  }

  static markFormGroupTouched(form: UntypedFormGroup): void {
    Object.values(form.controls).forEach((control) => {
      control.markAsTouched();

      if ((control as any).controls) {
        this.markFormGroupTouched(control as UntypedFormGroup);
      }
    });
  }

  static fileToDataUrl(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event: ProgressEvent<FileReader>) => {
        resolve(event?.target?.result as string);
      };

      reader.readAsDataURL(file);
    });
  }

  static objectsAreEqual(object1: Record<string, any>, object2: Record<string, any>): boolean {
    return (
      JSON.stringify(this.convertObjToTuple(object1).sort(this.sortStringKeyTuples)) ===
      JSON.stringify(this.convertObjToTuple(object2).sort(this.sortStringKeyTuples))
    );
  }

  static areObjectsKeysEqual(object1: any, object2: any): boolean {
    return !object1 && !object2
      ? true
      : !object1 || !object2
      ? false
      : JSON.stringify(Object.keys(object1).sort()) === JSON.stringify(Object.keys(object2).sort());
  }
}
