import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import {
  AvatarUpdateRequest,
  CatalogService,
  CreateCustomerLocationRequest,
  CreatedResponse,
  CustomerBusinessInviteRequest,
  CustomerLocationDepartmentService,
  CustomerLocationService,
  CustomerLocationSurchargeService,
  CustomerMainInfoUpdateRequest,
  CustomerMedicalInviteRequest,
  CustomerResponse,
  CustomerService,
  CustomerTerminalService,
  DepartmentListItemResponse,
  EmptySuccessfulResponse,
  GeneralApiResponse,
  ListApiResponse,
  TerminalCustomerLocationListItemResponse,
  LocationListItemResponse,
  LocationResponse,
  LogoUpdateRequest,
  PermissionItemResponse,
  ProfilePasswordUpdateRequest,
  PspBranchService,
  PspBranchUpdateRequest,
  PspInviteRequest,
  PspResponse,
  PspService,
  PspSurchargeService,
  QualificationResponse,
  SurchargeListItemResponse,
  SurchargeUpdateRequest,
  TerminalCreateRequest,
  TerminalListItemResponse,
  TerminalUpdateRequest,
  TerminalUsageRequest,
  CustomerLocationUpdateRequest,
  UserPasswordUpdateRequest,
  UserProfileResponse,
  UserResponse,
  UserService,
  UserUpdateRequest,
} from '../../../api';
import { BaseSharedHelperService } from '../../shared/services/base-shared-helper.service';

@Injectable()
export class BaseAdministrationService {
  constructor(
    private readonly userService: UserService,
    private readonly pspService: PspService,
    private readonly customerService: CustomerService,
    private readonly pspBranchService: PspBranchService,
    private readonly customerSurchargeService: CustomerLocationSurchargeService,
    private readonly pspSurchargeService: PspSurchargeService,
    private readonly catalogService: CatalogService,
    private readonly customerLocationService: CustomerLocationService,
    private readonly customerLocationDepartmentService: CustomerLocationDepartmentService,
    private readonly customerTerminalService: CustomerTerminalService
  ) {}

  getProfile(): Observable<{ message?: string; data?: UserProfileResponse }> {
    return this.userService.getAppApiWebV1UserUserprofileShow();
  }

  getCustomerUserProfile(customer: number, user: number): Observable<GeneralApiResponse & { data?: UserResponse }> {
    return this.customerService.getCustomersUsersShow({ customer, user });
  }

  updateCustomerUserProfile(userId: number, orgId: number, body: UserUpdateRequest): Observable<CreatedResponse> {
    return this.customerService.putCustomersUsersUpdate({ user: userId, customer: orgId, body });
  }

  getPspUserProfile(psp: number, user: number): Observable<GeneralApiResponse & { data?: UserResponse }> {
    return this.pspService.getPspsUsersShow({ psp, user });
  }

  updatePspUserProfile(psp: number, user: number, body: UserUpdateRequest): Observable<CreatedResponse> {
    return this.pspService.putPspsUsersUpdate({ psp, user, body });
  }

  updateCustomerLogo(customer: number, body: LogoUpdateRequest): Observable<EmptySuccessfulResponse> {
    return this.customerService.putCustomerLogoUpdate({ customer, body });
  }

  updatePspLogo(psp: number, body: LogoUpdateRequest): Observable<EmptySuccessfulResponse> {
    return this.pspService.putPspLogoUpdate({ psp, body });
  }

  updateUserAvatar(body: AvatarUpdateRequest): Observable<EmptySuccessfulResponse> {
    return this.userService.putAppApiWebV1UserUserprofileUpdateavatar({ body });
  }

  getLocations(customer: number, active?: any): Observable<LocationListItemResponse[]> {
    return this.customerLocationService
      .getCustomerLocationList({ customer, active })
      .pipe(map((response) => response.data));
  }

  activateLocation(params: { customer: number; location: number }): Observable<EmptySuccessfulResponse> {
    return this.customerLocationService.postCustomerLocationActivate(params);
  }

  deactivateLocation(params: { customer: number; location: number }): Observable<EmptySuccessfulResponse> {
    return this.customerLocationService.deleteCustomerLocationDeactivate$Response(params);
  }

  getDepartmentLocations(
    customer: number,
    locationId: number,
    active?: boolean
  ): Observable<DepartmentListItemResponse[]> {
    return this.customerLocationDepartmentService
      .getCustomerLocationDepartmentList({ location: locationId, customer, active })
      .pipe(map((response) => response.data));
  }

  activateDepartmentLocation(params: {
    customer: number;
    location: number;
    department: number;
  }): Observable<EmptySuccessfulResponse> {
    return this.customerLocationDepartmentService.postCustomerLocationDepartmentActivate(params);
  }

  deactivateDepartmentLocation(params: {
    customer: number;
    location: number;
    department: number;
  }): Observable<EmptySuccessfulResponse> {
    return this.customerLocationDepartmentService.deleteCustomerLocationDepartmentDeactivate(params);
  }

  getLocation(
    customer: number,
    location: number
  ): Observable<
    GeneralApiResponse & {
      data?: LocationResponse;
    }
  > {
    return this.customerLocationService.getCustomerLocationGet({ customer, location });
  }

  updateLocation(customer: number, location: number, body: CustomerLocationUpdateRequest) {
    return this.customerLocationService.putCustomerLocationUpdate({ customer, location, body });
  }

  createLocation(customer: number, body: CreateCustomerLocationRequest) {
    return this.customerLocationService.postCustomerLocationCreate({ customer, body });
  }

  preparePspUpdateModel(formValue: any): any {
    formValue = JSON.parse(JSON.stringify(formValue));
    // @ts-ignore
    const { name, legalName, vatId } = { ...formValue.contact };
    formValue.primaryData = { name, legalName, vatId };
    formValue.collectiveAgreement = formValue.contact.collectiveAgreement;
    formValue.openingHours = formValue.contact.openingHours;

    delete formValue.logo;
    delete formValue.contact.name;
    delete formValue.contact.legalName;
    delete formValue.contact.vatId;
    delete formValue.contact.collectiveAgreement;
    delete formValue.contact.openingHours;

    return formValue;
  }

  updatePspInfo(psp: number, body: any): Observable<EmptySuccessfulResponse> {
    return this.pspService.patchPspUpdate({ psp, body });
  }

  fetchPspInfo(psp: number): Observable<{ message: string; data: PspResponse }> {
    return this.pspService.getPspShow({ psp });
  }

  updateCustomerInfo(customer: number, body: CustomerMainInfoUpdateRequest): Observable<EmptySuccessfulResponse> {
    return this.customerService.putCustomerUpdate({ customer, body });
  }

  fetchCustomerInfo(customer: number): Observable<CustomerResponse | undefined> {
    return this.customerService
      .getCustomerGet({ customer })
      .pipe(map((response: { message?: string; data?: CustomerResponse | undefined }) => response.data));
  }

  updatePspBranch(branch: number, psp: number, body: PspBranchUpdateRequest): Observable<EmptySuccessfulResponse> {
    return this.pspBranchService.putPspBranchUpdate({ branch, psp, body });
  }

  invitePspUser(psp: number, body: PspInviteRequest): Observable<CreatedResponse> {
    return this.pspService.postPspsUsersInviteCreate({ psp, body });
  }

  inviteCustomerBusinessUser(customer: number, body: CustomerBusinessInviteRequest): Observable<CreatedResponse> {
    return this.customerService.postCustomersBusinessUsersInviteCreate({ customer, body });
  }

  inviteCustomerMedicalUser(customer: number, body: CustomerMedicalInviteRequest): Observable<CreatedResponse> {
    return this.customerService.postCustomersMedicalUsersInviteCreate({ customer, body });
  }

  getPermissions(): Observable<ListApiResponse & { data?: Array<PermissionItemResponse> }> {
    return this.catalogService.getAppApiWebV1CatalogsPermissionListIndex();
  }

  getCatalogQualifications(): Observable<GeneralApiResponse & { data?: QualificationResponse }> {
    return this.catalogService.getAppApiWebV1CatalogsQualificationListIndex();
  }

  prepareCustomerUpdateModel(formValue: any): any {
    formValue = JSON.parse(JSON.stringify(formValue));
    // @ts-ignore
    const { name, legalName, vatId } = { ...formValue.contact };
    formValue.primaryData = { name, legalName };
    formValue.collectiveAgreement = formValue.contact.collectiveAgreement;
    // @ts-ignore
    const { collectiveAgreementExtraCharge, collectiveAgreementRestriction, equalPay, maximumLeasePeriodDifference } = {
      ...formValue.temporaryEmployment,
    };

    formValue = {
      ...formValue,
      collectiveAgreementExtraCharge,
      collectiveAgreementRestriction,
      equalPay,
      maximumLeasePeriodDifference,
      vatId,
    };

    delete formValue.logo;
    delete formValue.contact.name;
    delete formValue.contact.legalName;
    delete formValue.contact.vatId;
    delete formValue.contact.collectiveAgreement;
    delete formValue.temporaryEmployment;

    return formValue;
  }

  getCustomerSurcharges(
    customer: number,
    location: number
  ): Observable<{ message: string; data: Array<SurchargeListItemResponse> }> {
    return this.customerSurchargeService.getCustomerSurchargeList({ customer, location });
  }

  getPspSurcharges(psp: number): Observable<ListApiResponse & { data?: Array<SurchargeListItemResponse> }> {
    return this.pspSurchargeService.getPspSurchargeList({ psp });
  }

  updateCustomerSurcharge(
    surcharge: number,
    customer: number,
    location: number,
    body: SurchargeUpdateRequest
  ): Observable<EmptySuccessfulResponse> {
    return this.customerSurchargeService
      .putCustomerSurchargeUpdate({ surcharge, customer, location, body })
      .pipe(take(1));
  }

  updatePspSurcharge(
    surcharge: number,
    psp: number,
    body: SurchargeUpdateRequest
  ): Observable<EmptySuccessfulResponse> {
    return this.pspSurchargeService.putPspSurchargeUpdate({ surcharge, psp, body });
  }

  getContractTemplate(customer: number): Observable<Blob> {
    return this.customerService.getCustomerContractTemplateDownload({ customer });
  }

  updateProfilePassword(body: ProfilePasswordUpdateRequest): Observable<EmptySuccessfulResponse> {
    return this.userService.putAppApiWebV1UserUserprofileUpdatepassword({ body });
  }

  updatePspUserPassword(params: {
    user: number;
    psp: number;
    body: UserPasswordUpdateRequest;
  }): Observable<EmptySuccessfulResponse> {
    return this.pspService.putPspsUsersPasswordUpdate(params);
  }

  updateCustomerUserPassword(params: {
    user: number;
    customer: number;
    body: UserPasswordUpdateRequest;
  }): Observable<EmptySuccessfulResponse> {
    return this.customerService.putCustomersUsersPasswordUpdate(params);
  }

  customerLocationTerminalsList(params: {
    customer: number;
    location: number;
  }): Observable<TerminalCustomerLocationListItemResponse[]> {
    return this.customerTerminalService.getCustomerLocationTerminalList(params).pipe(map((response) => response.data));
  }

  customerLocationTerminalUsageActivation(params: {
    customer: number;
    location: number;
    body: TerminalUsageRequest;
  }): Observable<EmptySuccessfulResponse> {
    return this.customerTerminalService.putTerminalUsage(params);
  }

  updateCustomerLocationTerminal(params: {
    customer: number;
    location: number;
    terminal: number;
    body: TerminalUpdateRequest;
  }): Observable<EmptySuccessfulResponse> {
    return this.customerTerminalService.putTerminalUpdate(params);
  }

  deleteCustomerLocationTerminal(params: {
    customer: number;
    location: number;
    terminal: number;
  }): Observable<EmptySuccessfulResponse> {
    return this.customerTerminalService.deleteTerminalDelete(params);
  }

  createCustomerLocationTerminal(params: {
    customer: number;
    location: number;
    body: TerminalCreateRequest;
  }): Observable<TerminalListItemResponse> {
    return this.customerTerminalService.postTerminalCreate(params).pipe(map((response) => response.data));
  }

  customerLocationTerminalQRCode(params: { customer: number; location: number; terminal: number }): Observable<any> {
    return this.customerTerminalService.getCustomerLocationTerminalQrCodeDownload$Response(params).pipe(
      map((response) => {
        const contentDispositionValue = response.headers.get('Content-Disposition');
        const fileName = BaseSharedHelperService.fileNameFromHeaderValue(contentDispositionValue, '=');
        return {
          body: response.body,
          fileName,
        };
      })
    );
  }
}
