/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CustomerAssignmentFilterListsResponse } from '../models/customer-assignment-filter-lists-response';
import { CustomerInquiryCreationResponse } from '../models/customer-inquiry-creation-response';
import { CustomerInquiryFilterListsResponse } from '../models/customer-inquiry-filter-lists-response';
import { CustomerInquiryListItemResponse } from '../models/customer-inquiry-list-item-response';
import { CustomerInquiryOfferListItemResponse } from '../models/customer-inquiry-offer-list-item-response';
import { EmptySuccessfulResponse } from '../models/empty-successful-response';
import { GeneralApiResponse } from '../models/general-api-response';
import { InquiryCreateRequest } from '../models/inquiry-create-request';
import { InquiryOfferAcceptRequest } from '../models/inquiry-offer-accept-request';
import { InquiryOfferDeclineRequest } from '../models/inquiry-offer-decline-request';
import { InquiryResponse } from '../models/inquiry-response';
import { InquiryUpdateStatusRequest } from '../models/inquiry-update-status-request';
import { OfferAmountResponse } from '../models/offer-amount-response';
import { PaginatedApiResponseWithFilters } from '../models/paginated-api-response-with-filters';

@Injectable({
  providedIn: 'root',
})
export class CustomerInquiryService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getCustomerListOfInquiries
   */
  static readonly GetCustomerListOfInquiriesPath = '/api/web/v1/customers/{customer}/inquiries';

  /**
   * Get list of inquiries by customer.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_INQUIRY'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerListOfInquiries()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerListOfInquiries$Response(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Page number in pagination.
     */
    page?: any;

    /**
     * Size number in pagination.
     */
    size?: any;

    /**
     * Filter by inquiry status: created(statuses[]&#x3D;1), published(statuses[]&#x3D;2), closed(statuses[]&#x3D;3)
     */
    'statuses[]'?: any;

    /**
     * Filter by Main Qualification Ids.
     */
    'mainQualifications[]'?: any;

    /**
     * Departments Ids to filter inquiries by.
     */
    'customerDepartments[]'?: any;

    /**
     * Include Dates
     */
    includeDates?: any;

    /**
     * If current&#x3D;true, filter by first inquiry date &gt;&#x3D; now
     */
    current?: any;

    /**
     * User identifier
     */
    createdBy?: any;

    /**
     * search given keyword using like word% in Inquiry.mainQualification.name,
     *      *         Inquiry.qualifications.name, Inquiry.customerDepartment.name, Inquiry.customerDepartment.legalName
     */
    search?: any;

    /**
     * Order inquiries by key.
     */
    orderKey?: 'createdAt' | 'departmentName' | 'mainQualificationName' | 'startDate' | 'status';

    /**
     * Order direction.
     */
    orderDirection?: 'ASC' | 'DESC';
    context?: HttpContext
  }
): Observable<StrictHttpResponse<PaginatedApiResponseWithFilters & {
'data'?: Array<CustomerInquiryListItemResponse>;
'filters'?: CustomerInquiryFilterListsResponse;
}>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerInquiryService.GetCustomerListOfInquiriesPath, 'get');
    if (params) {
      rb.path('customer', params.customer, {});
      rb.query('page', params.page, {});
      rb.query('size', params.size, {});
      rb.query('statuses[]', params['statuses[]'], {"style":"form"});
      rb.query('mainQualifications[]', params['mainQualifications[]'], {"style":"form"});
      rb.query('customerDepartments[]', params['customerDepartments[]'], {"style":"form"});
      rb.query('includeDates', params.includeDates, {"style":"form"});
      rb.query('current', params.current, {});
      rb.query('createdBy', params.createdBy, {});
      rb.query('search', params.search, {});
      rb.query('orderKey', params.orderKey, {});
      rb.query('orderDirection', params.orderDirection, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaginatedApiResponseWithFilters & {
        'data'?: Array<CustomerInquiryListItemResponse>;
        'filters'?: CustomerInquiryFilterListsResponse;
        }>;
      })
    );
  }

  /**
   * Get list of inquiries by customer.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_INQUIRY'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCustomerListOfInquiries$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerListOfInquiries(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Page number in pagination.
     */
    page?: any;

    /**
     * Size number in pagination.
     */
    size?: any;

    /**
     * Filter by inquiry status: created(statuses[]&#x3D;1), published(statuses[]&#x3D;2), closed(statuses[]&#x3D;3)
     */
    'statuses[]'?: any;

    /**
     * Filter by Main Qualification Ids.
     */
    'mainQualifications[]'?: any;

    /**
     * Departments Ids to filter inquiries by.
     */
    'customerDepartments[]'?: any;

    /**
     * Include Dates
     */
    includeDates?: any;

    /**
     * If current&#x3D;true, filter by first inquiry date &gt;&#x3D; now
     */
    current?: any;

    /**
     * User identifier
     */
    createdBy?: any;

    /**
     * search given keyword using like word% in Inquiry.mainQualification.name,
     *      *         Inquiry.qualifications.name, Inquiry.customerDepartment.name, Inquiry.customerDepartment.legalName
     */
    search?: any;

    /**
     * Order inquiries by key.
     */
    orderKey?: 'createdAt' | 'departmentName' | 'mainQualificationName' | 'startDate' | 'status';

    /**
     * Order direction.
     */
    orderDirection?: 'ASC' | 'DESC';
    context?: HttpContext
  }
): Observable<PaginatedApiResponseWithFilters & {
'data'?: Array<CustomerInquiryListItemResponse>;
'filters'?: CustomerInquiryFilterListsResponse;
}> {

    return this.getCustomerListOfInquiries$Response(params).pipe(
      map((r: StrictHttpResponse<PaginatedApiResponseWithFilters & {
'data'?: Array<CustomerInquiryListItemResponse>;
'filters'?: CustomerInquiryFilterListsResponse;
}>) => r.body as PaginatedApiResponseWithFilters & {
'data'?: Array<CustomerInquiryListItemResponse>;
'filters'?: CustomerInquiryFilterListsResponse;
})
    );
  }

  /**
   * Path part for operation postInquiryCreate
   */
  static readonly PostInquiryCreatePath = '/api/web/v1/customers/{customer}/inquiries';

  /**
   * Create a customer inquiry.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_INQUIRY'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postInquiryCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postInquiryCreate$Response(params: {

    /**
     * Customer Id.
     */
    customer: number;
    context?: HttpContext
    body: InquiryCreateRequest
  }
): Observable<StrictHttpResponse<GeneralApiResponse & {
'data'?: CustomerInquiryCreationResponse;
}>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerInquiryService.PostInquiryCreatePath, 'post');
    if (params) {
      rb.path('customer', params.customer, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GeneralApiResponse & {
        'data'?: CustomerInquiryCreationResponse;
        }>;
      })
    );
  }

  /**
   * Create a customer inquiry.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_INQUIRY'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postInquiryCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postInquiryCreate(params: {

    /**
     * Customer Id.
     */
    customer: number;
    context?: HttpContext
    body: InquiryCreateRequest
  }
): Observable<GeneralApiResponse & {
'data'?: CustomerInquiryCreationResponse;
}> {

    return this.postInquiryCreate$Response(params).pipe(
      map((r: StrictHttpResponse<GeneralApiResponse & {
'data'?: CustomerInquiryCreationResponse;
}>) => r.body as GeneralApiResponse & {
'data'?: CustomerInquiryCreationResponse;
})
    );
  }

  /**
   * Path part for operation getCustomerInquiryShow
   */
  static readonly GetCustomerInquiryShowPath = '/api/web/v1/customers/{customer}/inquiries/{inquiry}';

  /**
   * Get inquiry.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_INQUIRY'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerInquiryShow()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerInquiryShow$Response(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Inquiry Id.
     */
    inquiry: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<GeneralApiResponse & {
'data'?: InquiryResponse;
}>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerInquiryService.GetCustomerInquiryShowPath, 'get');
    if (params) {
      rb.path('customer', params.customer, {});
      rb.path('inquiry', params.inquiry, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GeneralApiResponse & {
        'data'?: InquiryResponse;
        }>;
      })
    );
  }

  /**
   * Get inquiry.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_INQUIRY'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCustomerInquiryShow$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerInquiryShow(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Inquiry Id.
     */
    inquiry: number;
    context?: HttpContext
  }
): Observable<GeneralApiResponse & {
'data'?: InquiryResponse;
}> {

    return this.getCustomerInquiryShow$Response(params).pipe(
      map((r: StrictHttpResponse<GeneralApiResponse & {
'data'?: InquiryResponse;
}>) => r.body as GeneralApiResponse & {
'data'?: InquiryResponse;
})
    );
  }

  /**
   * Path part for operation putCustomerInquiryUpdate
   */
  static readonly PutCustomerInquiryUpdatePath = '/api/web/v1/customers/{customer}/inquiries/{inquiry}';

  /**
   * Update a customer inquiry.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_INQUIRY'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putCustomerInquiryUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putCustomerInquiryUpdate$Response(params: {

    /**
     * Customer Id.
     */
    customer: number;
    inquiry: string;
    context?: HttpContext
    body: InquiryCreateRequest
  }
): Observable<StrictHttpResponse<EmptySuccessfulResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerInquiryService.PutCustomerInquiryUpdatePath, 'put');
    if (params) {
      rb.path('customer', params.customer, {});
      rb.path('inquiry', params.inquiry, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmptySuccessfulResponse>;
      })
    );
  }

  /**
   * Update a customer inquiry.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_INQUIRY'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putCustomerInquiryUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putCustomerInquiryUpdate(params: {

    /**
     * Customer Id.
     */
    customer: number;
    inquiry: string;
    context?: HttpContext
    body: InquiryCreateRequest
  }
): Observable<EmptySuccessfulResponse> {

    return this.putCustomerInquiryUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<EmptySuccessfulResponse>) => r.body as EmptySuccessfulResponse)
    );
  }

  /**
   * Path part for operation deleteCustomerInquiryDelete
   */
  static readonly DeleteCustomerInquiryDeletePath = '/api/web/v1/customers/{customer}/inquiries/{inquiry}';

  /**
   * Delete inquiry.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_INQUIRY'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteCustomerInquiryDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCustomerInquiryDelete$Response(params: {

    /**
     * Customer Id.
     */
    customer: number;
    inquiry: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EmptySuccessfulResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerInquiryService.DeleteCustomerInquiryDeletePath, 'delete');
    if (params) {
      rb.path('customer', params.customer, {});
      rb.path('inquiry', params.inquiry, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmptySuccessfulResponse>;
      })
    );
  }

  /**
   * Delete inquiry.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_INQUIRY'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteCustomerInquiryDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCustomerInquiryDelete(params: {

    /**
     * Customer Id.
     */
    customer: number;
    inquiry: number;
    context?: HttpContext
  }
): Observable<EmptySuccessfulResponse> {

    return this.deleteCustomerInquiryDelete$Response(params).pipe(
      map((r: StrictHttpResponse<EmptySuccessfulResponse>) => r.body as EmptySuccessfulResponse)
    );
  }

  /**
   * Path part for operation patchCustomerInquiryOfferAccept
   */
  static readonly PatchCustomerInquiryOfferAcceptPath = '/api/web/v1/customers/{customer}/inquiries/{inquiry}/offers/{offer}';

  /**
   * Accept an offer.
   *
   * **Accessible only for users with permission 'FEATURE_ACCEPT_OFFER'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchCustomerInquiryOfferAccept()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchCustomerInquiryOfferAccept$Response(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Inquiry Id.
     */
    inquiry: number;

    /**
     * Offer Id.
     */
    offer: number;
    context?: HttpContext
    body: InquiryOfferAcceptRequest
  }
): Observable<StrictHttpResponse<EmptySuccessfulResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerInquiryService.PatchCustomerInquiryOfferAcceptPath, 'patch');
    if (params) {
      rb.path('customer', params.customer, {});
      rb.path('inquiry', params.inquiry, {});
      rb.path('offer', params.offer, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmptySuccessfulResponse>;
      })
    );
  }

  /**
   * Accept an offer.
   *
   * **Accessible only for users with permission 'FEATURE_ACCEPT_OFFER'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchCustomerInquiryOfferAccept$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchCustomerInquiryOfferAccept(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Inquiry Id.
     */
    inquiry: number;

    /**
     * Offer Id.
     */
    offer: number;
    context?: HttpContext
    body: InquiryOfferAcceptRequest
  }
): Observable<EmptySuccessfulResponse> {

    return this.patchCustomerInquiryOfferAccept$Response(params).pipe(
      map((r: StrictHttpResponse<EmptySuccessfulResponse>) => r.body as EmptySuccessfulResponse)
    );
  }

  /**
   * Path part for operation postCustomerInquiryOfferAmount
   */
  static readonly PostCustomerInquiryOfferAmountPath = '/api/web/v1/customers/{customer}/inquiries/{inquiry}/offers/{offer}/amount';

  /**
   * Calculate amount.
   *
   * **Accessible only for users with permission 'FEATURE_ACCEPT_OFFER'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postCustomerInquiryOfferAmount()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postCustomerInquiryOfferAmount$Response(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Inquiry Id.
     */
    inquiry: number;

    /**
     * Offer Id.
     */
    offer: number;
    context?: HttpContext
    body: InquiryOfferAcceptRequest
  }
): Observable<StrictHttpResponse<GeneralApiResponse & {
'data'?: OfferAmountResponse;
}>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerInquiryService.PostCustomerInquiryOfferAmountPath, 'post');
    if (params) {
      rb.path('customer', params.customer, {});
      rb.path('inquiry', params.inquiry, {});
      rb.path('offer', params.offer, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GeneralApiResponse & {
        'data'?: OfferAmountResponse;
        }>;
      })
    );
  }

  /**
   * Calculate amount.
   *
   * **Accessible only for users with permission 'FEATURE_ACCEPT_OFFER'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postCustomerInquiryOfferAmount$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postCustomerInquiryOfferAmount(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Inquiry Id.
     */
    inquiry: number;

    /**
     * Offer Id.
     */
    offer: number;
    context?: HttpContext
    body: InquiryOfferAcceptRequest
  }
): Observable<GeneralApiResponse & {
'data'?: OfferAmountResponse;
}> {

    return this.postCustomerInquiryOfferAmount$Response(params).pipe(
      map((r: StrictHttpResponse<GeneralApiResponse & {
'data'?: OfferAmountResponse;
}>) => r.body as GeneralApiResponse & {
'data'?: OfferAmountResponse;
})
    );
  }

  /**
   * Path part for operation putCustomerInquiryOfferDecline
   */
  static readonly PutCustomerInquiryOfferDeclinePath = '/api/web/v1/customers/{customer}/inquiries/{inquiry}/offers/{offer}/declining';

  /**
   * Decline an offer.
   *
   * **Accessible only for users with permission 'FEATURE_ACCEPT_OFFER'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putCustomerInquiryOfferDecline()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putCustomerInquiryOfferDecline$Response(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Inquiry Id.
     */
    inquiry: number;

    /**
     * Offer Id.
     */
    offer: number;
    context?: HttpContext
    body: InquiryOfferDeclineRequest
  }
): Observable<StrictHttpResponse<EmptySuccessfulResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerInquiryService.PutCustomerInquiryOfferDeclinePath, 'put');
    if (params) {
      rb.path('customer', params.customer, {});
      rb.path('inquiry', params.inquiry, {});
      rb.path('offer', params.offer, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmptySuccessfulResponse>;
      })
    );
  }

  /**
   * Decline an offer.
   *
   * **Accessible only for users with permission 'FEATURE_ACCEPT_OFFER'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putCustomerInquiryOfferDecline$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putCustomerInquiryOfferDecline(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Inquiry Id.
     */
    inquiry: number;

    /**
     * Offer Id.
     */
    offer: number;
    context?: HttpContext
    body: InquiryOfferDeclineRequest
  }
): Observable<EmptySuccessfulResponse> {

    return this.putCustomerInquiryOfferDecline$Response(params).pipe(
      map((r: StrictHttpResponse<EmptySuccessfulResponse>) => r.body as EmptySuccessfulResponse)
    );
  }

  /**
   * Path part for operation getCustomerInquiryOfferDocumentFileDownload
   */
  static readonly GetCustomerInquiryOfferDocumentFileDownloadPath = '/api/web/v1/customers/{customer}/inquiries/{inquiry}/offers/{offer}/documents/{document}/file';

  /**
   * Download a file.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerInquiryOfferDocumentFileDownload()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerInquiryOfferDocumentFileDownload$Response(params: {
    customer: number;
    inquiry: number;
    offer: number;
    document: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerInquiryService.GetCustomerInquiryOfferDocumentFileDownloadPath, 'get');
    if (params) {
      rb.path('customer', params.customer, {});
      rb.path('inquiry', params.inquiry, {});
      rb.path('offer', params.offer, {});
      rb.path('document', params.document, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'multipart/form-data',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Download a file.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCustomerInquiryOfferDocumentFileDownload$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerInquiryOfferDocumentFileDownload(params: {
    customer: number;
    inquiry: number;
    offer: number;
    document: string;
    context?: HttpContext
  }
): Observable<Blob> {

    return this.getCustomerInquiryOfferDocumentFileDownload$Response(params).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Path part for operation getCustomerInquiryOffersList
   */
  static readonly GetCustomerInquiryOffersListPath = '/api/web/v1/customers/{customer}/inquiries/{inquiry}/offers';

  /**
   * Get Customer InquiryOffers List. Returns all accepted offers (does not filter by search),
   * filters only not accepted offers by search.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_INQUIRY'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerInquiryOffersList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerInquiryOffersList$Response(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Inquiry Id.
     */
    inquiry: number;

    /**
     * Page number in pagination.
     */
    page?: any;

    /**
     * Size number in pagination.
     */
    size?: any;

    /**
     * Search by given keyword for profile firstName and lastName or for PSPBranch name and legalName.
     *      *         Returns all accepted offers (does not filter by search). Filters only not accepted offers by search
     */
    search?: string;

    /**
     * Filter by Offer status
     */
    status?: '0' | '2' | '3' | '4' | '5' | '6' | '100';

    /**
     * Filter by Main Qualifications
     */
    'mainQualifications[]'?: any;

    /**
     * Filter by PSP branches id
     */
    'pspBranches[]'?: any;

    /**
     * Filter by profileAvarageRating
     */
    profileAverageRating?: number;
    experience?: boolean;

    /**
     * Order offer key.
     */
    orderKey?: 'price' | 'profileLastName' | 'profileFirstName' | 'pspLegalName' | 'qualificationName' | 'availableFrom';

    /**
     * Order direction.
     */
    orderDirection?: 'ASC' | 'DESC';
    context?: HttpContext
  }
): Observable<StrictHttpResponse<PaginatedApiResponseWithFilters & {
'data'?: Array<CustomerInquiryOfferListItemResponse>;
'filters'?: CustomerAssignmentFilterListsResponse;
}>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerInquiryService.GetCustomerInquiryOffersListPath, 'get');
    if (params) {
      rb.path('customer', params.customer, {});
      rb.path('inquiry', params.inquiry, {});
      rb.query('page', params.page, {});
      rb.query('size', params.size, {});
      rb.query('search', params.search, {});
      rb.query('status', params.status, {});
      rb.query('mainQualifications[]', params['mainQualifications[]'], {});
      rb.query('pspBranches[]', params['pspBranches[]'], {});
      rb.query('profileAverageRating', params.profileAverageRating, {});
      rb.query('experience', params.experience, {});
      rb.query('orderKey', params.orderKey, {});
      rb.query('orderDirection', params.orderDirection, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaginatedApiResponseWithFilters & {
        'data'?: Array<CustomerInquiryOfferListItemResponse>;
        'filters'?: CustomerAssignmentFilterListsResponse;
        }>;
      })
    );
  }

  /**
   * Get Customer InquiryOffers List. Returns all accepted offers (does not filter by search),
   * filters only not accepted offers by search.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_INQUIRY'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCustomerInquiryOffersList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerInquiryOffersList(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Inquiry Id.
     */
    inquiry: number;

    /**
     * Page number in pagination.
     */
    page?: any;

    /**
     * Size number in pagination.
     */
    size?: any;

    /**
     * Search by given keyword for profile firstName and lastName or for PSPBranch name and legalName.
     *      *         Returns all accepted offers (does not filter by search). Filters only not accepted offers by search
     */
    search?: string;

    /**
     * Filter by Offer status
     */
    status?: '0' | '2' | '3' | '4' | '5' | '6' | '100';

    /**
     * Filter by Main Qualifications
     */
    'mainQualifications[]'?: any;

    /**
     * Filter by PSP branches id
     */
    'pspBranches[]'?: any;

    /**
     * Filter by profileAvarageRating
     */
    profileAverageRating?: number;
    experience?: boolean;

    /**
     * Order offer key.
     */
    orderKey?: 'price' | 'profileLastName' | 'profileFirstName' | 'pspLegalName' | 'qualificationName' | 'availableFrom';

    /**
     * Order direction.
     */
    orderDirection?: 'ASC' | 'DESC';
    context?: HttpContext
  }
): Observable<PaginatedApiResponseWithFilters & {
'data'?: Array<CustomerInquiryOfferListItemResponse>;
'filters'?: CustomerAssignmentFilterListsResponse;
}> {

    return this.getCustomerInquiryOffersList$Response(params).pipe(
      map((r: StrictHttpResponse<PaginatedApiResponseWithFilters & {
'data'?: Array<CustomerInquiryOfferListItemResponse>;
'filters'?: CustomerAssignmentFilterListsResponse;
}>) => r.body as PaginatedApiResponseWithFilters & {
'data'?: Array<CustomerInquiryOfferListItemResponse>;
'filters'?: CustomerAssignmentFilterListsResponse;
})
    );
  }

  /**
   * Path part for operation postCustomerInquiryOfferPreSelection
   */
  static readonly PostCustomerInquiryOfferPreSelectionPath = '/api/web/v1/customers/{customer}/inquiries/{inquiry}/offers/{offer}/pre-selection';

  /**
   * Pre-select an offer.
   *
   * **Accessible only for users with permission 'FEATURE_PRESELECT_OFFERS'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postCustomerInquiryOfferPreSelection()` instead.
   *
   * This method doesn't expect any request body.
   */
  postCustomerInquiryOfferPreSelection$Response(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Inquiry Id.
     */
    inquiry: number;

    /**
     * Offer Id.
     */
    offer: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EmptySuccessfulResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerInquiryService.PostCustomerInquiryOfferPreSelectionPath, 'post');
    if (params) {
      rb.path('customer', params.customer, {});
      rb.path('inquiry', params.inquiry, {});
      rb.path('offer', params.offer, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmptySuccessfulResponse>;
      })
    );
  }

  /**
   * Pre-select an offer.
   *
   * **Accessible only for users with permission 'FEATURE_PRESELECT_OFFERS'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postCustomerInquiryOfferPreSelection$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  postCustomerInquiryOfferPreSelection(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Inquiry Id.
     */
    inquiry: number;

    /**
     * Offer Id.
     */
    offer: number;
    context?: HttpContext
  }
): Observable<EmptySuccessfulResponse> {

    return this.postCustomerInquiryOfferPreSelection$Response(params).pipe(
      map((r: StrictHttpResponse<EmptySuccessfulResponse>) => r.body as EmptySuccessfulResponse)
    );
  }

  /**
   * Path part for operation deleteCustomerInquiryOfferUnselection
   */
  static readonly DeleteCustomerInquiryOfferUnselectionPath = '/api/web/v1/customers/{customer}/inquiries/{inquiry}/offers/{offer}/pre-selection';

  /**
   * Unselect an offer.
   *
   * **Accessible only for users with permission 'FEATURE_PRESELECT_OFFERS'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteCustomerInquiryOfferUnselection()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCustomerInquiryOfferUnselection$Response(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Inquiry Id.
     */
    inquiry: number;

    /**
     * Offer Id.
     */
    offer: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EmptySuccessfulResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerInquiryService.DeleteCustomerInquiryOfferUnselectionPath, 'delete');
    if (params) {
      rb.path('customer', params.customer, {});
      rb.path('inquiry', params.inquiry, {});
      rb.path('offer', params.offer, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmptySuccessfulResponse>;
      })
    );
  }

  /**
   * Unselect an offer.
   *
   * **Accessible only for users with permission 'FEATURE_PRESELECT_OFFERS'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteCustomerInquiryOfferUnselection$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCustomerInquiryOfferUnselection(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Inquiry Id.
     */
    inquiry: number;

    /**
     * Offer Id.
     */
    offer: number;
    context?: HttpContext
  }
): Observable<EmptySuccessfulResponse> {

    return this.deleteCustomerInquiryOfferUnselection$Response(params).pipe(
      map((r: StrictHttpResponse<EmptySuccessfulResponse>) => r.body as EmptySuccessfulResponse)
    );
  }

  /**
   * Path part for operation patchCustomerInquiryUpdateStatus
   */
  static readonly PatchCustomerInquiryUpdateStatusPath = '/api/web/v1/customers/{customer}/inquiries/{inquiry}/status';

  /**
   * Update customer inquiry status.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_INQUIRY'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchCustomerInquiryUpdateStatus()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchCustomerInquiryUpdateStatus$Response(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Inquiry Id.
     */
    inquiry: number;
    context?: HttpContext
    body: InquiryUpdateStatusRequest
  }
): Observable<StrictHttpResponse<EmptySuccessfulResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerInquiryService.PatchCustomerInquiryUpdateStatusPath, 'patch');
    if (params) {
      rb.path('customer', params.customer, {});
      rb.path('inquiry', params.inquiry, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmptySuccessfulResponse>;
      })
    );
  }

  /**
   * Update customer inquiry status.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_INQUIRY'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchCustomerInquiryUpdateStatus$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchCustomerInquiryUpdateStatus(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Inquiry Id.
     */
    inquiry: number;
    context?: HttpContext
    body: InquiryUpdateStatusRequest
  }
): Observable<EmptySuccessfulResponse> {

    return this.patchCustomerInquiryUpdateStatus$Response(params).pipe(
      map((r: StrictHttpResponse<EmptySuccessfulResponse>) => r.body as EmptySuccessfulResponse)
    );
  }

}
