import { ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import * as fromAuth from './base-auth.reducer';
import * as fromShared from './base-shared.reducer';
import * as fromAdministration from './base-administration.reducer';
import * as fromForms from './base-forms.reducer';
import { localStorageSync } from 'ngrx-store-localstorage';

export interface BaseCoreState {
  auth: fromAuth.BaseAuthState;
  shared: fromShared.SharedState;
  administration: fromAdministration.AdministrationState;
  forms: fromForms.BaseFormsState;
}

export const BASE_REDUCERS: ActionReducerMap<any> = {
  auth: fromAuth.baseAuthReducer,
  shared: fromShared.baseSharedReducer,
  administration: fromAdministration.baseAdministrationReducer,
  forms: fromForms.baseFormsReducer,
};

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({
    keys: ['forms', { shared: ['isSidebarInitialOpening'] }],
    rehydrate: true,
    storage: sessionStorage,
  })(reducer);
}

export const BASE_META_REDUCERS: MetaReducer<BaseCoreState>[] = [localStorageSyncReducer];
