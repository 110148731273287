import { createAction, props } from '@ngrx/store';
import { BranchListItemResponse, PspDashboardForecastResponse, PspDashboardResponse } from '../../../api';

export const loadPspDashboardData = createAction(
  '[PspDashboard] Load psp dashboard data',
  props<{ payload: number | undefined }>()
);

export const setPspDashboardData = createAction(
  '[PspDashboard] Set psp dashboard data',
  props<{ payload: PspDashboardResponse | undefined }>()
);

export const errorPspDashboard = createAction('[PspDashboard] Error psp dashboard');
export const loadPspBranches = createAction(
  '[PspDashboard] Load psp branches',
  props<{ payload: BranchListItemResponse[] }>()
);
export const loadPspBranchesSuccess = createAction('[PspDashboard] Load psp branches successfully');
export const loadPspBranchesError = createAction('[PspBranch] Load psp branches error');

export const pspBranchesFilterChanged = createAction(
  '[PspDashboard] Psp assignments filter changed',
  props<{ payload: number }>()
);

export const loadPspSalesForecast = createAction(
  '[PspDashboard] Load psp sales forecast',
  props<{ payload: { from: string; to: string } }>()
);
export const setPspSalesForecast = createAction(
  '[PspDashboard] Set psp sales forecast',
  props<{ payload: PspDashboardForecastResponse }>()
);
export const successPspSalesForecast = createAction('[PspDashboard] Success psp sales forecast');
export const errorPspSalesForecast = createAction('[PspDashboard] Error psp sales forecast');
