import { Injectable } from '@angular/core';
import {
  CatalogService,
  CustomerService,
  DocumentCategoryResponse,
  DocumentCreationResponse,
  DocumentListItemResponse,
  DocumentRequest,
  DocumentResponse,
  EmptySuccessfulResponse,
} from '../../../api';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { BaseSharedHelperService } from '../../shared/services/base-shared-helper.service';

@Injectable()
export class BaseCustomerDocumentsService {
  constructor(private readonly customerService: CustomerService, private readonly catalogService: CatalogService) {}

  getDocuments(customer: number, location: number, _page?: number): Observable<Array<DocumentListItemResponse>> {
    return this.customerService.getCustomerLocationDocumentList({ customer, location }).pipe(
      take(1),
      map((response: { message: string; data: Array<DocumentListItemResponse> }) => response.data)
    );
  }

  createDocument(customer: number, location: number, file: Blob): Observable<DocumentCreationResponse> {
    return this.customerService.postCustomerLocationDocumentCreate({ customer, location, body: { file } }).pipe(
      take(1),
      map((response: { message: string; data: DocumentCreationResponse }) => response.data)
    );
  }

  getDocumentInfo(customer: number, location: number, document: string): Observable<DocumentResponse> {
    return this.customerService.getCustomerLocationDocumentShow({ document, location, customer }).pipe(
      take(1),
      map((response: { message: string; data: DocumentResponse }) => response.data)
    );
  }

  updateDocument(
    customer: number,
    location: number,
    document: string,
    body: DocumentRequest
  ): Observable<EmptySuccessfulResponse> {
    return this.customerService.putCustomerLocationDocumentUpdate({ document, customer, location, body }).pipe(take(1));
  }

  changeDocumentFile(
    customer: number,
    location: number,
    document: string,
    file: Blob
  ): Observable<EmptySuccessfulResponse> {
    return this.customerService
      .postCustomerLocationDocumentFileUpdate({ customer, location, document, body: { file } })
      .pipe(take(1));
  }

  deleteDocument(customer: number, location: number, document: string): Observable<EmptySuccessfulResponse> {
    return this.customerService.deleteCustomerLocationDocumentDelete({ customer, location, document }).pipe(take(1));
  }

  downloadDocument(customer: number, location: number, document: string): Observable<any> {
    return this.customerService.getCustomerLocationDocumentFileDownload$Response({ customer, location, document }).pipe(
      map((response) => {
        const contentDispositionValue = response.headers.get('Content-Disposition');
        const fileName = BaseSharedHelperService.fileNameFromHeaderValue(contentDispositionValue, '=');
        return {
          body: response.body,
          fileName,
        };
      })
    );
  }

  getDocumentCategories(): Observable<DocumentCategoryResponse> {
    return this.catalogService.getDocumentsCategories().pipe(
      take(1),
      map((result) => result.data)
    );
  }
}
