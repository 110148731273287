<div class="auth-teaser teaser">
  <form
    class="login-form"
    [formGroup]="loginForm"
    (ngSubmit)="login()"
    [ngClass]="{ 'was-validated': loginFormSubmitted }"
  >
    <h4 class="headline-36 mb-5 text-center">{{ 'auth.login' | translate }}</h4>
    <div class="form-group w-100">
      <base-input
        [autofocus]="true"
        placeholder="sampleEmail"
        [isEmailField]="true"
        [isInvalid]="!!loginForm.controls.username.errors"
        [errorMessage]="loginForm.controls.username.errors | baseFieldValidation"
        [size]="inputSize.lg"
        label="email"
        formControlName="username"
        data-cy="login-form.username"
      ></base-input>
    </div>
    <div class="form-group w-100">
      <base-password
        [isInvalid]="!!loginForm.controls.password.errors"
        [errorMessage]="loginForm.controls.password.errors | baseFieldValidation"
        label="auth.password.title"
        placeholder="*****************"
        formControlName="password"
        data-cy="login-form.password"
      ></base-password>
    </div>
    <div class="d-flex align-items-center justify-content-start flex-column">
      <base-button
        class="mb-5"
        [type]="buttonType.submit"
        label="action.login"
        [size]="buttonSize.md"
        color="primary"
        data-cy="login.submit"
      ></base-button>
      <p class="text-center">
        <a
          class="text-link text-primary"
          (click)="showResetPasswordModal()"
          data-cy="reset-button"
        >
          {{ 'auth.password.resetQuestion' | translate }}</a
        >
      </p>
      <p
        *ngIf="!isCustomizedUser"
        class="text-center"
      >
        <a
          routerLink="/auth/register"
          data-cy="login-form.register-link"
        >
          {{ 'auth.registered.noAccountYet' | translate }}
        </a>
      </p>
    </div>
  </form>
</div>

<ng-template #resetPasswordTemplate>
  <form
    class="reset-form"
    [formGroup]="resetForm"
    data-cy="reset-modal"
    [ngClass]="{ 'was-validated': resetFormSubmitted }"
  >
    <div class="form-group w-100">
      <base-input
        [isInvalid]="!!resetForm.controls.email.errors"
        placeholder=""
        [errorMessage]="resetForm.controls.email.errors | baseFieldValidation: { required: 'yourEmail' }"
        [isEmailField]="true"
        label="yourEmail"
        formControlName="email"
        data-cy="reset-email"
      ></base-input>
    </div>
  </form>
</ng-template>
