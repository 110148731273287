import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'base-page-title',
  templateUrl: './base-page-title.component.html',
  styleUrls: ['./base-page-title.component.scss'],
})
export class BasePageTitleComponent implements OnDestroy {
  title: string;
  private destroy$ = new Subject();

  constructor(public activatedRoute: ActivatedRoute, private router: Router) {
    this.router.events
      .pipe(
        takeUntil(this.destroy$),
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        map((route) => route.snapshot)
      )
      .subscribe((routeSnapshot) => {
        this.title = routeSnapshot.data?.title;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }
}
