import { Component, Input } from '@angular/core';

@Component({
  selector: 'base-go-back-teaser',
  templateUrl: './base-go-back-teaser.component.html',
})
export class BaseGoBackTeaserComponent {
  @Input() qualificationName: string;
  @Input() dateFrom: string;
  @Input() dateTo: string;
}
