import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { baseInquiryActions, baseInquirySelectors, BaseCoreState } from '../../../_store';

@Injectable()
export class BaseResponsibleUsersGuard {
  constructor(private store: Store<BaseCoreState>) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    this.store.dispatch(baseInquiryActions.loadResponsibleUserList({ payload: route.params.inquiry }));

    return this.store.select(baseInquirySelectors.getResponsibleUsers).pipe(
      filter((state) => state.ok !== undefined),
      map((state) => !!state.ok)
    );
  }
}
