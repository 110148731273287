<div
  class="password"
  [ngClass]="{ 'label-align-left': isLabelPositionLeft }"
>
  <label
    *ngIf="label"
    [ngClass]="{ required: isRequired }"
    [ngbTooltip]="labelTooltip"
    [tooltipClass]="tooltipClass"
    [placement]="tooltipPlacement"
    >{{ label | translate }}</label
  >
  <div
    class="password-wrapper"
    [ngClass]="{ 'custom-invalid-icon': invalidIcon, 'visible-icon': isEyeIcon }"
  >
    <i [ngClass]="{ 'is-invalid': isInvalid }"></i>
    <input
      [type]="type"
      #input
      [placeholder]="placeholder"
      [tabindex]="tabindex"
      class="form-control"
      [(ngModel)]="password"
      [autocomplete]="autoCompleteValue"
    />
    <i
      *ngIf="invalidIcon"
      class="me-5"
      [ngClass]="invalidIcon"
    ></i>
    <span
      class="eye-icon"
      (click)="changeVisibility()"
    >
      <i
        *ngIf="isEyeIcon"
        [ngbTooltip]="(isVisible ? 'tooltip.hidePassword' : 'tooltip.revealPassword') | translate"
        [ngClass]="[isVisible ? 'fa-solid fa-eye-slash' : 'fa-solid fa-eye']"
      ></i>
    </span>
  </div>
  <p
    *ngIf="isInvalid"
    class="invalid-feedback"
    [innerHTML]="errorMessage && (errorMessage | translate)"
  ></p>
  <ng-container *ngIf="isInvalid">
    <p
      *ngFor="let error of errorKeys"
      class="invalid-feedback"
    >
      {{ getErrorObject(error) | baseFieldValidation: errorMessageOverride | translate }}
    </p>
  </ng-container>
</div>
