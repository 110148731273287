import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { catchError, debounceTime, filter, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import {
  BestMatchingProfileListItemResponse,
  CustomerDepartmentService,
  CustomerInquiryListItemResponse,
  InquiryDateResponse,
  ShiftTimeResponse,
} from '../../../api';
import { BaseTokenInterface } from '../../shared/interfaces/base-token-service.interface';
import { baseInquiryActions, baseOffersAndBookingsActions, baseSharedActions } from '../actions';
import { baseAuthSelectors, baseInquirySelectors } from '../selectors';
import { BaseInquiryService } from '../../modules/inquiry/_services/base-inquiry.service';
import { BaseCoreState } from '../reducers';
import {
  InquiryBestCandidatesPagination,
  InquiryOffersPagination,
} from '../../modules/inquiry/_enums/inquiry-candidates-pagination.enum';
import { BaseInquiryType } from '../../modules/inquiry/_enums/base-inquiry.enum';
import { BaseAvailableFileTypeEnum } from '../../shared/enums/base-available-file-type.enum';
import { BaseToastAlertClasses } from '../../shared/enums/base-toast-alert-classes.enum';
import { BaseInquiryCandidateService } from '../../modules/inquiry/_services/base-inquiry-candidate.service';
import { BaseShiftPlanService } from '../../modules/inquiry/_services/base-shift-plan.service';
import { BasePspInquiryOfferInterface } from '../../modules/inquiry/_interfaces/base-psp-inquiry-offer.interface';
import { BasePspInquiryOfferService } from '../../modules/inquiry/_services/base-psp-inquiry-offer.service';
import { BaseCustomerInquiryOfferInterface } from '../../modules/inquiry/_interfaces/base-cusomer-inquiry-offer.interface';
import { BaseCustomerInquiryOfferService } from '../../modules/inquiry/_services/base-customer-inquiry-offer.service';
import { ShiftPlanCalendarRangeInterface } from '../../modules/inquiry/_interfaces/base-shift-plan.interface';
import { BaseSimilarInquiriesService } from '../../modules/inquiry/_services/base-similar-inquiries.service';
import {
  BaseInquiryCandidateInterface,
  BaseUnlimitedInquiryCandidateInterface,
} from '../../modules/inquiry/_interfaces/base-inquiry-candidate.interface';
import { BASE_INQUIRY_CANDIDATES_VISIBLE_LIMIT } from '../../modules/inquiry/_const/base-inquiry-candidates-visible-limit.const';
import { BaseQueryParamService } from '../../shared/services/base-query-param.service';
import { BaseFilterService } from '../../shared/services/base-filter.service';

@Injectable()
export class BaseInquiryEffects {
  constructor(
    private actions$: Actions,
    private store: Store<BaseCoreState>,
    private router: Router,
    private baseInquiryService: BaseInquiryService,
    private departmentService: CustomerDepartmentService,
    private sanitizer: DomSanitizer,
    private baseInquiryCandidateService: BaseInquiryCandidateService,
    private baseShiftViewPlanService: BaseShiftPlanService,
    private basePspInquiryOfferService: BasePspInquiryOfferService,
    private baseCustomerInquiryOfferService: BaseCustomerInquiryOfferService,
    private baseSimilarInquiriesService: BaseSimilarInquiriesService,
    private queryParamsService: BaseQueryParamService,
    private baseFilterService: BaseFilterService
  ) {}

  getInquiries$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseInquiryActions.getInquiries),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((user) => user.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        return this.baseInquiryService.getInquiries({ customer: user.organizationId, ...action.payload }).pipe(
          mergeMap((response) => [
            baseInquiryActions.loadInquiries({ payload: response }),
            baseInquiryActions.successLoadInquiries(),
          ]),
          catchError(() => of(baseInquiryActions.errorLoadInquiries()))
        );
      })
    );
  });

  getPspInquiries$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseInquiryActions.getPSPInquiries),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((user) => user.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        return this.baseInquiryService.getPSPInquiries({ psp: user.organizationId, ...action.payload }).pipe(
          mergeMap((response) => [
            baseInquiryActions.loadInquiries({ payload: response }),
            baseInquiryActions.successLoadInquiries(),
          ]),
          catchError(() => of(baseInquiryActions.errorLoadInquiries()))
        );
      })
    );
  });

  createInquiry$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseInquiryActions.createInquiry),
      withLatestFrom(this.store.select(baseAuthSelectors.getUserInfo)),
      switchMap(([action, user]) => {
        return this.baseInquiryService
          .createInquiry(user.data!.organizationId, action.payload)
          .pipe(mergeMap(() => [baseInquiryActions.successInquiryCRUD()]));
      }),
      catchError(() => of(baseInquiryActions.errorInquiryCRUD()))
    );
  });

  createAndPublishInquiry$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseInquiryActions.createAndPublishInquiry),
      withLatestFrom(this.store.select(baseAuthSelectors.getUserInfo)),
      switchMap(([action, user]) => {
        return this.baseInquiryService.createInquiry(user.data!.organizationId, action.payload).pipe(
          switchMap((response: any) => {
            return this.baseInquiryService
              .publishInquiry(response?.data?.inquiryId, user.data!.organizationId, { status: 2 })
              .pipe(mergeMap(() => [baseInquiryActions.successInquiryCRUD()]));
          })
        );
      }),
      catchError(() => of(baseInquiryActions.errorInquiryCRUD()))
    );
  });

  successInquiryCRUD$ = createEffect(
    () => {
      return this.actions$.pipe(ofType(baseInquiryActions.successInquiryCRUD));
    },
    { dispatch: false }
  );

  getDepartment$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseInquiryActions.fetchDepartment),
      withLatestFrom(this.store.select(baseAuthSelectors.getUserInfo)),
      switchMap(([action, user]) => {
        return this.departmentService
          .getCustomerDepartmentShow({
            department: action.payload as unknown as number,
            customer: user.data!.organizationId as unknown as number,
          })
          .pipe(
            map((response) => response.data),
            mergeMap((response) => [
              baseInquiryActions.loadDepartment({ payload: response }),
              baseInquiryActions.loadDepartmentSuccess(),
            ])
          );
      }),
      catchError(() => of(baseInquiryActions.loadDepartmentError()))
    );
  });

  getPSPInquiryEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseInquiryActions.loadPSPInquiry),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((user) => user.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        return this.baseInquiryService
          .getPSPInquiry({
            psp: user.organizationId,
            inquiry: action.payload,
          })
          .pipe(
            mergeMap((response) => [
              baseInquiryActions.setPSPInquiry({
                payload: {
                  ...response,
                  weekdays: this.baseInquiryService.getInquiryWeekdays(response.dates as InquiryDateResponse[]),
                },
              }),
              baseInquiryActions.successPSPInquiry(),
              baseInquiryActions.loadInquiryBranches({ payload: { inquiry: action.payload } }),
            ]),
            catchError(() => of(baseInquiryActions.errorPSPInquiry()))
          );
      })
    );
  });

  getCandidateInquiry$ = createEffect(() =>
    this.actions$.pipe(
      ofType(baseInquiryActions.loadCustomerInquiry),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((user) => user.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        return this.baseInquiryService.getCustomerInquiry(user.organizationId, action.payload).pipe(
          mergeMap((response) => [
            baseInquiryActions.setCustomerInquiry({ payload: response }),
            baseInquiryActions.successCustomerInquiry(),
          ]),
          catchError(() => of(baseInquiryActions.errorCustomerInquiry()))
        );
      })
    )
  );

  getResponsibleUserList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseInquiryActions.loadResponsibleUserList),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((user) => user.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        return this.baseInquiryService
          .responsibleUsersList({
            psp: user.organizationId,
            inquiry: action.payload,
          })
          .pipe(
            mergeMap((response: any) => [
              baseInquiryActions.setResponsibleUserList({ payload: response }),
              baseInquiryActions.successResponsibleUserList(),
            ]),
            catchError(() => of(baseInquiryActions.errorResponsibleUserList()))
          );
      })
    );
  });

  createInquiryOffer$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseInquiryActions.createInquiryOffer),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((user) => user.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        return this.baseInquiryService
          .createInquiryOffer({
            psp: user.organizationId,
            inquiry: action.payload.inquiry,
            body: action.payload.body,
          })
          .pipe(
            mergeMap(() => [
              baseInquiryActions.loadInquiryCandidatesList({
                payload: {
                  inquiry: action.payload.inquiry,
                  isUnlimited: !!action.payload.isUnlimited,
                  isIncludeSorting: true,
                },
              }),
              baseInquiryActions.loadPSPInquiry({ payload: action.payload.inquiry }),
            ])
          );
      })
    );
  });

  updateInquiryOffer$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseInquiryActions.updateInquiryOffer),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((user) => user.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        return this.baseInquiryService
          .updateInquiryOffer({
            psp: user.organizationId,
            offer: action.payload.offer,
            inquiry: action.payload.inquiry,
            body: action.payload.body,
          })
          .pipe(
            mergeMap(() => [
              baseInquiryActions.loadPspInquiryOffersList({
                payload: { inquiry: action.payload.inquiry, ...action.payload.filters, isIncludeSorting: true },
              }),
            ])
          );
      })
    );
  });

  getPSPCustomerQualificationPrice = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseInquiryActions.loadPSPCustomerQualificationPrice),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((user) => user.data as BaseTokenInterface))
      ),
      filter(([action, user]) => !!user),
      switchMap(([action, user]) => {
        return this.baseInquiryService
          .pspCustomerQualificationPrice({
            psp: user.organizationId,
            customer: action.payload.customer,
            qualification: action.payload.qualification,
            location: action.payload.locationId,
          })
          .pipe(
            mergeMap((response: any) => [
              baseInquiryActions.setPSPCustomerQualificationPrice({ payload: response }),
              baseInquiryActions.successPSPCustomerQualificationPrice(),
            ]),
            catchError(() => of(baseInquiryActions.errorPSPCustomerQualificationPrice()))
          );
      })
    );
  });

  acceptOffer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(baseInquiryActions.acceptOffer),
      withLatestFrom(this.store.select(baseAuthSelectors.getUserInfo).pipe(map((user) => user.data?.organizationId))),
      switchMap(([action, customer]) => {
        return this.baseInquiryService
          .acceptOffer(action.payload.offer, customer as number, action.payload.inquiry, {
            offerPositions: action.payload.offerPositions,
          })
          .pipe(
            mergeMap(() => [
              baseInquiryActions.acceptOfferSuccessful(),
              baseInquiryActions.loadCustomerInquiryOffersList({
                payload: { inquiry: action.payload.inquiry, ...action.payload.filters },
              }),
              baseInquiryActions.loadCustomerInquiry({ payload: action.payload.inquiry }),
            ])
          );
      })
    )
  );

  downloadPspCustomerContractTemplateFile$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseInquiryActions.downloadPspCustomerContractTemplateFile),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((user) => user.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        return this.baseInquiryService
          .getPspCustomerContractTemplateFile({
            psp: user.organizationId,
            customer: action.payload,
          })
          .pipe(
            tap((response) => {
              const blob = new Blob([response.body], { type: BaseAvailableFileTypeEnum.applicationPDF });
              const objectUrl = URL.createObjectURL(blob);
              const link = document.createElement('a');
              link.setAttribute('href', objectUrl);
              link.setAttribute('download', response.fileName);
              link.style.visibility = 'hidden';
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            }),
            map(() => baseInquiryActions.downloadPspCustomerContractTemplateFileSuccess()),
            catchError(() => of(baseInquiryActions.downloadPspCustomerContractTemplateFileError))
          );
      })
    );
  });

  downloadCustomerContractDocument$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseInquiryActions.downloadCustomerContractDocument),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((user) => user.data as BaseTokenInterface))
      ),
      switchMap(([_action, user]) => {
        return this.baseInquiryService.getContractDocument(user.organizationId).pipe(
          tap((response) => {
            const blob = new Blob([response.body], { type: BaseAvailableFileTypeEnum.applicationPDF });
            const objectUrl = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.setAttribute('href', objectUrl);
            link.setAttribute('download', response.fileName);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }),
          map(() => baseInquiryActions.downloadCustomerContractDocumentSuccess()),
          catchError(() => of(baseInquiryActions.downloadCustomerContractDocumentError()))
        );
      })
    );
  });

  downloadCustomerInquiryOfferDocument$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseInquiryActions.downloadInquiryOfferDocument),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((user) => user.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        const { inquiry, offer, documentUuid } = action.payload;
        return this.baseInquiryService
          .getCustomerInquiryOfferDocumentFileDownload({
            customer: user.organizationId,
            inquiry,
            offer,
            document: documentUuid,
          })
          .pipe(
            tap((response) => {
              if (action.payload.openInModal) {
                return;
              }
              const blob = new Blob([response.body], { type: 'application/pdf' });
              const objectUrl = URL.createObjectURL(blob);
              const link = document.createElement('a');
              link.setAttribute('href', objectUrl);
              link.setAttribute('download', response.fileName);
              link.style.visibility = 'hidden';
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            }),
            !action.payload.openInModal
              ? map(() => baseInquiryActions.downloadInquiryOfferDocumentSuccess())
              : mergeMap((response) => [
                  baseInquiryActions.setDownloadInquiryOfferDocumentFile({
                    payload: {
                      document:
                        action.payload.documentType === BaseAvailableFileTypeEnum.pdf
                          ? new Blob([response.body], { type: BaseAvailableFileTypeEnum.applicationPDF })
                          : this.sanitizer.bypassSecurityTrustUrl(
                              URL.createObjectURL(
                                new Blob([response.body], { type: `image/${action.payload.documentType}` })
                              )
                            ),
                    },
                  }),
                  baseInquiryActions.downloadInquiryOfferDocumentSuccess(),
                ]),
            catchError(() => of(baseInquiryActions.downloadInquiryOfferDocumentError()))
          );
      })
    );
  });

  declineOfferCustomer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(baseInquiryActions.declineOfferCustomer),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((user) => user.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        const { inquiry, offer, body } = action.payload;
        return this.baseInquiryService
          .declineOfferCustomer({ customer: user.organizationId, offer, inquiry, body })
          .pipe(
            debounceTime(500),
            mergeMap((_) => [
              baseInquiryActions.loadCustomerInquiryOffersList({ payload: { inquiry, ...action.payload.filters } }),
              baseInquiryActions.loadCustomerInquiry({ payload: inquiry }),
              baseInquiryActions.declineOfferCustomerSuccess(),
            ]),
            catchError(() => of(baseInquiryActions.declineOfferCustomerError()))
          );
      })
    )
  );

  revokeOffer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(baseInquiryActions.revokeOffer),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((user) => user.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        return this.baseInquiryService
          .revokeOffer(user.organizationId, action.payload.inquiry, action.payload.offer, action.payload.body)
          .pipe(
            debounceTime(500),
            mergeMap((_) =>
              action.payload.isOffersAndBookings
                ? [
                    baseInquiryActions.revokeOfferSuccess(),
                    baseOffersAndBookingsActions.loadOfferOverlapsList({ payload: {} }),
                  ]
                : [
                    baseInquiryActions.revokeOfferSuccess(),
                    baseInquiryActions.loadPspInquiryOffersList({
                      payload: { inquiry: action.payload.inquiry, ...action.payload.filters, isIncludeSorting: true },
                    }),
                    baseInquiryActions.loadPSPInquiry({ payload: action.payload.inquiry }),
                  ]
            ),
            catchError(() => of(baseInquiryActions.revokeOfferError()))
          );
      })
    )
  );

  updateInquiryStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(baseInquiryActions.updateInquiryStatus),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((user) => user.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        return this.baseInquiryService
          .updateInquiryStatus(
            action.payload.inquiry,
            user.organizationId,
            action.payload.status,
            action.payload.closedNote
          )
          .pipe(
            tap(() => {
              if (action.payload.route) {
                this.router.navigate([action.payload.route]);
              }
            }),
            mergeMap((_) =>
              action.payload.loadUnpublishedInquiries
                ? [
                    baseInquiryActions.updateInquiryStatusSuccess(),
                    baseSharedActions.addSystemAlert({
                      payload: {
                        body: 'successMessage.changesSaved',
                        class: BaseToastAlertClasses.success,
                      },
                    }),
                    baseInquiryActions.getInquiries({
                      payload: { 'statuses[]': [BaseInquiryType.STATUS_CREATED.toString()] },
                    }),
                  ]
                : [baseInquiryActions.updateInquiryStatusSuccess()]
            ),
            catchError(() => of(baseInquiryActions.updateInquiryStatusError()))
          );
      })
    )
  );

  updateCustomerInquiry$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseInquiryActions.updateCustomerInquiry),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((user) => user.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        return this.baseInquiryService
          .updateCustomerInquiry({
            customer: user.organizationId,
            inquiry: action.payload.inquiry,
            body: action.payload.body,
          })
          .pipe(
            mergeMap(() => [
              baseInquiryActions.updateCustomerInquirySuccess(),
              baseInquiryActions.successInquiryCRUD(),
            ]),
            catchError(() => of(baseInquiryActions.updateCustomerInquiryError))
          );
      })
    );
  });

  loadInquiryBranches$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseInquiryActions.loadInquiryBranches),
      withLatestFrom(this.store.select(baseAuthSelectors.getUserInfo)),
      switchMap(([action, user]) => {
        return this.baseInquiryService.getPspInquiryBranchList(user.data!.organizationId, action.payload.inquiry).pipe(
          mergeMap((response) => [
            baseInquiryActions.setInquiryBranches({ payload: response }),
            baseInquiryActions.successLoadInquiryBranches(),
          ]),
          catchError(() => of(baseInquiryActions.errorLoadInquiryBranches()))
        );
      })
    );
  });

  loadInquiryCandidatesList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(baseInquiryActions.loadInquiryCandidatesList),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo),
        this.store.select(baseInquirySelectors.getPSPInquiry),
        this.store.select(baseInquirySelectors.getInquiryCandidates)
      ),
      filter(([action, user, inquiry, candidates]) => !!user.ok && !!inquiry.ok),
      switchMap(([action, user, inquiry, candidates]) => {
        if (!user.data) {
          throw new Error('User data is not defined');
        }
        const activeFilters = this.baseFilterService.getInquiryCandidateListFilters(
          this.queryParamsService.getAllQueryParams(),
          action.payload.isIncludeSorting
        );

        return this.baseInquiryService
          .getBestCandidatesByInquiry(
            action.payload.inquiry,
            user.data.organizationId,
            action.payload.size || InquiryBestCandidatesPagination.size,
            action.payload.page || InquiryBestCandidatesPagination.page,
            action.payload.orderKey || activeFilters?.orderKey,
            action.payload.orderDirection || activeFilters?.orderDirection,
            activeFilters
          )
          .pipe(
            mergeMap((response) => {
              const notOfferedCandidates = (response.data as BestMatchingProfileListItemResponse[]).filter(
                (candidate) => !candidate.hasActiveOffers
              );

              const preparedCandidatesList = action.payload.isUnlimited
                ? this.baseInquiryCandidateService.prepareCandidateBubblesList<BaseUnlimitedInquiryCandidateInterface>(
                    notOfferedCandidates,
                    inquiry.data,
                    action.payload.inquiry,
                    action.payload.toLimitLeft,
                    true
                  )
                : this.baseInquiryCandidateService.prepareCandidateBubblesList<BaseInquiryCandidateInterface>(
                    notOfferedCandidates,
                    inquiry.data,
                    action.payload.inquiry,
                    action.payload.toLimitLeft
                  );
              return !action.payload.page || action.payload.page === 1
                ? preparedCandidatesList.length < BASE_INQUIRY_CANDIDATES_VISIBLE_LIMIT &&
                  response.meta.page.current < response.meta.page.total
                  ? [
                      baseInquiryActions.setInitialInquiryCandidatesList({
                        payload: {
                          data: preparedCandidatesList,
                          pagination: response.meta,
                        },
                      }),
                      baseInquiryActions.loadInquiryCandidatesList({
                        payload: {
                          inquiry: action.payload.inquiry,
                          page: response.meta.page.current + 1,
                          size: InquiryBestCandidatesPagination.size,
                          isUnlimited: action.payload.isUnlimited,
                        },
                      }),
                    ]
                  : [
                      baseInquiryActions.setInitialInquiryCandidatesList({
                        payload: {
                          data: preparedCandidatesList,
                          pagination: response.meta,
                        },
                      }),
                      baseInquiryActions.setInquiryCandidatesListSuccess(),
                    ]
                : (candidates.data.length % BASE_INQUIRY_CANDIDATES_VISIBLE_LIMIT) + preparedCandidatesList.length <
                    BASE_INQUIRY_CANDIDATES_VISIBLE_LIMIT && response.meta.page.current < response.meta.page.total
                ? [
                    baseInquiryActions.addInquiryCandidatesPage({
                      payload: {
                        data: preparedCandidatesList,
                        pagination: response.meta,
                      },
                    }),
                    baseInquiryActions.loadInquiryCandidatesList({
                      payload: {
                        inquiry: action.payload.inquiry,
                        page: ++response.meta.page.current,
                        size: InquiryBestCandidatesPagination.size,
                        isUnlimited: action.payload.isUnlimited,
                      },
                    }),
                  ]
                : [
                    baseInquiryActions.addInquiryCandidatesPage({
                      payload: {
                        data: preparedCandidatesList,
                        pagination: response.meta,
                      },
                    }),
                    baseInquiryActions.setInquiryCandidatesListSuccess(),
                  ];
            }),
            catchError(() => of(baseInquiryActions.setInquiryCandidatesListError()))
          );
      })
    )
  );

  defineVisibleInquiryCandidatesItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(baseInquiryActions.defineVisibleInquiryCandidatesItems),
      withLatestFrom(this.store.select(baseInquirySelectors.getInquiryCandidates)),
      map(([action, candidates]) => {
        return baseInquiryActions.setInquiryCandidatesList({
          payload: this.baseInquiryCandidateService.defineVisibleCandidates(candidates.data),
        });
      })
    )
  );

  selectCandidate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(baseInquiryActions.selectCandidate),
      withLatestFrom(this.store.select(baseInquirySelectors.getInquiryCandidates)),
      map(([action, candidates]: any) => {
        return baseInquiryActions.setInquiryCandidatesList({
          payload: this.baseInquiryCandidateService.updateCandidatesListWithSelectedCandidate(
            candidates.data,
            action.payload.candidateId
          ),
        });
      })
    )
  );

  selectCandidateBubble$ = createEffect(() =>
    this.actions$.pipe(
      ofType(baseInquiryActions.selectCandidateBubble),
      withLatestFrom(this.store.select(baseInquirySelectors.getInquiryCandidates)),
      map(([action, candidates]: any) => {
        return baseInquiryActions.setInquiryCandidatesList({
          payload: this.baseInquiryCandidateService.updateCandidatesListWithSelectedBubble(
            candidates.data,
            action.payload
          ),
        });
      })
    )
  );

  candidateFlexShiftsSelection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(baseInquiryActions.candidateFlexShiftsSelection),
      withLatestFrom(this.store.select(baseInquirySelectors.getInquiryCandidates)),
      map(([action, candidates]: any) => {
        return baseInquiryActions.setInquiryCandidatesList({
          payload: this.baseInquiryCandidateService.allFlexShiftsSelection(candidates.data, action.payload.candidateId),
        });
      })
    )
  );

  candidateFixedShiftsSelection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(baseInquiryActions.candidateFixedShiftsSelection),
      withLatestFrom(this.store.select(baseInquirySelectors.getInquiryCandidates)),
      map(([action, candidates]: any) => {
        return baseInquiryActions.setInquiryCandidatesList({
          payload: this.baseInquiryCandidateService.allFixedShiftsByTypeSelection(
            candidates.data,
            action.payload.candidateId,
            action.payload.shiftType
          ),
        });
      })
    )
  );

  shiftPlanCreateData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(baseInquiryActions.shiftPlanCreateData),
      withLatestFrom(this.store.select(baseInquirySelectors.getInquiry)),
      mergeMap(([action]) => {
        const shiftPlanRangeDataPayload = this.baseShiftViewPlanService.transformFormDataToShiftPlanViewData(
          action.payload.calendar
        );
        const shiftPlanCalendarList = this.baseShiftViewPlanService.generateInquiryShiftViewItems(
          shiftPlanRangeDataPayload ? [shiftPlanRangeDataPayload] : []
        );

        return [
          baseInquiryActions.setInquiryShiftViewRangeList({
            payload: shiftPlanRangeDataPayload ? [shiftPlanRangeDataPayload] : [],
          }),
          baseInquiryActions.setShiftPlanInquiryList({
            payload: {
              data: shiftPlanCalendarList,
              shiftTimes: action.payload.calendar.timeShiftsForm,
            },
          }),
          baseInquiryActions.loadSimilarInquiriesList({
            payload: {
              calendar: shiftPlanCalendarList,
              inquiries: action.payload.inquiries,
            },
          }),
        ];
      })
    )
  );

  openInquiryInEditMode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(baseInquiryActions.openInquiryInEditMode),
      withLatestFrom(this.store.select(baseInquirySelectors.getInquiry)),
      mergeMap(([action]) => {
        const shiftPlanRangeDataPayload: ShiftPlanCalendarRangeInterface[] =
          this.baseShiftViewPlanService.prepareDataForShiftPlanView(action.payload.inquiry);

        const shiftPlanCalendarList =
          this.baseShiftViewPlanService.generateInquiryShiftViewItems(shiftPlanRangeDataPayload);

        return [
          baseInquiryActions.setInquiryShiftViewRangeList({
            payload: shiftPlanRangeDataPayload,
          }),
          baseInquiryActions.setShiftPlanInquiryList({
            payload: {
              data: shiftPlanCalendarList,
              shiftTimes: action.payload.inquiry.shiftTimes as ShiftTimeResponse[],
            },
          }),
          baseInquiryActions.loadSimilarInquiriesList({
            payload: {
              calendar: shiftPlanCalendarList,
              inquiries: action.payload.similarInquiriesPayload,
            },
          }),
        ];
      })
    )
  );

  shiftPlanUpdateData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(baseInquiryActions.shiftPlanUpdateData),
      withLatestFrom(this.store.select<ShiftPlanCalendarRangeInterface[]>(baseInquirySelectors.getShiftPlanRangeList)),
      mergeMap(([action, shiftPlanRangeList]) => {
        const newShiftPlanRangeItem = this.baseShiftViewPlanService.transformFormDataToShiftPlanViewData(
          action.payload.data
        );
        const updatedShiftPlanRangeList: ShiftPlanCalendarRangeInterface[] = action.payload.rangeId
          ? shiftPlanRangeList.filter((item) => item.id !== action.payload.rangeId)
          : shiftPlanRangeList;
        const updatedListWithNewItem: ShiftPlanCalendarRangeInterface[] = newShiftPlanRangeItem
          ? [...updatedShiftPlanRangeList, newShiftPlanRangeItem]
          : updatedShiftPlanRangeList;

        return [
          baseInquiryActions.setInquiryShiftViewRangeList({ payload: updatedListWithNewItem }),
          baseInquiryActions.updateShiftPlanInquiryList({
            payload: this.baseShiftViewPlanService.generateInquiryShiftViewItems(updatedListWithNewItem),
          }),
        ];
      })
    )
  );

  removeShiftPlanRange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(baseInquiryActions.removeShiftPlanRange),
      withLatestFrom(this.store.select<ShiftPlanCalendarRangeInterface[]>(baseInquirySelectors.getShiftPlanRangeList)),
      mergeMap(([action, shiftPlanRangeList]) => {
        const updatedShiftPlanRangeList: ShiftPlanCalendarRangeInterface[] = shiftPlanRangeList.filter(
          (item) => item.id !== action.payload
        );

        return [
          baseInquiryActions.setInquiryShiftViewRangeList({ payload: updatedShiftPlanRangeList }),
          baseInquiryActions.updateShiftPlanInquiryList({
            payload: this.baseShiftViewPlanService.generateInquiryShiftViewItems(updatedShiftPlanRangeList),
          }),
        ];
      })
    )
  );

  loadPspInquiryOffersList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(baseInquiryActions.loadPspInquiryOffersList),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo),
        this.store.select(baseInquirySelectors.getPSPInquiry)
      ),
      switchMap(([action, user, inquiry]: any) => {
        const activeFilters = this.baseFilterService.getInquiryOffersListFilters(
          this.queryParamsService.getAllQueryParams(),
          action.payload.isIncludeSorting
        );
        return this.baseInquiryService
          .getOffersByInquiry(
            action.payload.inquiry,
            user.data.organizationId,
            action.payload.size || InquiryOffersPagination.size,
            action.payload.page || InquiryOffersPagination.page,
            action.payload.orderKey || activeFilters?.orderKey,
            action.payload.orderDirection || activeFilters?.orderDirection,
            activeFilters
          )
          .pipe(
            mergeMap((response) => {
              const payload: BasePspInquiryOfferInterface[] =
                this.basePspInquiryOfferService.preparePspInquiryOfferBubblesList(response.data, inquiry.data);
              return [
                action.payload.loadNextPage
                  ? baseInquiryActions.addPspInquiryOffersListNextPage({ payload })
                  : baseInquiryActions.setPspInquiryOffersList({ payload }),
                baseInquiryActions.setPspInquiryOffersListPagination({ payload: response.meta }),
                baseInquiryActions.setPspInquiryOffersListSuccess(),
              ];
            }),
            catchError(() => of(baseInquiryActions.setPspInquiryOffersListError()))
          );
      })
    )
  );

  selectPspInquiryOffer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(baseInquiryActions.selectPspInquiryOffer),
      withLatestFrom(this.store.select(baseInquirySelectors.getPspInquiryOffers)),
      map(([action, offers]) => {
        return baseInquiryActions.setPspInquiryOffersList({
          payload: this.basePspInquiryOfferService.selectPspInquiryOffer(offers.data, action.payload.offerId),
        });
      })
    )
  );

  loadCustomerInquiryOffersList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(baseInquiryActions.loadCustomerInquiryOffersList),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((user) => user.data as BaseTokenInterface)),
        this.store.select(baseInquirySelectors.getCustomerInquiry)
      ),
      switchMap(([action, user, inquiry]) => {
        return this.baseInquiryService
          .getCustomerInquiryOffers(
            user.organizationId,
            action.payload.inquiry,
            action.payload.profileAverageRating,
            action.payload.status,
            action.payload.mainQualifications,
            action.payload.pspBranches,
            action.payload.experience,
            action.payload.orderKey,
            action.payload.orderDirection,
            action.payload.search,
            action.payload.page || InquiryOffersPagination.page,
            action.payload.size || InquiryOffersPagination.size
          )
          .pipe(
            mergeMap((response: any) => {
              const payload: BaseCustomerInquiryOfferInterface[] =
                this.baseCustomerInquiryOfferService.prepareCustomerInquiryOfferBubblesList(
                  response.data,
                  inquiry.data
                );
              return [
                action.payload.loadNextPage
                  ? baseInquiryActions.addCustomerInquiryOffersListNextPage({ payload })
                  : baseInquiryActions.setCustomerInquiryOffersList({ payload }),
                baseInquiryActions.setCustomerInquiryOffersFiltersAndPagination({
                  payload: { filters: response.filters, pagination: response.meta },
                }),
                baseInquiryActions.setCustomerInquiryOffersListSuccess(),
              ];
            }),
            catchError(() => of(baseInquiryActions.setCustomerInquiryOffersListError()))
          );
      })
    )
  );

  selectCustomerInquiryOffer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(baseInquiryActions.selectCustomerInquiryOffer),
      withLatestFrom(this.store.select(baseInquirySelectors.getCustomerInquiryOffers)),
      map(([action, offers]: any) => {
        return baseInquiryActions.setCustomerInquiryOffersList({
          payload: this.baseCustomerInquiryOfferService.selectCustomerInquiryOffer(offers.data, action.payload.offerId),
        });
      })
    )
  );

  selectCustomerOfferBubble$ = createEffect(() =>
    this.actions$.pipe(
      ofType(baseInquiryActions.selectCustomerOfferBubble),
      withLatestFrom(this.store.select(baseInquirySelectors.getCustomerInquiryOffers)),
      map(([action, offers]: any) => {
        return baseInquiryActions.setCustomerInquiryOffersList({
          payload: this.baseCustomerInquiryOfferService.updateCustomerOffersListWithSelectedBubble(
            offers.data,
            action.payload
          ),
        });
      })
    )
  );

  customerOfferFlexShiftsSelection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(baseInquiryActions.customerOfferFlexShiftsSelection),
      withLatestFrom(this.store.select(baseInquirySelectors.getCustomerInquiryOffers)),
      map(([action, offers]: any) => {
        return baseInquiryActions.setCustomerInquiryOffersList({
          payload: this.baseCustomerInquiryOfferService.selectAllFlexShifts(offers.data, action.payload.offerId),
        });
      })
    )
  );

  customerOfferFixedShiftsSelection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(baseInquiryActions.customerOfferFixedShiftsSelection),
      withLatestFrom(this.store.select(baseInquirySelectors.getCustomerInquiryOffers)),
      map(([action, offers]: any) => {
        return baseInquiryActions.setCustomerInquiryOffersList({
          payload: this.baseCustomerInquiryOfferService.selectAllFixedShiftsByType(
            offers.data,
            action.payload.offerId,
            action.payload.shiftType
          ),
        });
      })
    )
  );

  customerOfferAllShiftsSelection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(baseInquiryActions.customerOfferAllShiftsSelection),
      withLatestFrom(this.store.select(baseInquirySelectors.getCustomerInquiryOffers)),
      map(([action, offers]: any) => {
        return baseInquiryActions.setCustomerInquiryOffersList({
          payload: this.baseCustomerInquiryOfferService.selectAllOfferedShifts(
            offers.data,
            action.payload.offerId,
            action.payload.isShiftsDeselectionMode
          ),
        });
      })
    )
  );

  loadSimilarInquiriesList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseInquiryActions.loadSimilarInquiriesList),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((user) => user.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        return this.baseInquiryService
          .getInquiries({
            customer: user.organizationId,
            ...action.payload,
          })
          .pipe(
            mergeMap((response) => {
              const payload = this.baseSimilarInquiriesService.generateSimilarInquiries(
                response.data,
                action.payload.calendar
              );
              return [
                baseInquiryActions.setSimilarInquiriesList({ payload: response.data }),
                baseInquiryActions.successSetSimilarInquiriesList(),
                baseInquiryActions.setMappedSimilarInquiries({ payload }),
              ];
            }),
            catchError(() => of(baseInquiryActions.errorSetSimilarInquiriesList()))
          );
      })
    );
  });

  updateShiftPlanInquiryList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(baseInquiryActions.updateShiftPlanInquiryList),
      withLatestFrom(
        this.store.select<{ data: CustomerInquiryListItemResponse[]; ok: boolean | undefined }>(
          baseInquirySelectors.getSimilarInquiriesList
        )
      ),
      mergeMap(([action, similarInquiries]) => {
        const payload = this.baseSimilarInquiriesService.generateSimilarInquiries(
          similarInquiries.data,
          action.payload
        );

        return [baseInquiryActions.setMappedSimilarInquiries({ payload })];
      })
    )
  );

  customerInquiryOfferPreselection$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseInquiryActions.customerInquiryOfferPreselection),
      withLatestFrom(this.store.select(baseAuthSelectors.getUserInfo)),
      switchMap(([action, userInfo]) => {
        const source$ = action.payload.preselect
          ? this.baseInquiryService.preselectCustomerInquiryOffer({
              customer: userInfo?.data?.organizationId as number,
              inquiry: action.payload.inquiry,
              offer: action.payload.offer,
            })
          : this.baseInquiryService.unselectCustomerInquiryOffer({
              customer: userInfo?.data?.organizationId as number,
              inquiry: action.payload.inquiry,
              offer: action.payload.offer,
            });

        return source$.pipe(
          mergeMap((response) => {
            return [
              baseInquiryActions.successCustomerInquiryOfferPreselection(),
              baseInquiryActions.loadCustomerInquiryOffersList({ payload: { inquiry: action.payload.inquiry } }),
            ];
          }),
          catchError(() => of(baseInquiryActions.errorCustomerInquiryOfferPreselection()))
        );
      })
    );
  });
}
