import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { BaseTabInterface } from '../../../shared/interfaces/base-tab.interface';
import { BaseButtonSize } from '../../../shared/components/button/base-button.component';

@Component({
  selector: 'base-horizontal-nav',
  templateUrl: './base-horizontal-nav.component.html',
  styleUrls: ['./base-horizontal-nav.component.scss'],
})
export class BaseHorizontalNavComponent {
  @Input() tabs: Array<BaseTabInterface> = [];
  @Input() routeOnClick = false;
  @Input() tabClass: string;
  @Input() tabSize: BaseButtonSize = BaseButtonSize.md;
  @Input() showBadge = false;
  @Input()
  get current(): Partial<BaseTabInterface> {
    return this.value;
  }
  set current(value) {
    if (!value || this.value?.id === value?.id) {
      return;
    }

    this.value = value;
    this.currentChange.emit(this.value);
  }
  @Input() navigationDisabled = false;
  @Input() isCombined = false;
  @Output() currentChange: EventEmitter<Partial<BaseTabInterface>> = new EventEmitter<Partial<BaseTabInterface>>();
  buttonSizes = BaseButtonSize;
  private value: Partial<BaseTabInterface> = {} as BaseTabInterface;

  constructor(private router: Router) {}

  onTabSelect(tab: BaseTabInterface): void {
    if (this.navigationDisabled) {
      this.currentChange.emit(tab);
      return;
    }
    this.current = tab;
    if (this.routeOnClick) {
      setTimeout(() => this.router.navigate([tab.url]), 0);
    }
  }
}
