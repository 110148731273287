<div class="login-modal my-5">
  <img
    class="logo"
    src="assets-global/images/powered-by-namirial.svg"
    alt="Powered by Namirial"
  />
  <div class="row">
    <div class="col-md-12 col-xl-6 px-1 order-xl-0 order-1 login-form">
      <form
        [formGroup]="loginForm"
        [ngClass]="{ 'was-validated': submitted }"
      >
        <div>
          <base-input
            placeholder=""
            [isInvalid]="loginForm.controls.username.touched && !!loginForm.controls.username.errors"
            [errorMessage]="loginForm.controls.username.errors | baseFieldValidation"
            label="namirialLoginModal.labels.username"
            formControlName="username"
            autoCompleteValue="off"
            [labelTooltip]="'namirialLoginModal.namirialDeviceCodeTooltip' | translate"
            tooltipClass="namirial-label-tooltip"
            tooltipPlacement="top-left"
            [isRequired]="true"
          ></base-input>
        </div>
        <div class="my-6">
          <base-password
            [isInvalid]="loginForm.controls.password.touched && !!loginForm.controls.password.errors"
            [errorMessage]="loginForm.controls.password.errors | baseFieldValidation"
            label="namirialLoginModal.labels.password"
            formControlName="password"
            autoCompleteValue="new-password"
            [labelTooltip]="'namirialLoginModal.namirialPinTooltip' | translate"
            tooltipClass="namirial-label-tooltip"
            tooltipPlacement="top-left"
            [isRequired]="true"
          ></base-password>
        </div>
        <div class="mb-5">
          <a
            *ngIf="isNamirialRegistrationAvailable"
            class="text-link"
            (click)="openRegisterModal()"
            >{{ 'namirialLoginModal.registration.linkTitle' | translate }}</a
          >

          <a
            *ngIf="isVoucherOrdered"
            class="text-link"
            [ngbTooltip]="'namirialLoginModal.voucher.linkTooltip' | translate"
            (click)="openVoucher()"
            >{{ 'namirialLoginModal.voucher.linkTitle' | translate }}</a
          >
        </div>
        <div class="d-flex justify-content-between pt-5">
          <base-button
            class="me-3"
            label="action.cancel"
            color="secondary"
            (click)="closeModal()"
          ></base-button>
          <base-button
            label="action.next"
            color="primary"
            [disabled]="loginForm.invalid"
            (btnClick)="submit()"
          ></base-button>
        </div>
      </form>
    </div>
    <div class="col-md-12 col-xl-6 order-xl-1 order-0 info-card">
      <div class="d-flex justify-content-end">
        <i
          class="fa-solid fa-circle-xmark pointer fa-2x"
          (click)="closeModal()"
        ></i>
      </div>
      <p>{{ 'namirialLoginModal.infoDescription' | translate }}:</p>
      <base-sign-contracts-info-card [assignment]="currentAssignment"></base-sign-contracts-info-card>
    </div>
  </div>
</div>

<ng-template #registerPopup>
  <base-namirial-registration-modal [modalRef]="modalRegisterRef"></base-namirial-registration-modal>
</ng-template>
