import { AbstractControl, ValidatorFn } from '@angular/forms';
import { getYear, isValid } from 'date-fns';
import { BaseDateService } from '../services/base-date.service';

export const baseDatepickerValidator: ValidatorFn = (c: AbstractControl): { [key: string]: boolean } | null => {
  const cvDateFormat = { dateFormatInvalid: true };

  if (!c.value) {
    return null;
  }
  const date = new Date(BaseDateService.toServerFormatString(c.value));
  return c.value.indexOf('_') < 0 && isValid(date) && getYear(date) !== 0 ? null : cvDateFormat;
};
