import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { baseRelatedPspBranchActions, baseRelatedPspBranchesSelectors, BaseCoreState } from '../../../_store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable()
export class BaseRelatedPspBranchListGuard {
  constructor(private store: Store<BaseCoreState>) {}

  canActivate(): Observable<boolean> {
    this.store.dispatch(baseRelatedPspBranchActions.loadRelatedPspBranchList({}));

    return this.store.select(baseRelatedPspBranchesSelectors.getRelatedPspBranchList).pipe(
      filter((state) => state.ok !== undefined),
      map((state) => state.ok as boolean)
    );
  }
}
