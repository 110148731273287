import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BaseToastAlertInfoInterface } from '../../interfaces/base-toast-alert-info.interface';
import { Store } from '@ngrx/store';
import { BaseCoreState, baseSharedActions } from '../../../_store';

@Component({
  selector: 'base-toast-alert-item',
  templateUrl: './base-toast-alert-item.component.html',
})
export class BaseToastAlertItemComponent implements OnInit, OnDestroy {
  @Input() alert: BaseToastAlertInfoInterface;
  closeTimeoutId: number;

  constructor(private store: Store<BaseCoreState>) {}

  ngOnInit(): void {
    if (!this.alert.closeManually) {
      this.closeTimeoutId = setTimeout(() => {
        this.closeAlert();
      }, this.alert.duration);
    }
  }

  ngOnDestroy(): void {
    clearTimeout(this.closeTimeoutId);
  }

  closeAlert(): void {
    this.store.dispatch(baseSharedActions.deleteSystemAlert({ payload: this.alert.id as number }));
  }
}
