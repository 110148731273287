<div class="auth-teaser teaser imprint-data-protection">
  <div class="back-button-wrapper">
    <base-button
      class="back-button"
      label="action.back"
      color="dark"
      (btnClick)="handleAddSignPopup()"
    ></base-button>
  </div>
  <ng-content></ng-content>
</div>
