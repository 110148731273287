<form
  [formGroup]="documentUploadForm"
  [ngClass]="{ 'was-validated': submitted }"
  class="d-flex flex-column"
>
  <base-document-info
    *ngIf="documentUploadForm.get('existingFile')?.value"
    [editCandidate]="editCandidate || documentUploadForm.get('existingFile')?.value?.id"
    [documentControl]="documentUploadForm.get('existingFile')"
    [errorMessage]="
      (documentUploadForm.get('existingFile')?.errors
        | baseFieldValidation: { fileSizeTooBig: 'fileSizeTooBigWithParams', fileTypeInvalid: 'fileTypeInvalid' }) ||
      undefined
    "
    (viewDocumentClicked)="viewDocument($event)"
    (deleteClicked)="deleteExistingFile()"
    class="mb-5"
  ></base-document-info>
  <base-document-upload
    *ngIf="!documentUploadForm.get('existingFile')?.value"
    [isInvalid]="!!documentUploadForm.get('file')?.errors && submitted"
    [errorMessage]="documentUploadForm.get('file')?.errors | baseFieldValidation: { required: 'file' }"
    formControlName="file"
    class="mb-5"
  ></base-document-upload>
  <div class="row mb-5">
    <div class="col-3">
      <label class="required fw-bold pt-2">{{ 'document.documentName' | translate }}</label>
    </div>
    <div class="col-9">
      <base-input
        placeholder="document.namePlaceholder"
        [isInvalid]="!!documentUploadForm.get('name')?.errors"
        [errorMessage]="documentUploadForm.get('name')?.errors | baseFieldValidation"
        formControlName="name"
      ></base-input>
    </div>
  </div>
  <div
    class="row mb-5"
    *ngIf="!fixedCategory"
  >
    <div class="col-3 pt-2">
      <label class="required fw-bold">{{ 'category' | translate }}</label>
    </div>
    <div class="col-9">
      <base-dropdown
        [items]="documentCategories"
        formControlName="category"
        [searchable]="false"
        bindOptionLabel="label"
        placeholder="pleaseSelect"
        baseFieldValidationArgs="fileCategory"
      >
      </base-dropdown>
    </div>
  </div>
  <div class="row mb-2">
    <div class="col-3 d-flex align-items-center">
      <label class="required fw-bold">{{ 'dateTime.validUntil' | translate }}</label>
    </div>
    <div class="col-9">
      <base-datepicker
        formControlName="validUntil"
        [isInvalid]="!!documentUploadForm.get('validUntil')?.errors"
        [errorText]="
          documentUploadForm.get('validUntil')?.errors | baseFieldValidation: { required: 'validation.selectDate' }
        "
      ></base-datepicker>
    </div>
  </div>
  <div class="row">
    <div class="col-3"></div>
    <div class="col-9">
      <base-checkbox
        label="dateTime.indefinitelyValid"
        [isChecked]="isValidIndefinitely"
        (getChange)="onValidIndefinitelyChange($event)"
      ></base-checkbox>
    </div>
  </div>
</form>

<ng-template #documentViewerTemplate>
  <base-document-viewer
    [src]="activeDocumentSrc"
    [title]="activeDocument.name"
    [fileName]="activeDocument.name"
    [fileType]="activeDocument.type"
    [modalRef]="documentViewerModalRef"
    (downloadImage)="downloadImage()"
  ></base-document-viewer>
</ng-template>
