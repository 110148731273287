import { Pipe, PipeTransform } from '@angular/core';
import { isValid, format } from 'date-fns';

@Pipe({ name: 'baseFormatDate' })
export class BaseFormatDatePipe implements PipeTransform {
  transform(value: any, withTime: boolean = false, onlyDayOfWeek: boolean = false): any {
    const convertedDate = new Date(value);

    if (value && isValid(convertedDate)) {
      const formattedDate = format(convertedDate, withTime ? 'dd.MM.yyyy HH:mm' : 'dd.MM.yyyy');
      if (onlyDayOfWeek) {
        const weekDayKey = format(convertedDate, 'EEEEEE').toLowerCase();
        return `tooltip.weekDays.${weekDayKey}`;
      } else {
        return formattedDate;
      }
    } else {
      return value;
    }
  }
}
