<div class="auth-teaser teaser register-teaser">
  <form
    [formGroup]="registrationForm"
    [ngClass]="{ 'was-validated': submitted }"
  >
    <div class="text-center d-flex flex-column gap-3">
      <h4 class="headline-36">{{ 'auth.register' | translate }}</h4>
      <span
        *ngIf="!inviteToken"
        class="mb-5"
      >
        {{ 'auth.registered.' + application.toLowerCase() | translate }}
      </span>
    </div>

    <div class="form-group w-100">
      <base-input
        label="companyName"
        placeholder=""
        [isRequired]="true"
        [isInvalid]="!!registrationForm.get('name')?.errors"
        [errorMessage]="registrationForm.get('name')?.errors | baseFieldValidation: { required: 'name' }"
        [size]="inputSize.md"
        formControlName="name"
        data-cy="register-form.company.name"
      ></base-input>
    </div>
    <div class="form-group w-100">
      <div class="row">
        <div class="col-lg-6">
          <base-dropdown
            [items]="titles"
            [searchable]="false"
            label="title"
            [requiredLabel]="true"
            [translateItems]="true"
            bindOptionValue="value"
            bindOptionLabel="label"
            formControlName="gender"
            placeholder="pleaseSelect"
            baseFieldValidationArgs="gender"
            data-cy="register-form.gender"
          ></base-dropdown>
        </div>
      </div>
    </div>
    <div class="form-group w-100">
      <div class="row">
        <div class="col-lg-6 mb-4 mb-lg-0">
          <base-input
            label="firstName"
            placeholder=""
            [isRequired]="true"
            [size]="inputSize.md"
            [isInvalid]="!!registrationForm.get('firstName')?.errors"
            [errorMessage]="registrationForm.get('firstName')?.errors | baseFieldValidation: { required: 'firstName' }"
            formControlName="firstName"
            [ngClass]="{ wide: inviteToken }"
            data-cy="register-form.user.firstName"
          ></base-input>
        </div>
        <div class="col-lg-6">
          <base-input
            label="lastName"
            placeholder=""
            [isRequired]="true"
            [size]="inputSize.md"
            [isInvalid]="!!registrationForm.get('lastName')?.errors"
            [errorMessage]="registrationForm.get('lastName')?.errors | baseFieldValidation: { required: 'lastName' }"
            formControlName="lastName"
            [ngClass]="{ wide: inviteToken }"
            data-cy="'register-form.user.lastName"
          ></base-input>
        </div>
      </div>
    </div>
    <div class="form-group w-100">
      <base-input
        *ngIf="!inviteToken"
        label="email"
        placeholder=""
        [size]="inputSize.md"
        [isRequired]="true"
        [isEmailField]="true"
        [isInvalid]="!!registrationForm.get('email')?.errors"
        [errorMessage]="registrationForm.get('email')?.errors | baseFieldValidation"
        formControlName="email"
        data-cy="'register-form.user.email"
      ></base-input>
    </div>
    <div class="form-group w-100">
      <base-password
        [isEyeIcon]="true"
        placeholder=""
        label="auth.password.title"
        [isRequired]="true"
        [isInvalid]="!!registrationForm.get('password')?.errors"
        [errors]="registrationForm.get('password')?.errors"
        [errorMessageOverride]="{ required: 'password' }"
        formControlName="password"
        data-cy="'register-form.user.password"
      ></base-password>
    </div>
    <div class="form-group w-100">
      <base-password
        [isEyeIcon]="true"
        placeholder=""
        label="auth.password.confirmPassword"
        [isRequired]="true"
        [isInvalid]="!!registrationForm.get('confirmPassword')?.errors"
        [errors]="registrationForm.get('confirmPassword')?.errors"
        formControlName="confirmPassword"
      ></base-password>
    </div>
    <ng-container *ngIf="application !== applications.growth">
      <div class="form-group w-100">
        <base-checkbox
          label="auth.recruitedByPsp"
          [isChecked]="isRecruitedByPsp"
          (getChange)="addRegistrationCode($event)"
        ></base-checkbox>
      </div>
      <div
        class="form-group w-100"
        *ngIf="isRecruitedByPsp"
      >
        <base-input
          label="auth.partnerId"
          placeholder=""
          formControlName="partnerCode"
          [isInvalid]="!!registrationForm.get('partnerCode')?.errors"
          [errorMessage]="registrationForm.get('partnerCode')?.errors | baseFieldValidation"
          [size]="inputSize.md"
        ></base-input>
      </div>
    </ng-container>
    <div class="form-group mb-3">
      <base-checkbox
        formControlName="conditionAccepted"
        [label]="labelConditionAccepted"
        [isInvalid]="!!registrationForm.get('conditionAccepted')?.errors"
        [errorMessage]="
          registrationForm.get('conditionAccepted')?.errors | baseFieldValidation: { required: 'pleaseAccept' }
        "
        [isRequired]="true"
      ></base-checkbox>
      <ng-template #labelConditionAccepted>
        <label class="required font-weight-400">
          {{ 'auth.acceptTerms1' | translate }}
          <a
            class="terms-of-use-link text-link"
            (click)="downloadLegalDocument()"
          >
            {{ 'auth.termsOfUse' | translate }}
          </a>
          {{ 'auth.acceptTerms2' | translate }}
        </label>
      </ng-template>
    </div>
    <div class="form-group mb-6">
      <base-checkbox
        formControlName="privacyPolicyAccepted"
        [label]="labelPrivacyPolicyAccepted"
        [isInvalid]="!!registrationForm.get('privacyPolicyAccepted')?.errors"
        [errorMessage]="
          registrationForm.get('privacyPolicyAccepted')?.errors
            | baseFieldValidation: { required: 'pleaseAcceptPrivacyPolicy' }
        "
        [isRequired]="true"
      ></base-checkbox>
      <ng-template #labelPrivacyPolicyAccepted>
        <label class="required font-weight-400">
          {{ 'auth.acceptPrivacyPolicy1' | translate }}
          <a
            class="terms-of-use-link text-link"
            (click)="downloadLegalDocument(true)"
          >
            {{ 'auth.privacyPolicy' | translate }}
          </a>
          {{ 'auth.acceptPrivacyPolicy2' | translate }}
        </label>
      </ng-template>
    </div>
    <div class="d-flex w-100 align-items-center flex-column">
      <base-button
        (btnClick)="register()"
        label="auth.register"
        color="primary"
        [size]="buttonSize.md"
        data-cy="register-form.submit"
        baseScrollToInvalid
        [baseInvalidFormSubmitted]="submitted"
        [baseInvalidForm]="registrationForm"
      ></base-button>
      <p class="mt-3 text-center">
        <a
          class="text-link text-primary"
          routerLink="/auth/login"
          (click)="clearRegisterFormCache()"
          >{{ 'auth.accountYet' | translate }}</a
        >
      </p>
      <p class="text-center">
        <a
          class="text-link text-primary"
          (click)="clearRegisterFormCache()"
          >{{ 'deleteFormdata' | translate }}</a
        >
      </p>
    </div>
  </form>
</div>
