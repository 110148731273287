import { BaseStoreServiceInterface } from '../../shared/interfaces/base-store-service.interface';

export class BaseLocalStorageService implements BaseStoreServiceInterface {
  set<T>(key: string, data: T): void {
    window.localStorage.setItem(key, JSON.stringify(data));
  }

  get<T>(key: string): T {
    return JSON.parse(window.localStorage.getItem(key) as string);
  }

  update<T>(key: string, data: Partial<T>): void {
    const oldData = window.localStorage.getItem(key) ? JSON.parse(window.localStorage.getItem(key) as string) : {};
    window.localStorage.setItem(key, JSON.stringify({ ...oldData, ...data }));
  }

  delete(key: string): void {
    window.localStorage.removeItem(key);
  }
}
