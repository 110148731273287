import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { BaseCoreState, baseInquiryActions, baseInquirySelectors } from '../../../_store';

@Injectable()
export class BasePspInquiryOffersGuard {
  constructor(private readonly store: Store<BaseCoreState>) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    this.store
      .select(baseInquirySelectors.getPSPInquiry)
      .pipe(
        filter((response) => response.ok !== undefined),
        take(1)
      )
      .subscribe((response) => {
        if (!response.data) {
          this.store.dispatch(baseInquiryActions.loadPSPInquiry({ payload: route.params.inquiry }));
        } else {
          this.store.dispatch(
            baseInquiryActions.loadPspInquiryOffersList({
              payload: {
                inquiry: response.data.id,
                ...route.queryParams,
              },
            })
          );
        }
      });

    return this.store.select(baseInquirySelectors.getPspInquiryOffers).pipe(
      filter((response) => response.ok !== undefined),
      map((response) => !!response.ok)
    );
  }
}
