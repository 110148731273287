<div class="sticky-top d-none">
  <div class="d-flex justify-content-between align-items-center mb-4">
    <div class="d-flex flex-grow-1">
      <ng-container [ngTemplateOutlet]="widgetsTemplate"></ng-container>
    </div>
    <div class="d-flex justify-content-end flex-grow-1">
      <ng-container [ngTemplateOutlet]="offersCountByStatus"></ng-container>
    </div>
  </div>
  <ng-container [ngTemplateOutlet]="teaserContent"></ng-container>
</div>

<ng-template
  #sidebarContent
  let-offcanvas
>
  <div class="offcanvas-header m-0 ps-0 pb-0 border-bottom">
    <ng-container [ngTemplateOutlet]="offersCountByStatus"></ng-container>
    <base-button
      color="transparent"
      icon="fa-solid fa-times"
      class="ms-auto"
      [iconSize]="buttonSize.md"
      (btnClick)="offcanvas.dismiss()"
    ></base-button>
  </div>
  <div class="offcanvas-body stazzle-scrollbar">
    <ng-container [ngTemplateOutlet]="teaserContent"></ng-container>
  </div>
  <div class="footer-content d-flex justify-content-end">
    <ng-container [ngTemplateOutlet]="widgetsTemplate"></ng-container>
  </div>
</ng-template>

<ng-template #offersCountByStatus>
  <div class="teaser w-60 w-full-80 m-0">
    <div class="d-flex justify-content-between align-items-center">
      <p class="fw-bold my-0 me-3">{{ 'inquiry.plural' | translate }}</p>
      <base-offers-status
        [state]="inquiry.status"
        [confirmedOffers]="offersCount?.confirmed"
        [declinedOffers]="offersCount?.declined"
        [openOffers]="offersCount?.open"
        [needConfirmationOffers]="offersCount?.needsConfirmation"
        [showHyphenIfEmpty]="false"
        [areDeclinedOffersAvailable]="isPsp || canPreselectOffers"
      ></base-offers-status>
    </div>
  </div>
</ng-template>

<ng-template #teaserContent>
  <base-inquiry-details-sidebar-content
    [inquiry]="inquiry"
    [inquiryId]="inquiryId"
    [isSidebarVisible]="isSidebarVisible"
    [shiftTimes]="shiftTimes"
    [sidebarContent]="sidebarContent"
  ></base-inquiry-details-sidebar-content>
</ng-template>
