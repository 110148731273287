import { BASE_PERMISSIONS, BaseTabInterface, BaseInquiryStatus, BaseDateService, BASE_DATE_SERVICE_FORMAT } from 'base';

export const APPLICATION_SECTIONS_GROWTH: Array<BaseTabInterface & { featurePermissionKeys: string[] }> = [
  {
    icon: 'fa-solid fa-fw fa-house',
    title: 'dashboard',
    url: '/dashboard',
    featurePermissionKeys: [],
  },
  {
    icon: 'fa-solid fa-fw fa-user-plus',
    title: 'requests',
    url: '/inquiries/list',
    queryParams: { page: 1, status: BaseInquiryStatus.released },
    featurePermissionKeys: [],
  },
  {
    icon: 'fa-solid fa-fw fa-person-circle-check',
    title: 'bookings.offersAndBookings',
    url: '/offers-and-bookings',
    featurePermissionKeys: [],
  },
  {
    icon: 'fa-solid fa-fw fa-bullseye',
    title: 'operations.plural',
    url: '/operations',
    queryParams: { status: 'planned,active' },
    featurePermissionKeys: [BASE_PERMISSIONS.FEATURE_ADMIN_ASSIGNMENTS],
  },
  {
    icon: 'fa-solid fa-fw fa-users',
    title: 'candidates.plural',
    url: '/candidates',
    queryParams: { active: 'true' },
    featurePermissionKeys: [BASE_PERMISSIONS.FEATURE_ADMIN_PROFILES],
  },
  {
    icon: 'fa-solid fa-rectangle-list',
    title: 'timeRecordings.page',
    url: '/time-recordings/list',
    queryParams: {
      period: `${BaseDateService.format(
        BaseDateService.getDateDaysAgo(7),
        BASE_DATE_SERVICE_FORMAT
      )},${BaseDateService.format(new Date(), BASE_DATE_SERVICE_FORMAT)}`,
    },
    featurePermissionKeys: [
      BASE_PERMISSIONS.FEATURE_ADMIN_ASSIGNMENTS,
      BASE_PERMISSIONS.FEATURE_ADMIN_ASSIGNMENT_DATES,
    ],
  },
  {
    icon: 'fa-solid fa-fw fa-building',
    title: 'location.customerPlural',
    url: '/customer-locations/list',
    featurePermissionKeys: [],
  },
];
