<div
  class="k-radiobutton"
  (click)="outerDivClick($event)"
  [ngClass]="{ 'form-group': isFormGroup }"
>
  <input
    type="radio"
    [id]="id"
    [name]="name"
    [value]="value"
    [ngModel]="current"
    (change)="setChecked(value, $event)"
    [tabindex]="tabindex"
    [disabled]="disabled"
    [checked]="isChecked"
    [attr.data-cy]="label"
    #radiobutton
  />
  <span></span>
  <label
    [for]="id"
    (click)="radiobutton.click()"
    [ngClass]="attachedClass"
    >{{ label | translate }}</label
  >
</div>
