import { BaseWeekDays } from '../../modules/inquiry/_enums/base-inquiry.enum';
import { BaseDateService } from '../services/base-date.service';

/**
 * Function to get the week day from a given date.
 * @param date The date object or string representation of a date.
 * @returns The week day as one of the BaseWeekDays enum values.
 * 'EEEEEE' returns weekdays in such format: Mo, Tu, We...
 */
export const getWeekDayFromDate = (date: Date | string): BaseWeekDays => {
  return BaseDateService.format(new Date(date), 'EEEEEE').toLowerCase() as BaseWeekDays;
};
