import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { baseSharedActions, baseSharedSelectors, BaseCoreState } from '../../_store';
import { BaseApplicationTagType } from '../enums/base-application.enum';

@Injectable()
export class BaseQualificationsGuard {
  constructor(private readonly store: Store<BaseCoreState>) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    this.store
      .select(baseSharedSelectors.getQualifications)
      .pipe(
        take(1),
        map((result) => result.data)
      )
      .subscribe((qualifications) => {
        if (!qualifications) {
          this.store.dispatch(
            baseSharedActions.getQualifications({
              application: route.data.application as BaseApplicationTagType,
            })
          );
        }
      });

    return this.store.select(baseSharedSelectors.getQualifications).pipe(
      filter((result) => result.ok !== undefined),
      map((result) => !!result.ok)
    );
  }
}
