import { getISODay, addDays } from 'date-fns';
import { WeekdaysIndexesArray } from '../../core/constants/base-calendar-values';
import { ShiftPlanRangeDateInterface } from '../../modules/inquiry/_interfaces/base-shift-plan.interface';

export const getDaysArrayWithWeekdays = <T extends Date | string | ShiftPlanRangeDateInterface>(
  start: Date,
  end: Date,
  weekdays: number[] = WeekdaysIndexesArray,
  toISOString = true,
  isShiftPlan = false
): T[] => {
  const arr = [];
  const currentDate = new Date(start.setHours(0));
  end = new Date(end.setHours(0));
  const dstTimeTimezoneOffset = new Date(start.getFullYear(), 5, 1).getTimezoneOffset();
  const normalTimeTimezoneOffset = new Date(start.getFullYear(), 1, 1).getTimezoneOffset();

  while (currentDate <= end) {
    const dayOfWeek = (currentDate.getDay() + 6) % 7;

    if (!isShiftPlan) {
      if (toISOString) {
        if (weekdays.includes(dayOfWeek)) {
          arr.push(
            new Date(currentDate.getTime() - currentDate.getTimezoneOffset() * 60000).toISOString().slice(0, 10) as T
          );
        }
      } else {
        if (weekdays.includes(dayOfWeek)) {
          arr.push(new Date(currentDate) as T);
        }
      }
    } else {
      const dateResetTimezone = new Date(currentDate.getTime() - currentDate.getTimezoneOffset() * 60000)
        .toISOString()
        .slice(0, 10);

      if (weekdays.includes(dayOfWeek)) {
        arr.push({
          dateString: dateResetTimezone,
          isVisible: true,
        } as T);
      } else {
        arr.push({
          dateString: dateResetTimezone,
          isVisible: false,
        } as T);
      }
    }

    currentDate.setUTCDate(currentDate.getUTCDate() + 1);
    if (currentDate.getHours() !== 0) {
      const dstTimeDifference = (Math.abs(dstTimeTimezoneOffset) - Math.abs(normalTimeTimezoneOffset)) * 60 * 1000;
      if (currentDate.getTimezoneOffset() === dstTimeTimezoneOffset) {
        currentDate.setTime(currentDate.getTime() - dstTimeDifference);
      } else {
        currentDate.setTime(currentDate.getTime() + dstTimeDifference);
      }
    }
  }
  return arr;
};

export const getClosestDayOfLastWeek = (dayOfWeek: string, fromDate = new Date()): Date => {
  const dayOfWeekMap: { [key: string]: number } = {
    Mon: 1,
    Tue: 2,
    Wed: 3,
    Thur: 4,
    Fri: 5,
    Sat: 6,
    Sun: 7,
  };

  const offsetDays = -(getISODay(fromDate) - dayOfWeekMap[dayOfWeek]);

  return addDays(fromDate, offsetDays);
};
