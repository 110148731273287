import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CustomerAssignmentFilterListsResponse, MetaPagination, PspBranchShortInfoResponse } from '../../../api';
import { BaseInquiryCandidateInterface } from '../../modules/inquiry/_interfaces/base-inquiry-candidate.interface';
import { BasePspInquiryOfferInterface } from '../../modules/inquiry/_interfaces/base-psp-inquiry-offer.interface';
import { BaseCustomerInquiryOfferInterface } from '../../modules/inquiry/_interfaces/base-cusomer-inquiry-offer.interface';

const getInquiriesState = createFeatureSelector('inquiries');

export const getInquiries = createSelector(
  getInquiriesState,
  (state: any): { ok: undefined | boolean; data: undefined | any } => state?.inquiries
);

export const getInquiry = createSelector(getInquiriesState, (state: any): any => state?.inquiry);
export const getDepartment = createSelector(getInquiriesState, (state: any) => state?.department);
export const getSimilarInquiriesList = createSelector(getInquiriesState, (state: any) => state?.similarInquiriesList);
export const getSimilarInquiriesMapped = createSelector(
  getInquiriesState,
  (state: any) => state?.similarInquiriesMapped
);
export const getShiftPlanRangeList = createSelector(getInquiriesState, (state: any) => state?.shiftPlanCalendarRanges);
export const getShiftPlanList = createSelector(getInquiriesState, (state: any) => state?.shiftPlanCalendarData);
export const getInquiryCRUD = createSelector(getInquiriesState, (state: any) => state?.inquiryCRUD);
export const getPSPInquiry = createSelector(getInquiriesState, (state: any) => state?.pspInquiry);
export const getResponsibleUsers = createSelector(getInquiriesState, (state: any) => state.responsibleUsers);
export const getPSPCustomerQualificationPrice = createSelector(
  getInquiriesState,
  (state: any) => state.pspCustomerQualificationPrice
);
export const getCustomerInquiry = createSelector(getInquiriesState, (state: any) => state?.customerInquiry);
export const getPSPOfferCreateSurcharges = createSelector(
  getInquiriesState,
  (state: any) => state.pspOfferCreateSurcharges
);
export const getCustomerSelectedFilters = createSelector(
  getInquiriesState,
  (state: any) => state?.selectedInquiryFilters
);
export const getInquiryBranches = createSelector(
  getInquiriesState,
  (state: any): { data: Array<PspBranchShortInfoResponse> | undefined; ok: boolean | undefined } => state?.branches
);
export const getInquiryOffersCloseStatus = createSelector(
  getInquiriesState,
  (state: any) => state?.inquiryOfferCloseHeaderWrapper
);
export const getDownloadDocument = createSelector(
  getInquiriesState,
  (state: any): { data: Blob | undefined } => state.downloadDocument
);
export const getInquiryCandidates = createSelector(
  getInquiriesState,
  (
    state: any
  ): { data: BaseInquiryCandidateInterface[]; pagination: MetaPagination | undefined; ok: boolean | undefined } =>
    state?.inquiryCandidatesList
);
export const getPspInquiryOffers = createSelector(
  getInquiriesState,
  (
    state: any
  ): {
    data: BasePspInquiryOfferInterface[];
    pagination: MetaPagination | undefined;
    ok: boolean | undefined;
  } => state?.pspInquiryOffersList
);
export const getCustomerInquiryOffers = createSelector(
  getInquiriesState,
  (
    state: any
  ): {
    data: BaseCustomerInquiryOfferInterface[];
    filters: CustomerAssignmentFilterListsResponse | undefined;
    pagination: MetaPagination | undefined;
    ok: boolean | undefined;
  } => state?.customerInquiryOffersList
);
