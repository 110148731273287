import { Directive, Input, ElementRef, OnInit, AfterViewInit } from '@angular/core';
import { BaseImagePlaceholderEnum } from '../enums/base-image-placeholder.enum';

@Directive({
  selector: '[baseImageFallback]',
})
export class BaseImageFallbackDirective implements OnInit, AfterViewInit {
  @Input() isCompany: boolean = false;

  preloaderImg: HTMLImageElement | null = null;
  hostElement: HTMLImageElement;
  defaultAvatar: BaseImagePlaceholderEnum = BaseImagePlaceholderEnum.BASE_DEFAULT_AVATAR;

  constructor(private elementRef: ElementRef) {}

  ngOnInit() {
    if (this.isCompany) {
      this.defaultAvatar = BaseImagePlaceholderEnum.BASE_DEFAULT_AVATAR_COMPANY;
    }

    this.preloaderImg = document.createElement('img');
    this.hostElement = <HTMLImageElement>this.elementRef.nativeElement;
  }

  ngAfterViewInit() {
    const backgroundImage = this.elementRef.nativeElement.style.backgroundImage.match(/url\("(.*)"\)/)[1];
    if (this.preloaderImg && backgroundImage) {
      this.preloaderImg.src = backgroundImage;

      this.preloaderImg.addEventListener('error', () => {
        this.hostElement.style.backgroundImage = `url(${this.defaultAvatar})`;
      });
    }
  }
}
