import { Directive, HostListener, Input, Renderer2 } from '@angular/core';
import { FormGroup, AbstractControl, FormControl } from '@angular/forms';

@Directive({
  selector: '[baseScrollToInvalid]',
})
export class BaseScrollToInvalidDirective {
  @Input() baseInvalidForm!: FormGroup;
  @Input() baseInvalidFormSubmitted: boolean = false;

  constructor(private renderer: Renderer2) {}

  @HostListener('click', ['$event'])
  onButtonClick() {
    if (!this.baseInvalidFormSubmitted || this.baseInvalidForm.valid) {
      return;
    }

    const invalidControl = this.findFirstInvalidControl(this.baseInvalidForm);
    if (invalidControl) {
      this.scrollToControl(invalidControl);
    }
  }

  /**
   * Recursively find the first invalid control in the form or form group.
   * @private
   * @param {AbstractControl} control - The control/form group to start the search from.
   * @returns {AbstractControl | null} - The first invalid control found or null if none found.
   */
  private findFirstInvalidControl(control: AbstractControl): AbstractControl | null {
    if (control.invalid && !!control.errors) {
      return control;
    }

    if (control instanceof FormGroup) {
      for (const name in control.controls) {
        if (control.controls[name].invalid) {
          return this.findFirstInvalidControl(control.controls[name]);
        }
      }
    }

    return null;
  }

  /**
   * Scroll the view to the specified control.
   * @private
   * @param {AbstractControl} control - The control to scroll to.
   */
  private scrollToControl(control: AbstractControl) {
    const name = this.getControlName(control);
    if (!name) {
      return;
    }

    let el = document.querySelector(`[formGroupName="${name}"]`);
    if (!el) {
      el = document.querySelector(`[formControlName="${name}"]`);
    }

    if (el) {
      this.renderer.selectRootElement(el, true).scrollIntoView({ behavior: 'smooth' });
    }
  }

  /**
   * Retrieve the name of the control within its parent form group.
   * @private
   * @param {AbstractControl} control - The control to retrieve the name for.
   * @returns {string | null} - The name of the control or null if not found.
   */
  private getControlName(control: AbstractControl): string | null {
    const parent = control.parent;
    if (!parent) {
      return null;
    }

    const formGroup = parent.controls;

    if (!(formGroup instanceof Array)) {
      return Object.keys(formGroup).find((name) => control === formGroup[name]) || null;
    }

    return null;
  }
}
