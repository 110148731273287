import { createFeatureSelector, createSelector } from '@ngrx/store';
import { BaseTokenInterface } from '../../shared/interfaces/base-token-service.interface';
import { BaseAuthState } from '../reducers/base-auth.reducer';

export const getAuth = createFeatureSelector<BaseAuthState>('auth');

export const getUserInfo = createSelector(
  getAuth,
  (state: BaseAuthState): { data: BaseTokenInterface | undefined; ok: boolean | undefined } => state?.user
);

export const getRefreshInfo = createSelector(getAuth, (state: BaseAuthState) => state.refresh);

export const getFirstTimeLoginStatus = createSelector(getAuth, (state: BaseAuthState) => state.isFirstTimeLogin);

export const getCustomizedUserStatus = createSelector(getAuth, (state: BaseAuthState) => state.isCustomizedUser);
