import { Component, Input, TemplateRef, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseEnvironmentService } from '../../../core/services/base-environment.service';
import { BaseApplication } from '../../enums/base-application.enum';
import { PdfSrcType } from '../../types/base-pdf-src.type';

@Component({
  selector: 'base-pdf-viewer',
  templateUrl: './base-pdf-viewer.component.html',
})
export class BasePdfViewerComponent implements OnInit {
  @Input() src: PdfSrcType = '';
  @Input() fileName: string | undefined = '';
  @Input() customToolbar: TemplateRef<any> | undefined;
  @Input() hideBorder = false;
  @Input() height = '90vh';

  pdfLoadingFailed = false;
  application: BaseApplication;

  constructor(public translateService: TranslateService, private environmentService: BaseEnvironmentService) {
    (window as any).ngxConsoleFilter = (): boolean => false;
  }

  ngOnInit() {
    const application = this.environmentService.getLocalValue('application');
    this.application = application.app;
  }

  onPdfLoadingFailed(): void {
    this.pdfLoadingFailed = true;
  }
}
