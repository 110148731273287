<div class="d-flex align-items-center flex-wrap justify-content-between">
  <base-header
    [showSideMenu]="false"
    logo="assets-global/images/stazzle-logo-dark-blue.svg"
  ></base-header>
  <div class="d-flex align-items-center justify-content-between flex-nowrap gap-2 pe-6 header-actions">
    <base-language-switch [color]="colors.darkBlue"></base-language-switch>
    <div class="d-flex flex-wrap justify-content-end gap-2">
      <base-button
        (btnClick)="goToContactUrl()"
        [size]="buttonSize.xs"
        label="contact"
        color="dark"
      ></base-button>
      <base-button
        routerLink="/auth/imprint"
        [size]="buttonSize.xs"
        label="imprint"
        color="dark"
      ></base-button>
      <base-button
        (btnClick)="downloadPrivacyPolicy()"
        [size]="buttonSize.xs"
        label="privacyPolicy"
        color="dark"
      ></base-button>
    </div>
  </div>
</div>

<div class="d-flex justify-content-center align-items-center mt-8">
  <section class="content-card teaser p-8">
    <div class="d-flex flex-column justify-content-between gap-8">
      <ng-content></ng-content>
    </div>
  </section>
</div>
