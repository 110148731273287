import { createAction, props } from '@ngrx/store';
import { FormGroup } from '@angular/forms';
import { BaseStaffRequestFormInterface } from '../../modules/inquiry/_interfaces/base-inquiry.interface';
import { FormRawValue } from '../../modules/inquiry/_interfaces/base-form.interface';

// All forms
export const clearAllForms = createAction('[Forms] Clear all forms');

// Staff request form
export const getStaffRequestForm = createAction('[Forms] Get staff request form');
export const setStaffRequestForm = createAction(
  '[Forms] Set staff request form',
  props<{ payload: FormRawValue<FormGroup<BaseStaffRequestFormInterface>> }>()
);
export const clearStaffRequestForm = createAction('[Forms] Clear staff request form');

// Account registration form
export const getAccountRegisterForm = createAction('[Forms] Get account register form');
export const setAccountRegisterForm = createAction(
  '[Forms] Set account register form',
  props<{ payload: any | undefined }>()
);
export const clearAccountRegisterForm = createAction('[Forms] Clear account register form');
