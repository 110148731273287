<form
  class="d-flex flex-column"
  [formGroup]="form"
  [ngClass]="{ 'was-validated': isFormSubmitted }"
>
  <div class="d-flex flex-nowrap align-items-center justify-content-between mb-4">
    <div class="d-flex flex-nowrap align-items-center gap-3">
      <base-avatar
        [image]="userData?.avatar?.data"
        [size]="avatarSizes.large"
      ></base-avatar>
      <h5 class="m-0">{{ userData?.firstName }} {{ userData?.lastName }}</h5>
    </div>
    <base-button
      [size]="buttonSizes.md"
      color="secondary"
      label="auth.password.change"
      (btnClick)="openChangePasswordModal()"
    ></base-button>
  </div>

  <div class="d-flex gap-5 my-4">
    <div class="w-35 pt-2">
      <label [ngClass]="{ required: isAdmin }">{{ 'placeholders.role' | translate }}</label>
    </div>
    <div class="d-flex flex-column w-35 gap-2">
      <span
        *ngIf="!isAdmin"
        class="pt-2"
        >{{
          form.get('isAdministrator')?.value
            ? ('placeholders.administrator' | translate)
            : ('user.singular' | translate)
        }}</span
      >
      <ng-container *ngIf="isAdmin">
        <base-radiobutton
          [value]="true"
          [isChecked]="form.get('isAdministrator')?.value"
          class="rb"
          label="placeholders.administrator"
          formControlName="isAdministrator"
        ></base-radiobutton>

        <base-radiobutton
          [value]="false"
          [isChecked]="!form.get('isAdministrator')?.value"
          class="rb"
          label="user.singular"
          formControlName="isAdministrator"
        ></base-radiobutton>
      </ng-container>
    </div>
  </div>

  <div
    class="d-flex gap-5 my-4"
    *ngIf="!form.get('isAdministrator')?.value"
  >
    <div class="w-35 pt-2">
      <label class="required">{{ 'permission.group' | translate }}</label>
    </div>
    <div class="w-35">
      <base-dropdown
        [items]="groups"
        formControlName="group"
        [searchable]="false"
      >
      </base-dropdown>
    </div>
  </div>

  <div class="d-flex gap-5 my-4">
    <div class="w-35 pt-2">
      <label class="required">{{ 'title' | translate }}</label>
    </div>
    <div class="w-35">
      <base-dropdown
        [items]="genderOptions"
        [translateItems]="true"
        formControlName="gender"
        [searchable]="false"
        bindOptionLabel="label"
        outputModel="value"
      >
      </base-dropdown>
    </div>
  </div>

  <div class="d-flex gap-5 my-4">
    <div class="w-35 pt-2">
      <label>{{ 'position' | translate }}</label>
    </div>
    <div class="w-50">
      <base-input
        label=""
        formControlName="position"
        placeholder=""
        [isRequired]="true"
        [isInvalid]="!!form.get('position')?.errors"
        [errorMessage]="form.get('position')?.errors | baseFieldValidation"
      ></base-input>
    </div>
  </div>

  <div class="d-flex gap-5 my-4">
    <div class="w-35 pt-2">
      <label class="required">{{ 'firstName' | translate }}</label>
    </div>
    <div class="w-50">
      <base-input
        label=""
        formControlName="firstName"
        placeholder=""
        [isRequired]="true"
        [isInvalid]="!!form.get('firstName')?.errors"
        [errorMessage]="form.get('firstName')?.errors | baseFieldValidation"
      ></base-input>
    </div>
  </div>

  <div class="d-flex gap-5 my-4">
    <div class="w-35 pt-2">
      <label class="required">{{ 'lastName' | translate }}</label>
    </div>
    <div class="w-50">
      <base-input
        label=""
        formControlName="lastName"
        placeholder=""
        [isRequired]="true"
        [isInvalid]="!!form.get('lastName')?.errors"
        [errorMessage]="form.get('lastName')?.errors | baseFieldValidation"
      ></base-input>
    </div>
  </div>

  <div class="d-flex gap-5 my-4">
    <div class="w-35 pt-2">
      <label class="required">{{ 'email' | translate }}</label>
    </div>
    <div class="w-50">
      <base-input
        label=""
        formControlName="email"
        placeholder="sampleEmail"
        [isRequired]="true"
        [isEmailField]="true"
        [isInvalid]="!!form.get('email')?.errors"
        [errorMessage]="form.get('email')?.errors | baseFieldValidation"
      ></base-input>
    </div>
  </div>

  <div class="d-flex gap-5 my-4">
    <div class="w-35 pt-2">
      <label>{{ 'landlinePhone' | translate }}</label>
    </div>
    <div class="w-50">
      <base-input
        label=""
        formControlName="phone"
        placeholder=""
        [isRequired]="true"
        [isInvalid]="!!form.get('phone')?.errors"
        [errorMessage]="form.get('phone')?.errors | baseFieldValidation"
      ></base-input>
    </div>
  </div>

  <div class="d-flex gap-5 my-4">
    <div class="w-35 pt-2">
      <label>{{ 'phoneMobile' | translate }}</label>
    </div>
    <div class="w-50">
      <base-input
        label=""
        formControlName="mobilePhone"
        placeholder=""
        [isRequired]="true"
        [isInvalid]="!!form.get('mobilePhone')?.errors"
        [errorMessage]="form.get('mobilePhone')?.errors | baseFieldValidation"
      ></base-input>
    </div>
  </div>

  <div class="p-0 mb-5 mt-3 d-flex justify-content-between">
    <base-button
      [size]="buttonSizes.md"
      color="secondary"
      label="action.cancel"
      (btnClick)="cancelEditUser()"
    ></base-button>
    <base-button
      [size]="buttonSizes.md"
      color="primary"
      label="action.save"
      (btnClick)="confirmEditUser()"
    ></base-button>
  </div>
</form>

<ng-template #changePasswordTemplate>
  <base-change-user-password-form
    [form]="changePasswordForm"
    [isFormSubmitted]="isChangePasswordFormSubmitted"
    (changePassword)="changeUserPassword()"
    (cancelChangePassword)="cancelChangeUserPassword()"
  ></base-change-user-password-form>
</ng-template>
