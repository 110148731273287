import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  CustomerResponse,
  DepartmentListItemResponse,
  LocationListItemResponse,
  PspResponse,
  UserResponse,
} from '../../../api';
import { AdministrationState } from '../reducers/base-administration.reducer';

const getAdministrationRoot = createFeatureSelector('administration');

export const getAdministration = createSelector(getAdministrationRoot, (state: any): AdministrationState => state);

export const getUserProfile = createSelector(
  getAdministration,
  (state: AdministrationState): { data: UserResponse | undefined; ok: boolean | undefined } => state.userProfileData
);

export const getUserProfileData = createSelector(
  getAdministration,
  (state: AdministrationState): UserResponse | undefined => state.userProfileData.data
);

export const getUserProfileStatus = createSelector(
  getAdministration,
  (state: AdministrationState): boolean | undefined => state.userProfileData.ok
);

export const getUserPasswordUpdateStatus = createSelector(
  getAdministration,
  (state: AdministrationState): boolean | undefined => state.userPasswordData.ok
);

export const getProfilePasswordUpdateStatus = createSelector(
  getAdministration,
  (state: AdministrationState): { ok: boolean | undefined } => state.profilePasswordData
);

export const getCompanyInfo = createSelector(
  getAdministration,
  (state: AdministrationState): { data: CustomerResponse | PspResponse | undefined; ok: boolean | undefined } =>
    state.companyInfo
);

export const getCompanyInfoData = createSelector(
  getAdministration,
  (state: AdministrationState): CustomerResponse | PspResponse | undefined => state.companyInfo.data
);

export const getCustomerData = createSelector(
  getAdministration,
  (state: AdministrationState): { data: CustomerResponse | undefined; ok: boolean | undefined } => state.customerData
);

export const getCustomerDataData = createSelector(
  getAdministration,
  (state: AdministrationState): CustomerResponse | undefined => state.customerData.data
);

export const getPermissions = createSelector(getAdministration, (state: AdministrationState) => state?.permissions);

export const getCatalogQualifications = createSelector(
  getAdministration,
  (state: AdministrationState) => state.catalogQualifications
);

export const getSurcharges = createSelector(getAdministration, (state: AdministrationState) => state.surcharges);

export const getUpdateSurchargeStatus = createSelector(
  getAdministration,
  (state: AdministrationState) => state.surcharges.updateCrud
);

export const getPspData = createSelector(getAdministration, (state: AdministrationState) => state.pspData);
export const getPspDataData = createSelector(
  getAdministration,
  (state: AdministrationState): { message: string; data: PspResponse } | undefined => state?.pspData?.data
);

export const getPspUpdateInfoStatus = createSelector(
  getAdministration,
  (state: AdministrationState) => state.pspData.updateCrud
);

export const getLocationsData = createSelector(
  getAdministration,
  (state: AdministrationState): { data: LocationListItemResponse[] | undefined; ok: boolean | undefined } =>
    state.locations
);

export const getLocationDepartmentsData = createSelector(
  getAdministration,
  (state: AdministrationState): DepartmentListItemResponse[] | undefined => state.locationDepartments.data
);

export const getLocationData = createSelector(getAdministration, (state: AdministrationState) => state.location);
export const getCreatedLocationId = createSelector(
  getAdministration,
  (state: AdministrationState) => state.createdLocationId.data
);
export const getCustomerLocationTerminals = createSelector(
  getAdministration,
  (state: AdministrationState) => state.customerLocationTerminals
);

export const getTerminalAPIKey = createSelector(
  getAdministration,
  (state: any): { data: Blob | undefined; fileName: string | undefined; terminalId: number | undefined } =>
    state.terminalAPIKey
);
