import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { BaseCoreState, baseInquiryActions, baseInquirySelectors } from '../../../_store';

@Injectable()
export class BaseCustomerInquiryOffersGuard {
  constructor(private readonly store: Store<BaseCoreState>) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    let payload = { inquiry: +route.params.inquiry };
    if (route.queryParams?.search) {
      payload = { ...payload, ...{ search: route.queryParams?.search } };
    }

    this.store
      .select(baseInquirySelectors.getCustomerInquiry)
      .pipe(
        filter((response) => response.ok !== undefined),
        take(1)
      )
      .subscribe((response) => {
        if (!response.data) {
          this.store.dispatch(baseInquiryActions.loadCustomerInquiry({ payload: route.params.inquiry }));
        } else {
          this.store.dispatch(baseInquiryActions.loadCustomerInquiryOffersList({ payload }));
        }
      });

    return this.store.select(baseInquirySelectors.getCustomerInquiryOffers).pipe(
      filter((response) => response.ok !== undefined),
      map((response) => !!response.ok)
    );
  }
}
