import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  BranchListItemResponse,
  DepartmentListItemResponse,
  GroupListItemResponse,
  PermissionItemResponse,
  UserItemResponse,
  CustomerMedicalInviteRequest,
  PspInviteRequest,
  CustomerBusinessInviteRequest,
} from '../../../../../../api';
import { Validators, FormGroup, NonNullableFormBuilder } from '@angular/forms';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BaseApplication } from '../../../../../shared/enums/base-application.enum';
import { BaseTokenInterface } from '../../../../../shared/interfaces/base-token-service.interface';
import { BaseAvatarSize } from '../../../../../shared/components/avatar/base-avatar.component';
import { BaseButtonSize } from '../../../../../shared/components/button/base-button.component';
import { noop, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { BaseModalService } from '../../../../../shared/services/base-modal.service';
import { BaseRolesService } from '../../../../../core/services/base-roles.service';
import { map, take, takeUntil } from 'rxjs/operators';
import { BaseFilterInterface } from '../../../../../shared/interfaces/base-filter.interface';
import { baseEmailValidator } from '../../../../../shared/validators/base-email.validator';
import {
  baseAdministrationActions,
  baseUserActions,
  baseAdministrationSelectors,
  baseAuthSelectors,
  baseSharedSelectors,
  baseUserSelectors,
  BaseCoreState,
} from '../../../../../_store';
import { GenderType } from '../../../_models/base-user-profile.model';
import { InviteUserForm } from '../../../_interfaces/base-user-management.interface';

@Component({
  selector: 'base-user-management',
  templateUrl: './base-user-management.component.html',
  styleUrls: ['./base-user-management.component.scss'],
})
export class BaseUserManagementComponent implements OnInit, OnDestroy {
  @Input() application: BaseApplication;
  @ViewChild('inviteUserModalTemplate', { static: true }) inviteUserModalTemplate: ElementRef;

  userList: UserItemResponse[];
  filteredUserList: UserItemResponse[];
  permissionGroups: GroupListItemResponse[];
  pspBranches: BranchListItemResponse[];
  customerDepartments: DepartmentListItemResponse[];
  inviteUserForm: FormGroup<InviteUserForm>;
  modalRef: NgbModalRef;
  loggedInUser: BaseTokenInterface;
  permissions: PermissionItemResponse[];
  avatarSizes = BaseAvatarSize;
  userListLoaded = false;
  selectedGroupId: string;
  isPSP: boolean;
  isInviteUserFormSubmitted: boolean;
  filterActiveUsers = true;

  buttonSizes = BaseButtonSize;

  private destroy$ = new Subject();

  constructor(
    private store: Store<BaseCoreState>,
    private modalService: BaseModalService,
    private fb: NonNullableFormBuilder,
    private rolesService: BaseRolesService
  ) {}

  ngOnInit() {
    this.isPSP = this.rolesService.isPsp();
    this.initInviteUserForm();

    this.store
      .select(baseUserSelectors.getAdminAndUserList)
      .pipe(
        takeUntil(this.destroy$),
        map((state) => state.data as UserItemResponse[])
      )
      .subscribe((users) => {
        this.userList = [...users].sort((a, b) =>
          a.position > b.position ? 1 : b.position > a.position || a.invited || b.invited ? -1 : 0
        );
        this.filterUsersByGroup();
        this.userListLoaded = true;
      });

    this.store
      .select(baseUserSelectors.getUserGroups)
      .pipe(
        takeUntil(this.destroy$),
        map((state) => state.data as GroupListItemResponse[])
      )
      .subscribe((groups) => {
        this.permissionGroups = groups;
      });

    this.store
      .select(baseAdministrationSelectors.getPermissions)
      .pipe(
        takeUntil(this.destroy$),
        map((state) => state.data as (PermissionItemResponse & { isSelected?: boolean })[])
      )
      .subscribe((permissions) => {
        this.permissions = permissions;
      });

    this.store
      .select(baseAuthSelectors.getUserInfo)
      .pipe(
        take(1),
        map((response) => response.data as BaseTokenInterface)
      )
      .subscribe((user) => (this.loggedInUser = user));

    this.store
      .select(baseSharedSelectors.getBranches)
      .pipe(
        takeUntil(this.destroy$),
        map((state) => state.data as Array<BranchListItemResponse>)
      )
      .subscribe((branches) => (this.pspBranches = branches));

    this.store
      .select(baseSharedSelectors.getDepartments)
      .pipe(
        takeUntil(this.destroy$),
        map((state) => state.data as Array<DepartmentListItemResponse>)
      )
      .subscribe((departments) => (this.customerDepartments = departments));

    this.store
      .select(baseUserSelectors.getUserInactiveFilter)
      .pipe(takeUntil(this.destroy$))
      .subscribe((filterActiveUsers) => (this.filterActiveUsers = filterActiveUsers));
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }

  selectGroup(event: BaseFilterInterface): void {
    this.selectedGroupId = event.id;
    this.filterUsersByGroup();
  }

  filterInactiveUsers(): void {
    this.filterActiveUsers = !this.filterActiveUsers;
    this.store.dispatch(baseUserActions.setUserInactiveFilter({ filterActive: this.filterActiveUsers }));
    this.store.dispatch(
      baseUserActions.loadAdminAndUserList({
        payload: { id: this.loggedInUser.organizationId.toString() },
      })
    );
  }

  openInviteUserModal(): void {
    this.inviteUserForm.reset(
      this.isPSP
        ? {
            firstName: '',
            gender: null,
            lastName: '',
            email: '',
            group: null,
            pspBranches: null,
          }
        : {
            firstName: '',
            gender: null,
            lastName: '',
            email: '',
            group: null,
            customerDepartments: null,
          }
    );
    this.isInviteUserFormSubmitted = false;
    this.modalRef = this.modalService.open(
      {
        headText: 'user.invite',
        bodyTemplate: this.inviteUserModalTemplate,
        cancelButtonDisplayed: false,
        okButtonDisplayed: false,
        hideFooter: true,
      },
      {
        size: 'lg',
      }
    );
    this.modalRef.result.then(noop, noop);
  }

  confirmUserInvitation(): void {
    if (this.inviteUserForm.valid) {
      const inviteUserFormValue = this.inviteUserForm.getRawValue();
      const body: Partial<CustomerMedicalInviteRequest & PspInviteRequest> = {
        group: inviteUserFormValue.group?.id || 0,
        gender: inviteUserFormValue.gender?.value || (1 as GenderType),
        email: inviteUserFormValue.email,
        firstName: inviteUserFormValue.firstName,
        lastName: inviteUserFormValue.lastName,
      };

      if (inviteUserFormValue.pspBranches) {
        body.pspBranches = inviteUserFormValue.pspBranches;
      }

      if (inviteUserFormValue.customerDepartments) {
        body.customerDepartments = inviteUserFormValue.customerDepartments;
      }

      const payload = {
        id: this.loggedInUser.organizationId,
        fullList: true,
        body: body as PspInviteRequest | CustomerBusinessInviteRequest,
      };
      this.store.dispatch(baseAdministrationActions.inviteUser({ payload }));
      this.modalRef.close();
    }
  }

  private filterUsersByGroup(): void {
    this.filteredUserList =
      this.selectedGroupId === 'isAdmin'
        ? this.userList.filter((item) => item.admin)
        : !!this.selectedGroupId
        ? this.userList.filter((item) => item.group?.id === +this.selectedGroupId)
        : this.userList;
  }

  private initInviteUserForm(): void {
    this.inviteUserForm = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: ['', [Validators.required, baseEmailValidator()]],
      gender: [null as { label: string; value: GenderType } | null, [Validators.required]],
      group: [null as GroupListItemResponse | null, [Validators.required]],
    });

    if (this.isPSP) {
      this.inviteUserForm.addControl('pspBranches', this.fb.control(null as number[] | null, [Validators.required]));
    } else {
      this.inviteUserForm.addControl(
        'customerDepartments',
        this.fb.control(null as number[] | null, [Validators.required])
      );
    }
  }
}
