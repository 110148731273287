import { Component, Input } from '@angular/core';
import { BaseInquiryBubble } from '../../_models/base-inquiry-date';

@Component({
  selector: 'base-inquiry-bubble-new',
  templateUrl: './base-inquiry-bubble-new.component.html',
  styleUrls: ['./base-inquiry-bubble-new.component.scss'],
})
export class BaseInquiryBubbleNewComponent {
  @Input() bubble: BaseInquiryBubble;
  @Input() isShiftPlan = false;
}
