import { OnDestroy, Directive, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { Location } from '@angular/common';
import { BaseCoreState } from '../../_store';
import { BaseButtonSize } from '../components/button/base-button.component';
import { getAppRouterState } from '../../_store/selectors/base-shared.selectors';

@Directive({
  selector: '[baseBackButton]',
})
export class BaseBackButtonDirective implements OnInit, OnDestroy {
  previousRoute: string | undefined;

  buttonSize = BaseButtonSize.lg;

  private destroy$ = new Subject();

  constructor(
    public activatedRoute: ActivatedRoute,
    private router: Router,
    private store: Store<BaseCoreState>,
    private location: Location
  ) {}

  get redirectRoute(): { url: string; query: Record<string, any> } | null {
    return this.getSnapshot(this.activatedRoute)?.redirectRoute || null;
  }

  @HostListener('click', ['$event']) onClick(event: Event) {
    this.redirectToProvidedBackRoute();
  }

  ngOnInit() {
    this.store
      .select(getAppRouterState)
      .pipe(
        filter(() => !!this.redirectRoute),
        take(1)
      )
      .subscribe((routerState) => {
        if (this.redirectRoute?.url && !this.previousRoute) {
          if (routerState.data.previousUrl) {
            this.previousRoute = routerState.data.previousUrl;
          } else {
            this.previousRoute = this.redirectRoute.url;
          }
        }

        if (this.previousRoute && this.router.url.includes(this.previousRoute)) {
          this.previousRoute = undefined;
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  getSnapshot(activatedRoute: ActivatedRoute) {
    let route = activatedRoute;
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route?.snapshot?.data;
  }

  redirectToProvidedBackRoute(): void {
    if (this.previousRoute) {
      const urlData = this.getUrlData(this.previousRoute);
      const queryParams = urlData?.queryParams || this.redirectRoute?.query || {};
      const url = urlData?.pathName || this.previousRoute;

      this.router.navigate([url], {
        queryParams,
      });
    } else {
      this.location.back();
    }
  }

  getUrlData(route: string): { pathName: string; queryParams: Record<string, any> } | null {
    const urlObject = new URL(route, window.location.origin);
    const queryParams: Record<string, any> = {};
    urlObject.searchParams.forEach((value, key) => {
      queryParams[key] = value;
    });
    return !!Object.keys(queryParams).length ? { pathName: urlObject.pathname, queryParams } : null;
  }
}
