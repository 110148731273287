import { AbstractControl, ValidationErrors } from '@angular/forms';

export const baseMatchValuesValidator = (matchTo: string) => {
  return (control: AbstractControl): ValidationErrors | null => {
    return (!!control.parent &&
      !!control.parent.value &&
      control.value === (control.parent.controls as { [key: string]: AbstractControl })[matchTo].value) ||
      (!!control.parent && !control.parent.value && !control.value)
      ? null
      : { isMatching: true };
  };
};
