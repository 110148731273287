import { BASE_PERMISSIONS, BASE_ROLES, BaseTabInterface } from 'base';

export const USER_MENU_GROWTH: Array<
  BaseTabInterface & { permissionsAllowed: BASE_PERMISSIONS[]; rolesAllowed: BASE_ROLES[] }
> = [
  {
    title: 'companyMasterData',
    url: '/administration/master-data',
    permissionsAllowed: [BASE_PERMISSIONS.FEATURE_ADMIN_MASTER_DATA, BASE_PERMISSIONS.FEATURE_ADMIN_DOCUMENTS],
    rolesAllowed: [],
    id: 10,
  },
  {
    title: 'user.management',
    url: '/administration/users',
    permissionsAllowed: [BASE_PERMISSIONS.FEATURE_ADMIN_USERS],
    rolesAllowed: [],
    id: 20,
  },
  {
    title: 'customer.related.temporaryEmployment',
    url: '/administration/temporary-employment',
    permissionsAllowed: [],
    rolesAllowed: [],
    id: 30,
  },
  {
    title: 'administration.manageOrganisation',
    url: '/administration/organisation',
    permissionsAllowed: [BASE_PERMISSIONS.FEATURE_ADMIN_BRANCHES],
    rolesAllowed: [],
    id: 40,
  },
  {
    title: 'administration.userSettings',
    url: '/administration/user-settings',
    permissionsAllowed: [],
    rolesAllowed: [],
    id: 50,
  },
  {
    title: 'administration.digitalSignatures',
    url: '/administration/digital-signature',
    permissionsAllowed: [BASE_PERMISSIONS.FEATURE_SIGN_CONTRACT],
    rolesAllowed: [],
    id: 60,
  },
];
