export enum BaseAvailableFileTypeEnum {
  pdf = 'pdf',
  jpeg = 'jpeg',
  png = 'png',

  // type that is corresponding to mime type is required for correct preview of a form control in 'base-documentupload-modal'
  applicationPDF = 'application/pdf',
  imageJPEG = 'image/jpeg',
  imagePNG = 'image/png',
}
