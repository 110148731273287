export const BaseAbsenceTypes = [
  {
    label: 'work.holiday',
    value: 'holiday',
    icon: 'fa-solid fa-tree-palm',
  },
  {
    label: 'work.illness',
    value: 'illness',
    icon: 'fa-solid fa-face-thermometer',
  },
  {
    label: 'work.offline',
    value: 'offline',
    icon: 'assigned-outside-icon',
  },
  {
    label: 'work.free_shift',
    value: 'free_shift',
    icon: 'free-shift-icon',
  },
  {
    label: 'work.time_off_compensation',
    value: 'time_off_compensation',
    icon: 'time-off-icon',
  },
  {
    label: 'work.unexcused_absence',
    value: 'unexcused_absence',
    icon: 'unexcused-absence-icon',
  },
  {
    label: 'work.other',
    value: 'other',
    icon: 'fa-solid fa-ban',
  },
];
