import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DocumentsState } from '../reducers/base-documents.reducer';
import {
  CustomerDepartmentResponse,
  DepartmentListItemResponse,
  RequirementResponse,
  UserItemResponse,
} from '../../../api';

const getCustomerDepartmentRoot = createFeatureSelector('customerDepartment');

export const getCustomerDepartmentState = createSelector(
  getCustomerDepartmentRoot,
  (state: any): DocumentsState => state
);

export const getCustomerDepartment = createSelector(
  getCustomerDepartmentState,
  (
    state: any
  ): {
    data: CustomerDepartmentResponse | undefined;
    ok: boolean | undefined;
  } => state.department
);

export const getCustomerUsers = createSelector(
  getCustomerDepartmentState,
  (
    state: any
  ): {
    data: UserItemResponse[];
    ok: boolean | undefined;
  } => state.customerUsers
);

export const getCustomerActiveUsers = createSelector(
  getCustomerDepartmentState,
  (
    state: any
  ): {
    data: UserItemResponse[];
    ok: boolean | undefined;
  } => state.customerActiveUsers
);

export const getRequirementCategories = createSelector(
  getCustomerDepartmentState,
  (
    state: any
  ): {
    data: RequirementResponse[];
    ok: boolean | undefined;
  } => state.requirementCategories
);

export const getCustomerDepartmentCrud = createSelector(
  getCustomerDepartmentState,
  (state: any): { data: DepartmentListItemResponse; ok: boolean | undefined } => state.crud
);
