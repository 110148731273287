import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  DepartmentListItemResponse,
  EmptySuccessfulResponse,
  PaginatedApiResponse,
  PriceListRequest,
  PspCustomerLocationItemResponse,
  PspCustomerLocationService,
  PspCustomerService,
  QualificationPricingListItemResponse,
  PspCustomerSurchargeResponse,
  PspCustomerLocationShowResponse,
} from '../../../api';
import { BaseSharedHelperService } from '../../shared/services/base-shared-helper.service';
import { BaseApplicationTagType } from '../../shared/enums/base-application.enum';

@Injectable()
export class BaseRelatedCustomerLocationsService {
  constructor(
    private pspCustomerLocationService: PspCustomerLocationService,
    private pspCustomerService: PspCustomerService
  ) {}

  relatedCustomerLocationList(params: {
    psp: number;
    page?: any;
    size?: any;
    search?: any;
    'branches[]'?: any;
  }): Observable<PaginatedApiResponse & { data?: PspCustomerLocationItemResponse[] }> {
    return this.pspCustomerLocationService.getPspCustomerLocationList(params);
  }

  relatedCustomerLocationDetails(params: {
    psp: number;
    customer: number;
    location: number;
  }): Observable<PspCustomerLocationShowResponse> {
    return this.pspCustomerService.getPspCustomerLocation(params).pipe(map((response) => response.data));
  }

  documentsList(params: { psp: number; customer: number; location: number }): Observable<any> {
    return this.pspCustomerService.getPspCustomerLocationDocumentList(params).pipe(map((response) => response.data));
  }

  downloadDocument(params: {
    document: string;
    customer: number;
    location: number;
    psp: number;
  }): Observable<{ body: Blob; fileName: string }> {
    return this.pspCustomerService.getPspCustomerLocationDocumentFileDownload$Response(params).pipe(
      map((response) => {
        const contentDispositionValue = response.headers.get('Content-Disposition');
        const fileName = BaseSharedHelperService.fileNameFromHeaderValue(contentDispositionValue, '=');
        return {
          body: response.body,
          fileName,
        };
      })
    );
  }

  departmentsList(params: {
    psp: number;
    customer: number;
    location: number;
  }): Observable<DepartmentListItemResponse[]> {
    return this.pspCustomerService.getPspCustomerLocationDepartmentsList(params).pipe(map((response) => response.data));
  }

  priceList(params: {
    psp: number;
    customer: number;
    location: number;
    tag?: BaseApplicationTagType;
  }): Observable<QualificationPricingListItemResponse[]> {
    return this.pspCustomerService
      .getAppApiWebV1PspCustomerPriceQualificationListIndex(params)
      .pipe(map((response) => response.data.qualifications));
  }

  updatePriceList(params: {
    psp: number;
    customer: number;
    location: number;
    body: PriceListRequest;
  }): Observable<EmptySuccessfulResponse> {
    return this.pspCustomerService.postAppApiWebV1PspCustomerPriceUpdateIndex(params);
  }

  surchargesList(params: {
    psp: number;
    customer: number;
    location: number;
  }): Observable<PspCustomerSurchargeResponse> {
    return this.pspCustomerService.getPspCustomerSurcharges(params).pipe(map((response) => response.data));
  }
}
