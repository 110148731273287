import { Pipe, PipeTransform } from '@angular/core';
import { OfferPositionResponse } from '../../../../api';
import { BaseInquiryColors } from '../_enums/base-inquiry.enum';
import { BaseInquiryService } from '../_services/base-inquiry.service';
import { BaseInquiryOfferBubbleColorPipe } from './base-inquiry-offer-bubble-color.pipe';

@Pipe({
  name: 'baseInquiryOfferBubbleDisabled',
})
export class BaseInquiryOfferBubbleDisabledPipe implements PipeTransform {
  constructor(
    private inquiryService: BaseInquiryService,
    private inquiryOfferBubbleColorPipe: BaseInquiryOfferBubbleColorPipe
  ) {}

  transform(
    value: { date: string; shift: string },
    args: {
      offerPositions: OfferPositionResponse[] | undefined;
      deselectedPositions: { shift: string; date: string }[];
      accepted: boolean;
      userSelected: boolean;
    }
  ): boolean {
    const color = this.inquiryOfferBubbleColorPipe.transform(value, {
      accepted: args.accepted,
      deselectedPositions: args.deselectedPositions,
      offerPositions: args.offerPositions,
      userSelected: args.userSelected,
    });

    return (
      !(args.offerPositions || []).some(
        (item) =>
          item.date === value.date &&
          this.inquiryService.inquiryDateExists(
            value.date,
            // @ts-ignore
            args.offerPositions,
            value.shift,
            args.deselectedPositions
          ) &&
          color === BaseInquiryColors.Purple
      ) && !args.deselectedPositions?.find((item) => item.date === value.date && item.shift === value.shift)
    );
  }
}
