import { Injectable } from '@angular/core';
import { NgxPermissionsService, NgxRolesService } from 'ngx-permissions';
import { BASE_PERMISSIONS } from '../constants/base-permissions';
import { BASE_ROLES } from '../constants/base-roles';

@Injectable()
export class BaseRolesService {
  constructor(private ngxRolesService: NgxRolesService, private ngxPermissionsService: NgxPermissionsService) {}

  setRolesAndPermissions(roles: string[], permissions: string[]): void {
    roles.forEach((role) => {
      const isAdminRole = role === BASE_ROLES.PSP_ADMIN || role === BASE_ROLES.CUSTOMER_ADMIN;
      const permissionsToAdd = isAdminRole ? [] : permissions;
      return this.ngxRolesService.addRoleWithPermissions(role, permissionsToAdd);
    });
  }

  setPermissions(permissions: string[]): void {
    this.ngxPermissionsService.addPermission(permissions);
  }

  clearRolesAndPermissions(): void {
    this.ngxRolesService.flushRolesAndPermissions();
  }

  clearPermissions(): void {
    this.ngxPermissionsService.flushPermissions();
  }

  isPsp(): boolean {
    return this.hasRole(BASE_ROLES.PSP_ADMIN) || this.hasRole(BASE_ROLES.PSP_USER);
  }

  isCustomer(): boolean {
    return this.hasRole(BASE_ROLES.CUSTOMER_ADMIN) || this.hasRole(BASE_ROLES.CUSTOMER_USER);
  }

  /**
   * Check if given permission is set for a user.
   * For PSP or customer admin this is always true.
   * @param permission One required permission for the check.
   */
  hasFeaturePermission(permission: BASE_PERMISSIONS): boolean {
    if (this.hasAdminRole()) {
      return true;
    } else {
      return !!this.ngxPermissionsService.getPermission(permission);
    }
  }

  /**
   * Check if given permission is set for a user.
   * For customer admins this is always true.
   * @param permission
   */
  hasCustomerFeaturePermission(permission: BASE_PERMISSIONS): boolean {
    if (this.hasRole(BASE_ROLES.CUSTOMER_ADMIN)) {
      return true;
    } else {
      return !!this.ngxPermissionsService.getPermission(permission);
    }
  }

  /**
   * Check if given permission is set for a user.
   * For PSP admins this is always true.
   * @param permission
   */
  hasPspFeaturePermission(permission: BASE_PERMISSIONS): boolean {
    if (this.hasRole(BASE_ROLES.PSP_ADMIN)) {
      return true;
    } else {
      return !!this.ngxPermissionsService.getPermission(permission);
    }
  }

  /**
   * Check if a user has the given role.
   * @param role
   */
  hasRole(role: BASE_ROLES): boolean {
    return !!this.ngxRolesService.getRole(role);
  }

  /**
   * A user has an admin role independent on the used application.
   * In detail: A user is a PSP or a customer admin.
   * @private
   */
  hasAdminRole() {
    return this.hasRole(BASE_ROLES.CUSTOMER_ADMIN) || this.hasRole(BASE_ROLES.PSP_ADMIN);
  }
}
