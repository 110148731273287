<div class="document-viewer">
  <ng-container
    *ngIf="fileType === baseAvailableFileTypeEnum.pdf || fileType === baseAvailableFileTypeEnum.applicationPDF"
  >
    <base-pdf-viewer
      [src]="src"
      [fileName]="fileName"
      [customToolbar]="pdfViewerToolbar"
      [height]="height"
      [hideBorder]="hideBorder"
    ></base-pdf-viewer>
  </ng-container>

  <ng-container
    *ngIf="fileType !== baseAvailableFileTypeEnum.pdf && fileType !== baseAvailableFileTypeEnum.applicationPDF"
  >
    <ng-container *ngTemplateOutlet="pdfViewerToolbar"></ng-container>
    <base-image-viewer [src]="src"></base-image-viewer>
  </ng-container>
</div>

<ng-template #pdfViewerToolbar>
  <base-document-viewer-toolbar
    [title]="title || fileName"
    [modalRef]="modalRef"
    (downloadImage)="downloadImage.emit()"
  ></base-document-viewer-toolbar>
  <div
    *ngIf="isCustomBodyText"
    class="pb-5"
  >
    <p>{{ customBodyText }}</p>
  </div>
</ng-template>
