import { Component, ViewChild, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { takeUntil } from 'rxjs/operators';
import { BaseModalService } from '../../../shared/services/base-modal.service';
import { baseSharedSelectors, BaseCoreState } from '../../../_store';
import { NotificationResponse, NotificationsListResponse } from '../../../../api';

@Component({
  selector: 'base-notifications',
  templateUrl: './base-notifications.component.html',
})
export class BaseNotificationsComponent implements OnInit, OnDestroy {
  @ViewChild('notificationsPopup', { static: true }) notificationsPopup: ElementRef;

  private destroy$ = new Subject();
  isOpen = false;
  modalRef: NgbModalRef;
  unreadNotifications: Array<NotificationResponse> = [];
  totalUnreadNotifications: number = 0;

  constructor(private modalService: BaseModalService, private store: Store<BaseCoreState>) {}

  ngOnInit() {
    this.store
      .select(baseSharedSelectors.getUserNotifications)
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ data }: { data: NotificationsListResponse | undefined }) => {
        this.totalUnreadNotifications = data?.unRead || 0;

        this.unreadNotifications = data?.notifications
          ? data.notifications.filter((notification) => !notification.isRead)
          : [];
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  toggle(): void {
    this.isOpen = !this.isOpen;
  }

  close(): void {
    this.isOpen = false;
  }
}
