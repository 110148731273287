import { Pipe, PipeTransform } from '@angular/core';
import { OfferPositionResponse } from '../../../../api';
import { BaseRolesService } from '../../../core/services/base-roles.service';
import { BaseDateService } from '../../../shared/services/base-date.service';
import { BaseInquiryColors } from '../_enums/base-inquiry.enum';

@Pipe({
  name: 'baseInquiryOfferBubbleColor',
})
export class BaseInquiryOfferBubbleColorPipe implements PipeTransform {
  constructor(private roleService: BaseRolesService) {}

  transform(
    value: { date: string; shift: string },
    args: {
      offerPositions: OfferPositionResponse[] | undefined;
      deselectedPositions: { shift: string; date: string }[];
      userSelected: boolean;
      accepted: boolean;
    }
  ): BaseInquiryColors {
    const positionExists = args.offerPositions?.find(
      (inquiryDate) =>
        BaseDateService.format(new Date(inquiryDate.date)) === BaseDateService.format(new Date(value.date)) &&
        // @ts-ignore
        inquiryDate[`shift${value.shift[0].toUpperCase()}${value.shift[1]}`] &&
        // @ts-ignore
        (!args.accepted || (args.accepted && inquiryDate.selectedShift === value.shift.toLowerCase()))
    );

    const isDeselected = args.deselectedPositions.find(
      (item) => item.date === value.date && item.shift === value.shift
    );

    if (isDeselected) {
      return BaseInquiryColors.Default;
    }

    return positionExists && args.userSelected
      ? BaseInquiryColors.Purple
      : positionExists &&
        !args.userSelected &&
        this.roleService.isCustomer() &&
        positionExists.selectedShift &&
        args.accepted
      ? BaseInquiryColors.Green
      : positionExists && !args.userSelected && this.roleService.isCustomer() && !args.accepted
      ? BaseInquiryColors.Yellow
      : BaseInquiryColors.Default;
  }
}
