import produce from 'immer';
import { baseRelatedPspBranchActions } from '../actions';
import {
  CustomerBranchListItemResponse,
  DocumentListItemResponse,
  MetaPagination,
  PspBranchResponse,
  PspResponse,
  SurchargeListItemResponse,
} from '../../../api';

export interface RelatedPspBranchesState {
  list: {
    data: CustomerBranchListItemResponse[] | undefined;
    pagination: MetaPagination | undefined;
    ok: boolean | undefined;
  };
  details: {
    data: PspBranchResponse | undefined;
    ok: boolean | undefined;
  };
  documents: {
    data: DocumentListItemResponse[] | undefined;
    ok: boolean | undefined;
  };
  downloadDocument: {
    data: Blob | undefined;
  };
  surcharges: {
    data: SurchargeListItemResponse[] | undefined;
    ok: boolean | undefined;
  };
  branchData: {
    data: PspResponse | undefined;
    ok: boolean | undefined;
  };
}

export const RELATED_PSP_BRANCHES_INITIAL_STATE: RelatedPspBranchesState = {
  list: {
    data: undefined,
    pagination: undefined,
    ok: undefined,
  },
  details: {
    data: undefined,
    ok: undefined,
  },
  documents: {
    data: undefined,
    ok: undefined,
  },
  downloadDocument: {
    data: undefined,
  },
  surcharges: {
    data: undefined,
    ok: undefined,
  },
  branchData: {
    data: undefined,
    ok: undefined,
  },
};

export function BaseRelatedPspBranchesReducer(
  state: RelatedPspBranchesState = RELATED_PSP_BRANCHES_INITIAL_STATE,
  action: any
): any {
  return produce(state, (draftState) => {
    switch (action.type) {
      // Related PSP branch list
      case baseRelatedPspBranchActions.loadRelatedPspBranchList.type:
        draftState.list.ok = undefined;
        return;

      case baseRelatedPspBranchActions.setRelatedPspBranchList.type:
        draftState.list.data = action.payload.data;
        draftState.list.pagination = action.payload.meta;
        return;

      case baseRelatedPspBranchActions.successRelatedPspBranchList.type:
        draftState.list.ok = true;
        return;

      case baseRelatedPspBranchActions.errorRelatedPspBranchList.type:
        draftState.list.ok = false;
        return;

      // Related PSP branch details
      case baseRelatedPspBranchActions.loadRelatedPspBranchDetails.type:
        draftState.details.ok = undefined;
        return;

      case baseRelatedPspBranchActions.setRelatedPspBranchDetails.type:
        draftState.details.data = action.payload;
        return;

      case baseRelatedPspBranchActions.successRelatedPspBranchDetails.type:
        draftState.details.ok = true;
        return;

      case baseRelatedPspBranchActions.errorRelatedPspBranchDetails.type:
        draftState.details.ok = false;
        return;

      // Related PSP branch documents
      case baseRelatedPspBranchActions.loadRelatedPspBranchDocuments.type:
        draftState.documents.ok = undefined;
        return;

      case baseRelatedPspBranchActions.setRelatedPspBranchDocuments.type:
        draftState.documents.data = action.payload;
        return;

      case baseRelatedPspBranchActions.successRelatedPspBranchDocuments.type:
        draftState.documents.ok = true;
        return;

      case baseRelatedPspBranchActions.errorRelatedPspBranchDocuments.type:
        draftState.documents.ok = false;
        return;

      case baseRelatedPspBranchActions.setDownloadRelatedPspBranchDocument.type:
        draftState.downloadDocument.data = action.payload.document;
        return;

      case baseRelatedPspBranchActions.clearDownloadRelatedPspBranchDocument.type:
        draftState.downloadDocument.data = undefined;
        return;

      // Surcharges
      case baseRelatedPspBranchActions.loadSurcharges.type:
        draftState.surcharges.ok = undefined;
        return;

      case baseRelatedPspBranchActions.setSurcharges.type:
        draftState.surcharges.data = action.payload;
        return;

      case baseRelatedPspBranchActions.successSurcharges.type:
        draftState.surcharges.ok = true;
        return;

      case baseRelatedPspBranchActions.errorSurcharges.type:
        draftState.surcharges.ok = false;
        return;

      // Related PSP branch data
      case baseRelatedPspBranchActions.loadRelatedPspBranchData.type:
        draftState.branchData.ok = undefined;
        return;

      case baseRelatedPspBranchActions.setRelatedPspBranchData.type:
        draftState.branchData.data = action.payload;
        return;

      case baseRelatedPspBranchActions.successRelatedPspBranchData.type:
        draftState.branchData.ok = true;
        return;

      case baseRelatedPspBranchActions.errorRelatedPspBranchData.type:
        draftState.branchData.ok = false;
        return;
    }
  });
}
