import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, NonNullableFormBuilder, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { noop } from 'rxjs';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { baseEmailValidator } from '../../../../../shared/validators/base-email.validator';
import { basePhoneNumberValidator } from '../../../../../shared/validators/base-phone-number.validator';
import { GroupListItemResponse, UserItemResponse } from '../../../../../../api';
import { BaseAvatarSize } from '../../../../../shared/components/avatar/base-avatar.component';
import { BaseButtonSize } from '../../../../../shared/components/button/base-button.component';
import { BaseModalService } from '../../../../../shared/services/base-modal.service';
import { baseAdministrationActions, BaseCoreState, baseUserActions } from '../../../../../_store';
import { GenderType } from '../../../_models/base-user-profile.model';
import { EditUserFormInterface } from '../../../_interfaces/base-user-management.interface';

@Component({
  selector: 'base-user-list-item',
  templateUrl: './base-user-list-item.component.html',
  styleUrls: ['./base-user-list-item.component.scss'],
})
export class BaseUserListItemComponent implements OnInit {
  @ViewChild('editUserModalTemplate', { static: true }) editUserModalTemplate: ElementRef;
  @ViewChild('deactivationConfirmationTemplate', { static: true }) deactivationConfirmationTemplate: ElementRef;
  @Input() user: UserItemResponse;
  @Input() loggedInUserId: number;
  @Input() groups: GroupListItemResponse[];

  modalRef: NgbModalRef;
  editUserForm: FormGroup<EditUserFormInterface>;
  isCurrentlyLoggedInUser: boolean;
  fullName: string;
  isEditUserFormSubmitted = false;
  isActive = true;
  avatarSizes = BaseAvatarSize;
  buttonSizes = BaseButtonSize;

  constructor(
    private store: Store<BaseCoreState>,
    private router: Router,
    private modalService: BaseModalService,
    private fb: NonNullableFormBuilder
  ) {}

  ngOnInit(): void {
    this.initEditUserForm();
    this.isCurrentlyLoggedInUser = this.loggedInUserId === this.user.party;
    this.isActive = this.user.active;
    this.fullName = `${this.user.firstName} ${this.user.lastName}`;
  }

  onItemClick(): void {
    if (this.isCurrentlyLoggedInUser) {
      this.router.navigate(['administration', 'user-settings']);
    }
  }

  setUserActivationStatus(): void {
    if (this.isActive) {
      this.store.dispatch(baseUserActions.activateUser({ payload: this.user?.party as number, loadCommonList: true }));
    } else {
      this.store.dispatch(
        baseUserActions.deactivateUser({
          payload: this.user?.party as number,
          loadCommonList: true,
        })
      );
    }
  }

  openUserEditModal(event: Event): void {
    event.stopPropagation();
    this.isEditUserFormSubmitted = false;
    this.initEditUserForm();
    this.store.dispatch(baseAdministrationActions.loadUserProfileData({ payload: { user: this.user.party } }));
    this.modalRef = this.modalService.open(
      {
        headText: 'user.edit',
        bodyTemplate: this.editUserModalTemplate,
        okButtonDisplayed: false,
        cancelButtonDisplayed: false,
        hideFooter: true,
      },
      {
        backdrop: 'static',
        size: 'lg',
      }
    );
    this.modalRef.result.then(noop, noop);
  }

  editUser(): void {
    this.isEditUserFormSubmitted = true;
    if (this.editUserForm.valid) {
      const editFormValue = this.editUserForm.getRawValue();
      const body: any = {
        ...this.editUserForm.getRawValue(),
        group: editFormValue.group?.id || 0,
      };

      if (editFormValue.isAdministrator !== null) {
        body.isAdministrator = editFormValue.isAdministrator;
      }

      this.store.dispatch(
        baseAdministrationActions.updateUserProfileData({
          payload: { user: this.user.party, loadList: true, body },
        })
      );
      this.modalRef.close();
    }
  }

  cancelUserEdit(): void {
    this.modalRef.close();
  }

  openDeleteInvitationModal(event: Event): void {
    event.stopPropagation();
    this.modalRef = this.modalService.open(
      {
        headText: 'user.deleteInvitation',
        bodyText: 'user.deleteInvitationConfirmation',
        okText: 'action.okWithdraw',
        cancelText: 'action.cancel',
      },
      {
        backdrop: 'static',
      }
    );

    this.modalRef.result.then((_) => {
      this.store.dispatch(baseUserActions.deleteInvitedUser({ payload: this.user?.party as number, fullList: true }));
    }, noop);
  }

  resendInvitation(event: Event, user: UserItemResponse): void {
    event.stopPropagation();
    this.store.dispatch(
      baseUserActions.resendUserInvitation({
        payload: { userId: user.party, userName: `${user.firstName} ${user.lastName}` },
      })
    );
  }

  changeIsActive(setActive: boolean): void {
    if (setActive) {
      this.setUserActivationStatus();
    } else {
      this.modalRef = this.modalService.open(
        {
          headText: 'user.deactivateUser.headText',
          okText: 'action.okProceed',
          bodyTemplate: this.deactivationConfirmationTemplate,
        },
        {
          size: 'lg',
          backdrop: 'static',
        }
      );

      this.modalRef.result.then(
        () => {
          this.setUserActivationStatus();
        },
        () => {
          this.isActive = true;
        }
      );
    }
  }

  private initEditUserForm(): void {
    this.editUserForm = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      position: [''],
      email: ['', [Validators.required, baseEmailValidator()]],
      phone: ['', basePhoneNumberValidator()],
      mobilePhone: ['', basePhoneNumberValidator()],
      gender: [null as null | { label: string; value: GenderType }],
      group: [null as GroupListItemResponse | null, [Validators.required]],
      isAdministrator: [null as boolean | null, [Validators.required]],
    });
  }
}
