export enum BaseInquiryBubbleValues {
  Default = '',
  Available = 'V',
  Booked = 'G',
  Offered = 'A',
  Sick = 'K',
  Vacation = 'U',
  Other = 'S',
  AssignedOutside = 'AO',
  Unavailable = 'FB',
  Rejected = 'A',
  Unexcused = 'U',
  TimeOff = 'TO',
  FreeShift = 'FS',
}

export enum InquiryBubbleValueTranslationKeys {
  V = 'shiftStatus.value.available',
  G = 'shiftStatus.value.booked',
  A = 'shiftStatus.value.offered',
  K = 'shiftStatus.value.sick',
  U = 'shiftStatus.value.holidays',
  S = 'shiftStatus.value.miscellaneous',
  FB = 'shiftStatus.value.notReady',
}
