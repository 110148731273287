/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CreatedResponse } from '../models/created-response';
import { CreatedResponseWithBody } from '../models/created-response-with-body';
import { CustomerBusinessInviteRequest } from '../models/customer-business-invite-request';
import { CustomerDashboardResponse } from '../models/customer-dashboard-response';
import { CustomerMainInfoUpdateRequest } from '../models/customer-main-info-update-request';
import { CustomerMedicalInviteRequest } from '../models/customer-medical-invite-request';
import { CustomerProfilePersonalIdRequest } from '../models/customer-profile-personal-id-request';
import { CustomerProfileRatingCreateRequest } from '../models/customer-profile-rating-create-request';
import { CustomerProfileRatingListResponse } from '../models/customer-profile-rating-list-response';
import { CustomerProfileRatingResponse } from '../models/customer-profile-rating-response';
import { CustomerProfileRatingUpdateRequest } from '../models/customer-profile-rating-update-request';
import { CustomerRegistrationRequest } from '../models/customer-registration-request';
import { CustomerResponse } from '../models/customer-response';
import { CustomerVerificationRequest } from '../models/customer-verification-request';
import { DocumentCreationResponse } from '../models/document-creation-response';
import { DocumentListItemResponse } from '../models/document-list-item-response';
import { DocumentRequest } from '../models/document-request';
import { DocumentResponse } from '../models/document-response';
import { EmptySuccessfulResponse } from '../models/empty-successful-response';
import { GeneralApiResponse } from '../models/general-api-response';
import { GroupCreateRequest } from '../models/group-create-request';
import { GroupItemResponse } from '../models/group-item-response';
import { GroupListItemResponse } from '../models/group-list-item-response';
import { GroupUpdateRequest } from '../models/group-update-request';
import { ListApiResponse } from '../models/list-api-response';
import { LogoUpdateRequest } from '../models/logo-update-request';
import { UpdatedResponse } from '../models/updated-response';
import { UserItemResponse } from '../models/user-item-response';
import { UserPasswordUpdateRequest } from '../models/user-password-update-request';
import { UserResponse } from '../models/user-response';
import { UserUpdateRequest } from '../models/user-update-request';

@Injectable({
  providedIn: 'root',
})
export class CustomerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation postAppApiWebV1CustomerBusinessCreateIndex
   */
  static readonly PostAppApiWebV1CustomerBusinessCreateIndexPath = '/api/web/v1/customers/business';

  /**
   * Register business customer.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postAppApiWebV1CustomerBusinessCreateIndex()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postAppApiWebV1CustomerBusinessCreateIndex$Response(params: {
    context?: HttpContext
    body: CustomerRegistrationRequest
  }
): Observable<StrictHttpResponse<CreatedResponseWithBody>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerService.PostAppApiWebV1CustomerBusinessCreateIndexPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CreatedResponseWithBody>;
      })
    );
  }

  /**
   * Register business customer.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postAppApiWebV1CustomerBusinessCreateIndex$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postAppApiWebV1CustomerBusinessCreateIndex(params: {
    context?: HttpContext
    body: CustomerRegistrationRequest
  }
): Observable<CreatedResponseWithBody> {

    return this.postAppApiWebV1CustomerBusinessCreateIndex$Response(params).pipe(
      map((r: StrictHttpResponse<CreatedResponseWithBody>) => r.body as CreatedResponseWithBody)
    );
  }

  /**
   * Path part for operation postCustomersBusinessUsersInviteCreate
   */
  static readonly PostCustomersBusinessUsersInviteCreatePath = '/api/web/v1/customers/{customer}/business/users/invites';

  /**
   * Invite Business user to customer organization.
   *
   * **Accessible only for users with roles 'ROLE_CUSTOMER_ADMIN', 'ROLE_BUSINESS_USER'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postCustomersBusinessUsersInviteCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postCustomersBusinessUsersInviteCreate$Response(params: {

    /**
     * Customer Id.
     */
    customer: number;
    context?: HttpContext
    body: CustomerBusinessInviteRequest
  }
): Observable<StrictHttpResponse<CreatedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerService.PostCustomersBusinessUsersInviteCreatePath, 'post');
    if (params) {
      rb.path('customer', params.customer, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CreatedResponse>;
      })
    );
  }

  /**
   * Invite Business user to customer organization.
   *
   * **Accessible only for users with roles 'ROLE_CUSTOMER_ADMIN', 'ROLE_BUSINESS_USER'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postCustomersBusinessUsersInviteCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postCustomersBusinessUsersInviteCreate(params: {

    /**
     * Customer Id.
     */
    customer: number;
    context?: HttpContext
    body: CustomerBusinessInviteRequest
  }
): Observable<CreatedResponse> {

    return this.postCustomersBusinessUsersInviteCreate$Response(params).pipe(
      map((r: StrictHttpResponse<CreatedResponse>) => r.body as CreatedResponse)
    );
  }

  /**
   * Path part for operation getCustomerContractTemplateDownload
   */
  static readonly GetCustomerContractTemplateDownloadPath = '/api/web/v1/customers/{customer}/contract-template/file';

  /**
   * Download customer template file.
   *
   * **Accessible only for users with role 'ROLE_CUSTOMER_ADMIN'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerContractTemplateDownload()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerContractTemplateDownload$Response(params: {
    customer: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerService.GetCustomerContractTemplateDownloadPath, 'get');
    if (params) {
      rb.path('customer', params.customer, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'multipart/form-data',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Download customer template file.
   *
   * **Accessible only for users with role 'ROLE_CUSTOMER_ADMIN'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCustomerContractTemplateDownload$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerContractTemplateDownload(params: {
    customer: number;
    context?: HttpContext
  }
): Observable<Blob> {

    return this.getCustomerContractTemplateDownload$Response(params).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Path part for operation getCustomerDashboardGet
   */
  static readonly GetCustomerDashboardGetPath = '/api/web/v1/customers/{customer}/dashboard';

  /**
   * Get Customer dashboard data.
   *
   * **Accessible only for users with permission 'FEATURE_VIEW_DASHBOARD'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerDashboardGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerDashboardGet$Response(params: {

    /**
     * Customer Id.
     */
    customer: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<GeneralApiResponse & {
'data'?: CustomerDashboardResponse;
}>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerService.GetCustomerDashboardGetPath, 'get');
    if (params) {
      rb.path('customer', params.customer, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GeneralApiResponse & {
        'data'?: CustomerDashboardResponse;
        }>;
      })
    );
  }

  /**
   * Get Customer dashboard data.
   *
   * **Accessible only for users with permission 'FEATURE_VIEW_DASHBOARD'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCustomerDashboardGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerDashboardGet(params: {

    /**
     * Customer Id.
     */
    customer: number;
    context?: HttpContext
  }
): Observable<GeneralApiResponse & {
'data'?: CustomerDashboardResponse;
}> {

    return this.getCustomerDashboardGet$Response(params).pipe(
      map((r: StrictHttpResponse<GeneralApiResponse & {
'data'?: CustomerDashboardResponse;
}>) => r.body as GeneralApiResponse & {
'data'?: CustomerDashboardResponse;
})
    );
  }

  /**
   * Path part for operation getCustomerGroupsList
   */
  static readonly GetCustomerGroupsListPath = '/api/web/v1/customers/{customer}/groups';

  /**
   * Get customer groups.
   *
   * **Accessible only for users with role 'ROLE_CUSTOMER_ADMIN'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerGroupsList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerGroupsList$Response(params: {

    /**
     * Customer Id.
     */
    customer: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ListApiResponse & {
'data'?: Array<GroupListItemResponse>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerService.GetCustomerGroupsListPath, 'get');
    if (params) {
      rb.path('customer', params.customer, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListApiResponse & {
        'data'?: Array<GroupListItemResponse>;
        }>;
      })
    );
  }

  /**
   * Get customer groups.
   *
   * **Accessible only for users with role 'ROLE_CUSTOMER_ADMIN'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCustomerGroupsList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerGroupsList(params: {

    /**
     * Customer Id.
     */
    customer: number;
    context?: HttpContext
  }
): Observable<ListApiResponse & {
'data'?: Array<GroupListItemResponse>;
}> {

    return this.getCustomerGroupsList$Response(params).pipe(
      map((r: StrictHttpResponse<ListApiResponse & {
'data'?: Array<GroupListItemResponse>;
}>) => r.body as ListApiResponse & {
'data'?: Array<GroupListItemResponse>;
})
    );
  }

  /**
   * Path part for operation postCustomerGroupCreate
   */
  static readonly PostCustomerGroupCreatePath = '/api/web/v1/customers/{customer}/groups';

  /**
   * Create new customer group.
   *
   * **Accessible only for users with role 'ROLE_CUSTOMER_ADMIN'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postCustomerGroupCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postCustomerGroupCreate$Response(params: {

    /**
     * Customer Id.
     */
    customer: number;
    context?: HttpContext
    body: GroupCreateRequest
  }
): Observable<StrictHttpResponse<CreatedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerService.PostCustomerGroupCreatePath, 'post');
    if (params) {
      rb.path('customer', params.customer, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CreatedResponse>;
      })
    );
  }

  /**
   * Create new customer group.
   *
   * **Accessible only for users with role 'ROLE_CUSTOMER_ADMIN'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postCustomerGroupCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postCustomerGroupCreate(params: {

    /**
     * Customer Id.
     */
    customer: number;
    context?: HttpContext
    body: GroupCreateRequest
  }
): Observable<CreatedResponse> {

    return this.postCustomerGroupCreate$Response(params).pipe(
      map((r: StrictHttpResponse<CreatedResponse>) => r.body as CreatedResponse)
    );
  }

  /**
   * Path part for operation getCustomerGroupsShow
   */
  static readonly GetCustomerGroupsShowPath = '/api/web/v1/customers/{customer}/groups/{group}';

  /**
   * Get customer group data.
   *
   * **Accessible only for users with role 'ROLE_CUSTOMER_ADMIN'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerGroupsShow()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerGroupsShow$Response(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Group Id.
     */
    group: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<GeneralApiResponse & {
'data'?: GroupItemResponse;
}>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerService.GetCustomerGroupsShowPath, 'get');
    if (params) {
      rb.path('customer', params.customer, {});
      rb.path('group', params.group, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GeneralApiResponse & {
        'data'?: GroupItemResponse;
        }>;
      })
    );
  }

  /**
   * Get customer group data.
   *
   * **Accessible only for users with role 'ROLE_CUSTOMER_ADMIN'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCustomerGroupsShow$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerGroupsShow(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Group Id.
     */
    group: number;
    context?: HttpContext
  }
): Observable<GeneralApiResponse & {
'data'?: GroupItemResponse;
}> {

    return this.getCustomerGroupsShow$Response(params).pipe(
      map((r: StrictHttpResponse<GeneralApiResponse & {
'data'?: GroupItemResponse;
}>) => r.body as GeneralApiResponse & {
'data'?: GroupItemResponse;
})
    );
  }

  /**
   * Path part for operation putCustomerGroupsUpdate
   */
  static readonly PutCustomerGroupsUpdatePath = '/api/web/v1/customers/{customer}/groups/{group}';

  /**
   * Update customer group data.
   *
   * **Accessible only for users with role 'ROLE_CUSTOMER_ADMIN'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putCustomerGroupsUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putCustomerGroupsUpdate$Response(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Group Id.
     */
    group: number;
    context?: HttpContext
    body: GroupUpdateRequest
  }
): Observable<StrictHttpResponse<EmptySuccessfulResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerService.PutCustomerGroupsUpdatePath, 'put');
    if (params) {
      rb.path('customer', params.customer, {});
      rb.path('group', params.group, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmptySuccessfulResponse>;
      })
    );
  }

  /**
   * Update customer group data.
   *
   * **Accessible only for users with role 'ROLE_CUSTOMER_ADMIN'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putCustomerGroupsUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putCustomerGroupsUpdate(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Group Id.
     */
    group: number;
    context?: HttpContext
    body: GroupUpdateRequest
  }
): Observable<EmptySuccessfulResponse> {

    return this.putCustomerGroupsUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<EmptySuccessfulResponse>) => r.body as EmptySuccessfulResponse)
    );
  }

  /**
   * Path part for operation deleteCustomerGroupsDelete
   */
  static readonly DeleteCustomerGroupsDeletePath = '/api/web/v1/customers/{customer}/groups/{group}';

  /**
   * delete customer group.
   *
   * **Accessible only for users with role 'ROLE_CUSTOMER_ADMIN'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteCustomerGroupsDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCustomerGroupsDelete$Response(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Group Id.
     */
    group: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EmptySuccessfulResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerService.DeleteCustomerGroupsDeletePath, 'delete');
    if (params) {
      rb.path('customer', params.customer, {});
      rb.path('group', params.group, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmptySuccessfulResponse>;
      })
    );
  }

  /**
   * delete customer group.
   *
   * **Accessible only for users with role 'ROLE_CUSTOMER_ADMIN'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteCustomerGroupsDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCustomerGroupsDelete(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Group Id.
     */
    group: number;
    context?: HttpContext
  }
): Observable<EmptySuccessfulResponse> {

    return this.deleteCustomerGroupsDelete$Response(params).pipe(
      map((r: StrictHttpResponse<EmptySuccessfulResponse>) => r.body as EmptySuccessfulResponse)
    );
  }

  /**
   * Path part for operation getCustomerLocationDocumentList
   */
  static readonly GetCustomerLocationDocumentListPath = '/api/web/v1/customers/{customer}/locations/{location}/documents';

  /**
   * Get list of documents.
   *
   * **Accessible only for users with roles: 'ROLE_CUSTOMER_ADMIN' and 'ROLE_CUSTOMER_LOCATION_ADMIN'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerLocationDocumentList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerLocationDocumentList$Response(params: {
    customer: number;
    location: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ListApiResponse & {
'data'?: Array<DocumentListItemResponse>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerService.GetCustomerLocationDocumentListPath, 'get');
    if (params) {
      rb.path('customer', params.customer, {});
      rb.path('location', params.location, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListApiResponse & {
        'data'?: Array<DocumentListItemResponse>;
        }>;
      })
    );
  }

  /**
   * Get list of documents.
   *
   * **Accessible only for users with roles: 'ROLE_CUSTOMER_ADMIN' and 'ROLE_CUSTOMER_LOCATION_ADMIN'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCustomerLocationDocumentList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerLocationDocumentList(params: {
    customer: number;
    location: number;
    context?: HttpContext
  }
): Observable<ListApiResponse & {
'data'?: Array<DocumentListItemResponse>;
}> {

    return this.getCustomerLocationDocumentList$Response(params).pipe(
      map((r: StrictHttpResponse<ListApiResponse & {
'data'?: Array<DocumentListItemResponse>;
}>) => r.body as ListApiResponse & {
'data'?: Array<DocumentListItemResponse>;
})
    );
  }

  /**
   * Path part for operation postCustomerLocationDocumentCreate
   */
  static readonly PostCustomerLocationDocumentCreatePath = '/api/web/v1/customers/{customer}/locations/{location}/documents';

  /**
   * Upload a file and create a document.
   *
   * **Accessible only for users with roles: 'ROLE_CUSTOMER_ADMIN' and 'ROLE_CUSTOMER_LOCATION_ADMIN'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postCustomerLocationDocumentCreate()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  postCustomerLocationDocumentCreate$Response(params: {
    customer: number;
    location: number;
    context?: HttpContext

    /**
     * Upload a document. There should be a variable with a name "file" which contains file
     */
    body: {
'file'?: Blob;
}
  }
): Observable<StrictHttpResponse<{
'message': string;
'data': DocumentCreationResponse;
}>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerService.PostCustomerLocationDocumentCreatePath, 'post');
    if (params) {
      rb.path('customer', params.customer, {});
      rb.path('location', params.location, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'message': string;
        'data': DocumentCreationResponse;
        }>;
      })
    );
  }

  /**
   * Upload a file and create a document.
   *
   * **Accessible only for users with roles: 'ROLE_CUSTOMER_ADMIN' and 'ROLE_CUSTOMER_LOCATION_ADMIN'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postCustomerLocationDocumentCreate$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  postCustomerLocationDocumentCreate(params: {
    customer: number;
    location: number;
    context?: HttpContext

    /**
     * Upload a document. There should be a variable with a name "file" which contains file
     */
    body: {
'file'?: Blob;
}
  }
): Observable<{
'message': string;
'data': DocumentCreationResponse;
}> {

    return this.postCustomerLocationDocumentCreate$Response(params).pipe(
      map((r: StrictHttpResponse<{
'message': string;
'data': DocumentCreationResponse;
}>) => r.body as {
'message': string;
'data': DocumentCreationResponse;
})
    );
  }

  /**
   * Path part for operation getCustomerLocationDocumentShow
   */
  static readonly GetCustomerLocationDocumentShowPath = '/api/web/v1/customers/{customer}/locations/{location}/documents/{document}';

  /**
   * Get document info.
   *
   * **Accessible only for users with roles: 'ROLE_CUSTOMER_ADMIN' and 'ROLE_CUSTOMER_LOCATION_ADMIN'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerLocationDocumentShow()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerLocationDocumentShow$Response(params: {
    customer: number;
    location: number;
    document: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<GeneralApiResponse & {
'data'?: DocumentResponse;
}>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerService.GetCustomerLocationDocumentShowPath, 'get');
    if (params) {
      rb.path('customer', params.customer, {});
      rb.path('location', params.location, {});
      rb.path('document', params.document, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GeneralApiResponse & {
        'data'?: DocumentResponse;
        }>;
      })
    );
  }

  /**
   * Get document info.
   *
   * **Accessible only for users with roles: 'ROLE_CUSTOMER_ADMIN' and 'ROLE_CUSTOMER_LOCATION_ADMIN'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCustomerLocationDocumentShow$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerLocationDocumentShow(params: {
    customer: number;
    location: number;
    document: string;
    context?: HttpContext
  }
): Observable<GeneralApiResponse & {
'data'?: DocumentResponse;
}> {

    return this.getCustomerLocationDocumentShow$Response(params).pipe(
      map((r: StrictHttpResponse<GeneralApiResponse & {
'data'?: DocumentResponse;
}>) => r.body as GeneralApiResponse & {
'data'?: DocumentResponse;
})
    );
  }

  /**
   * Path part for operation putCustomerLocationDocumentUpdate
   */
  static readonly PutCustomerLocationDocumentUpdatePath = '/api/web/v1/customers/{customer}/locations/{location}/documents/{document}';

  /**
   * Update data in a document.
   *
   * **Accessible only for users with roles: 'ROLE_CUSTOMER_ADMIN' and 'ROLE_CUSTOMER_LOCATION_ADMIN'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putCustomerLocationDocumentUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putCustomerLocationDocumentUpdate$Response(params: {
    customer: number;
    location: number;
    document: string;
    context?: HttpContext
    body: DocumentRequest
  }
): Observable<StrictHttpResponse<EmptySuccessfulResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerService.PutCustomerLocationDocumentUpdatePath, 'put');
    if (params) {
      rb.path('customer', params.customer, {});
      rb.path('location', params.location, {});
      rb.path('document', params.document, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmptySuccessfulResponse>;
      })
    );
  }

  /**
   * Update data in a document.
   *
   * **Accessible only for users with roles: 'ROLE_CUSTOMER_ADMIN' and 'ROLE_CUSTOMER_LOCATION_ADMIN'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putCustomerLocationDocumentUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putCustomerLocationDocumentUpdate(params: {
    customer: number;
    location: number;
    document: string;
    context?: HttpContext
    body: DocumentRequest
  }
): Observable<EmptySuccessfulResponse> {

    return this.putCustomerLocationDocumentUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<EmptySuccessfulResponse>) => r.body as EmptySuccessfulResponse)
    );
  }

  /**
   * Path part for operation deleteCustomerLocationDocumentDelete
   */
  static readonly DeleteCustomerLocationDocumentDeletePath = '/api/web/v1/customers/{customer}/locations/{location}/documents/{document}';

  /**
   * Delete a document.
   *
   * **Accessible only for users with roles: 'ROLE_CUSTOMER_ADMIN' and 'ROLE_CUSTOMER_LOCATION_ADMIN'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteCustomerLocationDocumentDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCustomerLocationDocumentDelete$Response(params: {
    customer: number;
    location: number;
    document: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EmptySuccessfulResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerService.DeleteCustomerLocationDocumentDeletePath, 'delete');
    if (params) {
      rb.path('customer', params.customer, {});
      rb.path('location', params.location, {});
      rb.path('document', params.document, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmptySuccessfulResponse>;
      })
    );
  }

  /**
   * Delete a document.
   *
   * **Accessible only for users with roles: 'ROLE_CUSTOMER_ADMIN' and 'ROLE_CUSTOMER_LOCATION_ADMIN'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteCustomerLocationDocumentDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCustomerLocationDocumentDelete(params: {
    customer: number;
    location: number;
    document: string;
    context?: HttpContext
  }
): Observable<EmptySuccessfulResponse> {

    return this.deleteCustomerLocationDocumentDelete$Response(params).pipe(
      map((r: StrictHttpResponse<EmptySuccessfulResponse>) => r.body as EmptySuccessfulResponse)
    );
  }

  /**
   * Path part for operation getCustomerLocationDocumentFileDownload
   */
  static readonly GetCustomerLocationDocumentFileDownloadPath = '/api/web/v1/customers/{customer}/locations/{location}/documents/{document}/file';

  /**
   * Download a file.
   *
   * **Accessible only for users with roles: 'ROLE_CUSTOMER_ADMIN' and 'ROLE_CUSTOMER_LOCATION_ADMIN'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerLocationDocumentFileDownload()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerLocationDocumentFileDownload$Response(params: {
    customer: number;
    location: number;
    document: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerService.GetCustomerLocationDocumentFileDownloadPath, 'get');
    if (params) {
      rb.path('customer', params.customer, {});
      rb.path('location', params.location, {});
      rb.path('document', params.document, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'multipart/form-data',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Download a file.
   *
   * **Accessible only for users with roles: 'ROLE_CUSTOMER_ADMIN' and 'ROLE_CUSTOMER_LOCATION_ADMIN'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCustomerLocationDocumentFileDownload$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerLocationDocumentFileDownload(params: {
    customer: number;
    location: number;
    document: string;
    context?: HttpContext
  }
): Observable<Blob> {

    return this.getCustomerLocationDocumentFileDownload$Response(params).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Path part for operation postCustomerLocationDocumentFileUpdate
   */
  static readonly PostCustomerLocationDocumentFileUpdatePath = '/api/web/v1/customers/{customer}/locations/{location}/documents/{document}/file';

  /**
   * Change a file for a document.
   *
   * **Accessible only for users with roles: 'ROLE_CUSTOMER_ADMIN' and 'ROLE_CUSTOMER_LOCATION_ADMIN'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postCustomerLocationDocumentFileUpdate()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  postCustomerLocationDocumentFileUpdate$Response(params: {
    customer: number;
    location: number;
    document: string;
    context?: HttpContext

    /**
     * Upload a document. There should be a variable with a name "file" which contains file
     */
    body: {
'file'?: Blob;
}
  }
): Observable<StrictHttpResponse<EmptySuccessfulResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerService.PostCustomerLocationDocumentFileUpdatePath, 'post');
    if (params) {
      rb.path('customer', params.customer, {});
      rb.path('location', params.location, {});
      rb.path('document', params.document, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmptySuccessfulResponse>;
      })
    );
  }

  /**
   * Change a file for a document.
   *
   * **Accessible only for users with roles: 'ROLE_CUSTOMER_ADMIN' and 'ROLE_CUSTOMER_LOCATION_ADMIN'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postCustomerLocationDocumentFileUpdate$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  postCustomerLocationDocumentFileUpdate(params: {
    customer: number;
    location: number;
    document: string;
    context?: HttpContext

    /**
     * Upload a document. There should be a variable with a name "file" which contains file
     */
    body: {
'file'?: Blob;
}
  }
): Observable<EmptySuccessfulResponse> {

    return this.postCustomerLocationDocumentFileUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<EmptySuccessfulResponse>) => r.body as EmptySuccessfulResponse)
    );
  }

  /**
   * Path part for operation putCustomerLogoUpdate
   */
  static readonly PutCustomerLogoUpdatePath = '/api/web/v1/customers/{customer}/logo';

  /**
   * Update Customer Logo.
   *
   * **Accessible only for users with role 'ROLE_CUSTOMER_ADMIN'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putCustomerLogoUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putCustomerLogoUpdate$Response(params: {
    customer: number;
    context?: HttpContext
    body: LogoUpdateRequest
  }
): Observable<StrictHttpResponse<EmptySuccessfulResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerService.PutCustomerLogoUpdatePath, 'put');
    if (params) {
      rb.path('customer', params.customer, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmptySuccessfulResponse>;
      })
    );
  }

  /**
   * Update Customer Logo.
   *
   * **Accessible only for users with role 'ROLE_CUSTOMER_ADMIN'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putCustomerLogoUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putCustomerLogoUpdate(params: {
    customer: number;
    context?: HttpContext
    body: LogoUpdateRequest
  }
): Observable<EmptySuccessfulResponse> {

    return this.putCustomerLogoUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<EmptySuccessfulResponse>) => r.body as EmptySuccessfulResponse)
    );
  }

  /**
   * Path part for operation postAppApiWebV1CustomerMedicalCreateIndex
   */
  static readonly PostAppApiWebV1CustomerMedicalCreateIndexPath = '/api/web/v1/customers/medical';

  /**
   * Register medical customer.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postAppApiWebV1CustomerMedicalCreateIndex()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postAppApiWebV1CustomerMedicalCreateIndex$Response(params: {
    context?: HttpContext
    body: CustomerRegistrationRequest
  }
): Observable<StrictHttpResponse<CreatedResponseWithBody>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerService.PostAppApiWebV1CustomerMedicalCreateIndexPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CreatedResponseWithBody>;
      })
    );
  }

  /**
   * Register medical customer.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postAppApiWebV1CustomerMedicalCreateIndex$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postAppApiWebV1CustomerMedicalCreateIndex(params: {
    context?: HttpContext
    body: CustomerRegistrationRequest
  }
): Observable<CreatedResponseWithBody> {

    return this.postAppApiWebV1CustomerMedicalCreateIndex$Response(params).pipe(
      map((r: StrictHttpResponse<CreatedResponseWithBody>) => r.body as CreatedResponseWithBody)
    );
  }

  /**
   * Path part for operation postCustomersMedicalUsersInviteCreate
   */
  static readonly PostCustomersMedicalUsersInviteCreatePath = '/api/web/v1/customers/{customer}/medical/users/invites';

  /**
   * Invite Medical Users to customer organization.
   *
   * **Accessible only for users with roles 'ROLE_CUSTOMER_ADMIN', 'ROLE_MEDICAL_USER'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postCustomersMedicalUsersInviteCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postCustomersMedicalUsersInviteCreate$Response(params: {

    /**
     * Customer Id.
     */
    customer: number;
    context?: HttpContext
    body: CustomerMedicalInviteRequest
  }
): Observable<StrictHttpResponse<CreatedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerService.PostCustomersMedicalUsersInviteCreatePath, 'post');
    if (params) {
      rb.path('customer', params.customer, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CreatedResponse>;
      })
    );
  }

  /**
   * Invite Medical Users to customer organization.
   *
   * **Accessible only for users with roles 'ROLE_CUSTOMER_ADMIN', 'ROLE_MEDICAL_USER'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postCustomersMedicalUsersInviteCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postCustomersMedicalUsersInviteCreate(params: {

    /**
     * Customer Id.
     */
    customer: number;
    context?: HttpContext
    body: CustomerMedicalInviteRequest
  }
): Observable<CreatedResponse> {

    return this.postCustomersMedicalUsersInviteCreate$Response(params).pipe(
      map((r: StrictHttpResponse<CreatedResponse>) => r.body as CreatedResponse)
    );
  }

  /**
   * Path part for operation putCustomerProfileIdUpdate
   */
  static readonly PutCustomerProfileIdUpdatePath = '/api/web/v1/customers/{customer}/profiles/{profile}/internal-personal-id';

  /**
   * Update or Create Internal Personal Id.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_ASSIGNMENTS'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putCustomerProfileIdUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putCustomerProfileIdUpdate$Response(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Profile Id.
     */
    profile: number;
    context?: HttpContext
    body: CustomerProfilePersonalIdRequest
  }
): Observable<StrictHttpResponse<UpdatedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerService.PutCustomerProfileIdUpdatePath, 'put');
    if (params) {
      rb.path('customer', params.customer, {});
      rb.path('profile', params.profile, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UpdatedResponse>;
      })
    );
  }

  /**
   * Update or Create Internal Personal Id.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_ASSIGNMENTS'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putCustomerProfileIdUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putCustomerProfileIdUpdate(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Profile Id.
     */
    profile: number;
    context?: HttpContext
    body: CustomerProfilePersonalIdRequest
  }
): Observable<UpdatedResponse> {

    return this.putCustomerProfileIdUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<UpdatedResponse>) => r.body as UpdatedResponse)
    );
  }

  /**
   * Path part for operation getCustomerProfileRatingList
   */
  static readonly GetCustomerProfileRatingListPath = '/api/web/v1/customers/{customer}/profiles/{profile}/rating';

  /**
   * Get customer profile rating list.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_INQUIRY'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerProfileRatingList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerProfileRatingList$Response(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Profile Id.
     */
    profile: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<GeneralApiResponse & {
'data'?: CustomerProfileRatingListResponse;
}>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerService.GetCustomerProfileRatingListPath, 'get');
    if (params) {
      rb.path('customer', params.customer, {});
      rb.path('profile', params.profile, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GeneralApiResponse & {
        'data'?: CustomerProfileRatingListResponse;
        }>;
      })
    );
  }

  /**
   * Get customer profile rating list.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_INQUIRY'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCustomerProfileRatingList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerProfileRatingList(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Profile Id.
     */
    profile: number;
    context?: HttpContext
  }
): Observable<GeneralApiResponse & {
'data'?: CustomerProfileRatingListResponse;
}> {

    return this.getCustomerProfileRatingList$Response(params).pipe(
      map((r: StrictHttpResponse<GeneralApiResponse & {
'data'?: CustomerProfileRatingListResponse;
}>) => r.body as GeneralApiResponse & {
'data'?: CustomerProfileRatingListResponse;
})
    );
  }

  /**
   * Path part for operation postCustomerProfileRatingCreate
   */
  static readonly PostCustomerProfileRatingCreatePath = '/api/web/v1/customers/{customer}/profiles/{profile}/rating';

  /**
   * Create Profile Rating for Customer.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_INQUIRY'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postCustomerProfileRatingCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postCustomerProfileRatingCreate$Response(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Profile Id.
     */
    profile: number;
    context?: HttpContext
    body: CustomerProfileRatingCreateRequest
  }
): Observable<StrictHttpResponse<CreatedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerService.PostCustomerProfileRatingCreatePath, 'post');
    if (params) {
      rb.path('customer', params.customer, {});
      rb.path('profile', params.profile, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CreatedResponse>;
      })
    );
  }

  /**
   * Create Profile Rating for Customer.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_INQUIRY'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postCustomerProfileRatingCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postCustomerProfileRatingCreate(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Profile Id.
     */
    profile: number;
    context?: HttpContext
    body: CustomerProfileRatingCreateRequest
  }
): Observable<CreatedResponse> {

    return this.postCustomerProfileRatingCreate$Response(params).pipe(
      map((r: StrictHttpResponse<CreatedResponse>) => r.body as CreatedResponse)
    );
  }

  /**
   * Path part for operation getCustomerProfileRatingShow
   */
  static readonly GetCustomerProfileRatingShowPath = '/api/web/v1/customers/{customer}/profiles/{profile}/rating/{rating}';

  /**
   * Show Profile Rating for Customer.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_INQUIRY'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerProfileRatingShow()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerProfileRatingShow$Response(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Profile Id.
     */
    profile: number;

    /**
     * Rating Id.
     */
    rating: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<GeneralApiResponse & {
'data'?: CustomerProfileRatingResponse;
}>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerService.GetCustomerProfileRatingShowPath, 'get');
    if (params) {
      rb.path('customer', params.customer, {});
      rb.path('profile', params.profile, {});
      rb.path('rating', params.rating, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GeneralApiResponse & {
        'data'?: CustomerProfileRatingResponse;
        }>;
      })
    );
  }

  /**
   * Show Profile Rating for Customer.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_INQUIRY'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCustomerProfileRatingShow$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerProfileRatingShow(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Profile Id.
     */
    profile: number;

    /**
     * Rating Id.
     */
    rating: number;
    context?: HttpContext
  }
): Observable<GeneralApiResponse & {
'data'?: CustomerProfileRatingResponse;
}> {

    return this.getCustomerProfileRatingShow$Response(params).pipe(
      map((r: StrictHttpResponse<GeneralApiResponse & {
'data'?: CustomerProfileRatingResponse;
}>) => r.body as GeneralApiResponse & {
'data'?: CustomerProfileRatingResponse;
})
    );
  }

  /**
   * Path part for operation putCustomerProfileRatingUpdate
   */
  static readonly PutCustomerProfileRatingUpdatePath = '/api/web/v1/customers/{customer}/profiles/{profile}/rating/{rating}';

  /**
   * Update Profile Rating for Customer.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_INQUIRY'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putCustomerProfileRatingUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putCustomerProfileRatingUpdate$Response(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Profile Id.
     */
    profile: number;

    /**
     * Rating Id.
     */
    rating: number;
    context?: HttpContext
    body: CustomerProfileRatingUpdateRequest
  }
): Observable<StrictHttpResponse<EmptySuccessfulResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerService.PutCustomerProfileRatingUpdatePath, 'put');
    if (params) {
      rb.path('customer', params.customer, {});
      rb.path('profile', params.profile, {});
      rb.path('rating', params.rating, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmptySuccessfulResponse>;
      })
    );
  }

  /**
   * Update Profile Rating for Customer.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_INQUIRY'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putCustomerProfileRatingUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putCustomerProfileRatingUpdate(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Profile Id.
     */
    profile: number;

    /**
     * Rating Id.
     */
    rating: number;
    context?: HttpContext
    body: CustomerProfileRatingUpdateRequest
  }
): Observable<EmptySuccessfulResponse> {

    return this.putCustomerProfileRatingUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<EmptySuccessfulResponse>) => r.body as EmptySuccessfulResponse)
    );
  }

  /**
   * Path part for operation getCustomerGet
   */
  static readonly GetCustomerGetPath = '/api/web/v1/customers/{customer}';

  /**
   * Get customer data.
   *
   * **Accessible only for users with roles 'ROLE_PSP_USER', 'ROLE_CUSTOMER_USER'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerGet$Response(params: {
    customer: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<GeneralApiResponse & {
'data'?: CustomerResponse;
}>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerService.GetCustomerGetPath, 'get');
    if (params) {
      rb.path('customer', params.customer, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GeneralApiResponse & {
        'data'?: CustomerResponse;
        }>;
      })
    );
  }

  /**
   * Get customer data.
   *
   * **Accessible only for users with roles 'ROLE_PSP_USER', 'ROLE_CUSTOMER_USER'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCustomerGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerGet(params: {
    customer: number;
    context?: HttpContext
  }
): Observable<GeneralApiResponse & {
'data'?: CustomerResponse;
}> {

    return this.getCustomerGet$Response(params).pipe(
      map((r: StrictHttpResponse<GeneralApiResponse & {
'data'?: CustomerResponse;
}>) => r.body as GeneralApiResponse & {
'data'?: CustomerResponse;
})
    );
  }

  /**
   * Path part for operation putCustomerUpdate
   */
  static readonly PutCustomerUpdatePath = '/api/web/v1/customers/{customer}';

  /**
   * Update customer data.
   *
   * **Accessible only for users with role 'ROLE_CUSTOMER_ADMIN'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putCustomerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putCustomerUpdate$Response(params: {
    customer: number;
    context?: HttpContext
    body: CustomerMainInfoUpdateRequest
  }
): Observable<StrictHttpResponse<EmptySuccessfulResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerService.PutCustomerUpdatePath, 'put');
    if (params) {
      rb.path('customer', params.customer, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmptySuccessfulResponse>;
      })
    );
  }

  /**
   * Update customer data.
   *
   * **Accessible only for users with role 'ROLE_CUSTOMER_ADMIN'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putCustomerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putCustomerUpdate(params: {
    customer: number;
    context?: HttpContext
    body: CustomerMainInfoUpdateRequest
  }
): Observable<EmptySuccessfulResponse> {

    return this.putCustomerUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<EmptySuccessfulResponse>) => r.body as EmptySuccessfulResponse)
    );
  }

  /**
   * Path part for operation postCustomersUsersActivate
   */
  static readonly PostCustomersUsersActivatePath = '/api/web/v1/customers/{customer}/users/{user}/activation';

  /**
   * Activate User in specified Customer organization.
   *
   * **Accessible only for users with role 'ROLE_CUSTOMER_ADMIN'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postCustomersUsersActivate()` instead.
   *
   * This method doesn't expect any request body.
   */
  postCustomersUsersActivate$Response(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * User Id.
     */
    user: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EmptySuccessfulResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerService.PostCustomersUsersActivatePath, 'post');
    if (params) {
      rb.path('customer', params.customer, {});
      rb.path('user', params.user, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmptySuccessfulResponse>;
      })
    );
  }

  /**
   * Activate User in specified Customer organization.
   *
   * **Accessible only for users with role 'ROLE_CUSTOMER_ADMIN'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postCustomersUsersActivate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  postCustomersUsersActivate(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * User Id.
     */
    user: number;
    context?: HttpContext
  }
): Observable<EmptySuccessfulResponse> {

    return this.postCustomersUsersActivate$Response(params).pipe(
      map((r: StrictHttpResponse<EmptySuccessfulResponse>) => r.body as EmptySuccessfulResponse)
    );
  }

  /**
   * Path part for operation deleteCustomersUsersDeactivate
   */
  static readonly DeleteCustomersUsersDeactivatePath = '/api/web/v1/customers/{customer}/users/{user}/activation';

  /**
   * Deactivate User in specified Customer organization.
   *
   * **Accessible only for users with role 'ROLE_CUSTOMER_ADMIN'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteCustomersUsersDeactivate()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCustomersUsersDeactivate$Response(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * User Id.
     */
    user: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EmptySuccessfulResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerService.DeleteCustomersUsersDeactivatePath, 'delete');
    if (params) {
      rb.path('customer', params.customer, {});
      rb.path('user', params.user, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmptySuccessfulResponse>;
      })
    );
  }

  /**
   * Deactivate User in specified Customer organization.
   *
   * **Accessible only for users with role 'ROLE_CUSTOMER_ADMIN'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteCustomersUsersDeactivate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCustomersUsersDeactivate(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * User Id.
     */
    user: number;
    context?: HttpContext
  }
): Observable<EmptySuccessfulResponse> {

    return this.deleteCustomersUsersDeactivate$Response(params).pipe(
      map((r: StrictHttpResponse<EmptySuccessfulResponse>) => r.body as EmptySuccessfulResponse)
    );
  }

  /**
   * Path part for operation postCustomersUserReinvitation
   */
  static readonly PostCustomersUserReinvitationPath = '/api/web/v1/customers/{customer}/users/{user}/reinvitation';

  /**
   * Invite user to Customer organization.
   *
   * **Accessible only for users with permission 'ROLE_CUSTOMER_ADMIN'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postCustomersUserReinvitation()` instead.
   *
   * This method doesn't expect any request body.
   */
  postCustomersUserReinvitation$Response(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * User Id.
     */
    user: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<CreatedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerService.PostCustomersUserReinvitationPath, 'post');
    if (params) {
      rb.path('customer', params.customer, {});
      rb.path('user', params.user, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CreatedResponse>;
      })
    );
  }

  /**
   * Invite user to Customer organization.
   *
   * **Accessible only for users with permission 'ROLE_CUSTOMER_ADMIN'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postCustomersUserReinvitation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  postCustomersUserReinvitation(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * User Id.
     */
    user: number;
    context?: HttpContext
  }
): Observable<CreatedResponse> {

    return this.postCustomersUserReinvitation$Response(params).pipe(
      map((r: StrictHttpResponse<CreatedResponse>) => r.body as CreatedResponse)
    );
  }

  /**
   * Path part for operation deleteCustomersUserInviteDelete
   */
  static readonly DeleteCustomersUserInviteDeletePath = '/api/web/v1/customers/{customer}/users/{user}/invites';

  /**
   * Remove only user with open invitation.
   *
   * **Accessible only for users with role 'ROLE_CUSTOMER_ADMIN'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteCustomersUserInviteDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCustomersUserInviteDelete$Response(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * User Id.
     */
    user: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EmptySuccessfulResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerService.DeleteCustomersUserInviteDeletePath, 'delete');
    if (params) {
      rb.path('customer', params.customer, {});
      rb.path('user', params.user, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmptySuccessfulResponse>;
      })
    );
  }

  /**
   * Remove only user with open invitation.
   *
   * **Accessible only for users with role 'ROLE_CUSTOMER_ADMIN'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteCustomersUserInviteDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCustomersUserInviteDelete(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * User Id.
     */
    user: number;
    context?: HttpContext
  }
): Observable<EmptySuccessfulResponse> {

    return this.deleteCustomersUserInviteDelete$Response(params).pipe(
      map((r: StrictHttpResponse<EmptySuccessfulResponse>) => r.body as EmptySuccessfulResponse)
    );
  }

  /**
   * Path part for operation getCustomersUsersList
   */
  static readonly GetCustomersUsersListPath = '/api/web/v1/customers/{customer}/users';

  /**
   * List users for specified customer.
   *
   * **Accessible only for users with role 'ROLE_CUSTOMER_ADMIN'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomersUsersList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomersUsersList$Response(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * User roles to filter them by. Possible values: user, admin
     */
    'role[]'?: any;

    /**
     * User roles to filter them by. Possible values: true, false
     */
    active?: any;

    /**
     * Search user by first-last name and email.
     */
    search?: any;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ListApiResponse & {
'data'?: Array<UserItemResponse>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerService.GetCustomersUsersListPath, 'get');
    if (params) {
      rb.path('customer', params.customer, {});
      rb.query('role[]', params['role[]'], {"style":"form"});
      rb.query('active', params.active, {"style":"form"});
      rb.query('search', params.search, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListApiResponse & {
        'data'?: Array<UserItemResponse>;
        }>;
      })
    );
  }

  /**
   * List users for specified customer.
   *
   * **Accessible only for users with role 'ROLE_CUSTOMER_ADMIN'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCustomersUsersList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomersUsersList(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * User roles to filter them by. Possible values: user, admin
     */
    'role[]'?: any;

    /**
     * User roles to filter them by. Possible values: true, false
     */
    active?: any;

    /**
     * Search user by first-last name and email.
     */
    search?: any;
    context?: HttpContext
  }
): Observable<ListApiResponse & {
'data'?: Array<UserItemResponse>;
}> {

    return this.getCustomersUsersList$Response(params).pipe(
      map((r: StrictHttpResponse<ListApiResponse & {
'data'?: Array<UserItemResponse>;
}>) => r.body as ListApiResponse & {
'data'?: Array<UserItemResponse>;
})
    );
  }

  /**
   * Path part for operation putCustomersUsersPasswordUpdate
   */
  static readonly PutCustomersUsersPasswordUpdatePath = '/api/web/v1/customers/{customer}/users/{user}/password';

  /**
   * Update password of selected user in specified Customer organization.
   *
   * **Accessible only for users with role 'ROLE_CUSTOMER_ADMIN'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putCustomersUsersPasswordUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putCustomersUsersPasswordUpdate$Response(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * User Id.
     */
    user: number;
    context?: HttpContext
    body: UserPasswordUpdateRequest
  }
): Observable<StrictHttpResponse<UpdatedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerService.PutCustomersUsersPasswordUpdatePath, 'put');
    if (params) {
      rb.path('customer', params.customer, {});
      rb.path('user', params.user, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UpdatedResponse>;
      })
    );
  }

  /**
   * Update password of selected user in specified Customer organization.
   *
   * **Accessible only for users with role 'ROLE_CUSTOMER_ADMIN'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putCustomersUsersPasswordUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putCustomersUsersPasswordUpdate(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * User Id.
     */
    user: number;
    context?: HttpContext
    body: UserPasswordUpdateRequest
  }
): Observable<UpdatedResponse> {

    return this.putCustomersUsersPasswordUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<UpdatedResponse>) => r.body as UpdatedResponse)
    );
  }

  /**
   * Path part for operation getCustomersUsersShow
   */
  static readonly GetCustomersUsersShowPath = '/api/web/v1/customers/{customer}/users/{user}';

  /**
   * Get customer user data.
   *
   * **Accessible only for users with role 'ROLE_CUSTOMER_ADMIN'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomersUsersShow()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomersUsersShow$Response(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * User Id.
     */
    user: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<GeneralApiResponse & {
'data'?: UserResponse;
}>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerService.GetCustomersUsersShowPath, 'get');
    if (params) {
      rb.path('customer', params.customer, {});
      rb.path('user', params.user, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GeneralApiResponse & {
        'data'?: UserResponse;
        }>;
      })
    );
  }

  /**
   * Get customer user data.
   *
   * **Accessible only for users with role 'ROLE_CUSTOMER_ADMIN'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCustomersUsersShow$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomersUsersShow(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * User Id.
     */
    user: number;
    context?: HttpContext
  }
): Observable<GeneralApiResponse & {
'data'?: UserResponse;
}> {

    return this.getCustomersUsersShow$Response(params).pipe(
      map((r: StrictHttpResponse<GeneralApiResponse & {
'data'?: UserResponse;
}>) => r.body as GeneralApiResponse & {
'data'?: UserResponse;
})
    );
  }

  /**
   * Path part for operation putCustomersUsersUpdate
   */
  static readonly PutCustomersUsersUpdatePath = '/api/web/v1/customers/{customer}/users/{user}';

  /**
   * Update data of selected user in specified customer organization.
   *
   * **Accessible only for users with role 'ROLE_CUSTOMER_ADMIN'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putCustomersUsersUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putCustomersUsersUpdate$Response(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * User Id.
     */
    user: number;
    context?: HttpContext
    body: UserUpdateRequest
  }
): Observable<StrictHttpResponse<CreatedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerService.PutCustomersUsersUpdatePath, 'put');
    if (params) {
      rb.path('customer', params.customer, {});
      rb.path('user', params.user, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CreatedResponse>;
      })
    );
  }

  /**
   * Update data of selected user in specified customer organization.
   *
   * **Accessible only for users with role 'ROLE_CUSTOMER_ADMIN'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putCustomersUsersUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putCustomersUsersUpdate(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * User Id.
     */
    user: number;
    context?: HttpContext
    body: UserUpdateRequest
  }
): Observable<CreatedResponse> {

    return this.putCustomersUsersUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<CreatedResponse>) => r.body as CreatedResponse)
    );
  }

  /**
   * Path part for operation postCustomerVerification
   */
  static readonly PostCustomerVerificationPath = '/api/web/v1/customers/{customer}/verification';

  /**
   * Verify customer registration.
   *
   * **Accessible only for users with roles 'ROLE_PSP_USER', 'ROLE_CUSTOMER_USER'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postCustomerVerification()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postCustomerVerification$Response(params: {
    customer: number;
    context?: HttpContext
    body: CustomerVerificationRequest
  }
): Observable<StrictHttpResponse<EmptySuccessfulResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerService.PostCustomerVerificationPath, 'post');
    if (params) {
      rb.path('customer', params.customer, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmptySuccessfulResponse>;
      })
    );
  }

  /**
   * Verify customer registration.
   *
   * **Accessible only for users with roles 'ROLE_PSP_USER', 'ROLE_CUSTOMER_USER'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postCustomerVerification$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postCustomerVerification(params: {
    customer: number;
    context?: HttpContext
    body: CustomerVerificationRequest
  }
): Observable<EmptySuccessfulResponse> {

    return this.postCustomerVerification$Response(params).pipe(
      map((r: StrictHttpResponse<EmptySuccessfulResponse>) => r.body as EmptySuccessfulResponse)
    );
  }

}
