import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BaseToastAlertClasses } from '../../shared/enums/base-toast-alert-classes.enum';
import { BaseAdministrationService } from '../../modules/administration/base-administration.service';
import { baseUserActions, basePspBranchActions, baseSharedActions } from '../actions';
import { BaseCoreState } from '../reducers';
import { baseAuthSelectors, basePspBranchSelectors } from '../selectors';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { BaseTokenInterface } from '../../shared/interfaces/base-token-service.interface';
import { BasePspBranchService } from '../../modules/psp-branch/base-psp-branch.service';

@Injectable()
export class BasePspBranchEffects {
  constructor(
    private actions$: Actions,
    private store: Store<BaseCoreState>,
    private pspBranchService: BasePspBranchService,
    private administrationService: BaseAdministrationService
  ) {}

  loadPspBranchList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(basePspBranchActions.loadPspBranchList),
      withLatestFrom(this.store.select(basePspBranchSelectors.getBranchInactiveFilter)),
      switchMap(([action, active]) => {
        return this.pspBranchService.getPspBranches(action.payload.orgId, active ? active : undefined).pipe(
          mergeMap((response: any) => {
            return [
              basePspBranchActions.setPspBranchList({ payload: response.data }),
              basePspBranchActions.successPspBranchList(),
            ];
          }),
          catchError(() => {
            return of(basePspBranchActions.errorPspBranchList());
          })
        );
      })
    );
  });

  setBranchStatus$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(basePspBranchActions.setPspBranchActiveCrud),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((state) => state.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        const source$ = action.payload.setActive
          ? this.pspBranchService.setActiveBranchStatus(user.organizationId, action.payload.branchId)
          : this.pspBranchService.setInactiveBranchStatus(user.organizationId, action.payload.branchId);
        return source$.pipe(
          mergeMap(() => {
            return [
              basePspBranchActions.loadPspBranchList({ payload: { orgId: user.organizationId } }),
              basePspBranchActions.successPspBranchCrud(),
            ];
          }),
          catchError(() => {
            return of(basePspBranchActions.errorPspBranchCrud());
          })
        );
      })
    );
  });

  loadPspUserList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(basePspBranchActions.loadPspUserList),
      switchMap((action: any) => {
        return this.pspBranchService.getPspAdminsAndUsers(action.payload.orgId).pipe(
          mergeMap((response: any) => {
            return [
              basePspBranchActions.setPspUserList({ payload: response }),
              basePspBranchActions.successPspUserList(),
            ];
          }),
          catchError(() => {
            return of(basePspBranchActions.errorPspUserList());
          })
        );
      })
    );
  });

  createPspBranch$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(basePspBranchActions.createPspBranchCrud),
      switchMap((action: any) => {
        return this.pspBranchService.createPspBranch(action.payload.orgId, action.payload.body).pipe(
          mergeMap(() => [
            basePspBranchActions.loadPspBranchList({ payload: { orgId: action.payload.orgId } }),
            basePspBranchActions.successPspBranchCrud(),
            baseSharedActions.addSystemAlert({
              payload: {
                class: BaseToastAlertClasses.success,
                body: 'branch.createdSuccessfully',
              },
            }),
          ]),
          catchError(() => {
            return of(basePspBranchActions.errorPspBranchCrud());
          })
        );
      })
    );
  });

  addBranchUsers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(basePspBranchActions.addBranchUsers),
      switchMap((action) => {
        return this.pspBranchService
          .addUsersToBranch(action.payload.branch, action.payload.psp, action.payload.users)
          .pipe(
            mergeMap(() => [
              baseSharedActions.addSystemAlert({
                payload: {
                  class: BaseToastAlertClasses.success,
                  body: 'branch.updatedSuccessfully',
                },
              }),
              baseSharedActions.loadBranchUsers({
                payload: { branch: action.payload.branch },
              }),
              baseUserActions.loadUserList({ payload: { id: action.payload.psp.toString() } }),
              basePspBranchActions.loadPspBranchList({ payload: { orgId: action.payload.psp } }),
            ])
          );
      })
    );
  });

  deleteBranchUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(basePspBranchActions.deleteBranchUser),
      switchMap((action) => {
        return this.pspBranchService
          .deleteUserFromBranch(action.payload.psp, action.payload.user, action.payload.branch)
          .pipe(
            mergeMap(() => [
              baseSharedActions.addSystemAlert({
                payload: {
                  class: BaseToastAlertClasses.success,
                  body: 'branch.userDeletedSuccessfully',
                },
              }),
              baseSharedActions.loadBranchUsers({
                payload: { branch: action.payload.branch },
              }),
              baseUserActions.loadUserList({ payload: { id: action.payload.psp.toString() } }),
              basePspBranchActions.loadPspBranchList({ payload: { orgId: action.payload.psp } }),
            ])
          );
      })
    );
  });

  updateUsers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(basePspBranchActions.updateBranchUsers),
      switchMap((action) => {
        return this.pspBranchService
          .updateUsersInBranch(action.payload.branch, action.payload.psp, action.payload.users)
          .pipe(
            mergeMap(() => [
              baseSharedActions.addSystemAlert({
                payload: {
                  class: BaseToastAlertClasses.success,
                  body: 'branch.updatedSuccessfully',
                },
              }),
              baseSharedActions.loadBranchUsers({
                payload: { branch: action.payload.branch },
              }),
              baseUserActions.loadUserList({ payload: { id: action.payload.psp.toString() } }),
              basePspBranchActions.loadPspBranchList({ payload: { orgId: action.payload.psp } }),
            ])
          );
      })
    );
  });

  loadCurrentBranch$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(basePspBranchActions.loadBranch),
      switchMap((action) =>
        this.pspBranchService.getPspBranch(action.payload.psp, action.payload.branch).pipe(
          mergeMap((response) => [
            basePspBranchActions.setBranch({ payload: response }),
            basePspBranchActions.successBranch(),
          ]),
          catchError(() => of(basePspBranchActions.errorBranch()))
        )
      )
    );
  });

  updateCurrentBranch$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(basePspBranchActions.updateBranch),
      switchMap((action) =>
        this.administrationService.updatePspBranch(action.payload.branch, action.payload.psp, action.payload.body).pipe(
          mergeMap(() => [
            basePspBranchActions.setBranch({ payload: action.payload.body }),
            basePspBranchActions.loadPspBranchList({ payload: { orgId: action.payload.psp } }),
            basePspBranchActions.successBranch(),
            baseSharedActions.addSystemAlert({
              payload: {
                class: BaseToastAlertClasses.success,
                body: 'branch.updatedSuccessfully',
              },
            }),
          ]),
          catchError(() => of(basePspBranchActions.errorBranch()))
        )
      )
    );
  });
}
