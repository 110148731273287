import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { NgModule } from '@angular/core';
import { baseAssignmentsEffects, baseAssignmentsReducer } from '../../_store';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { BaseAssignmentsService } from './base-assignments-service';
import { BaseCustomerAllAssignmentsListGuard } from './_guards/base-customer-all-assignments-list.guard';
import { BaseAssignmentListGuard } from './_guards/base-assignment-list.guard';
import { BaseSignContractAnimatedStatusButtonComponent } from './_components/base-sign-contract-animated-status-button/base-sign-contract-animated-status-button.component';
import { BaseSharedModule } from '../../shared/base-shared.module';
import { CommonModule } from '@angular/common';
import { BaseSignContractStatusTooltipComponent } from './_components/base-sign-contract-status-tooltip/base-sign-contract-status-tooltip.component';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('assignments', baseAssignmentsReducer.BaseAssignmentsReducer),
    EffectsModule.forFeature([baseAssignmentsEffects.BaseAssignmentsEffects]),
    NgbTooltipModule,
    BaseSharedModule,
  ],
  providers: [BaseAssignmentsService, BaseAssignmentListGuard, BaseCustomerAllAssignmentsListGuard],
  declarations: [BaseSignContractAnimatedStatusButtonComponent, BaseSignContractStatusTooltipComponent],
  exports: [BaseSignContractAnimatedStatusButtonComponent, BaseSignContractStatusTooltipComponent],
})
export class BaseAssignmentsModule {}
