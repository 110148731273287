/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CustomerBranchListItemResponse } from '../models/customer-branch-list-item-response';
import { DocumentListItemResponse } from '../models/document-list-item-response';
import { GeneralApiResponse } from '../models/general-api-response';
import { ListApiResponse } from '../models/list-api-response';
import { PspBranchResponse } from '../models/psp-branch-response';
import { PspResponse } from '../models/psp-response';
import { PaginatedApiResponse } from '../models/paginated-api-response';
import { SurchargeListItemResponse } from '../models/surcharge-list-item-response';

@Injectable({
  providedIn: 'root',
})
export class CustomerPspService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getCustomerListOfBranches
   */
  static readonly GetCustomerListOfBranchesPath = '/api/web/v1/customers/{customer}/branches';

  /**
   * List of Customer Branches.
   *
   * **Accessible only for users with permission 'FEATURE_VIEW_RELATED_ORG'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerListOfBranches()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerListOfBranches$Response(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Page number.
     */
    page?: any;

    /**
     * Size number in pagination.
     */
    size?: any;

    /**
     * Search branch by name and legalName.
     */
    search?: any;

    /**
     * Customer Location Ids to filter branches by.
     */
    'customerLocations[]'?: any;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<PaginatedApiResponse & {
'data'?: Array<CustomerBranchListItemResponse>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerPspService.GetCustomerListOfBranchesPath, 'get');
    if (params) {
      rb.path('customer', params.customer, {});
      rb.query('page', params.page, {});
      rb.query('size', params.size, {});
      rb.query('search', params.search, {});
      rb.query('customerLocations[]', params['customerLocations[]'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaginatedApiResponse & {
        'data'?: Array<CustomerBranchListItemResponse>;
        }>;
      })
    );
  }

  /**
   * List of Customer Branches.
   *
   * **Accessible only for users with permission 'FEATURE_VIEW_RELATED_ORG'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCustomerListOfBranches$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerListOfBranches(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Page number.
     */
    page?: any;

    /**
     * Size number in pagination.
     */
    size?: any;

    /**
     * Search branch by name and legalName.
     */
    search?: any;

    /**
     * Customer Location Ids to filter branches by.
     */
    'customerLocations[]'?: any;
    context?: HttpContext
  }
): Observable<PaginatedApiResponse & {
'data'?: Array<CustomerBranchListItemResponse>;
}> {

    return this.getCustomerListOfBranches$Response(params).pipe(
      map((r: StrictHttpResponse<PaginatedApiResponse & {
'data'?: Array<CustomerBranchListItemResponse>;
}>) => r.body as PaginatedApiResponse & {
'data'?: Array<CustomerBranchListItemResponse>;
})
    );
  }

  /**
   * Path part for operation getCustomerPspBranchLocationContractDownload
   */
  static readonly GetCustomerPspBranchLocationContractDownloadPath = '/api/web/v1/customers/{customer}/locations/{location}/branches/{branch}/contract/file';

  /**
   * Download typical contract.
   *
   * **Accessible only for users with permission 'FEATURE_VIEW_RELATED_ORG'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerPspBranchLocationContractDownload()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerPspBranchLocationContractDownload$Response(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Location Id.
     */
    location: number;

    /**
     * Branch Id.
     */
    branch: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerPspService.GetCustomerPspBranchLocationContractDownloadPath, 'get');
    if (params) {
      rb.path('customer', params.customer, {});
      rb.path('location', params.location, {});
      rb.path('branch', params.branch, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'multipart/form-data',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Download typical contract.
   *
   * **Accessible only for users with permission 'FEATURE_VIEW_RELATED_ORG'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCustomerPspBranchLocationContractDownload$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerPspBranchLocationContractDownload(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Location Id.
     */
    location: number;

    /**
     * Branch Id.
     */
    branch: number;
    context?: HttpContext
  }
): Observable<Blob> {

    return this.getCustomerPspBranchLocationContractDownload$Response(params).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Path part for operation getCustomerPspBranch
   */
  static readonly GetCustomerPspBranchPath = '/api/web/v1/customers/{customer}/locations/{location}/branches/{branch}';

  /**
   * Get PSP Branch data.
   *
   * **Accessible only for users with permission 'FEATURE_VIEW_RELATED_ORG'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerPspBranch()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerPspBranch$Response(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Location Id.
     */
    location: number;

    /**
     * Branch Id.
     */
    branch: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<GeneralApiResponse & {
'data'?: PspBranchResponse;
}>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerPspService.GetCustomerPspBranchPath, 'get');
    if (params) {
      rb.path('customer', params.customer, {});
      rb.path('location', params.location, {});
      rb.path('branch', params.branch, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GeneralApiResponse & {
        'data'?: PspBranchResponse;
        }>;
      })
    );
  }

  /**
   * Get PSP Branch data.
   *
   * **Accessible only for users with permission 'FEATURE_VIEW_RELATED_ORG'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCustomerPspBranch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerPspBranch(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Location Id.
     */
    location: number;

    /**
     * Branch Id.
     */
    branch: number;
    context?: HttpContext
  }
): Observable<GeneralApiResponse & {
'data'?: PspBranchResponse;
}> {

    return this.getCustomerPspBranch$Response(params).pipe(
      map((r: StrictHttpResponse<GeneralApiResponse & {
'data'?: PspBranchResponse;
}>) => r.body as GeneralApiResponse & {
'data'?: PspBranchResponse;
})
    );
  }

  /**
   * Path part for operation getCustomerPspDocumentsDownload
   */
  static readonly GetCustomerPspDocumentsDownloadPath = '/api/web/v1/customers/{customer}/psps/{psp}/documents/{document}/file';

  /**
   * Download a file.
   *
   * **Accessible only for users with permission 'FEATURE_VIEW_RELATED_ORG'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerPspDocumentsDownload()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerPspDocumentsDownload$Response(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * PSP Id.
     */
    psp: number;
    document: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerPspService.GetCustomerPspDocumentsDownloadPath, 'get');
    if (params) {
      rb.path('customer', params.customer, {});
      rb.path('psp', params.psp, {});
      rb.path('document', params.document, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'multipart/form-data',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Download a file.
   *
   * **Accessible only for users with permission 'FEATURE_VIEW_RELATED_ORG'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCustomerPspDocumentsDownload$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerPspDocumentsDownload(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * PSP Id.
     */
    psp: number;
    document: string;
    context?: HttpContext
  }
): Observable<Blob> {

    return this.getCustomerPspDocumentsDownload$Response(params).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Path part for operation getCustomerPspDocuments
   */
  static readonly GetCustomerPspDocumentsPath = '/api/web/v1/customers/{customer}/psps/{psp}/documents';

  /**
   * Get list of documents.
   *
   * **Accessible only for users with permission 'FEATURE_VIEW_RELATED_ORG'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerPspDocuments()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerPspDocuments$Response(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * PSP Id.
     */
    psp: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ListApiResponse & {
'data'?: Array<DocumentListItemResponse>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerPspService.GetCustomerPspDocumentsPath, 'get');
    if (params) {
      rb.path('customer', params.customer, {});
      rb.path('psp', params.psp, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListApiResponse & {
        'data'?: Array<DocumentListItemResponse>;
        }>;
      })
    );
  }

  /**
   * Get list of documents.
   *
   * **Accessible only for users with permission 'FEATURE_VIEW_RELATED_ORG'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCustomerPspDocuments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerPspDocuments(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * PSP Id.
     */
    psp: number;
    context?: HttpContext
  }
): Observable<ListApiResponse & {
'data'?: Array<DocumentListItemResponse>;
}> {

    return this.getCustomerPspDocuments$Response(params).pipe(
      map((r: StrictHttpResponse<ListApiResponse & {
'data'?: Array<DocumentListItemResponse>;
}>) => r.body as ListApiResponse & {
'data'?: Array<DocumentListItemResponse>;
})
    );
  }

  /**
   * Path part for operation getCustomerPspShow
   */
  static readonly GetCustomerPspShowPath = '/api/web/v1/customers/{customer}/psps/{psp}';

  /**
   * Get PSP data.
   *
   * **Accessible only for users with permission 'FEATURE_VIEW_RELATED_ORG'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerPspShow()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerPspShow$Response(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * PSP Id.
     */
    psp: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<GeneralApiResponse & {
'data'?: PspResponse;
}>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerPspService.GetCustomerPspShowPath, 'get');
    if (params) {
      rb.path('customer', params.customer, {});
      rb.path('psp', params.psp, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GeneralApiResponse & {
        'data'?: PspResponse;
        }>;
      })
    );
  }

  /**
   * Get PSP data.
   *
   * **Accessible only for users with permission 'FEATURE_VIEW_RELATED_ORG'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCustomerPspShow$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerPspShow(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * PSP Id.
     */
    psp: number;
    context?: HttpContext
  }
): Observable<GeneralApiResponse & {
'data'?: PspResponse;
}> {

    return this.getCustomerPspShow$Response(params).pipe(
      map((r: StrictHttpResponse<GeneralApiResponse & {
'data'?: PspResponse;
}>) => r.body as GeneralApiResponse & {
'data'?: PspResponse;
})
    );
  }

  /**
   * Path part for operation getCustomerPspSurcharges
   */
  static readonly GetCustomerPspSurchargesPath = '/api/web/v1/customers/{customer}/psps/{psp}/surcharges';

  /**
   * Get surcharges list.
   *
   * **Accessible only for users with permission 'FEATURE_VIEW_RELATED_ORG'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerPspSurcharges()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerPspSurcharges$Response(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * PSP Id.
     */
    psp: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<{
'message': string;
'data': Array<SurchargeListItemResponse>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerPspService.GetCustomerPspSurchargesPath, 'get');
    if (params) {
      rb.path('customer', params.customer, {});
      rb.path('psp', params.psp, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'message': string;
        'data': Array<SurchargeListItemResponse>;
        }>;
      })
    );
  }

  /**
   * Get surcharges list.
   *
   * **Accessible only for users with permission 'FEATURE_VIEW_RELATED_ORG'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCustomerPspSurcharges$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerPspSurcharges(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * PSP Id.
     */
    psp: number;
    context?: HttpContext
  }
): Observable<{
'message': string;
'data': Array<SurchargeListItemResponse>;
}> {

    return this.getCustomerPspSurcharges$Response(params).pipe(
      map((r: StrictHttpResponse<{
'message': string;
'data': Array<SurchargeListItemResponse>;
}>) => r.body as {
'message': string;
'data': Array<SurchargeListItemResponse>;
})
    );
  }

}
