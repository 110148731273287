import { createAction, props } from '@ngrx/store';
import { ConfirmRequest, FiltersResponse, PaginatedApiResponse, SectionResponse } from '../../../api';

// Offer overlaps list
export const loadOfferOverlapsList = createAction(
  '[Bookings and Offers] Load offer overlaps list',
  props<{ payload: { page?: any; size?: any; profile?: any; customer?: any } }>()
);
export const setOfferOverlapsList = createAction(
  '[Bookings and Offers] Set offer overlaps list',
  props<{ payload: PaginatedApiResponse & { data?: SectionResponse[]; filters?: FiltersResponse } }>()
);
export const successOfferOverlapsList = createAction('[Bookings and Offers] Success offer overlaps list');
export const errorOfferOverlapsList = createAction('[Bookings and Offers] Error offer overlaps list');

// Confirm psp inquiry offer
export const confirmPspInquiryOffer = createAction(
  '[Bookings and Offers] Confirm psp inquiry offer',
  props<{
    payload: {
      inquiry: number;
      offer: number;
      body: ConfirmRequest;
      isInquiryDetailsScreen?: boolean;
      filters?: Record<string, any>;
    };
  }>()
);
export const confirmPspInquiryOfferSuccess = createAction('[Bookings and Offers] Confirm psp inquiry offer success');
export const confirmPspInquiryOfferError = createAction('[Bookings and Offers] Confirm psp inquiry offer error');

// Withdraw psp inquiry offer
export const withdrawPspInquiryOffer = createAction(
  '[Bookings and Offers] Withdraw psp inquiry offer',
  props<{ payload: { inquiry: number; offer: number; isInquiryOffersPage?: boolean } }>()
);
export const withdrawPspInquiryOfferSuccess = createAction('[Bookings and Offers] Withdraw psp inquiry offer success');
export const withdrawPspInquiryOfferError = createAction('[Bookings and Offers] Withdraw psp inquiry offer error');
