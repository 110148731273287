import {
  AfterViewInit,
  Component,
  DoCheck,
  ElementRef,
  forwardRef,
  Input,
  Renderer2,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseFormatDecimalPipe } from '../../pipes/base-format-decimal.pipe';
import { BaseFormatPricePipe } from '../../pipes/base-format-price.pipe';
import { AbstractFormComponent } from '../_abstract-classes/abstract-form-component';

export enum BaseInputSizes {
  'md' = 'md',
  'lg' = 'lg',
}

@Component({
  selector: 'base-input',
  templateUrl: './base-input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BaseInputComponent),
      multi: true,
    },
  ],
})
export class BaseInputComponent extends AbstractFormComponent implements ControlValueAccessor, DoCheck, AfterViewInit {
  @ViewChild('input')
  public element: ElementRef;
  private val = '';
  @Input() isDisabled = false;
  @Input() placeholder = 'Placeholder';
  @Input() type = 'text';
  @Input() label = '';
  @Input() isValid = false;
  @Input() isInvalid = false;
  @Input() invalidIcon = '';
  @Input() isPreview = false;
  @Input() previewSuffix = '';
  @Input() maxlength = null;
  @Input() min = null;
  @Input() icon = '';
  @Input() size = BaseInputSizes.lg;
  @Input() errorMessage: string | null = null;
  @Input() rightAligned = false;
  @Input() tabIndex: number | null = null;
  @Input() autofocus = false;
  @Input() isPrice = false;
  @Input() isFloatedDecimal = false;
  @Input() isRequired = false;
  @Input() labelTooltip: TemplateRef<any>;
  @Input() backgroundColor = '';
  @Input() isEmailField = false;
  @Input() step = null;
  @Input() inputClasses = '';
  @Input() autoCompleteValue = 'on';
  @Input() tooltipClass = '';
  @Input() tooltipPlacement = 'top';

  private onChange = (_value: any) => {};
  private onTouched = () => {};

  constructor(
    protected renderer: Renderer2,
    private formatPricePipe: BaseFormatPricePipe,
    private formatDecimalPipe: BaseFormatDecimalPipe
  ) {
    super(renderer);
  }

  public ngDoCheck(): void {
    this.setNgClass();
  }

  public ngAfterViewInit(): void {
    this.setNgClass();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => {}): void {
    this.onTouched = fn;
  }

  writeValue(outsideValue: string): void {
    if (this.isPrice) {
      this.val = this.formatPricePipe.transform(outsideValue);
    } else if (this.isFloatedDecimal) {
      this.val = this.formatDecimalPipe.transform(outsideValue);
    } else {
      this.val = outsideValue;
    }
  }

  get value(): string {
    return this.val;
  }

  set value(value: string) {
    this.val = value;
    this.onChange(this.val);
    this.onTouched();
  }

  get classes(): string[] {
    return [this.inputClasses, this.rightAligned ? 'text-end' : ''];
  }

  get componentClasses(): string[] {
    return ['input', `input-${this.size}`];
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  onBlur(): void {
    if (this.isPrice) {
      this.val = this.formatPricePipe.transform(this.val);
    }
  }
}
