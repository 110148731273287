import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { BasePspBranchListGuard } from './_guards/base-psp-branch-list.guard';
import { BasePspUserListGuard } from './_guards/base-psp-user-list.guard';
import { basePspBranchEffects, basePspBranchReducer } from '../../_store';
import { BasePspBranchService } from './base-psp-branch.service';

@NgModule({
  imports: [
    StoreModule.forFeature('psp-branch', basePspBranchReducer.BasePspBranchReducer),
    EffectsModule.forFeature([basePspBranchEffects.BasePspBranchEffects]),
  ],
  exports: [],
  declarations: [],
  providers: [BasePspBranchService, BasePspUserListGuard, BasePspBranchListGuard],
})
export class BasePspBranchModule {}
