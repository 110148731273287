import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'base-dashboard-card-header',
  templateUrl: './dashboard-card-header.component.html',
  styleUrls: ['./dashboard-card-header.component.scss'],
})
export class DashboardCardHeaderComponent {
  @Input() header = '';
  @Input() amount: number | null = null;
  @Input() icon = '';
  @Input() buttonLabel = '';
  @Input() isButtonAllowed = true;
  @Input() link = '';
  @Input() queryParams = {};
  @Input() showBorder = true;
  @Output() buttonClick = new EventEmitter<boolean>();

  constructor(private router: Router) {}

  goToLinkUrl(): void {
    this.router.navigate([this.link], { queryParams: this.queryParams });
  }
}
