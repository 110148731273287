import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { AppsSharedModule, BaseSharedModule } from 'base';
import { BaseCustomerEmployeeMasterDataTabComponent } from './_components/employee-master-data-tab/base-customer-employee-master-data-tab.component';
import { BaseCustomerEmployeeOperationInfoTabComponent } from './_components/employee-operation-info-tab/base-customer-employee-operation-info-tab.component';
import { BaseCustomerEmployeeReviewsTabComponent } from './_components/employee-reviews-tab/base-customer-employee-reviews-tab.component';
import { BaseCustomerEmployeeTimeRecordingsTabComponent } from './_components/employee-time-recordings-tab/base-customer-employee-time-recordings-tab.component';
import { BaseCustomerEmployeeTimeRecordingsListItemComponent } from './_components/employee-time-recordings-tab/employee-time-recordings-list-item/base-customer-employee-time-recordings-list-item.component';
import { BaseCustomerShiftReadinessComponent } from './_components/shift-readiness/base-customer-shift-readiness.component';
import { BaseCustomerStaffRequestModalComponent } from './_components/staff-request-modal/base-customer-staff-request-modal.component';
import { BaseCustomerCandidateDocumentComponent } from './_components/employee-documents-tab/base-customer-candidate-document/base-customer-candidate-document.component';
import { BaseCustomerEmployeeDocumentsTabComponent } from './_components/employee-documents-tab/base-customer-employee-documents-tab.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    TranslateModule,
    BaseSharedModule,
    AppsSharedModule,
  ],
  declarations: [
    BaseCustomerStaffRequestModalComponent,
    BaseCustomerShiftReadinessComponent,
    BaseCustomerEmployeeMasterDataTabComponent,
    BaseCustomerEmployeeOperationInfoTabComponent,
    BaseCustomerEmployeeReviewsTabComponent,
    BaseCustomerEmployeeTimeRecordingsTabComponent,
    BaseCustomerEmployeeTimeRecordingsListItemComponent,
    BaseCustomerCandidateDocumentComponent,
    BaseCustomerEmployeeDocumentsTabComponent,
  ],
  exports: [
    BaseCustomerStaffRequestModalComponent,
    BaseCustomerShiftReadinessComponent,
    BaseCustomerEmployeeMasterDataTabComponent,
    BaseCustomerEmployeeOperationInfoTabComponent,
    BaseCustomerEmployeeReviewsTabComponent,
    BaseCustomerEmployeeTimeRecordingsTabComponent,
    BaseCustomerEmployeeTimeRecordingsListItemComponent,
    BaseCustomerCandidateDocumentComponent,
    BaseCustomerEmployeeDocumentsTabComponent,
  ],
})
export class BaseCustomerSharedModule {}
