import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { map, take } from 'rxjs/operators';
import { baseAuthSelectors, BaseCoreState, baseSharedActions, basePspBranchActions } from '../../../../../_store';
import { BaseButtonSize } from '../../../../../shared/components/button/base-button.component';
import { BranchListItemResponse } from '../../../../../../api';

@Component({
  selector: 'base-organisation-management-list-item',
  templateUrl: './base-organisation-management-list-item.component.html',
  styleUrls: ['./base-organisation-management-list-item.component.scss'],
})
export class BaseOrganisationManagementListItemComponent implements OnInit {
  @Input() branch: BranchListItemResponse;
  @Output() btnClick = new EventEmitter<boolean>();
  buttonSizes = BaseButtonSize;
  psp: number;

  isActive = new UntypedFormControl();

  constructor(private store: Store<BaseCoreState>) {}

  ngOnInit(): void {
    this.isActive.setValue(this.branch.isActive);

    this.store
      .select(baseAuthSelectors.getUserInfo)
      .pipe(
        take(1),
        map((state) => state.data)
      )
      .subscribe((user) => (this.psp = user?.organizationId as number));
  }

  changeIsActive(setActive: boolean): void {
    this.store.dispatch(
      basePspBranchActions.setPspBranchActiveCrud({
        payload: { branchId: this.branch.id, setActive },
      })
    );
  }

  editBranch(): void {
    this.store.dispatch(baseSharedActions.loadBranchUsers({ payload: { branch: this.branch.id } }));
    this.store.dispatch(
      basePspBranchActions.loadBranch({ payload: { branch: this.branch.id, psp: this.psp as number } })
    );

    this.btnClick.emit(true);
  }
}
