import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { baseSharedActions, baseSharedSelectors, BaseCoreState } from '../../_store';

@Injectable()
export class BaseCountryListGuard {
  constructor(private store: Store<BaseCoreState>) {}

  canActivate(): Observable<boolean> | any {
    return this.store.select(baseSharedSelectors.getCountryListStatus).pipe(
      tap((status: boolean | undefined) => {
        if (!status) {
          this.store.dispatch(baseSharedActions.loadCountryList());
        }
      }),
      filter((status: boolean | undefined) => status !== undefined)
    );
  }
}
