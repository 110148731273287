<div class="inquiry-teaser inquiry-calendar-container p-0">
  <div class="d-flex flex-column inquiry-left-content">
    <div class="ps-4 inquiry-info">
      <h2 baseTruncate>{{ inquiry.mainQualification?.name }}</h2>
      <p baseTruncate>{{ inquiry.dateFrom | baseFormatDate }} - {{ inquiry.dateTo | baseFormatDate }}</p>
      <base-inquiries-calendar-meta-new [datesQty]="datesArray.length"></base-inquiries-calendar-meta-new>
    </div>
    <div
      class="inquiry-calendar-shift-times ps-4 d-flex flex-column flex-grow-1 align-items-stretch justify-content-around"
      [ngClass]="{ 'd-none': !isVisibleCalendar }"
    >
      <div
        class="d-flex justify-content-between align-items-center"
        *ngFor="let shiftTime of shiftTimesWithFlex"
      >
        <span class="inquiry-shift-time-range">
          <ng-container *ngIf="shiftTime.startTime || shiftTime.endTime">
            {{ shiftTime.startTime }} - {{ shiftTime.endTime }}
          </ng-container>
        </span>
        <span
          class="d-flex align-items-center inquiry-shift-time"
          [ngClass]="{ 'inquiry-shift-time-flex': shiftTime.type === inquiryShiftTypes.FLEX }"
        >
          <span class="d-xl-block text-uppercase text-dark-blue">{{ shiftTime.label | translate }}</span>
          <i
            *ngIf="shiftTime.type !== 'flex'"
            class="ms-2 fa-fw fa-regular text-dark-blue {{ baseShiftIconsEnum[shiftTime.type] }}"
            [ngbTooltip]="'shiftTimes.labelLong.' + shiftTime.type | translate"
          ></i
        ></span>
      </div>
    </div>
  </div>

  <div class="inquiry-right-content calendar-days-container">
    <div
      (click)="toggleCalendar()"
      class="d-flex justify-content-start align-items-center font-size-sm pointer inquiry-calendar-shift-visibility-toggle"
    >
      <i
        [ngClass]="isVisibleCalendar ? 'fa-angle-up' : 'fa-angle-down'"
        class="fa-solid me-2"
      ></i
      >{{ (isVisibleCalendar ? 'reduceCalendar' : 'extendCalendar') | translate }}
    </div>
    <base-calendar-bubbles-list-new
      class="d-flex w-100 position-relative"
      [isVisibleCalendar]="isVisibleCalendar"
    ></base-calendar-bubbles-list-new>
  </div>
</div>
