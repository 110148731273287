import { Component } from '@angular/core';
import { APPLICATION_SECTIONS_GROWTH } from '../../_constants/application-sections';
import { USER_MENU_GROWTH } from '../../_constants/user-menu';

@Component({
  selector: 'growth-wrapper-component',
  templateUrl: './wrapper.component.html',
  styleUrls: ['./wrapper.component.scss'],
})
export class WrapperComponent {
  sections = APPLICATION_SECTIONS_GROWTH;
  userMenu = USER_MENU_GROWTH;
}
