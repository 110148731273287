import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { noop, Observable, Subject } from 'rxjs';
import { baseAdministrationSelectors, baseSharedSelectors, BaseCoreState, baseSharedActions } from '../../../_store';
import { BaseRolesService } from '../../../core/services/base-roles.service';
import { BASE_FORM_DEFAULTS } from '../../../shared/model/base-form-defaults';
import { BaseAvatarSize } from '../../../shared/components/avatar/base-avatar.component';
import { BaseButtonSize } from '../../../shared/components/button/base-button.component';
import { BaseModalService } from '../../../shared/services/base-modal.service';
import {
  AvatarUpdateRequest,
  CustomerResponse,
  LogoUpdateRequest,
  PspResponse,
  UserProfileResponse,
} from '../../../../api';
import { BaseToastAlertClasses } from '../../../shared/enums/base-toast-alert-classes.enum';
import { BASE_MAX_AVATAR_OR_LOGO_SIZE } from '../../../shared/constants/base-max-filesizes';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'base-avatar-teaser',
  templateUrl: './base-avatar-teaser.component.html',
  styleUrls: ['./base-avatar-teaser.component.scss'],
})
export class BaseAvatarTeaserComponent implements OnInit, OnDestroy {
  @Input() isUpdateAvatar = false;
  @Input() isCompany = false;
  @Input() isCustomer = false;
  @ViewChild('cropperTemplate', { static: true }) cropperTemplate: ElementRef;
  @Output() avatarChanged: EventEmitter<AvatarUpdateRequest | LogoUpdateRequest> = new EventEmitter<
    AvatarUpdateRequest | LogoUpdateRequest
  >();

  avatarSize = BaseAvatarSize.large;
  buttonSizes = BaseButtonSize;
  croppedImageResult = '';
  selectedImage: File;
  allowedImageTypes = BASE_FORM_DEFAULTS.allowedLogoImageTypes.join(',');
  modalRef: NgbModalRef;
  userProfile$: Observable<UserProfileResponse>;
  companyData: CustomerResponse | PspResponse | undefined;
  private destroy$ = new Subject();

  constructor(
    private readonly modalService: BaseModalService,
    private readonly store: Store<BaseCoreState>,
    private readonly roleService: BaseRolesService
  ) {}

  ngOnInit() {
    this.userProfile$ = this.store.select(baseSharedSelectors.getProfileData) as Observable<UserProfileResponse>;
    this.setCompanyData();
  }

  onAvatarUpdate(event: Event): void {
    const target = event.target as HTMLInputElement;
    const files = target.files;

    if (!files?.length) {
      return;
    }

    const wrongFileSize = Math.round(files[0].size / 1024) > BASE_MAX_AVATAR_OR_LOGO_SIZE;
    const wrongFileFormat = !BASE_FORM_DEFAULTS.allowedLogoImageTypes.includes(files[0].type);

    if (wrongFileFormat || wrongFileSize) {
      this.store.dispatch(
        baseSharedActions.addSystemAlert({
          payload: {
            class: wrongFileFormat ? BaseToastAlertClasses.warning : BaseToastAlertClasses.error,
            body: wrongFileFormat ? 'validation.invalidImageFile' : 'validation.imageFileTooLarge',
          },
        })
      );
      return;
    }
    this.selectedImage = files[0];

    this.modalRef = this.modalService.open({
      headText: 'image.adjust',
      bodyTemplate: this.cropperTemplate,
      okText: 'image.confirmCrop',
    });

    this.modalRef.result.then(() => {
      let body: {};
      if (this.isCompany) {
        body = { logo: { data: this.croppedImageResult } };
      } else {
        body = { avatar: { data: this.croppedImageResult } };
      }

      this.avatarChanged.emit(body);
    }, noop);
  }

  onImageCrop(event: ImageCroppedEvent): void {
    this.croppedImageResult = event.base64 || '';
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }

  private setCompanyData(): void {
    if (this.roleService.isPsp()) {
      this.store
        .select(baseAdministrationSelectors.getPspDataData)
        .pipe(takeUntil(this.destroy$))
        .subscribe((value) => {
          if (value) {
            this.companyData = value.data;
          }
        });
    } else {
      this.store
        .select(baseAdministrationSelectors.getCustomerDataData)
        .pipe(takeUntil(this.destroy$))
        .subscribe((value) => {
          if (value) {
            this.companyData = value;
          }
        });
    }
  }
}
