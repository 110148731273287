import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { baseSharedActions, baseSharedSelectors } from '../../../_store';
import { SharedState } from '../../../_store/reducers/base-shared.reducer';
import { BaseLanguageSwitchColors } from '../../enums/base-language-switch-colors.enum';
import { BaseLanguage } from '../../enums/base-language.enum';

@Component({
  selector: 'base-language-switch',
  templateUrl: './base-language-switch.component.html',
})
export class BaseLanguageSwitchComponent {
  @Input() color: BaseLanguageSwitchColors = BaseLanguageSwitchColors.darkBlue;
  languages = BaseLanguage;
  isOpen = false;
  activeLanguage: string = BaseLanguage.de;

  constructor(private readonly store: Store<SharedState>, private translateService: TranslateService) {
    this.store.select(baseSharedSelectors.getCurrentLanguage).subscribe((value) => {
      this.activeLanguage = value;
      this.translateService.use(value);
    });
  }

  public setLanguage(language: string) {
    this.store.dispatch(baseSharedActions.setLanguage({ language: language }));
  }

  toggle(): void {
    this.isOpen = !this.isOpen;
  }

  close(): void {
    this.isOpen = false;
  }
}
