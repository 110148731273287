import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { baseUserActions, baseUserSelectors, baseAuthSelectors, BaseCoreState } from '../../../_store';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { BaseTokenInterface } from '../../../shared/interfaces/base-token-service.interface';

@Injectable()
export class BaseActiveAdminAndUserGuard {
  constructor(private store: Store<BaseCoreState>) {}

  canActivate(): Observable<boolean> {
    this.store
      .select(baseAuthSelectors.getUserInfo)
      .pipe(
        map((state) => state.data),
        take(1)
      )
      .subscribe((user) => {
        this.store.dispatch(
          baseUserActions.loadAdminAndUserList({
            payload: { id: (user as BaseTokenInterface).organizationId.toString() },
          })
        );
      });

    return this.store.select(baseUserSelectors.getAdminAndUserList).pipe(
      filter((state) => state.ok !== undefined),
      map((state) => state.ok as boolean)
    );
  }
}
