<div class="d-flex flex-column">
  <base-avatar-teaser
    [isUpdateAvatar]="isCustomerLogoEditable || isMyProfile"
    [isCustomer]="isCustomer"
    [isCompany]="!isMyProfile"
    (avatarChanged)="onUpdateAvatar($event)"
  ></base-avatar-teaser>
  <base-horizontal-nav
    [tabs]="tabs"
    [(current)]="currentTab"
    [routeOnClick]="true"
    class="ms-3"
  ></base-horizontal-nav>
  <div class="teaser main-teaser mt-3 mx-3">
    <router-outlet></router-outlet>
  </div>
</div>
