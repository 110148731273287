import { BaseAsideDataInterface } from '../_interfaces/base-aside-data.interface';

export const BASE_ASIDE_MODEL: { [key: string]: BaseAsideDataInterface } = {
  '/auth/login': {
    title: 'auth.loginAside.header',
    description: 'auth.loginAside.description',
    backgroundUrl:
      'linear-gradient(180deg, rgba(39, 60, 73, 0.7), rgba(39, 60, 73, 0.7)), url("../../../../../assets/images/login_bg.jpg")',
  },
  '/auth/login?network=emasters': {
    title: undefined,
    description: 'auth.loginAside.description',
    customClass: 'customized-user',
    backgroundUrl:
      'linear-gradient(180deg, rgba(39, 60, 73, 0.7), rgba(39, 60, 73, 0.7)), url("../../../../../assets/images/login_bg.jpg")',
  },
  '/auth/register': {
    title: '',
    description: 'auth.registered.default.description',
    backgroundUrl:
      'linear-gradient(180deg, rgba(39, 60, 73, 0.7), rgba(39, 60, 73, 0.7)), url("../../../../../assets/images/register_bg.jpg")',
  },
  '/auth/register/psp': {
    title: undefined,
    description: undefined,
    backgroundUrl:
      'linear-gradient(180deg, rgba(39, 60, 73, 0.7), rgba(39, 60, 73, 0.7)), url("../../../../../assets/images/register_bg.jpg")',
  },
  '/auth/register/customer': {
    title: undefined,
    description: undefined,
    backgroundUrl:
      'linear-gradient(180deg, rgba(39, 60, 73, 0.7), rgba(39, 60, 73, 0.7)), url("../../../../../assets/images/register-hospital.jpg")',
  },
  '/auth/imprint': {
    title: undefined,
    description: undefined,
    backgroundUrl:
      'linear-gradient(180deg, rgba(39, 60, 73, 0.7), rgba(39, 60, 73, 0.7)), url("../../../../../assets/images/register-hospital.jpg")',
  },
  '/auth/imprint?network=emasters': {
    title: undefined,
    description: undefined,
    backgroundUrl:
      'linear-gradient(180deg, rgba(39, 60, 73, 0.7), rgba(39, 60, 73, 0.7)), url("../../../../../assets/images/register-hospital.jpg")',
  },
  '/auth/privacy-policy': {
    title: undefined,
    description: undefined,
    backgroundUrl:
      'linear-gradient(180deg, rgba(39, 60, 73, 0.7), rgba(39, 60, 73, 0.7)), url("../../../../../assets/images/register-hospital.jpg")',
  },
  default: {
    title: undefined,
    description: 'auth.registered.default.description',
    backgroundUrl:
      'linear-gradient(180deg, rgba(39, 60, 73, 0.7), rgba(39, 60, 73, 0.7)), url("../../../../../assets/images/register_bg.jpg")',
  },
};
