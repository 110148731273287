import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, SimpleChanges, OnChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { BASE_ASSIGNMENT_CONTRACT_FILE } from '../../../core/constants/base-assignment-cotract-file';
import { baseAssignmentsActions, baseAssignmentsSelectors, BaseCoreState } from '../../../_store';
import { AssignmentCustomerResponse, AssignmentPspResponse, ContractResponse } from '../../../../api';
import { BaseRolesService } from '../../../core/services/base-roles.service';
import { BaseModalService } from '../../services/base-modal.service';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject, noop } from 'rxjs';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BaseSignatureService } from '../../services/base-signature.service';

@Component({
  selector: 'base-sign-contracts-info-card',
  templateUrl: './base-sign-contracts-info-card.component.html',
})
export class BaseSignContractsInfoCardComponent implements OnInit, OnDestroy, OnChanges {
  @Input() assignment: AssignmentPspResponse | AssignmentCustomerResponse;
  @ViewChild('documentViewerTemplate') documentViewerTemplate: ElementRef;
  contractFileType = BASE_ASSIGNMENT_CONTRACT_FILE;
  documentViewerModalRef: NgbModalRef;
  contractFile: { data: Blob | undefined; fileName: string | undefined };
  private destroy$ = new Subject();
  isPsp: boolean;
  customerLocationName: string;
  validContract: ContractResponse;

  constructor(
    private store: Store<BaseCoreState>,
    private rolesService: BaseRolesService,
    private modalService: BaseModalService,
    private baseSignatureService: BaseSignatureService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.assignment?.currentValue) {
      this.validContract = this.assignment.contracts?.find((contract) => contract.valid) as ContractResponse;
    }
  }

  ngOnInit() {
    this.baseSignatureService.baseSignContractsInfoCardIsInitSubject.next(true);

    this.isPsp = this.rolesService.isPsp();
    if (!this.isPsp) {
      this.customerLocationName = (this.assignment as AssignmentCustomerResponse).customerLocation?.name || '';
    }

    this.store
      .select(baseAssignmentsSelectors.getDocumentView)
      .pipe(
        filter((state) => !!state.data),
        takeUntil(this.destroy$)
      )
      .subscribe((document) => {
        this.contractFile = document;
        this.openDocumentViewer();
      });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.baseSignatureService.baseSignContractsInfoCardIsInitSubject.next(false);
  }

  onDownload(): void {
    this.store.dispatch(
      baseAssignmentsActions.viewContract({
        payload: { assignment: this.assignment.id, contract: this.validContract.id },
      })
    );
  }

  private openDocumentViewer(): void {
    this.documentViewerModalRef = this.modalService.open(
      {
        bodyTemplate: this.documentViewerTemplate,
        cancelButtonDisplayed: false,
        okButtonDisplayed: false,
        hideHeader: true,
        hideFooter: true,
      },
      { size: 'lg' }
    );
    this.documentViewerModalRef.result.then(() => {
      this.store.dispatch(baseAssignmentsActions.clearDocumentView());
    }, noop);
  }
}
