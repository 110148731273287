import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { takeUntil, filter, map } from 'rxjs/operators';
import { baseAssignmentsActions, BaseCoreState, baseAssignmentsSelectors } from '../../../../../../_store';
import { AssignmentCustomerResponse, ContractResponse, AssignmentPspResponse } from '../../../../../../../api';
import { BaseFormatDatePipe } from '../../../../../pipes/base-format-date.pipe';
import { BaseModalService } from '../../../../../services/base-modal.service';
import { BaseButtonSize } from '../../../../button/base-button.component';
import { BaseRolesService } from '../../../../../../core/services/base-roles.service';
import { BaseAvailableFileTypeEnum } from '../../../../../enums/base-available-file-type.enum';

@Component({
  selector: 'base-contract-viewer-toolbar',
  templateUrl: './base-contract-viewer-toolbar.component.html',
})
export class BaseContractViewerToolbarComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('regenerateWarningRef', { static: true }) regenerateWarningRef: ElementRef;
  @Input() src: Blob | undefined;
  @Input() fileName: string | undefined = '';
  @Input() modalRef: NgbModalRef | undefined;
  @Input() isContractValid: boolean;
  @Output() isContractValidChange = new EventEmitter<boolean>();
  @Output() downloadImage = new EventEmitter<any>();
  assignment: AssignmentCustomerResponse | AssignmentPspResponse;
  contractsList: ContractResponse[] = [];
  selectedContract: ContractResponse | undefined;
  createdByMessage: string;
  isShowContractPreview = false;
  sanitizedSrc: SafeResourceUrl | null = null;
  isShowRegenerateButton = false;
  validContract: ContractResponse;

  buttonSizes = BaseButtonSize;
  protected readonly BaseButtonSize = BaseButtonSize;
  private destroy$ = new Subject();

  constructor(
    private readonly store: Store<BaseCoreState>,
    private readonly formatDatePipe: BaseFormatDatePipe,
    private readonly translateService: TranslateService,
    public readonly modalService: BaseModalService,
    private readonly sanitizer: DomSanitizer,
    private readonly roleService: BaseRolesService
  ) {}

  ngOnInit() {
    this.store
      .select(baseAssignmentsSelectors.getAssignment)
      .pipe(
        takeUntil(this.destroy$),
        filter((assignmentState) => assignmentState.ok === true),
        map((assignmentState) => assignmentState.data)
      )
      .subscribe((assignment) => {
        const isFirstAssignmentInit = !this.assignment;

        this.assignment = assignment;
        this.contractsList = assignment.contracts as ContractResponse[];
        this.validContract = this.contractsList.find((contract) => contract.valid) as ContractResponse;
        this.updateSelectedContractData(this.validContract);

        if (!isFirstAssignmentInit) {
          this.getContract(this.validContract.id);
        }
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.src) {
      this.sanitizedSrc = this.getSanitizedSrc(changes?.src.currentValue);
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }

  getSanitizedSrc(src: Blob): SafeResourceUrl {
    const blobUrl = `${URL.createObjectURL(src)}#toolbar=0`;
    return this.sanitizer.bypassSecurityTrustResourceUrl(blobUrl);
  }

  updateSelectedContractInfo(contract: ContractResponse) {
    this.updateSelectedContractData(contract);
    this.getContract(contract.id);
  }

  updateSelectedContractData(contract: ContractResponse) {
    this.selectedContract = contract;
    this.isShowRegenerateButton =
      this.selectedContract.valid !== false && this.selectedContract.signingStatus !== 'signed';
    this.isContractValid = contract?.valid ?? false;
    this.isContractValidChange.emit(this.isContractValid);
    this.createdByMessage = this.getActiveContractMessage(contract);
  }

  handleContractRegeneration() {
    this.modalService
      .open(
        {
          headText: 'contractRegeneration.title',
          bodyTemplate: this.regenerateWarningRef,
          okText: 'contractRegeneration.regenerateActionText',
        },
        {
          size: 'lg',
          backdrop: 'static',
        }
      )
      .result.then(
        () => {
          this.regenerateContract();
        },
        () => {
          this.isShowContractPreview = false;
        }
      );
  }

  regenerateContract() {
    if (this.roleService.isPsp()) {
      this.store.dispatch(
        baseAssignmentsActions.regeneratePspAssignmentContract({
          payload: {
            assignment: this.assignment.id,
          },
        })
      );
    } else {
      this.store.dispatch(
        baseAssignmentsActions.regenerateCustomerAssignmentContract({
          payload: {
            assignment: this.assignment.id,
          },
        })
      );
    }
  }

  contractListChange(value: ContractResponse) {
    this.updateSelectedContractInfo(value);
  }

  getActiveContractMessage(value: ContractResponse): string {
    return this.translateService.instant(
      'contractRegeneration.createdByMessage',
      value.regeneratedBy
        ? {
            creator: `${value.regeneratedBy.firstName} ${value.regeneratedBy.lastName}`,
            date: this.formatDatePipe.transform(value.createdAt),
          }
        : {
            creator: this.translateService.instant('contractRegeneration.system'),
            date: this.formatDatePipe.transform(value.createdAt),
          }
    );
  }

  getContract(contractId: number) {
    this.store.dispatch(
      baseAssignmentsActions.viewContract({
        payload: { assignment: this.assignment.id, contract: contractId },
      })
    );
  }

  toggleContractPreview() {
    this.isShowContractPreview = !this.isShowContractPreview;
  }

  downloadContract(): void {
    if (!this.selectedContract?.id || !this.src) {
      return;
    }

    this.createDownloadLink({
      contract: this.src,
      fileName: this.fileName || '',
    });
  }

  createDownloadLink(data: { contract: Blob; fileName: string }) {
    const blob = new Blob([data.contract], { type: BaseAvailableFileTypeEnum.applicationPDF });
    const objectUrl = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', objectUrl);
    link.setAttribute('download', data.fileName);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
