export enum BaseInquiryBubbleClasses {
  Default = 'empty-bubble position-relative',
  NotAvailable = 'bubble bubble-not-available dark-blue position-relative',
  NotAvailableDot = 'bubble-dot bubble-not-available dark-blue position-relative',
  AvailableInactive = 'bubble dark-grey position-relative',
  AvailableInactiveDot = 'bubble-dot dark-grey position-relative',
  AvailableActive = 'bubble green pointer position-relative',
  AvailableActiveDot = 'bubble-dot green pointer position-relative',
  AvailableDeselectedFixed = 'bubble bubble-framed dark-grey pointer position-relative',
  AvailableDeselectedFlex = 'bubble  bubble-framed dark-grey pointer position-relative',
  AvailableDeselectedFlexCircle = 'bubble-circle dark-grey pointer position-relative',
  Booked = 'bubble primary position-relative',
  BookedDot = 'bubble-dot light-green position-relative',
  Withdrawn = 'bubble bubble-withdrawn position-relative red',
  WithdrawnDot = 'bubble-dot bubble-withdrawn position-relative red',
  Holiday = 'bubble bubble-holiday pink position-relative',
  HolidayDot = 'bubble-dot bubble-holiday pink position-relative',
  Sick = 'bubble bubble-sick pink position-relative',
  SickDot = 'bubble-dot bubble-sick pink position-relative',
  AssignedOutside = 'assigned-outside-icon bubble pink position-relative',
  AssignedOutsideDot = 'assigned-outside-icon bubble-dot pink position-relative',
  Other = 'bubble bubble-other pink position-relative',
  OtherDot = 'bubble-dot bubble-other pink position-relative',
}
