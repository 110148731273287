import { Pipe, PipeTransform } from '@angular/core';
import { OfferPositionResponse, InquiryDateResponse } from '../../../api';
import { BaseInquiryShiftsWithFlex, BaseInquiryShifts } from '../../modules/inquiry/_enums/base-inquiry.enum';
import { BaseInquiryService } from '../../modules/inquiry/_services/base-inquiry.service';

@Pipe({ name: 'baseShiftCheckExisting' })
export class BaseShiftCheckExistingPipe implements PipeTransform {
  constructor(private baseInquiryService: BaseInquiryService) {}

  transform(
    shiftType: BaseInquiryShiftsWithFlex,
    positions: OfferPositionResponse[] | InquiryDateResponse[],
    isFlex = false
  ): boolean {
    if (shiftType === BaseInquiryShiftsWithFlex.FLEX) {
      return this.baseInquiryService.checkIsFlexShiftsExist(positions);
    }

    return this.baseInquiryService.checkIsShiftsExist(shiftType as unknown as BaseInquiryShifts, positions, isFlex);
  }
}
