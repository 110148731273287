export enum InquiryBestCandidatesPagination {
  size = 25,
  page = 1,
}

export enum InquiryBestCandidateRenderPagination {
  size = 6,
  page = 1,
}

export enum InquiryOffersPagination {
  size = 6,
  page = 1,
}
