import { Component, Input, forwardRef, ViewChild, ElementRef, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'base-radiobutton',
  templateUrl: './base-radiobutton.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BaseRadiobuttonComponent),
      multi: true,
    },
  ],
})
export class BaseRadiobuttonComponent implements ControlValueAccessor, OnInit {
  current: string | number | boolean | null = null;
  @ViewChild('radiobutton') radioButton: ElementRef;
  @Input() label = '';
  @Input() disabled = false;
  @Input() name = '';
  @Input() value: string | number | boolean | null = null;
  @Input() isChecked: boolean;
  @Input() isFormGroup = false;
  @Input() tabindex: number | null = null;
  @Input() isFavourite = false;
  @Input() attachedClass = '';
  @Input() isFullyClickable = false;

  id = '';
  private onChange = (_: any) => {};
  private onTouched = () => {};

  ngOnInit(): void {
    this.getUniqueId();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => {}): void {
    this.onTouched = fn;
  }

  writeValue(outsideValue: string | number | boolean | null): void {
    this.current = outsideValue;
  }

  outerDivClick(event: Event): void {
    if (this.isFullyClickable) {
      this.setChecked(this.value);
      event.stopPropagation();
    }
  }

  setChecked(value: string | number | boolean | null, event?: Event) {
    if (!this.isFullyClickable) {
      event?.stopPropagation();
    }
    this.current = value;
    this.onChange(this.current);
    this.onTouched();
  }

  private getUniqueId() {
    this.id = 'rb' + uuidv4();
    this.id.replace('-', '');
  }
}
