import {
  AfterViewInit,
  Component,
  DoCheck,
  ElementRef,
  forwardRef,
  Input,
  Renderer2,
  ViewChild,
  TemplateRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';
import { AbstractFormComponent } from '../_abstract-classes/abstract-form-component';

@Component({
  selector: 'base-password',
  templateUrl: './base-password.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BasePasswordComponent),
      multi: true,
    },
  ],
})
export class BasePasswordComponent
  extends AbstractFormComponent
  implements ControlValueAccessor, DoCheck, AfterViewInit
{
  @ViewChild('input')
  public element: ElementRef;
  private value = '';
  type = 'password';
  errorKeys: string[] = [];
  valid = true;
  @Input() isRequired = false;
  @Input() errors: ValidationErrors | null;
  @Input() errorMessage: string | null = '';
  @Input() errorMessageOverride: any;
  @Input() isInvalid = false;
  @Input() invalidIcon = '';
  @Input() isEyeIcon = false;
  @Input() label: string;
  @Input() placeholder: string = '';
  @Input() tabindex: number | null = null;
  @Input() autoCompleteValue = 'on';
  @Input() isLabelPositionLeft = false;
  @Input() labelTooltip: TemplateRef<any>;
  @Input() tooltipClass = '';
  @Input() tooltipPlacement = 'top';
  private onChange = (_value: any) => {};
  private onTouched = () => {};

  constructor(protected renderer: Renderer2) {
    super(renderer);
  }

  public ngDoCheck(): void {
    this.setNgClass();
    this.prepareErrors();
  }

  public ngAfterViewInit(): void {
    this.setNgClass();
    this.prepareErrors();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => {}): void {
    this.onTouched = fn;
  }

  writeValue(outsideValue: string): void {
    this.value = outsideValue;
  }

  set password(value: string) {
    this.value = value;
    this.onChange(this.value);
    this.onTouched();
  }

  get password(): string {
    return this.value;
  }

  getErrorObject(key: string) {
    return { [key]: true };
  }

  get isVisible(): boolean {
    return this.type === 'text';
  }

  changeVisibility(): void {
    this.type = this.isVisible ? 'password' : 'text';
  }

  private prepareErrors() {
    if (this.errors) {
      this.errorKeys = Object.keys(this.errors);
    }
  }
}
