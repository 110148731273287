import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseButtonSize } from '../button/base-button.component';
import { AbstractControl } from '@angular/forms';
import { BaseAvailableFileTypeEnum } from '../../enums/base-available-file-type.enum';

@Component({
  selector: 'base-document-info',
  templateUrl: './base-document-info.component.html',
})
export class BaseDocumentInfoComponent implements OnInit {
  @Output() viewDocumentClicked = new EventEmitter<boolean>();
  @Output() deleteClicked = new EventEmitter();
  @Input() editCandidate = false;
  @Input() documentControl: AbstractControl;
  @Input() errorMessage: string;
  @Input() value: any;
  buttonSize = BaseButtonSize;
  availableFileTypes = BaseAvailableFileTypeEnum;

  ngOnInit() {
    if (this.documentControl) {
      this.value = this.documentControl.value;
    }
  }
}
