import { Component, OnInit, Input, TemplateRef, ViewContainerRef } from '@angular/core';

@Component({
  selector: 'base-modal-body[content]',
  template: ``,
})
export class BaseModalBodyComponent implements OnInit {
  @Input() content: TemplateRef<any> | any;

  constructor(private viewContainer: ViewContainerRef) {}

  ngOnInit(): void {
    if (this.content) {
      this.viewContainer.createEmbeddedView(this.content);
    }
  }
}
