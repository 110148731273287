<div class="k-document-upload">
  <ng-container *ngIf="!multipleUpload">
    <div class="actions">
      <ng-container
        *ngIf="!value || !showMeta"
        [ngTemplateOutlet]="dragDropClickTrigger"
      ></ng-container>
      <base-document-info
        *ngIf="value && showMeta"
        (viewDocumentClicked)="viewDocument()"
        (deleteClicked)="clear()"
      ></base-document-info>
    </div>
  </ng-container>

  <ng-container *ngIf="multipleUpload">
    <div
      class="p-3 d-flex"
      *ngIf="value && value.length"
    >
      <div class="col-8 fw-bold">{{ 'name' | translate }}</div>
      <div class="col-4 fw-bold">{{ 'document.fileSize' | translate }}</div>
    </div>
    <div *ngFor="let file of value; index as i; last as last">
      <base-document-info
        *ngIf="value && value.length"
        [value]="file"
        (viewDocumentClicked)="downloadDocument(i)"
        (deleteClicked)="clear(i)"
      ></base-document-info>
    </div>
    <div *ngFor="let file of value; index as i; last as last">
      <base-document-info
        *ngIf="value && value.length"
        (viewDocumentClicked)="downloadDocument(i)"
        (deleteClicked)="clear(i)"
      ></base-document-info>
    </div>
    <div
      class="actions"
      [ngClass]="{ 'text-end': isShowUploadButton }"
    >
      <base-special-button
        *ngIf="isShowUploadButton"
        [outlined]="true"
        color="primary"
        label="document.addNew"
        icon="fa-solid fa-plus"
        (click)="file.click()"
        [iconBold]="false"
        [tabindex]="tabindex"
        class="d-inline-block"
      ></base-special-button>
      <ng-container
        *ngIf="!hideDragNDropArea"
        [ngTemplateOutlet]="dragDropClickTrigger"
      ></ng-container>
    </div>
  </ng-container>
</div>

<ng-template #dragDropClickTrigger>
  <div
    class="d-flex flex-column p-5 align-items-center drag-drop-click-trigger pointer"
    (click)="file.click()"
    (dragover)="$event.preventDefault()"
    (drop)="onDrop($event)"
    [ngClass]="{ 'invalid-document': isInvalid }"
  >
    <span class="fs-4 mb-2 lh-sm">{{ 'document.dragDrop.description' | translate }}</span>
    <span class="file-info lh-sm"
      >{{ 'document.dragDrop.fileTypes' | translate }}<br />{{ 'document.dragDrop.maxFileSize' | translate }}</span
    >
  </div>
  <p
    class="text-small text-danger"
    *ngIf="this.isInvalid && errorMessage"
  >
    {{ errorMessage | translate }}
  </p>
</ng-template>
<input
  type="file"
  [accept]="acceptedFileTypes.join(',')"
  #file
  (change)="addFileManually($event)"
/>
