import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ShiftTimeResponse, ShiftType } from '../../../../api';
import { BaseInquiryShiftsWithFlex, BaseShiftTimeIndexes, ShiftTimeAndFlexResponse } from '../_enums/base-inquiry.enum';

@Injectable()
export class BaseInquiryShiftService {
  shiftTimesWithFlexSubject$: BehaviorSubject<(ShiftTimeAndFlexResponse & { index: number; label?: string })[]> =
    new BehaviorSubject<(ShiftTimeAndFlexResponse & { index: number; label?: string })[]>([]);

  addFlexShiftToShiftTimes(shiftTimes: (ShiftTimeResponse & { index: number; label?: string })[]) {
    return [
      {
        endTime: '',
        startTime: '',
        index: -1,
        break: 0,
        label: 'inquiry.flexibleShifts',
        type: BaseInquiryShiftsWithFlex.FLEX,
      },
      ...shiftTimes,
    ];
  }

  addIndexAndLabelToShiftTimes(shiftTimes: ShiftTimeResponse[]) {
    const newShiftTimes: (ShiftTimeResponse & { index: number; label?: string })[] = [];
    shiftTimes?.forEach((item: ShiftTimeResponse) => {
      newShiftTimes.push({
        ...item,
        index: BaseShiftTimeIndexes[item.type as ShiftType],
        label: `shiftTimes.label.${item.type}`,
      });
    });

    return newShiftTimes;
  }

  sortShiftTimes(shiftTimes: (ShiftTimeResponse & { index: number; label?: string })[]) {
    return shiftTimes.sort((a, b) => (a.index > b.index ? 1 : -1));
  }
}
