import { createAction, props } from '@ngrx/store';
import {
  BranchListItemResponse,
  PermissionItemResponse,
  PspBranchCreateRequest,
  PspBranchResponse,
} from '../../../api';

export const loadPspBranchList = createAction('[Psp Branch] Load list', props<{ payload: { orgId: number } }>());
export const setPspBranchList = createAction(
  '[Psp Branch] Set list',
  props<{ payload: Array<BranchListItemResponse> }>()
);
export const successPspBranchList = createAction('[Psp Branch] List success');
export const errorPspBranchList = createAction('[Psp Branch] List error');
export const setPspBranchActiveCrud = createAction(
  '[Psp Branch] Set active status',
  props<{ payload: { branchId: number; setActive: boolean } }>()
);
export const createPspBranchCrud = createAction(
  '[Psp Branch] Create branch',
  props<{ payload: { orgId: number; body: PspBranchCreateRequest } }>()
);
export const successPspBranchCrud = createAction('[Psp Branch] Crud success');
export const errorPspBranchCrud = createAction('[Psp Branch] Crud error');
export const loadPspUserList = createAction(
  '[Psp Branch] Load user list',
  props<{ payload: { orgId: string } | undefined }>()
);
export const setPspUserList = createAction(
  '[Psp Branch] Set user list',
  props<{ payload: PermissionItemResponse[] | undefined }>()
);
export const successPspUserList = createAction('[Psp Branch] User list success');
export const errorPspUserList = createAction('[Psp Branch] User list error');

export const addBranchUsers = createAction(
  '[Psp Branch] Add user to PSP-branch',
  props<{ payload: { psp: number; branch: number; users: number[] } }>()
);
export const deleteBranchUser = createAction(
  '[Psp Branch] Delete user from PSP-branch',
  props<{ payload: { psp: number; branch: number; user: number } }>()
);
export const updateBranchUsers = createAction(
  '[Psp Branch] Update PSP-branch users',
  props<{ payload: { psp: number; branch: number; users: number[] } }>()
);

export const loadBranch = createAction(
  '[Psp Branch] Load Branch data',
  props<{ payload: { psp: number; branch: number } }>()
);

export const updateBranch = createAction(
  '[Psp Branch] Update branch',
  props<{ payload: { psp: number; branch: number; body: PspBranchResponse } }>()
);

export const setBranch = createAction('[Psp Branch] Set Branch data to store', props<{ payload: PspBranchResponse }>());
export const successBranch = createAction('[Psp Branch] Success branch');
export const errorBranch = createAction('[Psp Branch] Error branch');
export const resetBranch = createAction('[Psp Branch] Reset branch');

export const setBranchInactiveFilter = createAction(
  '[Shared] Set filter for inactive branches',
  props<{ filterActive: boolean }>()
);
