<base-unbranded-wrapper>
  <img
    height="100"
    [src]="appLogoUrls[application]"
    alt="stazzle Logo"
  />
  <div class="d-flex flex-column">
    <span class="headline-28 fw-medium">{{ 'auth.password.addNew' | translate }}</span>
    <span class="headline-28 fw-medium">{{ 'auth.password.differ' | translate }}</span>
  </div>
  <form
    class="d-flex flex-column gap-3"
    [formGroup]="resetForm"
    [ngClass]="{ 'was-validated': submitted }"
  >
    <base-password
      [isEyeIcon]="true"
      [isRequired]="true"
      label="auth.password.new"
      [isInvalid]="!!resetForm.touched && !!resetForm.get('password')?.errors"
      [errors]="resetForm.get('password')?.errors"
      [errorMessageOverride]="{ required: 'password' }"
      formControlName="password"
    ></base-password>
    <base-password
      [isEyeIcon]="true"
      [isRequired]="true"
      label="auth.password.repeat"
      [isInvalid]="!!resetForm.touched && !!resetForm.get('repeatPassword')?.errors"
      [errors]="resetForm.get('repeatPassword')?.errors"
      [errorMessageOverride]="{ required: 'confirmPasswordRequired' }"
      formControlName="repeatPassword"
    ></base-password>
  </form>
  <div class="d-flex justify-content-between flex-wrap action-buttons">
    <base-button
      (click)="cancel()"
      label="action.cancel"
      [size]="buttonSize.md"
      color="secondary"
    ></base-button>
    <base-button
      (click)="submit()"
      label="auth.password.confirmPassword"
      [size]="buttonSize.md"
      color="primary"
    ></base-button>
  </div>
</base-unbranded-wrapper>
