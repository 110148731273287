import { AbstractControl } from '@angular/forms';
import { BaseFileTypes } from '../constants/base-file-types';

export const baseFiletypeValidator = () => {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    const controlValue = control.value;
    if (controlValue) {
      if (controlValue.length) {
        const fileType = controlValue[controlValue.length - 1].type;
        if (!BaseFileTypes.includes(fileType)) {
          control.setValue(controlValue.slice(0, -1));
          return {
            fileTypeInvalid: true,
          };
        } else {
          return null;
        }
      } else {
        return BaseFileTypes.includes(controlValue.type) ? null : { fileTypeInvalid: true };
      }
    }
    return null;
  };
};
