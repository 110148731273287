<ng-container
  *ngTemplateOutlet="
    state === baseInquiryType.STATUS_CREATED ||
    (!confirmedOffers && !openOffers && !declinedOffers && !needConfirmationOffers)
      ? showHyphenIfEmpty
        ? emptyOffers
        : undefined
      : offerCircles
  "
></ng-container>

<ng-template #emptyOffers>
  <div class="empty-offers">
    <i
      [ngbTooltip]="tooltipEmptyOffers"
      class="fas fa-hyphen"
    ></i>
  </div>
</ng-template>

<ng-template #offerCircles>
  <div>
    <span
      class="circle-sm confirmed"
      [ngbTooltip]="tooltipOfferCountAccepted"
      [ngClass]="{ 'empty-offer': !confirmedOffers }"
    >
      {{ !!confirmedOffers ? confirmedOffers : '' }}
    </span>
    <span
      class="circle-sm needs-confirmation"
      [ngbTooltip]="tooltipOfferCountNeedsConfirmation"
      [ngClass]="{ 'empty-offer': !needConfirmationOffers }"
    >
      {{ !!needConfirmationOffers ? needConfirmationOffers : '' }}
    </span>
  </div>
  <div>
    <div
      class="circle-sm open"
      [ngbTooltip]="tooltipOfferCountOpen"
      [ngClass]="{ 'empty-offer': !openOffers }"
    >
      {{ !!openOffers ? openOffers : '' }}
    </div>
    <div
      class="circle-sm declined"
      *ngIf="areDeclinedOffersAvailable"
      [ngbTooltip]="tooltipOfferCountDeclined"
      [ngClass]="{ 'empty-offer': !declinedOffers }"
    >
      {{ !!declinedOffers ? declinedOffers : '' }}
    </div>
  </div>
</ng-template>

<ng-template #tooltipEmptyOffers>
  <p class="m-1">{{ 'inquiry.noOffers' | translate }}</p>
</ng-template>

<ng-template #tooltipOfferCountAccepted>
  <p class="m-1">{{ 'inquiry.confirmed' | translate }}</p>
</ng-template>

<ng-template #tooltipOfferCountNeedsConfirmation>
  <p class="m-1">{{ 'inquiry.needsConfirmation' | translate }}</p>
</ng-template>

<ng-template #tooltipOfferCountOpen>
  <p class="m-1">{{ 'dashboardInfo.chartOffered' | translate }}</p>
</ng-template>

<ng-template #tooltipOfferCountDeclined>
  <p class="m-1">{{ 'inquiry.declined' | translate }}</p>
</ng-template>
