<base-header
  [logo]="logo"
  [sections]="applicationSections"
>
  <ng-container *ngIf="isFullscreen; else mainHeaderContent">
    <base-button
      baseBackButton
      [size]="buttonSize"
      [iconSize]="buttonSize"
      color="dark"
      icon="fa-solid fa-close"
    ></base-button>
  </ng-container>
  <ng-template #mainHeaderContent>
    <base-user-nav [userMenu]="userMenu"></base-user-nav>
  </ng-template>
</base-header>
<div class="d-flex justify-content-start">
  <base-sidebar
    class="d-xl-block d-none"
    [logo]="logo"
    [applicationSections]="applicationSections"
    *ngIf="!isFullscreen"
  ></base-sidebar>
  <section class="content">
    <router-outlet></router-outlet>
  </section>
</div>
