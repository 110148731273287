import produce from 'immer';
import {
  AssignmentCustomerResponse,
  AssignmentDateListItemResponse,
  AssignmentPspResponse,
  CustomerAssignmentFilterListsResponse,
  CustomerAssignmentListItemResponse,
  CustomerProfileAssignmentItemResponse,
  CustomerProfileRatingListResponse,
  CustomerResponse,
  PaginatedApiResponse,
  PspAssignmentListItemResponse,
  CustomerSignVoucherResponse,
} from '../../../api';
import { BaseDateService } from '../../shared/services/base-date.service';
import { baseAssignmentsActions } from '../actions';

export interface AssignmentsState {
  list: {
    data:
      | (PaginatedApiResponse & { data?: PspAssignmentListItemResponse[] | CustomerAssignmentListItemResponse[] })
      | undefined;
    ok: boolean | undefined;
  };
  allCustomerAssignments: {
    data:
      | (PaginatedApiResponse & {
          data?: CustomerAssignmentListItemResponse[];
          filters?: CustomerAssignmentFilterListsResponse;
        })
      | undefined;
    ok: boolean | undefined;
  };
  assignment: {
    data: AssignmentPspResponse | AssignmentCustomerResponse | undefined;
    ok: boolean | undefined;
  };
  dates: {
    data: AssignmentDateListItemResponse[] | undefined;
    ok: boolean | undefined;
  };
  customer: {
    data: CustomerResponse | undefined;
    ok: boolean | undefined;
  };
  downloadDocument: {
    data: Blob | undefined;
    fileName?: string | undefined;
  };
  endedAssignments: {
    data: Array<CustomerProfileAssignmentItemResponse> | undefined;
    ok: boolean | undefined;
  };
  candidateEndedAssignments: {
    data: Array<CustomerProfileAssignmentItemResponse> | undefined;
    ok: boolean | undefined;
  };
  reviews: {
    data: CustomerProfileRatingListResponse | undefined;
    ok: boolean | undefined;
  };
  digitalSignVoucher: {
    data: CustomerSignVoucherResponse | undefined;
    ok: boolean | undefined;
  };
  signContractStatus: {
    ok: boolean | undefined;
  };
  customerAssignmentProfileIDCard: {
    data: Blob | undefined;
    fileName: string | undefined;
  };
}

export const ASSIGNMENTS_INITIAL_STATE: AssignmentsState = {
  list: {
    data: undefined,
    ok: undefined,
  },
  allCustomerAssignments: {
    data: undefined,
    ok: undefined,
  },
  assignment: {
    data: undefined,
    ok: undefined,
  },
  dates: {
    data: undefined,
    ok: undefined,
  },
  customer: {
    data: undefined,
    ok: undefined,
  },
  downloadDocument: {
    data: undefined,
    fileName: undefined,
  },
  endedAssignments: {
    data: undefined,
    ok: undefined,
  },
  candidateEndedAssignments: {
    data: undefined,
    ok: undefined,
  },
  reviews: {
    data: undefined,
    ok: undefined,
  },
  digitalSignVoucher: {
    data: undefined,
    ok: undefined,
  },
  signContractStatus: {
    ok: undefined,
  },
  customerAssignmentProfileIDCard: {
    data: undefined,
    fileName: undefined,
  },
};

export function BaseAssignmentsReducer(state: AssignmentsState = ASSIGNMENTS_INITIAL_STATE, action: any): any {
  return produce(state, (draftState) => {
    switch (action.type) {
      // Assignment list
      case baseAssignmentsActions.loadAssignmentList.type:
        draftState.list.ok = undefined;
        draftState.list.data = undefined;
        return;

      case baseAssignmentsActions.setAssignmentList.type:
        draftState.list.data = action.payload;
        draftState.list.ok = true;
        return;

      case baseAssignmentsActions.errorAssignmentList.type:
        draftState.list.ok = false;
        return;

      // Assignment details
      case baseAssignmentsActions.loadAssignment.type:
        draftState.assignment = { data: undefined, ok: undefined };
        return;

      case baseAssignmentsActions.resetAssignment.type:
        draftState.assignment.data = state.assignment.data;
        return;

      case baseAssignmentsActions.clearAssignment.type:
        draftState.assignment = ASSIGNMENTS_INITIAL_STATE.assignment;
        return;

      case baseAssignmentsActions.errorLoadAssignment.type:
        draftState.assignment.ok = false;
        return;

      case baseAssignmentsActions.successLoadAssignment.type:
        draftState.assignment.data = action.payload;
        draftState.assignment.ok = true;
        return;

      // Customer all assignments
      case baseAssignmentsActions.loadCustomerAllAssignments.type:
        draftState.allCustomerAssignments.ok = undefined;
        return;

      case baseAssignmentsActions.setCustomerAllAssignments.type:
        draftState.allCustomerAssignments.data = action.payload;
        return;

      case baseAssignmentsActions.successCustomerAllAssignment.type:
        draftState.allCustomerAssignments.ok = true;
        return;

      case baseAssignmentsActions.errorCustomerAllAssignment.type:
        draftState.allCustomerAssignments.ok = false;
        return;

      // Assignment dates
      case baseAssignmentsActions.loadAssignmentDates.type:
        draftState.dates = { data: undefined, ok: undefined };
        return;

      case baseAssignmentsActions.setAssignmentDates.type:
        draftState.dates.data = action.payload;
        return;

      case baseAssignmentsActions.successAssignmentDates.type:
        draftState.dates.ok = true;
        return;

      case baseAssignmentsActions.errorAssignmentDates.type:
        draftState.dates.ok = false;
        return;

      // Document view
      case baseAssignmentsActions.setDocumentView.type:
        draftState.downloadDocument.data = action.payload.document;
        draftState.downloadDocument.fileName = action.payload.fileName;
        return;

      case baseAssignmentsActions.clearDocumentView.type:
        draftState.downloadDocument.data = undefined;
        draftState.downloadDocument.fileName = undefined;
        return;

      // Candidate ended assignments
      case baseAssignmentsActions.getCandidateEndedAssignments.type:
        draftState.candidateEndedAssignments.ok = undefined;
        return;

      case baseAssignmentsActions.loadCandidateEndedAssignments.type:
        draftState.candidateEndedAssignments.data = action.payload;
        return;

      case baseAssignmentsActions.loadCandidateEndedAssignmentsSuccess.type:
        draftState.candidateEndedAssignments.ok = true;
        return;

      case baseAssignmentsActions.loadCandidateEndedAssignmentsError.type:
        draftState.candidateEndedAssignments.ok = false;
        return;

      // Customer reviews
      case baseAssignmentsActions.getCustomerReviews.type:
        draftState.reviews.ok = undefined;
        return;

      case baseAssignmentsActions.loadCustomerReviews.type:
        draftState.reviews.data = action.payload;
        return;

      case baseAssignmentsActions.loadCustomerReviewsSuccessfully.type:
        draftState.reviews.ok = true;
        return;

      case baseAssignmentsActions.loadCustomerReviewsError.type:
        draftState.reviews.ok = false;
        return;

      case baseAssignmentsActions.clearCustomerReviews.type:
        draftState.reviews = ASSIGNMENTS_INITIAL_STATE.reviews;
        return;

      case baseAssignmentsActions.assignmentCancellationSuccess.type:
        draftState.assignment.data = {
          ...draftState.assignment.data,
          // @ts-ignore
          assignmentCancelation: {
            ...(draftState.assignment.data?.assignmentCancelation || {}),
            cancelationDate: BaseDateService.format(new Date(), 'yyyy-MM-dd'),
          },
        };
        return;

      case baseAssignmentsActions.updateAssignmentDate.type:
        // @ts-ignore
        const index = (draftState.dates.data.data || []).findIndex((date) => date.id === action.payload.id);
        // @ts-ignore
        draftState.dates.data.data[index] = action.payload;
        draftState.dates.data = {
          ...draftState.dates.data,
          // @ts-ignore
          data: [...draftState.dates.data.data],
        };
        return;

      // Assignment customer
      case baseAssignmentsActions.loadAssignmentCustomer.type:
        draftState.customer.ok = undefined;
        return;

      case baseAssignmentsActions.setAssignmentCustomer.type:
        draftState.customer.data = action.payload;
        draftState.customer.ok = true;
        return;

      case baseAssignmentsActions.errorAssignmentCustomer.type:
        draftState.customer.ok = false;
        return;

      // Digital Sign Voucher
      case baseAssignmentsActions.loadDigitalSignVoucher.type:
        draftState.digitalSignVoucher.ok = undefined;
        return;

      case baseAssignmentsActions.setDigitalSignVoucher.type:
        draftState.digitalSignVoucher.data = action.payload;
        return;

      case baseAssignmentsActions.successDigitalSignVoucher.type:
        draftState.digitalSignVoucher.ok = true;
        return;

      case baseAssignmentsActions.errorDigitalSignVoucher.type:
        draftState.digitalSignVoucher.ok = false;
        return;

      // Sign contract
      case baseAssignmentsActions.clearContractStatus.type:
        draftState.signContractStatus.ok = undefined;
        return;

      case baseAssignmentsActions.signContractSuccess.type:
        draftState.signContractStatus.ok = true;
        return;

      case baseAssignmentsActions.signContractError.type:
        draftState.signContractStatus.ok = false;
        return;

      case baseAssignmentsActions.setCustomerAssignmentProfileIDCardView.type:
        draftState.customerAssignmentProfileIDCard.data = action.payload.document;
        draftState.customerAssignmentProfileIDCard.fileName = action.payload.fileName;
        return;

      case baseAssignmentsActions.clearCustomerAssignmentProfileIDCardView.type:
        draftState.customerAssignmentProfileIDCard.data = undefined;
        draftState.customerAssignmentProfileIDCard.fileName = undefined;
        return;
    }
  });
}
