import { Inject, Injectable } from '@angular/core';
import { ENVIRONMENTER } from '../../core/token/base-environmenter.token';

@Injectable()
export class BaseEnvironmenter {
  constructor(@Inject(ENVIRONMENTER) private environment: any) {}

  public getGlobalEnvironment(): any {
    return this.environment.global;
  }

  public getApplicationEnvironment(): any {
    return this.environment.application;
  }

  public getEnvironment(): object {
    const { global, application } = this.environment;

    return {
      ...global,
      ...application,
    };
  }
}
