import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'base-imprint-page',
  templateUrl: './base-imprint.component.html',
})
export class BaseImprintComponent {
  constructor(public translateService: TranslateService) {}
}
