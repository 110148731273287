import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  CustomerBranchListItemResponse,
  CustomerPspService,
  DocumentListItemResponse,
  PaginatedApiResponse,
  PspBranchResponse,
  PspResponse,
  SurchargeListItemResponse,
} from '../../../api';
import { BaseSharedHelperService } from '../../shared/services/base-shared-helper.service';

@Injectable()
export class BaseRelatedPspBranchesService {
  constructor(private customerPspService: CustomerPspService) {}

  relatedPspBranchesList(params: {
    page?: any;
    search?: any;
    'customerLocations[]'?: any;
    customer: number;
  }): Observable<PaginatedApiResponse & { data?: CustomerBranchListItemResponse[] }> {
    return this.customerPspService.getCustomerListOfBranches(params);
  }

  relatedPspBranchDetails(params: {
    branch: number;
    location: number;
    customer: number;
    psp: number;
  }): Observable<PspBranchResponse> {
    return this.customerPspService.getCustomerPspBranch(params).pipe(map((response) => response.data));
  }

  relatedPspBranchDocuments(params: { customer: number; psp: number }): Observable<DocumentListItemResponse[]> {
    return this.customerPspService.getCustomerPspDocuments(params).pipe(map((response) => response.data));
  }

  downloadDocument(params: { document: string; customer: number; psp: number }): Observable<any> {
    return this.customerPspService.getCustomerPspDocumentsDownload$Response(params).pipe(
      map((response) => {
        const contentDispositionValue = response.headers.get('Content-Disposition');
        const fileName = BaseSharedHelperService.fileNameFromHeaderValue(contentDispositionValue, '=');
        return {
          body: response.body,
          fileName,
        };
      })
    );
  }

  surchargesList(params: { customer: number; psp: number }): Observable<SurchargeListItemResponse[]> {
    return this.customerPspService.getCustomerPspSurcharges(params).pipe(map((response) => response.data));
  }

  relatedPspBranchData(params: { customer: number; psp: number }): Observable<PspResponse> {
    return this.customerPspService.getCustomerPspShow(params).pipe(map((response) => response.data));
  }

  downloadContract(params: { customer: number; location: number; branch: number }): Observable<Blob> {
    return this.customerPspService.getCustomerPspBranchLocationContractDownload(params);
  }
}
