import { createFeatureSelector, createSelector } from '@ngrx/store';

const getAssignmentsState = createFeatureSelector('assignments');

export const getAssignmentList = createSelector(getAssignmentsState, (state: any) => state.list);
export const getAssignmentListData = createSelector(getAssignmentsState, (state: any) => state.list.data);
export const getAllCustomerAssignments = createSelector(
  getAssignmentsState,
  (state: any) => state.allCustomerAssignments
);
export const getCustomerAssignmentsFilters = createSelector(
  getAssignmentsState,
  (state: any) => state.allCustomerAssignments.data.filters
);
export const getDocumentView = createSelector(
  getAssignmentsState,
  (state: any): { data: Blob | undefined; fileName: string | undefined } => state.downloadDocument
);
export const getAssignmentListStatus = createSelector(getAssignmentsState, (state: any) => state.list.ok);
export const getAssignment = createSelector(getAssignmentsState, (state: any) => state.assignment);
export const getAssignmentData = createSelector(getAssignmentsState, (state: any) => state.assignment.data);
export const getAssignmentDates = createSelector(getAssignmentsState, (state: any) => state.dates);
export const getEndedAssignments = createSelector(getAssignmentsState, (state: any) => state.endedAssignments);
export const getCandidateEndedAssignments = createSelector(
  getAssignmentsState,
  (state: any) => state.candidateEndedAssignments
);
export const getCandidateReviews = createSelector(getAssignmentsState, (state: any) => state.reviews);
export const getDigitalSignVoucher = createSelector(getAssignmentsState, (state: any) => state.digitalSignVoucher);
export const getSignContractStatus = createSelector(
  getAssignmentsState,
  (state: any): boolean | undefined => state.signContractStatus.ok
);
export const getCustomerAssignmentProfileIDCard = createSelector(
  getAssignmentsState,
  (
    state: any
  ): {
    data: Blob | undefined;
    fileName: string | undefined;
  } => state.customerAssignmentProfileIDCard
);
