import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

declare var require: any;
const ibanVal = require('ngx-iban-validator');

export function baseIbanValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const ibanValidation = ibanVal.validateIBAN({
      value: control.value,
    });
    return ibanValidation && ibanValidation.ibanInvalid ? ibanValidation.error : null;
  };
}
