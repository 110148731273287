export const getValueByNestedPath = (obj: Record<string, any>, path: string): any => {
  const pathArray = path.split('.');
  let value = obj;

  for (const key of pathArray) {
    if (value && typeof value === 'object' && key in value) {
      value = value[key];
    } else {
      return undefined; // Property not found
    }
  }

  return value;
};
