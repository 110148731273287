import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Validators, NonNullableFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { BaseTokenService } from '../../../../core/services/base-token.service';
import { BaseButtonSize, BaseButtonType } from '../../../../shared/components/button/base-button.component';
import { BaseInputSizes } from '../../../../shared/components/input/base-input.component';
import { BaseApplication } from '../../../../shared/enums/base-application.enum';
import { BaseModalService } from '../../../../shared/services/base-modal.service';
import { baseEmailValidator } from '../../../../shared/validators/base-email.validator';
import { baseAuthActions, baseAuthSelectors, BaseCoreState } from '../../../../_store';
import { BaseInitialTokenInterface } from '../../../../shared/interfaces/base-token-service.interface';
import { noop, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'base-login-form',
  templateUrl: './base-login-form.component.html',
  styleUrls: ['./base-login-form.component.scss'],
})
export class BaseLoginFormComponent implements OnInit, OnDestroy {
  @ViewChild('resetPasswordTemplate', { static: true }) resetPasswordTemplate: ElementRef;
  @Input() application: BaseApplication = BaseApplication.medical;
  modalRef: NgbModalRef;

  inputSize = BaseInputSizes;
  buttonSize = BaseButtonSize;
  buttonType = BaseButtonType;
  loginFormSubmitted = false;
  resetFormSubmitted = false;
  loginForm = this.fb.group({
    application: [null as BaseApplication | null],
    username: ['', [Validators.required, baseEmailValidator()]],
    password: ['', [Validators.required]],
  });
  resetForm = this.fb.group({
    email: ['', [Validators.required, baseEmailValidator()]],
  });
  isCustomizedUser = false;

  private destroy$ = new Subject();

  constructor(
    private store: Store<BaseCoreState>,
    private router: Router,
    private tokenService: BaseTokenService,
    private modalService: BaseModalService,
    private fb: NonNullableFormBuilder
  ) {}

  ngOnInit(): void {
    const token: string = this.tokenService.getToken();
    if (token && this.tokenService.isValid(token)) {
      this.router.navigate(['/dashboard']);
    }

    this.getCustomizedUser();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }

  login(): void {
    this.loginFormSubmitted = true;

    if (this.loginForm.invalid) {
      return;
    }

    switch (this.application) {
      // Leave switch-case statement here that can be useful in case of adding new applications
      case BaseApplication.growth:
        this.loginForm.get('application')?.setValue(BaseApplication.growth);
        break;
      case BaseApplication.business:
        this.loginForm.get('application')?.setValue(BaseApplication.business);
        break;
      case BaseApplication.medical:
      default:
        this.loginForm.get('application')?.setValue(BaseApplication.medical);
        break;
    }

    const loginFormValue = this.loginForm.getRawValue();
    const payload: BaseInitialTokenInterface = {
      application: loginFormValue.application || BaseApplication.growth,
      username: loginFormValue.username,
      password: loginFormValue.password,
    };

    this.store.dispatch(baseAuthActions.loadToken({ payload }));
  }

  showResetPasswordModal(): void {
    this.resetForm.reset();
    this.modalRef = this.modalService.open(
      {
        headText: 'auth.password.reset',
        bodyText: 'auth.password.resetPassword.description',
        bodyTemplate: this.resetPasswordTemplate,
        okText: 'action.okReset',
        cancelText: 'action.cancel',
        canBeClosed: () => this.submitResetFormModal(),
        cancelButtonClick: () => this.dismissResetFormModal(),
      },
      { size: 'lg' }
    );

    this.modalRef.result.then(() => {
      this.store.dispatch(
        baseAuthActions.resetPasswordEmail({
          payload: { email: this.resetForm.getRawValue().email },
        })
      );
    }, noop);
  }

  submitResetFormModal(): boolean {
    this.resetFormSubmitted = true;

    if (this.resetForm.invalid) {
      return false;
    }

    if (this.resetForm.valid && this.modalRef) {
      this.modalRef.close();
      return true;
    }

    return false;
  }

  dismissResetFormModal(): void {
    if (this.modalRef) {
      this.modalRef.dismiss();
    }
  }

  getCustomizedUser(): void {
    this.store
      .select(baseAuthSelectors.getCustomizedUserStatus)
      .pipe(
        takeUntil(this.destroy$),
        filter((status) => status !== undefined),
        map((status) => status as boolean)
      )
      .subscribe((status) => {
        this.isCustomizedUser = status;
      });
  }
}
