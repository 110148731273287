import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator } from '@angular/forms';
import { NgSelectConfig } from '@ng-select/ng-select';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { BaseCoreState, baseSharedSelectors } from '../../../../_store';
import { BaseCountry } from '../../../services/base-country.service';
import { DropdownFoundation } from '../dropdown-foundation';

@Component({
  selector: 'base-country-dropdown',
  templateUrl: './base-country-dropdown.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BaseCountryDropdownComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => BaseCountryDropdownComponent),
      multi: true,
    },
  ],
})
/**
 * Dropdown for country selection<br/>
 * ###Please do not use for other purposes!<br/>
 * Available inputs: <br/>
 * - preview
 * - label
 * - requiredLabel
 * - placeholder
 *  <br/>
 *  Please see description of each input to read about usage information
 */
export class BaseCountryDropdownComponent
  extends DropdownFoundation
  implements OnInit, ControlValueAccessor, Validator
{
  /**
   * Enable preview, disable select <br/>
   * default = false
   */
  @Input() preview = false;
  /**
   * Adds label above
   */
  @Input() label: string;
  /**
   * Adds required class to label (which makes the red asterisk appear behind it); <br/>
   * Only has an impact if used together with label input <br/>
   * default = false
   */
  @Input() requiredLabel = false;
  /**
   * Placeholder string<br/>
   * default = ''
   */
  @Input() placeholder: string = '';

  countryList$: Observable<{ data: BaseCountry[]; ok: boolean | undefined }>;

  constructor(
    private readonly store: Store<BaseCoreState>,
    protected ngSelectConfig: NgSelectConfig,
    protected translate: TranslateService
  ) {
    super(ngSelectConfig, translate);
  }

  ngOnInit() {
    this.countryList$ = this.store.select(baseSharedSelectors.getCountryList);
  }
}
