import produce from 'immer';
import { FormGroup } from '@angular/forms';
import { baseFormsActions } from '../actions';
import { BaseStaffRequestFormInterface } from '../../modules/inquiry/_interfaces/base-inquiry.interface';
import { FormRawValue } from '../../modules/inquiry/_interfaces/base-form.interface';

export interface BaseFormsState {
  staffRequestForm: {
    data: FormRawValue<FormGroup<BaseStaffRequestFormInterface>> | undefined;
  };
  accountRegisterForm: {
    data: any;
  };
}

export const INITIAL_FORMS_STATE: BaseFormsState = {
  staffRequestForm: {
    data: undefined,
  },
  accountRegisterForm: {
    data: undefined,
  },
};

export function baseFormsReducer(state: BaseFormsState = INITIAL_FORMS_STATE, action: any): any {
  return produce(state, (draftState) => {
    switch (action.type) {
      // All forms
      case baseFormsActions.clearAllForms.type:
        draftState.accountRegisterForm = INITIAL_FORMS_STATE.accountRegisterForm;
        draftState.staffRequestForm = INITIAL_FORMS_STATE.staffRequestForm;
        return;

      // Request form
      case baseFormsActions.setStaffRequestForm.type:
        draftState.staffRequestForm.data = action.payload;
        return;

      case baseFormsActions.clearStaffRequestForm.type:
        draftState.staffRequestForm.data = undefined;
        return;

      // Account register form
      case baseFormsActions.setAccountRegisterForm.type:
        draftState.accountRegisterForm.data = action.payload;
        return;

      case baseFormsActions.clearAccountRegisterForm.type:
        draftState.accountRegisterForm.data = undefined;
        return;
    }
  });
}
