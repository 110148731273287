import { Component, Input } from '@angular/core';
import { BaseButtonSize } from '../button/base-button.component';

@Component({
  selector: 'base-special-button',
  templateUrl: './base-special-button.component.html',
})
export class BaseSpecialButtonComponent {
  @Input() color = 'primary';
  @Input() iconColor = 'secondary';
  @Input() icon: string;
  @Input() size = BaseButtonSize.md;
  @Input() label = 'Button';
  @Input() outlined = false;
  @Input() iconBold = true;
  @Input() tabindex: number | null = null;

  get classes(): string[] {
    return ['btn', `btn-${this.color}`, 'button-special', `btn-${this.size}`, this.outlined ? 'outlined' : ''];
  }

  get iconClasses(): string[] {
    return [this.icon ? `${this.icon} icon-${this.iconColor}` : '', this.iconBold ? 'bold' : ''];
  }
}
