import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { baseSharedActions, BaseCoreState } from '../../_store';

@Injectable()
export class BaseClearSharedStateGuard {
  constructor(private store: Store<BaseCoreState>) {}

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    this.store.dispatch(baseSharedActions.clearSharedState());

    return of(true);
  }
}
