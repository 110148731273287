import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  CustomerBranchListItemResponse,
  DocumentListItemResponse,
  MetaPagination,
  PspBranchResponse,
  PspResponse,
  SurchargeListItemResponse,
} from '../../../api';

const getRelatedPspBranchesState = createFeatureSelector('relatedPspBranches');

export const getRelatedPspBranchList = createSelector(
  getRelatedPspBranchesState,
  (
    state: any
  ): {
    data: CustomerBranchListItemResponse[] | undefined;
    pagination: MetaPagination | undefined;
    ok: boolean | undefined;
  } => state.list
);

export const getRelatedPspBranchDetails = createSelector(
  getRelatedPspBranchesState,
  (
    state: any
  ): {
    data: PspBranchResponse | undefined;
    ok: boolean | undefined;
  } => state.details
);

export const getRelatedPspBranchDocuments = createSelector(
  getRelatedPspBranchesState,
  (
    state: any
  ): {
    data: DocumentListItemResponse[] | undefined;
    ok: boolean | undefined;
  } => state.documents
);

export const getRelatedPspBranchDownloadDocument = createSelector(
  getRelatedPspBranchesState,
  (
    state: any
  ): {
    data: Blob | undefined;
  } => state.downloadDocument
);

export const getRelatedPspBranchSurcharges = createSelector(
  getRelatedPspBranchesState,
  (
    state: any
  ): {
    data: SurchargeListItemResponse[] | undefined;
    ok: boolean | undefined;
  } => state.surcharges
);

export const getRelatedPspBranchData = createSelector(
  getRelatedPspBranchesState,
  (
    state: any
  ): {
    data: PspResponse | undefined;
    ok: boolean | undefined;
  } => state.branchData
);
