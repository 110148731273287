<div class="d-flex flex-wrap justify-content-center align-items-center">
  <a
    *ngIf="externalFaqLink"
    [href]="externalFaqLink"
    target="_blank"
    title=""
    [ngbTooltip]="'help' | translate"
    class="d-block link-dark me-5"
  >
    <i class="fa-solid fa-question-circle"></i>
  </a>
  <base-notifications></base-notifications>
  <base-language-switch></base-language-switch>
  <div
    class="k-dropdown"
    (baseClickOutside)="close()"
  >
    <div
      class="user-nav align-items-center d-flex ps-0 pe-2 ms-3 py-2"
      (click)="toggle()"
    >
      <base-avatar
        [image]="userAvatar"
        [size]="avatarSize"
        class="mx-2"
      ></base-avatar>
      <div class="d-flex flex-column text-start">
        <span class="bold-font">{{ userName }}</span>
        <span class="light-font">{{ companyData?.primaryData?.name }}</span>
      </div>
      <i
        class="fa-solid fa-fw me-1 bold-font"
        [ngClass]="isOpen ? 'fa-chevron-up' : 'fa-chevron-down'"
      ></i>
    </div>
    <div
      class="user-nav-menu"
      *ngIf="isOpen"
      (click)="close()"
    >
      <ng-container *ngTemplateOutlet="menu"></ng-container>
    </div>
  </div>
</div>

<ng-template #menu>
  <ul class="user-nav-body list-unstyled mx-3 my-2">
    <li class="d-flex align-items-center gap-2">
      <base-avatar
        [image]="companyData?.logo?.data"
        [size]="avatarSize"
        [isCompany]="true"
      ></base-avatar>
      <div class="col d-flex flex-column user-nav-first-row">
        <span class="bold-font">{{ companyData?.primaryData?.name }}</span>
        <span class="light-font">{{ position }}</span>
      </div>
    </li>
    <hr class="my-2" />
    <ng-container *ngFor="let item of userMenuItems">
      <li
        class="text-uppercase bold-font"
        *ngIf="item.id !== 60 || (item.id === 60 && signDigital)"
      >
        <a [routerLink]="item.url">
          {{ item.title | translate }}
        </a>
      </li>
    </ng-container>
    <hr class="my-2" />
    <li>
      <a
        class="user-nav-logout bold-font"
        [routerLink]="'/auth/logout'"
      >
        <span>{{ 'auth.logout' | translate }}</span>
        <i class="fa-solid fa-fw fa-chevron-right"></i>
      </a>
    </li>
  </ul>
</ng-template>
