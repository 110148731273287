import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { baseCustomerDashboardActions, baseCustomerDashboardSelectors, BaseCoreState } from '../../../_store';
import { filter, map } from 'rxjs/operators';

@Injectable()
export class BaseCustomerDashboardDataGuard {
  constructor(private store: Store<BaseCoreState>) {}

  canActivate(): Observable<boolean> {
    this.store.dispatch(baseCustomerDashboardActions.loadCustomerDashboardData());

    return this.store.select(baseCustomerDashboardSelectors.getCustomerDashboardData).pipe(
      filter((state) => state !== undefined),
      map((state) => !!state)
    );
  }
}
