import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Validators, NonNullableFormBuilder } from '@angular/forms';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { noop } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import {
  BaseAbsenceTypes,
  BaseAbsenceTypeEnum,
  baseCandidateActions,
  BaseCoreState,
  BaseModalService,
  ProfileAbsenceConflictsListItemResponse,
  ProfileAbsenceListItemResponse,
  ProfileShowResponse,
  ProfileCreateAbsenceRequest,
  baseCandidatesSelectors,
  AbsenceType,
  BaseAbsencePayloadTypeEnum,
} from 'base';
import { AbsencePagination } from '../edit-candidate/components/absence-time/absence-time.component';

@Component({
  selector: 'growth-add-absence',
  templateUrl: './add-absence.component.html',
})
export class AddAbsenceComponent implements OnInit {
  @ViewChild('absenceConflictsModal', { static: true }) absenceConflictsModal: ElementRef;
  @Input() profile: ProfileShowResponse;
  @Input() absenceToEdit: ProfileAbsenceListItemResponse;
  @Input() absencePagination: AbsencePagination;
  @Input() inquiryId: number;
  @Input() reloadOffersAndCandidates: boolean;
  @Input() activeTabId: number | string;
  @Output() cancel: EventEmitter<boolean> = new EventEmitter<boolean>();
  absenceTypes = BaseAbsenceTypeEnum;
  absencePayloadTypes = BaseAbsencePayloadTypeEnum;
  types = BaseAbsenceTypes;
  form = this.fb.group({
    range: [null as { from: string; to: string } | null, [Validators.required]],
    type: ['' as AbsenceType, [Validators.required]],
    note: [''],
  });

  validated = false;
  modalRef: NgbModalRef;
  conflicts: Array<ProfileAbsenceConflictsListItemResponse> = [];

  constructor(
    private fb: NonNullableFormBuilder,
    private store: Store<BaseCoreState>,
    private modalService: BaseModalService
  ) {}

  ngOnInit(): void {
    this.initForm(this.absenceToEdit);
  }

  initForm(absence?: ProfileAbsenceListItemResponse) {
    this.form.patchValue({
      range: absence?.startDate && absence?.endDate ? { from: absence.startDate, to: absence.endDate } : null,
      type: absence?.type || (this.types[0].value as AbsenceType),
      note: absence?.note || '',
    });
  }

  setAbsenceConflicts(): void {
    this.store
      .select(baseCandidatesSelectors.getAbsenceConflicts)
      .pipe(
        filter((state) => !!state.ok),
        take(1)
      )
      .subscribe((response) => {
        if (response.data.length) {
          this.conflicts = response.data;
          this.openConflictsModal();
        } else {
          this.createAbsence();
        }
      });
  }

  loadAbsenceConflicts(): void {
    this.validated = true;
    if (this.profile?.id && this.form.valid) {
      this.store.dispatch(
        baseCandidateActions.loadAbsenceConflicts({
          payload: {
            profile: this.profile.id,
            startDate: this.form.controls.range.value?.from,
            endDate: this.form.controls.range.value?.to,
          },
        })
      );
      this.setAbsenceConflicts();
    }
  }

  createAbsence(): void {
    const formValue = this.form.getRawValue();

    const body: ProfileCreateAbsenceRequest = {
      endDate: formValue.range?.to || '',
      startDate: formValue.range?.from || '',
      type: formValue.type,
      note: formValue.note,
    };
    const activeTabId = this.activeTabId.toString() as BaseAbsenceTypeEnum;

    this.store.dispatch(
      baseCandidateActions.createAbsenceAction({
        payload: {
          profile: this.profile?.id as number,
          body: body,
          noLoading: true,
          inquiry: this.reloadOffersAndCandidates ? this.inquiryId : undefined,
          absenceTab: this.activeTabId !== this.absenceTypes.all ? this.absencePayloadTypes[activeTabId] : undefined,
        },
      })
    );
    this.closeModal();
  }

  openConflictsModal(): void {
    this.modalRef = this.modalService.open(
      {
        headText: 'work.absences.conflictsModal.title',
        bodyTemplate: this.absenceConflictsModal,
        okText: 'action.confirm',
      },
      {
        size: 'md',
      }
    );

    this.modalRef.result.then((_): any => {
      this.createAbsence();
    }, noop);
  }

  updateAbsence() {
    this.validated = true;
    if (this.profile?.id && this.form.valid && this.absenceToEdit?.id) {
      const formValue = this.form.getRawValue();

      const body: ProfileCreateAbsenceRequest = {
        endDate: formValue.range?.to || '',
        startDate: formValue.range?.from || '',
        type: formValue.type,
        note: formValue.note,
      };
      const activeTabId = this.activeTabId.toString() as BaseAbsenceTypeEnum;

      this.store.dispatch(
        baseCandidateActions.updateAbsenceAction({
          payload: {
            absence: this.absenceToEdit.id,
            profile: this.profile.id,
            inquiry: this.reloadOffersAndCandidates ? this.inquiryId : undefined,
            body,
            absenceTab: this.activeTabId !== this.absenceTypes.all ? this.absencePayloadTypes[activeTabId] : undefined,
          },
        })
      );
      this.closeModal();
    }
  }

  closeModal() {
    this.cancel.emit(true);
  }
}
