import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'base-empty-list-row',
  templateUrl: './base-empty-list-row.component.html',
})
export class BaseEmptyListRowComponent implements OnInit {
  @Input() message: string;
  @Input() hideBackgroundImage = false;
  @Input() backgroundSecondary = false;
  @Input() largeImage = false;
  imageSource: string;
  backgroundImageSources = [
    'assets/images/empty-list-1.svg',
    'assets/images/empty-list-2.svg',
    'assets/images/empty-list-3.svg',
    'assets/images/empty-list-4.svg',
    'assets/images/empty-list-5.svg',
  ];

  ngOnInit() {
    this.setBackgroundImage();
  }

  private setBackgroundImage(): void {
    const backgroundImageIndex = Math.floor(Math.random() * this.backgroundImageSources.length);
    this.imageSource = this.backgroundImageSources[backgroundImageIndex];
  }
}
