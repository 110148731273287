import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseTokenService } from '../services/base-token.service';

@Injectable()
export class BaseAuthInterceptor implements HttpInterceptor {
  private AUTH_HEADER = 'Authorization';

  constructor(private tokenService: BaseTokenService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!req.url.includes('token-refreshing') && !req.url.includes('logout')) {
      req = this.addAuthenticationToken(req);
    }

    return next.handle(req);
  }

  private addAuthenticationToken(request: HttpRequest<any>): HttpRequest<any> {
    if (!this.tokenService.getToken()) {
      return request.clone({
        withCredentials: true,
      });
    }

    return request.clone({
      headers: request.headers.set(this.AUTH_HEADER, 'Bearer ' + this.tokenService.getToken()),
      withCredentials: true,
    });
  }
}
