import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PspBranchService, PspDashboardForecastResponse, PspService } from '../../../api';

@Injectable()
export class BaseDashboardService {
  constructor(private pspBranchService: PspBranchService, private pspService: PspService) {}

  getPspSalesForecast(psp: number, from: string, to: string): Observable<PspDashboardForecastResponse> {
    return this.pspService.getPspDashboardGetForecast({ from, to, psp }).pipe(map((response) => response.data));
  }
}
