export enum BaseCustomerOfferBubbleClassesEnum {
  OfferedDeselected = 'position-relative bubble light-grey',
  OfferedDeselectedDot = 'position-relative bubble-dot light-grey',
  OfferedSelected = 'position-relative bubble pointer bubble-framed green',
  OfferedSelectedDot = 'position-relative bubble-dot pointer bubble-framed green',
  OfferedAvailableForSelection = 'position-relative bubble pointer light-grey',
  OfferedAvailableForSelectionDot = 'position-relative bubble-dot pointer light-grey',
  Booked = 'position-relative bubble bubble-framed primary',
  BookedDot = 'position-relative bubble bubble-framed light-blue',
  Rejected = 'position-relative bubble bubble-framed red',
  RejectedDot = 'position-relative bubble-dot bubble-framed red',
}
