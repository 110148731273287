/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AddUsersToDepartmentRequest } from '../models/add-users-to-department-request';
import { CreatedResponse } from '../models/created-response';
import { CustomerDepartmentAddFavoriteQualificationsRequest } from '../models/customer-department-add-favorite-qualifications-request';
import { CustomerDepartmentCreateRequest } from '../models/customer-department-create-request';
import { CustomerDepartmentQualificationCreateRequest } from '../models/customer-department-qualification-create-request';
import { CustomerDepartmentUpdateRequest } from '../models/customer-department-update-request';
import { CustomerDepartmentUpdateShiftTimesRequest } from '../models/customer-department-update-shift-times-request';
import { DepartmentListItemResponse } from '../models/department-list-item-response';
import { EmptySuccessfulResponse } from '../models/empty-successful-response';
import { GeneralApiResponse } from '../models/general-api-response';
import { ListApiResponse } from '../models/list-api-response';
import { QualificationFavoritesResponse } from '../models/qualification-favorites-response';
import { UpdateUsersRequest } from '../models/update-users-request';

@Injectable({
  providedIn: 'root',
})
export class CustomerLocationDepartmentService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation postCustomerLocationDepartmentActivate
   */
  static readonly PostCustomerLocationDepartmentActivatePath = '/api/web/v1/customers/{customer}/locations/{location}/departments/{department}/activation';

  /**
   * Activate customer department location.
   *
   * **Accessible only for users with permissions 'ROLE_CUSTOMER_ADMIN', 'ROLE_CUSTOMER_LOCATION_ADMIN'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postCustomerLocationDepartmentActivate()` instead.
   *
   * This method doesn't expect any request body.
   */
  postCustomerLocationDepartmentActivate$Response(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Location Id.
     */
    location: number;

    /**
     * Department Id.
     */
    department: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EmptySuccessfulResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerLocationDepartmentService.PostCustomerLocationDepartmentActivatePath, 'post');
    if (params) {
      rb.path('customer', params.customer, {});
      rb.path('location', params.location, {});
      rb.path('department', params.department, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmptySuccessfulResponse>;
      })
    );
  }

  /**
   * Activate customer department location.
   *
   * **Accessible only for users with permissions 'ROLE_CUSTOMER_ADMIN', 'ROLE_CUSTOMER_LOCATION_ADMIN'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postCustomerLocationDepartmentActivate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  postCustomerLocationDepartmentActivate(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Location Id.
     */
    location: number;

    /**
     * Department Id.
     */
    department: number;
    context?: HttpContext
  }
): Observable<EmptySuccessfulResponse> {

    return this.postCustomerLocationDepartmentActivate$Response(params).pipe(
      map((r: StrictHttpResponse<EmptySuccessfulResponse>) => r.body as EmptySuccessfulResponse)
    );
  }

  /**
   * Path part for operation deleteCustomerLocationDepartmentDeactivate
   */
  static readonly DeleteCustomerLocationDepartmentDeactivatePath = '/api/web/v1/customers/{customer}/locations/{location}/departments/{department}/activation';

  /**
   * Deactivate customer department location.
   *
   * **Accessible only for users with permissions 'ROLE_CUSTOMER_ADMIN', 'ROLE_CUSTOMER_LOCATION_ADMIN'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteCustomerLocationDepartmentDeactivate()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCustomerLocationDepartmentDeactivate$Response(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Location Id.
     */
    location: number;

    /**
     * Department Id.
     */
    department: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EmptySuccessfulResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerLocationDepartmentService.DeleteCustomerLocationDepartmentDeactivatePath, 'delete');
    if (params) {
      rb.path('customer', params.customer, {});
      rb.path('location', params.location, {});
      rb.path('department', params.department, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmptySuccessfulResponse>;
      })
    );
  }

  /**
   * Deactivate customer department location.
   *
   * **Accessible only for users with permissions 'ROLE_CUSTOMER_ADMIN', 'ROLE_CUSTOMER_LOCATION_ADMIN'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteCustomerLocationDepartmentDeactivate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCustomerLocationDepartmentDeactivate(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Location Id.
     */
    location: number;

    /**
     * Department Id.
     */
    department: number;
    context?: HttpContext
  }
): Observable<EmptySuccessfulResponse> {

    return this.deleteCustomerLocationDepartmentDeactivate$Response(params).pipe(
      map((r: StrictHttpResponse<EmptySuccessfulResponse>) => r.body as EmptySuccessfulResponse)
    );
  }

  /**
   * Path part for operation getCustomerLocationDepartmentList
   */
  static readonly GetCustomerLocationDepartmentListPath = '/api/web/v1/customers/{customer}/locations/{location}/departments';

  /**
   * Get list of customer location departments.
   *
   * **Accessible only for users with permissions 'FEATURE_VIEW_DEPARTMENTS', 'ROLE_CUSTOMER_ADMIN',
   * 'ROLE_CUSTOMER_USER', 'ROLE_CUSTOMER_LOCATION_ADMIN'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerLocationDepartmentList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerLocationDepartmentList$Response(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Location Id.
     */
    location: number;

    /**
     * Filter active or inactive departments
     */
    active?: any;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ListApiResponse & {
'data'?: Array<DepartmentListItemResponse>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerLocationDepartmentService.GetCustomerLocationDepartmentListPath, 'get');
    if (params) {
      rb.path('customer', params.customer, {});
      rb.path('location', params.location, {});
      rb.query('active', params.active, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListApiResponse & {
        'data'?: Array<DepartmentListItemResponse>;
        }>;
      })
    );
  }

  /**
   * Get list of customer location departments.
   *
   * **Accessible only for users with permissions 'FEATURE_VIEW_DEPARTMENTS', 'ROLE_CUSTOMER_ADMIN',
   * 'ROLE_CUSTOMER_USER', 'ROLE_CUSTOMER_LOCATION_ADMIN'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCustomerLocationDepartmentList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerLocationDepartmentList(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Location Id.
     */
    location: number;

    /**
     * Filter active or inactive departments
     */
    active?: any;
    context?: HttpContext
  }
): Observable<ListApiResponse & {
'data'?: Array<DepartmentListItemResponse>;
}> {

    return this.getCustomerLocationDepartmentList$Response(params).pipe(
      map((r: StrictHttpResponse<ListApiResponse & {
'data'?: Array<DepartmentListItemResponse>;
}>) => r.body as ListApiResponse & {
'data'?: Array<DepartmentListItemResponse>;
})
    );
  }

  /**
   * Path part for operation postCustomerDepartmentCreate
   */
  static readonly PostCustomerDepartmentCreatePath = '/api/web/v1/customers/{customer}/locations/{location}/departments';

  /**
   * Create a customer location department, accessible only for users with permissions 'ROLE_CUSTOMER_ADMIN'
   * or 'ROLE_CUSTOMER_LOCATION_ADMIN'.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postCustomerDepartmentCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postCustomerDepartmentCreate$Response(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Location Id.
     */
    location: number;
    context?: HttpContext
    body: CustomerDepartmentCreateRequest
  }
): Observable<StrictHttpResponse<GeneralApiResponse & {
'data'?: DepartmentListItemResponse;
}>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerLocationDepartmentService.PostCustomerDepartmentCreatePath, 'post');
    if (params) {
      rb.path('customer', params.customer, {});
      rb.path('location', params.location, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GeneralApiResponse & {
        'data'?: DepartmentListItemResponse;
        }>;
      })
    );
  }

  /**
   * Create a customer location department, accessible only for users with permissions 'ROLE_CUSTOMER_ADMIN'
   * or 'ROLE_CUSTOMER_LOCATION_ADMIN'.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postCustomerDepartmentCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postCustomerDepartmentCreate(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Location Id.
     */
    location: number;
    context?: HttpContext
    body: CustomerDepartmentCreateRequest
  }
): Observable<GeneralApiResponse & {
'data'?: DepartmentListItemResponse;
}> {

    return this.postCustomerDepartmentCreate$Response(params).pipe(
      map((r: StrictHttpResponse<GeneralApiResponse & {
'data'?: DepartmentListItemResponse;
}>) => r.body as GeneralApiResponse & {
'data'?: DepartmentListItemResponse;
})
    );
  }

  /**
   * Path part for operation postCustomerLocationDepartmentQualificationsDescriptionCreate
   */
  static readonly PostCustomerLocationDepartmentQualificationsDescriptionCreatePath = '/api/web/v1/customers/{customer}/locations/{location}/departments/{department}/qualifications/{qualification}/description';

  /**
   * Create description for customer location department qualification.
   *
   * **Accessible only for users with permissions 'ROLE_CUSTOMER_ADMIN', 'ROLE_CUSTOMER_LOCATION_ADMIN'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postCustomerLocationDepartmentQualificationsDescriptionCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postCustomerLocationDepartmentQualificationsDescriptionCreate$Response(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Location Id.
     */
    location: number;

    /**
     * Department Id.
     */
    department: number;

    /**
     * Qualification Id.
     */
    qualification: number;
    context?: HttpContext
    body: CustomerDepartmentQualificationCreateRequest
  }
): Observable<StrictHttpResponse<CreatedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerLocationDepartmentService.PostCustomerLocationDepartmentQualificationsDescriptionCreatePath, 'post');
    if (params) {
      rb.path('customer', params.customer, {});
      rb.path('location', params.location, {});
      rb.path('department', params.department, {});
      rb.path('qualification', params.qualification, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CreatedResponse>;
      })
    );
  }

  /**
   * Create description for customer location department qualification.
   *
   * **Accessible only for users with permissions 'ROLE_CUSTOMER_ADMIN', 'ROLE_CUSTOMER_LOCATION_ADMIN'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postCustomerLocationDepartmentQualificationsDescriptionCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postCustomerLocationDepartmentQualificationsDescriptionCreate(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Location Id.
     */
    location: number;

    /**
     * Department Id.
     */
    department: number;

    /**
     * Qualification Id.
     */
    qualification: number;
    context?: HttpContext
    body: CustomerDepartmentQualificationCreateRequest
  }
): Observable<CreatedResponse> {

    return this.postCustomerLocationDepartmentQualificationsDescriptionCreate$Response(params).pipe(
      map((r: StrictHttpResponse<CreatedResponse>) => r.body as CreatedResponse)
    );
  }

  /**
   * Path part for operation getCustomerLocationDepartmentQualificationListFavoriteQualifications
   */
  static readonly GetCustomerLocationDepartmentQualificationListFavoriteQualificationsPath = '/api/web/v1/customers/{customer}/locations/{location}/departments/{department}/qualifications/favorites';

  /**
   * List favorite qualifications.
   *
   * **Accessible only for users with permission 'ROLE_CUSTOMER_ADMIN'
   * and location owner with role 'ROLE_CUSTOMER_LOCATION_ADMIN'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerLocationDepartmentQualificationListFavoriteQualifications()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerLocationDepartmentQualificationListFavoriteQualifications$Response(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Location Id.
     */
    location: number;

    /**
     * Department Id.
     */
    department: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ListApiResponse & {
'data'?: Array<QualificationFavoritesResponse>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerLocationDepartmentService.GetCustomerLocationDepartmentQualificationListFavoriteQualificationsPath, 'get');
    if (params) {
      rb.path('customer', params.customer, {});
      rb.path('location', params.location, {});
      rb.path('department', params.department, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListApiResponse & {
        'data'?: Array<QualificationFavoritesResponse>;
        }>;
      })
    );
  }

  /**
   * List favorite qualifications.
   *
   * **Accessible only for users with permission 'ROLE_CUSTOMER_ADMIN'
   * and location owner with role 'ROLE_CUSTOMER_LOCATION_ADMIN'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCustomerLocationDepartmentQualificationListFavoriteQualifications$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerLocationDepartmentQualificationListFavoriteQualifications(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Location Id.
     */
    location: number;

    /**
     * Department Id.
     */
    department: number;
    context?: HttpContext
  }
): Observable<ListApiResponse & {
'data'?: Array<QualificationFavoritesResponse>;
}> {

    return this.getCustomerLocationDepartmentQualificationListFavoriteQualifications$Response(params).pipe(
      map((r: StrictHttpResponse<ListApiResponse & {
'data'?: Array<QualificationFavoritesResponse>;
}>) => r.body as ListApiResponse & {
'data'?: Array<QualificationFavoritesResponse>;
})
    );
  }

  /**
   * Path part for operation postCustomerLocationDepartmentQualificationsFavoriteAdd
   */
  static readonly PostCustomerLocationDepartmentQualificationsFavoriteAddPath = '/api/web/v1/customers/{customer}/locations/{location}/departments/{department}/qualifications/favorites';

  /**
   * Add favorite qualifications to customer location department.
   *
   * **Accessible only for users with permissions 'ROLE_CUSTOMER_ADMIN', 'ROLE_CUSTOMER_LOCATION_ADMIN'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postCustomerLocationDepartmentQualificationsFavoriteAdd()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postCustomerLocationDepartmentQualificationsFavoriteAdd$Response(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Location Id.
     */
    location: number;

    /**
     * Department Id.
     */
    department: number;
    context?: HttpContext
    body: CustomerDepartmentAddFavoriteQualificationsRequest
  }
): Observable<StrictHttpResponse<EmptySuccessfulResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerLocationDepartmentService.PostCustomerLocationDepartmentQualificationsFavoriteAddPath, 'post');
    if (params) {
      rb.path('customer', params.customer, {});
      rb.path('location', params.location, {});
      rb.path('department', params.department, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmptySuccessfulResponse>;
      })
    );
  }

  /**
   * Add favorite qualifications to customer location department.
   *
   * **Accessible only for users with permissions 'ROLE_CUSTOMER_ADMIN', 'ROLE_CUSTOMER_LOCATION_ADMIN'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postCustomerLocationDepartmentQualificationsFavoriteAdd$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postCustomerLocationDepartmentQualificationsFavoriteAdd(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Location Id.
     */
    location: number;

    /**
     * Department Id.
     */
    department: number;
    context?: HttpContext
    body: CustomerDepartmentAddFavoriteQualificationsRequest
  }
): Observable<EmptySuccessfulResponse> {

    return this.postCustomerLocationDepartmentQualificationsFavoriteAdd$Response(params).pipe(
      map((r: StrictHttpResponse<EmptySuccessfulResponse>) => r.body as EmptySuccessfulResponse)
    );
  }

  /**
   * Path part for operation deleteCustomerLocationDepartmentQualificationsFavoriteDelete
   */
  static readonly DeleteCustomerLocationDepartmentQualificationsFavoriteDeletePath = '/api/web/v1/customers/{customer}/locations/{location}/departments/{department}/qualifications/favorites/{qualification}';

  /**
   * Delete Favorite Qualification from a Customer Department.
   *
   * **Accessible only for users with permissions 'ROLE_CUSTOMER_ADMIN', 'ROLE_CUSTOMER_LOCATION_ADMIN'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteCustomerLocationDepartmentQualificationsFavoriteDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCustomerLocationDepartmentQualificationsFavoriteDelete$Response(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Location Id.
     */
    location: number;

    /**
     * Department Id.
     */
    department: number;

    /**
     * Qualification Id.
     */
    qualification: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EmptySuccessfulResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerLocationDepartmentService.DeleteCustomerLocationDepartmentQualificationsFavoriteDeletePath, 'delete');
    if (params) {
      rb.path('customer', params.customer, {});
      rb.path('location', params.location, {});
      rb.path('department', params.department, {});
      rb.path('qualification', params.qualification, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmptySuccessfulResponse>;
      })
    );
  }

  /**
   * Delete Favorite Qualification from a Customer Department.
   *
   * **Accessible only for users with permissions 'ROLE_CUSTOMER_ADMIN', 'ROLE_CUSTOMER_LOCATION_ADMIN'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteCustomerLocationDepartmentQualificationsFavoriteDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCustomerLocationDepartmentQualificationsFavoriteDelete(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Location Id.
     */
    location: number;

    /**
     * Department Id.
     */
    department: number;

    /**
     * Qualification Id.
     */
    qualification: number;
    context?: HttpContext
  }
): Observable<EmptySuccessfulResponse> {

    return this.deleteCustomerLocationDepartmentQualificationsFavoriteDelete$Response(params).pipe(
      map((r: StrictHttpResponse<EmptySuccessfulResponse>) => r.body as EmptySuccessfulResponse)
    );
  }

  /**
   * Path part for operation putCustomerLocationDepartmentTimeShifts
   */
  static readonly PutCustomerLocationDepartmentTimeShiftsPath = '/api/web/v1/customers/{customer}/locations/{location}/departments/{department}/time-shifts';

  /**
   * Update a customer location department times shifts.
   *
   * **Accessible only for users with permissions 'ROLE_CUSTOMER_ADMIN', 'ROLE_CUSTOMER_LOCATION_ADMIN'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putCustomerLocationDepartmentTimeShifts()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putCustomerLocationDepartmentTimeShifts$Response(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Location Id.
     */
    location: number;

    /**
     * Department Id.
     */
    department: number;
    context?: HttpContext
    body: CustomerDepartmentUpdateShiftTimesRequest
  }
): Observable<StrictHttpResponse<EmptySuccessfulResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerLocationDepartmentService.PutCustomerLocationDepartmentTimeShiftsPath, 'put');
    if (params) {
      rb.path('customer', params.customer, {});
      rb.path('location', params.location, {});
      rb.path('department', params.department, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmptySuccessfulResponse>;
      })
    );
  }

  /**
   * Update a customer location department times shifts.
   *
   * **Accessible only for users with permissions 'ROLE_CUSTOMER_ADMIN', 'ROLE_CUSTOMER_LOCATION_ADMIN'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putCustomerLocationDepartmentTimeShifts$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putCustomerLocationDepartmentTimeShifts(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Location Id.
     */
    location: number;

    /**
     * Department Id.
     */
    department: number;
    context?: HttpContext
    body: CustomerDepartmentUpdateShiftTimesRequest
  }
): Observable<EmptySuccessfulResponse> {

    return this.putCustomerLocationDepartmentTimeShifts$Response(params).pipe(
      map((r: StrictHttpResponse<EmptySuccessfulResponse>) => r.body as EmptySuccessfulResponse)
    );
  }

  /**
   * Path part for operation putCustomerLocationDepartmentUpdate
   */
  static readonly PutCustomerLocationDepartmentUpdatePath = '/api/web/v1/customers/{customer}/locations/{location}/departments/{department}';

  /**
   * Update customer location department.
   *
   * **Accessible only for users with permissions 'ROLE_CUSTOMER_ADMIN', 'ROLE_CUSTOMER_LOCATION_ADMIN'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putCustomerLocationDepartmentUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putCustomerLocationDepartmentUpdate$Response(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Location Id.
     */
    location: number;

    /**
     * Department Id.
     */
    department: number;
    context?: HttpContext
    body: CustomerDepartmentUpdateRequest
  }
): Observable<StrictHttpResponse<EmptySuccessfulResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerLocationDepartmentService.PutCustomerLocationDepartmentUpdatePath, 'put');
    if (params) {
      rb.path('customer', params.customer, {});
      rb.path('location', params.location, {});
      rb.path('department', params.department, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmptySuccessfulResponse>;
      })
    );
  }

  /**
   * Update customer location department.
   *
   * **Accessible only for users with permissions 'ROLE_CUSTOMER_ADMIN', 'ROLE_CUSTOMER_LOCATION_ADMIN'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putCustomerLocationDepartmentUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putCustomerLocationDepartmentUpdate(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Location Id.
     */
    location: number;

    /**
     * Department Id.
     */
    department: number;
    context?: HttpContext
    body: CustomerDepartmentUpdateRequest
  }
): Observable<EmptySuccessfulResponse> {

    return this.putCustomerLocationDepartmentUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<EmptySuccessfulResponse>) => r.body as EmptySuccessfulResponse)
    );
  }

  /**
   * Path part for operation putLocationDepartmentUpdateUsers
   */
  static readonly PutLocationDepartmentUpdateUsersPath = '/api/web/v1/customers/{customer}/locations/{location}/departments/{department}/users';

  /**
   * Update location department users, accessible only for users with permissions 'ROLE_CUSTOMER_ADMIN'
   * or 'ROLE_CUSTOMER_LOCATION_ADMIN'.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putLocationDepartmentUpdateUsers()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putLocationDepartmentUpdateUsers$Response(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Location Id.
     */
    location: number;

    /**
     * Department Id.
     */
    department: number;
    context?: HttpContext
    body: UpdateUsersRequest
  }
): Observable<StrictHttpResponse<EmptySuccessfulResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerLocationDepartmentService.PutLocationDepartmentUpdateUsersPath, 'put');
    if (params) {
      rb.path('customer', params.customer, {});
      rb.path('location', params.location, {});
      rb.path('department', params.department, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmptySuccessfulResponse>;
      })
    );
  }

  /**
   * Update location department users, accessible only for users with permissions 'ROLE_CUSTOMER_ADMIN'
   * or 'ROLE_CUSTOMER_LOCATION_ADMIN'.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putLocationDepartmentUpdateUsers$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putLocationDepartmentUpdateUsers(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Location Id.
     */
    location: number;

    /**
     * Department Id.
     */
    department: number;
    context?: HttpContext
    body: UpdateUsersRequest
  }
): Observable<EmptySuccessfulResponse> {

    return this.putLocationDepartmentUpdateUsers$Response(params).pipe(
      map((r: StrictHttpResponse<EmptySuccessfulResponse>) => r.body as EmptySuccessfulResponse)
    );
  }

  /**
   * Path part for operation patchLocationDepartmentAddUsers
   */
  static readonly PatchLocationDepartmentAddUsersPath = '/api/web/v1/customers/{customer}/locations/{location}/departments/{department}/users';

  /**
   * Add location department users, accessible only for users with permissions 'ROLE_CUSTOMER_ADMIN'
   * or 'ROLE_CUSTOMER_LOCATION_ADMIN'.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchLocationDepartmentAddUsers()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchLocationDepartmentAddUsers$Response(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Location Id.
     */
    location: number;

    /**
     * Department Id.
     */
    department: number;
    context?: HttpContext
    body: AddUsersToDepartmentRequest
  }
): Observable<StrictHttpResponse<EmptySuccessfulResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerLocationDepartmentService.PatchLocationDepartmentAddUsersPath, 'patch');
    if (params) {
      rb.path('customer', params.customer, {});
      rb.path('location', params.location, {});
      rb.path('department', params.department, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmptySuccessfulResponse>;
      })
    );
  }

  /**
   * Add location department users, accessible only for users with permissions 'ROLE_CUSTOMER_ADMIN'
   * or 'ROLE_CUSTOMER_LOCATION_ADMIN'.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchLocationDepartmentAddUsers$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchLocationDepartmentAddUsers(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Location Id.
     */
    location: number;

    /**
     * Department Id.
     */
    department: number;
    context?: HttpContext
    body: AddUsersToDepartmentRequest
  }
): Observable<EmptySuccessfulResponse> {

    return this.patchLocationDepartmentAddUsers$Response(params).pipe(
      map((r: StrictHttpResponse<EmptySuccessfulResponse>) => r.body as EmptySuccessfulResponse)
    );
  }

  /**
   * Path part for operation deleteLocationDepartmentDeleteUser
   */
  static readonly DeleteLocationDepartmentDeleteUserPath = '/api/web/v1/customers/{customer}/locations/{location}/departments/{department}/users/{user}';

  /**
   * Remove user from  location department, accessible only for users with permissions 'ROLE_CUSTOMER_ADMIN'
   * or 'ROLE_CUSTOMER_LOCATION_ADMIN'.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteLocationDepartmentDeleteUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteLocationDepartmentDeleteUser$Response(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Location Id.
     */
    location: number;

    /**
     * Department Id.
     */
    department: number;

    /**
     * User Id.
     */
    user: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EmptySuccessfulResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerLocationDepartmentService.DeleteLocationDepartmentDeleteUserPath, 'delete');
    if (params) {
      rb.path('customer', params.customer, {});
      rb.path('location', params.location, {});
      rb.path('department', params.department, {});
      rb.path('user', params.user, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmptySuccessfulResponse>;
      })
    );
  }

  /**
   * Remove user from  location department, accessible only for users with permissions 'ROLE_CUSTOMER_ADMIN'
   * or 'ROLE_CUSTOMER_LOCATION_ADMIN'.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteLocationDepartmentDeleteUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteLocationDepartmentDeleteUser(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Location Id.
     */
    location: number;

    /**
     * Department Id.
     */
    department: number;

    /**
     * User Id.
     */
    user: number;
    context?: HttpContext
  }
): Observable<EmptySuccessfulResponse> {

    return this.deleteLocationDepartmentDeleteUser$Response(params).pipe(
      map((r: StrictHttpResponse<EmptySuccessfulResponse>) => r.body as EmptySuccessfulResponse)
    );
  }

}
