import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NonNullableFormBuilder, Validators } from '@angular/forms';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { filter, map, takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { noop, Subject } from 'rxjs';
import { AssignmentCustomerResponse, AssignmentPspResponse, ContractRequest } from '../../../../api';
import { BaseModalService } from '../../services/base-modal.service';
import { BaseButtonSize } from '../button/base-button.component';
import { baseAssignmentsActions, baseAssignmentsSelectors, baseSharedSelectors, BaseCoreState } from '../../../_store';
import { BaseNamirialSigningRegisterStatusEnum } from '../../../modules/administration/_enums/base-namirial-signing-register-status.enum';

@Component({
  selector: 'base-sign-contracts-login',
  templateUrl: './base-sign-contracts-login.component.html',
})
export class BaseSignContractsLoginComponent implements OnInit, OnDestroy {
  @ViewChild('registerPopup') registerPopup: ElementRef;
  @Input() modalRef: NgbModalRef;
  @Output() saveCredentials: EventEmitter<ContractRequest> = new EventEmitter<ContractRequest>();

  submitted = false;
  modalRegisterRef: NgbModalRef;
  buttonSizes = BaseButtonSize;
  currentAssignment: AssignmentPspResponse | AssignmentCustomerResponse;
  loginForm = this.fb.group({
    username: ['', [Validators.required]],
    password: ['', [Validators.required]],
  });
  isNamirialRegistrationAvailable: boolean;
  isVoucherOrdered: boolean;
  voucherLink: string;

  private destroy$ = new Subject();

  constructor(
    private fb: NonNullableFormBuilder,
    private modalService: BaseModalService,
    private store: Store<BaseCoreState>
  ) {}

  ngOnInit(): void {
    this.store
      .select(baseAssignmentsSelectors.getAssignment)
      .pipe(
        takeUntil(this.destroy$),
        filter((assignmentState) => assignmentState.ok === true),
        map((assignmentState) => assignmentState.data)
      )
      .subscribe((assignment) => {
        this.currentAssignment = assignment;
      });

    this.store
      .select(baseSharedSelectors.getProfileData)
      .pipe(takeUntil(this.destroy$))
      .subscribe((profile) => {
        this.isNamirialRegistrationAvailable = !profile?.signingRegisterStatus;
        this.isVoucherOrdered = profile?.signingRegisterStatus === BaseNamirialSigningRegisterStatusEnum.ordered;

        if (this.isVoucherOrdered) {
          this.store.dispatch(baseAssignmentsActions.loadDigitalSignVoucher({ payload: { hideAlert: true } }));
        }
      });

    this.store
      .select(baseAssignmentsSelectors.getDigitalSignVoucher)
      .pipe(
        takeUntil(this.destroy$),
        filter((state) => state.ok === true),
        map((state) => state.data)
      )
      .subscribe((voucherData) => {
        this.voucherLink = voucherData.url;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  closeModal(): void {
    this.modalRef.dismiss();
  }

  submit() {
    this.submitted = true;
    this.loginForm.markAllAsTouched();
    if (this.loginForm.valid) {
      this.saveCredentials.emit(this.loginForm.getRawValue());
      this.modalRef.close();
    }
  }

  openRegisterModal() {
    this.modalRegisterRef = this.modalService.open(
      {
        bodyTemplate: this.registerPopup,
        cancelButtonDisplayed: false,
        okButtonDisplayed: false,
        hideCloseIcon: true,
        hideHeader: true,
        hideFooter: true,
      },
      {
        size: 'lg',
      }
    );
    this.modalRegisterRef.result.then(noop, noop);
  }

  openVoucher(): void {
    window.open(this.voucherLink, '_blank');
  }
}
