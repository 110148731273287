import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxPermissionsModule } from 'ngx-permissions';
import { BaseComponent } from './base.component';
import { BaseGlobalErrorHandler } from './core/error-handler/base-global-error.handler';
import { BaseAuthGuard } from './core/guards/base-auth.guard';
import { BaseRefreshGuard } from './core/guards/base-refresh.guard';
import { BaseAuthInterceptor } from './core/interceptors/base-auth.interceptor';
import { BaseHttpErrorsInterceptor } from './core/interceptors/base-http-errors.interceptor';
import { BaseSpinnerInterceptor } from './core/interceptors/base-spinner.interceptor';
import { BaseAppWrapperLayoutService } from './core/services/base-app-wrapper-layout.service';
import { BaseConfigurationService } from './core/services/base-configuration.service';
import { BaseGlobalErrorService } from './core/services/base-global-error.service';
import { BaseHttpErrorService } from './core/services/base-http-error.service';
import { BaseHttpService } from './core/services/base-http.service';
import { BaseLoggingService } from './core/services/base-logging.service';
import { BaseRolesService } from './core/services/base-roles.service';
import { BaseStoreService } from './core/services/base-store.service';
import { BaseTokenService } from './core/services/base-token.service';
import { BaseEnvironmenterTokenService, ENVIRONMENTER } from './core/token/base-environmenter.token';
import { BasePermissionsGuard } from './modules/administration/_guards/base-permissions.guard';
import { BaseAuthModule } from './modules/auth/base-auth.module';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular-ivy';
import { BaseEnvironmenter } from './shared/services/base-environmenter.service';
import { BaseAcceptLanguageInterceptor } from './core/interceptors/base-accept-language.interceptor';

@NgModule({
  declarations: [BaseComponent],
  imports: [HttpClientModule, NgxPermissionsModule.forRoot(), NgbModule, BaseAuthModule],
  exports: [BaseComponent],
  providers: [
    BaseConfigurationService,
    BaseEnvironmenter,
    BaseStoreService,
    BaseRolesService,
    BaseTokenService,
    BaseGlobalErrorService,
    BaseLoggingService,
    BaseHttpErrorService,
    BaseHttpService,
    BaseAppWrapperLayoutService,
    BaseAuthGuard,
    BaseRefreshGuard,
    BasePermissionsGuard,
    { provide: ErrorHandler, useClass: BaseGlobalErrorHandler },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: BaseAuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: BaseAcceptLanguageInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: BaseHttpErrorsInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: BaseSpinnerInterceptor, multi: true },
    [
      {
        provide: ENVIRONMENTER,
        useClass: BaseEnvironmenterTokenService,
      },
    ],
  ],
})
export class BaseModule {}
