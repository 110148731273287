import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { BasePriceListCategoriesGuard } from './_guards/base-price-list-categories.guard';
import { basePspPriceListEffects, basePspPriceListReducer } from '../../_store';
import { BasePspPriceListService } from './base-psp-price-list.service';
import { BasePspPriceListGuard } from './_guards/base-psp-price-list.guard';

@NgModule({
  imports: [
    StoreModule.forFeature('pspPriceList', basePspPriceListReducer.BasePspPriceListReducer),
    EffectsModule.forFeature([basePspPriceListEffects.BasePspPriceListEffects]),
  ],
  providers: [BasePspPriceListService, BasePriceListCategoriesGuard, BasePspPriceListGuard],
})
export class BasePspPriceListModule {}
