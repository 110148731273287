import produce from 'immer';
import {
  DepartmentListItemResponse,
  DocumentListItemResponse,
  MetaPagination,
  PspCustomerLocationItemResponse,
  PspCustomerLocationShowResponse,
  PspCustomerSurchargeResponse,
  QualificationPricingListItemResponse,
} from '../../../api';
import { baseRelatedCustomerLocationsActions } from '../actions';

export interface BaseRelatedCustomerLocationsState {
  list: {
    data: PspCustomerLocationItemResponse[] | undefined;
    pagination: MetaPagination | undefined;
    ok: boolean | undefined;
  };
  details: {
    data: PspCustomerLocationShowResponse | undefined;
    ok: boolean | undefined;
  };
  customerLocationDocuments: {
    data: DocumentListItemResponse[] | undefined;
    ok: boolean | undefined;
  };
  downloadDocument: {
    data: Blob | undefined;
  };
  departments: {
    data: DepartmentListItemResponse[] | undefined;
    ok: boolean | undefined;
  };
  priceList: {
    data: QualificationPricingListItemResponse[] | undefined;
    ok: boolean | undefined;
  };
  surcharges: {
    data: PspCustomerSurchargeResponse | undefined;
    ok: boolean | undefined;
  };
}

export const RELATED_CUSTOMER_LOCATIONS_INITIAL_STATE: BaseRelatedCustomerLocationsState = {
  list: {
    data: undefined,
    pagination: undefined,
    ok: undefined,
  },
  details: {
    data: undefined,
    ok: undefined,
  },
  customerLocationDocuments: {
    data: undefined,
    ok: undefined,
  },
  downloadDocument: {
    data: undefined,
  },
  departments: {
    data: undefined,
    ok: undefined,
  },
  priceList: {
    data: undefined,
    ok: undefined,
  },
  surcharges: {
    data: undefined,
    ok: undefined,
  },
};

export function BaseRelatedCustomerLocationsReducer(
  state: BaseRelatedCustomerLocationsState = RELATED_CUSTOMER_LOCATIONS_INITIAL_STATE,
  action: any
): any {
  return produce(state, (draftState) => {
    switch (action.type) {
      // Related customer locations list
      case baseRelatedCustomerLocationsActions.loadRelatedCustomerLocations.type:
        draftState.list.ok = undefined;
        return;

      case baseRelatedCustomerLocationsActions.setRelatedCustomerLocations.type:
        draftState.list.data = action.payload.data;
        draftState.list.pagination = action.payload.meta;
        return;

      case baseRelatedCustomerLocationsActions.successRelatedCustomerLocations.type:
        draftState.list.ok = true;
        return;

      case baseRelatedCustomerLocationsActions.errorRelatedCustomerLocations.type:
        draftState.list.ok = false;
        return;

      // Related customer location details
      case baseRelatedCustomerLocationsActions.loadRelatedCustomerLocationDetails.type:
        draftState.details.ok = undefined;
        return;

      case baseRelatedCustomerLocationsActions.setRelatedCustomerLocationDetails.type:
        draftState.details.data = action.payload;
        return;

      case baseRelatedCustomerLocationsActions.successRelatedCustomerLocationDetails.type:
        draftState.details.ok = true;
        return;

      case baseRelatedCustomerLocationsActions.errorRelatedCustomerLocationDetails.type:
        draftState.details.ok = false;
        return;

      // Customer location documents
      case baseRelatedCustomerLocationsActions.loadRelatedCustomerLocationDocuments.type:
        draftState.customerLocationDocuments.ok = undefined;
        return;

      case baseRelatedCustomerLocationsActions.setRelatedCustomerLocationDocuments.type:
        draftState.customerLocationDocuments.data = action.payload;
        return;

      case baseRelatedCustomerLocationsActions.successRelatedCustomerLocationDocuments.type:
        draftState.customerLocationDocuments.ok = true;
        return;

      case baseRelatedCustomerLocationsActions.errorRelatedCustomerLocationDocuments.type:
        draftState.customerLocationDocuments.ok = false;
        return;

      // Customer location document download
      case baseRelatedCustomerLocationsActions.setRelatedCustomerLocationDocument.type:
        draftState.downloadDocument.data = action.payload.document;
        return;

      case baseRelatedCustomerLocationsActions.clearDownloadRelatedCustomerLocationDocument.type:
        draftState.downloadDocument.data = undefined;
        return;

      // Departments
      case baseRelatedCustomerLocationsActions.loadDepartments.type:
        draftState.departments.ok = undefined;
        return;

      case baseRelatedCustomerLocationsActions.setDepartments.type:
        draftState.departments.data = action.payload;
        return;

      case baseRelatedCustomerLocationsActions.successDepartments.type:
        draftState.departments.ok = true;
        return;

      case baseRelatedCustomerLocationsActions.errorDepartments.type:
        draftState.departments.ok = false;
        return;

      // Price list
      case baseRelatedCustomerLocationsActions.loadRelatedCustomerLocationPriceList.type:
        draftState.priceList.ok = undefined;
        return;

      case baseRelatedCustomerLocationsActions.setRelatedCustomerLocationPriceList.type:
        draftState.priceList.data = action.payload;
        return;

      case baseRelatedCustomerLocationsActions.updateRelatedCustomerLocationPriceList.type:
        draftState.priceList.ok = undefined;
        return;

      case baseRelatedCustomerLocationsActions.successRelatedCustomerLocationPriceList.type:
        draftState.priceList.ok = true;
        return;

      case baseRelatedCustomerLocationsActions.errorRelatedCustomerLocationPriceList.type:
        draftState.priceList.ok = false;
        return;

      // Surcharges
      case baseRelatedCustomerLocationsActions.loadSurcharges.type:
        draftState.surcharges.ok = undefined;
        return;

      case baseRelatedCustomerLocationsActions.setSurcharges.type:
        draftState.surcharges.data = action.payload;
        return;

      case baseRelatedCustomerLocationsActions.successSurcharges.type:
        draftState.surcharges.ok = true;
        return;

      case baseRelatedCustomerLocationsActions.errorSurcharges.type:
        draftState.surcharges.ok = false;
        return;
    }
  });
}
