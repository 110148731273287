import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { BaseTokenService } from '../services/base-token.service';
import { Store } from '@ngrx/store';
import { baseAuthActions, BaseCoreState } from '../../_store';

@Injectable()
export class BaseAuthGuard {
  constructor(private tokenService: BaseTokenService, private store: Store<BaseCoreState>, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    const token: string = this.tokenService.getToken();
    const isValid = token && this.tokenService.isValid(token);
    if (token && isValid) {
      return true;
    } else {
      if (this.tokenService.isRefreshTokenValid()) {
        return true;
      } else {
        this.store.dispatch(baseAuthActions.navigateToLoginPage());
        return false;
      }
    }
  }
}
