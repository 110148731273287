/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CustomerDepartmentQualificationsListResponse } from '../models/customer-department-qualifications-list-response';
import { CustomerDepartmentResponse } from '../models/customer-department-response';
import { DepartmentListItemResponse } from '../models/department-list-item-response';
import { GeneralApiResponse } from '../models/general-api-response';
import { ListApiResponse } from '../models/list-api-response';
import { UserItemResponse } from '../models/user-item-response';

@Injectable({
  providedIn: 'root',
})
export class CustomerDepartmentService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getCustomerDepartmentListOfDepartments
   */
  static readonly GetCustomerDepartmentListOfDepartmentsPath = '/api/web/v1/customers/{customer}/departments';

  /**
   * Get list of customer departments for a customer.
   *
   * **Accessible only for users with permissions 'FEATURE_VIEW_DEPARTMENTS',
   * 'ROLE_CUSTOMER_ADMIN', 'ROLE_CUSTOMER_LOCATION_ADMIN'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerDepartmentListOfDepartments()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerDepartmentListOfDepartments$Response(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Filter active or inactive customer departments
     */
    active?: any;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ListApiResponse & {
'data'?: Array<DepartmentListItemResponse>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerDepartmentService.GetCustomerDepartmentListOfDepartmentsPath, 'get');
    if (params) {
      rb.path('customer', params.customer, {});
      rb.query('active', params.active, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListApiResponse & {
        'data'?: Array<DepartmentListItemResponse>;
        }>;
      })
    );
  }

  /**
   * Get list of customer departments for a customer.
   *
   * **Accessible only for users with permissions 'FEATURE_VIEW_DEPARTMENTS',
   * 'ROLE_CUSTOMER_ADMIN', 'ROLE_CUSTOMER_LOCATION_ADMIN'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCustomerDepartmentListOfDepartments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerDepartmentListOfDepartments(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Filter active or inactive customer departments
     */
    active?: any;
    context?: HttpContext
  }
): Observable<ListApiResponse & {
'data'?: Array<DepartmentListItemResponse>;
}> {

    return this.getCustomerDepartmentListOfDepartments$Response(params).pipe(
      map((r: StrictHttpResponse<ListApiResponse & {
'data'?: Array<DepartmentListItemResponse>;
}>) => r.body as ListApiResponse & {
'data'?: Array<DepartmentListItemResponse>;
})
    );
  }

  /**
   * Path part for operation getCustomerDepartmentQualificationsList
   */
  static readonly GetCustomerDepartmentQualificationsListPath = '/api/web/v1/customers/{customer}/departments/{department}/qualifications';

  /**
   * Customer Department Qualifications List.
   *
   * **Accessible only for users with permissions 'FEATURE_VIEW_DEPARTMENTS'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerDepartmentQualificationsList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerDepartmentQualificationsList$Response(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Department Id.
     */
    department: number;

    /**
     * Filter Qualification Category Id
     */
    category?: number;

    /**
     * Filter Customer Department Qualifications by Tag
     */
    tag?: 'business' | 'medical';
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ListApiResponse & {
'data'?: Array<CustomerDepartmentQualificationsListResponse>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerDepartmentService.GetCustomerDepartmentQualificationsListPath, 'get');
    if (params) {
      rb.path('customer', params.customer, {});
      rb.path('department', params.department, {});
      rb.query('category', params.category, {"style":"form"});
      rb.query('tag', params.tag, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListApiResponse & {
        'data'?: Array<CustomerDepartmentQualificationsListResponse>;
        }>;
      })
    );
  }

  /**
   * Customer Department Qualifications List.
   *
   * **Accessible only for users with permissions 'FEATURE_VIEW_DEPARTMENTS'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCustomerDepartmentQualificationsList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerDepartmentQualificationsList(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Department Id.
     */
    department: number;

    /**
     * Filter Qualification Category Id
     */
    category?: number;

    /**
     * Filter Customer Department Qualifications by Tag
     */
    tag?: 'business' | 'medical';
    context?: HttpContext
  }
): Observable<ListApiResponse & {
'data'?: Array<CustomerDepartmentQualificationsListResponse>;
}> {

    return this.getCustomerDepartmentQualificationsList$Response(params).pipe(
      map((r: StrictHttpResponse<ListApiResponse & {
'data'?: Array<CustomerDepartmentQualificationsListResponse>;
}>) => r.body as ListApiResponse & {
'data'?: Array<CustomerDepartmentQualificationsListResponse>;
})
    );
  }

  /**
   * Path part for operation getCustomerDepartmentShow
   */
  static readonly GetCustomerDepartmentShowPath = '/api/web/v1/customers/{customer}/departments/{department}';

  /**
   * Show a customer department.
   *
   * **Accessible only for users with roles 'ROLE_PSP_USER', 'ROLE_CUSTOMER_USER'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerDepartmentShow()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerDepartmentShow$Response(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Department Id.
     */
    department: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<GeneralApiResponse & {
'data'?: CustomerDepartmentResponse;
}>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerDepartmentService.GetCustomerDepartmentShowPath, 'get');
    if (params) {
      rb.path('customer', params.customer, {});
      rb.path('department', params.department, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GeneralApiResponse & {
        'data'?: CustomerDepartmentResponse;
        }>;
      })
    );
  }

  /**
   * Show a customer department.
   *
   * **Accessible only for users with roles 'ROLE_PSP_USER', 'ROLE_CUSTOMER_USER'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCustomerDepartmentShow$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerDepartmentShow(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Department Id.
     */
    department: number;
    context?: HttpContext
  }
): Observable<GeneralApiResponse & {
'data'?: CustomerDepartmentResponse;
}> {

    return this.getCustomerDepartmentShow$Response(params).pipe(
      map((r: StrictHttpResponse<GeneralApiResponse & {
'data'?: CustomerDepartmentResponse;
}>) => r.body as GeneralApiResponse & {
'data'?: CustomerDepartmentResponse;
})
    );
  }

  /**
   * Path part for operation getCustomersDepartmentUsersList
   */
  static readonly GetCustomersDepartmentUsersListPath = '/api/web/v1/customers/{customer}/departments/{department}/users';

  /**
   * List users for specified customer department.
   *
   * **Accessible only for users with roles 'ROLE_PSP_USER', 'ROLE_CUSTOMER_USER'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomersDepartmentUsersList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomersDepartmentUsersList$Response(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Department Id.
     */
    department: number;

    /**
     * User roles to filter them by. Possible values: user, admin
     */
    'role[]'?: any;

    /**
     * Search user by first-last name and email.
     */
    search?: any;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ListApiResponse & {
'data'?: Array<UserItemResponse>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerDepartmentService.GetCustomersDepartmentUsersListPath, 'get');
    if (params) {
      rb.path('customer', params.customer, {});
      rb.path('department', params.department, {});
      rb.query('role[]', params['role[]'], {"style":"form"});
      rb.query('search', params.search, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListApiResponse & {
        'data'?: Array<UserItemResponse>;
        }>;
      })
    );
  }

  /**
   * List users for specified customer department.
   *
   * **Accessible only for users with roles 'ROLE_PSP_USER', 'ROLE_CUSTOMER_USER'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCustomersDepartmentUsersList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomersDepartmentUsersList(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Department Id.
     */
    department: number;

    /**
     * User roles to filter them by. Possible values: user, admin
     */
    'role[]'?: any;

    /**
     * Search user by first-last name and email.
     */
    search?: any;
    context?: HttpContext
  }
): Observable<ListApiResponse & {
'data'?: Array<UserItemResponse>;
}> {

    return this.getCustomersDepartmentUsersList$Response(params).pipe(
      map((r: StrictHttpResponse<ListApiResponse & {
'data'?: Array<UserItemResponse>;
}>) => r.body as ListApiResponse & {
'data'?: Array<UserItemResponse>;
})
    );
  }

}
