export enum BaseAssignmentDateStatusEnum {
  OPEN = 'open',
  CANCELED = 'canceled',
  SUBMITTED = 'submitted',
  ACCEPTED = 'accepted',
  CORRECTED_BY_CUSTOMER = 'corrected_by_customer',
  CORRECTED_BY_PSP = 'corrected_by_psp',
  DELETED = 'deleted',
  AUTOMATICALLY_ACCEPTED = 'automatically_accepted',
  VERIFICATION_NEEDED = 'verification_needed',
  INCOMPLETE = 'incomplete',
}
