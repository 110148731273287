import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BaseInquiryScrollSliderEnum } from '../_enums/base-inquiry-scrolling-meta-data.enum';

@Injectable()
export class BaseInquiryScrollService {
  private scrollOffsetSubject = new BehaviorSubject<number>(0);
  scrollOffset$ = this.scrollOffsetSubject.asObservable();
  private visibleDatesQtySubject = new BehaviorSubject<number>(0);
  visibleDatesQty$ = this.visibleDatesQtySubject.asObservable();

  setScrollOffset(offset: number): void {
    this.scrollOffsetSubject.next(offset);
  }

  setSliderOffsetValue(action: BaseInquiryScrollSliderEnum, datesQty: number) {
    switch (action) {
      case BaseInquiryScrollSliderEnum.start:
        this.scrollOffsetSubject.next(0);
        break;
      case BaseInquiryScrollSliderEnum.end:
        this.scrollOffsetSubject.next(datesQty - this.visibleDatesQtySubject.value);
        break;
      case BaseInquiryScrollSliderEnum.left:
        this.scrollOffsetSubject.next(
          this.scrollOffsetSubject.value
            ? this.scrollOffsetSubject.value - (this.scrollOffsetSubject.value % 7 || 7)
            : 0
        );
        break;
      case BaseInquiryScrollSliderEnum.right:
        this.scrollOffsetSubject.next(
          this.scrollOffsetSubject.value < datesQty - this.visibleDatesQtySubject.value
            ? this.scrollOffsetSubject.value + (7 - (this.scrollOffsetSubject.value % 7))
            : datesQty - this.visibleDatesQtySubject.value
        );
        break;
    }
  }

  setVisibleDatesValue(value: number) {
    this.visibleDatesQtySubject.next(value);
  }
}
