import { Injectable } from '@angular/core';
import { addDays } from 'date-fns';
import { InquiryDateResponse, InquiryResponse } from '../../../../api';
import { BaseDateService } from '../../../shared/services/base-date.service';
import { BehaviorSubject } from 'rxjs';
import { BaseInquiryDate } from '../_models/base-inquiry-date';
import { BaseLocalStorageService } from '../../../core/services/base-local-storage.service';
import {
  getClosestDayOfLastWeek,
  getDaysArrayWithWeekdays,
} from '../../../shared/helpers/base-get-dates-array-between-two-dates';

@Injectable()
export class BaseInquiryDateService {
  datesArraySubject$: BehaviorSubject<BaseInquiryDate[]> = new BehaviorSubject<BaseInquiryDate[]>([]);

  constructor(private baseLocalStorageService: BaseLocalStorageService) {}

  prepareDates(inquiry: InquiryResponse, currentDate: Date): BaseInquiryDate[] {
    const maxDaysToScroll = 56;
    let inquiryDateTimes: number[] = [];
    if (inquiry.dates) {
      inquiry.dates[0]?.date ? new Date(inquiry.dates[0]?.date) : new Date();
      inquiryDateTimes = inquiry.dates.map((newInquiryDate: InquiryDateResponse) =>
        new Date(newInquiryDate.date).getTime()
      );
    }
    const maxDate = new Date(
      Math.max(...inquiryDateTimes, BaseDateService.addDays(currentDate, maxDaysToScroll).getTime())
    );
    const inquiryLength = BaseDateService.differenceInWeeks(currentDate, maxDate);
    let weekAmount = inquiryLength + (maxDate.getDay() > 1 && maxDate.getDay() <= 6 ? 2 : 1);

    const closestMonday = getClosestDayOfLastWeek('Mon', currentDate);
    const dates: string[] = getDaysArrayWithWeekdays(closestMonday, addDays(closestMonday, weekAmount * 7 - 1));

    return this.addDataToDatesArray(dates);
  }

  private addDataToDatesArray(dates: string[]): BaseInquiryDate[] {
    if (!dates.length) {
      return [];
    }

    let datesArray: BaseInquiryDate[] = [];
    let lastMonth = -1;
    let isNewMonth = false;
    const localeDateString: string = this.baseLocalStorageService.get('language') || 'de';
    dates.forEach((date) => {
      const dateItem = new Date(date);
      const currentMonth = dateItem.getMonth();
      isNewMonth = !isNewMonth && currentMonth !== lastMonth;
      lastMonth = dateItem.getMonth();
      const inquiryDate: BaseInquiryDate = {
        dateString: date,
        day: dateItem.toLocaleString(localeDateString, { day: '2-digit' }),
        isToday: false,
        isWeekend: BaseDateService.isWeekendDay(dateItem),
        firstDayOfMonth: isNewMonth,
        weekdayShort: dateItem.toLocaleDateString(localeDateString, {
          weekday: 'short',
        }),
        monthLong: dateItem.toLocaleDateString(localeDateString, {
          month: 'long',
        }),
        date: dateItem,
      };
      if (dateItem.getDay() === 1) {
        inquiryDate.calendarWeek = BaseDateService.weekNumber(date);
      }
      datesArray.push(inquiryDate);
    });

    return datesArray;
  }
}
