export enum BASE_PERMISSIONS {
  FEATURE_ADMIN_MASTER_DATA = 'FEATURE_ADMIN_MASTER_DATA',
  FEATURE_ADMIN_BRANCHES = 'FEATURE_ADMIN_BRANCHES',
  FEATURE_ADMIN_DOCUMENTS = 'FEATURE_ADMIN_DOCUMENTS',
  FEATURE_ADMIN_PROFILES = 'FEATURE_ADMIN_PROFILES',
  FEATURE_ADMIN_USERS = 'FEATURE_ADMIN_USERS',
  FEATURE_PUBLISH_INQUIRY = 'FEATURE_PUBLISH_INQUIRY',
  FEATURE_SIGN_CONTRACT = 'FEATURE_SIGN_CONTRACT',
  FEATURE_ADMIN_PRICE_LIST = 'FEATURE_ADMIN_PRICE_LIST',
  FEATURE_VIEW_DEPARTMENTS = 'FEATURE_VIEW_DEPARTMENTS',
  FEATURE_ADMIN_INQUIRY = 'FEATURE_ADMIN_INQUIRY',
  FEATURE_ADMIN_OFFERS = 'FEATURE_ADMIN_OFFERS',
  FEATURE_VIEW_RELATED_ORG = 'FEATURE_VIEW_RELATED_ORG',
  FEATURE_ACCEPT_OFFER = 'FEATURE_ACCEPT_OFFER',
  FEATURE_ADMIN_ASSIGNMENTS = 'FEATURE_ADMIN_ASSIGNMENTS',
  FEATURE_VIEW_DASHBOARD = 'FEATURE_VIEW_DASHBOARD',
  FEATURE_ADMIN_ASSIGNMENT_DATES = 'FEATURE_ADMIN_ASSIGNMENT_DATES',
  FEATURE_PRESELECT_OFFERS = 'FEATURE_PRESELECT_OFFERS',
}
