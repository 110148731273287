import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BaseButtonSize } from '../../../../../shared/components/button/base-button.component';
import { MainFormBillingAddressInterface } from '../../../_interfaces/base-administration.interface';

@Component({
  selector: 'base-address-form',
  templateUrl: './base-address-form.component.html',
  styleUrls: ['./base-address-form.component.scss'],
})
export class BaseAddressFormComponent {
  @Input() form: FormGroup<Partial<MainFormBillingAddressInterface>>;
  @Input() isEditMode = false;
  @Input() submitted = false;
  @Input() isPaymentAddressForm = false;
  @Input() headline = '';
  @Input() subTitle = '';
  @Output() sameAsBusiness: EventEmitter<boolean> = new EventEmitter<boolean>();
  buttonSizes = BaseButtonSize;

  copyFromBusinessAddress(): void {
    this.sameAsBusiness.emit(true);
  }
}
