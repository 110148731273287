import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Validators, FormGroup, FormArray, NonNullableFormBuilder } from '@angular/forms';
import { Store } from '@ngrx/store';
import { filter, map, takeUntil } from 'rxjs/operators';
import { noop, Subject } from 'rxjs';
import {
  GroupItemResponse,
  GroupListItemResponse,
  GroupUpdateRequest,
  PermissionItemResponse,
} from '../../../../../../api';
import { BaseButtonSize } from '../../../../../shared/components/button/base-button.component';
import { BaseModalService } from '../../../../../shared/services/base-modal.service';
import { baseUserActions, baseUserSelectors, BaseCoreState } from '../../../../../_store';
import { PermissionGroupFormInterface } from '../../../_interfaces/base-user-management.interface';

@Component({
  selector: 'base-edit-permission-groups-form',
  templateUrl: './base-edit-permission-groups-form.component.html',
  styleUrls: ['./base-edit-permission-groups-form.component.scss'],
})
export class BaseEditPermissionGroupsFormComponent implements OnInit, OnDestroy {
  @Input() permissions: PermissionItemResponse[];
  @Output() updateGroup: EventEmitter<{ groupId: number; body: GroupUpdateRequest }> = new EventEmitter<{
    groupId: number;
    body: GroupUpdateRequest;
  }>();

  permissionGroups: GroupListItemResponse[];
  selectedGroupPermissions: (PermissionItemResponse & { isSelected?: boolean })[];
  editPermissionGroupsForm = this.fb.group({
    permissionGroups: this.fb.array<FormGroup<PermissionGroupFormInterface>>([]),
  });
  permissionGroupsFormArray: FormArray<FormGroup<PermissionGroupFormInterface>>;
  editableFormIndex = -1;
  isFormSubmitted = false;
  buttonSizes = BaseButtonSize;

  private destroy$ = new Subject();

  constructor(
    private fb: NonNullableFormBuilder,
    private store: Store<BaseCoreState>,
    private modalService: BaseModalService
  ) {}

  ngOnInit(): void {
    this.permissionGroupsFormArray = this.editPermissionGroupsForm.get('permissionGroups') as FormArray<
      FormGroup<PermissionGroupFormInterface>
    >;

    this.store
      .select(baseUserSelectors.getUserGroups)
      .pipe(
        takeUntil(this.destroy$),
        map((state) => state.data as GroupListItemResponse[])
      )
      .subscribe((groups) => {
        this.permissionGroups = groups;
        this.setPermissionGroupsFormArray();
      });

    this.store
      .select(baseUserSelectors.getGroup)
      .pipe(
        takeUntil(this.destroy$),
        filter((state) => state.ok === true),
        map((state) => state.data as GroupItemResponse)
      )
      .subscribe((group) => {
        this.selectedGroupPermissions = [...this.permissions].map((permission) => {
          return { ...permission, isSelected: group.permissions.some((item) => item.id === permission.id) };
        });

        if (this.editableFormIndex > -1) {
          this.selectedGroupPermissions.forEach((permission) => {
            this.changePermission(permission.isSelected as boolean, permission.id, this.editableFormIndex);
          });
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  openGroupEditForm(index: number): void {
    this.editableFormIndex = -1;
    let openItemIndex: number = -1;
    const editableLineExist = this.permissionGroupsFormArray.value.some((item: any, i: number) => {
      openItemIndex = item.isEditable ? i : -1;
      return !!item.isEditable;
    });
    if (editableLineExist) {
      const activeForm = this.permissionGroupsFormArray.controls.find((form: any) => {
        return form.value.isEditable;
      });

      if (activeForm?.pristine && openItemIndex !== -1) {
        this.setControlValues(index, openItemIndex);
        return;
      }

      const activeName = activeForm?.get('name')?.value;
      const confirmationModalRef = this.modalService.open(
        {
          headText: 'permission.saveActiveHead',
          bodyText: 'permission.saveActiveBody',
          headTextTranslateParams: { activeName },
          bodyTextTranslateParams: { activeName },
          okText: 'action.yesSave',
        },
        { backdrop: 'static' }
      );
      confirmationModalRef.result.then(() => {
        this.onGroupUpdate(openItemIndex);
        this.setControlValues(index, openItemIndex);
      }, noop);
    } else {
      this.loadPermissionsForSelectedGroup(index);
    }
  }

  setControlValues(index: number, openItemIndex: number) {
    // Set control values without changes
    this.permissionGroupsFormArray.controls[openItemIndex].get('isEditable')?.setValue(false);
    this.permissionGroupsFormArray.controls[openItemIndex]
      .get('name')
      ?.setValue(this.permissionGroups[openItemIndex].name);

    // Load permissions for new editable open group
    this.loadPermissionsForSelectedGroup(index);
  }

  changePermission(event: boolean, permissionId: number, index: number, markAsDirty = false): void {
    this.selectedGroupPermissions.forEach((permission) => {
      if (permission.id === permissionId) {
        permission.isSelected = event;
      }
    });
    const control = this.permissionGroupsFormArray.controls[index].get('permissions');

    control?.setValue(
      this.selectedGroupPermissions.filter((permission) => permission.isSelected).map((permission) => permission.id)
    );
    if (markAsDirty) {
      control?.markAsDirty();
    }
  }

  onGroupUpdate(index: number): void {
    this.isFormSubmitted = true;
    if (this.permissionGroupsFormArray.controls[index].valid) {
      const permissionGroupValue = this.permissionGroupsFormArray.controls[index].getRawValue();
      const body: GroupUpdateRequest = { name: permissionGroupValue.name };

      if (permissionGroupValue.permissions) {
        body.permissions = permissionGroupValue.permissions;
      }

      this.updateGroup.emit({
        groupId: permissionGroupValue.id,
        body,
      });
      this.isFormSubmitted = false;
    }
  }

  private loadPermissionsForSelectedGroup(index: number): void {
    this.editableFormIndex = index;
    this.store.dispatch(
      baseUserActions.loadGroup({
        payload: { groupId: this.permissionGroupsFormArray.controls[index].get('id')?.value },
      })
    );
    this.permissionGroupsFormArray.controls[index].get('isEditable')?.setValue(true);
  }

  private setPermissionGroupsFormArray(): void {
    let controlIndex: number;
    this.permissionGroups.forEach((group) => {
      const isControlExist = this.permissionGroupsFormArray.controls.some((control, index) => {
        controlIndex = control.get('id')?.value === group.id ? index : -1;
        return control.get('id')?.value === group.id;
      });

      if (isControlExist) {
        this.permissionGroupsFormArray.controls[controlIndex].reset({
          id: group.id,
          isEditable: false,
          name: group.name,
          permissions: [],
        });
      } else {
        this.permissionGroupsFormArray.push(
          this.fb.group({
            id: [group.id],
            isEditable: [false],
            name: [group.name, [Validators.required]],
            permissions: [[] as number[]],
          })
        );
      }
    });
  }
}
