/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BestMatchingProfileListItemResponse } from '../models/best-matching-profile-list-item-response';
import { GeneralApiResponse } from '../models/general-api-response';
import { InquiryResponse } from '../models/inquiry-response';
import { ListApiResponse } from '../models/list-api-response';
import { PspBranchShortInfoResponse } from '../models/psp-branch-short-info-response';
import { PspInquiryFilterListsResponse } from '../models/psp-inquiry-filter-lists-response';
import { PaginatedApiResponse } from '../models/paginated-api-response';
import { PaginatedApiResponseWithFilters } from '../models/paginated-api-response-with-filters';
import { PspInquiryListItemResponse } from '../models/psp-inquiry-list-item-response';
import { UserShortInfoResponse } from '../models/user-short-info-response';

@Injectable({
  providedIn: 'root',
})
export class PspInquiryService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getPspInquiryBranchList
   */
  static readonly GetPspInquiryBranchListPath = '/api/web/v1/psps/{psp}/inquiries/{inquiry}/branches';

  /**
   * Get list of PSP branches which are relevant for the given inquiry.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_OFFERS'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPspInquiryBranchList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPspInquiryBranchList$Response(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * Inquiry Id.
     */
    inquiry: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ListApiResponse & {
'data'?: Array<PspBranchShortInfoResponse>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, PspInquiryService.GetPspInquiryBranchListPath, 'get');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.path('inquiry', params.inquiry, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListApiResponse & {
        'data'?: Array<PspBranchShortInfoResponse>;
        }>;
      })
    );
  }

  /**
   * Get list of PSP branches which are relevant for the given inquiry.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_OFFERS'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPspInquiryBranchList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPspInquiryBranchList(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * Inquiry Id.
     */
    inquiry: number;
    context?: HttpContext
  }
): Observable<ListApiResponse & {
'data'?: Array<PspBranchShortInfoResponse>;
}> {

    return this.getPspInquiryBranchList$Response(params).pipe(
      map((r: StrictHttpResponse<ListApiResponse & {
'data'?: Array<PspBranchShortInfoResponse>;
}>) => r.body as ListApiResponse & {
'data'?: Array<PspBranchShortInfoResponse>;
})
    );
  }

  /**
   * Path part for operation getPspInquiryResponsibleList
   */
  static readonly GetPspInquiryResponsibleListPath = '/api/web/v1/psps/{psp}/inquiries/{inquiry}/branches/users';

  /**
   * Get list of responsible users.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_OFFERS'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPspInquiryResponsibleList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPspInquiryResponsibleList$Response(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * Inquiry Id.
     */
    inquiry: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ListApiResponse & {
'data'?: Array<UserShortInfoResponse>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, PspInquiryService.GetPspInquiryResponsibleListPath, 'get');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.path('inquiry', params.inquiry, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListApiResponse & {
        'data'?: Array<UserShortInfoResponse>;
        }>;
      })
    );
  }

  /**
   * Get list of responsible users.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_OFFERS'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPspInquiryResponsibleList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPspInquiryResponsibleList(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * Inquiry Id.
     */
    inquiry: number;
    context?: HttpContext
  }
): Observable<ListApiResponse & {
'data'?: Array<UserShortInfoResponse>;
}> {

    return this.getPspInquiryResponsibleList$Response(params).pipe(
      map((r: StrictHttpResponse<ListApiResponse & {
'data'?: Array<UserShortInfoResponse>;
}>) => r.body as ListApiResponse & {
'data'?: Array<UserShortInfoResponse>;
})
    );
  }

  /**
   * Path part for operation getPspListOfInquiries
   */
  static readonly GetPspListOfInquiriesPath = '/api/web/v1/psps/{psp}/inquiries';

  /**
   * Get list of PSP related Inquires.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_OFFERS'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPspListOfInquiries()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPspListOfInquiries$Response(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * Page number in pagination.
     */
    page?: any;

    /**
     * Size number in pagination.
     */
    size?: any;

    /**
     * Filter by inquiry status: published (status&#x3D;2), closed (status&#x3D;3)
     */
    status?: any;

    /**
     * Filter by Customer Ids
     */
    'customers[]'?: any;

    /**
     * Filter by PSP Branch Id
     */
    PSPBranch?: any;

    /**
     * Filter by Main Qualification Ids
     */
    'mainQualifications[]'?: any;

    /**
     * Customer department Ids to filter inquiries by.
     */
    'customerDepartments[]'?: any;

    /**
     * Order inquiries by key.
     */
    orderKey?: 'createdAt' | 'departmentName' | 'customerName' | 'mainQualificationName' | 'startDate' | 'status';

    /**
     * Order direction.
     */
    orderDirection?: 'ASC' | 'DESC';
    context?: HttpContext
  }
): Observable<StrictHttpResponse<PaginatedApiResponseWithFilters & {
'data'?: Array<PspInquiryListItemResponse>;
'filters'?: PspInquiryFilterListsResponse;
}>> {

    const rb = new RequestBuilder(this.rootUrl, PspInquiryService.GetPspListOfInquiriesPath, 'get');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.query('page', params.page, {});
      rb.query('size', params.size, {});
      rb.query('status', params.status, {"style":"form"});
      rb.query('customers[]', params['customers[]'], {"style":"form"});
      rb.query('PSPBranch', params.PSPBranch, {"style":"form"});
      rb.query('mainQualifications[]', params['mainQualifications[]'], {"style":"form"});
      rb.query('customerDepartments[]', params['customerDepartments[]'], {"style":"form"});
      rb.query('orderKey', params.orderKey, {});
      rb.query('orderDirection', params.orderDirection, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaginatedApiResponseWithFilters & {
        'data'?: Array<PspInquiryListItemResponse>;
        'filters'?: PspInquiryFilterListsResponse;
        }>;
      })
    );
  }

  /**
   * Get list of PSP related Inquires.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_OFFERS'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPspListOfInquiries$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPspListOfInquiries(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * Page number in pagination.
     */
    page?: any;

    /**
     * Size number in pagination.
     */
    size?: any;

    /**
     * Filter by inquiry status: published (status&#x3D;2), closed (status&#x3D;3)
     */
    status?: any;

    /**
     * Filter by Customer Ids
     */
    'customers[]'?: any;

    /**
     * Filter by PSP Branch Id
     */
    PSPBranch?: any;

    /**
     * Filter by Main Qualification Ids
     */
    'mainQualifications[]'?: any;

    /**
     * Customer department Ids to filter inquiries by.
     */
    'customerDepartments[]'?: any;

    /**
     * Order inquiries by key.
     */
    orderKey?: 'createdAt' | 'departmentName' | 'customerName' | 'mainQualificationName' | 'startDate' | 'status';

    /**
     * Order direction.
     */
    orderDirection?: 'ASC' | 'DESC';
    context?: HttpContext
  }
): Observable<PaginatedApiResponseWithFilters & {
'data'?: Array<PspInquiryListItemResponse>;
'filters'?: PspInquiryFilterListsResponse;
}> {

    return this.getPspListOfInquiries$Response(params).pipe(
      map((r: StrictHttpResponse<PaginatedApiResponseWithFilters & {
'data'?: Array<PspInquiryListItemResponse>;
'filters'?: PspInquiryFilterListsResponse;
}>) => r.body as PaginatedApiResponseWithFilters & {
'data'?: Array<PspInquiryListItemResponse>;
'filters'?: PspInquiryFilterListsResponse;
})
    );
  }

  /**
   * Path part for operation getAppApiWebV1PspInquiryProfileListIndex
   */
  static readonly GetAppApiWebV1PspInquiryProfileListIndexPath = '/api/web/v1/psps/{psp}/inquiries/{inquiry}/profiles';

  /**
   * Get a list of best matching candidate profiles to provided inquiry.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_OFFERS'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAppApiWebV1PspInquiryProfileListIndex()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAppApiWebV1PspInquiryProfileListIndex$Response(params: {

    /**
     * PSP Id.
     */
    psp: number;
    inquiry: number;

    /**
     * Search by given keyword for profile with matched first/last name and email.
     */
    search?: any;

    /**
     * Page number in pagination.
     */
    page?: any;

    /**
     * Size number in pagination.
     */
    size?: any;

    /**
     * Filter by main qualifications.
     */
    'mainQualifications[]'?: any;

    /**
     * Profile order key.
     */
    orderKey?: 'profileLastName' | 'profileMainQualificationName';

    /**
     * Profile direction.
     */
    orderDirection?: 'ASC' | 'DESC';
    context?: HttpContext
  }
): Observable<StrictHttpResponse<PaginatedApiResponse & {
'data'?: Array<BestMatchingProfileListItemResponse>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, PspInquiryService.GetAppApiWebV1PspInquiryProfileListIndexPath, 'get');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.path('inquiry', params.inquiry, {});
      rb.query('search', params.search, {"style":"form"});
      rb.query('page', params.page, {});
      rb.query('size', params.size, {});
      rb.query('mainQualifications[]', params['mainQualifications[]'], {});
      rb.query('orderKey', params.orderKey, {});
      rb.query('orderDirection', params.orderDirection, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaginatedApiResponse & {
        'data'?: Array<BestMatchingProfileListItemResponse>;
        }>;
      })
    );
  }

  /**
   * Get a list of best matching candidate profiles to provided inquiry.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_OFFERS'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAppApiWebV1PspInquiryProfileListIndex$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAppApiWebV1PspInquiryProfileListIndex(params: {

    /**
     * PSP Id.
     */
    psp: number;
    inquiry: number;

    /**
     * Search by given keyword for profile with matched first/last name and email.
     */
    search?: any;

    /**
     * Page number in pagination.
     */
    page?: any;

    /**
     * Size number in pagination.
     */
    size?: any;

    /**
     * Filter by main qualifications.
     */
    'mainQualifications[]'?: any;

    /**
     * Profile order key.
     */
    orderKey?: 'profileLastName' | 'profileMainQualificationName';

    /**
     * Profile direction.
     */
    orderDirection?: 'ASC' | 'DESC';
    context?: HttpContext
  }
): Observable<PaginatedApiResponse & {
'data'?: Array<BestMatchingProfileListItemResponse>;
}> {

    return this.getAppApiWebV1PspInquiryProfileListIndex$Response(params).pipe(
      map((r: StrictHttpResponse<PaginatedApiResponse & {
'data'?: Array<BestMatchingProfileListItemResponse>;
}>) => r.body as PaginatedApiResponse & {
'data'?: Array<BestMatchingProfileListItemResponse>;
})
    );
  }

  /**
   * Path part for operation getPspInquiryShow
   */
  static readonly GetPspInquiryShowPath = '/api/web/v1/psps/{psp}/inquiries/{inquiry}';

  /**
   * Get inquiry.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_OFFERS'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPspInquiryShow()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPspInquiryShow$Response(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * Inquiry Id.
     */
    inquiry: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<GeneralApiResponse & {
'data'?: InquiryResponse;
}>> {

    const rb = new RequestBuilder(this.rootUrl, PspInquiryService.GetPspInquiryShowPath, 'get');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.path('inquiry', params.inquiry, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GeneralApiResponse & {
        'data'?: InquiryResponse;
        }>;
      })
    );
  }

  /**
   * Get inquiry.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_OFFERS'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPspInquiryShow$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPspInquiryShow(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * Inquiry Id.
     */
    inquiry: number;
    context?: HttpContext
  }
): Observable<GeneralApiResponse & {
'data'?: InquiryResponse;
}> {

    return this.getPspInquiryShow$Response(params).pipe(
      map((r: StrictHttpResponse<GeneralApiResponse & {
'data'?: InquiryResponse;
}>) => r.body as GeneralApiResponse & {
'data'?: InquiryResponse;
})
    );
  }

}
