<div class="d-flex flex-wrap justify-content-between">
  <h3>{{ 'administration.profileInfo' | translate }}</h3>
  <div class="d-flex mb-2 mb-lg-0">
    <base-button
      [iconSize]="buttonSizes.md"
      [color]="isPreviewMode ? 'secondary' : 'primary'"
      [label]="isPreviewMode ? 'action.edit' : 'action.save'"
      class="me-3"
      (btnClick)="isPreviewMode ? editProfileForm() : submitProfileForm()"
    ></base-button>
    <base-button
      [iconSize]="buttonSizes.md"
      color="secondary"
      label="auth.password.changeTitle"
      (btnClick)="openChangePasswordModal()"
    ></base-button>
  </div>
</div>
<hr class="mt-0 mb-7" />
<form
  [formGroup]="profileForm"
  [ngClass]="{ 'was-validated': profileFormSubmitted }"
>
  <div class="d-flex flex-column flex-lg-row gap-4 mb-7 me-7">
    <div class="col-12 col-md-8 col-lg-4">
      <base-dropdown
        label="title"
        [requiredLabel]="true"
        [items]="genderOptions"
        [translateItems]="true"
        [searchable]="false"
        bindOptionValue="value"
        bindOptionLabel="label"
        formControlName="gender"
        [preview]="isPreviewMode"
      ></base-dropdown>
    </div>
    <div class="col-12 col-md-8 col-lg-4">
      <base-input
        label="position"
        [isInvalid]="profileForm.controls.position.touched && !!profileForm.controls.position.errors"
        [errorMessage]="profileForm.controls.position.errors | baseFieldValidation"
        placeholder="position"
        [isPreview]="isPreviewMode"
        formControlName="position"
      ></base-input>
    </div>
  </div>
  <div class="d-flex flex-column flex-lg-row gap-4 mb-7 me-7">
    <div class="col-12 col-md-8 col-lg-4">
      <base-input
        label="firstName"
        [isInvalid]="profileForm.controls.firstName.touched && !!profileForm.controls.firstName.errors"
        [errorMessage]="profileForm.controls.firstName.errors | baseFieldValidation: { required: 'yourFirstName' }"
        placeholder="firstName"
        [isRequired]="true"
        invalidIcon="fa-solid fa-xmark"
        [isPreview]="isPreviewMode"
        formControlName="firstName"
      ></base-input>
    </div>
    <div class="col-12 col-md-8 col-lg-4">
      <base-input
        label="lastName"
        [isInvalid]="profileForm.controls.lastName.touched && !!profileForm.controls.lastName.errors"
        [errorMessage]="profileForm.controls.lastName.errors | baseFieldValidation: { required: 'yourLastName' }"
        placeholder="lastName"
        [isRequired]="true"
        [isPreview]="isPreviewMode"
        invalidIcon="fa-solid fa-xmark"
        formControlName="lastName"
      ></base-input>
    </div>
  </div>
  <div class="d-flex flex-column flex-lg-row gap-4 mb-7 me-7">
    <div class="col-12 col-md-8 col-lg-4">
      <base-input
        label="email"
        [isInvalid]="profileForm.controls.email.touched && !!profileForm.controls.email.errors"
        [errorMessage]="profileForm.controls.email.errors | baseFieldValidation"
        placeholder="email"
        [isPreview]="true"
        [isEmailField]="true"
        formControlName="email"
      ></base-input>
    </div>
    <div class="col-12 col-md-8 col-lg-4">
      <base-input
        label="phone"
        [isInvalid]="profileForm.controls.phone.touched && !!profileForm.controls.phone.errors"
        [errorMessage]="profileForm.controls.phone.errors | baseFieldValidation"
        placeholder="phone"
        [isPreview]="isPreviewMode"
        formControlName="phone"
      ></base-input>
    </div>
    <div class="col-12 col-md-8 col-lg-4">
      <base-input
        label="mobile"
        [isInvalid]="profileForm.controls.mobilePhone.touched && !!profileForm.controls.mobilePhone.errors"
        [errorMessage]="profileForm.controls.mobilePhone.errors | baseFieldValidation"
        placeholder="placeholders.mobilePhone"
        [isPreview]="isPreviewMode"
        formControlName="mobilePhone"
      ></base-input>
    </div>
  </div>
  <div class="d-flex justify-content-end mt-5">
    <base-button
      [iconSize]="buttonSizes.md"
      color="primary"
      label="action.save"
      class="me-3"
      *ngIf="!isPreviewMode"
      (btnClick)="submitProfileForm()"
    ></base-button>
  </div>
</form>

<h3>{{ 'administration.notificationSettings.title' | translate }}</h3>
<hr class="mt-0 mb-7" />
<div class="d-flex flex-column">
  <div class="d-flex">
    <p class="settings-label">{{ 'administration.notificationSettings.allEmailNotificationsLabel' | translate }}</p>
    <base-switch
      [(ngModel)]="areEmailNotificationsActive"
      (ngModelChange)="activateEmailNotifications($event)"
    ></base-switch>
  </div>
  <p>{{ 'administration.notificationSettings.allEmailNotificationsDescription' | translate }}</p>
</div>

<ng-template #changePasswordPopup>
  <form
    [formGroup]="newPasswordForm"
    [ngClass]="{ 'was-validated': changePasswordFormSubmitted }"
  >
    <div
      class="mb-6"
      [ngClass]="{ oldPasswordInvalid: isInvalidOldPassword }"
    >
      <base-password
        label="auth.password.current"
        [isInvalid]="
          (newPasswordForm.get('oldPassword').touched && !!newPasswordForm.get('oldPassword').errors) ||
          isInvalidOldPassword
        "
        [errors]="newPasswordForm.get('oldPassword').errors"
        [errorMessageOverride]="{ required: 'oldPassword' }"
        [errorMessage]="isInvalidOldPassword ? 'validation.oldPasswordInvalid' : ''"
        formControlName="oldPassword"
        [isLabelPositionLeft]="true"
        invalidIcon="fa-solid fa-xmark"
        [isEyeIcon]="true"
        class="old-password-field"
        [isRequired]="true"
      ></base-password>
    </div>
    <div class="mb-6">
      <base-password
        label="auth.password.new"
        [isInvalid]="newPasswordForm.get('newPassword').touched && !!newPasswordForm.get('newPassword').errors"
        [errors]="newPasswordForm.get('newPassword').errors"
        [errorMessageOverride]="{ required: 'password' }"
        formControlName="newPassword"
        [isLabelPositionLeft]="true"
        invalidIcon="fa-solid fa-xmark"
        [isEyeIcon]="true"
        [isRequired]="true"
      ></base-password>
    </div>
    <base-password
      label="auth.password.repeat"
      [isInvalid]="
        newPasswordForm.get('newPasswordRepeat').touched && !!newPasswordForm.get('newPasswordRepeat').errors
      "
      [errors]="newPasswordForm.get('newPasswordRepeat').errors"
      [errorMessageOverride]="{ required: 'confirmPasswordRequired', isMatching: 'confirmPasswordInvalid' }"
      formControlName="newPasswordRepeat"
      [isLabelPositionLeft]="true"
      invalidIcon="fa-solid fa-xmark"
      [isEyeIcon]="true"
      [isRequired]="true"
    ></base-password>
    <div class="d-flex justify-content-between mt-7 mb-5">
      <base-button
        label="action.cancel"
        color="secondary"
        (btnClick)="cancelPasswordForm()"
      ></base-button>
      <base-button
        label="auth.password.changeTitle"
        color="primary"
        (btnClick)="submitPasswordForm()"
      ></base-button>
    </div>
  </form>
</ng-template>
