import { Component } from '@angular/core';
import { BaseAuthenticationService } from '../../../modules/auth/base-auth.service';
import { BaseButtonSize } from '../../../shared/components/button/base-button.component';
import { BaseLanguageSwitchColors } from '../../../shared/enums/base-language-switch-colors.enum';
import { BaseSharedService } from '../../../shared/services/base-shared.service';

@Component({
  selector: 'base-unbranded-wrapper',
  templateUrl: './base-unbranded-wrapper.component.html',
})
export class BaseUnbrandedWrapperComponent {
  buttonSize = BaseButtonSize;
  colors = BaseLanguageSwitchColors;

  constructor(private readonly authService: BaseAuthenticationService, private baseSharedService: BaseSharedService) {}

  goToContactUrl() {
    this.authService.openContactsPage();
  }

  downloadPrivacyPolicy(): void {
    this.baseSharedService.downloadLegalDocument(true);
  }
}
