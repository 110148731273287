import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { baseSharedSelectors, BaseCoreState } from '../../../_store';
import { BaseToastAlertInfoInterface } from '../../interfaces/base-toast-alert-info.interface';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'base-toast-alerts',
  templateUrl: './base-toast-alerts.component.html',
})
export class BaseToastAlertsComponent implements OnInit {
  alerts$: Observable<BaseToastAlertInfoInterface[]>;

  constructor(private store: Store<BaseCoreState>) {}

  ngOnInit(): void {
    this.alerts$ = this.store.select(baseSharedSelectors.getSystemAlertsData).pipe(delay(0));
  }
}
