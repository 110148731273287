import { Directive, DoCheck, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[baseTruncate]',
})
export class BaseTextTruncateDirective implements DoCheck {
  constructor(public renderer: Renderer2, public hostElement: ElementRef) {}

  ngDoCheck(): void {
    const host = this.hostElement.nativeElement;
    if (host.innerText && !host.classList.contains('text-truncate')) {
      this.renderer.addClass(host, 'text-truncate');
      if (host.offsetWidth < host.scrollWidth) {
        const tooltipHost = this.renderer.createElement('div');
        this.renderer.addClass(tooltipHost, 'tooltip-host');
        const tooltip = this.renderer.createElement('span');
        this.renderer.addClass(tooltip, 'truncate-tooltip');
        const text = this.renderer.createText(host.innerText);
        this.renderer.appendChild(host, tooltipHost);
        this.renderer.appendChild(tooltipHost, tooltip);
        this.renderer.appendChild(tooltip, text);
      }
    }
  }
}
