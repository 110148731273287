import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { baseCandidateActions, baseCandidatesSelectors, BaseCoreState } from '../../../_store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { BaseRolesService } from '../../../core/services/base-roles.service';
import { BASE_PERMISSIONS } from '../../../core/constants/base-permissions';

@Injectable()
export class BaseCandidateResponsibleUsersGuard {
  constructor(private readonly store: Store<BaseCoreState>, private rolesService: BaseRolesService) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | boolean {
    if (!this.rolesService.hasFeaturePermission(BASE_PERMISSIONS.FEATURE_ADMIN_PROFILES)) {
      return true;
    }

    this.store.dispatch(baseCandidateActions.loadResponsibleUserList());

    return this.store.select(baseCandidatesSelectors.getCandidateResponsibleUserList).pipe(
      filter((state) => state.ok !== undefined),
      map((state) => state.ok as boolean)
    );
  }
}
