<ng-select
  [items]="items"
  [(ngModel)]="value"
  bindLabel="version"
  groupBy="valid"
  [searchable]="false"
  [clearable]="false"
  (change)="changeHandler.emit($event)"
>
  <ng-template
    ng-label-tmp
    let-item="item"
  >
    <span class="fw-bold text-capitalize"
      >{{ 'contractRegeneration.versionMessage' | translate }} {{ item.version }}</span
    >
  </ng-template>

  <ng-template
    ng-option-tmp
    let-item="item"
  >
    <span class="fw-bold text-capitalize"
      >{{ 'contractRegeneration.versionMessage' | translate }} {{ item.version }}</span
    >
  </ng-template>

  <ng-template
    ng-optgroup-tmp
    let-item="item"
  >
    <div *ngIf="!item.valid">
      <span>{{ 'contractRegeneration.olderVersionsText' | translate }}</span>
    </div>
  </ng-template>
</ng-select>
