import { Component, EventEmitter, forwardRef, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'base-checkbox',
  templateUrl: './base-checkbox.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BaseCheckboxComponent),
      multi: true,
    },
  ],
})
export class BaseCheckboxComponent implements ControlValueAccessor, OnInit {
  @Input() label: string | TemplateRef<any> = '';
  @Input() isChecked = false;
  @Input() isDisabled = false;
  @Input() isRequired = false;
  @Input() isInvalid = false;
  @Input() checkboxClasses = '';
  @Input() errorMessage = '';
  @Input() labelClasses = '';
  @Input() labelTranslateParams: any;
  @Input() tooltip: string;
  @Output() getChange = new EventEmitter();
  public labelTemplate: TemplateRef<any>;
  private onChange = (_: any) => {};
  private onTouched = () => {};

  ngOnInit(): void {
    if (this.label instanceof TemplateRef) {
      this.labelTemplate = this.label;
    }
  }

  get value(): any {
    return this.isChecked;
  }

  set value(value: any) {
    this.isChecked = value;
  }

  onValueChange(isChecked: boolean): void {
    this.value = isChecked;
    this.getChange.emit(this.value);
    this.onChange(this.value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => {}): void {
    this.onTouched = fn;
  }

  writeValue(outsideValue: boolean): void {
    this.value = outsideValue;
  }
}
