export enum OfferBubbleValues {
  Default = '',
  Selected = 'selected',
  Booked = 'booked',
  OfferedByServiceProvider = 'offeredByProvider',
  Rejected = 'rejected',
  NeedsConfirmation = 'needsConfirmation',
}

export enum OfferBubbleValueTranslationKeys {
  selected = 'shiftStatus.value.selected',
  booked = 'shiftStatus.value.booked',
  offeredByProvider = 'shiftStatus.value.offeredByProvider',
  rejected = 'shiftStatus.value.rejected',
  needsConfirmation = 'shiftStatus.value.needsConfirmation',
}
