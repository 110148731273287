<ngx-extended-pdf-viewer
  [ngClass]="{ 'empty-toolbar': !customToolbar, 'hide-border': hideBorder }"
  *ngIf="!pdfLoadingFailed"
  [customToolbar]="customToolbar ? customToolbar : emptyToolbar"
  [filenameForDownload]="fileName"
  [showUnverifiedSignatures]="true"
  [src]="src"
  (pdfLoadingFailed)="onPdfLoadingFailed()"
  [height]="height"
  [logLevel]="0"
  [language]="translateService.currentLang"
  [useBrowserLocale]="true"
></ngx-extended-pdf-viewer>

<ng-container *ngIf="pdfLoadingFailed">
  <ng-container *ngTemplateOutlet="customToolbar || emptyToolbar"></ng-container>
  <div class="d-flex flex-column">
    <p>{{ 'document.pdfViewerError' | translate }}</p>
    <img
      class="error-image"
      src="assets-global/images/file-error-{{ application | lowercase }}.svg"
      alt="file-error"
    />
  </div>
</ng-container>

<ng-template #emptyToolbar> </ng-template>
