import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseLocalStorageService } from '../services/base-local-storage.service';

@Injectable()
export class BaseAcceptLanguageInterceptor implements HttpInterceptor {
  private LANGUAGE_HEADER = 'Accept-Language';

  constructor(private localStorage: BaseLocalStorageService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(this.addAcceptLanguageHeader(request));
  }

  addAcceptLanguageHeader(request: HttpRequest<any>): HttpRequest<any> {
    return request.clone({
      headers: request.headers.set(this.LANGUAGE_HEADER, this.currentLanguage),
    });
  }

  get currentLanguage(): string {
    return this.localStorage.get('language') ? (this.localStorage.get('language') as string) : 'de';
  }
}
