import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { baseAssignmentsActions, baseAssignmentsSelectors, BaseCoreState } from '../../../_store';

@Injectable()
export class BaseCustomerAllAssignmentsListGuard {
  constructor(private store: Store<BaseCoreState>) {}

  canActivate(): Observable<boolean> {
    this.store.dispatch(baseAssignmentsActions.loadCustomerAllAssignments({ payload: { page: 1 } }));

    return this.store.select(baseAssignmentsSelectors.getAllCustomerAssignments).pipe(
      filter((state) => state.ok !== undefined),
      map((state) => state.ok as boolean)
    );
  }
}
