<span
  class="k-checkbox-weekdays"
  [ngbTooltip]="tooltip | translate"
  [ngClass]="{ selected: isChecked, 'only-icon': onlyIcon }"
  (click)="onValueChange(!isChecked)"
>
  <label
    class="m-0 pointer"
    *ngIf="!icon && name"
    >{{ name | translate }}</label
  >
  <span
    class="pointer"
    [ngClass]="icon"
    *ngIf="icon"
  ></span>
</span>
