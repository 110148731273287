import { Component, Input, SimpleChanges, OnInit, OnChanges, TemplateRef } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { BaseAvatarSize } from '../../../../shared/components/avatar/base-avatar.component';
import { BaseShiftIconsEnum } from '../../_enums/base-inquiry.enum';
import {
  InquiryResponse,
  InquiryCreateRequest,
  ShiftTimeRequest,
  ShiftTimeResponse,
  InquiryDateResponse,
} from '../../../../../api';
import { baseInquiryActions, BaseCoreState } from '../../../../_store';
import { BaseInquiryService } from '../../_services/base-inquiry.service';
import { WeekdaysArray } from '../../../../core/constants/base-calendar-values';

@Component({
  selector: 'base-inquiry-details-sidebar-content',
  templateUrl: './base-inquiry-details-sidebar-content.component.html',
})
export class BaseInquiryDetailsSidebarContentComponent implements OnInit, OnChanges {
  @Input() inquiry: InquiryResponse;
  @Input() inquiryId: number;
  @Input() sidebarContent: TemplateRef<any>;
  @Input() isSidebarVisible = false;
  @Input() shiftTimes: (ShiftTimeResponse & { index: number; label?: string })[] = [];

  submitted = false;
  isEditNote = false;
  jobDescription = new FormControl('', {
    nonNullable: true,
    validators: [Validators.required, Validators.maxLength(255)],
  });
  savedJobDescriptionValue: string;
  weekdaysArray = WeekdaysArray;
  inquiryWeekdays: {
    mo: boolean;
    tu: boolean;
    we: boolean;
    th: boolean;
    fr: boolean;
    sa: boolean;
    su: boolean;
  };

  avatarSize = BaseAvatarSize;
  baseShiftIconsEnum = BaseShiftIconsEnum;

  constructor(private store: Store<BaseCoreState>, private baseInquiryService: BaseInquiryService) {}

  ngOnInit() {
    if (this.inquiry.jobDescription) {
      this.jobDescription.setValue(this.inquiry.jobDescription);
      this.savedJobDescriptionValue = this.inquiry.jobDescription;
    }
    this.inquiryWeekdays = this.baseInquiryService.getInquiryWeekdays(this.inquiry.dates as InquiryDateResponse[]);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.isSidebarVisible) {
      const isVisible = changes.isSidebarVisible?.currentValue;

      if (!isVisible && this.isEditNote) {
        this.cancelJobDescriptionUpdate();
      }
    }
  }

  cancelJobDescriptionUpdate() {
    this.isEditNote = false;
    this.submitted = false;
    this.jobDescription.setValue(this.savedJobDescriptionValue);
  }

  updateJobDescription() {
    if (this.isEditNote) {
      this.submitted = true;
      this.jobDescription.markAllAsTouched();
      if (this.jobDescription.valid) {
        this.store.dispatch(
          baseInquiryActions.updateCustomerInquiry({
            payload: {
              inquiry: this.inquiryId.toString(),
              body: this.collectCustomerInquiryData(),
            },
          })
        );
        this.isEditNote = false;
        this.submitted = false;
        this.savedJobDescriptionValue = this.jobDescription.getRawValue();
      }
    } else {
      this.isEditNote = !this.isEditNote;
      this.submitted = false;
    }
  }

  private collectCustomerInquiryData(): InquiryCreateRequest {
    const body: InquiryCreateRequest = {
      unlimited: !!this.inquiry.unlimited,
      customerDepartment: this.inquiry.customerDepartment?.id as number,
      mainQualification: this.inquiry.mainQualification?.id as number,
      responsible: this.inquiry.responsible?.id as number,
      jobDescription: this.jobDescription.getRawValue(),
      costUnit: this.inquiry.costUnit || null,
      workEquipmentCustomer: this.inquiry.workEquipmentCustomer || null,
      workEquipmentPsp: this.inquiry.workEquipmentPsp || null,
      shiftTimes: this.inquiry.shiftTimes as ShiftTimeRequest[],
    };

    if (this.inquiry.dates) {
      body.dates = this.inquiry.dates;
    }

    if (this.inquiry.qualifications) {
      body.qualifications = this.inquiry.qualifications.map((item) => item.id);
    }

    if (this.inquiry.requirements) {
      body.requirements = this.baseInquiryService
        .ungroupRequirements(this.inquiry?.requirements)
        .map((item) => item.id);
    }

    if (this.inquiry.weeklyWorkingHours) {
      body.weeklyWorkingHours = this.inquiry.weeklyWorkingHours;
    }

    return body;
  }
}
