export const baseRemoveEmpty = (obj: any) => {
  return Object.keys(obj)
    .filter((k: any) => !!obj[k] || typeof obj[k] === 'boolean')
    .reduce((acc: any, k) => {
      if (Array.isArray(obj[k])) {
        acc[k] = obj[k].concat();
      } else if (typeof obj[k] === 'object') {
        acc[k] = baseRemoveEmpty(obj[k]);
      } else {
        acc[k] = obj[k];
      }
      return acc;
    }, {});
};
