import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'base-checkbox-weekdays',
  templateUrl: './base-checkbox-weekdays.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BaseCheckboxWeekdaysComponent),
      multi: true,
    },
  ],
})
export class BaseCheckboxWeekdaysComponent implements ControlValueAccessor {
  @Input() name: string;
  @Input() icon: string;
  @Input() tooltip: string;
  @Input() onlyIcon: boolean;
  @Input() isDisabled = false;
  isChecked = false;
  private onChange = (_: any) => {};
  private onTouched = () => {};

  get value(): any {
    return this.isChecked;
  }

  set value(value: any) {
    this.isChecked = value;
  }

  onValueChange(isChecked: boolean): void {
    if (this.isDisabled) {
      return;
    }
    this.value = isChecked;
    this.onChange(this.value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => {}): void {
    this.onTouched = fn;
  }

  writeValue(outsideValue: boolean): void {
    this.value = outsideValue;
  }
}
