<div class="d-flex justify-content-between align-items-end p-4">
  <div class="d-flex flex-column">
    <p class="fw-bold">{{ 'user.filterByPermission' | translate }}</p>
    <base-filter-buttons
      selectedClass="btn-dark"
      [filters]="permissionGroupsForFilters"
      [(ngModel)]="selectedGroup"
      (ngModelChange)="onSelectUserGroup()"
    ></base-filter-buttons>
  </div>
  <div class="d-flex flex-wrap gap-2 justify-content-end">
    <base-button
      [size]="buttonSizes.xs"
      [iconSize]="buttonSizes.xs"
      (btnClick)="openEditGroupModal()"
      color="primary"
      icon="fa-solid fa-pen"
      label="user.editPermissionGroup"
    ></base-button>
    <base-button
      [size]="buttonSizes.xs"
      [iconSize]="buttonSizes.xs"
      (btnClick)="openAddGroupModal()"
      color="primary"
      icon="fa-solid fa-plus"
      label="user.addPermissionGroup"
    ></base-button>
  </div>
</div>

<ng-template #addPermissionGroupTemplate>
  <form
    class="d-flex flex-column"
    [formGroup]="addGroupForm"
    [ngClass]="{ 'was-validated': isFormSubmitted }"
  >
    <div class="d-flex gap-5 my-4">
      <div class="w-25 pt-2">
        <label class="required">{{ 'groupName' | translate }}</label>
      </div>
      <div class="w-50">
        <base-input
          label=""
          formControlName="name"
          placeholder=""
          [isRequired]="true"
          [isInvalid]="!!addGroupForm.get('name')?.errors"
          [errorMessage]="addGroupForm.get('name')?.errors | baseFieldValidation"
        ></base-input>
      </div>
    </div>

    <div class="d-flex gap-5 my-4">
      <div class="w-25">
        <label>{{ 'permission.plural' | translate }}</label>
      </div>
      <div class="d-flex flex-column w-75">
        <ng-container *ngFor="let permission of permissionsToSelect">
          <base-checkbox
            class="pb-3"
            [isChecked]="permission.isSelected"
            [label]="permissionCheckboxLabel"
            (getChange)="onPermissionSelect($event, permission.id)"
          ></base-checkbox>
          <ng-template #permissionCheckboxLabel>
            <base-permission-checkbox-template [permission]="permission"></base-permission-checkbox-template>
          </ng-template>
        </ng-container>
      </div>
    </div>

    <div class="p-0 mb-5 mt-3 d-flex justify-content-between">
      <base-button
        [size]="buttonSizes.md"
        color="secondary"
        label="action.cancel"
        (btnClick)="cancelAddingGroup()"
      ></base-button>
      <base-button
        [size]="buttonSizes.md"
        color="primary"
        label="action.save"
        (btnClick)="addGroup()"
      ></base-button>
    </div>
  </form>
</ng-template>

<ng-template #editPermissionGroupTemplate>
  <base-edit-permission-groups-form
    class="edit-permission-group"
    [permissions]="permissions"
    (updateGroup)="updatePermissionGroup($event)"
  ></base-edit-permission-groups-form>
</ng-template>
