import { Injectable } from '@angular/core';
import { FormGroup, FormArray } from '@angular/forms';
import * as uuid from 'uuid';
import {
  BaseStaffRequestFormInterface,
  NewInquiryFormInterface,
  TimeAndShiftsFormInterface,
  WeekdaysFormInterface,
} from '../_interfaces/base-inquiry.interface';
import { BaseInquiryShiftsWithFlex } from '../_enums/base-inquiry.enum';
import { BaseInquiryDate } from '../_models/base-inquiry-date';
import { BASE_INQUIRY_CALENDAR_MAX_DAYS_TO_SCROLL_SHIFT_PLAN } from '../_const/base-inquiry-calendar.const';
import { BaseDateService } from '../../../shared/services/base-date.service';
import { BaseLocalStorageService } from '../../../core/services/base-local-storage.service';
import {
  ShiftPlanListBubbleItemInterface,
  ShiftPlanCalendarDateInterface,
  ShiftPlanRangeDataInterface,
  ShiftPlanCalendarRangeInterface,
  ShiftPlanRangeDateInterface,
} from '../_interfaces/base-shift-plan.interface';
import { InquiryResponse, InquiryDateResponse, QualificationShortInfoResponse } from '../../../../api';
import { BaseInquiryService } from './base-inquiry.service';
import { FormRawValue } from '../_interfaces/base-form.interface';
import { mapDaysOfWeekToNumbers } from '../../../shared/helpers/base-day-object-to-array-converter';
import {
  getClosestDayOfLastWeek,
  getDaysArrayWithWeekdays,
} from '../../../shared/helpers/base-get-dates-array-between-two-dates';

@Injectable()
export class BaseShiftPlanService {
  constructor(
    private readonly baseLocalStorageService: BaseLocalStorageService,
    private baseInquiryService: BaseInquiryService
  ) {}

  transformFormDataToShiftPlanViewData(
    formValue: FormRawValue<FormGroup<BaseStaffRequestFormInterface>> | FormRawValue<FormGroup<NewInquiryFormInterface>>
  ): ShiftPlanCalendarRangeInterface | null {
    const id = uuid.v4();
    const {
      dates: { weekdays, range },
      shiftsAndPersons: { shifts, persons, amountPersons },
      flexShift,
    } = formValue;

    const dates = this.getActiveDates(range.from, range.to, weekdays);

    if (dates.length === 0) {
      return null;
    }

    return {
      dates,
      rangeData: {
        startDate: range.from,
        endDate: range.to,
        amountOfPersons: amountPersons,
        flexShift,
        shifts: {
          sd: flexShift ? (shifts.sd ? amountPersons : 0) : persons.sdPersons,
          nd: flexShift ? (shifts.nd ? amountPersons : 0) : persons.ndPersons,
          fd: flexShift ? (shifts.fd ? amountPersons : 0) : persons.fdPersons,
          zd: flexShift ? (shifts.zd ? amountPersons : 0) : persons.zdPersons,
        },
        weekdays: weekdays,
      },
      id,
    };
  }

  transformInquiryDataToShiftPlanViewData(inquiryDates: InquiryDateResponse[]): ShiftPlanCalendarRangeInterface {
    const id = uuid.v4();
    const startDate = inquiryDates[0].date;
    const endDate = inquiryDates[inquiryDates.length - 1].date;
    const flexShift = inquiryDates[0].flexShift as boolean;
    const weekdays = this.baseInquiryService.getInquiryWeekdays(inquiryDates);

    return {
      dates: this.getActiveDates(startDate, endDate, weekdays),
      rangeData: {
        startDate,
        endDate,
        amountOfPersons: flexShift
          ? ((inquiryDates[0].shiftSd ||
              inquiryDates[0].shiftNd ||
              inquiryDates[0].shiftFd ||
              inquiryDates[0].shiftZd) as number)
          : 0,
        flexShift,
        shifts: {
          sd: inquiryDates[0].shiftSd ?? 0,
          nd: inquiryDates[0].shiftNd ?? 0,
          fd: inquiryDates[0].shiftFd ?? 0,
          zd: inquiryDates[0].shiftZd ?? 0,
        },
        weekdays,
      },
      id,
    };
  }

  transformInquiryToFormData(inquiry: InquiryResponse): FormRawValue<FormGroup<BaseStaffRequestFormInterface>> {
    const shiftDate = (inquiry.dates as InquiryDateResponse[])[0];
    const shiftData = {
      date: shiftDate.date,
      flexShift: shiftDate.flexShift as boolean,
      shiftFd: shiftDate.shiftFd as number,
      shiftNd: shiftDate.shiftNd as number,
      shiftSd: shiftDate.shiftSd as number,
      shiftZd: shiftDate.shiftZd as number,
    };

    return {
      dates: {
        range: {
          from: inquiry.dateFrom as string,
          to: inquiry.dateTo as string,
        },
        weekdays: this.baseInquiryService.getInquiryWeekdays(inquiry.dates as InquiryDateResponse[]),
      },
      startDate: inquiry.dateFrom as string,
      unlimited: inquiry.unlimited as boolean,
      customerDepartment: {
        id: inquiry.customerDepartment?.id as number,
        primaryData: {
          name: inquiry.customerDepartment?.name || '',
        },
      },
      responsible: {
        party: inquiry.responsible?.id || 0,
        fullName: inquiry.responsible?.firstName + ' ' + inquiry.responsible?.lastName,
      },
      mainQualification: inquiry.mainQualification as QualificationShortInfoResponse,
      qualifications: (inquiry.qualifications as QualificationShortInfoResponse[])
        ?.map((item) => item.id)
        .filter((item) => item !== inquiry.mainQualification?.id),
      jobDescription: inquiry.jobDescription || '',
      weeklyWorkingHours: inquiry.weeklyWorkingHours ? Number.parseFloat(inquiry.weeklyWorkingHours) : 0,
      shiftsAndPersons: {
        persons: {
          fdPersons: shiftData.shiftFd,
          zdPersons: shiftData.shiftZd,
          sdPersons: shiftData.shiftSd,
          ndPersons: shiftData.shiftNd,
        },
        shifts: {
          fd: !!shiftData.shiftFd,
          zd: !!shiftData.shiftZd,
          sd: !!shiftData.shiftSd,
          nd: !!shiftData.shiftNd,
        },
        amountPersons: shiftData.flexShift
          ? shiftData.shiftFd || shiftData.shiftZd || shiftData.shiftSd || shiftData.shiftNd
          : 1,
      },
      requirements: inquiry.requirements?.flatMap((req) => req.requirements.map((r) => r.id)) || [],
      flexShift: shiftData.flexShift,
      timeShiftsForm: inquiry.shiftTimes as FormRawValue<FormArray<FormGroup<TimeAndShiftsFormInterface>>>,
      workEquipmentCustomer: inquiry.workEquipmentCustomer || null,
      workEquipmentPsp: inquiry.workEquipmentPsp || null,
      costUnit: inquiry.costUnit || null,
      remark: inquiry.remark || null,
    };
  }

  generateInquiryShiftViewItems(
    shiftPlanRangeList: ShiftPlanCalendarRangeInterface[]
  ): ShiftPlanCalendarDateInterface[] {
    const inquiryDays = this.getInquiryDays(shiftPlanRangeList);
    const startDate = this.getStartDate(inquiryDays);
    const localeDateString: string = this.baseLocalStorageService.get('language') || 'de';
    const closestMonday = getClosestDayOfLastWeek('Mon', startDate);
    const inquiryShiftViewItems: ShiftPlanCalendarDateInterface[] = [];
    const inquiryDateTimes = inquiryDays.map(({ dateString }) => new Date(dateString).getTime());
    const maxDate = new Date(
      Math.max(
        ...inquiryDateTimes,
        BaseDateService.addDays(startDate, BASE_INQUIRY_CALENDAR_MAX_DAYS_TO_SCROLL_SHIFT_PLAN).getTime()
      )
    );
    const inquiryLength = BaseDateService.differenceInWeeks(startDate, maxDate);
    const weekAmount = inquiryLength + 2;
    let lastMonth = -1;

    for (let i = 0; i < weekAmount * 7; i++) {
      const date = BaseDateService.addDays(closestMonday, i);
      const currentMonth = date.getMonth();
      const isNewMonth = currentMonth !== lastMonth;

      lastMonth = currentMonth;

      const inquiryShiftViewItem = this.generateInquiryShiftViewItem(date, inquiryDays, isNewMonth, localeDateString);
      inquiryShiftViewItems.push(inquiryShiftViewItem);
    }

    return inquiryShiftViewItems;
  }

  prepareDataForShiftPlanView(inquiry: InquiryResponse): ShiftPlanCalendarRangeInterface[] {
    const currentShiftTypeCollection: ShiftPlanCalendarRangeInterface[] = [];
    let inquiryDateCollection: InquiryDateResponse[] = [];

    if (inquiry.dates) {
      for (let index = 0; index < inquiry.dates.length; index++) {
        const item = inquiry.dates[index];
        const isLastIteration = index === inquiry.dates.length - 1;

        if (
          inquiryDateCollection.length === 0 ||
          this.baseInquiryService.isTwoDatesAreSimilar(item, inquiry.dates[index - 1])
        ) {
          inquiryDateCollection.push(item);

          if (isLastIteration) {
            currentShiftTypeCollection.push(this.transformInquiryDataToShiftPlanViewData(inquiryDateCollection));
          }
        } else {
          currentShiftTypeCollection.push(this.transformInquiryDataToShiftPlanViewData(inquiryDateCollection));

          if (isLastIteration) {
            currentShiftTypeCollection.push(this.transformInquiryDataToShiftPlanViewData([item]));
          } else {
            inquiryDateCollection = [item];
          }
        }
      }
    }

    return currentShiftTypeCollection;
  }

  private getActiveDates(
    dateFrom: string,
    dateTo: string,
    weekdays: FormRawValue<FormGroup<WeekdaysFormInterface>>
  ): ShiftPlanRangeDateInterface[] {
    const weekDaysIndexList = mapDaysOfWeekToNumbers(weekdays);

    if (weekDaysIndexList.length === 0) {
      return [];
    }

    return getDaysArrayWithWeekdays<ShiftPlanRangeDateInterface>(
      new Date(dateFrom),
      new Date(dateTo),
      weekDaysIndexList,
      true,
      true
    );
  }

  private generateInquiryShiftViewItem(
    date: Date,
    inquiryDays: (ShiftPlanRangeDateInterface & { rangeId: string; rangeData: ShiftPlanRangeDataInterface })[],
    isNewMonth: boolean,
    localeDateString: string
  ): ShiftPlanCalendarDateInterface {
    const dateItem = this.calculateInquiryDate(date, localeDateString, isNewMonth);
    const bubbles = this.calculateBubbles(dateItem.dateString, inquiryDays);
    const position = inquiryDays.find(({ dateString }) => dateString === dateItem.dateString);

    return {
      dateItem: dateItem,
      bubbles: bubbles,
      currentPosition: position
        ? {
            dateString: position.dateString,
            isVisible: position.isVisible,
            rangeId: position.rangeId,
          }
        : undefined,
    };
  }

  private calculateInquiryDate(dateItem: Date, localeDateString: string, isNewMonth: boolean): BaseInquiryDate {
    const dateResetTimezone = new Date(dateItem.getTime() - dateItem.getTimezoneOffset() * 60000);
    const dateString = dateResetTimezone.toISOString().slice(0, 10);

    return {
      calendarWeek: dateItem.getDay() === 1 ? BaseDateService.weekNumber(dateString) : undefined,
      dateString: dateString,
      day: dateItem.toLocaleString(localeDateString, { day: '2-digit' }),
      firstDayOfMonth: isNewMonth,
      isToday: BaseDateService.isToday(dateItem),
      isWeekend: BaseDateService.isWeekendDay(dateItem),
      monthLong: dateItem.toLocaleDateString(localeDateString, { month: 'long' }),
      weekdayShort: dateItem.toLocaleDateString(localeDateString, { weekday: 'short' }),
    };
  }

  private calculateBubbles(
    currentDateString: string,
    inquiryDays: (ShiftPlanRangeDateInterface & { rangeId: string; rangeData: ShiftPlanRangeDataInterface })[]
  ): ShiftPlanListBubbleItemInterface[] {
    const bubbles: ShiftPlanListBubbleItemInterface[] = [];
    const currentInquiryDate = inquiryDays.find(({ dateString }) => dateString === currentDateString);

    Object.values(BaseInquiryShiftsWithFlex).forEach((type) => {
      let bubble: ShiftPlanListBubbleItemInterface;

      if (currentInquiryDate) {
        if (type === BaseInquiryShiftsWithFlex.FLEX) {
          if (currentInquiryDate.rangeData.flexShift) {
            bubble = {
              shift: type,
              showDot: false,
              value: (currentInquiryDate.rangeData.shifts.fd ||
                currentInquiryDate.rangeData.shifts.sd ||
                currentInquiryDate.rangeData.shifts.nd ||
                currentInquiryDate.rangeData.shifts.zd) as number,
            };
          } else {
            bubble = {
              shift: type,
              showDot: false,
              value: 0,
            };
          }
        } else {
          if (currentInquiryDate.rangeData.flexShift) {
            bubble = {
              shift: type,
              showDot: (currentInquiryDate.rangeData.shifts[type] as number) > 0,
              value: 0,
            };
          } else {
            bubble = {
              shift: type,
              showDot: false,
              value: currentInquiryDate.rangeData.shifts[type] as number,
            };
          }
        }
      } else {
        bubble = {
          shift: type,
          showDot: false,
          value: 0,
        };
      }

      if (bubble) {
        bubbles.push(bubble);
      }
    });

    return bubbles;
  }

  private getInquiryDays(
    shiftPlanRangeList: ShiftPlanCalendarRangeInterface[]
  ): (ShiftPlanRangeDateInterface & { rangeId: string; rangeData: ShiftPlanRangeDataInterface })[] {
    if (shiftPlanRangeList.length === 0) {
      return [];
    }

    return shiftPlanRangeList
      .flatMap((item) =>
        item.dates.map((dateItem) => ({
          dateString: dateItem.dateString,
          isVisible: dateItem.isVisible,
          rangeId: item.id,
          rangeData: item.rangeData,
        }))
      )
      .sort((date1, date2) => {
        return BaseDateService.isBefore(new Date(date1.dateString), new Date(date2.dateString))
          ? -1
          : BaseDateService.isAfter(new Date(date1.dateString), new Date(date2.dateString))
          ? 1
          : 0;
      });
  }

  private getStartDate(inquiryDays: { dateString: string; isVisible: boolean }[]) {
    return inquiryDays.length > 0
      ? BaseDateService.toDate(inquiryDays[0].dateString)
      : BaseDateService.startOfDay(new Date());
  }
}
