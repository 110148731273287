import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  CatalogService,
  Category,
  CreatedResponse,
  PriceListRequest,
  PspService,
  QualificationPricingListItemResponse,
} from '../../../api';
import { BaseApplicationTagType } from '../../shared/enums/base-application.enum';

@Injectable()
export class BasePspPriceListService {
  constructor(private readonly pspService: PspService, private readonly catalogService: CatalogService) {}

  getCategories(params?: { tag?: BaseApplicationTagType }): Observable<Category[] | undefined> {
    return this.catalogService
      .getAppApiWebV1CatalogsQualificationCategoryListIndex(params)
      .pipe(map((response) => response.data.categories));
  }

  getPriceList(params: {
    psp: number;
    tag?: BaseApplicationTagType;
  }): Observable<QualificationPricingListItemResponse[] | undefined> {
    return this.pspService
      .getAppApiWebV1PspPriceListIndex(params)
      .pipe(map((response) => response.data.qualifications));
  }

  updatePriceList(params: { body: PriceListRequest; psp: number }): Observable<CreatedResponse> {
    return this.pspService.postAppApiWebV1PspPriceUpdateIndex(params);
  }
}
