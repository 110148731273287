import { Injectable } from '@angular/core';
import { NonNullableFormBuilder, FormGroup } from '@angular/forms';
import { CustomerMainFormInterface } from '../../modules/administration/_interfaces/base-administration.interface';

interface SharedFormGroupContext {
  formGroup: FormGroup<CustomerMainFormInterface>;
  referenceCount: number;
}

@Injectable()
export class BaseSharedFormBuilderService {
  private _formGroups = new Map<Symbol, SharedFormGroupContext>();

  constructor(readonly fb: NonNullableFormBuilder) {}

  sharedGroup(
    key: Symbol,
    formBuilder: (fb: NonNullableFormBuilder) => FormGroup<CustomerMainFormInterface>
  ): FormGroup<CustomerMainFormInterface> {
    let formGroupContext = this._formGroups.get(key);
    if (formGroupContext) {
      formGroupContext.referenceCount += 1;
      return formGroupContext.formGroup;
    }

    formGroupContext = { formGroup: formBuilder(this.fb), referenceCount: 1 };
    this._formGroups.set(key, formGroupContext);
    return formGroupContext.formGroup;
  }

  releaseSharedGroup(key: Symbol) {
    let formGroupContext = this._formGroups.get(key);
    if (!formGroupContext) return;

    formGroupContext.referenceCount -= 1;

    if (formGroupContext.referenceCount <= 0) {
      this._formGroups.delete(key);
    }
  }
}
