import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NonNullableFormBuilder, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { BaseInputSizes } from '../../../../shared/components/input/base-input.component';
import { BaseRadiobuttonItemInterface } from '../../../../shared/interfaces/base-radiobutton-item.interface';
import { BaseButtonSize } from '../../../../shared/components/button/base-button.component';
import { BaseAuthenticationService } from '../../base-auth.service';
import { baseEmailValidator } from '../../../../shared/validators/base-email.validator';
import { basePasswordValidator } from '../../../../shared/validators/base-password.validator';
import { UserRegisterCheckResponse } from '../../../../../api';
import { baseAuthActions, BaseCoreState } from '../../../../_store';
import { BaseApplication } from '../../../../shared/enums/base-application.enum';
import { BaseGenderOptions } from '../../../../shared/constants/base-gender-options';
import { passwordMatchingValidatior } from '../../../../shared/validators/base-confirm-password.validator';
import { baseFormsSelectors } from '../../../../_store/selectors';
import { baseFormsActions } from '../../../../_store/actions';
import { baseIsDeepEqual } from '../../../../shared/helpers/base-is-deep-equal';
import { BaseSharedService } from '../../../../shared/services/base-shared.service';
import { basePartnerRegistrationCodeValidator } from '../../../../shared/validators/base-partner-registration-code.validator';

@Component({
  selector: 'base-register-form',
  templateUrl: './base-register-form.component.html',
  styleUrls: ['./base-register-form.component.scss'],
})
export class BaseRegisterFormComponent implements OnInit, OnDestroy {
  @Input() application: BaseApplication;
  applications = BaseApplication;
  inviteToken: string;
  subtitle: string;
  submitted = false;
  inputSize = BaseInputSizes;
  buttonSize = BaseButtonSize;
  titles: BaseRadiobuttonItemInterface[] = BaseGenderOptions;
  destroy$ = new Subject();
  registrationForm = this.fb.group(
    {
      name: ['', [Validators.required]],
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: ['', [baseEmailValidator()]],
      password: ['', [Validators.required, basePasswordValidator()]],
      confirmPassword: ['', [Validators.required, basePasswordValidator()]],
      gender: [null as BaseRadiobuttonItemInterface | null, [Validators.required]],
      conditionAccepted: [false, [Validators.requiredTrue]],
      privacyPolicyAccepted: [false, [Validators.requiredTrue]],
      partnerCode: [{ value: '', disabled: true }, [Validators.required, basePartnerRegistrationCodeValidator()]],
    },
    { validators: passwordMatchingValidatior }
  );
  isRecruitedByPsp = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authenticationService: BaseAuthenticationService,
    private store: Store<BaseCoreState>,
    private fb: NonNullableFormBuilder,
    private baseSharedService: BaseSharedService
  ) {}

  ngOnInit(): void {
    this.inviteToken = this.activatedRoute.snapshot.params.token;
    if (this.inviteToken) {
      this.validateToken(this.inviteToken);
    } else {
      this.registrationForm.controls.email.addValidators(Validators.required);
      this.loadRegisterFormFromCache();
    }

    this.registrationForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((registrationFormValue) => {
      const { password, confirmPassword, conditionAccepted, ...form } = registrationFormValue;
      this.store.dispatch(baseFormsActions.setAccountRegisterForm({ payload: form }));
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  register(): void {
    this.submitted = true;

    const formData = JSON.parse(JSON.stringify(this.registrationForm.value));
    if (formData.gender) {
      formData.gender = formData.gender.value;
    }
    delete formData.confirmPassword;

    if (this.inviteToken) {
      formData.inviteToken = this.inviteToken;
      delete formData.email;
    }

    if (this.registrationForm.invalid) {
      return;
    }

    if (this.inviteToken) {
      this.store.dispatch(baseAuthActions.registerUser({ payload: formData }));
    } else {
      switch (this.application) {
        case BaseApplication.medical:
          this.store.dispatch(baseAuthActions.registerCustomer({ payload: formData }));
          break;
        case BaseApplication.growth:
          this.store.dispatch(baseAuthActions.registerPSP({ payload: formData }));
          break;
        case BaseApplication.business:
          this.store.dispatch(baseAuthActions.registerBusinessCustomer({ payload: formData }));
          break;
      }
    }
  }

  downloadLegalDocument(isPrivacyPolicy?: boolean): void {
    this.baseSharedService.downloadLegalDocument(isPrivacyPolicy);
  }

  clearRegisterFormCache(): void {
    this.store.dispatch(baseFormsActions.clearAccountRegisterForm());
    if (this.application !== this.applications.growth) {
      this.isRecruitedByPsp = false;
    }
    this.registrationForm.reset();
  }

  addRegistrationCode(event: boolean): void {
    this.isRecruitedByPsp = event;

    if (this.isRecruitedByPsp) {
      this.registrationForm.controls['partnerCode'].enable();
    } else {
      this.registrationForm.controls['partnerCode'].disable();
    }
  }

  private validateToken(token: string): void {
    this.authenticationService
      .validateInviteLink(token)
      .pipe(
        takeUntil(this.destroy$),
        map((userRegisterCheckResponse: UserRegisterCheckResponse) => userRegisterCheckResponse.data)
      )
      .subscribe((result) => {
        this.registrationForm.controls.name.setValue(result.organization.legalName);
        this.registrationForm.controls.name.disable();
        this.registrationForm.controls.gender.setValue(
          this.titles.find((title) => title.value === result.gender) || { value: '', label: '' }
        );
        this.registrationForm.controls.firstName.setValue(result.firstName);
        this.registrationForm.controls.lastName.setValue(result.lastName);
      });
  }

  private loadRegisterFormFromCache() {
    this.store
      .select(baseFormsSelectors.getAccountRegisterForm)
      .pipe(
        take(1),
        filter((state) => state?.data),
        map((state: any | undefined) => state.data)
      )
      .subscribe((registerFormValue) => {
        if (!baseIsDeepEqual(registerFormValue, this.registrationForm.value)) {
          this.registrationForm.patchValue(
            { ...registerFormValue, password: '', confirmPassword: '', conditionAccepted: false },
            { emitEvent: false }
          );
        }
      });
  }
}
