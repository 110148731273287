import { Component } from '@angular/core';

@Component({
  selector: 'base-imprint-de',
  template: `
    <h1 class="headline-36 mb-4">Impressum</h1>
    <div>
      <h2 class="headline-24">Herausgeber und verantwortlich für den Inhalt:</h2>
      <p>
        HR Software Solutions GmbH<br />
        Adenauerplatz 1<br />
        33602 Bielefeld
      </p>

      <p><b class="fw-bold">E-Mail:</b> kontakt@stazzle.de</p>
      <p><b class="fw-bold">Telefon:</b> +49 5218 9880 20</p>
      <p><b class="fw-bold">Geschäftsführung:</b> Marco Schmidt</p>
      <p><b class="fw-bold">Inhaltlich Verantwortlicher:</b> Marco Schmidt</p>
      <p><b class="fw-bold">Registergericht:</b> Amtsgericht Bielefeld</p>
      <p><b class="fw-bold">Registernummer:</b> HRB 43485</p>
      <p><b class="fw-bold">Umsatzsteuer-Identifikationsnummer:</b> DE325481466</p>

      <p>
        <b class="fw-bold">Haftungshinweis:</b><br />
        Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung für die Inhalte externer Links. Für den
        Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich.
      </p>

      <p>
        Aus Gründen der besseren Lesbarkeit haben wir auf die konsequente Nennung der männlichen und weiblichen Form
        verzichtet. Es sind selbstverständlich alle Geschlechter gemeint.
      </p>

      <p><b class="fw-bold">Außergerichtliche Streitbeilegung:</b></p>

      <p>
        Wir nehmen nicht an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teil. Urheberrecht
        Die durch den Betreiber dieser Seite erstellten Inhalte und Werke auf diesen Webseiten unterliegen dem deutschen
        Urheberrecht. Sämtliche Beiträge Dritter sind als solche gekennzeichnet. Die Vervielfältigung, Bearbeitung,
        Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechts bedürfen der schriftlichen
        Zustimmung des jeweiligen Autors bzw. Erstellers. Kopien von diesen Seiten sind nur für den privaten Bereich
        gestattet, nicht jedoch für kommerzielle Zwecke.
      </p>

      <p><b class="fw-bold">Bildnachweise:</b></p>

      <p>Copyright HR Software Solutions GmbH</p>
    </div>
  `,
})
export class ImprintDeComponent {}
