import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { baseInquirySelectors, BaseCoreState } from '../../../_store';

@Injectable()
export class BaseInquiryShiftPlanGuard {
  constructor(private readonly store: Store<BaseCoreState>, private router: Router) {}

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return this.store.select(baseInquirySelectors.getInquiry).pipe(
      map((result) => {
        if (typeof result.responsible === 'number' && result.customerDepartment) {
          return true;
        }

        this.router.navigate(['inquiries/list']);
        return false;
      })
    );
  }
}
