import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  AssignmentUnplannedListItemResponse,
  CreateAssignmentDateRequest,
  CreatedResponse,
  CustomerAcceptRequest,
  CustomerAssignmentDateListItemResponse,
  CustomerAssignmentService,
  CustomerCreateAbsence,
  EmptySuccessfulResponse,
  OrganizationFilterListResponse,
  PaginatedApiResponse,
  PspAcceptRequest,
  PspAssignmentDateListItemResponse,
  PspAssignmentService,
  PspCreateAbsence,
  UpdateWorkTimesRequest,
} from '../../../api';
import { BaseTimeRecordingItemInterface } from './_interfaces/base-time-recordings.interface';
import { BaseSharedHelperService } from '../../shared/services/base-shared-helper.service';
import {
  BaseTimeRecordCustomerExportInterface,
  BaseTimeRecordPspExportInterface,
} from './_interfaces/base-time-record-export.interface';

@Injectable()
export class BaseTimeRecordingsService {
  constructor(
    private pspAssignmentService: PspAssignmentService,
    private customerAssignmentService: CustomerAssignmentService
  ) {}

  getPspTimeRecordsList(
    psp: number,
    filters?: any,
    page?: number,
    size?: number
  ): Observable<
    PaginatedApiResponse & {
      data?: Array<PspAssignmentDateListItemResponse>;
      filters?: OrganizationFilterListResponse;
    }
  > {
    return this.pspAssignmentService.getPspAssignmentDateList({
      psp,
      ...filters,
      page,
      size,
    });
  }

  getCustomerTimeRecordsList(
    customer: number,
    filters?: any,
    page?: number,
    size?: number
  ): Observable<
    PaginatedApiResponse & {
      data?: Array<CustomerAssignmentDateListItemResponse>;
      filters?: OrganizationFilterListResponse;
    }
  > {
    return this.customerAssignmentService.getCustomerAssignmentDateList({
      customer,
      ...filters,
      page,
      size,
    });
  }

  acceptCustomerTimeRecord(params: {
    customer: number;
    body: CustomerAcceptRequest;
  }): Observable<EmptySuccessfulResponse> {
    return this.customerAssignmentService.postAssignmentAssignmentDatesAcceptance(params);
  }

  acceptPspTimeRecord(params: { psp: number; body: PspAcceptRequest }): Observable<EmptySuccessfulResponse> {
    return this.pspAssignmentService.postPspAssignmentDateAccept(params);
  }

  updateCustomerTimeRecord(params: {
    customer: number;
    assignment: number;
    assignmentDate: number;
    body: UpdateWorkTimesRequest;
  }): Observable<CreatedResponse> {
    return this.customerAssignmentService.putCustomerAssignmentAssignmentDateWorkTimeUpdate(params);
  }

  updatePspTimeRecord(params: {
    psp: number;
    assignment: number;
    assignmentDate: number;
    body: UpdateWorkTimesRequest;
  }): Observable<CreatedResponse> {
    return this.pspAssignmentService.putPspAssignmentDateTimeReplace(params);
  }

  prepareTimeRecordsList(list: CustomerAssignmentDateListItemResponse[]): BaseTimeRecordingItemInterface[] {
    return list.map((item) => ({ ...item, isSelected: false }));
  }

  timeRecordsListToggleSelection(
    ids: number[],
    isSelected: boolean,
    list: BaseTimeRecordingItemInterface[]
  ): BaseTimeRecordingItemInterface[] {
    if (ids.length === 0) {
      return list.map((item) => {
        return {
          ...item,
          isSelected,
        };
      });
    }

    return list.map((item) => {
      if (ids.includes(item.id)) {
        return {
          ...item,
          isSelected,
        };
      }

      return item;
    });
  }

  createPspTimeRecordAbsence(params: {
    psp: number;
    assignment: number;
    body: PspCreateAbsence;
  }): Observable<EmptySuccessfulResponse> {
    return this.pspAssignmentService.postPspAssignmentAbsenceCreate(params);
  }

  createCustomerTimeRecordAbsence(params: {
    customer: number;
    assignment: number;
    body: CustomerCreateAbsence;
  }): Observable<EmptySuccessfulResponse> {
    return this.customerAssignmentService.postCustomerAssignmentAbsenceCreate(params);
  }

  exportCustomerTimeRecords(
    params: BaseTimeRecordCustomerExportInterface
  ): Observable<{ fileName: string; body: Blob | (null & Blob) }> {
    return this.customerAssignmentService.getCustomerAssignmentDateExport$Response(params).pipe(
      map((response) => {
        const contentDispositionValue = response.headers.get('Content-Disposition');
        const fileName = BaseSharedHelperService.fileNameFromHeaderValue(contentDispositionValue, '=');

        return {
          body: response.body,
          fileName,
        };
      })
    );
  }

  exportPspTimeRecords(
    params: BaseTimeRecordPspExportInterface
  ): Observable<{ fileName: string; body: Blob | (null & Blob) }> {
    return this.pspAssignmentService.getPspAssignmentDateExport$Response(params).pipe(
      map((response) => {
        const contentDispositionValue = response.headers.get('Content-Disposition');
        const fileName = BaseSharedHelperService.fileNameFromHeaderValue(contentDispositionValue, '=');

        return {
          body: response.body,
          fileName,
        };
      })
    );
  }

  customerAssignmentsForTimeRecordCreation(customer: number): Observable<AssignmentUnplannedListItemResponse[]> {
    return this.customerAssignmentService
      .getCustomerAsignmentListUnplanned({ customer })
      .pipe(map((response) => response.data));
  }

  pspAssignmentsForTimeRecordCreation(psp: number): Observable<AssignmentUnplannedListItemResponse[]> {
    return this.pspAssignmentService.getPspAsignmentListUnplanned({ psp }).pipe(map((response) => response.data));
  }

  createCustomerAssignmentDate(params: {
    customer: number;
    assignment: number;
    body: CreateAssignmentDateRequest;
  }): Observable<CreatedResponse> {
    return this.customerAssignmentService.postCustomerAsignmentAssignmentDatesCreate(params);
  }

  createPspAssignmentDate(params: {
    psp: number;
    assignment: number;
    body: CreateAssignmentDateRequest;
  }): Observable<CreatedResponse> {
    return this.pspAssignmentService.postPspAsignmentAssignmentDateCreate(params);
  }
}
