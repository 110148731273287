<ng-container *ngIf="showCategoryAndMethod">
  <div class="row">
    <div class="col-4">
      <span class="fw-bold">{{ 'industrySurcharges.industry' | translate }}</span>
    </div>
    <div class="col-8">
      <span>{{ 'industrySurcharges.industries.' + surcharges.industry | translate }}</span>
    </div>
  </div>
  <div class="row mt-3 mb-7">
    <div class="col-4">
      <span class="fw-bold">{{ 'industrySurcharges.calculationMethod' | translate }}</span>
    </div>
    <div class="col-8">
      <span>{{ 'industrySurcharges.methodOfCalculation.' + surcharges.methodOfCalculation | translate }}</span>
    </div>
  </div>
</ng-container>
<div class="d-flex justify-content-between mb-4 ps-4">
  <span class="fw-bold">{{ 'industrySurcharges.staggering' | translate }}</span>
  <span class="fw-bold me-3">{{ 'industrySurcharges.hourlyBillingRate' | translate }}</span>
</div>
<div
  class="row teaser bg-lightest"
  *ngFor="let surcharge of surcharges.surcharges"
>
  <div class="col-10">
    <span class="fw-medium">
      {{
        (surcharge.unit === dateUnitType.week
          ? 'industrySurcharges.staggeringWeekType'
          : 'industrySurcharges.staggeringMonthType'
        ) | translate: { value: surcharge.number, suffix: surcharge.number | baseOrdinalSuffixPipe | translate }
      }}
    </span>
  </div>

  <div class="col-2 text-end">
    <span>{{ surcharge.price }} €</span>
  </div>
</div>
