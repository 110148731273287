import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { BaseDocumentCategoriesGuard } from './_guards/base-document-categories.guard';
import { baseDocumentEffects, baseDocumentReducer } from '../../_store';
import { BaseCustomerDocumentsService } from './base-customer-documents.service';
import { BasePspDocumentsService } from './base-psp-documents.service';

@NgModule({
  imports: [
    StoreModule.forFeature('documents', baseDocumentReducer.BaseDocumentsReducer),
    EffectsModule.forFeature([baseDocumentEffects.BaseDocumentsEffects]),
  ],
  declarations: [],
  exports: [],
  providers: [BaseCustomerDocumentsService, BasePspDocumentsService, BaseDocumentCategoriesGuard],
})
export class BaseDocumentsModule {}
