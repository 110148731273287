import { Component, Input, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseQueryParamService } from '../../services/base-query-param.service';

@Component({
  selector: 'base-pagination',
  templateUrl: './base-pagination.component.html',
})
export class BasePaginationComponent {
  @Input() page = 1;
  @Input() size = 120;
  @Input() pageSize = 15;
  @Input() visiblePages = 5;
  @Input() noFilters = false;
  @Input() turnOffInitialRequest = false;

  @Output() pageChange: EventEmitter<number> = new EventEmitter<number>();

  constructor(private queryParamsService: BaseQueryParamService, private route: ActivatedRoute) {
    if (!this.noFilters && !this.turnOffInitialRequest) {
      this.page = this.route.snapshot.queryParams?.page || 1;
      this.onPageChange(this.page);
    }
  }

  onPageChange(page: number) {
    if (!this.noFilters) {
      this.page = page;
      this.queryParamsService.filterSubject.next({
        page: this.page,
      });
    } else {
      this.pageChange.emit(page);
    }
  }
}
