export enum BaseInquiryBubbleColors {
  Default = '',
  Green = 'green',
  DarkGreyCircle = 'bubble-circle dark-grey',
  LightGreen = 'light-green',
  DarkGrey = 'dark-grey',
  LightGrey = 'light-grey',
  DarkGreyFramed = 'bubble-framed dark-grey',
  PinkSick = 'bubble-sick pink',
  PinkHoliday = 'bubble-holiday pink',
  PinkOther = 'bubble-other pink',
  PinkAssignedOutside = 'assigned-outside-icon pink',
  PinkUnexcused = 'unexcused-absence-icon bubble-unexcused pink',
  PinkTimeOff = 'time-off-icon pink',
  PinkFreeShift = 'free-shift-icon pink',
  DarkBlue = 'bubble-not-available dark-blue',
  RedWithdrawn = 'bubble-withdrawn red',
  RedRejected = 'bubble-framed red',
  Yellow = 'yellow',
  Purple = 'purple',
}
