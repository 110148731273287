import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BaseUpToDateBuildService } from '../../../core/services/base-up-to-date.service';
import { BaseModalService } from '../../services/base-modal.service';
import { BaseButtonSize } from '../button/base-button.component';

@Component({
  selector: 'base-up-to-date-app',
  templateUrl: './base-up-to-date.component.html',
  styleUrls: ['./base-up-to-date.component.scss'],
})
export class BaseUpToDateComponent implements OnInit, OnDestroy {
  @ViewChild('refreshAppVersionTemplate', { static: true }) refreshAppVersionTemplate: ElementRef;

  buildIsUpToDate = false;
  isReloadRequired = false;
  isReloadModalOpen = false;
  buttonSizes = BaseButtonSize;

  private destroy$ = new Subject();

  constructor(private upToDateService: BaseUpToDateBuildService, private modalService: BaseModalService) {}

  ngOnInit(): void {
    this.upToDateService.buildIsUpToDateSubject.pipe(takeUntil(this.destroy$)).subscribe((buildIsUpToDate) => {
      this.buildIsUpToDate = buildIsUpToDate;
      if (!this.buildIsUpToDate && !this.isReloadModalOpen) {
        this.showReloadModal();
      }
    });

    this.upToDateService.isReloadRequired.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      this.isReloadRequired = value;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  reloadPage(): void {
    location.reload();
  }

  private showReloadModal(): void {
    this.isReloadModalOpen = true;
    this.modalService
      .open(
        {
          headText: 'pageUpdate.title',
          bodyTemplate: this.refreshAppVersionTemplate,
          okText: 'pageUpdate.reloadNow',
          cancelText: 'pageUpdate.reloadLater',
        },
        {
          size: 'lg',
          backdrop: 'static',
        }
      )
      .result.then(
        () => {
          this.isReloadModalOpen = false;
          this.reloadPage();
          this.upToDateService.setIsReloadRequired(false);
        },
        () => {
          this.isReloadModalOpen = false;
          this.upToDateService.setIsReloadRequired(true);
        }
      );
  }
}
