import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BaseGenderOptions } from '../../../../../shared/constants/base-gender-options';
import { BaseButtonSize } from '../../../../../shared/components/button/base-button.component';
import { BranchListItemResponse, DepartmentListItemResponse, GroupListItemResponse } from '../../../../../../api';
import { InviteUserForm } from '../../../_interfaces/base-user-management.interface';

@Component({
  selector: 'base-invite-user-form',
  templateUrl: './base-invite-user-form.component.html',
  styleUrls: ['./base-invite-user-form.component.scss'],
})
export class BaseInviteUserFormComponent {
  @Input() form: FormGroup<InviteUserForm>;
  @Input() modalRef: NgbModalRef;
  @Input() groups: GroupListItemResponse[];
  @Input() pspBranches: BranchListItemResponse[];
  @Input() customerDepartments: DepartmentListItemResponse[];
  @Input() isFormSubmitted: boolean;
  @Input() isPSP: boolean;
  @Output() inviteUser: EventEmitter<boolean> = new EventEmitter<boolean>();
  genderOptions = BaseGenderOptions;
  buttonSizes = BaseButtonSize;

  confirmSendingInvitation(): void {
    this.isFormSubmitted = true;
    this.inviteUser.emit();
  }

  cancelSendingInvitation(): void {
    this.modalRef.close();
  }
}
