import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { BaseRolesService } from '../../core/services/base-roles.service';
import { BaseCoreState, baseSharedActions, baseSharedSelectors } from '../../_store';

@Injectable()
export class BaseBranchesOrDepartmentsGuard {
  constructor(private readonly store: Store<BaseCoreState>, private rolesService: BaseRolesService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (this.rolesService.isPsp()) {
      this.store.dispatch(baseSharedActions.getBranches());

      return this.store.select(baseSharedSelectors.getBranches).pipe(
        filter((result) => result.ok !== undefined),
        map((result) => !!result.ok)
      );
    } else {
      this.store.dispatch(baseSharedActions.loadCustomerDepartmentList({ payload: {} }));

      return this.store.select(baseSharedSelectors.getDepartments).pipe(
        filter((response) => response.ok !== undefined),
        map((response) => !!response.ok)
      );
    }
  }
}
