import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseEnvironmentService } from './base-environment.service';

@Injectable()
export class BaseHttpService {
  constructor(private readonly http: HttpClient, private readonly environmentService: BaseEnvironmentService) {}

  get<T>(url: string, data?: { [key: string]: any }, authorized?: boolean): Observable<T> {
    return this.http.get<T>(`${this.environmentService.getValue('apiUrl')}${url}`, {});
  }

  post<T>(url: string, data: T, authorized?: boolean): Observable<unknown> {
    return this.http.post<T>(`${this.environmentService.getValue('apiUrl')}${url}`, data);
  }

  put<T>(url: string, data: T, authorized?: boolean): Observable<unknown> {
    return this.http.put(`${this.environmentService.getValue('apiUrl')}${url}`, data);
  }
}
