import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { BASE_PERMISSIONS } from '../../../core/constants/base-permissions';
import { BaseRolesService } from '../../../core/services/base-roles.service';
import { baseAssignmentsActions, baseAssignmentsSelectors, BaseCoreState } from '../../../_store';
import { BaseAssignmentType } from '../../assignments/_const/base-assignment-type';
import { BaseOrderDirectionEnum } from '../../../shared/enums/base-order-direction.enum';
import { BaseOrderKeyEnum } from '../../../shared/enums/base-order-key.enum';

@Injectable()
export class BasePspAssignmentsListGuard {
  constructor(private readonly store: Store<BaseCoreState>, private rolesService: BaseRolesService) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | boolean {
    // @ts-ignore
    if (!this.rolesService.hasFeaturePermission(BASE_PERMISSIONS.FEATURE_ADMIN_ASSIGNMENTS)) {
      return true;
    }

    this.store.dispatch(
      baseAssignmentsActions.loadAssignmentList({
        payload: {
          page: 1,
          size: 4,
          orderKey: BaseOrderKeyEnum.createdAt,
          orderDirection: BaseOrderDirectionEnum.desc,
          filters: {
            'statuses[]': [BaseAssignmentType.PLANNED, BaseAssignmentType.ACTIVE].join(','),
          },
        },
      })
    );

    return this.store.select(baseAssignmentsSelectors.getAssignmentList).pipe(
      filter((state) => state.ok !== undefined),
      map((state) => state.ok as boolean)
    );
  }
}
