import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { baseRelatedCustomerLocationsReducer, baseRelatedCustomerLocationsEffects } from '../../_store';
import { BaseRelatedCustomerLocationsService } from './base-related-customer-locations.service';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      'relatedCustomerLocations',
      baseRelatedCustomerLocationsReducer.BaseRelatedCustomerLocationsReducer
    ),
    EffectsModule.forFeature([baseRelatedCustomerLocationsEffects.BaseRelatedCustomerLocationsEffects]),
  ],
  providers: [BaseRelatedCustomerLocationsService],
})
export class BaseRelatedCustomerLocationsModule {}
