import { Component, Input } from '@angular/core';
import { PdfSrcType } from '../../../../types/base-pdf-src.type';

@Component({
  selector: 'base-image-viewer',
  templateUrl: './base-image-viewer.component.html',
})
export class BaseImageViewerComponent {
  @Input() src: PdfSrcType = '';
}
