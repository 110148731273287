import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const passwordMatchingValidatior: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const password = control.get('password');
  const confirmPassword = control.get('confirmPassword');

  if (password?.value !== confirmPassword?.value) {
    confirmPassword?.setErrors({ confirmPasswordInvalid: true });
  } else if (confirmPassword?.errors) {
    delete confirmPassword?.errors['confirmPasswordInvalid'];
  }

  return null;
};
