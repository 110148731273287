import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { BaseTimeRecordingsListComponent } from './_components/list/base-time-recordings-list.component';
import { BaseSharedModule } from '../../shared/base-shared.module';
import { AppsSharedModule } from '../../apps-shared/apps-shared.module';
import { BaseTimeRecordingsSearchExportComponent } from './_components/search-bar/base-time-recordings-search-export.component';
import { BaseTimeRecordingsFiltersComponent } from './_components/filters/base-time-recordings-filters.component';
import { BaseTimeRecordingsListItemComponent } from './_components/list-item/base-time-recordings-list-item.component';
import { BaseTimeRecordingsListHeadlineComponent } from './_components/list-headline/base-time-recordings-list-headline.component';
import { BaseTimeRecordingsService } from './base-time-recordings.service';
import { baseTimeRecordsEffects, baseTimeRecordsReducer } from '../../_store';
import { BaseEditTimeRecordModalComponent } from './_components/base-edit-time-record-modal/base-edit-time-record-modal.component';
import { BaseRecordStateComponent } from './_components/record-state/base-record-state.component';
import { BaseAssignmentDateAbsenceReasonTooltipComponent } from './_components/base-assignment-date-absence-reason-tooltip/base-assignment-date-absence-reason-tooltip.component';
import { BaseTimeRecordCorrectedInfoRowComponent } from './_components/base-time-record-corrected-info-row/base-time-record-corrected-info-row.component';
import { BaseTimeRecordsListGuard } from './_guards/base-time-records-list.guard';
import { BaseTimeRecordAbsenceFormComponent } from './_components/base-time-record-absence-form/base-time-record-absence-form.component';
import { BaseTimeRecordFormComponent } from './_components/base-time-record-form/base-time-record-form.component';
import { BaseAddTimeRecordModalComponent } from './_components/base-add-time-record-modal/base-add-time-record-modal.component';
import { BaseAddEditTimeRecordModalFooterComponent } from './_components/base-add-edit-time-record-modal-footer/base-add-edit-time-record-modal-footer.component';
import { BaseAddAbsenceModalComponent } from './_components/base-add-absence-modal/base-add-absence-modal.component';
import { BaseAssignmentsModule } from '../assignments/base-assignments.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    BaseSharedModule,
    BaseAssignmentsModule,
    AppsSharedModule,
    NgbTooltipModule,
    StoreModule.forFeature('timeRecords', baseTimeRecordsReducer.BaseTimeRecords),
    EffectsModule.forFeature([baseTimeRecordsEffects.BaseTimeRecordsEffects]),
  ],
  declarations: [
    BaseTimeRecordingsListComponent,
    BaseTimeRecordingsSearchExportComponent,
    BaseTimeRecordingsFiltersComponent,
    BaseTimeRecordingsListItemComponent,
    BaseTimeRecordingsListHeadlineComponent,
    BaseEditTimeRecordModalComponent,
    BaseRecordStateComponent,
    BaseAssignmentDateAbsenceReasonTooltipComponent,
    BaseTimeRecordCorrectedInfoRowComponent,
    BaseTimeRecordAbsenceFormComponent,
    BaseTimeRecordFormComponent,
    BaseAddTimeRecordModalComponent,
    BaseAddEditTimeRecordModalFooterComponent,
    BaseAddAbsenceModalComponent,
  ],
  providers: [BaseTimeRecordingsService, BaseTimeRecordsListGuard],
  exports: [BaseTimeRecordingsListComponent, BaseEditTimeRecordModalComponent],
})
export class BaseTimeRecordingsModule {}
