import { createFeatureSelector, createSelector } from '@ngrx/store';
import { GroupListItemResponse, PermissionItemResponse, UserItemResponse } from '../../../api';
import { BaseUsersState } from '../reducers/base-user.reducer';

const getUsersState = createFeatureSelector('users');

export const getUsers = createSelector(getUsersState, (state: any): BaseUsersState => state);

export const getAdminList = createSelector(
  getUsers,
  (
    state: BaseUsersState
  ): { data: PermissionItemResponse[] | UserItemResponse[] | undefined; ok: boolean | undefined } => state.adminList
);

export const getUserList = createSelector(
  getUsers,
  (
    state: BaseUsersState
  ): { data: PermissionItemResponse[] | UserItemResponse[] | undefined; ok: boolean | undefined } => state.userList
);

export const getAdminAndUserList = createSelector(
  getUsers,
  (
    state: BaseUsersState
  ): { data: PermissionItemResponse[] | UserItemResponse[] | undefined; ok: boolean | undefined } =>
    state.adminAndUserList
);

export const getUserGroups = createSelector(
  getUsers,
  (state: BaseUsersState): { data: Array<GroupListItemResponse> | undefined; ok: boolean | undefined } =>
    state.userGroups
);

export const getUserGroupsStatus = createSelector(
  getUsers,
  (state: BaseUsersState): boolean | undefined => state.userGroups.ok
);

export const getGroup = createSelector(getUsers, (state: BaseUsersState) => state.userGroup);

export const getGroupCrud = createSelector(getUsers, (state: BaseUsersState) => state.userGroup.crud);

export const getUserInactiveFilter = createSelector(
  getUsers,
  (state: BaseUsersState): boolean => state.userFilter.filterActive
);
