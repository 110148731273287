import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { mapTo, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

// Service for different application configurations
@Injectable()
export class BaseConfigurationService {
  private configuration: { [key: string]: unknown } = {};

  constructor(private httpClient: HttpClient) {}

  load(): Observable<void> {
    return this.httpClient.get('').pipe(
      tap((configuration: any) => (this.configuration = configuration)),
      mapTo(undefined)
    );
  }

  getValue(key: string, defaultValue?: any): any {
    return this.configuration[key] || defaultValue;
  }
}
