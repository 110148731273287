import { Pipe, PipeTransform } from '@angular/core';

enum SuffixEng {
  st = 'st',
  nd = 'nd',
  rd = 'rd',
  th = 'th',
}

@Pipe({ name: 'baseOrdinalSuffixPipe' })
export class BaseOrdinalSuffixPipe implements PipeTransform {
  transform(value: number): string {
    if (!Number.isInteger(value)) {
      return value.toString();
    }

    let suffixKey: string;
    const v = value % 100;

    if (v > 3 && v < 21) {
      suffixKey = SuffixEng.th;
    } else {
      switch (v % 10) {
        case 1:
          suffixKey = SuffixEng.st;
          break;
        case 2:
          suffixKey = SuffixEng.nd;
          break;
        case 3:
          suffixKey = SuffixEng.rd;
          break;
        default:
          suffixKey = SuffixEng.th;
      }
    }

    return `ordinalSuffix.${suffixKey}`;
  }
}
