<div class="employee-short-info">
  <base-avatar
    class="me-3"
    [image]="anonymous ? '' : avatar"
    [size]="baseAvatarSize.medium"
  ></base-avatar>
  <div class="d-block max-width-150 fw-bold">
    <p
      baseTruncate
      class="m-0"
    >
      {{ lastName }},
    </p>
    <p
      baseTruncate
      class="m-0"
    >
      {{ firstName }}
    </p>
  </div>
</div>
