import { Inject, Injectable, Optional } from '@angular/core';
import { LogService } from '../../../api';
import { ENVIRONMENTER } from '../token/base-environmenter.token';
import { BaseTokenService } from './base-token.service';

@Injectable()
export class BaseLoggingService {
  private errorStack: string[] = [];
  private stackLength = 10;
  private environment: any;

  constructor(
    @Optional() @Inject(ENVIRONMENTER) environment: any,
    private logService: LogService,
    private tokenService: BaseTokenService
  ) {
    this.environment = environment;
  }

  logError(error: Error): void {
    const token = this.tokenService.getToken();
    if (this.tokenService.isValid(token)) {
      const stringifiedError = error.stack ? JSON.stringify(error.stack) : error.message;
      if (!this.errorStack.includes(stringifiedError)) {
        this.errorStack.push(stringifiedError);

        const logMessage = {
          logLevel: 'error' as any,
          message: stringifiedError,
        };

        if (this.environment.global.sendLogs) {
          this.logService
            .postAppApiWebV1LogLog({ body: logMessage })
            .toPromise()
            .finally(() => {
              this.shiftErrorStackIfFull();
            })
            .catch((errback) => {
              console.error(errback);
            });
        } else {
          console.error('An unexpected error occurred. Sending logs is skipped in current mode.', logMessage);
          this.shiftErrorStackIfFull();
        }
      }
    }
  }

  private shiftErrorStackIfFull() {
    if (this.errorStack.length > this.stackLength) {
      this.errorStack.shift();
    }
  }
}
