import produce from 'immer';
import { baseTimeRecordsActions } from '../actions';
import { AssignmentUnplannedListItemResponse, MetaPagination, OrganizationFilterListResponse } from '../../../api';
import { BaseTimeRecordingItemInterface } from '../../modules/time-recordings/_interfaces/base-time-recordings.interface';

export interface BaseTimeRecordsState {
  list: {
    data: Array<BaseTimeRecordingItemInterface>;
    filters: OrganizationFilterListResponse | undefined;
    pagination: MetaPagination | undefined;
    ok: boolean | undefined;
  };
  assignments: {
    data: AssignmentUnplannedListItemResponse[];
    ok: boolean | undefined;
  };
}
export const INITIAL_TIME_RECORDS_STATE: BaseTimeRecordsState = {
  list: {
    data: [],
    filters: undefined,
    pagination: undefined,
    ok: undefined,
  },
  assignments: {
    data: [],
    ok: undefined,
  },
};

export function BaseTimeRecords(state: BaseTimeRecordsState = INITIAL_TIME_RECORDS_STATE, action: any): any {
  return produce(state, (draftState) => {
    switch (action.type) {
      case baseTimeRecordsActions.loadTimeRecordsList.type:
        draftState.list.ok = undefined;
        draftState.list.data = [];
        return;

      case baseTimeRecordsActions.setTimeRecordsList.type:
        draftState.list.data = action.payload;
        return;

      case baseTimeRecordsActions.setTimeRecordsFiltersAndPagination.type:
        draftState.list.filters = action.payload.filters;
        draftState.list.pagination = action.payload.pagination;
        return;

      case baseTimeRecordsActions.successTimeRecordsList.type:
        draftState.list.ok = true;
        return;

      case baseTimeRecordsActions.errorTimeRecordsList.type:
        draftState.list.ok = false;
        return;

      case baseTimeRecordsActions.loadAssignmentListForTimeRecordCreation.type:
        draftState.assignments.ok = undefined;
        return;

      case baseTimeRecordsActions.setAssignmentListForTimeRecordCreation.type:
        draftState.assignments.data = action.payload;
        return;

      case baseTimeRecordsActions.successAssignmentListForTimeRecordCreation.type:
        draftState.assignments.ok = true;
        return;

      case baseTimeRecordsActions.errorAssignmentListForTimeRecordCreation.type:
        draftState.assignments.ok = false;
        return;
    }
  });
}
