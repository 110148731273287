import produce from 'immer';
import { baseUserActions } from '../actions';
import { GroupItemResponse, GroupListItemResponse, UserItemResponse } from '../../../api';

export interface BaseUsersState {
  adminList: {
    data: UserItemResponse[] | undefined;
    ok: boolean | undefined;
  };
  userList: {
    data: UserItemResponse[] | undefined;
    ok: boolean | undefined;
  };
  adminAndUserList: {
    data: UserItemResponse[] | undefined;
    ok: boolean | undefined;
  };
  userGroups: {
    data: Array<GroupListItemResponse> | undefined;
    ok: boolean | undefined;
  };
  userGroup: {
    data: GroupItemResponse | undefined;
    ok: boolean | undefined;
    crud: boolean | undefined;
  };
  userFilter: {
    filterActive: boolean;
  };
}

export const INITIAL_USERS_STATE: BaseUsersState = {
  adminList: {
    data: [],
    ok: undefined,
  },
  userList: {
    data: [],
    ok: undefined,
  },
  adminAndUserList: {
    data: [],
    ok: undefined,
  },
  userGroups: {
    data: undefined,
    ok: undefined,
  },
  userGroup: {
    data: undefined,
    ok: undefined,
    crud: undefined,
  },
  userFilter: {
    filterActive: true,
  },
};

export function BaseUserReducer(state: BaseUsersState = INITIAL_USERS_STATE, action: any): any {
  return produce(state, (draftState) => {
    switch (action.type) {
      // Admin list
      case baseUserActions.loadAdminList.type:
        draftState.adminList.ok = undefined;
        return;

      case baseUserActions.setAdminList.type:
        draftState.adminList.data = action.payload;
        return;

      case baseUserActions.successAdminList.type:
        draftState.adminList.ok = true;
        return;

      case baseUserActions.errorAdminList.type:
        draftState.adminList.ok = false;
        return;

      // User list
      case baseUserActions.loadUserList.type:
        draftState.userList.ok = undefined;
        return;

      case baseUserActions.setUserList.type:
        draftState.userList.data = action.payload;
        return;

      case baseUserActions.successUserList.type:
        draftState.userList.ok = true;
        return;

      case baseUserActions.errorUserList.type:
        draftState.userList.ok = false;
        return;

      // Admin and User list
      case baseUserActions.loadAdminAndUserList.type:
        draftState.adminAndUserList.ok = undefined;
        return;

      case baseUserActions.setAdminAndUserList.type:
        draftState.adminAndUserList.data = action.payload;
        return;

      case baseUserActions.successAdminAndUserList.type:
        draftState.adminAndUserList.ok = true;
        return;

      case baseUserActions.errorAdminAndUserList.type:
        draftState.adminAndUserList.ok = false;
        return;

      // User Groups
      case baseUserActions.loadUserGroups.type:
        draftState.userGroups.ok = undefined;
        return;

      case baseUserActions.setUserGroups.type:
        draftState.userGroups.data = action.payload;
        return;

      case baseUserActions.successUserGroups.type:
        draftState.userGroups.ok = true;
        return;

      case baseUserActions.errorUserGroups.type:
        draftState.userGroups.ok = false;
        return;

      // User Group
      case baseUserActions.loadGroup.type:
        draftState.userGroup.ok = undefined;
        return;

      case baseUserActions.setGroup.type:
        draftState.userGroup.data = action.payload;
        return;

      case baseUserActions.successGroup.type:
        draftState.userGroup.ok = true;
        return;

      case baseUserActions.errorGroup.type:
        draftState.userGroup.ok = false;
        return;

      // User Groups CRUD
      case baseUserActions.createGroupCrud.type:
      case baseUserActions.updateGroupCrud.type:
        draftState.userGroup.crud = undefined;
        return;

      case baseUserActions.successGroupCrud.type:
        draftState.userGroup.crud = true;
        return;

      case baseUserActions.errorGroupCrud.type:
        draftState.userGroup.crud = false;
        return;

      // User filter
      case baseUserActions.setUserInactiveFilter.type:
        draftState.userFilter.filterActive = action.filterActive;
        return;
    }
  });
}
