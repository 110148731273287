import { Injectable, ElementRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseFormatDatePipe } from '../pipes/base-format-date.pipe';
import { BaseModalService } from './base-modal.service';
import {
  BaseSigningStatusEnum,
  BaseSigningStatusType,
} from '../../modules/assignments/_enums/base-signing-status.enum';
import { BaseDateService } from './base-date.service';
import { AssignmentPspResponse, PspAssignmentListItemResponse, ContractResponse } from '../../../api';
import { BaseCoreState, baseAssignmentsActions } from '../../_store';

interface CurrentOperationStatusInterface {
  signButtonTitle: string;
  statusButtonBorder: string;
  canBeSigned: boolean;
}

interface CurrentAssignmentTimeInterface {
  isPlannedAssignment: boolean;
  isActiveAssignment: boolean;
  isEndedAssignment: boolean;
}

@Injectable()
export class BaseEmployeeOperationService {
  constructor(
    private formatDatePipe: BaseFormatDatePipe,
    private store: Store<BaseCoreState>,
    private modalService: BaseModalService
  ) {}

  updateStatus(
    assignment: AssignmentPspResponse | (PspAssignmentListItemResponse & { isSelected: boolean })
  ): CurrentOperationStatusInterface {
    let contract: ContractResponse;

    if ('contracts' in assignment) {
      contract = assignment.contracts?.find((contract) => contract.valid) as ContractResponse;
    } else {
      contract = (assignment as PspAssignmentListItemResponse).contract;
    }

    return {
      canBeSigned:
        contract?.signatures?.statusPsp !== 'signed' &&
        contract?.signingStatus !== BaseSigningStatusEnum.SIGNED_BY_PSP &&
        contract?.signingStatus !== BaseSigningStatusEnum.SIGNED,
      statusButtonBorder: this.setSignButtonClass(contract?.signingStatus),
      signButtonTitle: this.setSignButtonTitle(contract?.signingStatus),
    };
  }

  setAssignmentTimeStatus(
    assignment: AssignmentPspResponse | (PspAssignmentListItemResponse & { isSelected: boolean })
  ): CurrentAssignmentTimeInterface {
    return {
      isPlannedAssignment: BaseDateService.isBefore(new Date(), new Date(assignment.startDate)),
      isActiveAssignment: BaseDateService.isInsideAPeriod(
        new Date(),
        new Date(assignment.startDate),
        new Date(assignment.endDate)
      ),
      isEndedAssignment: BaseDateService.isAfter(new Date(), new Date(assignment.endDate)),
    };
  }

  checkAssigmentDate(
    assignment: AssignmentPspResponse | (PspAssignmentListItemResponse & { isSelected: boolean })
  ): boolean {
    const date = this.formatDatePipe.transform(new Date());
    const formattedDate = BaseDateService.toServerFormatString(date);
    return BaseDateService.isEqual(new Date(formattedDate), new Date(assignment.endDate));
  }

  showEmployeeModal(employeeModal: ElementRef, assignmentId: number) {
    this.store.dispatch(baseAssignmentsActions.loadAssignment({ payload: { assignment: assignmentId } }));
    this.store.dispatch(baseAssignmentsActions.loadAssignmentDates({ payload: { assignment: assignmentId } }));

    return this.modalService.open(
      {
        hideHeader: true,
        bodyTemplate: employeeModal,
        hideFooter: true,
      },
      {
        size: 'xl',
      }
    );
  }

  setSignButtonTitle(signingStatus: BaseSigningStatusType): string {
    return signingStatus === BaseSigningStatusEnum.SIGNED
      ? 'candidates.status.signed'
      : signingStatus === BaseSigningStatusEnum.SIGNED_BY_PSP
      ? 'externalEmployees.contractStatus.waitingForPartner'
      : signingStatus === BaseSigningStatusEnum.UNSIGNED || signingStatus === BaseSigningStatusEnum.SIGNED_BY_CUSTOMER
      ? 'externalEmployees.contractStatus.signatureRequired'
      : '';
  }

  setSignButtonClass(signingStatus: BaseSigningStatusType): string {
    return signingStatus === BaseSigningStatusEnum.SIGNED
      ? 'state-btn-border-green'
      : signingStatus === BaseSigningStatusEnum.SIGNED_BY_PSP
      ? 'state-btn-border-yellow'
      : signingStatus === BaseSigningStatusEnum.UNSIGNED || signingStatus === BaseSigningStatusEnum.SIGNED_BY_CUSTOMER
      ? 'state-btn-border-red'
      : '';
  }
}
