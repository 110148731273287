<base-switch
  *ngIf="isSurchargesEditable"
  label="surcharges.editMode"
  [(ngModel)]="surchargesEditableValue"
  [isDisabled]="disabled || isCancellationPeriodEdited"
  (ngModelChange)="changeIsEditable()"
  class="d-flex surcharge-editable-mode mb-6"
></base-switch>
<form
  [formGroup]="cancellationPeriodForm"
  [ngClass]="{ 'was-validated': submitted }"
>
  <div class="d-flex mb-6">
    <base-input
      label="surcharges.cancellationPeriodInHours"
      placeholder=""
      type="number"
      min="1"
      [isPreview]="!isCancellationPeriodEdited"
      [ngClass]="{ 'editable-input': isCancellationPeriodEdited }"
      formControlName="cancellationPeriod"
      [isInvalid]="!!cancellationPeriodForm.get('cancellationPeriod')?.errors"
      [errorMessage]="
        cancellationPeriodForm.get('cancellationPeriod')?.errors | baseFieldValidation: { min: 'amountMinOne' }
      "
      class="me-2 canc-period-field"
    ></base-input>
    <div class="d-flex gap-1">
      <base-button
        color="dark"
        icon="fa-solid {{ isCancellationPeriodEdited ? 'fa-check' : 'fa-pen' }}"
        [size]="buttonSizes.sm"
        [iconSize]="buttonSizes.sm"
        class="canc-period-form-trigger"
        [disabled]="disabled"
        [tooltip]="isCancellationPeriodEdited ? 'action.save' : 'action.edit'"
        (btnClick)="changeCancPeriod()"
      ></base-button>
      <base-button
        color="dark"
        *ngIf="isCancellationPeriodEdited"
        icon="fa-solid fa-xmark"
        [size]="buttonSizes.sm"
        [iconSize]="buttonSizes.sm"
        [disabled]="disabled"
        tooltip="action.cancel"
        class="canc-period-form-trigger"
        (btnClick)="cancelChangePeriod()"
      ></base-button>
    </div>
  </div>
</form>

<h5 class="mb-6">{{ 'surcharges.plural' | translate }}</h5>

<div class="listview w-100 w-xl-75 ms-0 mb-5 px-0">
  <div class="listview-header my-2">
    <div class="row">
      <div
        class="col-3"
        baseTruncate
      >
        {{ 'surcharges.type' | translate }}
      </div>
      <div
        class="col-3"
        baseTruncate
      >
        {{ 'times' | translate }}
      </div>
      <div
        class="col-3"
        baseTruncate
      >
        {{ 'surcharges.singular' | translate }}
      </div>
      <div
        class="col-2"
        baseTruncate
      >
        {{ 'surcharges.cumulative' | translate }}
      </div>
      <div class="col-1"></div>
    </div>
  </div>

  <base-terms-of-contract-surcharge
    *ngFor="let surcharge of surcharges$ | async"
    [surcharge]="surcharge"
    [disabled]="disabled || isCancellationPeriodEdited"
    (editChange)="disabled = $event; isEdited.emit($event)"
  ></base-terms-of-contract-surcharge>
</div>
