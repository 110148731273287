import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { baseInquiryActions, baseInquirySelectors, BaseCoreState } from '../../../_store';
import { BaseInquiryStatus } from '../../inquiry/_enums/base-inquiry.enum';

@Injectable()
export class BaseCustomerDashboardInquiriesGuard {
  constructor(private store: Store<BaseCoreState>) {}

  canActivate(): Observable<boolean> {
    this.store.dispatch(
      baseInquiryActions.getInquiries({
        payload: {
          page: 1,
          size: 10,
          'statuses[]': [BaseInquiryStatus.unpublished.toString()],
        },
      })
    );
    return this.store.select(baseInquirySelectors.getInquiries).pipe(
      filter((response) => response.ok !== undefined),
      map((response) => !!response.ok)
    );
  }
}
