<div id="toolbarViewer">
  <div
    id="toolbarViewerLeft"
    class="d-flex w-100"
  >
    <h3
      class="fa-2x"
      baseTruncate
    >
      {{ title }}
    </h3>
    <i
      class="fa-solid fa-download mx-4 pointer fa-2x"
      id="download"
      (click)="downloadImage.emit()"
    ></i>
    <i
      class="fa-solid fa-circle-xmark pointer fa-2x ms-auto"
      (click)="modalRef.close()"
    ></i>
  </div>
</div>
