<button
  [tabindex]="tabindex"
  [ngClass]="classes"
>
  <span>
    <i [ngClass]="iconClasses"></i>
  </span>
  {{ label | translate }}
  <ng-content></ng-content>
</button>
