import { Pipe, PipeTransform } from '@angular/core';
import { BaseEnvironmentService } from '../../core/services/base-environment.service';
import { BaseApplication } from '../enums/base-application.enum';

@Pipe({
  name: 'baseBusinessKey',
})
export class BaseBusinessKeyPipe implements PipeTransform {
  constructor(private environmentService: BaseEnvironmentService) {}

  transform(value: string): string {
    const application = this.environmentService.getLocalValue('application').app;
    switch (application) {
      case BaseApplication.business:
        return value + '-business';
      default:
        return value;
    }
  }
}
