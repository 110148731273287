import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AssignmentCustomerResponse } from '../../../../api';
import { BaseAvatarSize } from '../../../shared/components/avatar/base-avatar.component';
import { BaseDateService } from '../../../shared/services/base-date.service';
import { BaseDeregisterFormInterface } from '../../_interfaces/base-deregister-candidate-modal.interface';
import { BaseFormatDatePipe } from '../../../shared/pipes/base-format-date.pipe';

@Component({
  selector: 'base-deregister-candidate-modal',
  templateUrl: './base-deregister-candidate-modal.component.html',
  styleUrls: ['./base-deregister-candidate-modal.component.scss'],
})
export class BaseDeregisterCandidateModalComponent implements OnChanges, OnInit {
  @Input() form: FormGroup<BaseDeregisterFormInterface>;
  @Input() assignment: AssignmentCustomerResponse;
  @Input() isFormSubmitted = false;
  avatarSize = BaseAvatarSize;
  minDate: { year: number; month: number; day: number };
  maxDate: { year: number; month: number; day: number };
  isFirstDatesEqual: boolean;
  newPeriodMessage: string;

  constructor(private datePipe: BaseFormatDatePipe) {}

  ngOnInit() {
    this.handleCancellationPeriodDate();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.assignment?.currentValue) {
      const min = BaseDateService.toDate(this.assignment.startDate);
      const max = BaseDateService.toDate(this.assignment.endDate);
      this.minDate = { year: min.getFullYear(), month: min.getMonth() + 1, day: min.getDate() };
      this.maxDate = { year: max.getFullYear(), month: max.getMonth() + 1, day: max.getDate() };
    }
  }

  private handleCancellationPeriodDate(): void {
    this.form.controls['cancelationDate'].valueChanges.subscribe((date) => {
      this.isFirstDatesEqual = BaseDateService.isEqual(new Date(this.assignment.startDate), new Date(date));
      const currentDate = new Date(date);
      const endDate = currentDate.setDate(currentDate.getDate() - 1);
      this.newPeriodMessage =
        this.datePipe.transform(new Date(this.assignment.startDate)) + '-' + this.datePipe.transform(new Date(endDate));
    });
  }
}
