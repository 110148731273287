/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ContractRequest } from '../models/contract-request';
import { CustomerSignVoucherResponse } from '../models/customer-sign-voucher-response';
import { GeneralApiResponse } from '../models/general-api-response';
import { PspSignVoucherResponse } from '../models/psp-sign-voucher-response';
import { UpdatedResponse } from '../models/updated-response';

@Injectable({
  providedIn: 'root',
})
export class SigningService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation postCustomerSignAcceptCondition
   */
  static readonly PostCustomerSignAcceptConditionPath = '/api/web/v1/customers/{customer}/sign/conditions';

  /**
   * Accept Customer logged-in user sign condition.
   *
   * **Accessible only for users with permission 'FEATURE_SIGN_CONTRACT'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postCustomerSignAcceptCondition()` instead.
   *
   * This method doesn't expect any request body.
   */
  postCustomerSignAcceptCondition$Response(params: {

    /**
     * Customer Id.
     */
    customer: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<UpdatedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SigningService.PostCustomerSignAcceptConditionPath, 'post');
    if (params) {
      rb.path('customer', params.customer, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UpdatedResponse>;
      })
    );
  }

  /**
   * Accept Customer logged-in user sign condition.
   *
   * **Accessible only for users with permission 'FEATURE_SIGN_CONTRACT'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postCustomerSignAcceptCondition$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  postCustomerSignAcceptCondition(params: {

    /**
     * Customer Id.
     */
    customer: number;
    context?: HttpContext
  }
): Observable<UpdatedResponse> {

    return this.postCustomerSignAcceptCondition$Response(params).pipe(
      map((r: StrictHttpResponse<UpdatedResponse>) => r.body as UpdatedResponse)
    );
  }

  /**
   * Path part for operation putCustomerSignatureDigital
   */
  static readonly PutCustomerSignatureDigitalPath = '/api/web/v1/customers/{customer}/sign/contracts/{contract}/signDigital';

  /**
   * Sign a contract.
   *
   * **Accessible only for users with permission 'FEATURE_SIGN_CONTRACT'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putCustomerSignatureDigital()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putCustomerSignatureDigital$Response(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Contract Id.
     */
    contract: number;
    context?: HttpContext
    body: ContractRequest
  }
): Observable<StrictHttpResponse<{

/**
 * Message
 */
'message'?: string;

/**
 * Data
 */
'data'?: any;
}>> {

    const rb = new RequestBuilder(this.rootUrl, SigningService.PutCustomerSignatureDigitalPath, 'put');
    if (params) {
      rb.path('customer', params.customer, {});
      rb.path('contract', params.contract, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * Message
         */
        'message'?: string;
        
        /**
         * Data
         */
        'data'?: any;
        }>;
      })
    );
  }

  /**
   * Sign a contract.
   *
   * **Accessible only for users with permission 'FEATURE_SIGN_CONTRACT'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putCustomerSignatureDigital$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putCustomerSignatureDigital(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Contract Id.
     */
    contract: number;
    context?: HttpContext
    body: ContractRequest
  }
): Observable<{

/**
 * Message
 */
'message'?: string;

/**
 * Data
 */
'data'?: any;
}> {

    return this.putCustomerSignatureDigital$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * Message
 */
'message'?: string;

/**
 * Data
 */
'data'?: any;
}>) => r.body as {

/**
 * Message
 */
'message'?: string;

/**
 * Data
 */
'data'?: any;
})
    );
  }

  /**
   * Path part for operation putCustomerSignatureExternal
   */
  static readonly PutCustomerSignatureExternalPath = '/api/web/v1/customers/{customer}/sign/contracts/{contract}/signExternal';

  /**
   * Sign a contract.
   *
   * **Accessible only for users with permission 'FEATURE_SIGN_CONTRACT'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putCustomerSignatureExternal()` instead.
   *
   * This method doesn't expect any request body.
   */
  putCustomerSignatureExternal$Response(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Contract Id.
     */
    contract: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<{

/**
 * Message
 */
'message'?: string;

/**
 * Data
 */
'data'?: any;
}>> {

    const rb = new RequestBuilder(this.rootUrl, SigningService.PutCustomerSignatureExternalPath, 'put');
    if (params) {
      rb.path('customer', params.customer, {});
      rb.path('contract', params.contract, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * Message
         */
        'message'?: string;
        
        /**
         * Data
         */
        'data'?: any;
        }>;
      })
    );
  }

  /**
   * Sign a contract.
   *
   * **Accessible only for users with permission 'FEATURE_SIGN_CONTRACT'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putCustomerSignatureExternal$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  putCustomerSignatureExternal(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Contract Id.
     */
    contract: number;
    context?: HttpContext
  }
): Observable<{

/**
 * Message
 */
'message'?: string;

/**
 * Data
 */
'data'?: any;
}> {

    return this.putCustomerSignatureExternal$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * Message
 */
'message'?: string;

/**
 * Data
 */
'data'?: any;
}>) => r.body as {

/**
 * Message
 */
'message'?: string;

/**
 * Data
 */
'data'?: any;
})
    );
  }

  /**
   * Path part for operation getCustomerSignVoucher
   */
  static readonly GetCustomerSignVoucherPath = '/api/web/v1/customers/{customer}/sign/voucher';

  /**
   * Get Customer logged-in users namirial voucher.
   *
   * **Accessible only for users with permission 'FEATURE_SIGN_CONTRACT'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerSignVoucher()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerSignVoucher$Response(params: {

    /**
     * Customer Id.
     */
    customer: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<GeneralApiResponse & {
'data'?: CustomerSignVoucherResponse;
}>> {

    const rb = new RequestBuilder(this.rootUrl, SigningService.GetCustomerSignVoucherPath, 'get');
    if (params) {
      rb.path('customer', params.customer, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GeneralApiResponse & {
        'data'?: CustomerSignVoucherResponse;
        }>;
      })
    );
  }

  /**
   * Get Customer logged-in users namirial voucher.
   *
   * **Accessible only for users with permission 'FEATURE_SIGN_CONTRACT'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCustomerSignVoucher$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerSignVoucher(params: {

    /**
     * Customer Id.
     */
    customer: number;
    context?: HttpContext
  }
): Observable<GeneralApiResponse & {
'data'?: CustomerSignVoucherResponse;
}> {

    return this.getCustomerSignVoucher$Response(params).pipe(
      map((r: StrictHttpResponse<GeneralApiResponse & {
'data'?: CustomerSignVoucherResponse;
}>) => r.body as GeneralApiResponse & {
'data'?: CustomerSignVoucherResponse;
})
    );
  }

  /**
   * Path part for operation postPspSignAcceptCondition
   */
  static readonly PostPspSignAcceptConditionPath = '/api/web/v1/psps/{psp}/sign/conditions';

  /**
   * Accept PSP logged-in user sign condition.
   *
   * **Accessible only for users with permission 'FEATURE_SIGN_CONTRACT'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postPspSignAcceptCondition()` instead.
   *
   * This method doesn't expect any request body.
   */
  postPspSignAcceptCondition$Response(params: {
    psp: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<UpdatedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SigningService.PostPspSignAcceptConditionPath, 'post');
    if (params) {
      rb.path('psp', params.psp, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UpdatedResponse>;
      })
    );
  }

  /**
   * Accept PSP logged-in user sign condition.
   *
   * **Accessible only for users with permission 'FEATURE_SIGN_CONTRACT'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postPspSignAcceptCondition$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  postPspSignAcceptCondition(params: {
    psp: string;
    context?: HttpContext
  }
): Observable<UpdatedResponse> {

    return this.postPspSignAcceptCondition$Response(params).pipe(
      map((r: StrictHttpResponse<UpdatedResponse>) => r.body as UpdatedResponse)
    );
  }

  /**
   * Path part for operation putAppApiWebV1PspSignContractSignconcretisationIndex
   */
  static readonly PutAppApiWebV1PspSignContractSignconcretisationIndexPath = '/api/web/v1/psps/{psp}/sign/contracts/{contract}/signConcretisation';

  /**
   * Sign a contract.
   *
   * **Accessible only for users with permission 'FEATURE_SIGN_CONTRACT'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putAppApiWebV1PspSignContractSignconcretisationIndex()` instead.
   *
   * This method doesn't expect any request body.
   */
  putAppApiWebV1PspSignContractSignconcretisationIndex$Response(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * Contract Id.
     */
    contract: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<{

/**
 * Message
 */
'message'?: string;

/**
 * Data
 */
'data'?: any;
}>> {

    const rb = new RequestBuilder(this.rootUrl, SigningService.PutAppApiWebV1PspSignContractSignconcretisationIndexPath, 'put');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.path('contract', params.contract, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * Message
         */
        'message'?: string;
        
        /**
         * Data
         */
        'data'?: any;
        }>;
      })
    );
  }

  /**
   * Sign a contract.
   *
   * **Accessible only for users with permission 'FEATURE_SIGN_CONTRACT'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putAppApiWebV1PspSignContractSignconcretisationIndex$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  putAppApiWebV1PspSignContractSignconcretisationIndex(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * Contract Id.
     */
    contract: number;
    context?: HttpContext
  }
): Observable<{

/**
 * Message
 */
'message'?: string;

/**
 * Data
 */
'data'?: any;
}> {

    return this.putAppApiWebV1PspSignContractSignconcretisationIndex$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * Message
 */
'message'?: string;

/**
 * Data
 */
'data'?: any;
}>) => r.body as {

/**
 * Message
 */
'message'?: string;

/**
 * Data
 */
'data'?: any;
})
    );
  }

  /**
   * Path part for operation putAppApiWebV1PspSignContractSigndigitalIndex
   */
  static readonly PutAppApiWebV1PspSignContractSigndigitalIndexPath = '/api/web/v1/psps/{psp}/sign/contracts/{contract}/signDigital';

  /**
   * Sign a contract.
   *
   * **Accessible only for users with permission 'FEATURE_SIGN_CONTRACT'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putAppApiWebV1PspSignContractSigndigitalIndex()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putAppApiWebV1PspSignContractSigndigitalIndex$Response(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * Contract Id.
     */
    contract: number;
    context?: HttpContext
    body: ContractRequest
  }
): Observable<StrictHttpResponse<{

/**
 * Message
 */
'message'?: string;

/**
 * Data
 */
'data'?: any;
}>> {

    const rb = new RequestBuilder(this.rootUrl, SigningService.PutAppApiWebV1PspSignContractSigndigitalIndexPath, 'put');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.path('contract', params.contract, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * Message
         */
        'message'?: string;
        
        /**
         * Data
         */
        'data'?: any;
        }>;
      })
    );
  }

  /**
   * Sign a contract.
   *
   * **Accessible only for users with permission 'FEATURE_SIGN_CONTRACT'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putAppApiWebV1PspSignContractSigndigitalIndex$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putAppApiWebV1PspSignContractSigndigitalIndex(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * Contract Id.
     */
    contract: number;
    context?: HttpContext
    body: ContractRequest
  }
): Observable<{

/**
 * Message
 */
'message'?: string;

/**
 * Data
 */
'data'?: any;
}> {

    return this.putAppApiWebV1PspSignContractSigndigitalIndex$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * Message
 */
'message'?: string;

/**
 * Data
 */
'data'?: any;
}>) => r.body as {

/**
 * Message
 */
'message'?: string;

/**
 * Data
 */
'data'?: any;
})
    );
  }

  /**
   * Path part for operation putAppApiWebV1PspSignContractSignexternalIndex
   */
  static readonly PutAppApiWebV1PspSignContractSignexternalIndexPath = '/api/web/v1/psps/{psp}/sign/contracts/{contract}/signExternal';

  /**
   * Sign a contract.
   *
   * **Accessible only for users with permission 'FEATURE_SIGN_CONTRACT'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putAppApiWebV1PspSignContractSignexternalIndex()` instead.
   *
   * This method doesn't expect any request body.
   */
  putAppApiWebV1PspSignContractSignexternalIndex$Response(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * Contract Id.
     */
    contract: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<{

/**
 * Message
 */
'message'?: string;

/**
 * Data
 */
'data'?: any;
}>> {

    const rb = new RequestBuilder(this.rootUrl, SigningService.PutAppApiWebV1PspSignContractSignexternalIndexPath, 'put');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.path('contract', params.contract, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * Message
         */
        'message'?: string;
        
        /**
         * Data
         */
        'data'?: any;
        }>;
      })
    );
  }

  /**
   * Sign a contract.
   *
   * **Accessible only for users with permission 'FEATURE_SIGN_CONTRACT'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putAppApiWebV1PspSignContractSignexternalIndex$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  putAppApiWebV1PspSignContractSignexternalIndex(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * Contract Id.
     */
    contract: number;
    context?: HttpContext
  }
): Observable<{

/**
 * Message
 */
'message'?: string;

/**
 * Data
 */
'data'?: any;
}> {

    return this.putAppApiWebV1PspSignContractSignexternalIndex$Response(params).pipe(
      map((r: StrictHttpResponse<{

/**
 * Message
 */
'message'?: string;

/**
 * Data
 */
'data'?: any;
}>) => r.body as {

/**
 * Message
 */
'message'?: string;

/**
 * Data
 */
'data'?: any;
})
    );
  }

  /**
   * Path part for operation getPspSignVoucher
   */
  static readonly GetPspSignVoucherPath = '/api/web/v1/psps/{psp}/sign/voucher';

  /**
   * Get PSP logged-in users namirial voucher.
   *
   * **Accessible only for users with permission 'FEATURE_SIGN_CONTRACT'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPspSignVoucher()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPspSignVoucher$Response(params: {
    psp: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<GeneralApiResponse & {
'data'?: PspSignVoucherResponse;
}>> {

    const rb = new RequestBuilder(this.rootUrl, SigningService.GetPspSignVoucherPath, 'get');
    if (params) {
      rb.path('psp', params.psp, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GeneralApiResponse & {
        'data'?: PspSignVoucherResponse;
        }>;
      })
    );
  }

  /**
   * Get PSP logged-in users namirial voucher.
   *
   * **Accessible only for users with permission 'FEATURE_SIGN_CONTRACT'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPspSignVoucher$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPspSignVoucher(params: {
    psp: string;
    context?: HttpContext
  }
): Observable<GeneralApiResponse & {
'data'?: PspSignVoucherResponse;
}> {

    return this.getPspSignVoucher$Response(params).pipe(
      map((r: StrictHttpResponse<GeneralApiResponse & {
'data'?: PspSignVoucherResponse;
}>) => r.body as GeneralApiResponse & {
'data'?: PspSignVoucherResponse;
})
    );
  }

}
