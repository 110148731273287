import { Component, Input, OnInit } from '@angular/core';
import { NgbDateAdapter, NgbDateParserFormatter, NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { CustomerInquiryOfferListItemResponse } from '../../../../api';
import {
  BaseCustomAdapter,
  BaseCustomDateParserFormatter,
} from '../../../shared/components/datepicker/base-datepicker.adapter';
import { BaseCustomDatepickerI18n } from '../../../shared/components/datepicker/base-datepicker.i18n';

@Component({
  selector: 'base-date-picker-modal',
  templateUrl: './base-date-picker-modal.component.html',
  styleUrls: ['./base-date-picker-modal.component.scss'],
  providers: [
    { provide: NgbDateAdapter, useClass: BaseCustomAdapter },
    { provide: NgbDateParserFormatter, useClass: BaseCustomDateParserFormatter },
    { provide: NgbDatepickerI18n, useClass: BaseCustomDatepickerI18n },
  ],
})
export class BaseDatePickerModalComponent implements OnInit {
  @Input() currentOffer: CustomerInquiryOfferListItemResponse | undefined;
  @Input() conflictedDays: Array<Date>;
  currentStartDate: NgbDateStruct;
  currentEndDate: NgbDateStruct;

  ngOnInit() {
    if (this.currentOffer) {
      this.currentStartDate = this.getDateStruct(this.currentOffer.positions[0].date);
      this.currentEndDate = this.getDateStruct(
        this.currentOffer.positions[this.currentOffer.positions.length - 1].date
      );
    }
    if (this.conflictedDays) {
      this.currentStartDate = this.getDateStruct(this.conflictedDays[0].toDateString());
      this.currentEndDate = this.getDateStruct(this.conflictedDays[this.conflictedDays.length - 1].toDateString());
    }
  }

  getDateStruct(date: string) {
    let taskDate = new Date(date);
    let day: number = taskDate.getDate();
    let month: number = taskDate.getMonth() + 1;
    let year: number = taskDate.getFullYear();
    return { day: day, month: month, year: year };
  }

  hasPosition(date: NgbDateStruct) {
    return this.dateHasPosition(date);
  }

  dateHasPosition(date: NgbDateStruct) {
    if (this.currentOffer) {
      for (let i = 0; i < this.currentOffer?.positions?.length; i++) {
        let dateStruct = this.getDateStruct(this.currentOffer.positions[i].date);

        if (dateStruct.day === date.day && dateStruct.month === date.month && dateStruct.year === date.year) {
          return true;
        }
      }
    }
    if (this.conflictedDays) {
      for (let i = 0; i < this.conflictedDays?.length; i++) {
        let dateStruct = this.getDateStruct(this.conflictedDays[i].toDateString());

        if (dateStruct.day === date.day && dateStruct.month === date.month && dateStruct.year === date.year) {
          return true;
        }
      }
    }

    return false;
  }
}
