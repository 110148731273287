import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { baseAdministrationActions, baseAdministrationSelectors, BaseCoreState, PspResponse } from 'base';

@Injectable()
export class GrowthPspDataGuard {
  constructor(private store: Store<BaseCoreState>) {}

  canActivate(): Observable<boolean> {
    this.store
      .select(baseAdministrationSelectors.getPspDataData)
      .pipe(
        take(1),
        map((state) => state?.data as PspResponse)
      )
      .subscribe((data) => {
        if (!data) {
          this.store.dispatch(baseAdministrationActions.loadPspData());
        }
      });

    return this.store.select(baseAdministrationSelectors.getPspData).pipe(
      filter((state) => state?.ok !== undefined),
      map((state) => state?.ok as boolean)
    );
  }
}
