import { Validators, FormGroup, NonNullableFormBuilder } from '@angular/forms';
import { baseIbanValidator } from '../../../shared/validators/base-iban-validator.validator';
import { baseEmailValidator } from '../../../shared/validators/base-email.validator';
import { basePhoneNumberValidator } from '../../../shared/validators/base-phone-number.validator';
import { CustomerMainFormInterface } from '../_interfaces/base-administration.interface';
import { baseWebsiteValidator } from '../../../shared/validators/base-website.validator';

export const companyMainFormKey = Symbol();
export const pspMainFormKey = Symbol();

export const createCompanyMainFormGroup = (fb: NonNullableFormBuilder): FormGroup<CustomerMainFormInterface> => {
  return createMainForm(fb);
};

export const createPSPMainFormGroup = (fb: NonNullableFormBuilder): FormGroup<CustomerMainFormInterface> => {
  const mainForm = createMainForm(fb);
  mainForm.addControl(
    'bankDetails',
    fb.group({
      bankName: ['', [Validators.maxLength(255)]],
      iban: ['', [baseIbanValidator()]],
      bic: ['', [Validators.minLength(8), Validators.maxLength(11)]],
    })
  );
  mainForm.controls.contact.addControl(
    'collectiveAgreement',
    fb.control('', [Validators.required, Validators.maxLength(255)])
  );
  return mainForm;
};

export const createMainForm = (fb: NonNullableFormBuilder): FormGroup<CustomerMainFormInterface> => {
  return fb.group({
    contact: fb.group({
      name: ['', [Validators.required, Validators.maxLength(255)]],
      vatId: ['', [Validators.maxLength(255)]],
      phone: ['', [Validators.maxLength(255), basePhoneNumberValidator()]],
      email: ['', [baseEmailValidator(), Validators.maxLength(255)]],
      mobilePhone: ['', [Validators.maxLength(255)]],
      fax: ['', [Validators.maxLength(255)]],
      legalName: ['', [Validators.required, Validators.maxLength(255)]],
      website: ['', [baseWebsiteValidator(), Validators.maxLength(255)]],
    }),
    businessAddress: fb.group({
      street: ['', [Validators.required, Validators.maxLength(255)]],
      city: ['', [Validators.required, Validators.maxLength(255)]],
      postCode: ['', [Validators.required]],
      countryCode: ['', [Validators.required]],
    }),
    billingAddress: fb.group({
      street: ['', [Validators.required, Validators.maxLength(255)]],
      city: ['', [Validators.required, Validators.maxLength(255)]],
      postCode: ['', [Validators.required]],
      countryCode: ['', [Validators.required]],
    }),
    logo: '',
  });
};
