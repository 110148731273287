/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AssignmentDateListItemResponse } from '../models/assignment-date-list-item-response';
import { AssignmentPspResponse } from '../models/assignment-psp-response';
import { AssignmentUnplannedListItemResponse } from '../models/assignment-unplanned-list-item-response';
import { CancelationRequest } from '../models/cancelation-request';
import { CreateAssignmentDateRequest } from '../models/create-assignment-date-request';
import { CreatedResponse } from '../models/created-response';
import { EmptySuccessfulResponse } from '../models/empty-successful-response';
import { GeneralApiResponse } from '../models/general-api-response';
import { ListApiResponse } from '../models/list-api-response';
import { OrganizationFilterListResponse } from '../models/organization-filter-list-response';
import { PspAcceptRequest } from '../models/psp-accept-request';
import { PspAssignmentDateListItemResponse } from '../models/psp-assignment-date-list-item-response';
import { PspAssignmentFilterListsResponse } from '../models/psp-assignment-filter-lists-response';
import { PspAssignmentListItemResponse } from '../models/psp-assignment-list-item-response';
import { PspCreateAbsence } from '../models/psp-create-absence';
import { PspProfileAssignmentItemResponse } from '../models/psp-profile-assignment-item-response';
import { PaginatedApiResponse } from '../models/paginated-api-response';
import { PaginatedApiResponseWithFilters } from '../models/paginated-api-response-with-filters';
import { UpdateWorkTimesRequest } from '../models/update-work-times-request';

@Injectable({
  providedIn: 'root',
})
export class PspAssignmentService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation postPspAssignmentAbsenceCreate
   */
  static readonly PostPspAssignmentAbsenceCreatePath = '/api/web/v1/psps/{psp}/assignments/{assignment}/absences';

  /**
   * Create absence.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_ASSIGNMENTS' or 'FEATURE_ADMIN_ASSIGNMENT_DATES'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postPspAssignmentAbsenceCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPspAssignmentAbsenceCreate$Response(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * Assignment Id.
     */
    assignment: number;
    context?: HttpContext
    body: PspCreateAbsence
  }
): Observable<StrictHttpResponse<EmptySuccessfulResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PspAssignmentService.PostPspAssignmentAbsenceCreatePath, 'post');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.path('assignment', params.assignment, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmptySuccessfulResponse>;
      })
    );
  }

  /**
   * Create absence.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_ASSIGNMENTS' or 'FEATURE_ADMIN_ASSIGNMENT_DATES'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postPspAssignmentAbsenceCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPspAssignmentAbsenceCreate(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * Assignment Id.
     */
    assignment: number;
    context?: HttpContext
    body: PspCreateAbsence
  }
): Observable<EmptySuccessfulResponse> {

    return this.postPspAssignmentAbsenceCreate$Response(params).pipe(
      map((r: StrictHttpResponse<EmptySuccessfulResponse>) => r.body as EmptySuccessfulResponse)
    );
  }

  /**
   * Path part for operation getPspAsignmentAssignmentDateList
   */
  static readonly GetPspAsignmentAssignmentDateListPath = '/api/web/v1/psps/{psp}/assignments/{assignment}/assignment-dates';

  /**
   * Get a list of all AssignmentDates.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_ASSIGNMENTS'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPspAsignmentAssignmentDateList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPspAsignmentAssignmentDateList$Response(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * Assignment Id.
     */
    assignment: number;

    /**
     * Filter Assignments Dates by status.
     */
    status?: 'open' | 'submitted' | 'accepted';
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ListApiResponse & {
'data'?: Array<AssignmentDateListItemResponse>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, PspAssignmentService.GetPspAsignmentAssignmentDateListPath, 'get');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.path('assignment', params.assignment, {});
      rb.query('status', params.status, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListApiResponse & {
        'data'?: Array<AssignmentDateListItemResponse>;
        }>;
      })
    );
  }

  /**
   * Get a list of all AssignmentDates.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_ASSIGNMENTS'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPspAsignmentAssignmentDateList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPspAsignmentAssignmentDateList(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * Assignment Id.
     */
    assignment: number;

    /**
     * Filter Assignments Dates by status.
     */
    status?: 'open' | 'submitted' | 'accepted';
    context?: HttpContext
  }
): Observable<ListApiResponse & {
'data'?: Array<AssignmentDateListItemResponse>;
}> {

    return this.getPspAsignmentAssignmentDateList$Response(params).pipe(
      map((r: StrictHttpResponse<ListApiResponse & {
'data'?: Array<AssignmentDateListItemResponse>;
}>) => r.body as ListApiResponse & {
'data'?: Array<AssignmentDateListItemResponse>;
})
    );
  }

  /**
   * Path part for operation postPspAsignmentAssignmentDateCreate
   */
  static readonly PostPspAsignmentAssignmentDateCreatePath = '/api/web/v1/psps/{psp}/assignments/{assignment}/assignment-dates';

  /**
   * Create AssignmentDates.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_ASSIGNMENT_DATES' or 'FEATURE_ADMIN_ASSIGNMENTS'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postPspAsignmentAssignmentDateCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPspAsignmentAssignmentDateCreate$Response(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * Assignment Id.
     */
    assignment: number;
    context?: HttpContext
    body: CreateAssignmentDateRequest
  }
): Observable<StrictHttpResponse<CreatedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PspAssignmentService.PostPspAsignmentAssignmentDateCreatePath, 'post');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.path('assignment', params.assignment, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CreatedResponse>;
      })
    );
  }

  /**
   * Create AssignmentDates.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_ASSIGNMENT_DATES' or 'FEATURE_ADMIN_ASSIGNMENTS'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postPspAsignmentAssignmentDateCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPspAsignmentAssignmentDateCreate(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * Assignment Id.
     */
    assignment: number;
    context?: HttpContext
    body: CreateAssignmentDateRequest
  }
): Observable<CreatedResponse> {

    return this.postPspAsignmentAssignmentDateCreate$Response(params).pipe(
      map((r: StrictHttpResponse<CreatedResponse>) => r.body as CreatedResponse)
    );
  }

  /**
   * Path part for operation putPspAssignmentDateTimeReplace
   */
  static readonly PutPspAssignmentDateTimeReplacePath = '/api/web/v1/psps/{psp}/assignments/{assignment}/assignment-dates/{assignmentDate}/work-times';

  /**
   * Update assignment date work time.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_ASSIGNMENTS'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putPspAssignmentDateTimeReplace()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putPspAssignmentDateTimeReplace$Response(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * Assignment Id.
     */
    assignment: number;

    /**
     * AssignmentDate Id.
     */
    assignmentDate: number;
    context?: HttpContext
    body: UpdateWorkTimesRequest
  }
): Observable<StrictHttpResponse<CreatedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PspAssignmentService.PutPspAssignmentDateTimeReplacePath, 'put');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.path('assignment', params.assignment, {});
      rb.path('assignmentDate', params.assignmentDate, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CreatedResponse>;
      })
    );
  }

  /**
   * Update assignment date work time.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_ASSIGNMENTS'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putPspAssignmentDateTimeReplace$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putPspAssignmentDateTimeReplace(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * Assignment Id.
     */
    assignment: number;

    /**
     * AssignmentDate Id.
     */
    assignmentDate: number;
    context?: HttpContext
    body: UpdateWorkTimesRequest
  }
): Observable<CreatedResponse> {

    return this.putPspAssignmentDateTimeReplace$Response(params).pipe(
      map((r: StrictHttpResponse<CreatedResponse>) => r.body as CreatedResponse)
    );
  }

  /**
   * Path part for operation postPspAsignmentCancelAssignment
   */
  static readonly PostPspAsignmentCancelAssignmentPath = '/api/web/v1/psps/{psp}/assignments/{assignment}/cancelation';

  /**
   * Cancel an Assignment during the cancellation period.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_ASSIGNMENTS'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postPspAsignmentCancelAssignment()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPspAsignmentCancelAssignment$Response(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * Assignment Id.
     */
    assignment: number;
    context?: HttpContext
    body: CancelationRequest
  }
): Observable<StrictHttpResponse<EmptySuccessfulResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PspAssignmentService.PostPspAsignmentCancelAssignmentPath, 'post');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.path('assignment', params.assignment, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmptySuccessfulResponse>;
      })
    );
  }

  /**
   * Cancel an Assignment during the cancellation period.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_ASSIGNMENTS'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postPspAsignmentCancelAssignment$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPspAsignmentCancelAssignment(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * Assignment Id.
     */
    assignment: number;
    context?: HttpContext
    body: CancelationRequest
  }
): Observable<EmptySuccessfulResponse> {

    return this.postPspAsignmentCancelAssignment$Response(params).pipe(
      map((r: StrictHttpResponse<EmptySuccessfulResponse>) => r.body as EmptySuccessfulResponse)
    );
  }

  /**
   * Path part for operation getPspAsignmentContractDownload
   */
  static readonly GetPspAsignmentContractDownloadPath = '/api/web/v1/psps/{psp}/assignments/{assignment}/contracts/{contract}/file';

  /**
   * Download contract PDF.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_ASSIGNMENTS'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPspAsignmentContractDownload()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPspAsignmentContractDownload$Response(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * Assignment Id.
     */
    assignment: number;

    /**
     * Contract Id.
     */
    contract: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, PspAssignmentService.GetPspAsignmentContractDownloadPath, 'get');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.path('assignment', params.assignment, {});
      rb.path('contract', params.contract, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'multipart/form-data',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Download contract PDF.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_ASSIGNMENTS'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPspAsignmentContractDownload$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPspAsignmentContractDownload(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * Assignment Id.
     */
    assignment: number;

    /**
     * Contract Id.
     */
    contract: number;
    context?: HttpContext
  }
): Observable<Blob> {

    return this.getPspAsignmentContractDownload$Response(params).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Path part for operation postPspAsignmentContractRegeneration
   */
  static readonly PostPspAsignmentContractRegenerationPath = '/api/web/v1/psps/{psp}/assignments/{assignment}/contracts/regeneration';

  /**
   * Regenerate contract.
   *
   * **Accessible only for users with permissions 'ROLE_PSP_ADMIN', 'FEATURE_SIGN_CONTRACT'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postPspAsignmentContractRegeneration()` instead.
   *
   * This method doesn't expect any request body.
   */
  postPspAsignmentContractRegeneration$Response(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * Assignment Id.
     */
    assignment: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EmptySuccessfulResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PspAssignmentService.PostPspAsignmentContractRegenerationPath, 'post');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.path('assignment', params.assignment, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmptySuccessfulResponse>;
      })
    );
  }

  /**
   * Regenerate contract.
   *
   * **Accessible only for users with permissions 'ROLE_PSP_ADMIN', 'FEATURE_SIGN_CONTRACT'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postPspAsignmentContractRegeneration$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  postPspAsignmentContractRegeneration(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * Assignment Id.
     */
    assignment: number;
    context?: HttpContext
  }
): Observable<EmptySuccessfulResponse> {

    return this.postPspAsignmentContractRegeneration$Response(params).pipe(
      map((r: StrictHttpResponse<EmptySuccessfulResponse>) => r.body as EmptySuccessfulResponse)
    );
  }

  /**
   * Path part for operation getPspAssignmentListExport
   */
  static readonly GetPspAssignmentListExportPath = '/api/web/v1/psps/{psp}/assignments/export';

  /**
   * Export list of PSP assignments as CSV file.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_ASSIGNMENTS'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPspAssignmentListExport()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPspAssignmentListExport$Response(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * Search assignment by profile.firstName, profile.lastName, profile.personalId.
     */
    search?: string;

    /**
     * Filter assignments by Main Qualifications ids.
     */
    'mainQualifications[]'?: any;

    /**
     * Filter assignments by Customer ids.
     */
    'customers[]'?: any;

    /**
     * Filter assignments by PSP Branches ids.
     */
    'pspBranches[]'?: any;

    /**
     * From date, including. If passed then startDate &gt;&#x3D; fromDate
     */
    fromDate?: string;

    /**
     * Filter assignments by statuses: planned,active,ended
     */
    'statuses[]'?: any;

    /**
     * Filter assignments by open contracts: psp,customer,signed
     */
    'openContracts[]'?: any;

    /**
     * Filter assignments by open assignment times.
     */
    openAssignmentTimes?: boolean;

    /**
     * Filter assignments by ids
     */
    'assignments[]'?: any;

    /**
     * Order assignments key.
     */
    orderKey?: 'startDate' | 'endDate' | 'createdAt' | 'profileLastName' | 'customerName' | 'mainQualificationName' | 'pspBranchName';

    /**
     * Order direction.
     */
    orderDirection?: 'ASC' | 'DESC';
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, PspAssignmentService.GetPspAssignmentListExportPath, 'get');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.query('search', params.search, {});
      rb.query('mainQualifications[]', params['mainQualifications[]'], {});
      rb.query('customers[]', params['customers[]'], {});
      rb.query('pspBranches[]', params['pspBranches[]'], {});
      rb.query('fromDate', params.fromDate, {});
      rb.query('statuses[]', params['statuses[]'], {});
      rb.query('openContracts[]', params['openContracts[]'], {});
      rb.query('openAssignmentTimes', params.openAssignmentTimes, {});
      rb.query('assignments[]', params['assignments[]'], {});
      rb.query('orderKey', params.orderKey, {});
      rb.query('orderDirection', params.orderDirection, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'text/csv',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Export list of PSP assignments as CSV file.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_ASSIGNMENTS'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPspAssignmentListExport$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPspAssignmentListExport(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * Search assignment by profile.firstName, profile.lastName, profile.personalId.
     */
    search?: string;

    /**
     * Filter assignments by Main Qualifications ids.
     */
    'mainQualifications[]'?: any;

    /**
     * Filter assignments by Customer ids.
     */
    'customers[]'?: any;

    /**
     * Filter assignments by PSP Branches ids.
     */
    'pspBranches[]'?: any;

    /**
     * From date, including. If passed then startDate &gt;&#x3D; fromDate
     */
    fromDate?: string;

    /**
     * Filter assignments by statuses: planned,active,ended
     */
    'statuses[]'?: any;

    /**
     * Filter assignments by open contracts: psp,customer,signed
     */
    'openContracts[]'?: any;

    /**
     * Filter assignments by open assignment times.
     */
    openAssignmentTimes?: boolean;

    /**
     * Filter assignments by ids
     */
    'assignments[]'?: any;

    /**
     * Order assignments key.
     */
    orderKey?: 'startDate' | 'endDate' | 'createdAt' | 'profileLastName' | 'customerName' | 'mainQualificationName' | 'pspBranchName';

    /**
     * Order direction.
     */
    orderDirection?: 'ASC' | 'DESC';
    context?: HttpContext
  }
): Observable<Blob> {

    return this.getPspAssignmentListExport$Response(params).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Path part for operation getPspAssignmentListAll
   */
  static readonly GetPspAssignmentListAllPath = '/api/web/v1/psps/{psp}/assignments';

  /**
   * Get list of PSP assignments.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_ASSIGNMENTS'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPspAssignmentListAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPspAssignmentListAll$Response(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * Page number in pagination.
     */
    page?: any;

    /**
     * Size number in pagination.
     */
    size?: any;

    /**
     * Search assignment by profile.firstName, profile.lastName, profile.personalId.
     */
    search?: string;

    /**
     * Filter assignments by Main Qualification ids.
     */
    'mainQualifications[]'?: any;

    /**
     * Filter assignments by Customer ids.
     */
    'customers[]'?: any;

    /**
     * Filter assignments by PSP Branch ids.
     */
    'pspBranches[]'?: any;

    /**
     * From date, including. If passed then startDate &gt;&#x3D; fromDate
     */
    fromDate?: string;

    /**
     * Filter assignments by statuses: planned,active,ended
     */
    'statuses[]'?: any;

    /**
     * Filter assignments by open contracts: psp,customer,signed
     */
    'openContracts[]'?: any;

    /**
     * Filter assignments by open assignment times.
     */
    openAssignmentTimes?: boolean;

    /**
     * Order assignments key.
     */
    orderKey?: 'startDate' | 'endDate' | 'createdAt' | 'profileLastName' | 'customerName' | 'mainQualificationName' | 'pspBranchName';

    /**
     * Order direction.
     */
    orderDirection?: 'ASC' | 'DESC';
    context?: HttpContext
  }
): Observable<StrictHttpResponse<PaginatedApiResponseWithFilters & {
'data'?: Array<PspAssignmentListItemResponse>;
'filters'?: PspAssignmentFilterListsResponse;
}>> {

    const rb = new RequestBuilder(this.rootUrl, PspAssignmentService.GetPspAssignmentListAllPath, 'get');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.query('page', params.page, {});
      rb.query('size', params.size, {});
      rb.query('search', params.search, {});
      rb.query('mainQualifications[]', params['mainQualifications[]'], {});
      rb.query('customers[]', params['customers[]'], {});
      rb.query('pspBranches[]', params['pspBranches[]'], {});
      rb.query('fromDate', params.fromDate, {});
      rb.query('statuses[]', params['statuses[]'], {});
      rb.query('openContracts[]', params['openContracts[]'], {});
      rb.query('openAssignmentTimes', params.openAssignmentTimes, {});
      rb.query('orderKey', params.orderKey, {});
      rb.query('orderDirection', params.orderDirection, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaginatedApiResponseWithFilters & {
        'data'?: Array<PspAssignmentListItemResponse>;
        'filters'?: PspAssignmentFilterListsResponse;
        }>;
      })
    );
  }

  /**
   * Get list of PSP assignments.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_ASSIGNMENTS'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPspAssignmentListAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPspAssignmentListAll(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * Page number in pagination.
     */
    page?: any;

    /**
     * Size number in pagination.
     */
    size?: any;

    /**
     * Search assignment by profile.firstName, profile.lastName, profile.personalId.
     */
    search?: string;

    /**
     * Filter assignments by Main Qualification ids.
     */
    'mainQualifications[]'?: any;

    /**
     * Filter assignments by Customer ids.
     */
    'customers[]'?: any;

    /**
     * Filter assignments by PSP Branch ids.
     */
    'pspBranches[]'?: any;

    /**
     * From date, including. If passed then startDate &gt;&#x3D; fromDate
     */
    fromDate?: string;

    /**
     * Filter assignments by statuses: planned,active,ended
     */
    'statuses[]'?: any;

    /**
     * Filter assignments by open contracts: psp,customer,signed
     */
    'openContracts[]'?: any;

    /**
     * Filter assignments by open assignment times.
     */
    openAssignmentTimes?: boolean;

    /**
     * Order assignments key.
     */
    orderKey?: 'startDate' | 'endDate' | 'createdAt' | 'profileLastName' | 'customerName' | 'mainQualificationName' | 'pspBranchName';

    /**
     * Order direction.
     */
    orderDirection?: 'ASC' | 'DESC';
    context?: HttpContext
  }
): Observable<PaginatedApiResponseWithFilters & {
'data'?: Array<PspAssignmentListItemResponse>;
'filters'?: PspAssignmentFilterListsResponse;
}> {

    return this.getPspAssignmentListAll$Response(params).pipe(
      map((r: StrictHttpResponse<PaginatedApiResponseWithFilters & {
'data'?: Array<PspAssignmentListItemResponse>;
'filters'?: PspAssignmentFilterListsResponse;
}>) => r.body as PaginatedApiResponseWithFilters & {
'data'?: Array<PspAssignmentListItemResponse>;
'filters'?: PspAssignmentFilterListsResponse;
})
    );
  }

  /**
   * Path part for operation getPspAsignmentListUnplanned
   */
  static readonly GetPspAsignmentListUnplannedPath = '/api/web/v1/psps/{psp}/assignments/unplanned-dates';

  /**
   * Get list of unplanned PSP assignments.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_ASSIGNMENT_DATES' or 'FEATURE_ADMIN_ASSIGNMENTS'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPspAsignmentListUnplanned()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPspAsignmentListUnplanned$Response(params: {

    /**
     * PSP Id.
     */
    psp: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ListApiResponse & {
'data'?: Array<AssignmentUnplannedListItemResponse>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, PspAssignmentService.GetPspAsignmentListUnplannedPath, 'get');
    if (params) {
      rb.path('psp', params.psp, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListApiResponse & {
        'data'?: Array<AssignmentUnplannedListItemResponse>;
        }>;
      })
    );
  }

  /**
   * Get list of unplanned PSP assignments.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_ASSIGNMENT_DATES' or 'FEATURE_ADMIN_ASSIGNMENTS'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPspAsignmentListUnplanned$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPspAsignmentListUnplanned(params: {

    /**
     * PSP Id.
     */
    psp: number;
    context?: HttpContext
  }
): Observable<ListApiResponse & {
'data'?: Array<AssignmentUnplannedListItemResponse>;
}> {

    return this.getPspAsignmentListUnplanned$Response(params).pipe(
      map((r: StrictHttpResponse<ListApiResponse & {
'data'?: Array<AssignmentUnplannedListItemResponse>;
}>) => r.body as ListApiResponse & {
'data'?: Array<AssignmentUnplannedListItemResponse>;
})
    );
  }

  /**
   * Path part for operation getPspAsignmentAssignment
   */
  static readonly GetPspAsignmentAssignmentPath = '/api/web/v1/psps/{psp}/assignments/{assignment}';

  /**
   * Get the assignment data.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_ASSIGNMENTS'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPspAsignmentAssignment()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPspAsignmentAssignment$Response(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * Assignment Id.
     */
    assignment: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<GeneralApiResponse & {
'data'?: AssignmentPspResponse;
}>> {

    const rb = new RequestBuilder(this.rootUrl, PspAssignmentService.GetPspAsignmentAssignmentPath, 'get');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.path('assignment', params.assignment, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GeneralApiResponse & {
        'data'?: AssignmentPspResponse;
        }>;
      })
    );
  }

  /**
   * Get the assignment data.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_ASSIGNMENTS'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPspAsignmentAssignment$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPspAsignmentAssignment(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * Assignment Id.
     */
    assignment: number;
    context?: HttpContext
  }
): Observable<GeneralApiResponse & {
'data'?: AssignmentPspResponse;
}> {

    return this.getPspAsignmentAssignment$Response(params).pipe(
      map((r: StrictHttpResponse<GeneralApiResponse & {
'data'?: AssignmentPspResponse;
}>) => r.body as GeneralApiResponse & {
'data'?: AssignmentPspResponse;
})
    );
  }

  /**
   * Path part for operation postPspAssignmentDateAccept
   */
  static readonly PostPspAssignmentDateAcceptPath = '/api/web/v1/psps/{psp}/assignment-dates/acceptance';

  /**
   * Accept assignment dates.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_ASSIGNMENTS'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postPspAssignmentDateAccept()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPspAssignmentDateAccept$Response(params: {

    /**
     * PSP Id.
     */
    psp: number;
    context?: HttpContext
    body: PspAcceptRequest
  }
): Observable<StrictHttpResponse<EmptySuccessfulResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PspAssignmentService.PostPspAssignmentDateAcceptPath, 'post');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmptySuccessfulResponse>;
      })
    );
  }

  /**
   * Accept assignment dates.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_ASSIGNMENTS'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postPspAssignmentDateAccept$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postPspAssignmentDateAccept(params: {

    /**
     * PSP Id.
     */
    psp: number;
    context?: HttpContext
    body: PspAcceptRequest
  }
): Observable<EmptySuccessfulResponse> {

    return this.postPspAssignmentDateAccept$Response(params).pipe(
      map((r: StrictHttpResponse<EmptySuccessfulResponse>) => r.body as EmptySuccessfulResponse)
    );
  }

  /**
   * Path part for operation getPspAssignmentDateExport
   */
  static readonly GetPspAssignmentDateExportPath = '/api/web/v1/psps/{psp}/assignment-dates/export';

  /**
   * Export psp Assignment dates.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_ASSIGNMENTS'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPspAssignmentDateExport()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPspAssignmentDateExport$Response(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * Filter by assignment date ids.
     */
    'assignmentDates[]'?: any;

    /**
     * Filter Assignments Dates by statuses.
     */
    'statuses[]'?: Array<'open' | 'submitted' | 'accepted' | 'canceled' | 'corrected_by_customer' | 'corrected_by_psp' | 'deleted' | 'automatically_accepted' | 'incomplete' | 'verification_needed'>;

    /**
     * Filter AssignmentDates from date.
     */
    dateFrom?: string;

    /**
     * Filter AssignmentDates to date.
     */
    dateTo?: string;

    /**
     * Filter AssignmentDates by PSP Branch ids.
     */
    'pspBranches[]'?: any;

    /**
     * Filter AssignmentDates by Profile ids.
     */
    'profiles[]'?: any;

    /**
     * Filter AssignmentDates by Customer department ids.
     */
    'customerDepartments[]'?: any;

    /**
     * Filter AssignmentDates by Customer location ids.
     */
    'customerLocations[]'?: any;

    /**
     * Search assignment date by profile.firstName, profile.lastName, profile.personalId
     *      *         or profile.terminalCards.id.
     */
    search?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, PspAssignmentService.GetPspAssignmentDateExportPath, 'get');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.query('assignmentDates[]', params['assignmentDates[]'], {});
      rb.query('statuses[]', params['statuses[]'], {"explode":false});
      rb.query('dateFrom', params.dateFrom, {});
      rb.query('dateTo', params.dateTo, {});
      rb.query('pspBranches[]', params['pspBranches[]'], {});
      rb.query('profiles[]', params['profiles[]'], {});
      rb.query('customerDepartments[]', params['customerDepartments[]'], {});
      rb.query('customerLocations[]', params['customerLocations[]'], {});
      rb.query('search', params.search, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/csv',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Export psp Assignment dates.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_ASSIGNMENTS'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPspAssignmentDateExport$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPspAssignmentDateExport(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * Filter by assignment date ids.
     */
    'assignmentDates[]'?: any;

    /**
     * Filter Assignments Dates by statuses.
     */
    'statuses[]'?: Array<'open' | 'submitted' | 'accepted' | 'canceled' | 'corrected_by_customer' | 'corrected_by_psp' | 'deleted' | 'automatically_accepted' | 'incomplete' | 'verification_needed'>;

    /**
     * Filter AssignmentDates from date.
     */
    dateFrom?: string;

    /**
     * Filter AssignmentDates to date.
     */
    dateTo?: string;

    /**
     * Filter AssignmentDates by PSP Branch ids.
     */
    'pspBranches[]'?: any;

    /**
     * Filter AssignmentDates by Profile ids.
     */
    'profiles[]'?: any;

    /**
     * Filter AssignmentDates by Customer department ids.
     */
    'customerDepartments[]'?: any;

    /**
     * Filter AssignmentDates by Customer location ids.
     */
    'customerLocations[]'?: any;

    /**
     * Search assignment date by profile.firstName, profile.lastName, profile.personalId
     *      *         or profile.terminalCards.id.
     */
    search?: string;
    context?: HttpContext
  }
): Observable<Blob> {

    return this.getPspAssignmentDateExport$Response(params).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Path part for operation getPspAssignmentDateList
   */
  static readonly GetPspAssignmentDateListPath = '/api/web/v1/psps/{psp}/assignment-dates';

  /**
   * Get a list of all psp Assignment dates.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_ASSIGNMENTS'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPspAssignmentDateList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPspAssignmentDateList$Response(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * Page number in pagination.
     */
    page?: any;

    /**
     * Size number in pagination.
     */
    size?: any;

    /**
     * Filter by assignment id.
     */
    assignmentId?: any;

    /**
     * Filter Assignments Dates by statuses.
     */
    'statuses[]'?: Array<'open' | 'submitted' | 'accepted' | 'canceled' | 'corrected_by_customer' | 'corrected_by_psp' | 'deleted' | 'automatically_accepted' | 'incomplete' | 'verification_needed'>;

    /**
     * Filter AssignmentDates from date.
     */
    dateFrom?: string;

    /**
     * Filter AssignmentDates to date.
     */
    dateTo?: string;

    /**
     * Filter AssignmentDates by PSP Branch ids.
     */
    'pspBranches[]'?: any;

    /**
     * Filter AssignmentDates by Profile ids.
     */
    'profiles[]'?: any;

    /**
     * Filter AssignmentDates by Customer department ids.
     */
    'customerDepartments[]'?: any;

    /**
     * Filter AssignmentDates by Customer location ids.
     */
    'customerLocations[]'?: any;

    /**
     * Search assignment date by profile.firstName, profile.lastName, profile.personalId
     *      *         or profile.terminalCards.id.
     */
    search?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<PaginatedApiResponseWithFilters & {
'data'?: Array<PspAssignmentDateListItemResponse>;
'filters'?: OrganizationFilterListResponse;
}>> {

    const rb = new RequestBuilder(this.rootUrl, PspAssignmentService.GetPspAssignmentDateListPath, 'get');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.query('page', params.page, {});
      rb.query('size', params.size, {});
      rb.query('assignmentId', params.assignmentId, {});
      rb.query('statuses[]', params['statuses[]'], {"explode":false});
      rb.query('dateFrom', params.dateFrom, {});
      rb.query('dateTo', params.dateTo, {});
      rb.query('pspBranches[]', params['pspBranches[]'], {});
      rb.query('profiles[]', params['profiles[]'], {});
      rb.query('customerDepartments[]', params['customerDepartments[]'], {});
      rb.query('customerLocations[]', params['customerLocations[]'], {});
      rb.query('search', params.search, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaginatedApiResponseWithFilters & {
        'data'?: Array<PspAssignmentDateListItemResponse>;
        'filters'?: OrganizationFilterListResponse;
        }>;
      })
    );
  }

  /**
   * Get a list of all psp Assignment dates.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_ASSIGNMENTS'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPspAssignmentDateList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPspAssignmentDateList(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * Page number in pagination.
     */
    page?: any;

    /**
     * Size number in pagination.
     */
    size?: any;

    /**
     * Filter by assignment id.
     */
    assignmentId?: any;

    /**
     * Filter Assignments Dates by statuses.
     */
    'statuses[]'?: Array<'open' | 'submitted' | 'accepted' | 'canceled' | 'corrected_by_customer' | 'corrected_by_psp' | 'deleted' | 'automatically_accepted' | 'incomplete' | 'verification_needed'>;

    /**
     * Filter AssignmentDates from date.
     */
    dateFrom?: string;

    /**
     * Filter AssignmentDates to date.
     */
    dateTo?: string;

    /**
     * Filter AssignmentDates by PSP Branch ids.
     */
    'pspBranches[]'?: any;

    /**
     * Filter AssignmentDates by Profile ids.
     */
    'profiles[]'?: any;

    /**
     * Filter AssignmentDates by Customer department ids.
     */
    'customerDepartments[]'?: any;

    /**
     * Filter AssignmentDates by Customer location ids.
     */
    'customerLocations[]'?: any;

    /**
     * Search assignment date by profile.firstName, profile.lastName, profile.personalId
     *      *         or profile.terminalCards.id.
     */
    search?: string;
    context?: HttpContext
  }
): Observable<PaginatedApiResponseWithFilters & {
'data'?: Array<PspAssignmentDateListItemResponse>;
'filters'?: OrganizationFilterListResponse;
}> {

    return this.getPspAssignmentDateList$Response(params).pipe(
      map((r: StrictHttpResponse<PaginatedApiResponseWithFilters & {
'data'?: Array<PspAssignmentDateListItemResponse>;
'filters'?: OrganizationFilterListResponse;
}>) => r.body as PaginatedApiResponseWithFilters & {
'data'?: Array<PspAssignmentDateListItemResponse>;
'filters'?: OrganizationFilterListResponse;
})
    );
  }

  /**
   * Path part for operation getPspProfileEndedAssignmentList
   */
  static readonly GetPspProfileEndedAssignmentListPath = '/api/web/v1/psps/{psp}/profiles/{profile}/assignments/ended';

  /**
   * Get List of Ended Profile Assignments for PSP.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_ASSIGNMENTS'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPspProfileEndedAssignmentList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPspProfileEndedAssignmentList$Response(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * Profile Id.
     */
    profile: number;

    /**
     * Page number in pagination.
     */
    page?: any;

    /**
     * Size number in pagination.
     */
    size?: any;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<PaginatedApiResponse & {
'data'?: Array<PspProfileAssignmentItemResponse>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, PspAssignmentService.GetPspProfileEndedAssignmentListPath, 'get');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.path('profile', params.profile, {});
      rb.query('page', params.page, {});
      rb.query('size', params.size, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaginatedApiResponse & {
        'data'?: Array<PspProfileAssignmentItemResponse>;
        }>;
      })
    );
  }

  /**
   * Get List of Ended Profile Assignments for PSP.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_ASSIGNMENTS'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPspProfileEndedAssignmentList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPspProfileEndedAssignmentList(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * Profile Id.
     */
    profile: number;

    /**
     * Page number in pagination.
     */
    page?: any;

    /**
     * Size number in pagination.
     */
    size?: any;
    context?: HttpContext
  }
): Observable<PaginatedApiResponse & {
'data'?: Array<PspProfileAssignmentItemResponse>;
}> {

    return this.getPspProfileEndedAssignmentList$Response(params).pipe(
      map((r: StrictHttpResponse<PaginatedApiResponse & {
'data'?: Array<PspProfileAssignmentItemResponse>;
}>) => r.body as PaginatedApiResponse & {
'data'?: Array<PspProfileAssignmentItemResponse>;
})
    );
  }

}
