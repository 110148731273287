<header class="header pe-5 d-flex flex-column flex-lg-row justify-content-between align-items-center position-relative">
  <div
    class="header-logo position-relative d-flex flex-wrap align-items-center justify-content-center px-6 overflow-hidden"
  >
    <i
      *ngIf="!isErrorPage && showSideMenu"
      class="d-xl-none pointer icon-lg fa-solid fa-fw fa-bars mt-2 me-3"
      (click)="openOffcanvasSidebar(offcanvasSidebar)"
    ></i>
    <img
      class="img-fluid pointer"
      [src]="logo"
      alt="Logo"
      routerLink="/dashboard"
      [replaceUrl]="true"
    />
    <div class="header-dot mx-4 mt-1"></div>
    <div class="header-title mt-1 overflow-hidden">
      <base-page-title></base-page-title>
    </div>
  </div>

  <div
    class="header-right d-flex justify-content-between align-items-center mt-4 mt-lg-0"
    *ngIf="showSideMenu"
  >
    <div class="header-user-nav d-flex justify-content-between align-items-center">
      <ng-content></ng-content>
    </div>
  </div>
</header>

<ng-template
  #offcanvasSidebar
  let-offcanvas
>
  <div class="offcanvas-body">
    <div class="h-100 d-flex flex-column justify-content-between">
      <base-vertical-nav
        [tabs]="tabs"
        (currentChange)="offcanvas.dismiss()"
      ></base-vertical-nav>
      <base-legal-document-links [isOffcanvasSidebar]="true"></base-legal-document-links>
    </div>
  </div>
</ng-template>
