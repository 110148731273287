import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import {
  CustomerBranchListItemResponse,
  CatalogService,
  Category,
  CustomerDepartmentQualificationsListResponse,
  CustomerDepartmentService,
  CustomerLocationDepartmentService,
  CustomerPspService,
  DepartmentListItemResponse,
  DocumentCategoryResponse,
  EmptySuccessfulResponse,
  NotificationRecipientsUpdateRequest,
  NotificationsListResponse,
  PaginatedApiResponse,
  PspBranchService,
  PspCustomerService,
  PspCustomerSurchargeResponse,
  PspService,
  QualificationPricingListItemResponse,
  QualificationsListItemResponse,
  RequirementCategoryResponse,
  ShiftTimesResponse,
  UserItemResponse,
  UserProfileResponse,
  UserProfileUpdateRequest,
  UserService,
  ListApiResponse,
  UserProfileSettingsUpdateRequest,
  IndustrySurcharge,
} from '../../../api';
import { BaseApplicationTagType } from '../enums/base-application.enum';
import {
  BASE_STAZZLE_PRIVACY_POLICY_FILE_PATHS,
  BASE_STAZZLE_TERMS_OF_USE_FILE_PATHS,
} from '../constants/base-stazzle-docs-paths';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class BaseSharedService {
  constructor(
    private readonly catalogService: CatalogService,
    private readonly pspBranchService: PspBranchService,
    private readonly userService: UserService,
    private readonly pspService: PspService,
    private readonly customerDepartmentService: CustomerDepartmentService,
    private readonly customerLocationDepartmentService: CustomerLocationDepartmentService,
    private customerPspService: CustomerPspService,
    private pspCustomerService: PspCustomerService,
    private translateService: TranslateService
  ) {}

  loadQualifications(application: BaseApplicationTagType): Observable<QualificationsListItemResponse[]> {
    return this.catalogService
      .getAppApiWebV1CatalogsQualificationListIndex({ tag: application })
      .pipe(map((response) => response.data.qualifications));
  }

  loadQualificationsCategories(application: BaseApplicationTagType): Observable<Array<Category>> {
    return this.catalogService.getAppApiWebV1CatalogsQualificationCategoryListIndex({ tag: application }).pipe(
      take(1),
      map((response) => response.data.categories)
    );
  }

  loadRequirements(application: BaseApplicationTagType): Observable<Array<RequirementCategoryResponse>> {
    return this.catalogService.getAppApiWebV1CatalogsRequirementListIndex({ tag: application }).pipe(
      take(1),
      map((response) => response.data.categories)
    );
  }

  getPspBranches(orgId: number, active?: any): Observable<Array<CustomerBranchListItemResponse>> {
    return this.pspBranchService
      .getPspBranchListOfBranches({ psp: orgId, active })
      .pipe(map((response) => response.data));
  }

  getCustomerBranches(customer: number): Observable<Array<CustomerBranchListItemResponse>> {
    return this.customerPspService.getCustomerListOfBranches({ customer }).pipe(map((response) => response.data));
  }

  getProfile(): Observable<UserProfileResponse | undefined> {
    return this.userService.getAppApiWebV1UserUserprofileShow().pipe(
      take(1),
      map((response: { message?: string; data?: UserProfileResponse }) => response.data)
    );
  }

  updateProfile(body: UserProfileUpdateRequest): Observable<EmptySuccessfulResponse> {
    return this.userService.putAppApiWebV1UserUserprofileUpdate({ body }).pipe(take(1));
  }

  getCustomerDepartmentList(customerId: number | string, active?: boolean): Observable<DepartmentListItemResponse[]> {
    return this.customerDepartmentService
      .getCustomerDepartmentListOfDepartments({ customer: customerId as number, active })
      .pipe(map((response) => response.data));
  }

  getCustomerDepartmentUsers(customer: number, department: number): Observable<Array<UserItemResponse>> {
    return this.customerDepartmentService
      .getCustomersDepartmentUsersList({ customer, department })
      .pipe(map((response) => response.data));
  }

  getCustomerDepartmentQualificationsList(
    customer: number,
    department: number,
    category?: number,
    tag?: BaseApplicationTagType
  ): Observable<Array<CustomerDepartmentQualificationsListResponse>> {
    return this.customerDepartmentService
      .getCustomerDepartmentQualificationsList({ customer, department, category, tag })
      .pipe(map((response) => response.data));
  }

  getDocumentCategories(): Observable<DocumentCategoryResponse> {
    return this.catalogService.getDocumentsCategories().pipe(map((response) => response.data));
  }

  getPspPriceList(params: {
    tag?: BaseApplicationTagType;
    psp: number;
  }): Observable<QualificationPricingListItemResponse[]> {
    return this.pspService
      .getAppApiWebV1PspPriceListIndex(params)
      .pipe(map((response) => response.data.qualifications));
  }

  prepareUserProfileUpdateData<T>(profile: any): T {
    profile = JSON.parse(JSON.stringify(profile));

    profile.avatar = profile.avatar ? { data: profile.avatar } : undefined;

    if (profile.password && profile.newPassword && profile.newPasswordRepeat) {
      profile.password = {
        password: profile.password,
        newPassword: profile.newPassword,
        newPasswordRepeat: profile.newPasswordRepeat,
      };

      delete profile.newPassword;
      delete profile.newPasswordRepeat;
    } else {
      delete profile.password;
    }

    return profile;
  }

  getDefaultShiftsTimes(): Observable<ShiftTimesResponse> {
    return this.catalogService.getApiCatalogsShiftTimes().pipe(map((response) => response.data));
  }

  pspCustomerSurchargesList(params: {
    psp: number;
    customer: number;
    location: number;
  }): Observable<PspCustomerSurchargeResponse> {
    return this.pspCustomerService.getPspCustomerSurcharges(params).pipe(map((response) => response.data));
  }

  loadUserNotifications(params?: {
    page?: any;
    size?: any;
    isRead?: boolean;
  }): Observable<PaginatedApiResponse & { data?: NotificationsListResponse[] }> {
    return this.userService.getNotificationsGetList(params);
  }

  setNotificationReadState(notifications: number[]): Observable<EmptySuccessfulResponse> {
    const requestParams: { body: NotificationRecipientsUpdateRequest } = {
      body: { notificationMessageIds: notifications },
    };
    return this.userService.putNotificationsChangeReadStatus(requestParams);
  }

  setAllUserNotificationsReadStatus(): Observable<EmptySuccessfulResponse> {
    return this.userService.postNotificationsChangeAllReadStatus();
  }

  customerDepartmentFavouriteQualificationList(params: {
    customer: number;
    location: number;
    department: number;
  }): Observable<QualificationsListItemResponse[]> {
    return this.customerLocationDepartmentService
      .getCustomerLocationDepartmentQualificationListFavoriteQualifications(params)
      .pipe(map((response: any) => response.data.qualificationFavorites));
  }

  getPspBranchUsers(
    psp: number,
    branch: number
  ): Observable<ListApiResponse & { data?: Array<UserItemResponse & { fullName: string }> }> {
    return this.pspBranchService.getPspsBranchUsersList({ psp, branch, active: true }).pipe(
      map((res) => {
        if (res && res?.data && res.data.length > 0) {
          return {
            ...res,
            data: res.data
              ? (res.data || []).map((user: any) => {
                  user.fullName = `${user.firstName} ${user.lastName}`;
                  return user;
                })
              : [],
          };
        }

        return res;
      })
    );
  }

  downloadLegalDocument(isPrivacyPolicy?: boolean): void {
    const termsOfUsePath = isPrivacyPolicy
      ? BASE_STAZZLE_PRIVACY_POLICY_FILE_PATHS[this.translateService.currentLang]
      : BASE_STAZZLE_TERMS_OF_USE_FILE_PATHS[this.translateService.currentLang];
    const termsOfUseFileName = this.translateService.instant(
      isPrivacyPolicy ? 'stazzleFileNames.privacyPolicy' : 'stazzleFileNames.termsOfUse'
    );

    this.downloadLegalDocumentFile(termsOfUsePath, termsOfUseFileName);
  }

  updateUserSettings(body: UserProfileSettingsUpdateRequest): Observable<EmptySuccessfulResponse> {
    return this.userService.putAppApiWebV1UserSettingUpdateIndex({ body });
  }

  private downloadLegalDocumentFile(filePath: string, fileName: string): void {
    const link = document.createElement('a');
    link.setAttribute('href', filePath);
    link.setAttribute('download', fileName);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  getIndustrySurchargesList(): Observable<Array<IndustrySurcharge>> {
    return this.catalogService.getCatalogIndustrySurchargesList().pipe(map((response) => response.data));
  }
}
