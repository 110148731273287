import { createAction, props } from '@ngrx/store';
import {
  CustomerBranchListItemResponse,
  Category,
  CustomerDepartmentQualificationsListResponse,
  DepartmentListItemResponse,
  DocumentCategoryResponse,
  NotificationsListResponse,
  PaginatedApiResponse,
  PspCustomerSurchargeResponse,
  QualificationPricingListItemResponse,
  QualificationsListItemResponse,
  RequirementCategoryResponse,
  ShiftTimesResponse,
  UserItemResponse,
  UserProfileResponse,
  UserProfileSettingsUpdateRequest,
  UserProfileUpdateRequest,
  IndustrySurcharge,
} from '../../../api';
import { BaseApplicationTagType } from '../../shared/enums/base-application.enum';
import { BaseToastAlertInfoInterface } from '../../shared/interfaces/base-toast-alert-info.interface';
import { BaseCountry } from '../../shared/services/base-country.service';

// Country List
export const loadCountryList = createAction('[Country List] Load list'); // done
export const setCountryList = createAction('[Country List] Set list', props<{ payload: BaseCountry[] }>()); // done
export const successCountryList = createAction('[Country List] Success'); // done
export const errorCountryList = createAction('[Country List] Error'); // done

// Qualifications
export const getQualifications = createAction(
  '[Candidates] Get qualifications',
  props<{ application: BaseApplicationTagType }>()
); // done
export const setQualifications = createAction(
  '[Candidates] Load qualifications',
  props<{ payload: QualificationsListItemResponse[] }>()
); // done
export const successLoadQualifications = createAction('[Candidates] Success load qualifications'); // done
export const errorLoadQualifications = createAction('[Candidates] Error load qualifications'); // done

// Qualification categories
export const getQualificationCategories = createAction(
  '[Candidates] Get qualification categories',
  props<{ application?: BaseApplicationTagType }>()
); // done
export const loadQualificationCategories = createAction(
  '[Candidates] Load qualification categories',
  props<{ payload: Array<Category> }>()
); // done
export const successLoadQualificationCategories = createAction('[Candidates] Success load qualification categories'); // done
export const errorLoadQualificationCategories = createAction('[Candidates] Error load qualification categories'); // done

// Branches
export const getBranches = createAction('[Candidates] Get branches'); // done
export const loadBranches = createAction(
  '[Candidates] Load branches',
  props<{ payload: CustomerBranchListItemResponse[] }>()
); // done
export const successLoadBranches = createAction('[Candidates] Success load branches'); // done
export const errorLoadBranches = createAction('[Candidates] Error load branches'); // done

// Logged in user
export const loadProfileData = createAction('[Administration: Profile] Load profile data'); // done
export const setProfileData = createAction(
  '[Administration: User data] Store profile data',
  props<{ payload: UserProfileResponse | undefined }>()
); // done
export const successProfileData = createAction('[Administration: Profile] Profile fetched'); // done
export const errorProfileData = createAction('[Administration: Profile] Profile fetch error'); // done
export const updateProfileData = createAction(
  '[Administration: Profile] Update user profile',
  props<{ payload: UserProfileUpdateRequest }>()
); // done
export const updateProfileSettings = createAction(
  '[Administration: Profile] Update user profile settings',
  props<{ payload: UserProfileSettingsUpdateRequest }>()
);

// Requirements
export const getRequirements = createAction(
  '[Shared] Get requirements',
  props<{ application: BaseApplicationTagType }>()
); // done
export const loadRequirements = createAction(
  '[Shared] Load requirements',
  props<{ payload: Array<RequirementCategoryResponse> }>()
); // done
export const successLoadRequirements = createAction('[Shared] Success load requirements'); // done
export const errorLoadRequirements = createAction('[Shared] Error load requirements'); // done

// Departments
export const loadCustomerDepartmentList = createAction(
  '[Shared] Load customer departments list',
  props<{ payload: { active?: boolean } }>()
); // done
export const setCustomerDepartmentList = createAction(
  '[Customer Department] Set list',
  props<{ payload: DepartmentListItemResponse[] }>()
); // done
export const successCustomerDepartmentList = createAction('[Shared] Success list'); // done
export const errorCustomerDepartmentList = createAction('[Shared] Error list'); // done

// Department users
export const getCustomerDepartmentUsers = createAction('[Shared] Get departmment users', props<{ payload: number }>()); // done
export const loadCustomerDepartmentUsers = createAction(
  '[Shared] Load department users',
  props<{ payload: UserItemResponse[] }>()
); // done
export const successCustomerDepartmentUsers = createAction('[Shared] Success load department users'); // done
export const errorCustomerDepartmentUsers = createAction('[Shared] Error load department users'); // done
export const clearCustomerDepartmentUsers = createAction('[Shared] Clear department users');

// Document categories
export const loadDocumentCategories = createAction('[Shared] Load document categories'); // done
export const setDocumentCategories = createAction(
  '[Shared] Set documents categories',
  props<{ payload: DocumentCategoryResponse }>()
); // done
export const successDocumentCategories = createAction('[Shared] Success document categories'); // done
export const errorDocumentCategories = createAction('[Shared] Error document categories'); // done

// Departments qualifications list
export const loadCustomerDepartmentQualificationsList = createAction(
  '[Shared] Load customer departments qualifications list',
  props<{
    payload: {
      department: number;
      category?: number;
      tag?: BaseApplicationTagType;
    };
  }>()
);
export const setCustomerDepartmentQualificationsList = createAction(
  '[Shared] Set customer departments qualifications list',
  props<{ payload: CustomerDepartmentQualificationsListResponse[] }>()
);
export const successCustomerDepartmentQualificationsList = createAction(
  '[Shared] Success customer department qualifications list'
);
export const errorCustomerDepartmentQualificationsList = createAction(
  '[Shared] Error customer department qualifications list'
);

// PSP Price List
export const loadPspPriceList = createAction(
  '[Shared] Load PSP Price List',
  props<{ payload: { tag?: BaseApplicationTagType } }>()
);
export const setPspPriceList = createAction(
  '[Shared] Set PSP Price List',
  props<{ payload: QualificationPricingListItemResponse[] | undefined }>()
);
export const successPspPriceList = createAction('[Shared] Success PSP Price List');
export const errorPspPriceList = createAction('[Shared] Error PSP Price List');

// Fetch default times for shifts
export const fetchDefaultShiftTimes = createAction('[Inquiry] Fetch default shifts times');
export const loadDefaultShiftTimes = createAction(
  '[Inquiry] Load default shifts times',
  props<{ payload: ShiftTimesResponse }>()
);
export const loadDefaultShiftTimesSuccess = createAction('[Inquiry] Load default shift times success');
export const loadDefaultShiftTimesError = createAction('[Inquiry] Load default shift times error');

// PSP Customer Surcharges
export const loadPSPCustomerSurcharges = createAction(
  '[Shared] Load surcharges',
  props<{ payload: { customerId: number; locationId: number } }>()
);
export const setPSPCustomerSurcharges = createAction(
  '[Shared] Set surcharges',
  props<{ payload: PspCustomerSurchargeResponse }>()
);
export const successPSPCustomerSurcharges = createAction('[Shared] Success surcharges');
export const errorPSPCustomerSurcharges = createAction('[Shared] Error surcharges');

export const clearSharedState = createAction('[Shared] Clear state');

// User Notifications
export const loadUserNotifications = createAction(
  '[Shared] Load User Notifications',
  props<{ payload: { page?: any; size?: any; isRead?: boolean } }>()
);
export const setUserNotifications = createAction(
  '[Shared] Set User Notifications',
  props<{ payload: PaginatedApiResponse & { data?: NotificationsListResponse[] } }>()
);

export const setNotificationReadState = createAction(
  '[Shared] Set Notification Read State',
  props<{ payload: { notification: number }; page?: any; size?: number }>()
);

export const setAllUserNotificationsAsRead = createAction('[Shared] Set All User Notifications as Read');

export const successUserNotifications = createAction('[Shared] Success User Notifications');
export const errorUserNotifications = createAction('[Shared] Error User Notifications');

// App Router
export const setCurrentAndPreviousRouteUrls = createAction(
  '[Shared] Set current and previous router urls',
  props<{ payload: { previousUrl: string; currentUrl: string } }>()
);

// Customer Department Favourite Qualifications List
export const loadCustomerDepartmentFavouriteQualificationList = createAction(
  '[Shared] Load Customer Department Favourite Qualifications List',
  props<{ payload: { department: number; location: number } }>()
);
export const setCustomerDepartmentFavouriteQualificationList = createAction(
  '[Shared] Set Customer Department Favourite Qualifications List',
  props<{ payload: QualificationsListItemResponse[] }>()
);
export const successCustomerDepartmentFavouriteQualificationList = createAction(
  '[Shared] Success Customer Department Favourite Qualifications List'
);
export const errorCustomerDepartmentFavouriteQualificationList = createAction(
  '[Shared] Error Customer Department Favourite Qualifications List'
);

// Language
export const setLanguage = createAction('[Shared] Set Language', props<{ language: string }>());
export const setLanguageSuccess = createAction('[Shared] Set Language Success', props<{ language: string }>());

// System Alerts
export const addSystemAlert = createAction(
  '[Shared] Add System Alert',
  props<{ payload: BaseToastAlertInfoInterface }>()
);
export const addUniqueSystemAlert = createAction(
  '[Shared] Add Unique System Alert',
  props<{ payload: BaseToastAlertInfoInterface }>()
);
export const deleteSystemAlert = createAction('[Shared] Delete System Alert', props<{ payload: number }>());

export const setInitState = createAction('[Shared] Set Init State');

export const setOrganisationInactiveFilter = createAction(
  '[Shared] Set filter for inactive locations or departments',
  props<{ filterActive: boolean }>()
);

export const setSelectedLocationId = createAction('[Shared] Set Selected location id', props<{ locationId: number }>());
export const clearSelectedLocationId = createAction('[Shared] Clear Selected location id');

// Branch users
export const loadBranchUsers = createAction('[Shared] Load branch users', props<{ payload: { branch: number } }>());
export const setBranchUsers = createAction(
  '[Shared] Set branch users',
  props<{
    payload: { data: Array<UserItemResponse & { fullName: string }> | undefined; message: string | undefined };
  }>()
);
export const resetBranchUsers = createAction('[Shared] Reset branch users');
export const successBranchUsers = createAction('[Shared] Success branch users');
export const errorBranchUsers = createAction('[Shared] Error branch users');

export const setSidebarInitialOpening = createAction(
  '[Shared] Set inquiry sidebar initial opening',
  props<{ payload: boolean }>()
);

// Industry Surcharges
export const loadIndustrySurcharges = createAction('[Shared] Load Industry Surcharges');
export const setIndustrySurcharges = createAction(
  '[Shared] Set Industry Surcharges',
  props<{ payload: IndustrySurcharge[] }>()
);
export const successIndustrySurcharges = createAction('[Shared] Success Industry Surcharges');
export const errorIndustrySurcharges = createAction('[Shared] Error Industry Surcharges');
