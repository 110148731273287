import { SafeUrl } from '@angular/platform-browser';
import { createAction, props } from '@ngrx/store';
import { DocumentCategoryResponse, DocumentListItemResponse, DocumentRequest, DocumentResponse } from '../../../api';

export const getDocuments = createAction('[Documents] Get documents');
export const loadDocuments = createAction(
  '[Documents] Load documents',
  props<{ payload: { location?: number; page?: number } }>()
);
export const setDocuments = createAction(
  '[Documents] Store documents',
  props<{ payload: Array<DocumentListItemResponse> }>()
);
export const successLoadDocuments = createAction('[Documents] Success get documents');
export const errorLoadDocuments = createAction('[Documents] Error get documents');

export const createDocumentAction = createAction(
  '[Documents] Create document',
  props<{ payload: { file: any; location?: number; body: DocumentRequest } }>()
);

export const getDocumentInfo = createAction(
  '[Documents] Get document info',
  props<{ payload: { document: string; location?: number } }>()
);
export const loadDocumentInfo = createAction('[Documents] Load document info', props<{ payload: DocumentResponse }>());
export const successLoadDocumentInfo = createAction('[Documents] Success load document info');
export const errorLoadDocumentInfo = createAction('[Documents] Error load document info');

export const updateDocument = createAction(
  '[Documents] Update document info',
  props<{ payload: { document: string; location?: number; body: DocumentRequest } }>()
);
export const successUpdateDocument = createAction('[Documents] Success update document info');
export const errorUpdateDocument = createAction('[Documents] Error update document info');

export const changeDocumentFile = createAction(
  '[Documents] Change document file',
  props<{ payload: { document: string; location?: number; file: Blob } }>()
);
export const successChangeDocumentFile = createAction('[Documents] Success change document file');
export const errorChangeDocumentFile = createAction('[Documents] Error change document file');

export const deleteDocument = createAction(
  '[Documents] delete document',
  props<{ payload: { document: string; location?: number } }>()
);
export const successDeleteDocument = createAction('[Documents] Success delete document');
export const errorDeleteDocument = createAction('[Documents] Error dlete document');

export const downloadDocument = createAction(
  '[Documents] Download document',
  props<{ payload: { document: string; documentType: string; location?: number; openInModal?: boolean } }>()
);
export const setDownloadDocument = createAction(
  '[Documents] Set download document',
  props<{ payload: { document: Blob | SafeUrl } }>()
);
export const clearDownloadDocument = createAction('[Documents] Clear download document');
export const successDownloadDocument = createAction('[Documents] Success download document');
export const errorDownloadDocument = createAction('[Documents] Error download document');

export const getDocumentCategories = createAction('[Documents] Get document categories');
export const loadDocumentCategories = createAction(
  '[Documents] Load documents categories',
  props<{ payload: DocumentCategoryResponse }>()
);
export const successDocumentCategories = createAction('[Documents] Success document categories');
export const errorDocumentCategories = createAction('[Documents] Error document categories');
