<div
  *ngIf="!isPreview"
  [ngClass]="componentClasses"
>
  <label
    *ngIf="label"
    (click)="input.click()"
    [ngbTooltip]="labelTooltip"
    [ngClass]="{ required: isRequired }"
    [tooltipClass]="tooltipClass"
    [placement]="tooltipPlacement"
    >{{ label | translate }}</label
  >
  <i
    [ngClass]="icon"
    *ngIf="icon"
  ></i>
  <div [ngClass]="{ 'custom-invalid-icon': invalidIcon }">
    <input
      [type]="type"
      (blur)="onBlur()"
      class="form-control"
      [baseRemoveMailTo]="isEmailField"
      [min]="min"
      [ngClass]="classes"
      [ngStyle]="{ backgroundColor }"
      [placeholder]="placeholder | translate"
      [disabled]="isDisabled"
      [autofocus]="autofocus"
      [tabIndex]="tabIndex"
      [maxlength]="maxlength"
      [step]="step"
      [(ngModel)]="value"
      [autocomplete]="autoCompleteValue"
      #input
    />
    <i
      *ngIf="invalidIcon"
      [ngClass]="invalidIcon"
    ></i>
  </div>
  <p
    class="invalid-feedback"
    *ngIf="this.isInvalid && errorMessage"
  >
    {{ errorMessage | translate }}
  </p>
</div>

<base-form-field-preview
  *ngIf="isPreview"
  [label]="label"
  [value]="value + ' ' + (previewSuffix | translate)"
></base-form-field-preview>
