import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { baseRelatedPspBranchActions, baseRelatedPspBranchesSelectors, BaseCoreState } from '../../../_store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable()
export class BaseRelatedPspBranchDetailsGuard {
  constructor(private store: Store<BaseCoreState>) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    this.store.dispatch(
      baseRelatedPspBranchActions.loadRelatedPspBranchDetails({
        payload: {
          branch: route.params.branchId,
          psp: route.params.pspId,
          location: route.params.locationId,
        },
      })
    );

    return this.store.select(baseRelatedPspBranchesSelectors.getRelatedPspBranchDetails).pipe(
      filter((state) => state.ok !== undefined),
      map((state) => state.ok as boolean)
    );
  }
}
