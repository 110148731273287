/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CountryResponse } from '../models/country-response';
import { DocumentCategoryResponse } from '../models/document-category-response';
import { GeneralApiResponse } from '../models/general-api-response';
import { IndustrySurcharge } from '../models/industry-surcharge';
import { ListApiResponse } from '../models/list-api-response';
import { PermissionItemResponse } from '../models/permission-item-response';
import { QualificationCategoryResponse } from '../models/qualification-category-response';
import { QualificationResponse } from '../models/qualification-response';
import { RequirementCategoryListResponse } from '../models/requirement-category-list-response';
import { ShiftTimesResponse } from '../models/shift-times-response';

@Injectable({
  providedIn: 'root',
})
export class CatalogService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getApiCatalogsIndex
   */
  static readonly GetApiCatalogsIndexPath = '/api/web/v1/catalogs/countries';

  /**
   * Get list of countries.
   *
   * **Accessible only for users with roles 'ROLE_PSP_USER', 'ROLE_CUSTOMER_USER'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getApiCatalogsIndex()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApiCatalogsIndex$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<{
'message': string;
'data': CountryResponse;
}>> {

    const rb = new RequestBuilder(this.rootUrl, CatalogService.GetApiCatalogsIndexPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'message': string;
        'data': CountryResponse;
        }>;
      })
    );
  }

  /**
   * Get list of countries.
   *
   * **Accessible only for users with roles 'ROLE_PSP_USER', 'ROLE_CUSTOMER_USER'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getApiCatalogsIndex$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApiCatalogsIndex(params?: {
    context?: HttpContext
  }
): Observable<{
'message': string;
'data': CountryResponse;
}> {

    return this.getApiCatalogsIndex$Response(params).pipe(
      map((r: StrictHttpResponse<{
'message': string;
'data': CountryResponse;
}>) => r.body as {
'message': string;
'data': CountryResponse;
})
    );
  }

  /**
   * Path part for operation getDocumentsCategories
   */
  static readonly GetDocumentsCategoriesPath = '/api/web/v1/catalogs/documents/categories';

  /**
   * Returns list of categories for a document.
   *
   * **Accessible only for users with roles 'ROLE_PSP_USER', 'ROLE_CUSTOMER_USER'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDocumentsCategories()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumentsCategories$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<{
'message': string;
'data': DocumentCategoryResponse;
}>> {

    const rb = new RequestBuilder(this.rootUrl, CatalogService.GetDocumentsCategoriesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'message': string;
        'data': DocumentCategoryResponse;
        }>;
      })
    );
  }

  /**
   * Returns list of categories for a document.
   *
   * **Accessible only for users with roles 'ROLE_PSP_USER', 'ROLE_CUSTOMER_USER'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDocumentsCategories$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumentsCategories(params?: {
    context?: HttpContext
  }
): Observable<{
'message': string;
'data': DocumentCategoryResponse;
}> {

    return this.getDocumentsCategories$Response(params).pipe(
      map((r: StrictHttpResponse<{
'message': string;
'data': DocumentCategoryResponse;
}>) => r.body as {
'message': string;
'data': DocumentCategoryResponse;
})
    );
  }

  /**
   * Path part for operation getCatalogIndustrySurchargesList
   */
  static readonly GetCatalogIndustrySurchargesListPath = '/api/web/v1/catalogs/industry-surcharges';

  /**
   * Get list of industry surcharges.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCatalogIndustrySurchargesList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCatalogIndustrySurchargesList$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ListApiResponse & {
'data'?: Array<IndustrySurcharge>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, CatalogService.GetCatalogIndustrySurchargesListPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListApiResponse & {
        'data'?: Array<IndustrySurcharge>;
        }>;
      })
    );
  }

  /**
   * Get list of industry surcharges.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCatalogIndustrySurchargesList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCatalogIndustrySurchargesList(params?: {
    context?: HttpContext
  }
): Observable<ListApiResponse & {
'data'?: Array<IndustrySurcharge>;
}> {

    return this.getCatalogIndustrySurchargesList$Response(params).pipe(
      map((r: StrictHttpResponse<ListApiResponse & {
'data'?: Array<IndustrySurcharge>;
}>) => r.body as ListApiResponse & {
'data'?: Array<IndustrySurcharge>;
})
    );
  }

  /**
   * Path part for operation getAppApiWebV1CatalogsPermissionListIndex
   */
  static readonly GetAppApiWebV1CatalogsPermissionListIndexPath = '/api/web/v1/catalogs/permissions';

  /**
   * Get list of permissions.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_USERS'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAppApiWebV1CatalogsPermissionListIndex()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAppApiWebV1CatalogsPermissionListIndex$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ListApiResponse & {
'data'?: Array<PermissionItemResponse>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, CatalogService.GetAppApiWebV1CatalogsPermissionListIndexPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListApiResponse & {
        'data'?: Array<PermissionItemResponse>;
        }>;
      })
    );
  }

  /**
   * Get list of permissions.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_USERS'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAppApiWebV1CatalogsPermissionListIndex$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAppApiWebV1CatalogsPermissionListIndex(params?: {
    context?: HttpContext
  }
): Observable<ListApiResponse & {
'data'?: Array<PermissionItemResponse>;
}> {

    return this.getAppApiWebV1CatalogsPermissionListIndex$Response(params).pipe(
      map((r: StrictHttpResponse<ListApiResponse & {
'data'?: Array<PermissionItemResponse>;
}>) => r.body as ListApiResponse & {
'data'?: Array<PermissionItemResponse>;
})
    );
  }

  /**
   * Path part for operation getAppApiWebV1CatalogsQualificationCategoryListIndex
   */
  static readonly GetAppApiWebV1CatalogsQualificationCategoryListIndexPath = '/api/web/v1/catalogs/qualifications/categories';

  /**
   * Get list of qualifications categories.
   *
   * **Accessible only for users with roles 'ROLE_PSP_USER', 'ROLE_CUSTOMER_USER'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAppApiWebV1CatalogsQualificationCategoryListIndex()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAppApiWebV1CatalogsQualificationCategoryListIndex$Response(params?: {

    /**
     * Filter categories by tag. Keys: business, medical
     */
    tag?: 'business' | 'medical';
    context?: HttpContext
  }
): Observable<StrictHttpResponse<GeneralApiResponse & {
'data'?: QualificationCategoryResponse;
}>> {

    const rb = new RequestBuilder(this.rootUrl, CatalogService.GetAppApiWebV1CatalogsQualificationCategoryListIndexPath, 'get');
    if (params) {
      rb.query('tag', params.tag, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GeneralApiResponse & {
        'data'?: QualificationCategoryResponse;
        }>;
      })
    );
  }

  /**
   * Get list of qualifications categories.
   *
   * **Accessible only for users with roles 'ROLE_PSP_USER', 'ROLE_CUSTOMER_USER'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAppApiWebV1CatalogsQualificationCategoryListIndex$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAppApiWebV1CatalogsQualificationCategoryListIndex(params?: {

    /**
     * Filter categories by tag. Keys: business, medical
     */
    tag?: 'business' | 'medical';
    context?: HttpContext
  }
): Observable<GeneralApiResponse & {
'data'?: QualificationCategoryResponse;
}> {

    return this.getAppApiWebV1CatalogsQualificationCategoryListIndex$Response(params).pipe(
      map((r: StrictHttpResponse<GeneralApiResponse & {
'data'?: QualificationCategoryResponse;
}>) => r.body as GeneralApiResponse & {
'data'?: QualificationCategoryResponse;
})
    );
  }

  /**
   * Path part for operation getAppApiWebV1CatalogsQualificationListIndex
   */
  static readonly GetAppApiWebV1CatalogsQualificationListIndexPath = '/api/web/v1/catalogs/qualifications';

  /**
   * Get list of qualifications.
   *
   * **Accessible only for users with roles 'ROLE_PSP_USER', 'ROLE_CUSTOMER_USER'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAppApiWebV1CatalogsQualificationListIndex()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAppApiWebV1CatalogsQualificationListIndex$Response(params?: {

    /**
     * Filter qualifications by tag.
     */
    tag?: 'business' | 'medical';
    context?: HttpContext
  }
): Observable<StrictHttpResponse<GeneralApiResponse & {
'data'?: QualificationResponse;
}>> {

    const rb = new RequestBuilder(this.rootUrl, CatalogService.GetAppApiWebV1CatalogsQualificationListIndexPath, 'get');
    if (params) {
      rb.query('tag', params.tag, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GeneralApiResponse & {
        'data'?: QualificationResponse;
        }>;
      })
    );
  }

  /**
   * Get list of qualifications.
   *
   * **Accessible only for users with roles 'ROLE_PSP_USER', 'ROLE_CUSTOMER_USER'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAppApiWebV1CatalogsQualificationListIndex$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAppApiWebV1CatalogsQualificationListIndex(params?: {

    /**
     * Filter qualifications by tag.
     */
    tag?: 'business' | 'medical';
    context?: HttpContext
  }
): Observable<GeneralApiResponse & {
'data'?: QualificationResponse;
}> {

    return this.getAppApiWebV1CatalogsQualificationListIndex$Response(params).pipe(
      map((r: StrictHttpResponse<GeneralApiResponse & {
'data'?: QualificationResponse;
}>) => r.body as GeneralApiResponse & {
'data'?: QualificationResponse;
})
    );
  }

  /**
   * Path part for operation getAppApiWebV1CatalogsRequirementListIndex
   */
  static readonly GetAppApiWebV1CatalogsRequirementListIndexPath = '/api/web/v1/catalogs/requirements';

  /**
   * Get list of requirements.
   *
   * **Accessible only for users with roles 'ROLE_PSP_USER', 'ROLE_CUSTOMER_USER'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAppApiWebV1CatalogsRequirementListIndex()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAppApiWebV1CatalogsRequirementListIndex$Response(params?: {

    /**
     * Filter requirements by tag.
     */
    tag?: 'business' | 'medical';
    context?: HttpContext
  }
): Observable<StrictHttpResponse<GeneralApiResponse & {
'data'?: RequirementCategoryListResponse;
}>> {

    const rb = new RequestBuilder(this.rootUrl, CatalogService.GetAppApiWebV1CatalogsRequirementListIndexPath, 'get');
    if (params) {
      rb.query('tag', params.tag, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GeneralApiResponse & {
        'data'?: RequirementCategoryListResponse;
        }>;
      })
    );
  }

  /**
   * Get list of requirements.
   *
   * **Accessible only for users with roles 'ROLE_PSP_USER', 'ROLE_CUSTOMER_USER'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAppApiWebV1CatalogsRequirementListIndex$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAppApiWebV1CatalogsRequirementListIndex(params?: {

    /**
     * Filter requirements by tag.
     */
    tag?: 'business' | 'medical';
    context?: HttpContext
  }
): Observable<GeneralApiResponse & {
'data'?: RequirementCategoryListResponse;
}> {

    return this.getAppApiWebV1CatalogsRequirementListIndex$Response(params).pipe(
      map((r: StrictHttpResponse<GeneralApiResponse & {
'data'?: RequirementCategoryListResponse;
}>) => r.body as GeneralApiResponse & {
'data'?: RequirementCategoryListResponse;
})
    );
  }

  /**
   * Path part for operation getApiCatalogsShiftTimes
   */
  static readonly GetApiCatalogsShiftTimesPath = '/api/web/v1/catalogs/shift-times';

  /**
   * Get default list of shift-times.
   *
   * **Accessible only for users with roles 'ROLE_PSP_USER', 'ROLE_CUSTOMER_USER'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getApiCatalogsShiftTimes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApiCatalogsShiftTimes$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<GeneralApiResponse & {
'data'?: ShiftTimesResponse;
}>> {

    const rb = new RequestBuilder(this.rootUrl, CatalogService.GetApiCatalogsShiftTimesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GeneralApiResponse & {
        'data'?: ShiftTimesResponse;
        }>;
      })
    );
  }

  /**
   * Get default list of shift-times.
   *
   * **Accessible only for users with roles 'ROLE_PSP_USER', 'ROLE_CUSTOMER_USER'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getApiCatalogsShiftTimes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApiCatalogsShiftTimes(params?: {
    context?: HttpContext
  }
): Observable<GeneralApiResponse & {
'data'?: ShiftTimesResponse;
}> {

    return this.getApiCatalogsShiftTimes$Response(params).pipe(
      map((r: StrictHttpResponse<GeneralApiResponse & {
'data'?: ShiftTimesResponse;
}>) => r.body as GeneralApiResponse & {
'data'?: ShiftTimesResponse;
})
    );
  }

}
