import { Injectable } from '@angular/core';
import {
  BranchListItemResponse,
  CreatedResponse,
  CustomerService,
  EmptySuccessfulResponse,
  ListApiResponse,
  PspBranchCreateRequest,
  PspBranchResponse,
  PspBranchService,
  PspBranchUpdateUsersRequest,
  PspService,
  UserItemResponse,
  UserService,
} from '../../../api';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class BasePspBranchService {
  constructor(
    private readonly userService: UserService,
    private readonly pspService: PspService,
    private readonly customerService: CustomerService,
    private readonly pspBranchService: PspBranchService
  ) {}

  getPspBranches(orgId: number, active?: any): Observable<ListApiResponse & { data?: Array<BranchListItemResponse> }> {
    return this.pspBranchService.getPspBranchListOfBranches({ psp: orgId, active });
  }

  setActiveBranchStatus(orgId: number, branchId: number): Observable<EmptySuccessfulResponse> {
    return this.pspBranchService.postPspBranchActivateBranch({ psp: orgId, branch: branchId });
  }

  setInactiveBranchStatus(orgId: number, branchId: number): Observable<EmptySuccessfulResponse> {
    return this.pspBranchService.deletePspBranchDeactivateBranch({ psp: orgId, branch: branchId });
  }

  getPspAdminsAndUsers(orgId: string, _active?: any): Observable<ListApiResponse & { data?: Array<UserItemResponse> }> {
    return this.pspService.getPspsUsersList({ psp: Number(orgId) });
  }

  createPspBranch(orgId: number, body: PspBranchCreateRequest): Observable<CreatedResponse> {
    return this.pspBranchService.postPspBranchCreate({ psp: orgId, body });
  }

  getPspBranchUsers(psp: number, branch: number): Observable<ListApiResponse & { data?: Array<UserItemResponse> }> {
    return this.pspBranchService.getPspsBranchUsersList({ psp, branch });
  }

  addUsersToBranch(branch: number, psp: number, users: Array<number>): Observable<EmptySuccessfulResponse> {
    return this.pspBranchService.patchPspBranchAddUsers({ psp, branch, body: { users } });
  }

  deleteUserFromBranch(psp: number, user: number, branch: number): Observable<EmptySuccessfulResponse> {
    return this.pspBranchService.deletePspBranchDeleteUser({ psp, user, branch });
  }

  updateUsersInBranch(branch: number, psp: number, users: Array<number>): Observable<EmptySuccessfulResponse> {
    return this.pspBranchService.putPspBranchUpdateUsers({ psp, branch, body: { users } });
  }

  getPspBranch(psp: number, branch: number): Observable<PspBranchResponse> {
    return this.pspBranchService.getPspBranchShow({ psp, branch }).pipe(map((response) => response.data));
  }
}
