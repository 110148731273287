import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  ConfirmRequest,
  EmptySuccessfulResponse,
  FiltersResponse,
  PaginatedApiResponse,
  PspOfferService,
  SectionResponse,
} from '../../../api';

@Injectable()
export class BaseOffersAndBookingsService {
  constructor(private pspOfferService: PspOfferService) {}

  getPspOfferOverlapList(params: {
    psp: number;
    page?: any;
    size?: any;
    profile?: any;
    customer?: any;
  }): Observable<PaginatedApiResponse & { data?: SectionResponse[]; filters?: FiltersResponse }> {
    return this.pspOfferService.getPspOfferOverlapList(params);
  }

  confirmPspInquiryOffer(params: {
    psp: number;
    inquiry: number;
    offer: number;
    body: ConfirmRequest;
  }): Observable<EmptySuccessfulResponse> {
    return this.pspOfferService.postPspInquiryOfferConfirm(params);
  }

  withdrawPspInquiryOffer(params: {
    psp: number;
    inquiry: number;
    offer: number;
  }): Observable<EmptySuccessfulResponse> {
    return this.pspOfferService.deletePspInquiryOfferWithdraw(params);
  }
}
