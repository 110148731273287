<div
  class="notifications-all"
  #notificationsContainer
>
  <div *ngIf="notifications.length > 0">
    <div class="d-flex justify-content-end mb-3 p-0">
      <base-button
        [disabled]="allNotificationsRead"
        [size]="buttonSize.sm"
        label="notification.markAllAsRead"
        color="secondary"
        (btnClick)="markAllAsRead()"
      ></base-button>
    </div>
    <div
      *ngFor="let notification of notifications"
      id="{{ 'n' + notification.id }}"
      class="notifications-item p-4 mb-4"
      (click)="markNotificationAsRead($event, notification)"
    >
      <div class="d-flex justify-content-between align-items-center mb-5">
        <h2 class="headline-20 mb-0">
          <span *ngIf="!notification.isRead">{{ 'notification.unreadCapital' | translate }}:</span>
          {{ notification.messageSubject }}
        </h2>
        <div class="d-flex justify-content-center align-items-center">
          <span class="notification-date">
            {{
              'notification.notificationTime' | translate: { dateTime: notification.sendDate | baseFormatDate: true }
            }}
          </span>
          <span
            *ngIf="!notification.isRead"
            class="notifications-dot ms-2"
          ></span>
        </div>
      </div>
      <div
        class="notification-body"
        [innerHTML]="notification.messageBody"
      ></div>
    </div>
  </div>

  <div *ngIf="notifications.length === 0 && isListLoaded">
    <base-empty-list-row
      message="notification.empty"
      [backgroundSecondary]="true"
    ></base-empty-list-row>
  </div>

  <base-pagination
    [page]="page"
    [pageSize]="pageSize"
    [size]="size"
    [noFilters]="true"
    (pageChange)="pageChanged($event)"
  ></base-pagination>
</div>
