import { Component, Input } from '@angular/core';
import { BaseInquiryType } from '../../../modules/inquiry/_enums/base-inquiry.enum';

@Component({
  selector: 'base-offers-status',
  templateUrl: './base-offers-status.component.html',
})
export class BaseOffersStatusComponent {
  @Input() state: BaseInquiryType;
  @Input() confirmedOffers: number | undefined;
  @Input() openOffers: number | undefined;
  @Input() declinedOffers: number | undefined;
  @Input() needConfirmationOffers: number | undefined;
  @Input() showHyphenIfEmpty = true;
  @Input() areDeclinedOffersAvailable = true;
  baseInquiryType = BaseInquiryType;
}
