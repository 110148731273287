import { SafeUrl } from '@angular/platform-browser';
import { createAction, props } from '@ngrx/store';
import {
  DepartmentListItemResponse,
  PaginatedApiResponse,
  PriceListRequest,
  PspCustomerLocationItemResponse,
  QualificationPricingListItemResponse,
  PspCustomerSurchargeResponse,
  DocumentListItemResponse,
  PspCustomerLocationShowResponse,
} from '../../../api';
import { BaseApplicationTagType } from '../../shared/enums/base-application.enum';

// Related customer locations list
export const loadRelatedCustomerLocations = createAction(
  '[Related Customer Locations] Load list',
  props<{ payload?: { page?: any; size?: any; search?: any; branches?: any } }>()
);
export const setRelatedCustomerLocations = createAction(
  '[Related Customer Locations] Set list',
  props<{ payload: PaginatedApiResponse & { data?: PspCustomerLocationItemResponse[] } }>()
);
export const successRelatedCustomerLocations = createAction('[Related Customer Locations] Success load list');
export const errorRelatedCustomerLocations = createAction('[Related Customer Locations] Error load list');

// Related customer location details
export const loadRelatedCustomerLocationDetails = createAction(
  '[Related Customer Locations] Load details',
  props<{ payload: { customer: number; location: number } }>()
);
export const setRelatedCustomerLocationDetails = createAction(
  '[Related Customer Locations] Set details',
  props<{ payload: PspCustomerLocationShowResponse }>()
);
export const successRelatedCustomerLocationDetails = createAction('[Related Customer Locations] Success details');
export const errorRelatedCustomerLocationDetails = createAction('[Related Customer Locations] Error details');

// Customer location documents list
export const loadRelatedCustomerLocationDocuments = createAction(
  '[Related Customer Locations] Load customer location documents',
  props<{ payload: { customer: number; location: number } }>()
);
export const setRelatedCustomerLocationDocuments = createAction(
  '[Related Customer Locations] Set customer location documents',
  props<{ payload: DocumentListItemResponse[] }>()
);
export const successRelatedCustomerLocationDocuments = createAction(
  '[Related Customer Locations] Success customer location documents'
);
export const errorRelatedCustomerLocationDocuments = createAction(
  '[Related Customer Locations] Error customer location documents'
);

// Customer location document download
export const downloadRelatedCustomerLocationDocument = createAction(
  '[Related Customer Locations] Download document location file',
  props<{
    payload: { customer: number; location: number; document: string; documentType: string; openInModal?: boolean };
  }>()
);
export const setRelatedCustomerLocationDocument = createAction(
  '[Related Customer Locations] Set download document file',
  props<{ payload: { document: Blob | SafeUrl } }>()
);
export const clearDownloadRelatedCustomerLocationDocument = createAction(
  '[Related Customer Locations] Clear Download document file'
);
export const successDownloadRelatedCustomerLocationDocument = createAction(
  '[Related Customer Locations] Success Download document file'
);
export const errorDownloadRelatedCustomerLocationDocument = createAction(
  '[Related Customer Locations] Error Download document file'
);

// Customer departments list
export const loadDepartments = createAction(
  '[Related Customer Locations] Load departments',
  props<{ payload: { location: number; customer: number } }>()
);
export const setDepartments = createAction(
  '[Related Customer Locations] Set departments',
  props<{ payload: DepartmentListItemResponse[] }>()
);
export const successDepartments = createAction('[Related Customer Locations] Success departments');
export const errorDepartments = createAction('[Related Customer Locations] Error departments');

// Related Customer Price List
export const loadRelatedCustomerLocationPriceList = createAction(
  '[Related Customer Locations] Load Price List',
  props<{
    payload: {
      customer: number;
      location: number;
      tag?: BaseApplicationTagType;
    };
  }>()
);
export const setRelatedCustomerLocationPriceList = createAction(
  '[Related Customer Locations] Set Price List',
  props<{ payload: QualificationPricingListItemResponse[] }>()
);
export const updateRelatedCustomerLocationPriceList = createAction(
  '[Related Customer Locations] Update Price List',
  props<{
    payload: {
      customer: number;
      location: number;
      body: PriceListRequest;
      tag?: BaseApplicationTagType;
    };
  }>()
);
export const successRelatedCustomerLocationPriceList = createAction('[Related Customer Locations] Success Price List');
export const errorRelatedCustomerLocationPriceList = createAction('[Related Customer Locations] Error Price List');

// Surcharges
export const loadSurcharges = createAction(
  '[Related Customer Locations] Load surcharges',
  props<{ payload: { customer: number; location: number } }>()
);
export const setSurcharges = createAction(
  '[Related Customer Locations] Set surcharges',
  props<{ payload: PspCustomerSurchargeResponse }>()
);
export const successSurcharges = createAction('[Related Customer Locations] Success surcharges');
export const errorSurcharges = createAction('[Related Customer Locations] Error surcharges');
