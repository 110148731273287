import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BaseModalService } from './base-modal.service';
import { noop } from 'rxjs';

@Injectable()
export class BaseDeviceService {
  constructor(private deviceService: DeviceDetectorService, private modalService: BaseModalService) {}

  isDesktop(): boolean {
    return this.deviceService.isDesktop();
  }

  openMobileDeviceInfoModal(): void {
    this.modalService
      .open(
        {
          headText: 'mobileDeviceNote.headText',
          bodyText: 'mobileDeviceNote.bodyText',
          okText: 'action.understood',
          confirmButtonOnly: true,
          cancelButtonDisplayed: false,
        },
        {
          size: 'lg',
          backdrop: 'static',
        }
      )
      .result.then(noop, noop);
  }
}
