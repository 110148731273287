import produce from 'immer';
import { DocumentCategoryResponse, DocumentListItemResponse, DocumentResponse } from '../../../api';
import { baseDocumentActions } from '../actions';

export interface DocumentsState {
  documents: {
    data: Array<DocumentListItemResponse> | undefined;
    ok: boolean | undefined;
  };
  currentDocumentInfo: {
    data: DocumentResponse | undefined;
    ok: boolean | undefined;
  };
  categories: {
    data: DocumentCategoryResponse | undefined;
    ok: boolean | undefined;
  };
  downloadDocument: {
    data: Blob | undefined;
  };
}

export const INITIAL_DOCUMENTS_STATE: DocumentsState = {
  documents: {
    data: undefined,
    ok: undefined,
  },
  currentDocumentInfo: {
    data: undefined,
    ok: undefined,
  },
  categories: {
    data: undefined,
    ok: undefined,
  },
  downloadDocument: {
    data: undefined,
  },
};

export function BaseDocumentsReducer(state: DocumentsState = INITIAL_DOCUMENTS_STATE, action: any): any {
  return produce(state, (draftState) => {
    switch (action.type) {
      case baseDocumentActions.getDocuments.type:
        draftState.documents.ok = undefined;
        return;

      case baseDocumentActions.setDocuments.type:
        draftState.documents.data = action.payload;
        return;

      case baseDocumentActions.setDownloadDocument.type:
        draftState.downloadDocument.data = action.payload.document;
        return;

      case baseDocumentActions.clearDownloadDocument.type:
        draftState.downloadDocument.data = undefined;
        return;

      case baseDocumentActions.successLoadDocuments.type:
        draftState.documents.ok = true;
        return;

      case baseDocumentActions.errorLoadDocuments.type:
        draftState.documents.ok = false;
        return;

      case baseDocumentActions.getDocumentInfo.type:
        draftState.currentDocumentInfo.ok = undefined;
        return;

      case baseDocumentActions.loadDocumentInfo.type:
        draftState.currentDocumentInfo.data = action.payload;
        return;

      case baseDocumentActions.successLoadDocumentInfo.type:
        draftState.currentDocumentInfo.ok = true;
        return;

      case baseDocumentActions.errorLoadDocumentInfo.type:
        draftState.currentDocumentInfo.ok = false;
        return;

      case baseDocumentActions.getDocumentCategories.type:
        draftState.categories.ok = undefined;
        return;

      case baseDocumentActions.loadDocumentCategories.type:
        draftState.categories.data = action.payload;
        return;

      case baseDocumentActions.successDocumentCategories.type:
        draftState.categories.ok = true;
        return;

      case baseDocumentActions.errorDocumentCategories.type:
        draftState.categories.ok = false;
        return;
    }
  });
}
