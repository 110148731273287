<div
  class="modal-content modal-width-{{ modalParams.width }} modal-max-width-{{ modalParams.maxWidth }}"
  [ngStyle]="modalStyles"
>
  <div
    class="modal-header"
    *ngIf="!modalParams.hideHeader"
  >
    <div
      class="modal-title-container"
      [ngClass]="{ centered: modalParams.headTextCentered }"
    >
      <h1
        class="modal-title"
        [ngClass]="modalParams.headerSize"
      >
        {{ modalParams.headText | translate: modalParams.headTextTranslateParams }}
      </h1>
      <h2
        class="modal-title-subheader"
        [ngClass]="modalParams.subHeaderSize"
        *ngIf="subHeadText()"
      >
        {{ subHeadText() | translate }}
      </h2>
    </div>
    <div
      *ngIf="!modalParams.hideCloseIcon"
      class="close modal-close-btn"
      (click)="dismissModal(modalDismissReasons.crossClick)"
    >
      <i class="fa-solid fa-circle-xmark"></i>
    </div>
  </div>
  <div
    class="modal-body"
    #modalBody
  >
    <div
      *ngIf="modalParams.bodyText"
      [innerHTML]="modalParams.bodyText | translate: modalParams.bodyTextTranslateParams"
    ></div>
    <div *ngIf="modalParams.bodyTemplate">
      <base-modal-body [content]="content"></base-modal-body>
    </div>
  </div>
  <div
    class="modal-footer"
    *ngIf="!modalParams.hideFooter"
    [ngClass]="{
      'single-button-footer':
        (modalParams.cancelButtonOnly && !modalParams.confirmButtonOnly) ||
        (!modalParams.cancelButtonOnly && modalParams.confirmButtonOnly),
      'edge-footer': modalParams.edgeFooter
    }"
  >
    <base-button
      *ngIf="modalParams.cancelButtonDisplayed"
      color="secondary"
      [ngClass]="{
        'd-flex w-100 justify-content-center':
          !modalParams.secondaryActionButtonDisplayed && !modalParams.okButtonDisplayed
      }"
      [disabled]="modalParams.cancelButtonDisabled"
      [size]="buttonSize.md"
      [label]="modalParams.cancelText"
      (click)="dismissModal(modalDismissReasons.cancel)"
      [attr.data-cy]="'action.cancel'"
    >
    </base-button>
    <div class="d-flex align-items-center gap-2">
      <base-button
        class="btn-confirm"
        *ngIf="modalParams.secondaryActionButtonDisplayed"
        [disabled]="modalParams.secondaryActionButtonDisabled"
        color="primary"
        [size]="buttonSize.md"
        [label]="modalParams.secondaryActionButtonText | translate"
        (click)="closeModal('secondaryAction'); scrollToTopForm()"
        [attr.data-cy]="'modal.button.secondaryAction'"
      >
      </base-button>
      <base-button
        class="btn-confirm"
        *ngIf="modalParams.okButtonDisplayed"
        [disabled]="modalParams.okButtonDisabled"
        color="primary"
        [size]="buttonSize.md"
        [label]="okText()"
        (click)="closeModal('ok'); scrollToTopForm()"
        [attr.data-cy]="'modal.button.ok'"
      >
      </base-button>
    </div>
  </div>
</div>
