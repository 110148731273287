<div class="auth-wrapper-background-layer"></div>
<div
  class="auth-wrapper"
  [ngStyle]="{ 'background-image': 'url(' + backgroundImage + ')' }"
>
  <div class="auth-actions">
    <base-language-switch
      [color]="textWhite ? colors.white : colors.darkBlue"
      class="auth-wrapper-language-switch"
    ></base-language-switch>
    <div class="d-flex justify-content-end flex-wrap gap-2">
      <base-contact-button class="auth-contact"></base-contact-button>
      <base-button
        *ngIf="!isImprintPage"
        (btnClick)="goTo('/auth/imprint')"
        [size]="buttonSize.sm"
        label="imprint"
        color="white"
      ></base-button>
      <base-button
        *ngIf="!isRegisterPage"
        (btnClick)="downloadPrivacyPolicy()"
        [size]="buttonSize.sm"
        label="privacyPolicy"
        color="white"
      ></base-button>
    </div>
  </div>
  <div
    class="auth-intro"
    [ngClass]="asideData.customClass"
  >
    <img
      class="img-fluid"
      [ngClass]="{ 'm-0': !(asideData?.title && asideData?.description) }"
      [src]="logo"
      alt="stazzle Logo"
    />
    <h2
      *ngIf="asideData?.title"
      [ngClass]="{ 'text-dark': !textWhite }"
    >
      {{ asideData.title | translate }}
    </h2>
    <p
      *ngIf="asideData?.description"
      class="auth-wrapper-description"
      [ngClass]="{
        'auth-wrapper-description-padding': asideData?.title,
        'auth-wrapper-description-spacing': !asideData?.title,
        'text-dark': !textWhite
      }"
    >
      {{ asideData?.description | translate }}
    </p>
  </div>
</div>

<div class="auth-component-wrapper">
  <router-outlet></router-outlet>
</div>
