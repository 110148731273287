import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { AvatarUpdateRequest, LogoUpdateRequest } from '../../../../../api';
import { BaseAvatarSize } from '../../../../shared/components/avatar/base-avatar.component';
import { BaseTabInterface } from '../../../../shared/interfaces/base-tab.interface';
import { BASE_PERMISSIONS } from '../../../../core/constants/base-permissions';
import { baseAdministrationActions, baseAdministrationSelectors, BaseCoreState } from '../../../../_store';
import { BaseRolesService } from '../../../../core/services/base-roles.service';

@Component({
  selector: 'base-administration',
  templateUrl: './base-administration.component.html',
  styleUrls: ['./base-administration.component.scss'],
})
export class BaseAdministrationComponent implements OnInit, OnDestroy {
  @Input() tabs: Array<BaseTabInterface & { permissionsAllowed: BASE_PERMISSIONS[] }>;
  @Input() isCustomer = false;
  avatarSize = BaseAvatarSize.large;
  currentTab: BaseTabInterface = { title: '', url: '' };
  destroy$ = new Subject();
  isMyProfile = false;
  isCustomerLogoEditable: boolean;
  private signDigital: boolean | undefined;

  constructor(
    private readonly store: Store<BaseCoreState>,
    private readonly router: Router,
    private readonly baseRolesService: BaseRolesService
  ) {}

  ngOnInit(): void {
    this.isCustomerLogoEditable = this.baseRolesService.hasAdminRole();
    this.setCurrentTab(this.router.url);
    this.router.events
      .pipe(
        takeUntil(this.destroy$),
        filter((event) => event instanceof NavigationEnd)
      )
      .subscribe((event: any) => {
        this.setCurrentTab(event.urlAfterRedirects);
      });

    if (this.baseRolesService.isPsp()) {
      this.store
        .select(baseAdministrationSelectors.getPspData)
        .pipe(
          takeUntil(this.destroy$),
          filter((data) => data !== undefined),
          map((data) => data.data)
        )
        .subscribe((value) => {
          if (value) {
            this.signDigital = value.data.signDigital;
            this.filterTabsBasedOnSignDigital();
          }
        });
    } else {
      this.store
        .select(baseAdministrationSelectors.getCustomerDataData)
        .pipe(takeUntil(this.destroy$))
        .subscribe((value) => {
          if (value) {
            this.signDigital = value.signDigital;
            this.filterTabsBasedOnSignDigital();
          }
        });
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }

  onUpdateAvatar(body: AvatarUpdateRequest | LogoUpdateRequest): void {
    if (!this.isCustomerLogoEditable && !this.isMyProfile) {
      return;
    }
    this.isMyProfile
      ? this.store.dispatch(
          baseAdministrationActions.updateProfileAvatarAction({ payload: { body: body as AvatarUpdateRequest } })
        )
      : this.isCustomer
      ? this.store.dispatch(
          baseAdministrationActions.updateCustomerLogoAction({ payload: { body: body as LogoUpdateRequest } })
        )
      : this.store.dispatch(
          baseAdministrationActions.updatePspLogoAction({ payload: { body: body as LogoUpdateRequest } })
        );
  }

  private filterTabsBasedOnSignDigital() {
    if (this.signDigital) {
      return;
    }

    this.tabs = this.tabs.filter((tab) => tab.id !== 60);
  }

  private setCurrentTab(url: string): void {
    const currentTabIndex = this.tabs.findIndex((tab) => tab?.url?.split('/')[2] === url.split('/')[2]);
    this.currentTab = this.tabs[currentTabIndex];
    this.isMyProfile = this.currentTab?.url.includes('user-settings');
  }
}
