import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'base-customer-contract-dropdown',
  templateUrl: './base-customer-contract-dropdown.component.html',
})
export class BaseCustomerContractDropdownComponent {
  @Input() items: any[] = [];
  @Input() value: any = null;

  @Output() changeHandler = new EventEmitter<any>();
}
