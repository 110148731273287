import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { BaseCoreState, baseSharedActions, baseSharedSelectors } from '../../_store';

@Injectable()
export class BaseBranchesGuard {
  constructor(private store: Store<BaseCoreState>) {}

  canActivate(): Observable<boolean> {
    this.store.dispatch(baseSharedActions.getBranches());

    return this.store.select(baseSharedSelectors.getBranches).pipe(
      filter((result) => result.ok !== undefined),
      map((result) => !!result.ok)
    );
  }
}
