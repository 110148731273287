import { SafeUrl } from '@angular/platform-browser';
import { createAction, props } from '@ngrx/store';
import {
  PspResponse,
  PaginatedApiResponse,
  CustomerBranchListItemResponse,
  PspBranchResponse,
  SurchargeListItemResponse,
  DocumentListItemResponse,
} from '../../../api';

// Related PSP branch list
export const loadRelatedPspBranchList = createAction(
  '[Related PSP Branches] Load list',
  props<{ payload?: { page?: any; search?: any; locations?: number[] } }>()
);
export const setRelatedPspBranchList = createAction(
  '[Related PSP Branches] Set list',
  props<{ payload: PaginatedApiResponse & { data?: CustomerBranchListItemResponse[] } }>()
);
export const successRelatedPspBranchList = createAction('[Related PSP Branches] Success load list');
export const errorRelatedPspBranchList = createAction('[Related PSP Branches] Error load list');

// Related PSP branch details
export const loadRelatedPspBranchDetails = createAction(
  '[Related PSP Branches] Load branch details',
  props<{ payload: { branch: number; psp: number; location: number } }>()
);
export const setRelatedPspBranchDetails = createAction(
  '[Related PSP Branches] Set branch details',
  props<{ payload: PspBranchResponse }>()
);
export const successRelatedPspBranchDetails = createAction('[Related PSP Branches] Success branch details');
export const errorRelatedPspBranchDetails = createAction('[Related PSP Branches] Error branch details');

// Related PSP branch documents
export const loadRelatedPspBranchDocuments = createAction(
  '[Related PSP Branches] Load documents',
  props<{ payload: { psp: number } }>()
);
export const setRelatedPspBranchDocuments = createAction(
  '[Related PSP Branches] Set documents',
  props<{ payload: DocumentListItemResponse[] }>()
);
export const successRelatedPspBranchDocuments = createAction('[Related PSP Branches] Success documents');
export const errorRelatedPspBranchDocuments = createAction('[Related PSP Branches] Error documents');
export const downloadRelatedPspBranchDocument = createAction(
  '[Related PSP Branches] Download document file',
  props<{ payload: { psp: number; document: string; documentType: string; openInNewWindow?: boolean } }>()
);
export const setDownloadRelatedPspBranchDocument = createAction(
  '[Related PSP Branches] Set downloaded document file',
  props<{ payload: { document: Blob | SafeUrl } }>()
);
export const clearDownloadRelatedPspBranchDocument = createAction(
  '[Related PSP Branches] Clear Download document file'
);
export const successDownloadRelatedPspBranchDocument = createAction(
  '[Related PSP Branches] Success Download document file'
);
export const errorDownloadRelatedPspBranchDocument = createAction(
  '[Related PSP Branches] Error Download document file'
);

export const downloadRelatedPspBranchContract = createAction(
  '[Related PSP Branches] Download contract',
  props<{ payload: { location: number; branch: number; fileName?: string } }>()
);

// Surcharges
export const loadSurcharges = createAction('[Related PSP Branches] Load surcharges', props<{ payload: number }>());
export const setSurcharges = createAction(
  '[Related PSP Branches] Set surcharges',
  props<{ payload: SurchargeListItemResponse[] }>()
);
export const successSurcharges = createAction('[Related PSP Branches] Success surcharges');
export const errorSurcharges = createAction('[Related PSP Branches] Error surcharges');

// Related PSP branch data
export const loadRelatedPspBranchData = createAction(
  '[Related PSP Branches] Load data',
  props<{ payload: { psp: number } }>()
);
export const setRelatedPspBranchData = createAction(
  '[Related PSP Branches] Set data',
  props<{ payload: PspResponse }>()
);
export const successRelatedPspBranchData = createAction('[Related PSP Branches] Success branch data');
export const errorRelatedPspBranchData = createAction('[Related PSP Branches] Error branch data');
