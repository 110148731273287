import produce from 'immer';
import {
  CustomerResponse,
  DepartmentListItemResponse,
  TerminalCustomerLocationListItemResponse,
  LocationListItemResponse,
  LocationResponse,
  PermissionItemResponse,
  PspResponse,
  QualificationShortInfoResponse,
  SurchargeListItemResponse,
  UserResponse,
} from '../../../api';
import { baseAdministrationActions } from '../actions';
import { BaseLocationTerminalStatusEnum } from '../../modules/administration/_enums/base-location-terminal-status.enum';
import { BaseDateService } from '../../shared/services/base-date.service';
import { BASE_LOCATION_TERMINAL_STATUS_PING_TIME } from '../../modules/administration/_constans/location-terminal-status-ping-time.const';

export interface AdministrationState {
  userProfileData: {
    data: UserResponse | undefined;
    ok: boolean | undefined;
  };
  userPasswordData: {
    ok: boolean | undefined;
  };
  profilePasswordData: {
    ok: boolean | undefined;
  };
  companyInfo: {
    data: CustomerResponse | PspResponse | undefined;
    ok: boolean | undefined;
  };
  customerData: {
    data: CustomerResponse | undefined;
    ok: boolean | undefined;
    updateCrud: boolean | undefined;
  };
  pspData: {
    data: { message: string; data: PspResponse } | undefined;
    ok: boolean | undefined;
    updateCrud: boolean | undefined;
  };
  permissions: {
    data: PermissionItemResponse[] | undefined;
    ok: boolean | undefined;
    crud: boolean | undefined;
  };
  catalogQualifications: {
    data: QualificationShortInfoResponse[] | undefined;
    ok: boolean | undefined;
  };
  surcharges: {
    data: SurchargeListItemResponse[] | undefined;
    ok: boolean | undefined;
    updateCrud: boolean | undefined;
  };
  locations: {
    data: LocationListItemResponse[] | undefined;
    ok: boolean | undefined;
  };
  locationDepartments: {
    data: DepartmentListItemResponse[] | undefined;
    ok: boolean | undefined;
  };
  location: {
    data: LocationResponse | undefined;
    ok: boolean | undefined;
  };
  createdLocationId: {
    data: number | undefined;
  };
  customerLocationTerminals: {
    data: (TerminalCustomerLocationListItemResponse & { status: BaseLocationTerminalStatusEnum })[];
    ok: boolean | undefined;
  };
  terminalAPIKey: {
    data: Blob | undefined;
    fileName: string | undefined;
    terminalId: number | undefined;
  };
}

export const INITIAL_USER_PROFILE_STATE: AdministrationState = {
  userProfileData: {
    data: undefined,
    ok: undefined,
  },
  userPasswordData: {
    ok: undefined,
  },
  profilePasswordData: {
    ok: undefined,
  },
  companyInfo: {
    data: undefined,
    ok: undefined,
  },
  customerData: {
    data: undefined,
    ok: undefined,
    updateCrud: undefined,
  },
  pspData: {
    data: undefined,
    ok: undefined,
    updateCrud: undefined,
  },
  permissions: {
    data: undefined,
    ok: undefined,
    crud: undefined,
  },
  catalogQualifications: {
    data: undefined,
    ok: undefined,
  },
  surcharges: {
    data: undefined,
    ok: undefined,
    updateCrud: undefined,
  },
  locations: {
    data: undefined,
    ok: undefined,
  },
  locationDepartments: {
    data: undefined,
    ok: undefined,
  },
  location: {
    data: undefined,
    ok: undefined,
  },
  createdLocationId: {
    data: undefined,
  },
  customerLocationTerminals: {
    data: [],
    ok: undefined,
  },
  terminalAPIKey: {
    data: undefined,
    fileName: undefined,
    terminalId: undefined,
  },
};

export function baseAdministrationReducer(state: AdministrationState = INITIAL_USER_PROFILE_STATE, action: any): any {
  return produce(state, (draftState) => {
    switch (action.type) {
      // User Profile
      case baseAdministrationActions.loadUserProfileData.type:
        draftState.userProfileData.ok = undefined;
        return;

      case baseAdministrationActions.setUserProfileData.type:
        draftState.userProfileData.data = action.payload;
        return;

      case baseAdministrationActions.successUserProfileData.type:
        draftState.userProfileData.ok = true;
        return;

      case baseAdministrationActions.errorUserProfileData.type:
        draftState.userProfileData.ok = false;
        return;

      case baseAdministrationActions.updateUserProfileData.type:
        draftState.userProfileData.ok = undefined;
        return;

      case baseAdministrationActions.successProfilePasswordUpdate.type:
        draftState.profilePasswordData.ok = true;
        return;

      case baseAdministrationActions.errorProfilePasswordUpdate.type:
        // if we're getting the same value the variable of the state is not triggered that's why we set an undefined value
        draftState.profilePasswordData.ok = undefined;
        draftState.profilePasswordData.ok = false;
        return;

      case baseAdministrationActions.successUserPasswordUpdate.type:
        draftState.userPasswordData.ok = true;
        return;

      // Company info
      case baseAdministrationActions.loadCompanyInfo.type:
        draftState.companyInfo.ok = undefined;
        return;

      case baseAdministrationActions.setCompanyInfo.type:
        draftState.companyInfo.data = action.payload;
        return;

      case baseAdministrationActions.successCompanyInfo.type:
        draftState.companyInfo.ok = true;
        return;

      case baseAdministrationActions.errorCompanyInfo.type:
        draftState.companyInfo.ok = false;
        return;

      // Customer administration
      case baseAdministrationActions.loadCustomerData.type:
        draftState.customerData.ok = undefined;
        return;

      case baseAdministrationActions.setCustomerData.type:
        draftState.customerData.data = action.payload;
        return;

      case baseAdministrationActions.successCustomerData.type:
        draftState.customerData.ok = true;
        return;

      case baseAdministrationActions.errorCustomerData.type:
        draftState.customerData.ok = false;
        return;

      // PSP administration
      case baseAdministrationActions.loadPspData.type:
        draftState.pspData.ok = undefined;
        return;

      case baseAdministrationActions.setPspData.type:
        draftState.pspData.data = action.payload;
        return;

      case baseAdministrationActions.successPspData.type:
        draftState.pspData.ok = true;
        return;

      case baseAdministrationActions.errorPspData.type:
        draftState.pspData.ok = false;
        return;

      // Permissions
      case baseAdministrationActions.loadPermissions.type:
        draftState.permissions.ok = undefined;
        return;

      case baseAdministrationActions.setPermissions.type:
        draftState.permissions.data = action.payload;
        return;

      case baseAdministrationActions.successPermissions.type:
        draftState.permissions.ok = true;
        return;

      case baseAdministrationActions.errorPermissions.type:
        draftState.permissions.ok = false;
        return;

      case baseAdministrationActions.loadCatalogQualifications.type:
        draftState.catalogQualifications.ok = undefined;
        return;

      case baseAdministrationActions.setCatalogQualifications.type:
        draftState.catalogQualifications.data = action.payload;
        return;

      case baseAdministrationActions.successCatalogQualifications.type:
        draftState.catalogQualifications.ok = true;
        return;

      case baseAdministrationActions.errorCatalogQualifications.type:
        draftState.catalogQualifications.ok = false;
        return;

      case baseAdministrationActions.loadSurcharges.type:
        draftState.surcharges.ok = undefined;
        return;

      case baseAdministrationActions.setSurcharges.type:
        draftState.surcharges.data = action.payload;
        return;

      case baseAdministrationActions.successSurcharges.type:
        draftState.surcharges.ok = true;
        return;

      case baseAdministrationActions.errorSurcharges.type:
        draftState.surcharges.ok = false;
        return;

      case baseAdministrationActions.updateSurcharge.type:
        draftState.surcharges.updateCrud = undefined;
        return;

      case baseAdministrationActions.successUpdateSurcharge.type:
        draftState.surcharges.updateCrud = true;
        return;

      case baseAdministrationActions.errorUpdateSurcharge.type:
        draftState.surcharges.updateCrud = false;
        return;

      case baseAdministrationActions.updateCustomerInfo.type:
        draftState.customerData.updateCrud = undefined;
        return;

      case baseAdministrationActions.successUpdateCustomerInfo.type:
        draftState.customerData.updateCrud = true;
        return;

      case baseAdministrationActions.errorUpdateCustomerInfo.type:
        draftState.customerData.updateCrud = false;
        return;

      case baseAdministrationActions.updatePspInfo.type:
        draftState.pspData.updateCrud = undefined;
        return;

      case baseAdministrationActions.successUpdatePspInfo.type:
        draftState.pspData.updateCrud = true;
        return;

      case baseAdministrationActions.errorUpdatePspInfo.type:
        draftState.pspData.updateCrud = false;
        return;

      case baseAdministrationActions.setLocationsData.type:
        draftState.locations.data = action.payload;
        draftState.locations.ok = true;
        return;

      case baseAdministrationActions.setLocationDepartmentsData.type:
        draftState.locationDepartments.data = action.payload;
        draftState.locationDepartments.ok = true;
        return;

      case baseAdministrationActions.setLocationData.type:
        draftState.location.data = action.payload;
        draftState.location.ok = true;
        return;

      case baseAdministrationActions.setCreatedLocationId.type:
        draftState.createdLocationId.data = action.payload;
        return;

      case baseAdministrationActions.clearCreatedLocationId.type:
        draftState.createdLocationId.data = undefined;
        return;

      case baseAdministrationActions.resetLocationData.type:
        draftState.location.data = undefined;
        draftState.location.ok = undefined;
        return;

      case baseAdministrationActions.setInitState.type:
        draftState.pspData = INITIAL_USER_PROFILE_STATE.pspData;
        draftState.customerData = INITIAL_USER_PROFILE_STATE.customerData;
        return;

      // Customer Location Terminals List
      case baseAdministrationActions.loadCustomerLocationTerminalsList.type:
        draftState.customerLocationTerminals.ok = undefined;
        return;

      case baseAdministrationActions.setCustomerLocationTerminalsList.type:
        draftState.customerLocationTerminals.data = action.payload.map(
          (terminal: TerminalCustomerLocationListItemResponse) => {
            let status: BaseLocationTerminalStatusEnum = !terminal.lastOnlineStatusPing
              ? BaseLocationTerminalStatusEnum.pending
              : BaseDateService.differenceInMinutes(new Date(), new Date(terminal.lastOnlineStatusPing)) >
                BASE_LOCATION_TERMINAL_STATUS_PING_TIME
              ? BaseLocationTerminalStatusEnum.offline
              : BaseLocationTerminalStatusEnum.online;

            return { ...terminal, status };
          }
        );
        return;

      case baseAdministrationActions.successCustomerLocationTerminalsList.type:
        draftState.customerLocationTerminals.ok = true;
        return;

      case baseAdministrationActions.errorCustomerLocationTerminalsList.type:
        draftState.customerLocationTerminals.ok = false;
        return;

      case baseAdministrationActions.setTerminalAPIKeyView.type:
        draftState.terminalAPIKey.data = action.payload.document;
        draftState.terminalAPIKey.fileName = action.payload.fileName;
        draftState.terminalAPIKey.terminalId = action.payload.terminalId;
        return;

      case baseAdministrationActions.clearTerminalAPIKeyView.type:
        draftState.terminalAPIKey.data = undefined;
        draftState.terminalAPIKey.fileName = undefined;
        return;
    }
  });
}
