import { createAction, props } from '@ngrx/store';
import { CustomerDashboardResponse, CustomerOfferListItemResponse } from '../../../api';

export const loadCustomerDashboardData = createAction('[CustomerDashboard] Load customer dashboard data');

export const setCustomerDashboardData = createAction(
  '[CustomerDashboard] Set customer dashboard data',
  props<{ payload: CustomerDashboardResponse | undefined }>()
);

export const loadCustomerOfferData = createAction('[CustomerDashboard] Load customer offer data');

export const setCustomerOfferData = createAction(
  '[CustomerDashboard] Set customer offer data',
  props<{ payload: CustomerOfferListItemResponse[] | undefined }>()
);

export const errorCustomerDashboard = createAction('[CustomerDashboard] Error customer dashboard');
