import { Component, Input } from '@angular/core';
import { BaseImagePlaceholderEnum } from '../../enums/base-image-placeholder.enum';

/**
 * @enum
 * BaseAvatarSize defines possible values for size property
 */
export enum BaseAvatarSize {
  esmall = 'esmall',
  small = 'small',
  intermediate = 'intermediate',
  medium = 'medium',
  large = 'large',
  xlarge = 'xlarge',
}

/**
 * BaseAvatarComponent is used to display users and companies logos
 *
 *
 * @example
 * <base-avatar
 *   [size]="avatarSize.large"
 *   [image]="currentAssignment?.profile?.avatar"
 *   [avatarClasses]="['cursor-auto']"
 * ></base-avatar>
 */
@Component({
  selector: 'base-avatar',
  templateUrl: './base-avatar.component.html',
})
export class BaseAvatarComponent {
  /**
   * Defines if user or company logo will be displayed
   */
  @Input() isCompany = false;

  /**
   * Defines image to be displayed
   */
  @Input() image: string = '';

  /**
   * Defines displayed image size
   */
  @Input() size: BaseAvatarSize = BaseAvatarSize.large;

  /**
   * Defines extra classes that can be applied for avatar
   */
  @Input() avatarClasses: string[] = [];

  /**
   * Applies extra classes for avatar
   */
  get classes(): string[] {
    let classes = ['avatar', `avatar-${this.size}`, ...this.avatarClasses];
    if (this.isCompany) {
      classes.push('company-logo');
    }
    return classes;
  }

  /**
   * Returns background image URL or default image URL
   */
  get background(): string {
    if (this.image) {
      return this.image;
    }

    return this.isCompany
      ? BaseImagePlaceholderEnum.BASE_DEFAULT_AVATAR_COMPANY
      : BaseImagePlaceholderEnum.BASE_DEFAULT_AVATAR;
  }
}
