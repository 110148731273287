import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator } from '@angular/forms';
import { DropdownPosition, NgSelectComponent, NgSelectConfig } from '@ng-select/ng-select';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime, distinctUntilChanged, switchMap, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DropdownFoundation } from '../dropdown-foundation';

@Component({
  selector: 'base-dropdown',
  templateUrl: './base-dropdown.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BaseDropdownComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => BaseDropdownComponent),
      multi: true,
    },
  ],
})
/**
 * **Main dropdown component** <br/>
 * Inputs available:
 *  - items (required)
 *  - translateItems
 *  - multiple
 *  - bindOptionLabel
 *  - bindOptionSubLabel
 *  - bindValueLabel
 *  - searchable
 *  - requiredAnother
 *  - baseFieldValidationArgs
 *  - preview
 *  - label
 *  - requiredLabel
 *  - showSelected
 *  - placement
 *  - outputModel
 *  - combineLabel
 *  - customLayout
 *  <br/>
 *  Please see description of each input to read about usage information
 */
export class BaseDropdownComponent
  extends DropdownFoundation
  implements ControlValueAccessor, Validator, OnChanges, OnInit, OnDestroy
{
  @ViewChild('selectVariant1') selectVariant1: NgSelectComponent;
  @ViewChild('selectVariant2') selectVariant2: NgSelectComponent;
  /**
   * Items to be handed into dropdown options
   */
  @Input() set items(items: any[]) {
    this._items = items;

    if (!this.virtualScroll) {
      this.itemsBuffer = this._items;
      return;
    }

    if (this._items?.length) {
      this.itemsBuffer = this._items.slice(0, this.itemsBufferSize);
    }
  }
  /**
   * Provides that items of dropdown options will be translated <br/>
   * ###Attention: is not usable with combineLabel and customLayout
   */
  @Input() translateItems = false;
  /**
   * Makes checkboxes available; <br/>
   * default = false
   */
  @Input() multiple: boolean = false;
  /**
   * Binding label of dropdown items to a specific data structure; <br/>
   * default = 'name'
   */
  @Input() bindOptionLabel: string = 'name';
  /**
   * bindOptionSubLabel to display sub label as custom field from object; <br/>
   * default = undefined
   */
  @Input() bindOptionSubLabel: string;
  /**
   * Binding value of dropdown items to a specific data structure; <br/>
   * default = 'id'
   */
  @Input() bindOptionValue: string = 'id';
  /**
   * Placeholder string; <br/>
   * default = ''
   */
  @Input() placeholder: string = '';
  /**
   * Is search available?; <br/>
   * default = true
   */
  @Input() searchable: boolean = true;
  /**
   * Another input required first <br/>
   * Hand in the translation string you want to be displayed and the display condition as a tuple <br/>
   * Example: ['fillInDepartmentFirst', !form.get('customerDepartment').value] <br/>
   * Default = ['', false]
   */
  @Input() requiredAnother: [string, boolean] = ['', false];
  /**
   * Args for baseFieldValidation pipe <br/>
   * Be aware that every string you hand in here, has to be a key in the errors object of the pipe
   * and only will be a replacement of the required validator
   */
  @Input() baseFieldValidationArgs: string = 'required';
  /**
   * Enable preview, disable select <br/> default = false
   */
  @Input() preview = false;
  /**
   * Add label above the select field
   */
  @Input() label: string;
  /**
   * Adds required class to label (which makes the red asterisk appear behind it); <br/>
   * default = false
   */
  @Input() requiredLabel = false;
  /**
   * Show selected in dropdown select; <br/>
   * default = true;
   */
  @Input() showSelected = true;
  /**
   * Defines where to place the dropdown statically <br/>
   * default = 'auto'
   */
  @Input() placement: DropdownPosition = 'auto';
  /**
   * Defines the output that should be sent as change to the original form<br/>
   * Currently it is only usable for one property e.g. 'id'
   */
  @Input() outputModel: string;
  /**
   * combines two labels of data model <br/>
   * replaces label of bindOptionLabel; never use both together <br/>
   * suitable for e.g. firstName and lastName <br/>
   * implementation: item[combineLabel[0]] + ' ' + item[combineLabel[1]] <br/>
   * ###Attention: is not usable with translatableItems and customLayout
   */
  @Input() combineLabel: [string, string];
  /**
   * Apply custom layout for displaying avatar and extra information <br/>
   * Currently this custom layout is only usable for configuration: <br/>
   * item.firstName, item.lastName, item.position and item.avatar
   * default = false; <br/>
   * ###Attention: is not usable with combineLabel and translatableItems
   */
  @Input() customLayout = false;
  /**
   * Tab index
   */
  @Input() tabIndex = 0;
  /**
   * Set this to true activates the ng-select virtual scroll
   * @link https://ng-select.github.io/ng-select#/virtual-scroll
   */
  @Input() virtualScroll = false;
  /**
   * Defines the search property.
   * This can be a string only (default) or any object property like "name" for example.
   * It is also possible to pass a comma separated string (without spaces). In this case
   * each string represents a property. Search will try to match those values in the
   * given order.
   */
  @Input() searchProperty: string = 'name';

  @Input() markFavourites = false;

  @Input() isInvalid = false;

  @Input() isValidationMessageHidden = false;

  /**
   * Parameter that is used in addition to customLayout flag <br/>
   * For a specific layout in case of assignment selection when add new assignment date <br/>
   */
  @Input() isAssignmentView: boolean = false;

  @Output() search = new EventEmitter<any>();

  get items() {
    return this._items;
  }

  /**
   * Buffer for virtual scrolling of ng select. This becomes relevant when <br/>
   * "virtualScroll" property is true.
   * In this case this is the data added to the items property of ng-select.
   * Array of data will increase while scrolling.
   * @private
   */
  itemsBuffer: any[] = [];

  _items: any[] = [];

  searchSubject$ = new Subject<string>();

  /**
   * The amount of items, that will be loaded when "virtualScroll" property is<br/>
   * set to true and scrolling reaches the zone of property "numberOfItemsFromEndBeforeFetchingMore".
   * @private
   */
  private itemsBufferSize = 30;

  /**
   * If property "virtualScroll" is set to true, this value represents for the<br/>
   * buffer zone items when additional data will be added to the list of items.
   * @private
   */
  private numberOfItemsFromEndBeforeFetchingMore = 10;

  /**
   * Loading status for virtual scrolling.
   * @private
   */
  private loadVirtualScrolling = false;

  private destroy$ = new Subject<void>();

  constructor(protected ngSelectConfig: NgSelectConfig, protected translate: TranslateService) {
    super(ngSelectConfig, translate);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.firstChange && changes.isInvalid) {
      this.setNgClass(changes.isInvalid.currentValue);
    }
  }

  ngOnInit() {
    if (!this.virtualScroll) {
      this.itemsBufferSize = 200;
    }

    this.searchHandler();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  change(value: any) {
    if (this.outputModel && Array.isArray(value)) {
      const tmpArray: any[] = [];
      value.forEach((entry) => {
        tmpArray.push(entry[this.outputModel] ? entry[this.outputModel] : entry);
      });
      this._onChange(tmpArray);
    } else if (this.outputModel) {
      this._onChange(value[this.outputModel]);
    } else {
      this._onChange(value);
    }

    this._onTouch();
    this._onChangeValidation();
  }

  findItemByKeyValue(key: string, value: number | string, outputKey: string) {
    return this._items.find((item) => item[key] === value)[outputKey];
  }

  onScroll(end: number) {
    if (this.virtualScroll || this.loadVirtualScrolling || this.items.length <= this.itemsBuffer.length) {
      return;
    }

    if (end + this.numberOfItemsFromEndBeforeFetchingMore >= this.itemsBuffer.length) {
      this.fetchMoreItems();
    }
  }

  fetchMoreItems(searchValue: string = '') {
    const fetchedItems = this.fetchMore(
      this._items,
      this.itemsBuffer,
      this.itemsBufferSize,
      this.searchProperty,
      searchValue
    );
    this.loadVirtualScrolling = true;
    setTimeout(() => {
      this.loadVirtualScrolling = false;
      this.itemsBuffer = this.itemsBuffer.concat(fetchedItems);
    }, 200);
  }

  private searchHandler() {
    this.searchSubject$
      .pipe(
        takeUntil(this.destroy$),
        debounceTime(200),
        distinctUntilChanged(),
        switchMap((searchString) =>
          this.searchService(this._items, searchString ? searchString : '', this.searchProperty)
        )
      )
      .subscribe((data) => {
        this.itemsBuffer = data.slice(0, this.itemsBufferSize);
      });
  }

  private setNgClass(isInvalid: boolean): void {
    if (isInvalid) {
      if (this.selectVariant1) {
        this.selectVariant1.element.classList.add('ng-invalid');
        this.selectVariant1.element.classList.remove('ng-valid');
      }
      if (this.selectVariant2) {
        this.selectVariant2.element.classList.add('ng-invalid');
        this.selectVariant2.element.classList.remove('ng-valid');
      }
    } else {
      if (this.selectVariant1) {
        this.selectVariant1.element.classList.add('ng-valid');
        this.selectVariant1.element.classList.remove('ng-invalid');
      }
      if (this.selectVariant2) {
        this.selectVariant2.element.classList.add('ng-valid');
        this.selectVariant2.element.classList.remove('ng-invalid');
      }
    }
  }
}
