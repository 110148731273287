/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { AuthService } from './services/auth.service';
import { CatalogService } from './services/catalog.service';
import { CustomerAssignmentService } from './services/customer-assignment.service';
import { CustomerPspService } from './services/customer-psp.service';
import { CustomerService } from './services/customer.service';
import { CustomerDepartmentService } from './services/customer-department.service';
import { CustomerInquiryService } from './services/customer-inquiry.service';
import { CustomerLocationService } from './services/customer-location.service';
import { CustomerLocationDepartmentService } from './services/customer-location-department.service';
import { CustomerLocationDepartmentRequirementService } from './services/customer-location-department-requirement.service';
import { CustomerLocationSurchargeService } from './services/customer-location-surcharge.service';
import { CustomerTerminalService } from './services/customer-terminal.service';
import { DashboardService } from './services/dashboard.service';
import { SigningService } from './services/signing.service';
import { LogService } from './services/log.service';
import { PspAssignmentService } from './services/psp-assignment.service';
import { PspBranchService } from './services/psp-branch.service';
import { CandidateProfileService } from './services/candidate-profile.service';
import { PspService } from './services/psp.service';
import { PspCustomerService } from './services/psp-customer.service';
import { PspInquiryService } from './services/psp-inquiry.service';
import { PspOfferService } from './services/psp-offer.service';
import { PspCustomerLocationService } from './services/psp-customer-location.service';
import { PspSurchargeService } from './services/psp-surcharge.service';
import { UserService } from './services/user.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    AuthService,
    CatalogService,
    CustomerAssignmentService,
    CustomerPspService,
    CustomerService,
    CustomerDepartmentService,
    CustomerInquiryService,
    CustomerLocationService,
    CustomerLocationDepartmentService,
    CustomerLocationDepartmentRequirementService,
    CustomerLocationSurchargeService,
    CustomerTerminalService,
    DashboardService,
    SigningService,
    LogService,
    PspAssignmentService,
    PspBranchService,
    CandidateProfileService,
    PspService,
    PspCustomerService,
    PspInquiryService,
    PspOfferService,
    PspCustomerLocationService,
    PspSurchargeService,
    UserService,
    ApiConfiguration
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
