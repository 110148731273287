import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import {
  BaseCoreState,
  BaseEnvironmentService,
  BaseLocalStorageService,
  NotificationsListResponse,
  baseSharedActions,
  baseSharedSelectors,
  baseAuthSelectors,
  BaseTokenInterface,
} from 'base';
import { ActivatedRoute, Data, NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { DOCUMENT } from '@angular/common';
import { environment } from '../environments/environment';
import { filter, map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'growth-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  env = environment;
  previousUrl: string;
  currentUrl: string;
  unreadNotifications: number;
  checkForUpdates = false;
  private destroy$ = new Subject();

  constructor(
    private translateService: TranslateService,
    private ngbTooltipConfig: NgbTooltipConfig,
    private localStorageService: BaseLocalStorageService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private store: Store<BaseCoreState>,
    private environmentService: BaseEnvironmentService,
    private translate: TranslateService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit(): void {
    const defaultLanguage = this.environmentService.getValue('defaultLanguage');
    // this language will be used as a fallback when a translation isn't found in the current language
    this.translateService.setDefaultLang(defaultLanguage);

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    const language = this.localStorageService.get('language') as string;
    this.store.dispatch(baseSharedActions.setLanguage({ language: language || defaultLanguage }));

    // settings for tooltip
    this.ngbTooltipConfig.tooltipClass = 'tooltip';
    this.ngbTooltipConfig.triggers = 'hover';
    this.ngbTooltipConfig.container = 'body';

    this.router.events
      .pipe(
        takeUntil(this.destroy$),
        filter((event) => event instanceof NavigationEnd)
      )
      .subscribe((event) => {
        this.setApplicationTitle();
        this.previousUrl = this.currentUrl;
        this.currentUrl = (event as NavigationEnd).url;
        this.store.dispatch(
          baseSharedActions.setCurrentAndPreviousRouteUrls({
            payload: {
              currentUrl: this.currentUrl,
              previousUrl: this.previousUrl,
            },
          })
        );
      });

    this.store
      .select(baseSharedSelectors.getUserNotifications)
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ data }: { data: NotificationsListResponse | undefined }) => {
        this.unreadNotifications = data?.unRead || 0;
        this.setApplicationTitle();
      });

    this.store
      .select(baseAuthSelectors.getUserInfo)
      .pipe(
        takeUntil(this.destroy$),
        map((response) => response.data as BaseTokenInterface)
      )
      .subscribe(
        (user) =>
          (this.checkForUpdates =
            (environment.global.production || environment.global.staging || environment.global.demo) && !!user)
      );
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  private setApplicationTitle(): void {
    let currentRoute = this.activatedRoute.root;
    while (currentRoute.children[0] !== undefined) {
      currentRoute = currentRoute.children[0];
    }
    const title = (currentRoute.data as BehaviorSubject<Data>).value.title;
    let pageTitle = 'stazzle growth';
    if (title) {
      pageTitle = this.translateService.instant(title) + ' | ' + pageTitle;
    }
    if (this.unreadNotifications && this.unreadNotifications > 0) {
      document.title = '(' + this.unreadNotifications + ') ' + pageTitle;
    } else {
      document.title = pageTitle;
    }
  }
}
