import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { InquiryResponse, OffersCountResponse, ShiftTimeResponse } from '../../../../../api';
import { BaseAvatarSize } from '../../../../shared/components/avatar/base-avatar.component';
import { BaseButtonSize } from '../../../../shared/components/button/base-button.component';
import { BaseInquiryShifts } from '../../_enums/base-inquiry.enum';
import { BaseInquiryService } from '../../_services/base-inquiry.service';
import { BaseRolesService } from '../../../../core/services/base-roles.service';
import { BASE_PERMISSIONS } from '../../../../core/constants/base-permissions';

@Component({
  selector: 'base-inquiry-details-sidebar',
  templateUrl: './inquiry-details-sidebar.component.html',
  styleUrls: ['./inquiry-details-sidebar.component.scss'],
})
export class InquiryDetailsSidebarComponent implements OnChanges, OnInit {
  @ViewChild('sidebarContent', { static: true }) sidebarContent: ElementRef;
  @Input() inquiry: InquiryResponse;
  @Input() offersCount: OffersCountResponse | undefined;
  @Input() shiftTimes: (ShiftTimeResponse & { index: number; label?: string })[] = [];
  @Input() isSidebarVisible = false;
  @Input() widgetsTemplate: TemplateRef<any>;
  @Input() inquiryId: number;
  @Output() hideSidebar: EventEmitter<boolean> = new EventEmitter<boolean>();

  isPsp: boolean;
  canPreselectOffers: boolean;
  avatarSize = BaseAvatarSize;
  buttonSize = BaseButtonSize;
  inquiryShiftTypes = BaseInquiryShifts;

  constructor(
    private offcanvasService: NgbOffcanvas,
    private baseInquiryService: BaseInquiryService,
    private rolesService: BaseRolesService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.isSidebarVisible) {
      const isSidebarVisible = changes.isSidebarVisible?.currentValue;

      isSidebarVisible ? this.openInfoOffcanvas(this.sidebarContent) : this.offcanvasService.dismiss();
    }
  }

  ngOnInit() {
    this.isPsp = this.rolesService.isPsp();
    this.canPreselectOffers = this.rolesService.hasCustomerFeaturePermission(BASE_PERMISSIONS.FEATURE_PRESELECT_OFFERS);
  }

  private openInfoOffcanvas(content: any): void {
    this.offcanvasService
      .open(content, {
        ariaLabelledBy: 'offcanvas-basic-title',
        position: 'end',
        panelClass: 'right-content-offcanvas',
      })
      .result.then(
        () => {},
        () => {
          this.baseInquiryService.toggleInquirySidebarSubject$.next(false);
          this.hideSidebar.emit(false);
        }
      );
  }
}
