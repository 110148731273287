<div
  *ngIf="value"
  class="document-info teaser bg-lightest mb-0"
  [ngClass]="documentControl && documentControl.invalid ? 'border-danger' : 'border-0'"
>
  <div class="col-8 d-flex">
    <i
      class="fa-solid fs-4 me-3"
      [ngClass]="value.type === availableFileTypes.applicationPDF ? 'fa-file-pdf' : 'fa-file-image'"
    ></i>
    <span>{{ value.name }}</span>
  </div>
  <div class="col-2">
    {{ value.size | baseFilesize }}
  </div>
  <div class="col-2 d-flex justify-content-end">
    <ng-container>
      <base-button
        icon="fa-solid fa-eye"
        color="dark"
        [iconSize]="buttonSize.md"
        stateClass="pe-1"
        class="mx-2"
        tooltip="document.view"
        [disabled]="documentControl.hasError('fileTypeInvalid')"
        (btnClick)="viewDocumentClicked.emit(editCandidate)"
      ></base-button>
      <base-button
        icon="fa-solid fa-trash"
        color="dark"
        [iconSize]="buttonSize.md"
        stateClass="pe-1"
        tooltip="action.delete"
        (btnClick)="deleteClicked.emit()"
        *ngIf="!editCandidate"
      ></base-button>
    </ng-container>
  </div>
</div>
<p
  *ngIf="documentControl && documentControl.invalid"
  class="text-danger"
>
  {{ errorMessage | translate: { maxSize: '5' } }}
</p>
