import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AuthService } from '../../../api';
import { BaseHttpErrorService } from '../services/base-http-error.service';
import { baseAuthActions, BaseCoreState } from '../../_store';
import { HttpErrorStatus } from '../../shared/enums/http-error-status.enum';

@Injectable()
export class BaseHttpErrorsInterceptor implements HttpInterceptor {
  constructor(
    private httpErrorService: BaseHttpErrorService,
    private store: Store<BaseCoreState>,
    public router: Router
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap({
        next: (event: HttpEvent<any>) => {
          // Do nothing on success
        },
        error: (httpErrorResponse: HttpErrorResponse) => {
          if (!req.url.includes(AuthService.PostAppApiAuthAuthTokenrefreshingPath)) {
            this.httpErrorService.handle(httpErrorResponse);
          }
          if (
            req.url.includes(AuthService.PostAppApiAuthAuthTokenrefreshingPath) &&
            httpErrorResponse.status === HttpErrorStatus.Unauthorized
          ) {
            this.store.dispatch(baseAuthActions.logout());
          }
          if (httpErrorResponse.status === HttpErrorStatus.NotFound) {
            this.httpErrorService.handle(httpErrorResponse);
          }
        },
      })
    );
  }
}
