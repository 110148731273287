/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ApplyRequest } from '../models/apply-request';
import { AvatarUpdateRequest } from '../models/avatar-update-request';
import { CreatedResponse } from '../models/created-response';
import { EmptySuccessfulResponse } from '../models/empty-successful-response';
import { NotificationRecipientsUpdateRequest } from '../models/notification-recipients-update-request';
import { NotificationsListResponse } from '../models/notifications-list-response';
import { PaginatedApiResponse } from '../models/paginated-api-response';
import { ProfilePasswordUpdateRequest } from '../models/profile-password-update-request';
import { ResetRequest } from '../models/reset-request';
import { UserProfileResponse } from '../models/user-profile-response';
import { UserProfileSettingsUpdateRequest } from '../models/user-profile-settings-update-request';
import { UserProfileUpdateRequest } from '../models/user-profile-update-request';
import { UserRegisterCheckResponse } from '../models/user-register-check-response';
import { UserRegistrationRequest } from '../models/user-registration-request';

@Injectable({
  providedIn: 'root',
})
export class UserService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation postAppApiWebV1UserResetpasswordReset
   */
  static readonly PostAppApiWebV1UserResetpasswordResetPath = '/api/web/v1/users/password-resetting';

  /**
   * Send password reset request.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postAppApiWebV1UserResetpasswordReset()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postAppApiWebV1UserResetpasswordReset$Response(params: {
    context?: HttpContext
    body: ResetRequest
  }
): Observable<StrictHttpResponse<EmptySuccessfulResponse>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.PostAppApiWebV1UserResetpasswordResetPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmptySuccessfulResponse>;
      })
    );
  }

  /**
   * Send password reset request.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postAppApiWebV1UserResetpasswordReset$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postAppApiWebV1UserResetpasswordReset(params: {
    context?: HttpContext
    body: ResetRequest
  }
): Observable<EmptySuccessfulResponse> {

    return this.postAppApiWebV1UserResetpasswordReset$Response(params).pipe(
      map((r: StrictHttpResponse<EmptySuccessfulResponse>) => r.body as EmptySuccessfulResponse)
    );
  }

  /**
   * Path part for operation getApply
   */
  static readonly GetApplyPath = '/api/web/v1/users/password-resetting/{token}';

  /**
   * Check if password reset request is valid.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getApply()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApply$Response(params: {
    token: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EmptySuccessfulResponse>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.GetApplyPath, 'get');
    if (params) {
      rb.path('token', params.token, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmptySuccessfulResponse>;
      })
    );
  }

  /**
   * Check if password reset request is valid.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getApply$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApply(params: {
    token: string;
    context?: HttpContext
  }
): Observable<EmptySuccessfulResponse> {

    return this.getApply$Response(params).pipe(
      map((r: StrictHttpResponse<EmptySuccessfulResponse>) => r.body as EmptySuccessfulResponse)
    );
  }

  /**
   * Path part for operation putAppApiWebV1UserResetpasswordApply
   */
  static readonly PutAppApiWebV1UserResetpasswordApplyPath = '/api/web/v1/users/password-resetting/{token}';

  /**
   * Apply new password on password reset request.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putAppApiWebV1UserResetpasswordApply()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putAppApiWebV1UserResetpasswordApply$Response(params: {
    token: string;
    context?: HttpContext
    body: ApplyRequest
  }
): Observable<StrictHttpResponse<EmptySuccessfulResponse>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.PutAppApiWebV1UserResetpasswordApplyPath, 'put');
    if (params) {
      rb.path('token', params.token, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmptySuccessfulResponse>;
      })
    );
  }

  /**
   * Apply new password on password reset request.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putAppApiWebV1UserResetpasswordApply$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putAppApiWebV1UserResetpasswordApply(params: {
    token: string;
    context?: HttpContext
    body: ApplyRequest
  }
): Observable<EmptySuccessfulResponse> {

    return this.putAppApiWebV1UserResetpasswordApply$Response(params).pipe(
      map((r: StrictHttpResponse<EmptySuccessfulResponse>) => r.body as EmptySuccessfulResponse)
    );
  }

  /**
   * Path part for operation putAppApiWebV1UserSettingUpdateIndex
   */
  static readonly PutAppApiWebV1UserSettingUpdateIndexPath = '/api/web/v1/users/profile/settings';

  /**
   * Update an user profile settings.
   *
   * **Accessible only for users with roles 'ROLE_PSP_USER', 'ROLE_CUSTOMER_USER'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putAppApiWebV1UserSettingUpdateIndex()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putAppApiWebV1UserSettingUpdateIndex$Response(params: {
    context?: HttpContext
    body: UserProfileSettingsUpdateRequest
  }
): Observable<StrictHttpResponse<EmptySuccessfulResponse>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.PutAppApiWebV1UserSettingUpdateIndexPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmptySuccessfulResponse>;
      })
    );
  }

  /**
   * Update an user profile settings.
   *
   * **Accessible only for users with roles 'ROLE_PSP_USER', 'ROLE_CUSTOMER_USER'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putAppApiWebV1UserSettingUpdateIndex$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putAppApiWebV1UserSettingUpdateIndex(params: {
    context?: HttpContext
    body: UserProfileSettingsUpdateRequest
  }
): Observable<EmptySuccessfulResponse> {

    return this.putAppApiWebV1UserSettingUpdateIndex$Response(params).pipe(
      map((r: StrictHttpResponse<EmptySuccessfulResponse>) => r.body as EmptySuccessfulResponse)
    );
  }

  /**
   * Path part for operation getUsersRegistrationCheck
   */
  static readonly GetUsersRegistrationCheckPath = '/api/web/v1/users/register/{tokenString}';

  /**
   * Check invitation link is valid.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUsersRegistrationCheck()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUsersRegistrationCheck$Response(params: {
    tokenString: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<UserRegisterCheckResponse>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.GetUsersRegistrationCheckPath, 'get');
    if (params) {
      rb.path('tokenString', params.tokenString, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserRegisterCheckResponse>;
      })
    );
  }

  /**
   * Check invitation link is valid.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUsersRegistrationCheck$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUsersRegistrationCheck(params: {
    tokenString: string;
    context?: HttpContext
  }
): Observable<UserRegisterCheckResponse> {

    return this.getUsersRegistrationCheck$Response(params).pipe(
      map((r: StrictHttpResponse<UserRegisterCheckResponse>) => r.body as UserRegisterCheckResponse)
    );
  }

  /**
   * Path part for operation postUsersRegistrationSubmit
   */
  static readonly PostUsersRegistrationSubmitPath = '/api/web/v1/users';

  /**
   * Invited user registration.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postUsersRegistrationSubmit()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postUsersRegistrationSubmit$Response(params: {
    context?: HttpContext
    body: UserRegistrationRequest
  }
): Observable<StrictHttpResponse<CreatedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.PostUsersRegistrationSubmitPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CreatedResponse>;
      })
    );
  }

  /**
   * Invited user registration.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postUsersRegistrationSubmit$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postUsersRegistrationSubmit(params: {
    context?: HttpContext
    body: UserRegistrationRequest
  }
): Observable<CreatedResponse> {

    return this.postUsersRegistrationSubmit$Response(params).pipe(
      map((r: StrictHttpResponse<CreatedResponse>) => r.body as CreatedResponse)
    );
  }

  /**
   * Path part for operation getNotificationsGetList
   */
  static readonly GetNotificationsGetListPath = '/api/web/v1/users/notifications';

  /**
   * Get list of notifications.
   *
   * **Accessible only for users with roles 'ROLE_PSP_USER', 'ROLE_CUSTOMER_USER'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getNotificationsGetList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNotificationsGetList$Response(params?: {

    /**
     * Page number in pagination.
     */
    page?: any;

    /**
     * Size number in pagination.
     */
    size?: any;

    /**
     * Filter active or unread notifications. Possible values: true, false
     */
    isRead?: any;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<PaginatedApiResponse & {
'data'?: Array<NotificationsListResponse>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.GetNotificationsGetListPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('size', params.size, {});
      rb.query('isRead', params.isRead, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaginatedApiResponse & {
        'data'?: Array<NotificationsListResponse>;
        }>;
      })
    );
  }

  /**
   * Get list of notifications.
   *
   * **Accessible only for users with roles 'ROLE_PSP_USER', 'ROLE_CUSTOMER_USER'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getNotificationsGetList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNotificationsGetList(params?: {

    /**
     * Page number in pagination.
     */
    page?: any;

    /**
     * Size number in pagination.
     */
    size?: any;

    /**
     * Filter active or unread notifications. Possible values: true, false
     */
    isRead?: any;
    context?: HttpContext
  }
): Observable<PaginatedApiResponse & {
'data'?: Array<NotificationsListResponse>;
}> {

    return this.getNotificationsGetList$Response(params).pipe(
      map((r: StrictHttpResponse<PaginatedApiResponse & {
'data'?: Array<NotificationsListResponse>;
}>) => r.body as PaginatedApiResponse & {
'data'?: Array<NotificationsListResponse>;
})
    );
  }

  /**
   * Path part for operation putNotificationsChangeReadStatus
   */
  static readonly PutNotificationsChangeReadStatusPath = '/api/web/v1/users/notifications/read-state';

  /**
   * Change notifications statuses as read.
   *
   * **Accessible only for users with roles 'ROLE_PSP_USER', 'ROLE_CUSTOMER_USER'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putNotificationsChangeReadStatus()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putNotificationsChangeReadStatus$Response(params: {
    context?: HttpContext
    body: NotificationRecipientsUpdateRequest
  }
): Observable<StrictHttpResponse<EmptySuccessfulResponse>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.PutNotificationsChangeReadStatusPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmptySuccessfulResponse>;
      })
    );
  }

  /**
   * Change notifications statuses as read.
   *
   * **Accessible only for users with roles 'ROLE_PSP_USER', 'ROLE_CUSTOMER_USER'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putNotificationsChangeReadStatus$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putNotificationsChangeReadStatus(params: {
    context?: HttpContext
    body: NotificationRecipientsUpdateRequest
  }
): Observable<EmptySuccessfulResponse> {

    return this.putNotificationsChangeReadStatus$Response(params).pipe(
      map((r: StrictHttpResponse<EmptySuccessfulResponse>) => r.body as EmptySuccessfulResponse)
    );
  }

  /**
   * Path part for operation postNotificationsChangeAllReadStatus
   */
  static readonly PostNotificationsChangeAllReadStatusPath = '/api/web/v1/users/notifications/read-state';

  /**
   * Change All the notifications statuses as read.
   *
   * **Accessible only for users with roles 'ROLE_PSP_USER', 'ROLE_CUSTOMER_USER'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postNotificationsChangeAllReadStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  postNotificationsChangeAllReadStatus$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EmptySuccessfulResponse>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.PostNotificationsChangeAllReadStatusPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmptySuccessfulResponse>;
      })
    );
  }

  /**
   * Change All the notifications statuses as read.
   *
   * **Accessible only for users with roles 'ROLE_PSP_USER', 'ROLE_CUSTOMER_USER'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postNotificationsChangeAllReadStatus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  postNotificationsChangeAllReadStatus(params?: {
    context?: HttpContext
  }
): Observable<EmptySuccessfulResponse> {

    return this.postNotificationsChangeAllReadStatus$Response(params).pipe(
      map((r: StrictHttpResponse<EmptySuccessfulResponse>) => r.body as EmptySuccessfulResponse)
    );
  }

  /**
   * Path part for operation getAppApiWebV1UserUserprofileShow
   */
  static readonly GetAppApiWebV1UserUserprofileShowPath = '/api/web/v1/users/profile';

  /**
   * Get user profile information from logged in user.
   *
   * **Accessible only for users with roles 'ROLE_PSP_USER', 'ROLE_CUSTOMER_USER'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAppApiWebV1UserUserprofileShow()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAppApiWebV1UserUserprofileShow$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<{
'message'?: string;
'data'?: UserProfileResponse;
}>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.GetAppApiWebV1UserUserprofileShowPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'message'?: string;
        'data'?: UserProfileResponse;
        }>;
      })
    );
  }

  /**
   * Get user profile information from logged in user.
   *
   * **Accessible only for users with roles 'ROLE_PSP_USER', 'ROLE_CUSTOMER_USER'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAppApiWebV1UserUserprofileShow$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAppApiWebV1UserUserprofileShow(params?: {
    context?: HttpContext
  }
): Observable<{
'message'?: string;
'data'?: UserProfileResponse;
}> {

    return this.getAppApiWebV1UserUserprofileShow$Response(params).pipe(
      map((r: StrictHttpResponse<{
'message'?: string;
'data'?: UserProfileResponse;
}>) => r.body as {
'message'?: string;
'data'?: UserProfileResponse;
})
    );
  }

  /**
   * Path part for operation putAppApiWebV1UserUserprofileUpdate
   */
  static readonly PutAppApiWebV1UserUserprofileUpdatePath = '/api/web/v1/users/profile';

  /**
   * Update logged in user profile.
   *
   * **Accessible only for users with roles 'ROLE_PSP_USER', 'ROLE_CUSTOMER_USER'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putAppApiWebV1UserUserprofileUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putAppApiWebV1UserUserprofileUpdate$Response(params: {
    context?: HttpContext
    body: UserProfileUpdateRequest
  }
): Observable<StrictHttpResponse<EmptySuccessfulResponse>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.PutAppApiWebV1UserUserprofileUpdatePath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmptySuccessfulResponse>;
      })
    );
  }

  /**
   * Update logged in user profile.
   *
   * **Accessible only for users with roles 'ROLE_PSP_USER', 'ROLE_CUSTOMER_USER'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putAppApiWebV1UserUserprofileUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putAppApiWebV1UserUserprofileUpdate(params: {
    context?: HttpContext
    body: UserProfileUpdateRequest
  }
): Observable<EmptySuccessfulResponse> {

    return this.putAppApiWebV1UserUserprofileUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<EmptySuccessfulResponse>) => r.body as EmptySuccessfulResponse)
    );
  }

  /**
   * Path part for operation putAppApiWebV1UserUserprofileUpdateavatar
   */
  static readonly PutAppApiWebV1UserUserprofileUpdateavatarPath = '/api/web/v1/users/profile/avatar';

  /**
   * Update logged in User Avatar.
   *
   * **Accessible only for users with roles 'ROLE_PSP_USER', 'ROLE_CUSTOMER_USER'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putAppApiWebV1UserUserprofileUpdateavatar()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putAppApiWebV1UserUserprofileUpdateavatar$Response(params: {
    context?: HttpContext
    body: AvatarUpdateRequest
  }
): Observable<StrictHttpResponse<EmptySuccessfulResponse>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.PutAppApiWebV1UserUserprofileUpdateavatarPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmptySuccessfulResponse>;
      })
    );
  }

  /**
   * Update logged in User Avatar.
   *
   * **Accessible only for users with roles 'ROLE_PSP_USER', 'ROLE_CUSTOMER_USER'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putAppApiWebV1UserUserprofileUpdateavatar$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putAppApiWebV1UserUserprofileUpdateavatar(params: {
    context?: HttpContext
    body: AvatarUpdateRequest
  }
): Observable<EmptySuccessfulResponse> {

    return this.putAppApiWebV1UserUserprofileUpdateavatar$Response(params).pipe(
      map((r: StrictHttpResponse<EmptySuccessfulResponse>) => r.body as EmptySuccessfulResponse)
    );
  }

  /**
   * Path part for operation putAppApiWebV1UserUserprofileUpdatepassword
   */
  static readonly PutAppApiWebV1UserUserprofileUpdatepasswordPath = '/api/web/v1/users/profile/password';

  /**
   * Update for logged in user password.
   *
   * **Accessible only for users with roles 'ROLE_PSP_USER', 'ROLE_CUSTOMER_USER'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putAppApiWebV1UserUserprofileUpdatepassword()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putAppApiWebV1UserUserprofileUpdatepassword$Response(params: {
    context?: HttpContext
    body: ProfilePasswordUpdateRequest
  }
): Observable<StrictHttpResponse<EmptySuccessfulResponse>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.PutAppApiWebV1UserUserprofileUpdatepasswordPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmptySuccessfulResponse>;
      })
    );
  }

  /**
   * Update for logged in user password.
   *
   * **Accessible only for users with roles 'ROLE_PSP_USER', 'ROLE_CUSTOMER_USER'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putAppApiWebV1UserUserprofileUpdatepassword$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putAppApiWebV1UserUserprofileUpdatepassword(params: {
    context?: HttpContext
    body: ProfilePasswordUpdateRequest
  }
): Observable<EmptySuccessfulResponse> {

    return this.putAppApiWebV1UserUserprofileUpdatepassword$Response(params).pipe(
      map((r: StrictHttpResponse<EmptySuccessfulResponse>) => r.body as EmptySuccessfulResponse)
    );
  }

}
