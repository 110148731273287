import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  baseAdministrationActions,
  baseAuthActions,
  BaseCoreState,
  baseFormsActions,
  baseSharedActions,
} from '../../_store';
import { BaseToastAlertClasses } from '../../shared/enums/base-toast-alert-classes.enum';
import { BaseTokenService } from './base-token.service';
import { BASE_CUSTOM_ERROR_ALERTS } from '../constants/base-custom-error-alerts';
import { BaseRolesService } from './base-roles.service';
import { HttpErrorStatus } from '../../shared/enums/http-error-status.enum';
import { BaseModalService } from '../../shared/services/base-modal.service';

export const SYSTEM_PAGE_PATHES = [
  { code: HttpErrorStatus.Unauthorized, path: '/auth/login' },
  { code: HttpErrorStatus.Forbidden, path: '/403' },
];

@Injectable()
export class BaseHttpErrorService {
  constructor(
    public router: Router,
    private tokenService: BaseTokenService,
    private rolesService: BaseRolesService,
    private modalService: BaseModalService,
    private store: Store<BaseCoreState>
  ) {}

  handle(errorResponse: HttpErrorResponse): void {
    if (SYSTEM_PAGE_PATHES.some((item) => item.code === errorResponse.status)) {
      if (errorResponse.status === HttpErrorStatus.Unauthorized) {
        if (
          !!this.tokenService.getToken() &&
          !this.tokenService.isValid(this.tokenService.getToken()) &&
          this.tokenService.isRefreshTokenValid()
        ) {
          this.store.dispatch(baseAuthActions.refreshUser());
        } else {
          this.refreshDataAndNavigateToLogin();

          this.store.dispatch(
            baseSharedActions.addUniqueSystemAlert({
              payload: {
                class: BaseToastAlertClasses.error,
                body: errorResponse?.error?.message,
                closeManually: false,
                id: errorResponse.status,
                unique: true,
              },
            })
          );
        }
      } else {
        const path = SYSTEM_PAGE_PATHES.filter((item) => item.code === errorResponse.status)[0].path;
        this.router.navigateByUrl(path);
        this.store.dispatch(
          baseSharedActions.addUniqueSystemAlert({
            payload: {
              class: BaseToastAlertClasses.error,
              body: errorResponse?.error?.message,
              id: errorResponse.status,
              unique: true,
            },
          })
        );
      }
    } else if (
      errorResponse.status === HttpErrorStatus.BadRequest ||
      errorResponse.status === HttpErrorStatus.InternalServerError
    ) {
      let message = '';
      const errors = errorResponse?.error?.errors;
      if (Array.isArray(errors)) {
        for (const errMessage of errors) {
          message += errMessage + '\n';
        }
      } else {
        for (const errMessage in errors) {
          message += errors[errMessage].join('\n') + '\n';
        }
      }
      this.store.dispatch(
        baseSharedActions.addUniqueSystemAlert({
          payload: {
            class: BaseToastAlertClasses.error,
            body: message || errorResponse?.error?.message || 'errorAlerts.default',
            id: errorResponse.status,
            unique: true,
          },
        })
      );
    } else if (errorResponse.status === HttpErrorStatus.NotFound) {
      this.router.navigateByUrl('404').then(() => {
        this.store.dispatch(
          baseSharedActions.addUniqueSystemAlert({
            payload: {
              class: BaseToastAlertClasses.error,
              body: errorResponse.error.message || 'errorAlerts.default',
              id: errorResponse.status,
              duration: 10000,
              unique: true,
            },
          })
        );
      });
    } else {
      const customAlert = BASE_CUSTOM_ERROR_ALERTS.find((errorAlert) => errorAlert.status === errorResponse.status);
      if (customAlert) {
        this.store.dispatch(
          baseSharedActions.addUniqueSystemAlert({
            payload: {
              class: BaseToastAlertClasses.error,
              id: errorResponse.status,
              body: customAlert.message,
              unique: true,
            },
          })
        );
      } else {
        this.store.dispatch(
          baseSharedActions.addUniqueSystemAlert({
            payload: {
              class: BaseToastAlertClasses.error,
              body: errorResponse.error.message || 'errorAlerts.default',
              id: errorResponse.status,
              duration: 10000,
              unique: true,
            },
          })
        );
      }
    }
  }

  private refreshDataAndNavigateToLogin(): void {
    this.tokenService.deleteToken();
    this.rolesService.clearRolesAndPermissions();

    this.store.dispatch(baseAuthActions.navigateToLoginPage());

    this.modalService.closeAllModals();

    this.store.dispatch(baseAuthActions.setInitState());
    this.store.dispatch(baseSharedActions.setInitState());
    this.store.dispatch(baseAdministrationActions.setInitState());
    this.store.dispatch(baseFormsActions.clearAllForms());
  }
}
