/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AssignmentCustomerResponse } from '../models/assignment-customer-response';
import { AssignmentDateListItemResponse } from '../models/assignment-date-list-item-response';
import { AssignmentUnplannedListItemResponse } from '../models/assignment-unplanned-list-item-response';
import { CancelationRequest } from '../models/cancelation-request';
import { CreateAssignmentDateRequest } from '../models/create-assignment-date-request';
import { CreatedResponse } from '../models/created-response';
import { CustomerAcceptRequest } from '../models/customer-accept-request';
import { CustomerAssignmentDateListItemResponse } from '../models/customer-assignment-date-list-item-response';
import { CustomerAssignmentFilterListsResponse } from '../models/customer-assignment-filter-lists-response';
import { CustomerAssignmentListItemResponse } from '../models/customer-assignment-list-item-response';
import { CustomerCreateAbsence } from '../models/customer-create-absence';
import { CustomerProfileAssignmentItemResponse } from '../models/customer-profile-assignment-item-response';
import { EmptySuccessfulResponse } from '../models/empty-successful-response';
import { GeneralApiResponse } from '../models/general-api-response';
import { ListApiResponse } from '../models/list-api-response';
import { OrganizationFilterListResponse } from '../models/organization-filter-list-response';
import { PaginatedApiResponse } from '../models/paginated-api-response';
import { PaginatedApiResponseWithFilters } from '../models/paginated-api-response-with-filters';
import { UpdateWorkTimesRequest } from '../models/update-work-times-request';

@Injectable({
  providedIn: 'root',
})
export class CustomerAssignmentService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation postCustomerAssignmentAbsenceCreate
   */
  static readonly PostCustomerAssignmentAbsenceCreatePath = '/api/web/v1/customers/{customer}/assignments/{assignment}/absences';

  /**
   * Create absence.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_ASSIGNMENTS' or 'FEATURE_ADMIN_ASSIGNMENT_DATES'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postCustomerAssignmentAbsenceCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postCustomerAssignmentAbsenceCreate$Response(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Assignment Id.
     */
    assignment: number;
    context?: HttpContext
    body: CustomerCreateAbsence
  }
): Observable<StrictHttpResponse<EmptySuccessfulResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerAssignmentService.PostCustomerAssignmentAbsenceCreatePath, 'post');
    if (params) {
      rb.path('customer', params.customer, {});
      rb.path('assignment', params.assignment, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmptySuccessfulResponse>;
      })
    );
  }

  /**
   * Create absence.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_ASSIGNMENTS' or 'FEATURE_ADMIN_ASSIGNMENT_DATES'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postCustomerAssignmentAbsenceCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postCustomerAssignmentAbsenceCreate(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Assignment Id.
     */
    assignment: number;
    context?: HttpContext
    body: CustomerCreateAbsence
  }
): Observable<EmptySuccessfulResponse> {

    return this.postCustomerAssignmentAbsenceCreate$Response(params).pipe(
      map((r: StrictHttpResponse<EmptySuccessfulResponse>) => r.body as EmptySuccessfulResponse)
    );
  }

  /**
   * Path part for operation postAssignmentAssignmentDateConfirmation
   */
  static readonly PostAssignmentAssignmentDateConfirmationPath = '/api/web/v1/customers/{customer}/assignments/{assignment}/assignment-dates/{assignmentDate}/confirmation';

  /**
   * Confirm the AssignmentDate.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_ASSIGNMENTS'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postAssignmentAssignmentDateConfirmation()` instead.
   *
   * This method doesn't expect any request body.
   */
  postAssignmentAssignmentDateConfirmation$Response(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Assignment Id.
     */
    assignment: number;

    /**
     * AssignmentDate Id.
     */
    assignmentDate: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EmptySuccessfulResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerAssignmentService.PostAssignmentAssignmentDateConfirmationPath, 'post');
    if (params) {
      rb.path('customer', params.customer, {});
      rb.path('assignment', params.assignment, {});
      rb.path('assignmentDate', params.assignmentDate, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmptySuccessfulResponse>;
      })
    );
  }

  /**
   * Confirm the AssignmentDate.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_ASSIGNMENTS'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postAssignmentAssignmentDateConfirmation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  postAssignmentAssignmentDateConfirmation(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Assignment Id.
     */
    assignment: number;

    /**
     * AssignmentDate Id.
     */
    assignmentDate: number;
    context?: HttpContext
  }
): Observable<EmptySuccessfulResponse> {

    return this.postAssignmentAssignmentDateConfirmation$Response(params).pipe(
      map((r: StrictHttpResponse<EmptySuccessfulResponse>) => r.body as EmptySuccessfulResponse)
    );
  }

  /**
   * Path part for operation getCustomerAsignmentAssignmentDatesList
   */
  static readonly GetCustomerAsignmentAssignmentDatesListPath = '/api/web/v1/customers/{customer}/assignments/{assignment}/assignment-dates';

  /**
   * Get a list of all AssignmentDates.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_ASSIGNMENTS'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerAsignmentAssignmentDatesList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerAsignmentAssignmentDatesList$Response(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Assignment Id.
     */
    assignment: number;

    /**
     * Filter Assignments Dates by status.
     */
    status?: 'open' | 'submitted' | 'accepted';
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ListApiResponse & {
'data'?: Array<AssignmentDateListItemResponse>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerAssignmentService.GetCustomerAsignmentAssignmentDatesListPath, 'get');
    if (params) {
      rb.path('customer', params.customer, {});
      rb.path('assignment', params.assignment, {});
      rb.query('status', params.status, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListApiResponse & {
        'data'?: Array<AssignmentDateListItemResponse>;
        }>;
      })
    );
  }

  /**
   * Get a list of all AssignmentDates.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_ASSIGNMENTS'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCustomerAsignmentAssignmentDatesList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerAsignmentAssignmentDatesList(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Assignment Id.
     */
    assignment: number;

    /**
     * Filter Assignments Dates by status.
     */
    status?: 'open' | 'submitted' | 'accepted';
    context?: HttpContext
  }
): Observable<ListApiResponse & {
'data'?: Array<AssignmentDateListItemResponse>;
}> {

    return this.getCustomerAsignmentAssignmentDatesList$Response(params).pipe(
      map((r: StrictHttpResponse<ListApiResponse & {
'data'?: Array<AssignmentDateListItemResponse>;
}>) => r.body as ListApiResponse & {
'data'?: Array<AssignmentDateListItemResponse>;
})
    );
  }

  /**
   * Path part for operation postCustomerAsignmentAssignmentDatesCreate
   */
  static readonly PostCustomerAsignmentAssignmentDatesCreatePath = '/api/web/v1/customers/{customer}/assignments/{assignment}/assignment-dates';

  /**
   * Create AssignmentDates.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_ASSIGNMENT_DATES' or 'FEATURE_ADMIN_ASSIGNMENTS'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postCustomerAsignmentAssignmentDatesCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postCustomerAsignmentAssignmentDatesCreate$Response(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Assignment Id.
     */
    assignment: number;
    context?: HttpContext
    body: CreateAssignmentDateRequest
  }
): Observable<StrictHttpResponse<CreatedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerAssignmentService.PostCustomerAsignmentAssignmentDatesCreatePath, 'post');
    if (params) {
      rb.path('customer', params.customer, {});
      rb.path('assignment', params.assignment, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CreatedResponse>;
      })
    );
  }

  /**
   * Create AssignmentDates.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_ASSIGNMENT_DATES' or 'FEATURE_ADMIN_ASSIGNMENTS'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postCustomerAsignmentAssignmentDatesCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postCustomerAsignmentAssignmentDatesCreate(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Assignment Id.
     */
    assignment: number;
    context?: HttpContext
    body: CreateAssignmentDateRequest
  }
): Observable<CreatedResponse> {

    return this.postCustomerAsignmentAssignmentDatesCreate$Response(params).pipe(
      map((r: StrictHttpResponse<CreatedResponse>) => r.body as CreatedResponse)
    );
  }

  /**
   * Path part for operation putCustomerAssignmentAssignmentDateWorkTimeUpdate
   */
  static readonly PutCustomerAssignmentAssignmentDateWorkTimeUpdatePath = '/api/web/v1/customers/{customer}/assignments/{assignment}/assignment-dates/{assignmentDate}/work-times';

  /**
   * Update assignment date work time.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_ASSIGNMENTS'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putCustomerAssignmentAssignmentDateWorkTimeUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putCustomerAssignmentAssignmentDateWorkTimeUpdate$Response(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Assignment Id.
     */
    assignment: number;

    /**
     * AssignmentDate Id.
     */
    assignmentDate: number;
    context?: HttpContext
    body: UpdateWorkTimesRequest
  }
): Observable<StrictHttpResponse<CreatedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerAssignmentService.PutCustomerAssignmentAssignmentDateWorkTimeUpdatePath, 'put');
    if (params) {
      rb.path('customer', params.customer, {});
      rb.path('assignment', params.assignment, {});
      rb.path('assignmentDate', params.assignmentDate, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CreatedResponse>;
      })
    );
  }

  /**
   * Update assignment date work time.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_ASSIGNMENTS'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putCustomerAssignmentAssignmentDateWorkTimeUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putCustomerAssignmentAssignmentDateWorkTimeUpdate(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Assignment Id.
     */
    assignment: number;

    /**
     * AssignmentDate Id.
     */
    assignmentDate: number;
    context?: HttpContext
    body: UpdateWorkTimesRequest
  }
): Observable<CreatedResponse> {

    return this.putCustomerAssignmentAssignmentDateWorkTimeUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<CreatedResponse>) => r.body as CreatedResponse)
    );
  }

  /**
   * Path part for operation postCustomerAsignmentCancelAssignment
   */
  static readonly PostCustomerAsignmentCancelAssignmentPath = '/api/web/v1/customers/{customer}/assignments/{assignment}/cancelation';

  /**
   * Cancel an Assignment during the cancellation period.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_ASSIGNMENTS'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postCustomerAsignmentCancelAssignment()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postCustomerAsignmentCancelAssignment$Response(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Assignment Id.
     */
    assignment: number;
    context?: HttpContext
    body: CancelationRequest
  }
): Observable<StrictHttpResponse<EmptySuccessfulResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerAssignmentService.PostCustomerAsignmentCancelAssignmentPath, 'post');
    if (params) {
      rb.path('customer', params.customer, {});
      rb.path('assignment', params.assignment, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmptySuccessfulResponse>;
      })
    );
  }

  /**
   * Cancel an Assignment during the cancellation period.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_ASSIGNMENTS'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postCustomerAsignmentCancelAssignment$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postCustomerAsignmentCancelAssignment(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Assignment Id.
     */
    assignment: number;
    context?: HttpContext
    body: CancelationRequest
  }
): Observable<EmptySuccessfulResponse> {

    return this.postCustomerAsignmentCancelAssignment$Response(params).pipe(
      map((r: StrictHttpResponse<EmptySuccessfulResponse>) => r.body as EmptySuccessfulResponse)
    );
  }

  /**
   * Path part for operation getCustomerContractExport
   */
  static readonly GetCustomerContractExportPath = '/api/web/v1/customers/{customer}/assignments/export/contracts';

  /**
   * Export Assignments Contracts.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_ASSIGNMENTS'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerContractExport()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerContractExport$Response(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Search assignment by profile.firstName, profile.lastName, profile.personalId.
     */
    search?: string;

    /**
     * Filter assignments by Main Qualifications ids.
     */
    'mainQualifications[]'?: any;

    /**
     * Filter assignments by Customer Department id.
     */
    department?: number;

    /**
     * Filter assignments by PSP Branches ids.
     */
    'pspBranches[]'?: any;

    /**
     * From date, including. If passed then startDate &gt;&#x3D; fromDate AND endDate &lt;&#x3D; today
     */
    fromDate?: string;

    /**
     * Filter assignments by statuses: planned,active,ended
     */
    'statuses[]'?: any;

    /**
     * Filter assignments by ids
     */
    'assignments[]'?: any;

    /**
     * Filter assignments by open assignment times.
     */
    openAssignmentTimes?: boolean;

    /**
     * Filter assignments by open contracts: psp,customer,signed
     */
    'openContracts[]'?: any;

    /**
     * Order assignments key.
     */
    orderKey?: 'startDate' | 'endDate' | 'createdAt' | 'profileLastName' | 'customerName' | 'mainQualificationName' | 'pspBranchName';

    /**
     * Order direction.
     */
    orderDirection?: 'ASC' | 'DESC';
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerAssignmentService.GetCustomerContractExportPath, 'get');
    if (params) {
      rb.path('customer', params.customer, {});
      rb.query('search', params.search, {});
      rb.query('mainQualifications[]', params['mainQualifications[]'], {});
      rb.query('department', params.department, {});
      rb.query('pspBranches[]', params['pspBranches[]'], {});
      rb.query('fromDate', params.fromDate, {});
      rb.query('statuses[]', params['statuses[]'], {});
      rb.query('assignments[]', params['assignments[]'], {});
      rb.query('openAssignmentTimes', params.openAssignmentTimes, {});
      rb.query('openContracts[]', params['openContracts[]'], {});
      rb.query('orderKey', params.orderKey, {});
      rb.query('orderDirection', params.orderDirection, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/zip',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Export Assignments Contracts.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_ASSIGNMENTS'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCustomerContractExport$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerContractExport(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Search assignment by profile.firstName, profile.lastName, profile.personalId.
     */
    search?: string;

    /**
     * Filter assignments by Main Qualifications ids.
     */
    'mainQualifications[]'?: any;

    /**
     * Filter assignments by Customer Department id.
     */
    department?: number;

    /**
     * Filter assignments by PSP Branches ids.
     */
    'pspBranches[]'?: any;

    /**
     * From date, including. If passed then startDate &gt;&#x3D; fromDate AND endDate &lt;&#x3D; today
     */
    fromDate?: string;

    /**
     * Filter assignments by statuses: planned,active,ended
     */
    'statuses[]'?: any;

    /**
     * Filter assignments by ids
     */
    'assignments[]'?: any;

    /**
     * Filter assignments by open assignment times.
     */
    openAssignmentTimes?: boolean;

    /**
     * Filter assignments by open contracts: psp,customer,signed
     */
    'openContracts[]'?: any;

    /**
     * Order assignments key.
     */
    orderKey?: 'startDate' | 'endDate' | 'createdAt' | 'profileLastName' | 'customerName' | 'mainQualificationName' | 'pspBranchName';

    /**
     * Order direction.
     */
    orderDirection?: 'ASC' | 'DESC';
    context?: HttpContext
  }
): Observable<Blob> {

    return this.getCustomerContractExport$Response(params).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Path part for operation getCustomerAsignmentContractDownload
   */
  static readonly GetCustomerAsignmentContractDownloadPath = '/api/web/v1/customers/{customer}/assignments/{assignment}/contracts/{contract}/file';

  /**
   * Download contract PDF.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_ASSIGNMENTS'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerAsignmentContractDownload()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerAsignmentContractDownload$Response(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Assignment Id.
     */
    assignment: number;

    /**
     * Contract Id.
     */
    contract: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerAssignmentService.GetCustomerAsignmentContractDownloadPath, 'get');
    if (params) {
      rb.path('customer', params.customer, {});
      rb.path('assignment', params.assignment, {});
      rb.path('contract', params.contract, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'multipart/form-data',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Download contract PDF.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_ASSIGNMENTS'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCustomerAsignmentContractDownload$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerAsignmentContractDownload(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Assignment Id.
     */
    assignment: number;

    /**
     * Contract Id.
     */
    contract: number;
    context?: HttpContext
  }
): Observable<Blob> {

    return this.getCustomerAsignmentContractDownload$Response(params).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Path part for operation postCustomerAsignmentContractRegeneration
   */
  static readonly PostCustomerAsignmentContractRegenerationPath = '/api/web/v1/customers/{customer}/assignments/{assignment}/contracts/regeneration';

  /**
   * Regenerate contract.
   *
   * **Accessible only for users with permissions 'ROLE_CUSTOMER_ADMIN', 'FEATURE_SIGN_CONTRACT'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postCustomerAsignmentContractRegeneration()` instead.
   *
   * This method doesn't expect any request body.
   */
  postCustomerAsignmentContractRegeneration$Response(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Assignment Id.
     */
    assignment: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EmptySuccessfulResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerAssignmentService.PostCustomerAsignmentContractRegenerationPath, 'post');
    if (params) {
      rb.path('customer', params.customer, {});
      rb.path('assignment', params.assignment, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmptySuccessfulResponse>;
      })
    );
  }

  /**
   * Regenerate contract.
   *
   * **Accessible only for users with permissions 'ROLE_CUSTOMER_ADMIN', 'FEATURE_SIGN_CONTRACT'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postCustomerAsignmentContractRegeneration$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  postCustomerAsignmentContractRegeneration(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Assignment Id.
     */
    assignment: number;
    context?: HttpContext
  }
): Observable<EmptySuccessfulResponse> {

    return this.postCustomerAsignmentContractRegeneration$Response(params).pipe(
      map((r: StrictHttpResponse<EmptySuccessfulResponse>) => r.body as EmptySuccessfulResponse)
    );
  }

  /**
   * Path part for operation getCustomerAssignmentDocumentsDownload
   */
  static readonly GetCustomerAssignmentDocumentsDownloadPath = '/api/web/v1/customers/{customer}/assignments/{assignment}/documents/{document}/file';

  /**
   * Download a document.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_ASSIGNMENTS'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerAssignmentDocumentsDownload()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerAssignmentDocumentsDownload$Response(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Assignment Id.
     */
    assignment: number;
    document: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerAssignmentService.GetCustomerAssignmentDocumentsDownloadPath, 'get');
    if (params) {
      rb.path('customer', params.customer, {});
      rb.path('assignment', params.assignment, {});
      rb.path('document', params.document, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'multipart/form-data',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Download a document.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_ASSIGNMENTS'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCustomerAssignmentDocumentsDownload$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerAssignmentDocumentsDownload(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Assignment Id.
     */
    assignment: number;
    document: string;
    context?: HttpContext
  }
): Observable<Blob> {

    return this.getCustomerAssignmentDocumentsDownload$Response(params).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Path part for operation getCustomerAssignmentListExport
   */
  static readonly GetCustomerAssignmentListExportPath = '/api/web/v1/customers/{customer}/assignments/export';

  /**
   * Export list of customer assignments as CSV file.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_ASSIGNMENTS'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerAssignmentListExport()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerAssignmentListExport$Response(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Search assignment by profile.firstName, profile.lastName, profile.personalId.
     */
    search?: string;

    /**
     * Filter assignments by Main Qualifications ids.
     */
    'mainQualifications[]'?: any;

    /**
     * Filter assignments by Customer Department IDs.
     */
    'departments[]'?: number;

    /**
     * Filter assignments by PSP Branches ids.
     */
    'pspBranches[]'?: any;

    /**
     * From date, including. If passed then startDate &gt;&#x3D; fromDate
     */
    fromDate?: string;

    /**
     * Filter assignments by statuses: planned,active,ended
     */
    'statuses[]'?: any;

    /**
     * Filter assignments by open assignment times.
     */
    openAssignmentTimes?: boolean;

    /**
     * Filter assignments by open contracts: psp,customer,signed
     */
    'openContracts[]'?: any;

    /**
     * Filter assignments by ids
     */
    'assignments[]'?: any;

    /**
     * Order assignments key.
     */
    orderKey?: 'startDate' | 'endDate' | 'createdAt' | 'profileLastName' | 'customerName' | 'mainQualificationName' | 'pspBranchName';

    /**
     * Order direction.
     */
    orderDirection?: 'ASC' | 'DESC';
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerAssignmentService.GetCustomerAssignmentListExportPath, 'get');
    if (params) {
      rb.path('customer', params.customer, {});
      rb.query('search', params.search, {});
      rb.query('mainQualifications[]', params['mainQualifications[]'], {});
      rb.query('departments[]', params['departments[]'], {});
      rb.query('pspBranches[]', params['pspBranches[]'], {});
      rb.query('fromDate', params.fromDate, {});
      rb.query('statuses[]', params['statuses[]'], {});
      rb.query('openAssignmentTimes', params.openAssignmentTimes, {});
      rb.query('openContracts[]', params['openContracts[]'], {});
      rb.query('assignments[]', params['assignments[]'], {});
      rb.query('orderKey', params.orderKey, {});
      rb.query('orderDirection', params.orderDirection, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'text/csv',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Export list of customer assignments as CSV file.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_ASSIGNMENTS'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCustomerAssignmentListExport$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerAssignmentListExport(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Search assignment by profile.firstName, profile.lastName, profile.personalId.
     */
    search?: string;

    /**
     * Filter assignments by Main Qualifications ids.
     */
    'mainQualifications[]'?: any;

    /**
     * Filter assignments by Customer Department IDs.
     */
    'departments[]'?: number;

    /**
     * Filter assignments by PSP Branches ids.
     */
    'pspBranches[]'?: any;

    /**
     * From date, including. If passed then startDate &gt;&#x3D; fromDate
     */
    fromDate?: string;

    /**
     * Filter assignments by statuses: planned,active,ended
     */
    'statuses[]'?: any;

    /**
     * Filter assignments by open assignment times.
     */
    openAssignmentTimes?: boolean;

    /**
     * Filter assignments by open contracts: psp,customer,signed
     */
    'openContracts[]'?: any;

    /**
     * Filter assignments by ids
     */
    'assignments[]'?: any;

    /**
     * Order assignments key.
     */
    orderKey?: 'startDate' | 'endDate' | 'createdAt' | 'profileLastName' | 'customerName' | 'mainQualificationName' | 'pspBranchName';

    /**
     * Order direction.
     */
    orderDirection?: 'ASC' | 'DESC';
    context?: HttpContext
  }
): Observable<Blob> {

    return this.getCustomerAssignmentListExport$Response(params).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Path part for operation getCustomerAssignmentList
   */
  static readonly GetCustomerAssignmentListPath = '/api/web/v1/customers/{customer}/assignments';

  /**
   * Get list of customer assignments.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_ASSIGNMENTS'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerAssignmentList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerAssignmentList$Response(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Page number in pagination.
     */
    page?: any;

    /**
     * Size number in pagination.
     */
    size?: any;

    /**
     * Search assignment by profile.firstName, profile.lastName, profile.personalId.
     */
    search?: string;

    /**
     * Filter assignments by Main Qualifications ids.
     */
    'mainQualifications[]'?: any;

    /**
     * Filter assignments by Customer Department IDs.
     */
    'departments[]'?: number;

    /**
     * Filter assignments by PSP Branches ids.
     */
    'pspBranches[]'?: any;

    /**
     * From date, including. If passed then startDate &gt;&#x3D; fromDate
     */
    fromDate?: string;

    /**
     * Filter assignments by statuses: planned,active,ended
     */
    'statuses[]'?: any;

    /**
     * Filter assignments by open assignment times.
     */
    openAssignmentTimes?: boolean;

    /**
     * Filter assignments by open contracts: psp,customer,signed
     */
    'openContracts[]'?: any;

    /**
     * Order assignments key.
     */
    orderKey?: 'startDate' | 'endDate' | 'createdAt' | 'profileLastName' | 'customerName' | 'mainQualificationName' | 'pspBranchName';

    /**
     * Order direction.
     */
    orderDirection?: 'ASC' | 'DESC';
    context?: HttpContext
  }
): Observable<StrictHttpResponse<PaginatedApiResponseWithFilters & {
'data'?: Array<CustomerAssignmentListItemResponse>;
'filters'?: CustomerAssignmentFilterListsResponse;
}>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerAssignmentService.GetCustomerAssignmentListPath, 'get');
    if (params) {
      rb.path('customer', params.customer, {});
      rb.query('page', params.page, {});
      rb.query('size', params.size, {});
      rb.query('search', params.search, {});
      rb.query('mainQualifications[]', params['mainQualifications[]'], {});
      rb.query('departments[]', params['departments[]'], {});
      rb.query('pspBranches[]', params['pspBranches[]'], {});
      rb.query('fromDate', params.fromDate, {});
      rb.query('statuses[]', params['statuses[]'], {});
      rb.query('openAssignmentTimes', params.openAssignmentTimes, {});
      rb.query('openContracts[]', params['openContracts[]'], {});
      rb.query('orderKey', params.orderKey, {});
      rb.query('orderDirection', params.orderDirection, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaginatedApiResponseWithFilters & {
        'data'?: Array<CustomerAssignmentListItemResponse>;
        'filters'?: CustomerAssignmentFilterListsResponse;
        }>;
      })
    );
  }

  /**
   * Get list of customer assignments.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_ASSIGNMENTS'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCustomerAssignmentList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerAssignmentList(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Page number in pagination.
     */
    page?: any;

    /**
     * Size number in pagination.
     */
    size?: any;

    /**
     * Search assignment by profile.firstName, profile.lastName, profile.personalId.
     */
    search?: string;

    /**
     * Filter assignments by Main Qualifications ids.
     */
    'mainQualifications[]'?: any;

    /**
     * Filter assignments by Customer Department IDs.
     */
    'departments[]'?: number;

    /**
     * Filter assignments by PSP Branches ids.
     */
    'pspBranches[]'?: any;

    /**
     * From date, including. If passed then startDate &gt;&#x3D; fromDate
     */
    fromDate?: string;

    /**
     * Filter assignments by statuses: planned,active,ended
     */
    'statuses[]'?: any;

    /**
     * Filter assignments by open assignment times.
     */
    openAssignmentTimes?: boolean;

    /**
     * Filter assignments by open contracts: psp,customer,signed
     */
    'openContracts[]'?: any;

    /**
     * Order assignments key.
     */
    orderKey?: 'startDate' | 'endDate' | 'createdAt' | 'profileLastName' | 'customerName' | 'mainQualificationName' | 'pspBranchName';

    /**
     * Order direction.
     */
    orderDirection?: 'ASC' | 'DESC';
    context?: HttpContext
  }
): Observable<PaginatedApiResponseWithFilters & {
'data'?: Array<CustomerAssignmentListItemResponse>;
'filters'?: CustomerAssignmentFilterListsResponse;
}> {

    return this.getCustomerAssignmentList$Response(params).pipe(
      map((r: StrictHttpResponse<PaginatedApiResponseWithFilters & {
'data'?: Array<CustomerAssignmentListItemResponse>;
'filters'?: CustomerAssignmentFilterListsResponse;
}>) => r.body as PaginatedApiResponseWithFilters & {
'data'?: Array<CustomerAssignmentListItemResponse>;
'filters'?: CustomerAssignmentFilterListsResponse;
})
    );
  }

  /**
   * Path part for operation getCustomerAsignmentListUnplanned
   */
  static readonly GetCustomerAsignmentListUnplannedPath = '/api/web/v1/customers/{customer}/assignments/unplanned-dates';

  /**
   * Get list of unplanned Customer assignments.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_ASSIGNMENT_DATES' or 'FEATURE_ADMIN_ASSIGNMENTS'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerAsignmentListUnplanned()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerAsignmentListUnplanned$Response(params: {

    /**
     * Customer Id.
     */
    customer: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ListApiResponse & {
'data'?: Array<AssignmentUnplannedListItemResponse>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerAssignmentService.GetCustomerAsignmentListUnplannedPath, 'get');
    if (params) {
      rb.path('customer', params.customer, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListApiResponse & {
        'data'?: Array<AssignmentUnplannedListItemResponse>;
        }>;
      })
    );
  }

  /**
   * Get list of unplanned Customer assignments.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_ASSIGNMENT_DATES' or 'FEATURE_ADMIN_ASSIGNMENTS'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCustomerAsignmentListUnplanned$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerAsignmentListUnplanned(params: {

    /**
     * Customer Id.
     */
    customer: number;
    context?: HttpContext
  }
): Observable<ListApiResponse & {
'data'?: Array<AssignmentUnplannedListItemResponse>;
}> {

    return this.getCustomerAsignmentListUnplanned$Response(params).pipe(
      map((r: StrictHttpResponse<ListApiResponse & {
'data'?: Array<AssignmentUnplannedListItemResponse>;
}>) => r.body as ListApiResponse & {
'data'?: Array<AssignmentUnplannedListItemResponse>;
})
    );
  }

  /**
   * Path part for operation getCustomerAssignmentProfileIdCardFileDownload
   */
  static readonly GetCustomerAssignmentProfileIdCardFileDownloadPath = '/api/web/v1/customers/{customer}/assignments/{assignment}/profiles/{profile}/id-card/file';

  /**
   * Download employee ID card.
   *
   * **Accessible only for users with permissions 'FEATURE_ADMIN_ASSIGNMENTS'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerAssignmentProfileIdCardFileDownload()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerAssignmentProfileIdCardFileDownload$Response(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Assignment Id.
     */
    assignment: number;

    /**
     * Profile Id.
     */
    profile: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerAssignmentService.GetCustomerAssignmentProfileIdCardFileDownloadPath, 'get');
    if (params) {
      rb.path('customer', params.customer, {});
      rb.path('assignment', params.assignment, {});
      rb.path('profile', params.profile, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'multipart/form-data',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Download employee ID card.
   *
   * **Accessible only for users with permissions 'FEATURE_ADMIN_ASSIGNMENTS'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCustomerAssignmentProfileIdCardFileDownload$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerAssignmentProfileIdCardFileDownload(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Assignment Id.
     */
    assignment: number;

    /**
     * Profile Id.
     */
    profile: number;
    context?: HttpContext
  }
): Observable<Blob> {

    return this.getCustomerAssignmentProfileIdCardFileDownload$Response(params).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Path part for operation postCustomerAssignmentProfileIdCardRegenerate
   */
  static readonly PostCustomerAssignmentProfileIdCardRegeneratePath = '/api/web/v1/customers/{customer}/assignments/{assignment}/profiles/{profile}/id-card/regeneration';

  /**
   * Regenerate employee ID card.
   *
   * **Accessible only for users with permissions 'FEATURE_ADMIN_ASSIGNMENTS'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postCustomerAssignmentProfileIdCardRegenerate()` instead.
   *
   * This method doesn't expect any request body.
   */
  postCustomerAssignmentProfileIdCardRegenerate$Response(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Assignment Id.
     */
    assignment: number;

    /**
     * Profile Id.
     */
    profile: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerAssignmentService.PostCustomerAssignmentProfileIdCardRegeneratePath, 'post');
    if (params) {
      rb.path('customer', params.customer, {});
      rb.path('assignment', params.assignment, {});
      rb.path('profile', params.profile, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'multipart/form-data',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Regenerate employee ID card.
   *
   * **Accessible only for users with permissions 'FEATURE_ADMIN_ASSIGNMENTS'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postCustomerAssignmentProfileIdCardRegenerate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  postCustomerAssignmentProfileIdCardRegenerate(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Assignment Id.
     */
    assignment: number;

    /**
     * Profile Id.
     */
    profile: number;
    context?: HttpContext
  }
): Observable<Blob> {

    return this.postCustomerAssignmentProfileIdCardRegenerate$Response(params).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Path part for operation getCustomerAsignmentAssignment
   */
  static readonly GetCustomerAsignmentAssignmentPath = '/api/web/v1/customers/{customer}/assignments/{assignment}';

  /**
   * Get the assignment data.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_ASSIGNMENTS'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerAsignmentAssignment()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerAsignmentAssignment$Response(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Assignment Id.
     */
    assignment: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<GeneralApiResponse & {
'data'?: AssignmentCustomerResponse;
}>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerAssignmentService.GetCustomerAsignmentAssignmentPath, 'get');
    if (params) {
      rb.path('customer', params.customer, {});
      rb.path('assignment', params.assignment, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GeneralApiResponse & {
        'data'?: AssignmentCustomerResponse;
        }>;
      })
    );
  }

  /**
   * Get the assignment data.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_ASSIGNMENTS'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCustomerAsignmentAssignment$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerAsignmentAssignment(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Assignment Id.
     */
    assignment: number;
    context?: HttpContext
  }
): Observable<GeneralApiResponse & {
'data'?: AssignmentCustomerResponse;
}> {

    return this.getCustomerAsignmentAssignment$Response(params).pipe(
      map((r: StrictHttpResponse<GeneralApiResponse & {
'data'?: AssignmentCustomerResponse;
}>) => r.body as GeneralApiResponse & {
'data'?: AssignmentCustomerResponse;
})
    );
  }

  /**
   * Path part for operation postAssignmentAssignmentDatesAcceptance
   */
  static readonly PostAssignmentAssignmentDatesAcceptancePath = '/api/web/v1/customers/{customer}/assignment-dates/acceptance';

  /**
   * Confirm AssignmentDates.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_ASSIGNMENTS'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postAssignmentAssignmentDatesAcceptance()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postAssignmentAssignmentDatesAcceptance$Response(params: {

    /**
     * Customer Id.
     */
    customer: number;
    context?: HttpContext
    body: CustomerAcceptRequest
  }
): Observable<StrictHttpResponse<EmptySuccessfulResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerAssignmentService.PostAssignmentAssignmentDatesAcceptancePath, 'post');
    if (params) {
      rb.path('customer', params.customer, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmptySuccessfulResponse>;
      })
    );
  }

  /**
   * Confirm AssignmentDates.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_ASSIGNMENTS'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postAssignmentAssignmentDatesAcceptance$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postAssignmentAssignmentDatesAcceptance(params: {

    /**
     * Customer Id.
     */
    customer: number;
    context?: HttpContext
    body: CustomerAcceptRequest
  }
): Observable<EmptySuccessfulResponse> {

    return this.postAssignmentAssignmentDatesAcceptance$Response(params).pipe(
      map((r: StrictHttpResponse<EmptySuccessfulResponse>) => r.body as EmptySuccessfulResponse)
    );
  }

  /**
   * Path part for operation getCustomerAssignmentDateExport
   */
  static readonly GetCustomerAssignmentDateExportPath = '/api/web/v1/customers/{customer}/assignment-dates/export';

  /**
   * Export customer Assignment dates.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_ASSIGNMENTS'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerAssignmentDateExport()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerAssignmentDateExport$Response(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Filter by assignment date ids.
     */
    'assignmentDates[]'?: any;

    /**
     * Filter Assignments Dates by statuses.
     */
    'statuses[]'?: Array<'open' | 'submitted' | 'accepted' | 'canceled' | 'corrected_by_customer' | 'corrected_by_psp' | 'deleted' | 'automatically_accepted' | 'incomplete' | 'verification_needed'>;

    /**
     * Filter AssignmentDates from date.
     */
    dateFrom?: string;

    /**
     * Filter AssignmentDates to date.
     */
    dateTo?: string;

    /**
     * Filter AssignmentDates by PSP Branch ids.
     */
    'pspBranches[]'?: any;

    /**
     * Filter AssignmentDates by Profile ids.
     */
    'profiles[]'?: any;

    /**
     * Filter AssignmentDates by Customer department ids.
     */
    'customerDepartments[]'?: any;

    /**
     * Filter AssignmentDates by Customer location ids.
     */
    'customerLocations[]'?: any;

    /**
     * Search assignment date by profile.firstName, profile.lastName, profile.personalId
     *      *         or profile.terminalCards.id.
     */
    search?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerAssignmentService.GetCustomerAssignmentDateExportPath, 'get');
    if (params) {
      rb.path('customer', params.customer, {});
      rb.query('assignmentDates[]', params['assignmentDates[]'], {});
      rb.query('statuses[]', params['statuses[]'], {});
      rb.query('dateFrom', params.dateFrom, {});
      rb.query('dateTo', params.dateTo, {});
      rb.query('pspBranches[]', params['pspBranches[]'], {});
      rb.query('profiles[]', params['profiles[]'], {});
      rb.query('customerDepartments[]', params['customerDepartments[]'], {});
      rb.query('customerLocations[]', params['customerLocations[]'], {});
      rb.query('search', params.search, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/csv',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Export customer Assignment dates.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_ASSIGNMENTS'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCustomerAssignmentDateExport$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerAssignmentDateExport(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Filter by assignment date ids.
     */
    'assignmentDates[]'?: any;

    /**
     * Filter Assignments Dates by statuses.
     */
    'statuses[]'?: Array<'open' | 'submitted' | 'accepted' | 'canceled' | 'corrected_by_customer' | 'corrected_by_psp' | 'deleted' | 'automatically_accepted' | 'incomplete' | 'verification_needed'>;

    /**
     * Filter AssignmentDates from date.
     */
    dateFrom?: string;

    /**
     * Filter AssignmentDates to date.
     */
    dateTo?: string;

    /**
     * Filter AssignmentDates by PSP Branch ids.
     */
    'pspBranches[]'?: any;

    /**
     * Filter AssignmentDates by Profile ids.
     */
    'profiles[]'?: any;

    /**
     * Filter AssignmentDates by Customer department ids.
     */
    'customerDepartments[]'?: any;

    /**
     * Filter AssignmentDates by Customer location ids.
     */
    'customerLocations[]'?: any;

    /**
     * Search assignment date by profile.firstName, profile.lastName, profile.personalId
     *      *         or profile.terminalCards.id.
     */
    search?: string;
    context?: HttpContext
  }
): Observable<Blob> {

    return this.getCustomerAssignmentDateExport$Response(params).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Path part for operation getCustomerAssignmentDateList
   */
  static readonly GetCustomerAssignmentDateListPath = '/api/web/v1/customers/{customer}/assignment-dates';

  /**
   * Get a list of all customer Assignment dates.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_ASSIGNMENTS'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerAssignmentDateList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerAssignmentDateList$Response(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Page number in pagination.
     */
    page?: any;

    /**
     * Size number in pagination.
     */
    size?: any;

    /**
     * Filter by assignment id.
     */
    assignmentId?: any;

    /**
     * Filter Assignments Dates by statuses.
     */
    'statuses[]'?: Array<'open' | 'submitted' | 'accepted' | 'canceled' | 'corrected_by_customer' | 'corrected_by_psp' | 'deleted' | 'automatically_accepted' | 'incomplete' | 'verification_needed'>;

    /**
     * Filter AssignmentDates from date.
     */
    dateFrom?: string;

    /**
     * Filter AssignmentDates to date.
     */
    dateTo?: string;

    /**
     * Filter AssignmentDates by PSP Branch ids.
     */
    'pspBranches[]'?: any;

    /**
     * Filter AssignmentDates by Profile ids.
     */
    'profiles[]'?: any;

    /**
     * Filter AssignmentDates by Customer department ids.
     */
    'customerDepartments[]'?: any;

    /**
     * Filter AssignmentDates by Customer location ids.
     */
    'customerLocations[]'?: any;

    /**
     * Search assignment date by profile.firstName, profile.lastName, profile.personalId
     *      *         or profile.terminalCards.id.
     */
    search?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<PaginatedApiResponseWithFilters & {
'data'?: Array<CustomerAssignmentDateListItemResponse>;
'filters'?: OrganizationFilterListResponse;
}>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerAssignmentService.GetCustomerAssignmentDateListPath, 'get');
    if (params) {
      rb.path('customer', params.customer, {});
      rb.query('page', params.page, {});
      rb.query('size', params.size, {});
      rb.query('assignmentId', params.assignmentId, {});
      rb.query('statuses[]', params['statuses[]'], {"explode":false});
      rb.query('dateFrom', params.dateFrom, {});
      rb.query('dateTo', params.dateTo, {});
      rb.query('pspBranches[]', params['pspBranches[]'], {});
      rb.query('profiles[]', params['profiles[]'], {});
      rb.query('customerDepartments[]', params['customerDepartments[]'], {});
      rb.query('customerLocations[]', params['customerLocations[]'], {});
      rb.query('search', params.search, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaginatedApiResponseWithFilters & {
        'data'?: Array<CustomerAssignmentDateListItemResponse>;
        'filters'?: OrganizationFilterListResponse;
        }>;
      })
    );
  }

  /**
   * Get a list of all customer Assignment dates.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_ASSIGNMENTS'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCustomerAssignmentDateList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerAssignmentDateList(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Page number in pagination.
     */
    page?: any;

    /**
     * Size number in pagination.
     */
    size?: any;

    /**
     * Filter by assignment id.
     */
    assignmentId?: any;

    /**
     * Filter Assignments Dates by statuses.
     */
    'statuses[]'?: Array<'open' | 'submitted' | 'accepted' | 'canceled' | 'corrected_by_customer' | 'corrected_by_psp' | 'deleted' | 'automatically_accepted' | 'incomplete' | 'verification_needed'>;

    /**
     * Filter AssignmentDates from date.
     */
    dateFrom?: string;

    /**
     * Filter AssignmentDates to date.
     */
    dateTo?: string;

    /**
     * Filter AssignmentDates by PSP Branch ids.
     */
    'pspBranches[]'?: any;

    /**
     * Filter AssignmentDates by Profile ids.
     */
    'profiles[]'?: any;

    /**
     * Filter AssignmentDates by Customer department ids.
     */
    'customerDepartments[]'?: any;

    /**
     * Filter AssignmentDates by Customer location ids.
     */
    'customerLocations[]'?: any;

    /**
     * Search assignment date by profile.firstName, profile.lastName, profile.personalId
     *      *         or profile.terminalCards.id.
     */
    search?: string;
    context?: HttpContext
  }
): Observable<PaginatedApiResponseWithFilters & {
'data'?: Array<CustomerAssignmentDateListItemResponse>;
'filters'?: OrganizationFilterListResponse;
}> {

    return this.getCustomerAssignmentDateList$Response(params).pipe(
      map((r: StrictHttpResponse<PaginatedApiResponseWithFilters & {
'data'?: Array<CustomerAssignmentDateListItemResponse>;
'filters'?: OrganizationFilterListResponse;
}>) => r.body as PaginatedApiResponseWithFilters & {
'data'?: Array<CustomerAssignmentDateListItemResponse>;
'filters'?: OrganizationFilterListResponse;
})
    );
  }

  /**
   * Path part for operation getCustomerProfileEndedAssignmentList
   */
  static readonly GetCustomerProfileEndedAssignmentListPath = '/api/web/v1/customers/{customer}/profiles/{profile}/assignments/ended';

  /**
   * Get List of Ended Profile Assignments for Customer.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_ASSIGNMENTS'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerProfileEndedAssignmentList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerProfileEndedAssignmentList$Response(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Profile Id.
     */
    profile: number;

    /**
     * Page number in pagination.
     */
    page?: any;

    /**
     * Size number in pagination.
     */
    size?: any;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<PaginatedApiResponse & {
'data'?: Array<CustomerProfileAssignmentItemResponse>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerAssignmentService.GetCustomerProfileEndedAssignmentListPath, 'get');
    if (params) {
      rb.path('customer', params.customer, {});
      rb.path('profile', params.profile, {});
      rb.query('page', params.page, {});
      rb.query('size', params.size, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaginatedApiResponse & {
        'data'?: Array<CustomerProfileAssignmentItemResponse>;
        }>;
      })
    );
  }

  /**
   * Get List of Ended Profile Assignments for Customer.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_ASSIGNMENTS'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCustomerProfileEndedAssignmentList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerProfileEndedAssignmentList(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Profile Id.
     */
    profile: number;

    /**
     * Page number in pagination.
     */
    page?: any;

    /**
     * Size number in pagination.
     */
    size?: any;
    context?: HttpContext
  }
): Observable<PaginatedApiResponse & {
'data'?: Array<CustomerProfileAssignmentItemResponse>;
}> {

    return this.getCustomerProfileEndedAssignmentList$Response(params).pipe(
      map((r: StrictHttpResponse<PaginatedApiResponse & {
'data'?: Array<CustomerProfileAssignmentItemResponse>;
}>) => r.body as PaginatedApiResponse & {
'data'?: Array<CustomerProfileAssignmentItemResponse>;
})
    );
  }

}
