import { Injectable } from '@angular/core';
import { BuildDetails } from '../../shared/model/base-build-details';

@Injectable({ providedIn: 'root' })
export class BaseBuildDetailsService {
  public buildDetails: BuildDetails;

  constructor() {
    this.buildDetails = new BuildDetails();
  }
}
