import { Injectable } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { delay } from 'rxjs/operators';
import { BASE_MODULES_URL } from '../../core/constants/base-modules-url';
import { baseDeepClone } from '../helpers/base-deep-clone';

@Injectable()
export class BaseQueryParamService {
  baseModules = BASE_MODULES_URL;
  filterSubject: BehaviorSubject<Params | null> = new BehaviorSubject<Params | null>(null);
  constructor(private router: Router, private route: ActivatedRoute) {
    this.filterSubject.pipe(delay(0)).subscribe((data: Params | null) => {
      if (this.router.url.includes(this.baseModules.ADMINISTRATION)) {
        return;
      }
      if (data && data.page && typeof data.page !== 'string') {
        this.updatePage(data.page);
      }
    });
  }

  /**
   * Get query params from ActivatedRoute snapshot
   * and convert comma separated strings to array.
   */
  getAllQueryParams(notArrayKeys: string[] = []): Params {
    let queryParams = baseDeepClone(this.route.snapshot.queryParams);
    if (Object.entries(queryParams).length) {
      Object.entries(queryParams).forEach(([key, value]) => {
        if (notArrayKeys.includes(key)) {
          return;
        }
        queryParams[key] = value.split(',');
      });
    }

    return queryParams;
  }

  /**
   * Get query params from ActivatedRoute snapshot
   * and convert comma separated strings to array.
   */
  getQueryParamByName(name: string): any {
    return this.route.snapshot.queryParams[name];
  }

  /**
   * Add a single query parameter and using "merge" for query params handling.
   * @param key
   * @param value
   */
  addParameter(key: string, value: string) {
    if (!key || !value) {
      return;
    }

    const queryParams = { ...this.route.snapshot.queryParams, ...{ [key]: value } };

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: queryParams,
      queryParamsHandling: 'merge',
    });
  }

  /**
   * Remove a single query parameter from ActivatedRoute snapshot.
   * @param key
   */
  removeParameter(key: string) {
    if (!key) {
      return;
    }

    const queryParams = { ...this.route.snapshot.queryParams };

    delete queryParams[key];

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: queryParams,
    });
  }

  updateQueryParams(data: Object, objectValueFields: Record<string, string> = {}) {
    if (!Object.entries(data)?.length) {
      return;
    }

    const queryParams: any = {};
    Object.entries(data).forEach(([key, value]) => {
      queryParams[key] = null;
      if (Array.isArray(value) && value.length) {
        queryParams[key] = [];
        value.forEach((val: any) => {
          if (val.value) {
            queryParams[key].push(val.value);
          } else if (val.id) {
            queryParams[key].push(val.id);
          } else {
            return;
          }
        });

        if (queryParams[key].toString()) {
          queryParams[key] = queryParams[key].toString();
        } else {
          delete queryParams[key];
        }
      } else if (value !== null && typeof value === 'object') {
        if (objectValueFields[key] && value[objectValueFields[key]] !== null) {
          queryParams[key] = value[objectValueFields[key]];
        } else {
          return;
        }
      } else {
        if (!value) {
          queryParams[key] = null;
          return;
        }

        queryParams[key] = value;
      }
    });

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: queryParams,
      queryParamsHandling: 'merge',
    });
  }

  updatePage(page: number) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { page: page },
      queryParamsHandling: 'merge',
    });
  }

  getQueryParam(key: string): string {
    if (!key) {
      return '';
    }

    return this.route.snapshot.queryParams[key] ? this.route.snapshot.queryParams[key] : '';
  }

  clear(): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {},
    });
  }
}
