import { createAction, props } from '@ngrx/store';
import {
  ApplyRequest,
  CustomerRegistrationRequest,
  PspRegistrationRequest,
  ResetRequest,
  UserRegistrationRequest,
} from '../../../api';
import { BaseApplication } from '../../shared/enums/base-application.enum';
import { BaseTokenInterface } from '../../shared/interfaces/base-token-service.interface';
import { HttpErrorResponse } from '@angular/common/http';

// User info from token
export const loadToken = createAction(
  '[Auth] Load token',
  props<{ payload: { application: BaseApplication; username: string; password: string } }>()
);
export const setUserInfoFromToken = createAction(
  '[Auth] Set user info from token',
  props<{ payload: BaseTokenInterface }>()
);
export const successUserInfo = createAction('[Auth] Success user info');
export const errorUserInfo = createAction('[Auth] Error user info', props<{ error: HttpErrorResponse }>());

export const refreshUser = createAction('[Auth] Refresh token');
export const successRefreshUser = createAction('[Auth] Success refresh token');
export const errorRefreshUser = createAction('[Auth] Error refresh token');
export const setFirstLoginIndicator = createAction('[Auth] Set first login indicator', props<{ payload: boolean }>());

// Register User
export const registerUser = createAction('[Auth] Register User', props<{ payload: UserRegistrationRequest }>());
export const successRegisterUser = createAction('[Auth] Success Register User');
export const errorRegisterUser = createAction('[Auth] Error Register User');

// Register PSP
export const registerPSP = createAction('[Auth] Register PSP', props<{ payload: PspRegistrationRequest }>());
export const successRegisterPSP = createAction('[Auth] Success Register PSP');
export const errorRegisterPSP = createAction('[Auth] Error Register PSP');

// Register Customer
export const registerCustomer = createAction(
  '[Auth] Register Customer',
  props<{ payload: CustomerRegistrationRequest }>()
);
export const successRegisterCustomer = createAction('[Auth] Success Register Customer');
export const errorRegisterCustomer = createAction('[Auth] Error Register Customer');

// Register Business Customer
export const registerBusinessCustomer = createAction(
  '[Auth] Register Business Customer',
  props<{ payload: CustomerRegistrationRequest }>()
);
export const successRegisterBusinessCustomer = createAction('[Auth] Success Register Business Customer');
export const errorRegisterBusinessCustomer = createAction('[Auth] Error Register Business Customer');

// Registration Verification
export const verifyRegistration = createAction(
  '[Auth] Verify Registration',
  props<{ payload: { id: number; token: string; type: 'customer' | 'psp' } }>()
);
export const verifyRegistrationSuccess = createAction('[Auth] Verify Registration Success');
export const verifyRegistrationError = createAction('[Auth] Verify Registration Error');

export const resetPasswordEmail = createAction('[Auth] Reset password email', props<{ payload: ResetRequest }>());
export const successResetPasswordEmail = createAction('[Auth] Success reset password email');
export const errorResetPasswordEmail = createAction('[Auth] Error reset password email');

export const resetPasswordConfirmation = createAction(
  '[Auth] Reset password confirmation',
  props<{ payload: { token: string; body: ApplyRequest } }>()
);
export const successResetPasswordConfirmation = createAction('[Auth] Success reset password confirmation');
export const errorResetPasswordConfirmation = createAction('[Auth] Error reset password confirmation');

export const logout = createAction('[Auth] Logout user');
export const setInitState = createAction('[Auth] Set init state to auth store');

export const setCustomizedUser = createAction(
  '[Auth] Set customised user flag to auth store',
  props<{ payload: boolean }>()
);

export const navigateToLoginPage = createAction('[Auth] Navigate to login page');
