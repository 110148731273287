import { Component } from '@angular/core';
import { BaseButtonSize } from '../../../shared/components/button/base-button.component';
import { BaseAuthenticationService } from '../../../modules/auth/base-auth.service';

@Component({
  selector: 'base-contact-button',
  templateUrl: './base-contact-button.component.html',
  styleUrls: ['./base-contact-button.component.scss'],
})
export class BaseContactButtonComponent {
  buttonSize = BaseButtonSize;

  constructor(private authService: BaseAuthenticationService) {}

  goToContactUrl(): void {
    this.authService.openContactsPage();
  }
}
