/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { EmptySuccessfulResponse } from '../models/empty-successful-response';
import { GeneralApiResponse } from '../models/general-api-response';
import { ListApiResponse } from '../models/list-api-response';
import { TerminalCreateRequest } from '../models/terminal-create-request';
import { TerminalCustomerLocationListItemResponse } from '../models/terminal-customer-location-list-item-response';
import { TerminalListItemResponse } from '../models/terminal-list-item-response';
import { TerminalUpdateRequest } from '../models/terminal-update-request';
import { TerminalUsageRequest } from '../models/terminal-usage-request';

@Injectable({
  providedIn: 'root',
})
export class CustomerTerminalService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getCustomerLocationTerminalList
   */
  static readonly GetCustomerLocationTerminalListPath = '/api/web/v1/customers/{customer}/locations/{location}/terminals';

  /**
   * Get list of customer terminals.
   *
   * **Accessible only for users with roles 'ROLE_CUSTOMER_ADMIN', 'ROLE_CUSTOMER_LOCATION_ADMIN'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerLocationTerminalList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerLocationTerminalList$Response(params: {
    customer: number;
    location: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ListApiResponse & {
'data'?: Array<TerminalCustomerLocationListItemResponse>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerTerminalService.GetCustomerLocationTerminalListPath, 'get');
    if (params) {
      rb.path('customer', params.customer, {});
      rb.path('location', params.location, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListApiResponse & {
        'data'?: Array<TerminalCustomerLocationListItemResponse>;
        }>;
      })
    );
  }

  /**
   * Get list of customer terminals.
   *
   * **Accessible only for users with roles 'ROLE_CUSTOMER_ADMIN', 'ROLE_CUSTOMER_LOCATION_ADMIN'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCustomerLocationTerminalList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerLocationTerminalList(params: {
    customer: number;
    location: number;
    context?: HttpContext
  }
): Observable<ListApiResponse & {
'data'?: Array<TerminalCustomerLocationListItemResponse>;
}> {

    return this.getCustomerLocationTerminalList$Response(params).pipe(
      map((r: StrictHttpResponse<ListApiResponse & {
'data'?: Array<TerminalCustomerLocationListItemResponse>;
}>) => r.body as ListApiResponse & {
'data'?: Array<TerminalCustomerLocationListItemResponse>;
})
    );
  }

  /**
   * Path part for operation postTerminalCreate
   */
  static readonly PostTerminalCreatePath = '/api/web/v1/customers/{customer}/locations/{location}/terminals';

  /**
   * Create a customer terminal.
   *
   * **Accessible only for users with permissions 'ROLE_CUSTOMER_ADMIN' or 'ROLE_CUSTOMER_LOCATION_ADMIN'.**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postTerminalCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postTerminalCreate$Response(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Location Id.
     */
    location: number;
    context?: HttpContext
    body: TerminalCreateRequest
  }
): Observable<StrictHttpResponse<GeneralApiResponse & {
'data'?: TerminalListItemResponse;
}>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerTerminalService.PostTerminalCreatePath, 'post');
    if (params) {
      rb.path('customer', params.customer, {});
      rb.path('location', params.location, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GeneralApiResponse & {
        'data'?: TerminalListItemResponse;
        }>;
      })
    );
  }

  /**
   * Create a customer terminal.
   *
   * **Accessible only for users with permissions 'ROLE_CUSTOMER_ADMIN' or 'ROLE_CUSTOMER_LOCATION_ADMIN'.**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postTerminalCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postTerminalCreate(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Location Id.
     */
    location: number;
    context?: HttpContext
    body: TerminalCreateRequest
  }
): Observable<GeneralApiResponse & {
'data'?: TerminalListItemResponse;
}> {

    return this.postTerminalCreate$Response(params).pipe(
      map((r: StrictHttpResponse<GeneralApiResponse & {
'data'?: TerminalListItemResponse;
}>) => r.body as GeneralApiResponse & {
'data'?: TerminalListItemResponse;
})
    );
  }

  /**
   * Path part for operation putTerminalUpdate
   */
  static readonly PutTerminalUpdatePath = '/api/web/v1/customers/{customer}/locations/{location}/terminals/{terminal}';

  /**
   * Update terminal.
   *
   * **Accessible only for users with permissions 'ROLE_CUSTOMER_ADMIN' or 'ROLE_CUSTOMER_LOCATION_ADMIN'.**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putTerminalUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putTerminalUpdate$Response(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Location Id.
     */
    location: number;

    /**
     * Terminal Id.
     */
    terminal: number;
    context?: HttpContext
    body: TerminalUpdateRequest
  }
): Observable<StrictHttpResponse<EmptySuccessfulResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerTerminalService.PutTerminalUpdatePath, 'put');
    if (params) {
      rb.path('customer', params.customer, {});
      rb.path('location', params.location, {});
      rb.path('terminal', params.terminal, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmptySuccessfulResponse>;
      })
    );
  }

  /**
   * Update terminal.
   *
   * **Accessible only for users with permissions 'ROLE_CUSTOMER_ADMIN' or 'ROLE_CUSTOMER_LOCATION_ADMIN'.**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putTerminalUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putTerminalUpdate(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Location Id.
     */
    location: number;

    /**
     * Terminal Id.
     */
    terminal: number;
    context?: HttpContext
    body: TerminalUpdateRequest
  }
): Observable<EmptySuccessfulResponse> {

    return this.putTerminalUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<EmptySuccessfulResponse>) => r.body as EmptySuccessfulResponse)
    );
  }

  /**
   * Path part for operation deleteTerminalDelete
   */
  static readonly DeleteTerminalDeletePath = '/api/web/v1/customers/{customer}/locations/{location}/terminals/{terminal}';

  /**
   * Delete customer terminal.
   *
   * **Accessible only for users with permissions 'ROLE_CUSTOMER_ADMIN' or 'ROLE_CUSTOMER_LOCATION_ADMIN'.**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteTerminalDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTerminalDelete$Response(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Location Id.
     */
    location: number;

    /**
     * Terminal Id.
     */
    terminal: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EmptySuccessfulResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerTerminalService.DeleteTerminalDeletePath, 'delete');
    if (params) {
      rb.path('customer', params.customer, {});
      rb.path('location', params.location, {});
      rb.path('terminal', params.terminal, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmptySuccessfulResponse>;
      })
    );
  }

  /**
   * Delete customer terminal.
   *
   * **Accessible only for users with permissions 'ROLE_CUSTOMER_ADMIN' or 'ROLE_CUSTOMER_LOCATION_ADMIN'.**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteTerminalDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTerminalDelete(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Location Id.
     */
    location: number;

    /**
     * Terminal Id.
     */
    terminal: number;
    context?: HttpContext
  }
): Observable<EmptySuccessfulResponse> {

    return this.deleteTerminalDelete$Response(params).pipe(
      map((r: StrictHttpResponse<EmptySuccessfulResponse>) => r.body as EmptySuccessfulResponse)
    );
  }

  /**
   * Path part for operation getCustomerLocationTerminalQrCodeDownload
   */
  static readonly GetCustomerLocationTerminalQrCodeDownloadPath = '/api/web/v1/customers/{customer}/locations/{location}/terminals/{terminal}/qr-code';

  /**
   * Download terminal qr-code.
   *
   * **Accessible only for users with permissions 'ROLE_CUSTOMER_ADMIN', 'ROLE_CUSTOMER_LOCATION_ADMIN'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerLocationTerminalQrCodeDownload()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerLocationTerminalQrCodeDownload$Response(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Location Id.
     */
    location: number;

    /**
     * Terminal Id.
     */
    terminal: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerTerminalService.GetCustomerLocationTerminalQrCodeDownloadPath, 'get');
    if (params) {
      rb.path('customer', params.customer, {});
      rb.path('location', params.location, {});
      rb.path('terminal', params.terminal, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'multipart/form-data',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Download terminal qr-code.
   *
   * **Accessible only for users with permissions 'ROLE_CUSTOMER_ADMIN', 'ROLE_CUSTOMER_LOCATION_ADMIN'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCustomerLocationTerminalQrCodeDownload$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerLocationTerminalQrCodeDownload(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Location Id.
     */
    location: number;

    /**
     * Terminal Id.
     */
    terminal: number;
    context?: HttpContext
  }
): Observable<Blob> {

    return this.getCustomerLocationTerminalQrCodeDownload$Response(params).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Path part for operation putTerminalUsage
   */
  static readonly PutTerminalUsagePath = '/api/web/v1/customers/{customer}/locations/{location}/terminalUsage';

  /**
   * Update terminal usage.
   *
   * **Accessible only for users with permissions 'ROLE_CUSTOMER_ADMIN' or 'ROLE_CUSTOMER_LOCATION_ADMIN'.**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putTerminalUsage()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putTerminalUsage$Response(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Location Id.
     */
    location: number;
    context?: HttpContext
    body: TerminalUsageRequest
  }
): Observable<StrictHttpResponse<EmptySuccessfulResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerTerminalService.PutTerminalUsagePath, 'put');
    if (params) {
      rb.path('customer', params.customer, {});
      rb.path('location', params.location, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmptySuccessfulResponse>;
      })
    );
  }

  /**
   * Update terminal usage.
   *
   * **Accessible only for users with permissions 'ROLE_CUSTOMER_ADMIN' or 'ROLE_CUSTOMER_LOCATION_ADMIN'.**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putTerminalUsage$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putTerminalUsage(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Location Id.
     */
    location: number;
    context?: HttpContext
    body: TerminalUsageRequest
  }
): Observable<EmptySuccessfulResponse> {

    return this.putTerminalUsage$Response(params).pipe(
      map((r: StrictHttpResponse<EmptySuccessfulResponse>) => r.body as EmptySuccessfulResponse)
    );
  }

}
