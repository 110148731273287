import { Directive, HostListener, AfterViewInit, OnDestroy, Input } from '@angular/core';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { BASE_INQUIRY_CALENDAR_DAY_COLUMN_WIDTH } from '../../modules/inquiry/_const/base-inquiry-calendar.const';
import { BaseInquiryScrollService } from '../../modules/inquiry/_services/base-inquiry-scroll.service';
import { takeUntil, distinctUntilChanged, take } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { elementIsVisibleInViewport } from '../helpers/base-is-element-visible-in-viewport';

@Directive({
  selector: '[baseInquiryHorizontalScroll]',
})
export class BaseHorizontalScrollDirective implements AfterViewInit, OnDestroy {
  @Input() isCalendar = false;

  private destroy$ = new Subject();
  constructor(private viewPort: CdkVirtualScrollViewport, private baseInquiryScrollService: BaseInquiryScrollService) {}

  @HostListener('window:resize', ['$event'])
  onResize() {
    if (this.isCalendar) {
      this.setVisiblePartWidth();
    }
  }

  @HostListener('window:scroll', ['$event'])
  onScroll() {
    this.baseInquiryScrollService.scrollOffset$.pipe(take(1), distinctUntilChanged()).subscribe((value) => {
      if (elementIsVisibleInViewport(this.viewPort.getElementRef().nativeElement, true)) {
        this.viewPort.scrollToIndex(value);
      }
    });
  }

  ngAfterViewInit(): void {
    this.scrollAction();
    if (this.isCalendar) {
      this.setVisiblePartWidth();
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.baseInquiryScrollService.setScrollOffset(0);
  }

  private scrollAction() {
    this.baseInquiryScrollService.scrollOffset$
      .pipe(takeUntil(this.destroy$), distinctUntilChanged())
      .subscribe((value) => {
        if (elementIsVisibleInViewport(this.viewPort.getElementRef().nativeElement, true)) {
          this.viewPort.scrollToIndex(value, 'smooth');
        }
      });
  }

  private setVisiblePartWidth() {
    this.baseInquiryScrollService.setVisibleDatesValue(
      Math.floor(this.viewPort.getElementRef().nativeElement.offsetWidth / BASE_INQUIRY_CALENDAR_DAY_COLUMN_WIDTH)
    );
  }
}
