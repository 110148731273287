<span
  class="position-relative"
  [ngClass]="bubble.classes"
>
  <div *ngIf="bubble.bookedCustomersValue >= 0 && bubble.currentValue > 0">
    <span>{{ bubble.bookedCustomersValue }}</span>
    <span class="mx-1">|</span>
  </div>
  <span class="bubble-content">
    {{ bubble.content }}
  </span>
  <span
    *ngIf="isShiftPlan"
    class="hover-bubble bg-white"
  ></span>
</span>
