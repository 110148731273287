<div class="d-flex flex-column gap-4 p-2">
  <div class="d-flex flex-column align-items-baseline">
    <p class="m-0 fw-bold">{{ 'externalEmployees.contractStatus.tooltip.partner' | translate }}</p>
    <ng-container *ngIf="isPsp; then customerStatus; else pspStatus"></ng-container>
  </div>

  <div class="d-flex flex-column align-items-baseline">
    <p class="m-0 fw-bold">{{ 'externalEmployees.contractStatus.tooltip.yourCompany' | translate }}</p>
    <ng-container *ngIf="isPsp; then pspStatus; else customerStatus"></ng-container>
  </div>
</div>

<ng-template #pspStatus>
  <span *ngIf="contract.signatures.statusPsp !== signingStatusBySide.SIGNED">
    {{ 'externalEmployees.contractStatus.tooltip.notSigned' | translate }}
  </span>
  <div
    class="d-flex flex-column align-items-baseline"
    *ngIf="contract.signatures.statusPsp === signingStatusBySide.SIGNED"
  >
    <span>
      {{
        'externalEmployees.contractStatus.tooltip.signatureDetails.signedOn'
          | translate: { signedAt: contract?.signedAtPSP | baseFormatDate }
      }}
    </span>
    <span>
      {{
        'externalEmployees.contractStatus.tooltip.signatureDetails.signedBy'
          | translate: { signedBy: contract?.signedByPspUser.firstName + ' ' + contract?.signedByPspUser.lastName }
      }}
    </span>
    <span>
      {{
        'externalEmployees.contractStatus.tooltip.signatureDetails.signedMethod'
          | translate: { signingMethod: 'externalEmployees.contractSignMethod.' + contract.type | translate }
      }}
    </span>
  </div>
</ng-template>

<ng-template #customerStatus>
  <span *ngIf="contract.signatures.statusCustomer !== signingStatusBySide.SIGNED">
    {{ 'externalEmployees.contractStatus.tooltip.notSigned' | translate }}
  </span>
  <div
    class="d-flex flex-column align-items-baseline"
    *ngIf="contract.signatures.statusCustomer === signingStatusBySide.SIGNED"
  >
    <span>
      {{
        'externalEmployees.contractStatus.tooltip.signatureDetails.signedOn'
          | translate: { signedAt: contract?.signedAtCustomer | baseFormatDate }
      }}
    </span>
    <span>
      {{
        'externalEmployees.contractStatus.tooltip.signatureDetails.signedBy'
          | translate
            : { signedBy: contract?.signedByCustomerUser.firstName + ' ' + contract?.signedByCustomerUser.lastName }
      }}
    </span>
    <span>
      {{
        'externalEmployees.contractStatus.tooltip.signatureDetails.signedMethod'
          | translate: { signingMethod: 'externalEmployees.contractSignMethod.' + contract.type | translate }
      }}
    </span>
  </div>
</ng-template>
