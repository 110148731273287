<div
  class="status-button-wrapper"
  [ngbTooltip]="pspSignatureError || customerSignatureError ? errorStatusTooltip : undefined"
>
  <div
    class="status-button-body"
    [ngClass]="{ 'animated-border': isInAQueue, 'static-error': isErrorBorder, 'static-warning': isWarningBorder }"
  ></div>
  <div class="status-button-icon">
    <i class="fa-solid fa-file-signature"></i>
  </div>
  <span
    *ngIf="pspSignatureError || customerSignatureError"
    class="position-absolute translate-middle badge rounded-circle bg-warning status-button-badge"
  >
    <span class="fw-bold text-dark">!</span>
  </span>
</div>

<ng-template #errorStatusTooltip>
  <div
    class="p-1"
    *ngIf="pspSignatureError || customerSignatureError"
  >
    <p class="text-start fw-bold">
      {{
        (pspSignatureError && customerSignatureError ? 'operations.signErrorHeaderBoth' : 'operations.signErrorHeader')
          | translate
      }}
    </p>
    <div
      class="text-start m-0"
      *ngIf="pspSignatureError"
    >
      <p class="fw-bold m-0">{{ 'psp' | translate }}:</p>
      <p>
        {{
          'operations.signErrorMessage'
            | translate: { fullName: contractSignedByPSP, date: contractSignedAtPSP | baseFormatDate: true }
        }}
      </p>
    </div>
    <div
      class="text-start m-0"
      *ngIf="customerSignatureError"
    >
      <p class="fw-bold m-0">{{ 'customer.singular' | translate }}:</p>
      <p class="m-0">
        {{
          'operations.signErrorMessage'
            | translate: { fullName: contractSignedByCustomer, date: contractSignedAtCustomer | baseFormatDate: true }
        }}
      </p>
    </div>
  </div>
</ng-template>
