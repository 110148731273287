<form
  class="d-flex flex-column gap-5"
  [formGroup]="form"
  [ngClass]="{ 'was-validated': isFormSubmitted }"
>
  <base-password
    label="auth.password.new"
    formControlName="newPassword"
    invalidIcon="fa-solid fa-xmark"
    [isInvalid]="!!form.get('newPassword')?.errors"
    [errors]="form.get('newPassword')?.errors"
    [errorMessageOverride]="{ required: 'password' }"
    [isLabelPositionLeft]="true"
    [isEyeIcon]="true"
    [isRequired]="true"
  ></base-password>

  <base-password
    label="auth.password.repeat"
    formControlName="newPasswordConfirm"
    invalidIcon="fa-solid fa-xmark"
    [isInvalid]="!!form.get('newPasswordConfirm')?.errors"
    [errors]="form.get('newPasswordConfirm')?.errors"
    [errorMessageOverride]="{ required: 'confirmPasswordRequired', isMatching: 'confirmPasswordInvalid' }"
    [isLabelPositionLeft]="true"
    [isEyeIcon]="true"
    [isRequired]="true"
  ></base-password>

  <div class="p-0 mb-5 mt-3 d-flex justify-content-between">
    <base-button
      color="secondary"
      label="action.cancel"
      (btnClick)="cancelPasswordChange()"
    ></base-button>
    <base-button
      color="primary"
      label="auth.password.changeTitle"
      (btnClick)="confirmPasswordChange()"
    ></base-button>
  </div>
</form>
