/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CustomerResponse } from '../models/customer-response';
import { DepartmentListItemResponse } from '../models/department-list-item-response';
import { DocumentListItemResponse } from '../models/document-list-item-response';
import { EmptySuccessfulResponse } from '../models/empty-successful-response';
import { GeneralApiResponse } from '../models/general-api-response';
import { ListApiResponse } from '../models/list-api-response';
import { PspCustomerLocationShowResponse } from '../models/psp-customer-location-show-response';
import { PriceListRequest } from '../models/price-list-request';
import { PspCustomerSurchargeResponse } from '../models/psp-customer-surcharge-response';
import { QualificationPricingListResponse } from '../models/qualification-pricing-list-response';
import { QualificationPricingResponse } from '../models/qualification-pricing-response';

@Injectable({
  providedIn: 'root',
})
export class PspCustomerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getPspCustomerContractTemplateDownload
   */
  static readonly GetPspCustomerContractTemplateDownloadPath = '/api/web/v1/psps/{psp}/customers/{customer}/contract-template/file';

  /**
   * Download PSP related customer template file.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_OFFERS'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPspCustomerContractTemplateDownload()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPspCustomerContractTemplateDownload$Response(params: {
    psp: number;
    customer: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, PspCustomerService.GetPspCustomerContractTemplateDownloadPath, 'get');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.path('customer', params.customer, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'multipart/form-data',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Download PSP related customer template file.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_OFFERS'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPspCustomerContractTemplateDownload$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPspCustomerContractTemplateDownload(params: {
    psp: number;
    customer: number;
    context?: HttpContext
  }
): Observable<Blob> {

    return this.getPspCustomerContractTemplateDownload$Response(params).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Path part for operation getPspCustomerShow
   */
  static readonly GetPspCustomerShowPath = '/api/web/v1/psps/{psp}/customers/{customer}';

  /**
   * Get PSP Customer data.
   *
   * **Accessible only for users with permission 'FEATURE_VIEW_RELATED_ORG'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPspCustomerShow()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPspCustomerShow$Response(params: {
    psp: number;
    customer: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<GeneralApiResponse & {
'data'?: CustomerResponse;
}>> {

    const rb = new RequestBuilder(this.rootUrl, PspCustomerService.GetPspCustomerShowPath, 'get');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.path('customer', params.customer, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GeneralApiResponse & {
        'data'?: CustomerResponse;
        }>;
      })
    );
  }

  /**
   * Get PSP Customer data.
   *
   * **Accessible only for users with permission 'FEATURE_VIEW_RELATED_ORG'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPspCustomerShow$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPspCustomerShow(params: {
    psp: number;
    customer: number;
    context?: HttpContext
  }
): Observable<GeneralApiResponse & {
'data'?: CustomerResponse;
}> {

    return this.getPspCustomerShow$Response(params).pipe(
      map((r: StrictHttpResponse<GeneralApiResponse & {
'data'?: CustomerResponse;
}>) => r.body as GeneralApiResponse & {
'data'?: CustomerResponse;
})
    );
  }

  /**
   * Path part for operation getPspCustomerLocationDepartmentsList
   */
  static readonly GetPspCustomerLocationDepartmentsListPath = '/api/web/v1/psps/{psp}/customers/{customer}/locations/{location}/departments';

  /**
   * Get list of customer departments.
   *
   * **Accessible only for users with permission 'FEATURE_VIEW_RELATED_ORG'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPspCustomerLocationDepartmentsList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPspCustomerLocationDepartmentsList$Response(params: {
    psp: number;
    customer: number;
    location: number;

    /**
     * Filter active or inactive customer departments
     */
    active?: any;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ListApiResponse & {
'data'?: Array<DepartmentListItemResponse>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, PspCustomerService.GetPspCustomerLocationDepartmentsListPath, 'get');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.path('customer', params.customer, {});
      rb.path('location', params.location, {});
      rb.query('active', params.active, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListApiResponse & {
        'data'?: Array<DepartmentListItemResponse>;
        }>;
      })
    );
  }

  /**
   * Get list of customer departments.
   *
   * **Accessible only for users with permission 'FEATURE_VIEW_RELATED_ORG'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPspCustomerLocationDepartmentsList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPspCustomerLocationDepartmentsList(params: {
    psp: number;
    customer: number;
    location: number;

    /**
     * Filter active or inactive customer departments
     */
    active?: any;
    context?: HttpContext
  }
): Observable<ListApiResponse & {
'data'?: Array<DepartmentListItemResponse>;
}> {

    return this.getPspCustomerLocationDepartmentsList$Response(params).pipe(
      map((r: StrictHttpResponse<ListApiResponse & {
'data'?: Array<DepartmentListItemResponse>;
}>) => r.body as ListApiResponse & {
'data'?: Array<DepartmentListItemResponse>;
})
    );
  }

  /**
   * Path part for operation getPspCustomerLocationDocumentFileDownload
   */
  static readonly GetPspCustomerLocationDocumentFileDownloadPath = '/api/web/v1/psps/{psp}/customers/{customer}/locations/{location}/documents/{document}/file';

  /**
   * Download a file.
   *
   * **Accessible only for users with permission 'FEATURE_VIEW_RELATED_ORG'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPspCustomerLocationDocumentFileDownload()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPspCustomerLocationDocumentFileDownload$Response(params: {
    psp: number;
    customer: number;
    location: number;
    document: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, PspCustomerService.GetPspCustomerLocationDocumentFileDownloadPath, 'get');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.path('customer', params.customer, {});
      rb.path('location', params.location, {});
      rb.path('document', params.document, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'multipart/form-data',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Download a file.
   *
   * **Accessible only for users with permission 'FEATURE_VIEW_RELATED_ORG'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPspCustomerLocationDocumentFileDownload$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPspCustomerLocationDocumentFileDownload(params: {
    psp: number;
    customer: number;
    location: number;
    document: string;
    context?: HttpContext
  }
): Observable<Blob> {

    return this.getPspCustomerLocationDocumentFileDownload$Response(params).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Path part for operation getPspCustomerLocationDocumentList
   */
  static readonly GetPspCustomerLocationDocumentListPath = '/api/web/v1/psps/{psp}/customers/{customer}/locations/{location}/documents';

  /**
   * Get list of documents.
   *
   * **Accessible only for users with permission 'FEATURE_VIEW_RELATED_ORG'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPspCustomerLocationDocumentList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPspCustomerLocationDocumentList$Response(params: {
    psp: number;
    customer: number;
    location: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ListApiResponse & {
'data'?: Array<DocumentListItemResponse>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, PspCustomerService.GetPspCustomerLocationDocumentListPath, 'get');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.path('customer', params.customer, {});
      rb.path('location', params.location, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListApiResponse & {
        'data'?: Array<DocumentListItemResponse>;
        }>;
      })
    );
  }

  /**
   * Get list of documents.
   *
   * **Accessible only for users with permission 'FEATURE_VIEW_RELATED_ORG'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPspCustomerLocationDocumentList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPspCustomerLocationDocumentList(params: {
    psp: number;
    customer: number;
    location: number;
    context?: HttpContext
  }
): Observable<ListApiResponse & {
'data'?: Array<DocumentListItemResponse>;
}> {

    return this.getPspCustomerLocationDocumentList$Response(params).pipe(
      map((r: StrictHttpResponse<ListApiResponse & {
'data'?: Array<DocumentListItemResponse>;
}>) => r.body as ListApiResponse & {
'data'?: Array<DocumentListItemResponse>;
})
    );
  }

  /**
   * Path part for operation getPspCustomerLocation
   */
  static readonly GetPspCustomerLocationPath = '/api/web/v1/psps/{psp}/customers/{customer}/locations/{location}';

  /**
   * Get Location.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPspCustomerLocation()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPspCustomerLocation$Response(params: {
    psp: number;
    customer: number;
    location: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<GeneralApiResponse & {
'data'?: PspCustomerLocationShowResponse;
}>> {

    const rb = new RequestBuilder(this.rootUrl, PspCustomerService.GetPspCustomerLocationPath, 'get');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.path('customer', params.customer, {});
      rb.path('location', params.location, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GeneralApiResponse & {
        'data'?: PspCustomerLocationShowResponse;
        }>;
      })
    );
  }

  /**
   * Get Location.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPspCustomerLocation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPspCustomerLocation(params: {
    psp: number;
    customer: number;
    location: number;
    context?: HttpContext
  }
): Observable<GeneralApiResponse & {
'data'?: PspCustomerLocationShowResponse;
}> {

    return this.getPspCustomerLocation$Response(params).pipe(
      map((r: StrictHttpResponse<GeneralApiResponse & {
'data'?: PspCustomerLocationShowResponse;
}>) => r.body as GeneralApiResponse & {
'data'?: PspCustomerLocationShowResponse;
})
    );
  }

  /**
   * Path part for operation getAppApiWebV1PspCustomerPriceQualificationListIndex
   */
  static readonly GetAppApiWebV1PspCustomerPriceQualificationListIndexPath = '/api/web/v1/psps/{psp}/customers/{customer}/locations/{location}/prices';

  /**
   * Get PSP Customer prices.
   *
   * **Accessible only for users with roles 'ROLE_PSP_USER', 'ROLE_CUSTOMER_USER'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAppApiWebV1PspCustomerPriceQualificationListIndex()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAppApiWebV1PspCustomerPriceQualificationListIndex$Response(params: {
    psp: number;
    customer: number;
    location: number;

    /**
     * Qualification category id
     */
    category?: number;

    /**
     * Filter by tag qualification.
     */
    tag?: 'business' | 'medical';
    context?: HttpContext
  }
): Observable<StrictHttpResponse<GeneralApiResponse & {
'data'?: QualificationPricingListResponse;
}>> {

    const rb = new RequestBuilder(this.rootUrl, PspCustomerService.GetAppApiWebV1PspCustomerPriceQualificationListIndexPath, 'get');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.path('customer', params.customer, {});
      rb.path('location', params.location, {});
      rb.query('category', params.category, {});
      rb.query('tag', params.tag, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GeneralApiResponse & {
        'data'?: QualificationPricingListResponse;
        }>;
      })
    );
  }

  /**
   * Get PSP Customer prices.
   *
   * **Accessible only for users with roles 'ROLE_PSP_USER', 'ROLE_CUSTOMER_USER'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAppApiWebV1PspCustomerPriceQualificationListIndex$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAppApiWebV1PspCustomerPriceQualificationListIndex(params: {
    psp: number;
    customer: number;
    location: number;

    /**
     * Qualification category id
     */
    category?: number;

    /**
     * Filter by tag qualification.
     */
    tag?: 'business' | 'medical';
    context?: HttpContext
  }
): Observable<GeneralApiResponse & {
'data'?: QualificationPricingListResponse;
}> {

    return this.getAppApiWebV1PspCustomerPriceQualificationListIndex$Response(params).pipe(
      map((r: StrictHttpResponse<GeneralApiResponse & {
'data'?: QualificationPricingListResponse;
}>) => r.body as GeneralApiResponse & {
'data'?: QualificationPricingListResponse;
})
    );
  }

  /**
   * Path part for operation postAppApiWebV1PspCustomerPriceUpdateIndex
   */
  static readonly PostAppApiWebV1PspCustomerPriceUpdateIndexPath = '/api/web/v1/psps/{psp}/customers/{customer}/locations/{location}/prices';

  /**
   * Create or update existent PSP Customer prices.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_PRICE_LIST'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postAppApiWebV1PspCustomerPriceUpdateIndex()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postAppApiWebV1PspCustomerPriceUpdateIndex$Response(params: {
    psp: number;
    customer: number;
    location: number;
    context?: HttpContext
    body: PriceListRequest
  }
): Observable<StrictHttpResponse<EmptySuccessfulResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PspCustomerService.PostAppApiWebV1PspCustomerPriceUpdateIndexPath, 'post');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.path('customer', params.customer, {});
      rb.path('location', params.location, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmptySuccessfulResponse>;
      })
    );
  }

  /**
   * Create or update existent PSP Customer prices.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_PRICE_LIST'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postAppApiWebV1PspCustomerPriceUpdateIndex$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postAppApiWebV1PspCustomerPriceUpdateIndex(params: {
    psp: number;
    customer: number;
    location: number;
    context?: HttpContext
    body: PriceListRequest
  }
): Observable<EmptySuccessfulResponse> {

    return this.postAppApiWebV1PspCustomerPriceUpdateIndex$Response(params).pipe(
      map((r: StrictHttpResponse<EmptySuccessfulResponse>) => r.body as EmptySuccessfulResponse)
    );
  }

  /**
   * Path part for operation getAppApiWebV1PspCustomerPriceQualificationShowIndex
   */
  static readonly GetAppApiWebV1PspCustomerPriceQualificationShowIndexPath = '/api/web/v1/psps/{psp}/customers/{customer}/locations/{location}/qualifications/{qualification}/prices';

  /**
   * Get PSP Customer qualification price.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_OFFERS'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAppApiWebV1PspCustomerPriceQualificationShowIndex()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAppApiWebV1PspCustomerPriceQualificationShowIndex$Response(params: {
    psp: number;
    customer: number;
    location: number;
    qualification: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<GeneralApiResponse & {
'data'?: QualificationPricingResponse;
}>> {

    const rb = new RequestBuilder(this.rootUrl, PspCustomerService.GetAppApiWebV1PspCustomerPriceQualificationShowIndexPath, 'get');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.path('customer', params.customer, {});
      rb.path('location', params.location, {});
      rb.path('qualification', params.qualification, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GeneralApiResponse & {
        'data'?: QualificationPricingResponse;
        }>;
      })
    );
  }

  /**
   * Get PSP Customer qualification price.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_OFFERS'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAppApiWebV1PspCustomerPriceQualificationShowIndex$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAppApiWebV1PspCustomerPriceQualificationShowIndex(params: {
    psp: number;
    customer: number;
    location: number;
    qualification: number;
    context?: HttpContext
  }
): Observable<GeneralApiResponse & {
'data'?: QualificationPricingResponse;
}> {

    return this.getAppApiWebV1PspCustomerPriceQualificationShowIndex$Response(params).pipe(
      map((r: StrictHttpResponse<GeneralApiResponse & {
'data'?: QualificationPricingResponse;
}>) => r.body as GeneralApiResponse & {
'data'?: QualificationPricingResponse;
})
    );
  }

  /**
   * Path part for operation getPspCustomerSurcharges
   */
  static readonly GetPspCustomerSurchargesPath = '/api/web/v1/psps/{psp}/customers/{customer}/locations/{location}/surcharges';

  /**
   * Get surcharges list.
   *
   * **Accessible only for users with permission 'FEATURE_VIEW_RELATED_ORG'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPspCustomerSurcharges()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPspCustomerSurcharges$Response(params: {
    psp: number;
    customer: number;
    location: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<GeneralApiResponse & {
'data'?: PspCustomerSurchargeResponse;
}>> {

    const rb = new RequestBuilder(this.rootUrl, PspCustomerService.GetPspCustomerSurchargesPath, 'get');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.path('customer', params.customer, {});
      rb.path('location', params.location, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GeneralApiResponse & {
        'data'?: PspCustomerSurchargeResponse;
        }>;
      })
    );
  }

  /**
   * Get surcharges list.
   *
   * **Accessible only for users with permission 'FEATURE_VIEW_RELATED_ORG'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPspCustomerSurcharges$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPspCustomerSurcharges(params: {
    psp: number;
    customer: number;
    location: number;
    context?: HttpContext
  }
): Observable<GeneralApiResponse & {
'data'?: PspCustomerSurchargeResponse;
}> {

    return this.getPspCustomerSurcharges$Response(params).pipe(
      map((r: StrictHttpResponse<GeneralApiResponse & {
'data'?: PspCustomerSurchargeResponse;
}>) => r.body as GeneralApiResponse & {
'data'?: PspCustomerSurchargeResponse;
})
    );
  }

}
