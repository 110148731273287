import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  BASE_PERMISSIONS,
  BASE_ROLES,
  BaseAuthGuard,
  BaseCountryListGuard,
  BaseErrorComponent,
  BaseProfileGuard,
  BaseRefreshGuard,
} from 'base';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { WrapperComponent } from './modules/shared/_components/wrapper/wrapper.component';
import { GrowthPspDataGuard } from './modules/shared/_guards/growth-psp-data.guard';

const routes: Routes = [
  {
    path: '',
    canActivate: [BaseRefreshGuard],
    children: [
      { path: '', redirectTo: 'auth', pathMatch: 'full' },
      {
        path: 'auth',
        loadChildren: () => import('./modules/auth/auth.module').then((m) => m.AuthModule),
      },
      {
        path: 'dashboard',
        canActivate: [BaseAuthGuard, BaseProfileGuard, GrowthPspDataGuard],
        component: WrapperComponent,
        loadChildren: () => import('./modules/dashboard/dashboard.module').then((m) => m.DashboardModule),
      },
      {
        path: 'administration',
        canActivate: [BaseAuthGuard, BaseCountryListGuard, BaseProfileGuard, GrowthPspDataGuard],
        data: {
          permissions: {
            only: [],
            redirectTo: 'dashboard',
          },
        },
        component: WrapperComponent,
        loadChildren: () =>
          import('./modules/administration/administration.module').then((m) => m.AdministrationModule),
      },
      {
        path: 'candidates',
        component: WrapperComponent,
        canActivate: [BaseAuthGuard, NgxPermissionsGuard, BaseProfileGuard, GrowthPspDataGuard],
        data: {
          permissions: {
            only: [BASE_ROLES.PSP_ADMIN, BASE_PERMISSIONS.FEATURE_ADMIN_PROFILES],
            redirectTo: 'dashboard',
          },
        },
        loadChildren: () => import('./modules/candidates/candidates.module').then((m) => m.CandidatesModule),
      },
      {
        path: 'inquiries',
        component: WrapperComponent,
        canActivate: [BaseAuthGuard, BaseProfileGuard, GrowthPspDataGuard],
        loadChildren: () => import('./modules/inquiry/inquiry.module').then((m) => m.InquiryModule),
      },
      {
        path: 'customer-locations',
        component: WrapperComponent,
        canActivate: [BaseAuthGuard, BaseProfileGuard, GrowthPspDataGuard],
        loadChildren: () =>
          import('./modules/related-customer-locations/related-customer-locations.module').then(
            (m) => m.RelatedCustomerLocationsModule
          ),
      },
      {
        path: 'operations',
        component: WrapperComponent,
        canActivate: [BaseAuthGuard, NgxPermissionsGuard, BaseProfileGuard, GrowthPspDataGuard],
        data: {
          permissions: {
            only: [BASE_ROLES.PSP_ADMIN, BASE_PERMISSIONS.FEATURE_ADMIN_ASSIGNMENTS],
            redirectTo: '/403',
          },
        },
        loadChildren: () => import('./modules/operations/operations.module').then((m) => m.OperationsModule),
      },
      {
        path: 'offers-and-bookings',
        component: WrapperComponent,
        canActivate: [BaseAuthGuard, NgxPermissionsGuard],
        data: {
          permissions: {
            only: [],
            redirectTo: '/403',
          },
        },
        loadChildren: () =>
          import('./modules/offers-and-bookings/offers-and-bookings.module').then((m) => m.OffersAndBookingsModule),
      },
      {
        path: 'time-recordings',
        component: WrapperComponent,
        canActivate: [BaseAuthGuard, BaseProfileGuard, GrowthPspDataGuard],
        data: {
          permissions: {
            only: [
              BASE_ROLES.PSP_ADMIN,
              BASE_PERMISSIONS.FEATURE_ADMIN_ASSIGNMENTS,
              BASE_PERMISSIONS.FEATURE_ADMIN_ASSIGNMENT_DATES,
            ],
            redirectTo: '403',
          },
        },
        loadChildren: () =>
          import('./modules/time-recordings/time-recordings.module').then((m) => m.TimeRecordingsModule),
      },

      // System pages
      {
        path: '403',
        component: BaseErrorComponent,
        data: {
          title: 'systemPage.403.headText',
          is404: false,
        },
      },
      {
        path: '404',
        component: BaseErrorComponent,
        data: {
          title: 'systemPage.404.headText',
          is404: true,
        },
      },
      { path: '**', redirectTo: '404' },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
