import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { BaseCoreState, baseInquiryActions, baseInquirySelectors } from '../../../_store';
import { InquiryBestCandidatesPagination } from '../_enums/inquiry-candidates-pagination.enum';

@Injectable()
export class BaseUnlimitedInquiryCandidatesGuard {
  constructor(private readonly store: Store<BaseCoreState>) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    this.store.dispatch(
      baseInquiryActions.loadInquiryCandidatesList({
        payload: {
          inquiry: +route.parent?.params.inquiry,
          isUnlimited: true,
          page: InquiryBestCandidatesPagination.page,
          size: InquiryBestCandidatesPagination.size,
          ...route.queryParams,
        },
      })
    );
    return this.store.select(baseInquirySelectors.getInquiryCandidates).pipe(
      filter((response) => response.ok !== undefined),
      map((response) => !!response.ok)
    );
  }
}
