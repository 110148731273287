<div class="teaser d-flex mx-3">
  <div
    class="me-3 logo"
    (click)="isUpdateAvatar ? avatarUploadInput.click() : null"
  >
    <base-avatar
      [size]="avatarSize"
      [isCompany]="isCompany"
      [image]="isCompany ? companyData?.logo.data : (userProfile$ | async)?.avatar?.data"
    ></base-avatar>
    <input
      #avatarUploadInput
      type="file"
      id="logo"
      (change)="onAvatarUpdate($event)"
      hidden
      [accept]="allowedImageTypes"
    />
    <ng-template #cropperTemplate>
      <base-image-cropper
        [imageFile]="selectedImage"
        [maintainAspectRatio]="!isCompany"
        [roundCropper]="!isCompany"
        (imageCropped)="onImageCrop($event)"
      ></base-image-cropper>
    </ng-template>
    <base-button
      *ngIf="isUpdateAvatar"
      [size]="buttonSizes.sm"
      [iconSize]="buttonSizes.sm"
      tooltip="action.edit"
      color="dark"
      icon="fa-solid fa-pen"
    ></base-button>
  </div>
  <div class="d-flex flex-column justify-content-center">
    <h4
      class="my-0"
      *ngIf="!isCompany"
    >
      {{ (userProfile$ | async)?.firstName }} {{ (userProfile$ | async)?.lastName }}
    </h4>
    <h4
      class="my-0"
      *ngIf="isCompany"
    >
      {{ companyData?.primaryData?.legalName }}
    </h4>
    <span *ngIf="!isCompany">{{ companyData?.primaryData?.legalName }}</span>
  </div>
</div>
