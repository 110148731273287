import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Validators, FormGroup, NonNullableFormBuilder } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subject, noop } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { GroupListItemResponse, UserItemResponse, UserResponse } from '../../../../../../api';
import { BaseRolesService } from '../../../../../core/services/base-roles.service';
import { BaseAvatarSize } from '../../../../../shared/components/avatar/base-avatar.component';
import { BaseButtonSize } from '../../../../../shared/components/button/base-button.component';
import { BaseGenderOptions } from '../../../../../shared/constants/base-gender-options';
import { BaseUserProfileModel } from '../../../_models/base-user-profile.model';
import { BaseModalService } from '../../../../../shared/services/base-modal.service';
import { baseAdministrationActions, baseAdministrationSelectors, BaseCoreState } from '../../../../../_store';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { basePasswordValidator } from '../../../../../shared/validators/base-password.validator';
import { baseMatchValuesValidator } from '../../../../../shared/validators/base-match-values.validator';
import {
  EditUserFormInterface,
  BaseChangePasswordFormInterface,
} from '../../../_interfaces/base-user-management.interface';
import { BASE_ROLES } from '../../../../../core/constants/base-roles';

@Component({
  selector: 'base-edit-user-form',
  templateUrl: './base-edit-user-form.component.html',
  styleUrls: ['./base-edit-user-form.component.scss'],
})
export class BaseEditUserFormComponent implements OnInit, OnDestroy {
  @ViewChild('changePasswordTemplate', { static: true }) changePasswordTemplate: ElementRef;
  @Input() form: FormGroup<EditUserFormInterface>;
  @Input() user: UserItemResponse;
  @Input() isFormSubmitted: boolean;
  @Input() groups: GroupListItemResponse[];
  @Output() editUser: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() cancelUserEdit: EventEmitter<boolean> = new EventEmitter<boolean>();

  modalRef: NgbModalRef;
  userData: UserResponse;
  isChangePasswordFormSubmitted = false;
  avatarSizes = BaseAvatarSize;
  buttonSizes = BaseButtonSize;
  genderOptions = BaseGenderOptions;
  isAdmin = false;
  changePasswordForm: FormGroup<BaseChangePasswordFormInterface>;

  private destroy$ = new Subject();

  constructor(
    private store: Store<BaseCoreState>,
    private modalService: BaseModalService,
    private fb: NonNullableFormBuilder,
    private rolesService: BaseRolesService
  ) {}

  ngOnInit(): void {
    this.isAdmin = this.rolesService.hasAdminRole();

    this.changePasswordForm = this.fb.group({
      newPassword: ['', [Validators.required, basePasswordValidator(), Validators.maxLength(30)]],
      newPasswordConfirm: this.fb.control('', {
        validators: Validators.compose([Validators.required, baseMatchValuesValidator('newPassword')]),
      }),
    });
    this.store
      .select(baseAdministrationSelectors.getUserProfile)
      .pipe(
        takeUntil(this.destroy$),
        filter((state) => !!state.ok),
        map((state) => state.data as UserResponse)
      )
      .subscribe((user) => {
        this.userData = user;
        this.form.patchValue(new BaseUserProfileModel(user));
        this.groups.forEach((group, index) => {
          if (group.id === this.userData.group?.id) {
            this.form.get('group')?.setValue(this.groups[index]);
          }
        });
      });

    this.form.controls.isAdministrator.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      if (!!value) {
        this.form.controls.group.disable();
      } else {
        this.form.controls.group.enable();
        this.form.controls.group.setValue(null);
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }

  confirmEditUser(): void {
    this.editUser.emit();
  }

  cancelEditUser(): void {
    this.cancelUserEdit.emit();
  }

  openChangePasswordModal(): void {
    this.isChangePasswordFormSubmitted = false;
    this.changePasswordForm.reset({
      newPassword: '',
      newPasswordConfirm: '',
    });
    this.changePasswordForm.controls.newPassword.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.changePasswordForm.controls.newPasswordConfirm.setValue(
        this.changePasswordForm.controls.newPasswordConfirm.value
      );
    });
    this.modalRef = this.modalService.open(
      {
        headText: 'auth.password.changeTitle',
        bodyTemplate: this.changePasswordTemplate,
        okButtonDisplayed: false,
        cancelButtonDisplayed: false,
        hideFooter: true,
      },
      {
        backdrop: 'static',
        size: 'lg',
      }
    );

    this.modalRef.result.then(noop, noop);
  }

  changeUserPassword(): void {
    this.isChangePasswordFormSubmitted = true;
    if (this.changePasswordForm.valid) {
      this.store.dispatch(
        baseAdministrationActions.updateUserPassword({
          payload: {
            user: this.user.party,
            body: { newPassword: this.changePasswordForm.get('newPassword')?.getRawValue() },
          },
        })
      );
      this.store
        .select(baseAdministrationSelectors.getUserPasswordUpdateStatus)
        .pipe(
          takeUntil(this.destroy$),
          filter((profilePasswordStatus) => !!profilePasswordStatus)
        )
        .subscribe(() => {
          this.modalRef.close();
        });
    }
  }

  cancelChangeUserPassword(): void {
    this.modalRef.close();
  }
}
