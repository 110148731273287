import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Subject } from 'rxjs';
import { BaseQueryParamService } from '../../services/base-query-param.service';

@Component({
  selector: 'base-search',
  templateUrl: './base-search.component.html',
})
export class BaseSearchComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('input', { static: false }) input: ElementRef;
  @Input() name?: string;
  @Input() noValidation = false;
  @Input() placeholder = 'search.general';
  @Input() useEnterEvent = false;
  @Input() rounded = true;
  @Input() backgroundColor: string;
  @Input() width: string;
  @Output() query: EventEmitter<string> = new EventEmitter<string>();
  @Input() value = '';
  bgColorClasses: string;
  private stream = new Subject();

  constructor(private baseQueryParamService: BaseQueryParamService) {}

  get changes(): string {
    return this.value;
  }

  set changes(value: string) {
    this.value = value;

    if (!this.useEnterEvent) {
      this.query.emit(this.value);
      this.stream.next(this.value);
    }
  }

  ngOnInit() {
    this.bgColorClasses = !!this.backgroundColor ? `bg-${this.backgroundColor} search` : 'search';
  }

  ngAfterViewInit() {
    this.focusFilterField();
  }

  ngOnDestroy() {
    this.changes = '';
    this.value = '';
    this.stream.next('');
    this.stream.complete();
  }

  resetFilterValue() {
    this.changes = '';

    if (this.useEnterEvent) {
      this.value = '';
      this.query.emit(this.value);
    }
  }

  clearFilter(event: Event): void {
    event.stopPropagation();
    this.resetFilterValue();
    this.focusFilterField();
    if (this.name) {
      this.baseQueryParamService.removeParameter(this.name);
    }
  }

  focusFilterField() {
    if (this.input?.nativeElement) {
      this.input.nativeElement.focus();
      this.input.nativeElement.select();
    }
  }

  onEnter(): void {
    if (this.useEnterEvent) {
      this.stream.next(this.value);
      if (this.name && !this.value) {
        this.baseQueryParamService.removeParameter(this.name);
        this.resetFilterValue();
      } else {
        this.query.emit(this.value);
      }
    }
  }
}
