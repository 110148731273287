import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  BranchListItemResponse,
  Category,
  CustomerDepartmentQualificationsListResponse,
  DepartmentListItemResponse,
  DocumentCategoryResponse,
  MetaPagination,
  NotificationsListResponse,
  PspCustomerSurchargeResponse,
  QualificationPricingListItemResponse,
  QualificationShortInfoResponse,
  QualificationsListItemResponse,
  RequirementCategoryResponse,
  UserItemResponse,
  UserProfileResponse,
  IndustrySurcharge,
} from '../../../api';
import { BaseToastAlertInfoInterface } from '../../shared/interfaces/base-toast-alert-info.interface';
import { BaseCountry } from '../../shared/services/base-country.service';
import { SharedState } from '../reducers/base-shared.reducer';

export const getShared = createFeatureSelector<SharedState>('shared');

export const getCountryList = createSelector(
  getShared,
  (state: SharedState): { data: BaseCountry[]; ok: boolean | undefined } => state?.countryList
);

export const getCountryListStatus = createSelector(
  getShared,
  (state: SharedState): boolean | undefined => state?.countryList.ok
);

export const getQualifications = createSelector(
  getShared,
  (state: any): { data: Array<QualificationShortInfoResponse> | undefined; ok: boolean | undefined } =>
    state?.qualifications
);

export const getQualificationCategories = createSelector(
  getShared,
  (state: any): { data: Array<Category> | undefined; ok: boolean | undefined } => state?.qualificationCategories
);

export const getCustomerDepartmentQualificationsList = createSelector(
  getShared,
  (
    state: any
  ): {
    data: Array<CustomerDepartmentQualificationsListResponse> | undefined;
    ok: boolean | undefined;
  } => state?.customerDepartmentQualifications
);

export const getBranches = createSelector(
  getShared,
  (state: any): { data: Array<BranchListItemResponse> | undefined; ok: boolean | undefined } => state?.branches
);

export const getProfile = createSelector(
  getShared,
  (state: SharedState): { data: UserProfileResponse | undefined; ok: boolean | undefined } => state?.profileData
);

export const getProfileData = createSelector(
  getShared,
  (state: SharedState): UserProfileResponse | undefined => state?.profileData?.data
);

export const getRequirements = createSelector(
  getShared,
  (state: SharedState): { data: Array<RequirementCategoryResponse> | undefined; ok: boolean | undefined } =>
    state?.requirements
);

export const getDepartments = createSelector(
  getShared,
  (state: SharedState): { data: Array<DepartmentListItemResponse> | undefined; ok: boolean | undefined } =>
    state?.departments
);

export const getDepartmentUsers = createSelector(
  getShared,
  (state: SharedState): { data: Array<UserItemResponse> | undefined; ok: boolean | undefined } => state?.departmentUsers
);

export const getDocumentCategories = createSelector(
  getShared,
  (state: SharedState): { data: DocumentCategoryResponse | undefined; ok: boolean | undefined } =>
    state.documentCategories
);

export const getPspPriceList = createSelector(
  getShared,
  (state: SharedState): { data: QualificationPricingListItemResponse[]; ok: boolean | undefined } => state.pspPriceList
);

export const getDefaultShiftTimes = createSelector(getShared, (state: any) => state.defaultShiftTimes);
export const getPSPCustomerSurcharges = createSelector(
  getShared,
  (
    state: any
  ): {
    data: PspCustomerSurchargeResponse | undefined;
    ok: boolean | undefined;
  } => state.pspCustomerSurcharges
);

export const getUserNotifications = createSelector(
  getShared,
  (
    state: SharedState
  ): {
    data: NotificationsListResponse | undefined;
    pagination: MetaPagination | undefined;
    ok: boolean | undefined;
  } => state.userNotifications
);

export const getAppRouterState = createSelector(
  getShared,
  (
    state: SharedState
  ): {
    data: { previousUrl: string; currentUrl: string };
  } => state.appRouter
);

export const getCustomerDepartmentFavouriteQualifications = createSelector(
  getShared,
  (
    state: SharedState
  ): {
    data: QualificationsListItemResponse[];
    ok: boolean | undefined;
  } => state.customerDepartmentFavouriteQualifications
);

export const getCurrentLanguage = createSelector(getShared, (state: SharedState): string => state.language.current);

export const getSystemAlertsData = createSelector(
  getShared,
  (state: SharedState): BaseToastAlertInfoInterface[] => state.systemAlerts.data
);

export const getOrganisationFilterState = createSelector(
  getShared,
  (state: SharedState): boolean => state.organisationFilter.filterActive
);

export const getSelectedLocationId = createSelector(
  getShared,
  (state: SharedState): number => state.selectedLocation.id as number
);

export const getCurrentBranchUsers = createSelector(
  getShared,
  (
    state: SharedState
  ): {
    data: Array<UserItemResponse & { fullName: string }> | undefined;
    message: string | undefined;
    ok: boolean | undefined;
  } => state?.currentBranchUsers
);

export const getSidebarInitialOpening = createSelector(
  getShared,
  (state: SharedState): boolean => state.isSidebarInitialOpening
);

export const getIndustrySurcharges = createSelector(
  getShared,
  (
    state: SharedState
  ): {
    data: IndustrySurcharge[] | undefined;
    ok: boolean | undefined;
  } => state.industrySurcharges
);
