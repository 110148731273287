import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { catchError, concatMap, map, mergeMap, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { BaseToastAlertClasses } from '../../shared/enums/base-toast-alert-classes.enum';
import { BaseTokenInterface } from '../../shared/interfaces/base-token-service.interface';
import { BaseCandidatesService } from '../../modules/candidates/base-candidates.service';
import { baseCandidateActions, baseInquiryActions, baseSharedActions } from '../actions';
import { BaseCoreState } from '../reducers';
import { baseAuthSelectors, baseCandidatesSelectors } from '../selectors';
import { TypedAction } from '@ngrx/store/src/models';
import { BaseAssignmentsService } from '../../modules/assignments/base-assignments-service';
import { BaseInquiryService } from '../../modules/inquiry/_services/base-inquiry.service';
import { CreatedResponseWithBody, ProfileDocumentResponse } from '../../../api';
import { BaseAvailableFileTypeEnum } from '../../shared/enums/base-available-file-type.enum';

@Injectable()
export class BaseCandidatesEffects {
  constructor(
    private actions$: Actions,
    private store: Store<BaseCoreState>,
    private readonly candidatesService: BaseCandidatesService,
    private assignmentsService: BaseAssignmentsService,
    private inquiryService: BaseInquiryService,
    private translate: TranslateService,
    private sanitizer: DomSanitizer
  ) {}

  loadCandidates$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseCandidateActions.loadCandidates),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((user) => user.data as BaseTokenInterface)),
        this.store.select(baseCandidatesSelectors.getCandidateFilter)
      ),
      switchMap(([_, user, filter]) => {
        const params = {
          psp: user.organizationId,
          ...filter,
        };
        return this.candidatesService.loadCandidates(params).pipe(
          mergeMap((response) => [
            baseCandidateActions.setCandidates({ payload: response }),
            baseCandidateActions.successLoadCandidates(),
          ]),
          catchError(() => of(baseCandidateActions.errorLoadCandidates()))
        );
      })
    );
  });

  updateCandidate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseCandidateActions.updateCandidate),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((user) => user.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        return this.candidatesService
          .updateCandidate(user.organizationId, action.payload.profile, action.payload.body)
          .pipe(
            mergeMap(() =>
              action.payload.inquiry
                ? [
                    baseCandidateActions.successUpdateCandidate(),
                    baseCandidateActions.loadCandidateProfile({
                      payload: action.payload.profile,
                    }),
                    baseInquiryActions.loadInquiryCandidatesList({
                      payload: { inquiry: action.payload.inquiry },
                    }),
                    baseInquiryActions.loadPspInquiryOffersList({
                      payload: { inquiry: action.payload.inquiry },
                    }),
                  ]
                : [
                    baseCandidateActions.successUpdateCandidate(),
                    baseCandidateActions.loadCandidateProfile({
                      payload: action.payload.profile,
                    }),
                    baseCandidateActions.loadCandidates(),
                    baseCandidateActions.loadProfileAccountInformation({
                      payload: { profile: action.payload.profile },
                    }),
                  ]
            ),
            catchError(() => of(baseCandidateActions.errorUpdateCandidate()))
          );
      })
    );
  });

  getCandidate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseCandidateActions.loadCandidateProfile),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((user) => user.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        return this.candidatesService.getCandidate(user.organizationId, action.payload).pipe(
          mergeMap((result) => [
            baseCandidateActions.setCandidateProfile({ payload: result }),
            baseCandidateActions.successCandidateProfile(),
          ]),
          catchError(() => of(baseCandidateActions.errorCandidateProfile()))
        );
      })
    );
  });

  createCandidateProfile$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseCandidateActions.createCandidateProfile),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((user) => user.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        return this.candidatesService.createCandidate(user.organizationId, action.payload).pipe(
          mergeMap((response: CreatedResponseWithBody) => [
            baseCandidateActions.successCreateCandidateProfile({ id: response.data.id }),
            baseSharedActions.addSystemAlert({
              payload: {
                body: 'successMessage.changesSaved',
                class: BaseToastAlertClasses.success,
              },
            }),
            baseCandidateActions.loadCandidates(),
          ]),
          catchError(() => of(baseCandidateActions.errorCreateCandidateProfile()))
        );
      })
    );
  });

  uploadDocument$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseCandidateActions.uploadDocumentAction),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((user) => user.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        return this.candidatesService.uploadDocument(user.organizationId, action.payload.file).pipe(
          mergeMap((response) => [
            baseCandidateActions.setDocumentAction({ payload: response?.data?.documentId as string }),
            baseCandidateActions.successUploadDocumentAction(),
          ]),
          catchError(() => of(baseCandidateActions.errorUploadDocumentAction()))
        );
      })
    );
  });

  updateDocument$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseCandidateActions.updateDocumentAction),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((user) => user.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        return this.candidatesService
          .updateDocument(
            user.organizationId,
            action.payload?.profile as number,
            action.payload.document,
            action.payload.body
          )
          .pipe(
            tap(() => {
              if (action.payload.reloadDocumentsOnly) {
                this.store.dispatch(
                  baseCandidateActions.loadCandidateProfileDocuments({
                    payload: { profile: action.payload?.profile as number },
                  })
                );
              } else {
                this.store.dispatch(
                  baseCandidateActions.loadCandidateProfile({
                    payload: action.payload?.profile as number,
                  })
                );
              }
            })
          );
      }),
      mergeMap(() => [baseCandidateActions.successUploadDocumentAction()]),
      catchError(() => of(baseCandidateActions.errorUploadDocumentAction()))
    );
  });

  downloadDocumentFile$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseCandidateActions.downloadCandidateDocumentFile),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((state) => state.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        return this.candidatesService
          .downloadCandidateDocumentFile({
            profile: action.payload.profile as number,
            document: action.payload.document,
            psp: user.organizationId,
          })
          .pipe(
            tap((response: any) => {
              if (!action.payload.openInModal) {
                const blob = new Blob([response.body], { type: BaseAvailableFileTypeEnum.applicationPDF });
                const objectUrl = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.setAttribute('href', objectUrl);
                link.setAttribute('download', response.fileName);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
              }
            }),
            !action.payload.openInModal
              ? map(() => baseCandidateActions.successDownloadCandidateDocumentFile())
              : mergeMap((response) => {
                  return [
                    baseCandidateActions.setDownloadCandidateDocumentFile({
                      payload: {
                        document:
                          action.payload.documentType === BaseAvailableFileTypeEnum.pdf
                            ? new Blob([response.body], { type: BaseAvailableFileTypeEnum.applicationPDF })
                            : this.sanitizer.bypassSecurityTrustUrl(
                                URL.createObjectURL(
                                  new Blob([response.body], { type: `image/${action.payload.documentType}` })
                                )
                              ),
                      },
                    }),
                    baseCandidateActions.successDownloadCandidateDocumentFile(),
                  ];
                }),
            catchError(() => of(baseCandidateActions.errorDownloadCandidateDocumentFile()))
          );
      })
    );
  });

  deleteDocument$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseCandidateActions.deleteDocumentAction),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((user) => user.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        return this.candidatesService
          .deleteDocument(user.organizationId, action.payload.profile as number, action.payload.document)
          .pipe(
            mergeMap(() => [
              baseCandidateActions.successDeleteDocumentAction(),
              baseCandidateActions.loadCandidateProfile({
                payload: action.payload.profile as number,
              }),
            ]),
            catchError(() => of(baseCandidateActions.errorDeleteDocumentAction()))
          );
      })
    );
  });

  getAbsences$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseCandidateActions.loadAbsencesAction),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((user) => user.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        const page = action.payload.page ? action.payload.page : 1;
        return this.candidatesService
          .getAbsences(user.organizationId, action.payload.profile, page, action.payload.type, action.payload.size)
          .pipe(
            mergeMap((result) => [
              baseCandidateActions.setAbsencesAction({ payload: result }),
              baseCandidateActions.successAbsencesAction(),
            ]),
            catchError(() => of(baseCandidateActions.errorAbsencesAction()))
          );
      })
    );
  });

  getAbsenceSummaries$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseCandidateActions.loadAbsenceSummaryAction),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((user) => user.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        return this.candidatesService.getAbsenceSummary(user.organizationId, action.payload.profile).pipe(
          mergeMap((result) => [
            baseCandidateActions.setAbsenceSummariesAction({ payload: result.data }),
            baseCandidateActions.successAbsenceSummaryAction(),
          ]),
          catchError(() => of(baseCandidateActions.errorAbsenceSummaryAction()))
        );
      })
    );
  });

  getAbsence$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseCandidateActions.loadAbsenceAction),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((user) => user.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        return this.candidatesService
          .getAbsence(user.organizationId, action.payload.profile, action.payload.absence)
          .pipe(
            mergeMap((result) => [
              baseCandidateActions.setAbsenceAction({ payload: result.data }),
              baseCandidateActions.successAbsenceAction(),
            ]),
            catchError(() => of(baseCandidateActions.errorAbsenceAction()))
          );
      })
    );
  });

  createAbsence$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseCandidateActions.createAbsenceAction),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((user) => user.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        return this.candidatesService
          .createAbsence(user.organizationId, action.payload.profile, action.payload.body)
          .pipe(
            mergeMap(() => {
              const actions: TypedAction<any>[] = action.payload.inquiry
                ? [
                    baseCandidateActions.loadAbsenceSummaryAction({
                      payload: { profile: action.payload.profile },
                    }),
                    baseCandidateActions.loadAbsencesAction({
                      payload: { profile: action.payload.profile, page: 1, type: action.payload.absenceTab },
                    }),
                    baseCandidateActions.successAbsenceCrudAction(),
                    baseInquiryActions.loadInquiryCandidatesList({
                      payload: { inquiry: action.payload.inquiry },
                    }),
                    baseInquiryActions.loadPspInquiryOffersList({
                      payload: { inquiry: action.payload.inquiry },
                    }),
                  ]
                : [
                    baseCandidateActions.loadAbsenceSummaryAction({
                      payload: { profile: action.payload.profile },
                    }),
                    baseCandidateActions.loadAbsencesAction({
                      payload: { profile: action.payload.profile, page: 1, type: action.payload.absenceTab },
                    }),
                    baseCandidateActions.successAbsenceCrudAction(),
                  ];
              if (!action.payload.noLoading) {
                const loadAction = baseCandidateActions.loadAbsencesAction({
                  payload: {
                    profile: action.payload.profile,
                    page: 1,
                    type: action.payload.body.type,
                  },
                });
                actions.push(loadAction);
              }
              return actions;
            }),
            catchError(() => of(baseCandidateActions.errorAbsenceCrudAction()))
          );
      })
    );
  });

  updateAbsence$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseCandidateActions.updateAbsenceAction),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((user) => user.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        return this.candidatesService
          .updateAbsence(user.organizationId, action.payload.profile, action.payload.absence, action.payload.body)
          .pipe(
            mergeMap(() =>
              action.payload.inquiry
                ? [
                    baseCandidateActions.loadAbsenceSummaryAction({
                      payload: { profile: action.payload.profile },
                    }),
                    baseCandidateActions.loadAbsencesAction({
                      payload: { profile: action.payload.profile, page: 1, type: action.payload.absenceTab },
                    }),
                    baseCandidateActions.successAbsenceCrudAction(),
                    baseInquiryActions.loadInquiryCandidatesList({
                      payload: { inquiry: action.payload.inquiry },
                    }),
                    baseInquiryActions.loadPspInquiryOffersList({
                      payload: { inquiry: action.payload.inquiry },
                    }),
                  ]
                : [
                    baseCandidateActions.loadAbsenceSummaryAction({
                      payload: { profile: action.payload.profile },
                    }),
                    baseCandidateActions.loadAbsencesAction({
                      payload: { profile: action.payload.profile, page: 1, type: action.payload.absenceTab },
                    }),
                    baseCandidateActions.successAbsenceCrudAction(),
                  ]
            ),
            catchError(() => of(baseCandidateActions.errorAbsenceCrudAction()))
          );
      })
    );
  });

  deleteAbsence$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseCandidateActions.deleteAbsenceAction),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((user) => user.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        return this.candidatesService
          .deleteAbsence(user.organizationId, action.payload.profile, action.payload.absence)
          .pipe(
            mergeMap(() =>
              action.payload.inquiry
                ? [
                    baseCandidateActions.loadAbsenceSummaryAction({
                      payload: { profile: action.payload.profile },
                    }),
                    baseCandidateActions.loadAbsencesAction({
                      payload: { profile: action.payload.profile, page: 1, type: action.payload.absenceTab },
                    }),
                    baseCandidateActions.successAbsenceCrudAction(),
                    baseInquiryActions.loadInquiryCandidatesList({
                      payload: { inquiry: action.payload.inquiry },
                    }),
                    baseInquiryActions.loadPspInquiryOffersList({
                      payload: { inquiry: action.payload.inquiry },
                    }),
                  ]
                : [
                    baseCandidateActions.loadAbsenceSummaryAction({
                      payload: { profile: action.payload.profile },
                    }),
                    baseCandidateActions.loadAbsencesAction({
                      payload: { profile: action.payload.profile, page: 1, type: action.payload.absenceTab },
                    }),
                    baseCandidateActions.successAbsenceCrudAction(),
                  ]
            ),
            catchError(() => of(baseCandidateActions.errorAbsenceCrudAction()))
          );
      })
    );
  });

  getAbsenceConflicts$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseCandidateActions.loadAbsenceConflicts),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((user) => user.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        return this.candidatesService
          .getAbsenceConflicts(
            action.payload.profile,
            action.payload.startDate,
            action.payload.endDate,
            user.organizationId
          )
          .pipe(
            mergeMap((result) => [
              baseCandidateActions.setAbsenceConflicts({ payload: result.data }),
              baseCandidateActions.successAbsenceConflicts(),
            ]),
            catchError(() => of(baseCandidateActions.errorAbsenceConflicts()))
          );
      })
    );
  });

  avatarUpdate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseCandidateActions.updateAvatarCrudAction),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((user) => user.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        return this.candidatesService
          .updateAvatar(user.organizationId, action.payload.profile, action.payload.body)
          .pipe(
            mergeMap(() => [
              baseCandidateActions.loadCandidateProfile({
                payload: action.payload.profile,
              }),
              baseCandidateActions.successAvatarCrudAction(),
            ]),
            catchError(() => of(baseCandidateActions.errorAvatarCrudAction()))
          );
      })
    );
  });

  getProfileAccountInformation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseCandidateActions.loadProfileAccountInformation),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((user) => user.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        return this.candidatesService.getProfileAccountInformation(user.organizationId, action.payload.profile).pipe(
          mergeMap((result) => [
            baseCandidateActions.setProfileAccountInformation({ payload: result.data }),
            baseCandidateActions.successLoadProfileAccountInformation(),
          ]),
          catchError(() => of(baseCandidateActions.errorLoadProfileAccountInformation()))
        );
      })
    );
  });

  reactivateUserAccount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseCandidateActions.reactivateProfileAccount),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((user) => user.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        return this.candidatesService.reactivateProfileAccount(user.organizationId, action.payload).pipe(
          mergeMap(() => {
            this.translate
              .get('app.reactivated')
              .pipe(take(1))
              .subscribe((message) => {
                this.store.dispatch(
                  baseSharedActions.addSystemAlert({
                    payload: {
                      class: BaseToastAlertClasses.success,
                      body: message,
                    },
                  })
                );
              });
            return [
              baseCandidateActions.successReactivateProfileAccount(),
              baseCandidateActions.successProfileAccountCrud(),
            ];
          }),
          catchError(() => of(baseCandidateActions.errorProfileAccountCrud()))
        );
      })
    );
  });

  activateMobileAccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseCandidateActions.activateMobileAccess),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((user) => user.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        return this.candidatesService.activateMobileAccess(user.organizationId, action.payload).pipe(
          mergeMap(() =>
            action.inquiry
              ? [
                  baseCandidateActions.loadProfileAccountInformation({ payload: { profile: action.payload } }),
                  baseCandidateActions.successProfileAccountCrud(),
                  baseInquiryActions.loadInquiryCandidatesList({
                    payload: { inquiry: action.inquiry },
                  }),
                  baseInquiryActions.loadPspInquiryOffersList({
                    payload: { inquiry: action.inquiry },
                  }),
                ]
              : [
                  baseCandidateActions.loadProfileAccountInformation({ payload: { profile: action.payload } }),
                  baseCandidateActions.successProfileAccountCrud(),
                ]
          ),
          catchError(() => of(baseCandidateActions.errorProfileAccountCrud()))
        );
      })
    );
  });

  deactivateMobileAccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseCandidateActions.deactivateMobileAccess),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((user) => user.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        return this.candidatesService.deactivateMobileAccess(user.organizationId, action.payload).pipe(
          mergeMap(() =>
            action.inquiry
              ? [
                  baseCandidateActions.loadProfileAccountInformation({ payload: { profile: action.payload } }),
                  baseCandidateActions.successProfileAccountCrud(),
                  baseInquiryActions.loadInquiryCandidatesList({
                    payload: { inquiry: action.inquiry },
                  }),
                  baseInquiryActions.loadPspInquiryOffersList({
                    payload: { inquiry: action.inquiry },
                  }),
                ]
              : [
                  baseCandidateActions.loadProfileAccountInformation({ payload: { profile: action.payload } }),
                  baseCandidateActions.successProfileAccountCrud(),
                ]
          ),
          catchError(() => of(baseCandidateActions.errorProfileAccountCrud()))
        );
      })
    );
  });

  downloadAssignmentTimes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseCandidateActions.downloadAssignmentTimes),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((state) => state.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        return this.candidatesService.getAssignmentTimes(user.organizationId, action.payload).pipe(
          tap((response: any) => {
            const blob = new Blob([response.body], { type: 'text/csv' });
            const objectUrl = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.setAttribute('href', objectUrl);
            link.setAttribute('download', response.fileName);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }),
          map(() => baseCandidateActions.successDownloadAssignmentTimes()),
          catchError(() => of(baseCandidateActions.errorDownloadAssignmentTimes()))
        );
      })
    );
  });

  downloadContracts$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseCandidateActions.downloadContracts),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((state) => state.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        return this.candidatesService.downloadContracts(user.organizationId, action.payload).pipe(
          tap((response: any) => {
            const blob = new Blob([response.body], { type: 'application/zip' });
            const objectUrl = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.setAttribute('href', objectUrl);
            link.setAttribute('download', response.fileName);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }),
          map(() => baseCandidateActions.successDownloadContracts()),
          catchError(() => of(baseCandidateActions.errorDownloadContracts()))
        );
      })
    );
  });

  loadCandidateResponsibleUserList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseCandidateActions.loadResponsibleUserList),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((user) => user.data as BaseTokenInterface))
      ),
      switchMap(([_action, user]) => {
        return this.candidatesService.responsibleUsersList(user.organizationId).pipe(
          mergeMap((result) => [
            baseCandidateActions.setResponsibleUserList({ payload: result }),
            baseCandidateActions.successResponsibleUserList(),
          ]),
          catchError(() => of(baseCandidateActions.errorResponsibleUserList()))
        );
      })
    );
  });

  loadCandidateLeavingDateConflicts$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseCandidateActions.loadLeavingDateConflicts),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((user) => user.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        return this.candidatesService.leavingDateConflicts({ ...action.payload, ...{ psp: user.organizationId } }).pipe(
          mergeMap((result) => [
            baseCandidateActions.setLeavingDateConflicts({ payload: result as any }),
            baseCandidateActions.successLeavingDateConflicts(),
          ]),
          catchError(() => of(baseCandidateActions.errorLeavingDateConflicts()))
        );
      })
    );
  });

  setCandidateProfileLeavingDate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseCandidateActions.setCandidateProfileLeavingDate),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((user) => user.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        return this.candidatesService
          .setCandidateProfileLeavingDate({ ...action.payload, ...{ psp: user.organizationId } })
          .pipe(
            mergeMap((result) => [
              baseCandidateActions.successSetCandidateProfileLeavingDate(),
              baseCandidateActions.loadCandidates(),
              baseCandidateActions.loadCandidateProfile({
                payload: action.payload.profile,
              }),
            ]),
            catchError(() => of(baseCandidateActions.errorSetCandidateProfileLeavingDate()))
          );
      })
    );
  });

  cancelCandidateAssignmentByDate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseCandidateActions.cancelCandidateAssignmentByDate),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((user) => user.data as BaseTokenInterface))
      ),
      concatMap(([action, user]) => {
        return this.assignmentsService
          .cancelAssignmentPsp(user?.organizationId, action.payload.assignment, action.payload.body)
          .pipe(
            mergeMap((result) => [
              baseCandidateActions.setCandidateAssignmentCancellationStatus({
                payload: { assignment: action.payload.assignment, hasError: false },
              }),
            ]),
            catchError(() =>
              of(
                baseCandidateActions.setCandidateAssignmentCancellationStatus({
                  payload: { assignment: action.payload.assignment, hasError: true },
                })
              )
            )
          );
      })
    );
  });

  revokeCandidateOfferByDate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseCandidateActions.revokeCandidateOfferByDate),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((user) => user.data as BaseTokenInterface))
      ),
      concatMap(([action, user]) => {
        return this.inquiryService
          .revokeOffer(user?.organizationId, action.payload.inquiry, action.payload.offer, action.payload.body)
          .pipe(
            mergeMap((result) => [
              baseCandidateActions.setCandidateRevokeOfferStatus({
                payload: { offer: action.payload.offer, hasError: false },
              }),
            ]),
            catchError(() =>
              of(
                baseCandidateActions.setCandidateRevokeOfferStatus({
                  payload: { offer: action.payload.offer, hasError: true },
                })
              )
            )
          );
      })
    );
  });

  reactivateCandidateProfile$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseCandidateActions.reactivateCandidateProfile),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((user) => user.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        return this.candidatesService
          .reactivateCandidateProfile({ psp: user.organizationId, profile: action.payload.profile })
          .pipe(
            mergeMap((result) => [
              baseCandidateActions.successCandidateProfileReactivation(),
              baseCandidateActions.loadCandidates(),
              baseCandidateActions.loadCandidateProfile({
                payload: action.payload.profile,
              }),
            ]),
            catchError(() => of(baseCandidateActions.errorCandidateProfileReactivation()))
          );
      })
    );
  });

  loadCandidateProfileDocuments$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseCandidateActions.loadCandidateProfileDocuments),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((user) => user.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        return this.candidatesService
          .candidateProfileDocumentList({ psp: user.organizationId, profile: action.payload.profile })
          .pipe(
            mergeMap((response: ProfileDocumentResponse[]) => [
              baseCandidateActions.setCandidateProfileDocuments({ payload: response }),
              baseCandidateActions.successCandidateProfileDocuments(),
            ]),
            catchError(() => of(baseCandidateActions.errorCandidateProfileDocuments()))
          );
      })
    );
  });
}
