<div class="imprint-data-protection-links pt-4">
  <ul class="d-flex flex-column list-unstyled justify-content-between my-3">
    <li>
      <a
        class="link-dark pointer"
        (click)="redirectToImprintPage()"
      >
        {{ 'imprint' | translate }}
      </a>
    </li>
    <li>
      <a
        class="link-dark pointer"
        (click)="downloadLegalDocument(true)"
      >
        {{ 'privacyPolicy' | translate }}
      </a>
    </li>
    <li>
      <a
        class="link-dark pointer"
        (click)="downloadLegalDocument()"
      >
        {{ 'termsOfUse' | translate }}
      </a>
    </li>
  </ul>
</div>
