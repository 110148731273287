import { Component, Input, ViewChild, ElementRef } from '@angular/core';
import { noop } from 'rxjs';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BaseButtonSize } from '../../../../shared/components/button/base-button.component';
import { NotificationResponse } from '../../../../../api';
import { BaseModalHeaderSizes } from '../../../../shared/components/modal/base-modal.component';
import { BaseModalService } from '../../../../shared/services/base-modal.service';
import { baseSharedActions, BaseCoreState } from '../../../../_store';

@Component({
  selector: 'base-notifications-dropdown',
  templateUrl: './base-notifications-dropdown.component.html',
})
export class BaseNotificationsDropdownComponent {
  @ViewChild('notificationsPopup', { static: true }) notificationsPopup: ElementRef;

  @Input() totalUnreadNotifications: number = 0;
  @Input() unreadNotifications: Array<NotificationResponse> = [];
  @Input() modalRef: NgbModalRef;

  buttonSize = BaseButtonSize;
  activeNotificationId: number | null = null;

  constructor(private modalService: BaseModalService, private store: Store<BaseCoreState>, private router: Router) {}

  openNotificationModal(event: MouseEvent, id: number | null = null) {
    event.preventDefault();
    this.activeNotificationId = id;

    this.modalRef = this.modalService.open(
      {
        headText: 'notification.pluralCapital',
        headerSize: BaseModalHeaderSizes.lg,
        bodyTemplate: this.notificationsPopup,
        scrollToTop: true,
        okButtonDisplayed: false,
        cancelButtonDisplayed: false,
      },
      {
        scrollable: true,
        backdrop: 'static',
        size: 'xl',
      }
    );

    this.modalRef.result.then(noop, () => {
      this.store.dispatch(baseSharedActions.loadUserNotifications({ payload: { isRead: false } }));
    });
  }
}
