import produce from 'immer';
import {
  CustomerAssignmentFilterListsResponse,
  CustomerDepartmentResponse,
  CustomerInquiryListItemResponse,
  InquiryDate,
  InquiryResponse,
  MetaPagination,
  PspBranchShortInfoResponse,
  QualificationPricingResponse,
  ShiftTimeResponse,
  SurchargeListItemResponse,
  UserShortInfoResponse,
} from '../../../api';
import { baseInquiryActions } from '../actions';
import { BaseInquiryCandidateInterface } from '../../modules/inquiry/_interfaces/base-inquiry-candidate.interface';
import { BasePspInquiryOfferInterface } from '../../modules/inquiry/_interfaces/base-psp-inquiry-offer.interface';
import { BaseCustomerInquiryOfferInterface } from '../../modules/inquiry/_interfaces/base-cusomer-inquiry-offer.interface';
import {
  ShiftPlanCalendarDateInterface,
  ShiftPlanCalendarRangeInterface,
  SimilarInquiryMappedInterface,
} from '../../modules/inquiry/_interfaces/base-shift-plan.interface';
import { InquiryResponseInterface } from '../../modules/inquiry/_interfaces/base-inquiry.interface';

export interface InquiriesState {
  inquiries: {
    data: undefined | any;
    ok: undefined | boolean;
  };
  inquiry: {
    customerDepartment: any;
    dates: InquiryDate[];
    mainQualification: any;
    jobDescription: '';
    qualifications: any[];
    requirements: any[];
    responsible: any;
    id: undefined | number;
  };
  department: {
    data: Partial<CustomerDepartmentResponse>;
    ok: boolean | undefined;
  };
  inquiryCRUD: boolean | undefined;
  pspInquiry: {
    data: InquiryResponseInterface | undefined;
    ok: boolean | undefined;
  };
  customerInquiry: {
    data: InquiryResponse | undefined;
    ok: boolean | undefined;
  };
  // offerAmount: OfferAmountResponse;
  responsibleUsers: {
    data: (UserShortInfoResponse & { fullName: string })[] | undefined;
    ok: boolean | undefined;
  };
  pspCustomerQualificationPrice: {
    data: QualificationPricingResponse | undefined;
    ok: boolean | undefined;
  };
  pspOfferCreateSurcharges: SurchargeListItemResponse[];
  selectedInquiryFilters: {
    data: undefined | any;
  };
  branches: {
    data: PspBranchShortInfoResponse[] | undefined;
    ok: undefined | boolean;
  };
  inquiryOfferCloseHeaderWrapper: boolean | undefined;
  downloadDocument: {
    data: Blob | undefined;
  };
  inquiryCandidatesList: {
    data: BaseInquiryCandidateInterface[];
    pagination: MetaPagination | undefined;
    ok: boolean | undefined;
  };
  shiftPlanCalendarData: {
    data: ShiftPlanCalendarDateInterface[];
    shiftTimes: ShiftTimeResponse[];
  };
  shiftPlanCalendarRanges: ShiftPlanCalendarRangeInterface[];
  pspInquiryOffersList: {
    data: BasePspInquiryOfferInterface[];
    pagination: MetaPagination | undefined;
    ok: boolean | undefined;
  };
  customerInquiryOffersList: {
    data: BaseCustomerInquiryOfferInterface[];
    filters: CustomerAssignmentFilterListsResponse | undefined;
    pagination: MetaPagination | undefined;
    ok: boolean | undefined;
  };
  similarInquiriesList: {
    data: CustomerInquiryListItemResponse[];
    ok: boolean | undefined;
  };
  similarInquiriesMapped: SimilarInquiryMappedInterface[];
}

export const INQUIRIES_INIT_STATE: InquiriesState = {
  inquiries: {
    data: undefined,
    ok: undefined,
  },
  inquiry: {
    customerDepartment: 0,
    dates: [],
    mainQualification: {},
    jobDescription: '',
    qualifications: [],
    requirements: [],
    responsible: {},
    id: undefined,
  },
  department: {
    data: {},
    ok: undefined,
  },
  inquiryCRUD: undefined,
  pspInquiry: {
    data: undefined,
    ok: undefined,
  },
  customerInquiry: {
    data: undefined,
    ok: undefined,
  },
  responsibleUsers: {
    data: undefined,
    ok: undefined,
  },
  pspCustomerQualificationPrice: {
    data: undefined,
    ok: undefined,
  },
  pspOfferCreateSurcharges: [],
  selectedInquiryFilters: {
    data: undefined,
  },
  branches: {
    data: undefined,
    ok: undefined,
  },
  inquiryOfferCloseHeaderWrapper: undefined,
  downloadDocument: {
    data: undefined,
  },
  inquiryCandidatesList: {
    data: [],
    pagination: undefined,
    ok: undefined,
  },
  shiftPlanCalendarData: {
    data: [],
    shiftTimes: [],
  },
  shiftPlanCalendarRanges: [],
  pspInquiryOffersList: {
    data: [],
    pagination: undefined,
    ok: undefined,
  },
  customerInquiryOffersList: {
    data: [],
    filters: undefined,
    pagination: undefined,
    ok: undefined,
  },
  similarInquiriesList: {
    data: [],
    ok: undefined,
  },
  similarInquiriesMapped: [],
};

export function BaseInquiryReducer(state: InquiriesState = INQUIRIES_INIT_STATE, action: any): any {
  return produce(state, (draftState) => {
    switch (action.type) {
      case baseInquiryActions.getInquiries.type:
        draftState.inquiries.ok = undefined;
        return;

      case baseInquiryActions.loadInquiries.type:
        draftState.inquiries.data = action.payload;
        return;
      case baseInquiryActions.successLoadInquiries.type:
        draftState.inquiries.ok = true;
        return;

      case baseInquiryActions.errorLoadInquiries.type:
        draftState.inquiries.ok = false;
        return;

      case baseInquiryActions.updateInquiryProps.type:
        draftState.inquiry = { ...draftState.inquiry, ...action.payload };
        return;

      case baseInquiryActions.resetInquiryProps.type:
        draftState.inquiry = INQUIRIES_INIT_STATE.inquiry;
        return;

      case baseInquiryActions.fetchDepartment.type:
        draftState.department.ok = undefined;
        return;

      case baseInquiryActions.loadDepartment.type:
        draftState.department = action.payload;
        return;

      case baseInquiryActions.loadDepartmentSuccess.type:
        draftState.department.ok = true;
        return;

      case baseInquiryActions.loadDepartmentError.type:
        draftState.department.ok = false;
        return;

      case baseInquiryActions.setInquiryShiftViewRangeList.type:
        draftState.shiftPlanCalendarRanges = action.payload;
        return;

      case baseInquiryActions.setShiftPlanInquiryList.type:
        draftState.shiftPlanCalendarData = {
          data: action.payload.data,
          shiftTimes: action.payload.shiftTimes,
        };
        return;

      case baseInquiryActions.updateShiftPlanInquiryList.type:
        draftState.shiftPlanCalendarData.data = action.payload;
        return;

      case baseInquiryActions.resetShiftPlanData.type:
        draftState.shiftPlanCalendarData = INQUIRIES_INIT_STATE.shiftPlanCalendarData;
        draftState.shiftPlanCalendarRanges = INQUIRIES_INIT_STATE.shiftPlanCalendarRanges;
        draftState.similarInquiriesMapped = INQUIRIES_INIT_STATE.similarInquiriesMapped;
        draftState.similarInquiriesList = INQUIRIES_INIT_STATE.similarInquiriesList;
        return;

      case baseInquiryActions.createInquiry.type:
        draftState.inquiryCRUD = undefined;
        return;

      case baseInquiryActions.createAndPublishInquiry.type:
        draftState.inquiryCRUD = undefined;
        return;

      case baseInquiryActions.successInquiryCRUD.type:
        draftState.inquiryCRUD = true;
        return;

      case baseInquiryActions.errorInquiryCRUD.type:
        draftState.inquiryCRUD = false;
        return;

      case baseInquiryActions.clearInquiryCRUDStatus.type:
        draftState.inquiryCRUD = undefined;
        return;

      case baseInquiryActions.updateInquiryStatusSuccess.type:
        draftState.inquiryCRUD = true;
        return;

      case baseInquiryActions.clearInquiryCustomerData.type:
        draftState.customerInquiry.data = undefined;
        draftState.customerInquiry.ok = undefined;
        return;

      // PSP Inquiry
      case baseInquiryActions.loadPSPInquiry.type:
        draftState.pspInquiry.ok = undefined;
        return;

      case baseInquiryActions.setPSPInquiry.type:
        draftState.pspInquiry.data = action.payload;
        return;

      case baseInquiryActions.successPSPInquiry.type:
        draftState.pspInquiry.ok = true;
        return;

      case baseInquiryActions.errorPSPInquiry.type:
        draftState.pspInquiry.ok = false;
        return;

      case baseInquiryActions.setDownloadInquiryOfferDocumentFile.type:
        draftState.downloadDocument.data = action.payload.document;
        return;

      case baseInquiryActions.clearDownloadInquiryOfferDocumentFile.type:
        draftState.downloadDocument.data = undefined;
        return;

      case baseInquiryActions.loadCustomerInquiry.type:
        draftState.customerInquiry.ok = undefined;
        return;

      case baseInquiryActions.setCustomerInquiry.type:
        draftState.customerInquiry.data = action.payload;
        return;

      case baseInquiryActions.successCustomerInquiry.type:
        draftState.customerInquiry.ok = true;
        return;

      case baseInquiryActions.errorCustomerInquiry.type:
        draftState.customerInquiry.ok = false;
        return;

      case baseInquiryActions.clearOfferManagementPage.type:
        draftState.customerInquiry.data = undefined;
        draftState.customerInquiry.ok = undefined;
        return;

      // Responsible user list
      case baseInquiryActions.loadResponsibleUserList.type:
        draftState.responsibleUsers.ok = undefined;
        return;

      case baseInquiryActions.setResponsibleUserList.type:
        draftState.responsibleUsers.data = action.payload;
        return;

      case baseInquiryActions.successResponsibleUserList.type:
        draftState.responsibleUsers.ok = true;
        return;

      case baseInquiryActions.errorResponsibleUserList.type:
        draftState.responsibleUsers.ok = false;
        return;

      // PSP Customer Qualification price
      case baseInquiryActions.loadPSPCustomerQualificationPrice.type:
        draftState.pspCustomerQualificationPrice.ok = undefined;
        return;

      case baseInquiryActions.setPSPCustomerQualificationPrice.type:
        draftState.pspCustomerQualificationPrice.data = action.payload;
        return;

      case baseInquiryActions.successPSPCustomerQualificationPrice.type:
        draftState.pspCustomerQualificationPrice.ok = true;
        return;

      case baseInquiryActions.errorPSPCustomerQualificationPrice.type:
        draftState.pspCustomerQualificationPrice.ok = false;
        return;

      case baseInquiryActions.setSurchargesForOfferCreation.type:
        draftState.pspOfferCreateSurcharges = action.payload;
        return;

      case baseInquiryActions.loadInquiryBranches.type:
        draftState.branches.ok = undefined;
        return;

      case baseInquiryActions.setInquiryBranches.type:
        draftState.branches.data = action.payload;
        return;

      case baseInquiryActions.successLoadInquiryBranches.type:
        draftState.branches.ok = true;
        return;

      case baseInquiryActions.errorLoadInquiryBranches.type:
        draftState.branches.ok = false;
        return;

      case baseInquiryActions.loadInquiryCandidatesList.type:
        draftState.inquiryCandidatesList.ok = undefined;
        return;

      case baseInquiryActions.setInquiryCandidatesList.type:
        draftState.inquiryCandidatesList.data = action.payload;
        return;

      case baseInquiryActions.setInitialInquiryCandidatesList.type:
        draftState.inquiryCandidatesList.data = action.payload.data;
        draftState.inquiryCandidatesList.pagination = action.payload.pagination;
        return;

      case baseInquiryActions.addInquiryCandidatesPage.type:
        draftState.inquiryCandidatesList.data = [...draftState.inquiryCandidatesList.data, ...action.payload.data];
        draftState.inquiryCandidatesList.pagination = action.payload.pagination;
        return;

      case baseInquiryActions.resetInquiryCandidatesItems.type:
        draftState.inquiryCandidatesList.data = [];
        draftState.inquiryCandidatesList.pagination = undefined;
        draftState.inquiryCandidatesList.ok = undefined;
        return;

      case baseInquiryActions.setInquiryCandidatesListSuccess.type:
        draftState.inquiryCandidatesList.ok = true;
        return;

      case baseInquiryActions.setInquiryCandidatesListError.type:
        draftState.inquiryCandidatesList.ok = false;
        return;

      case baseInquiryActions.loadSimilarInquiriesList.type:
        draftState.similarInquiriesList.ok = undefined;
        return;

      case baseInquiryActions.setSimilarInquiriesList.type:
        draftState.similarInquiriesList.data = action.payload;
        return;

      case baseInquiryActions.successSetSimilarInquiriesList.type:
        draftState.similarInquiriesList.ok = true;
        return;

      case baseInquiryActions.errorSetSimilarInquiriesList.type:
        draftState.similarInquiriesList.ok = false;
        return;

      case baseInquiryActions.setMappedSimilarInquiries.type:
        draftState.similarInquiriesMapped = action.payload;
        return;

      case baseInquiryActions.loadPspInquiryOffersList.type:
        draftState.pspInquiryOffersList.ok = undefined;
        return;

      case baseInquiryActions.setPspInquiryOffersList.type:
        draftState.pspInquiryOffersList.data = action.payload;
        return;

      case baseInquiryActions.addPspInquiryOffersListNextPage.type:
        draftState.pspInquiryOffersList.data = [...draftState.pspInquiryOffersList.data, ...action.payload];
        return;

      case baseInquiryActions.setPspInquiryOffersListPagination.type:
        draftState.pspInquiryOffersList.pagination = action.payload;
        return;

      case baseInquiryActions.setPspInquiryOffersListSuccess.type:
        draftState.pspInquiryOffersList.ok = true;
        return;

      case baseInquiryActions.setPspInquiryOffersListError.type:
        draftState.pspInquiryOffersList.ok = false;
        return;

      case baseInquiryActions.loadCustomerInquiryOffersList.type:
        draftState.customerInquiryOffersList.ok = undefined;
        return;

      case baseInquiryActions.setCustomerInquiryOffersList.type:
        draftState.customerInquiryOffersList.data = action.payload;
        return;

      case baseInquiryActions.addCustomerInquiryOffersListNextPage.type:
        draftState.customerInquiryOffersList.data = [...draftState.customerInquiryOffersList.data, ...action.payload];
        return;

      case baseInquiryActions.setCustomerInquiryOffersFiltersAndPagination.type:
        draftState.customerInquiryOffersList.filters = action.payload.filters;
        draftState.customerInquiryOffersList.pagination = action.payload.pagination;
        return;

      case baseInquiryActions.setCustomerInquiryOffersListSuccess.type:
        draftState.customerInquiryOffersList.ok = true;
        return;

      case baseInquiryActions.setCustomerInquiryOffersListError.type:
        draftState.customerInquiryOffersList.ok = false;
        return;

      default:
        draftState = { ...draftState };
        return;
    }
  });
}
