<aside
  class="sidebar d-flex flex-column"
  [ngStyle]="{ height: 'calc((100vh - ' + sidebarScrollHeight + 'px) - 1.25rem)' }"
>
  <img
    class="img-fluid pointer sidebar-logo"
    [ngClass]="{ visible: isSidebarLogoVisible }"
    [src]="logo"
    alt="Logo"
    routerLink="/dashboard"
    [replaceUrl]="true"
  />
  <hr
    *ngIf="isSidebarLogoVisible"
    class="logo-divider"
  />
  <base-vertical-nav
    class="flex-grow-1"
    [ngClass]="{ 'pt-4': !isSidebarLogoVisible }"
    [tabs]="tabs"
  ></base-vertical-nav>
  <base-legal-document-links></base-legal-document-links>
</aside>
