import { AfterViewInit, Component, ElementRef, EventEmitter, OnDestroy, Output, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BaseSpinnerService } from './base-spinner.service';

@Component({
  selector: 'base-spinner',
  templateUrl: './base-spinner.component.html',
})
export class BaseSpinnerComponent implements AfterViewInit, OnDestroy {
  @ViewChild('spinner', { static: true }) spinner: ElementRef;
  @Output() spinnerEnabled = new EventEmitter();

  destroy$ = new Subject();

  constructor(private waitingSpinnerService: BaseSpinnerService) {}

  ngAfterViewInit(): void {
    this.waitingSpinnerService.showSpinner$.pipe(takeUntil(this.destroy$)).subscribe((bool: boolean) => {
      this.spinner.nativeElement.hidden = !bool;
      this.spinnerEnabled.emit(bool);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }
}
