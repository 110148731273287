import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

/*
 * Convert bytes into largest possible unit.
 * Takes an precision argument that defaults to 2.
 * Usage: bytes | fileSize:precision
 * Example: {{ 1024 |  fileSize}} -> formats to: 1 KB
 */
@Pipe({
  name: 'baseFilesize',
})
export class FilesizePipe implements PipeTransform {
  private decimalPipe: DecimalPipe;
  private units = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];

  constructor(@Inject(LOCALE_ID) localeId: string) {
    this.decimalPipe = new DecimalPipe(localeId);
  }

  public transform(bytes: number = 0, precision: number = 2): string {
    if (isNaN(parseFloat(String(bytes))) || !isFinite(bytes)) {
      return '?';
    }

    let unit = 0;

    while (bytes >= 1024) {
      bytes /= 1024;
      unit++;
    }

    if (unit === 0) {
      precision = 0;
    }

    let digitsInfo = '1.1-' + precision;
    if (precision === 0) {
      digitsInfo = '1.0-' + precision;
    }

    return this.decimalPipe.transform(bytes, digitsInfo) + ' ' + this.units[unit];
  }
}
