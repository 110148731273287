import { Component, Input } from '@angular/core';
import { BaseInquiryScrollSliderEnum } from '../../_enums/base-inquiry-scrolling-meta-data.enum';
import { BaseInquiryScrollService } from '../../_services/base-inquiry-scroll.service';

@Component({
  selector: 'base-inquiries-calendar-meta-new',
  templateUrl: 'base-inquiries-calendar-meta-new.component.html',
})
export class BaseInquiriesCalendarMetaNewComponent {
  @Input() datesQty = 0;

  baseInquiryScrollSliderEnum = BaseInquiryScrollSliderEnum;

  constructor(private baseInquiryScrollService: BaseInquiryScrollService) {}

  navigateCalendar(action: BaseInquiryScrollSliderEnum): void {
    this.baseInquiryScrollService.setSliderOffsetValue(action, this.datesQty);
  }
}
