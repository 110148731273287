import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { BaseRolesService } from '../../../core/services/base-roles.service';
import { BaseErrorCodesEnum } from '../../../shared/enums/base-error-codes.enum';
import { BaseTabInterface } from '../../../shared/interfaces/base-tab.interface';
import { BaseButtonSize } from '../../../shared/components/button/base-button.component';
import { BASE_PERMISSIONS } from '../../../core/constants/base-permissions';

@Component({
  selector: 'base-header',
  templateUrl: './base-header.component.html',
  styleUrls: ['./base-header.component.scss'],
})
export class BaseHeaderComponent implements OnInit {
  @Input() logo: string;
  @Input() sections: Array<BaseTabInterface & { featurePermissionKeys: string[] }> = [];
  @Input() showSideMenu: boolean = true;
  buttonSizes = BaseButtonSize;
  tabs: Array<BaseTabInterface & { featurePermissionKeys: string[] }> = [];
  isPsp = false;
  isErrorPage = false;

  constructor(
    private offcanvasService: NgbOffcanvas,
    private roleService: BaseRolesService,
    private route: ActivatedRoute
  ) {
    this.isErrorPage = Object.values(BaseErrorCodesEnum)
      .map((val) => (isNaN(+val) ? '' : val.toString()))
      .includes(route.snapshot.routeConfig?.path || '');
  }

  ngOnInit(): void {
    this.isPsp = this.roleService.isPsp();
    this.tabs = this.sections?.filter(
      (option) =>
        !option.featurePermissionKeys.length ||
        option.featurePermissionKeys.some((permission) =>
          this.roleService.hasFeaturePermission(permission as BASE_PERMISSIONS)
        )
    );
  }

  openOffcanvasSidebar(offcanvasSidebar: any): void {
    this.offcanvasService.open(offcanvasSidebar, {
      panelClass: 'navigation-offcanvas',
    });
  }
}
