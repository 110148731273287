import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'base-form-field-preview',
  templateUrl: './base-form-field-preview.component.html',
})
export class BaseFormFieldPreviewComponent implements OnChanges {
  @Input() label = '';
  @Input() value: string;
  @Input() isMultiline = false;
  @Input() previewClass = '';
  @Input() rightAligned = false;
  valueToDisplay: string;

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.value && !changes.firstChange) {
      this.valueToDisplay = this.value?.trim() === 'null' ? '' : this.value;
    }
  }
}
