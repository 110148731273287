import { Group, UserResponse } from '../../../../api';
import { BaseGender } from '../../../shared/enums/base-gender.enum';

export type GenderType = 1 | 2 | 3;

export class BaseUserProfileModel {
  avatar: string;
  email: string;
  firstName: string;
  gender: { label: string; value: BaseGender };
  group: Group;
  isAdministrator: boolean;
  lastName: string;
  mobilePhone: string;
  phone: string;
  position: string;
  newPassword = '';
  newPasswordRepeat = '';

  constructor(profile: UserResponse) {
    this.avatar = profile.avatar?.data || '';
    this.email = profile.email || '';
    this.firstName = profile.firstName || '';
    this.gender = { label: BaseGender[profile.gender || 1], value: profile.gender || 1 };
    this.group = profile.group || {};
    this.isAdministrator = profile.isAdmin ?? false;
    this.lastName = profile.lastName || '';
    this.mobilePhone = profile.mobilePhone || '';
    this.phone = profile.phone || '';
    this.position = profile.position || '';
  }
}
