import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  MetaPagination,
  PspCustomerLocationItemResponse,
  QualificationPricingListItemResponse,
  PspCustomerSurchargeResponse,
  DepartmentListItemResponse,
  DocumentListItemResponse,
  PspCustomerLocationShowResponse,
} from '../../../api';

const getRelatedCustomerLocationsState = createFeatureSelector('relatedCustomerLocations');

export const getRelatedCustomerLocationsList = createSelector(
  getRelatedCustomerLocationsState,
  (
    state: any
  ): {
    data: PspCustomerLocationItemResponse[] | undefined;
    pagination: MetaPagination | undefined;
    ok: boolean | undefined;
  } => state.list
);

export const getRelatedCustomerLocationDetails = createSelector(
  getRelatedCustomerLocationsState,
  (
    state: any
  ): {
    data: PspCustomerLocationShowResponse | undefined;
    ok: boolean | undefined;
  } => state.details
);

export const getRelatedCustomerLocationDocuments = createSelector(
  getRelatedCustomerLocationsState,
  (
    state: any
  ): {
    data: DocumentListItemResponse[] | undefined;
    ok: boolean | undefined;
  } => state.customerLocationDocuments
);

export const getRelatedCustomerLocationDownloadDocument = createSelector(
  getRelatedCustomerLocationsState,
  (
    state: any
  ): {
    data: Blob | undefined;
  } => state.downloadDocument
);

export const getRelatedCustomerLocationDepartments = createSelector(
  getRelatedCustomerLocationsState,
  (
    state: any
  ): {
    data: DepartmentListItemResponse[] | undefined;
    ok: boolean | undefined;
  } => state.departments
);

export const getRelatedCustomerLocationPriceList = createSelector(
  getRelatedCustomerLocationsState,
  (
    state: any
  ): {
    data: QualificationPricingListItemResponse[] | undefined;
    ok: boolean | undefined;
  } => state.priceList
);

export const getRelatedCustomerLocationSurcharges = createSelector(
  getRelatedCustomerLocationsState,
  (
    state: any
  ): {
    data: PspCustomerSurchargeResponse | undefined;
    ok: boolean | undefined;
  } => state.surcharges
);
