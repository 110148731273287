<div class="container-fluid inquiry-container">
  <div
    class="sticky-content-container"
    baseIsSticky
    stickyClass="sticky-top"
    [stickyScrollDistance]="stickyHeaderScrollDistance"
    (stickyChange)="onStickyChange($event)"
  >
    <div class="position-relative">
      <div class="inquiry-calendar position-relative">
        <base-inquiry-calendar></base-inquiry-calendar>
      </div>
      <div class="inquiry-actions-and-filters py-4">
        <ng-content select="[filtersAndActions]"></ng-content>
      </div>
    </div>
  </div>
  <div class="position-relative">
    <router-outlet></router-outlet>
  </div>
</div>

<ng-content select="[inquirySidebarOffcanvas]"></ng-content>
