import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { BaseInquiryCustomerGuard } from './_guards/base-inquiry-customer.guard';
import { BaseInquiryDepartmentsGuard } from './_guards/base-inquiry-departments.guard';
import { BasePspInquiryGuard } from './_guards/base-psp-inquiry.guard';
import { BaseResponsibleUsersGuard } from './_guards/base-responsible-users.guard';
import { BaseFillFlexShiftsPipe } from './_pipe/base-fill-flex-shifts.pipe';
import { BaseInquiryOfferBubbleColorPipe } from './_pipe/base-inquiry-offer-bubble-color.pipe';
import { BaseInquiryOfferBubbleDisabledPipe } from './_pipe/base-inquiry-offer-bubble-disabled.pipe';
import { baseInquiryEffects, baseInquiryReducer } from '../../_store';
import { BaseInquiryService } from './_services/base-inquiry.service';
import { BaseSharedModule } from '../../shared/base-shared.module';
import { InquiryDetailsSidebarComponent } from './_components/inquiry-details-sidebar/inquiry-details-sidebar.component';
import { AppsSharedModule } from '../../apps-shared/apps-shared.module';
import { BaseInquiryRequirementsModalComponent } from './_components/base-inquiry-requirements-modal/base-inquiry-requirements-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BaseOffersSortingDropdownComponent } from './_components/base-offers-sorting-dropdown/base-offers-sorting-dropdown.component';
import { BaseInquiryShiftPlanGuard } from './_guards/base-inquiry-shift-plan.guard';
import { BaseInquiryCalendarComponent } from './_components/base-inquiry-calendar/base-inquiry-calendar.component';
import { CdkFixedSizeVirtualScroll, CdkVirtualForOf, CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { BaseInquiriesCalendarMetaNewComponent } from './_components/base-inquiries-calendar-meta-new/base-inquiries-calendar-meta-new.component';
import { BaseInquiryCandidatesAndOffersComponent } from './_components/base-inquiry-candidates-and-offers/base-inquiry-candidates-and-offers.component';
import { RouterOutlet } from '@angular/router';
import { BaseInquiryEmployeeAvatarAndNameComponent } from './_components/base-inquiry-emloyee-avatar-and-name/base-inquiry-employee-avatar-and-name.component';
import { BaseInquiryShiftService } from './_services/base-inquiry-shift.service';
import { BaseInquiryDateService } from './_services/base-inquiry-date.service';
import { BaseCalendarBubblesListNewComponent } from './_components/base-calendar-bubbles-list-new/base-calendar-bubbles-list-new.component';
import { BaseInquiryBubbleNewComponent } from './_components/base-inquiry-bubble-new/base-inquiry-bubble-new.component';
import { BaseInquiryCalendarBubbleListService } from './_services/base-inquiry-calendar-bubble-list.service';
import { BaseInquiryCandidatesGuard } from './_guards/base-inquiry-candidates.guard';
import { BaseInquiryCandidateService } from './_services/base-inquiry-candidate.service';
import { BaseInquiryCandidateBubbleComponent } from './_components/base-inquiry-candidate-bubble/base-inquiry-candidate-bubble.component';
import { BaseInquiryDetailsSidebarContentComponent } from './_components/base-inquiry-details-sidebar-content/base-inquiry-details-sidebar-content.component';
import { BaseShiftPlanService } from './_services/base-shift-plan.service';
import { BasePspInquiryOfferService } from './_services/base-psp-inquiry-offer.service';
import { BasePspInquiryOffersGuard } from './_guards/base-psp-inquiry-offers.guard';
import { BaseCustomerInquiryOffersGuard } from './_guards/base-customer-inquiry-offers.guard';
import { BaseCustomerInquiryOfferService } from './_services/base-customer-inquiry-offer.service';
import { BaseSimilarInquiriesService } from './_services/base-similar-inquiries.service';
import { BaseInquiryScrollService } from './_services/base-inquiry-scroll.service';
import { BaseInquiryHorizontalScrollComponent } from './_components/base-inquiry-horizontal-scroll/base-inquiry-horizontal-scroll.component';
import { BaseUnlimitedInquiryCandidatesGuard } from './_guards/base-unlimited-inquiry-candidates.guard';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    BaseSharedModule,
    AppsSharedModule,
    NgbTooltipModule,
    ReactiveFormsModule,
    StoreModule.forFeature('inquiries', baseInquiryReducer.BaseInquiryReducer),
    EffectsModule.forFeature([baseInquiryEffects.BaseInquiryEffects]),
    RouterOutlet,
    CdkFixedSizeVirtualScroll,
    CdkVirtualForOf,
    CdkVirtualScrollViewport,
  ],
  declarations: [
    BaseFillFlexShiftsPipe,
    BaseInquiryOfferBubbleColorPipe,
    BaseInquiryOfferBubbleDisabledPipe,
    BaseInquiriesCalendarMetaNewComponent,
    BaseInquiryBubbleNewComponent,
    InquiryDetailsSidebarComponent,
    BaseInquiryRequirementsModalComponent,
    BaseOffersSortingDropdownComponent,
    BaseInquiryDetailsSidebarContentComponent,
    BaseInquiryCalendarComponent,
    BaseInquiryCandidatesAndOffersComponent,
    BaseInquiryEmployeeAvatarAndNameComponent,
    BaseCalendarBubblesListNewComponent,
    BaseInquiryCandidateBubbleComponent,
    BaseInquiryHorizontalScrollComponent,
  ],
  exports: [
    BaseFillFlexShiftsPipe,
    BaseInquiryOfferBubbleColorPipe,
    BaseInquiryOfferBubbleDisabledPipe,
    BaseInquiriesCalendarMetaNewComponent,
    BaseInquiryBubbleNewComponent,
    InquiryDetailsSidebarComponent,
    BaseInquiryRequirementsModalComponent,
    BaseOffersSortingDropdownComponent,
    BaseInquiryDetailsSidebarContentComponent,
    BaseInquiryCalendarComponent,
    BaseInquiryCandidatesAndOffersComponent,
    BaseInquiryEmployeeAvatarAndNameComponent,
    BaseCalendarBubblesListNewComponent,
    BaseInquiryCandidateBubbleComponent,
    BaseInquiryHorizontalScrollComponent,
  ],
  providers: [
    BaseInquiryService,
    BaseInquiryCustomerGuard,
    BaseInquiryDepartmentsGuard,
    BasePspInquiryGuard,
    BaseResponsibleUsersGuard,
    BaseFillFlexShiftsPipe,
    BaseInquiryOfferBubbleColorPipe,
    BaseInquiryOfferBubbleDisabledPipe,
    BaseInquiryShiftPlanGuard,
    BaseInquiryShiftService,
    BaseInquiryDateService,
    BaseInquiryCalendarBubbleListService,
    BaseInquiryCandidatesGuard,
    BaseUnlimitedInquiryCandidatesGuard,
    BaseInquiryCandidateService,
    BaseShiftPlanService,
    BaseSimilarInquiriesService,
    BasePspInquiryOfferService,
    BasePspInquiryOffersGuard,
    BaseCustomerInquiryOfferService,
    BaseCustomerInquiryOffersGuard,
    BaseInquiryScrollService,
  ],
})
export class BaseInquiryModule {}
