import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbActiveModal, NgbModalModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {
  AppsSharedModule,
  BaseAdministrationModule,
  BaseAssignmentsModule,
  BaseCandidatesModule,
  BaseHoursToDaysPipe,
  BaseInquiryModule,
  BaseSharedModule,
} from 'base';
import { AddCandidateComponent } from './_components/add-candidate/add-candidate.component';
import { ConditionsFormComponent } from './_components/add-candidate/conditions-form/conditions-form.component';
import { MasterDataFormComponent } from './_components/add-candidate/master-data-form/master-data-form.component';
import { QualificationFormComponent } from './_components/add-candidate/qualification-form/qualification-form.component';
import { EmployeeModalOperationsComponent } from './_components/employee-operation-modal/_components/employee-modal-operations/employee-modal-operations.component';
import { EmployeeModalTimeRecordingsComponent } from './_components/employee-operation-modal/_components/employee-modal-time-recordings/employee-modal-time-recordings.component';
import { TimeRecordingsListItemComponent } from './_components/employee-operation-modal/_components/employee-modal-time-recordings/_components/time-recordings-list-item/time-recordings-list-item.component';
import { EmployeeShortInfoComponent } from './_components/employee-operation-modal/_components/employee-short-info/employee-short-info.component';
import { OfferPositionsModalComponent } from './_components/offer-positions-modal/offer-positions-modal.component';
import { WrapperComponent } from './_components/wrapper/wrapper.component';
import { CreateCustomerInfoModalComponent } from './_components/create-customer-info-modal/create-customer-info-modal.component';
import { EditCandidateComponent } from './_components/edit-candidate/edit-candidate.component';
import { MasterDataComponent } from './_components/edit-candidate/components/master-data/master-data.component';
import { AbsenceTimeComponent } from './_components/edit-candidate/components/absence-time/absence-time.component';
import { StazzleAppComponent } from './_components/edit-candidate/components/stazzle-app/stazzle-app.component';
import { AddAbsenceComponent } from './_components/add-absence/add-absence.component';
import { EditCandidateDocumentComponent } from './_components/edit-candidate/components/_components/edit-candidate-document/edit-candidate-document.component';
import { EmployeeOperationModalComponent } from './_components/employee-operation-modal/employee-operation-modal.component';
import { CandidateLogOffModalComponent } from './_components/edit-candidate/components/candidate-log-off-modal/candidate-log-off-modal.component';
import { DocumentsComponent } from './_components/edit-candidate/components/documents/documents.component';
import { CandidateLogOffSummaryModalComponent } from './_components/edit-candidate/components/candidate-log-off-summary-modal/candidate-log-off-summary-modal.component';
import { EditTimeRecordsModalComponent } from './_components/employee-operation-modal/_components/employee-modal-time-recordings/_components/edit-time-records-modal/edit-time-records-modal.component';
import { QualificationPriceGroupComponent } from './_components/qualification-price-group/qualification-price-group.component';
import { EmployeeDocumentsComponent } from './_components/employee-operation-modal/_components/employee-documents/employee-documents.component';
import { BaseCustomerSharedModule } from 'base-customer';
import { EmployeeDocumentComponent } from './_components/employee-operation-modal/_components/employee-document/employee-document.component';
import { GrowthPspDataGuard } from './_guards/growth-psp-data.guard';
import { OfferConfirmationModalComponent } from './_components/offer-confirmation-modal/offer-confirmation-modal.component';
import { AbsencesModalComponent } from './_components/absences-modal/absences-modal.component';
import { IndustrySurchargesComponent } from './_components/industry-surcharges/industry-surcharges.component';
import { IndustrySurchargesNewFormComponent } from './_components/industry-surcharges/industry-surcharges-new-form/industry-surcharges-new-form.component';
import { OfferConfirmationModalFooterComponent } from './_components/offer-confirmation-modal/offer-confirmation-modal-footer/offer-confirmation-modal-footer.component';

@NgModule({
  declarations: [
    AddCandidateComponent,
    ConditionsFormComponent,
    MasterDataFormComponent,
    QualificationFormComponent,
    WrapperComponent,
    CreateCustomerInfoModalComponent,
    EditCandidateComponent,
    MasterDataComponent,
    AbsenceTimeComponent,
    StazzleAppComponent,
    AddAbsenceComponent,
    EditCandidateDocumentComponent,
    EmployeeModalOperationsComponent,
    EmployeeModalTimeRecordingsComponent,
    EmployeeShortInfoComponent,
    TimeRecordingsListItemComponent,
    EmployeeOperationModalComponent,
    CandidateLogOffModalComponent,
    DocumentsComponent,
    CandidateLogOffSummaryModalComponent,
    EditTimeRecordsModalComponent,
    QualificationPriceGroupComponent,
    EmployeeDocumentsComponent,
    EmployeeDocumentComponent,
    OfferConfirmationModalComponent,
    AbsencesModalComponent,
    OfferPositionsModalComponent,
    IndustrySurchargesComponent,
    IndustrySurchargesNewFormComponent,
    OfferConfirmationModalFooterComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    BaseSharedModule,
    AppsSharedModule,
    NgbModule,
    NgbModalModule,
    BaseCandidatesModule,
    BaseAssignmentsModule,
    BaseInquiryModule,
    BaseCustomerSharedModule,
    BaseAdministrationModule,
  ],
  exports: [
    CommonModule,
    NgbModule,
    NgbModalModule,
    BaseSharedModule,
    AddCandidateComponent,
    ConditionsFormComponent,
    MasterDataFormComponent,
    QualificationFormComponent,
    CreateCustomerInfoModalComponent,
    EditCandidateComponent,
    EmployeeModalOperationsComponent,
    EmployeeModalTimeRecordingsComponent,
    EmployeeShortInfoComponent,
    TimeRecordingsListItemComponent,
    EmployeeOperationModalComponent,
    CandidateLogOffModalComponent,
    DocumentsComponent,
    CandidateLogOffSummaryModalComponent,
    EditTimeRecordsModalComponent,
    QualificationPriceGroupComponent,
    EmployeeDocumentsComponent,
    EmployeeDocumentComponent,
    OfferConfirmationModalComponent,
    AbsencesModalComponent,
    OfferPositionsModalComponent,
    IndustrySurchargesComponent,
    IndustrySurchargesNewFormComponent,
  ],
  providers: [GrowthPspDataGuard],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [NgbActiveModal, BaseHoursToDaysPipe],
    };
  }
}
