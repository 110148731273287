<div class="k-datepicker">
  <div
    class="form-inline"
    [ngClass]="{ 'ng-invalid': isInvalid }"
  >
    <label
      *ngIf="label"
      [ngClass]="{ required: isRequired }"
      >{{ label | translate }}</label
    >
    <div [class]="{ 'position-absolute': !isFilter }">
      <div
        class="input-group k-date-range-picker"
        [ngClass]="{ invalid: !!errorText, 'k-datepicker-filter': isFilter }"
      >
        <input
          [ngClass]="{ 'form-select': isFilter, 'no-pointer': readonly }"
          [placeholder]="placeholder"
          [readonly]="readonly"
          [disabled]="disabled"
          autoClose="outside"
          [placement]="placement"
          (dateSelect)="onDateSelect($event)"
          ngbDatepicker
          baseMaskDate
          #d="ngbDatepicker"
          (click)="d.toggle()"
          [dayTemplate]="t"
          outsideDays="hidden"
          [minDate]="minDate"
          [maxDate]="maxDate"
          [startDate]="fromDate"
          [selectionEnd]="toDate"
          (closed)="onClose()"
        />
      </div>
    </div>
  </div>
  <div class="d-flex align-items-center">
    <div
      [ngClass]="isInvalid ? 'invalid-datepicker-input-group' : 'valid-datepicker-input-group'"
      class="input-group me-2 d-flex align-items-center"
      *ngIf="!isFilter"
    >
      <input
        [ngClass]="{ 'form-control': !isFilter, 'form-select': isFilter }"
        class="fs-7 ps-3 pe-1 start-date"
        [placeholder]="placeholder"
        [tabindex]="tabindex"
        [readonly]="readonly"
        name="dpFromDate"
        [value]="format(fromDate, true, false)"
        (input)="onFromChange($event)"
        baseMaskDate
        [disabled]="disabled"
        #fromInput
      />
      <div class="px-1 py-2">-</div>
      <input
        [ngClass]="{
          'form-control': !isFilter,
          'form-select': isFilter
        }"
        class="fs-7 pe-6 ps-1"
        [placeholder]="placeholder"
        [tabindex]="tabindex + 1"
        [readonly]="readonly"
        name="dpToDate"
        [value]="format(toDate, false, true)"
        (input)="onToChange($event)"
        baseMaskDate
        [disabled]="disabled"
        #toInput
      />
      <button
        class="btn"
        type="button"
        (click)="d.toggle()"
        [disabled]="disabled"
        *ngIf="!isFilter"
      >
        <i class="fa-solid fa-calendar-days"></i>
      </button>
    </div>
  </div>
  <p class="invalid-feedback">{{ !!errorText ? (errorText | translate) : '' }}</p>
</div>
<ng-template
  #t
  let-date
  let-focused="focused"
>
  <span
    class="custom-day"
    [class.focused]="focused"
    [class.range]="isRange(date)"
    [class.faded]="isHovered(date) || isInside(date)"
    (mouseenter)="hoveredDate = date"
    (mouseleave)="hoveredDate = null"
  >
    {{ date.day }}
  </span>
</ng-template>
