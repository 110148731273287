import { Inject, Injectable, Optional } from '@angular/core';
import { BaseStoragesInterface, BaseStoreServiceInterface } from '../../shared/interfaces/base-store-service.interface';
import { ENVIRONMENTER } from '../token/base-environmenter.token';
import { BaseLocalStorageService } from './base-local-storage.service';

const STORAGES: BaseStoragesInterface = {
  LocalStorage: new BaseLocalStorageService(),
};

@Injectable()
export class BaseStoreService implements BaseStoreServiceInterface {
  protected storage: BaseStoreServiceInterface;

  constructor(@Optional() @Inject(ENVIRONMENTER) environment: any) {
    // @ts-ignore
    this.storage = STORAGES[environment.global.storage];
  }

  set<T>(key: string, data: T): void {
    this.storage.set<T>(key, data);
  }

  get<T>(key: string): T {
    return this.storage.get<T>(key);
  }

  update<T>(key: string, data: Partial<T>): void {
    this.storage.update<T>(key, data);
  }

  delete(key: string): void {
    this.storage.delete(key);
  }
}
