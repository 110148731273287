<div
  class="fixed-top-bar-alert"
  *ngIf="!buildIsUpToDate && isReloadRequired"
>
  <div class="d-flex justify-content-center align-items-center gap-3">
    <p class="m-0 fw-bold">{{ 'pageUpdate.staticMessage' | translate }}</p>
    <base-button
      label="pageUpdate.reload"
      color="dark"
      [size]="buttonSizes.xs"
      (btnClick)="reloadPage()"
    ></base-button>
  </div>
</div>

<ng-template #refreshAppVersionTemplate>
  <div class="d-flex flex-column">
    <p class="fw-bold">{{ 'pageUpdate.descriptionPart1' | translate }}</p>
    <p>{{ 'pageUpdate.descriptionPart2' | translate }}</p>
  </div>
</ng-template>
