/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AvatarUpdateRequest } from '../models/avatar-update-request';
import { CreatedResponse } from '../models/created-response';
import { CreatedResponseWithBody } from '../models/created-response-with-body';
import { DocumentCreationResponse } from '../models/document-creation-response';
import { DocumentResponse } from '../models/document-response';
import { DocumentUpdateRequest } from '../models/document-update-request';
import { EmptySuccessfulResponse } from '../models/empty-successful-response';
import { GeneralApiResponse } from '../models/general-api-response';
import { ListApiResponse } from '../models/list-api-response';
import { PspProfileUpdateRequest } from '../models/psp-profile-update-request';
import { PaginatedApiResponse } from '../models/paginated-api-response';
import { ProfileAbsenceConflictsListItemResponse } from '../models/profile-absence-conflicts-list-item-response';
import { ProfileAbsenceListItemResponse } from '../models/profile-absence-list-item-response';
import { ProfileAbsenceResponse } from '../models/profile-absence-response';
import { ProfileAbsencesTypeSummaryResponse } from '../models/profile-absences-type-summary-response';
import { ProfileCreateAbsenceRequest } from '../models/profile-create-absence-request';
import { ProfileCreateRequest } from '../models/profile-create-request';
import { ProfileDocumentResponse } from '../models/profile-document-response';
import { ProfileLeavingDateConflictsListResponse } from '../models/profile-leaving-date-conflicts-list-response';
import { ProfileLeavingDateUpdateRequest } from '../models/profile-leaving-date-update-request';
import { ProfileListResponse } from '../models/profile-list-response';
import { ProfileShowResponse } from '../models/profile-show-response';
import { ProfileUpdateAbsenceRequest } from '../models/profile-update-absence-request';
import { ProfileUserShowResponse } from '../models/profile-user-show-response';
import { UpdatedResponse } from '../models/updated-response';

@Injectable({
  providedIn: 'root',
})
export class CandidateProfileService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getPspContractExport
   */
  static readonly GetPspContractExportPath = '/api/web/v1/psps/{psp}/assignments/export/contracts';

  /**
   * Export Assignments Contracts.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_ASSIGNMENTS'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPspContractExport()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPspContractExport$Response(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * Search assignment by profile.firstName, profile.lastName, profile.personalId.
     */
    search?: string;

    /**
     * Filter assignments by Main Qualifications ids.
     */
    'mainQualifications[]'?: any;

    /**
     * Filter assignments by Customer ids.
     */
    'customers[]'?: any;

    /**
     * Filter assignments by PSP Branches ids.
     */
    'pspBranches[]'?: any;

    /**
     * From date, including. If passed then startDate &gt;&#x3D; fromDate AND endDate &lt;&#x3D; today
     */
    fromDate?: string;

    /**
     * Filter assignments by statuses: planned,active,ended
     */
    'statuses[]'?: any;

    /**
     * Filter assignments by open contracts: psp,customer,signed
     */
    'openContracts[]'?: any;

    /**
     * Filter assignments by open assignment times.
     */
    openAssignmentTimes?: boolean;

    /**
     * Order assignments key.
     */
    orderKey?: 'startDate' | 'endDate' | 'createdAt' | 'profileLastName' | 'customerName' | 'mainQualificationName' | 'pspBranchName';

    /**
     * Order direction.
     */
    orderDirection?: 'ASC' | 'DESC';

    /**
     * Filter assignments by ids. When values set, other filters discarded.
     */
    'assignments[]'?: any;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, CandidateProfileService.GetPspContractExportPath, 'get');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.query('search', params.search, {});
      rb.query('mainQualifications[]', params['mainQualifications[]'], {});
      rb.query('customers[]', params['customers[]'], {});
      rb.query('pspBranches[]', params['pspBranches[]'], {});
      rb.query('fromDate', params.fromDate, {});
      rb.query('statuses[]', params['statuses[]'], {});
      rb.query('openContracts[]', params['openContracts[]'], {});
      rb.query('openAssignmentTimes', params.openAssignmentTimes, {});
      rb.query('orderKey', params.orderKey, {});
      rb.query('orderDirection', params.orderDirection, {});
      rb.query('assignments[]', params['assignments[]'], {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/zip',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Export Assignments Contracts.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_ASSIGNMENTS'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPspContractExport$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPspContractExport(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * Search assignment by profile.firstName, profile.lastName, profile.personalId.
     */
    search?: string;

    /**
     * Filter assignments by Main Qualifications ids.
     */
    'mainQualifications[]'?: any;

    /**
     * Filter assignments by Customer ids.
     */
    'customers[]'?: any;

    /**
     * Filter assignments by PSP Branches ids.
     */
    'pspBranches[]'?: any;

    /**
     * From date, including. If passed then startDate &gt;&#x3D; fromDate AND endDate &lt;&#x3D; today
     */
    fromDate?: string;

    /**
     * Filter assignments by statuses: planned,active,ended
     */
    'statuses[]'?: any;

    /**
     * Filter assignments by open contracts: psp,customer,signed
     */
    'openContracts[]'?: any;

    /**
     * Filter assignments by open assignment times.
     */
    openAssignmentTimes?: boolean;

    /**
     * Order assignments key.
     */
    orderKey?: 'startDate' | 'endDate' | 'createdAt' | 'profileLastName' | 'customerName' | 'mainQualificationName' | 'pspBranchName';

    /**
     * Order direction.
     */
    orderDirection?: 'ASC' | 'DESC';

    /**
     * Filter assignments by ids. When values set, other filters discarded.
     */
    'assignments[]'?: any;
    context?: HttpContext
  }
): Observable<Blob> {

    return this.getPspContractExport$Response(params).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Path part for operation getProfileAbsenceConflicts
   */
  static readonly GetProfileAbsenceConflictsPath = '/api/web/v1/psps/{psp}/profiles/{profile}/absences/conflicts';

  /**
   * Profile absences conflicts.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_PROFILES'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProfileAbsenceConflicts()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProfileAbsenceConflicts$Response(params: {
    psp: number;
    profile: number;

    /**
     * Start date, including.
     */
    startDate: any;

    /**
     * End date, including.
     */
    endDate: any;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ListApiResponse & {
'data'?: Array<ProfileAbsenceConflictsListItemResponse>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, CandidateProfileService.GetProfileAbsenceConflictsPath, 'get');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.path('profile', params.profile, {});
      rb.query('startDate', params.startDate, {});
      rb.query('endDate', params.endDate, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListApiResponse & {
        'data'?: Array<ProfileAbsenceConflictsListItemResponse>;
        }>;
      })
    );
  }

  /**
   * Profile absences conflicts.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_PROFILES'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getProfileAbsenceConflicts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProfileAbsenceConflicts(params: {
    psp: number;
    profile: number;

    /**
     * Start date, including.
     */
    startDate: any;

    /**
     * End date, including.
     */
    endDate: any;
    context?: HttpContext
  }
): Observable<ListApiResponse & {
'data'?: Array<ProfileAbsenceConflictsListItemResponse>;
}> {

    return this.getProfileAbsenceConflicts$Response(params).pipe(
      map((r: StrictHttpResponse<ListApiResponse & {
'data'?: Array<ProfileAbsenceConflictsListItemResponse>;
}>) => r.body as ListApiResponse & {
'data'?: Array<ProfileAbsenceConflictsListItemResponse>;
})
    );
  }

  /**
   * Path part for operation getProfileAbsenceList
   */
  static readonly GetProfileAbsenceListPath = '/api/web/v1/psps/{psp}/profiles/{profile}/absences';

  /**
   * List Profile Absence Records.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_PROFILES'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProfileAbsenceList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProfileAbsenceList$Response(params: {
    psp: number;
    profile: number;

    /**
     * Page number in pagination.
     */
    page?: any;

    /**
     * Size number in pagination.
     */
    size?: any;
    type?: 'holiday' | 'illness' | 'other' | 'offline' | 'time_off_compensation' | 'free_shift' | 'unexcused_absence';
    context?: HttpContext
  }
): Observable<StrictHttpResponse<PaginatedApiResponse & {
'data'?: Array<ProfileAbsenceListItemResponse>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, CandidateProfileService.GetProfileAbsenceListPath, 'get');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.path('profile', params.profile, {});
      rb.query('page', params.page, {});
      rb.query('size', params.size, {});
      rb.query('type', params.type, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaginatedApiResponse & {
        'data'?: Array<ProfileAbsenceListItemResponse>;
        }>;
      })
    );
  }

  /**
   * List Profile Absence Records.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_PROFILES'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getProfileAbsenceList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProfileAbsenceList(params: {
    psp: number;
    profile: number;

    /**
     * Page number in pagination.
     */
    page?: any;

    /**
     * Size number in pagination.
     */
    size?: any;
    type?: 'holiday' | 'illness' | 'other' | 'offline' | 'time_off_compensation' | 'free_shift' | 'unexcused_absence';
    context?: HttpContext
  }
): Observable<PaginatedApiResponse & {
'data'?: Array<ProfileAbsenceListItemResponse>;
}> {

    return this.getProfileAbsenceList$Response(params).pipe(
      map((r: StrictHttpResponse<PaginatedApiResponse & {
'data'?: Array<ProfileAbsenceListItemResponse>;
}>) => r.body as PaginatedApiResponse & {
'data'?: Array<ProfileAbsenceListItemResponse>;
})
    );
  }

  /**
   * Path part for operation postProfileAbsenceCreate
   */
  static readonly PostProfileAbsenceCreatePath = '/api/web/v1/psps/{psp}/profiles/{profile}/absences';

  /**
   * Create Absence.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_PROFILES'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postProfileAbsenceCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postProfileAbsenceCreate$Response(params: {
    psp: number;
    profile: number;
    context?: HttpContext
    body: ProfileCreateAbsenceRequest
  }
): Observable<StrictHttpResponse<CreatedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CandidateProfileService.PostProfileAbsenceCreatePath, 'post');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.path('profile', params.profile, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CreatedResponse>;
      })
    );
  }

  /**
   * Create Absence.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_PROFILES'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postProfileAbsenceCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postProfileAbsenceCreate(params: {
    psp: number;
    profile: number;
    context?: HttpContext
    body: ProfileCreateAbsenceRequest
  }
): Observable<CreatedResponse> {

    return this.postProfileAbsenceCreate$Response(params).pipe(
      map((r: StrictHttpResponse<CreatedResponse>) => r.body as CreatedResponse)
    );
  }

  /**
   * Path part for operation getProfileAbsenceGet
   */
  static readonly GetProfileAbsenceGetPath = '/api/web/v1/psps/{psp}/profiles/{profile}/absences/{absence}';

  /**
   * Get Absence.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_PROFILES'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProfileAbsenceGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProfileAbsenceGet$Response(params: {
    psp: number;
    profile: number;
    absence: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<GeneralApiResponse & {
'data'?: ProfileAbsenceResponse;
}>> {

    const rb = new RequestBuilder(this.rootUrl, CandidateProfileService.GetProfileAbsenceGetPath, 'get');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.path('profile', params.profile, {});
      rb.path('absence', params.absence, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GeneralApiResponse & {
        'data'?: ProfileAbsenceResponse;
        }>;
      })
    );
  }

  /**
   * Get Absence.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_PROFILES'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getProfileAbsenceGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProfileAbsenceGet(params: {
    psp: number;
    profile: number;
    absence: number;
    context?: HttpContext
  }
): Observable<GeneralApiResponse & {
'data'?: ProfileAbsenceResponse;
}> {

    return this.getProfileAbsenceGet$Response(params).pipe(
      map((r: StrictHttpResponse<GeneralApiResponse & {
'data'?: ProfileAbsenceResponse;
}>) => r.body as GeneralApiResponse & {
'data'?: ProfileAbsenceResponse;
})
    );
  }

  /**
   * Path part for operation putProfileAbsenceUpdate
   */
  static readonly PutProfileAbsenceUpdatePath = '/api/web/v1/psps/{psp}/profiles/{profile}/absences/{absence}';

  /**
   * Update Absence.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_PROFILES'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putProfileAbsenceUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putProfileAbsenceUpdate$Response(params: {
    psp: number;
    profile: number;
    absence: number;
    context?: HttpContext
    body: ProfileUpdateAbsenceRequest
  }
): Observable<StrictHttpResponse<UpdatedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CandidateProfileService.PutProfileAbsenceUpdatePath, 'put');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.path('profile', params.profile, {});
      rb.path('absence', params.absence, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UpdatedResponse>;
      })
    );
  }

  /**
   * Update Absence.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_PROFILES'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putProfileAbsenceUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putProfileAbsenceUpdate(params: {
    psp: number;
    profile: number;
    absence: number;
    context?: HttpContext
    body: ProfileUpdateAbsenceRequest
  }
): Observable<UpdatedResponse> {

    return this.putProfileAbsenceUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<UpdatedResponse>) => r.body as UpdatedResponse)
    );
  }

  /**
   * Path part for operation deleteProfileAbsenceDelete
   */
  static readonly DeleteProfileAbsenceDeletePath = '/api/web/v1/psps/{psp}/profiles/{profile}/absences/{absence}';

  /**
   * Delete Absence.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_PROFILES'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteProfileAbsenceDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteProfileAbsenceDelete$Response(params: {
    psp: number;
    profile: number;
    absence: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EmptySuccessfulResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CandidateProfileService.DeleteProfileAbsenceDeletePath, 'delete');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.path('profile', params.profile, {});
      rb.path('absence', params.absence, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmptySuccessfulResponse>;
      })
    );
  }

  /**
   * Delete Absence.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_PROFILES'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteProfileAbsenceDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteProfileAbsenceDelete(params: {
    psp: number;
    profile: number;
    absence: number;
    context?: HttpContext
  }
): Observable<EmptySuccessfulResponse> {

    return this.deleteProfileAbsenceDelete$Response(params).pipe(
      map((r: StrictHttpResponse<EmptySuccessfulResponse>) => r.body as EmptySuccessfulResponse)
    );
  }

  /**
   * Path part for operation getProfileAbsenceSummary
   */
  static readonly GetProfileAbsenceSummaryPath = '/api/web/v1/psps/{psp}/profiles/{profile}/absences/summary';

  /**
   * Profile absences summary.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_PROFILES'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProfileAbsenceSummary()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProfileAbsenceSummary$Response(params: {
    psp: number;
    profile: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<GeneralApiResponse & {
'data'?: ProfileAbsencesTypeSummaryResponse;
}>> {

    const rb = new RequestBuilder(this.rootUrl, CandidateProfileService.GetProfileAbsenceSummaryPath, 'get');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.path('profile', params.profile, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GeneralApiResponse & {
        'data'?: ProfileAbsencesTypeSummaryResponse;
        }>;
      })
    );
  }

  /**
   * Profile absences summary.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_PROFILES'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getProfileAbsenceSummary$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProfileAbsenceSummary(params: {
    psp: number;
    profile: number;
    context?: HttpContext
  }
): Observable<GeneralApiResponse & {
'data'?: ProfileAbsencesTypeSummaryResponse;
}> {

    return this.getProfileAbsenceSummary$Response(params).pipe(
      map((r: StrictHttpResponse<GeneralApiResponse & {
'data'?: ProfileAbsencesTypeSummaryResponse;
}>) => r.body as GeneralApiResponse & {
'data'?: ProfileAbsencesTypeSummaryResponse;
})
    );
  }

  /**
   * Path part for operation putAppApiWebV1PspProfileAvatarUpdateIndex
   */
  static readonly PutAppApiWebV1PspProfileAvatarUpdateIndexPath = '/api/web/v1/psps/{psp}/profiles/{profile}/avatar';

  /**
   * Update Profile Avatar.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_PROFILES'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putAppApiWebV1PspProfileAvatarUpdateIndex()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putAppApiWebV1PspProfileAvatarUpdateIndex$Response(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * Profile Id.
     */
    profile: number;
    context?: HttpContext
    body: AvatarUpdateRequest
  }
): Observable<StrictHttpResponse<UpdatedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CandidateProfileService.PutAppApiWebV1PspProfileAvatarUpdateIndexPath, 'put');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.path('profile', params.profile, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UpdatedResponse>;
      })
    );
  }

  /**
   * Update Profile Avatar.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_PROFILES'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putAppApiWebV1PspProfileAvatarUpdateIndex$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putAppApiWebV1PspProfileAvatarUpdateIndex(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * Profile Id.
     */
    profile: number;
    context?: HttpContext
    body: AvatarUpdateRequest
  }
): Observable<UpdatedResponse> {

    return this.putAppApiWebV1PspProfileAvatarUpdateIndex$Response(params).pipe(
      map((r: StrictHttpResponse<UpdatedResponse>) => r.body as UpdatedResponse)
    );
  }

  /**
   * Path part for operation getAppApiWebV1PspProfileListIndex
   */
  static readonly GetAppApiWebV1PspProfileListIndexPath = '/api/web/v1/psps/{psp}/profiles';

  /**
   * Get list of profiles of given psp, supports search.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_PROFILES'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAppApiWebV1PspProfileListIndex()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAppApiWebV1PspProfileListIndex$Response(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * Page number in pagination.
     */
    page?: any;

    /**
     * Size number in pagination.
     */
    size?: any;

    /**
     * PSP Branches  to filter profile by
     */
    'branches[]'?: any;

    /**
     * Qualification categories to filter profile by
     */
    'qualificationCategory[]'?: any;

    /**
     * Main Qualification  to filter profile by
     */
    mainQualification?: any;

    /**
     * Filter active or inactive profiles
     */
    active?: any;

    /**
     * Search profile.
     */
    search?: any;

    /**
     * Filter profile by location
     */
    location?: any;

    /**
     * Filter profile by radius
     */
    radius?: any;

    /**
     * Responsible User id  to filter profile by
     */
    responsible?: any;

    /**
     * Order key.
     */
    orderKey?: 'lastName' | 'mainQualificationName' | 'city';

    /**
     * Order direction.
     */
    orderDirection?: 'ASC' | 'DESC';
    context?: HttpContext
  }
): Observable<StrictHttpResponse<PaginatedApiResponse & {
'data'?: Array<ProfileListResponse>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, CandidateProfileService.GetAppApiWebV1PspProfileListIndexPath, 'get');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.query('page', params.page, {});
      rb.query('size', params.size, {});
      rb.query('branches[]', params['branches[]'], {"style":"form"});
      rb.query('qualificationCategory[]', params['qualificationCategory[]'], {"style":"form"});
      rb.query('mainQualification', params.mainQualification, {"style":"form"});
      rb.query('active', params.active, {"style":"form"});
      rb.query('search', params.search, {});
      rb.query('location', params.location, {});
      rb.query('radius', params.radius, {});
      rb.query('responsible', params.responsible, {"style":"form"});
      rb.query('orderKey', params.orderKey, {});
      rb.query('orderDirection', params.orderDirection, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaginatedApiResponse & {
        'data'?: Array<ProfileListResponse>;
        }>;
      })
    );
  }

  /**
   * Get list of profiles of given psp, supports search.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_PROFILES'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAppApiWebV1PspProfileListIndex$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAppApiWebV1PspProfileListIndex(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * Page number in pagination.
     */
    page?: any;

    /**
     * Size number in pagination.
     */
    size?: any;

    /**
     * PSP Branches  to filter profile by
     */
    'branches[]'?: any;

    /**
     * Qualification categories to filter profile by
     */
    'qualificationCategory[]'?: any;

    /**
     * Main Qualification  to filter profile by
     */
    mainQualification?: any;

    /**
     * Filter active or inactive profiles
     */
    active?: any;

    /**
     * Search profile.
     */
    search?: any;

    /**
     * Filter profile by location
     */
    location?: any;

    /**
     * Filter profile by radius
     */
    radius?: any;

    /**
     * Responsible User id  to filter profile by
     */
    responsible?: any;

    /**
     * Order key.
     */
    orderKey?: 'lastName' | 'mainQualificationName' | 'city';

    /**
     * Order direction.
     */
    orderDirection?: 'ASC' | 'DESC';
    context?: HttpContext
  }
): Observable<PaginatedApiResponse & {
'data'?: Array<ProfileListResponse>;
}> {

    return this.getAppApiWebV1PspProfileListIndex$Response(params).pipe(
      map((r: StrictHttpResponse<PaginatedApiResponse & {
'data'?: Array<ProfileListResponse>;
}>) => r.body as PaginatedApiResponse & {
'data'?: Array<ProfileListResponse>;
})
    );
  }

  /**
   * Path part for operation postAppApiWebV1PspProfileCreateIndex
   */
  static readonly PostAppApiWebV1PspProfileCreateIndexPath = '/api/web/v1/psps/{psp}/profiles';

  /**
   * Create a new profile.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_PROFILES'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postAppApiWebV1PspProfileCreateIndex()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postAppApiWebV1PspProfileCreateIndex$Response(params: {

    /**
     * PSP Id.
     */
    psp: number;
    context?: HttpContext
    body: ProfileCreateRequest
  }
): Observable<StrictHttpResponse<CreatedResponseWithBody>> {

    const rb = new RequestBuilder(this.rootUrl, CandidateProfileService.PostAppApiWebV1PspProfileCreateIndexPath, 'post');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CreatedResponseWithBody>;
      })
    );
  }

  /**
   * Create a new profile.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_PROFILES'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postAppApiWebV1PspProfileCreateIndex$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postAppApiWebV1PspProfileCreateIndex(params: {

    /**
     * PSP Id.
     */
    psp: number;
    context?: HttpContext
    body: ProfileCreateRequest
  }
): Observable<CreatedResponseWithBody> {

    return this.postAppApiWebV1PspProfileCreateIndex$Response(params).pipe(
      map((r: StrictHttpResponse<CreatedResponseWithBody>) => r.body as CreatedResponseWithBody)
    );
  }

  /**
   * Path part for operation postProfleDocumentAdd
   */
  static readonly PostProfleDocumentAddPath = '/api/web/v1/psps/{psp}/profiles/documents';

  /**
   * Create document.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_PROFILES'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postProfleDocumentAdd()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  postProfleDocumentAdd$Response(params: {
    psp: number;
    context?: HttpContext

    /**
     * Upload a document. There should be a variable with a name "file" which contains file
     */
    body: {
'file'?: Blob;
}
  }
): Observable<StrictHttpResponse<{
'message': string;
'data': DocumentCreationResponse;
}>> {

    const rb = new RequestBuilder(this.rootUrl, CandidateProfileService.PostProfleDocumentAddPath, 'post');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'message': string;
        'data': DocumentCreationResponse;
        }>;
      })
    );
  }

  /**
   * Create document.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_PROFILES'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postProfleDocumentAdd$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  postProfleDocumentAdd(params: {
    psp: number;
    context?: HttpContext

    /**
     * Upload a document. There should be a variable with a name "file" which contains file
     */
    body: {
'file'?: Blob;
}
  }
): Observable<{
'message': string;
'data': DocumentCreationResponse;
}> {

    return this.postProfleDocumentAdd$Response(params).pipe(
      map((r: StrictHttpResponse<{
'message': string;
'data': DocumentCreationResponse;
}>) => r.body as {
'message': string;
'data': DocumentCreationResponse;
})
    );
  }

  /**
   * Path part for operation getProfileDocumentShow
   */
  static readonly GetProfileDocumentShowPath = '/api/web/v1/psps/{psp}/profiles/{profile}/documents/{document}';

  /**
   * Get document info.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_PROFILES'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProfileDocumentShow()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProfileDocumentShow$Response(params: {
    psp: number;
    profile: number;
    document: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<GeneralApiResponse & {
'data'?: DocumentResponse;
}>> {

    const rb = new RequestBuilder(this.rootUrl, CandidateProfileService.GetProfileDocumentShowPath, 'get');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.path('profile', params.profile, {});
      rb.path('document', params.document, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GeneralApiResponse & {
        'data'?: DocumentResponse;
        }>;
      })
    );
  }

  /**
   * Get document info.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_PROFILES'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getProfileDocumentShow$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProfileDocumentShow(params: {
    psp: number;
    profile: number;
    document: string;
    context?: HttpContext
  }
): Observable<GeneralApiResponse & {
'data'?: DocumentResponse;
}> {

    return this.getProfileDocumentShow$Response(params).pipe(
      map((r: StrictHttpResponse<GeneralApiResponse & {
'data'?: DocumentResponse;
}>) => r.body as GeneralApiResponse & {
'data'?: DocumentResponse;
})
    );
  }

  /**
   * Path part for operation putProfileDocumentUpdate
   */
  static readonly PutProfileDocumentUpdatePath = '/api/web/v1/psps/{psp}/profiles/{profile}/documents/{document}';

  /**
   * Update document info, accessible only for users with permission 'FEATURE_ADMIN_PROFILES'.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putProfileDocumentUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putProfileDocumentUpdate$Response(params: {
    psp: number;
    profile: number;
    document: string;
    context?: HttpContext
    body: DocumentUpdateRequest
  }
): Observable<StrictHttpResponse<EmptySuccessfulResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CandidateProfileService.PutProfileDocumentUpdatePath, 'put');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.path('profile', params.profile, {});
      rb.path('document', params.document, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmptySuccessfulResponse>;
      })
    );
  }

  /**
   * Update document info, accessible only for users with permission 'FEATURE_ADMIN_PROFILES'.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putProfileDocumentUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putProfileDocumentUpdate(params: {
    psp: number;
    profile: number;
    document: string;
    context?: HttpContext
    body: DocumentUpdateRequest
  }
): Observable<EmptySuccessfulResponse> {

    return this.putProfileDocumentUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<EmptySuccessfulResponse>) => r.body as EmptySuccessfulResponse)
    );
  }

  /**
   * Path part for operation deleteProfileDocumentDelete
   */
  static readonly DeleteProfileDocumentDeletePath = '/api/web/v1/psps/{psp}/profiles/{profile}/documents/{document}';

  /**
   * Delete a document.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_PROFILES'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteProfileDocumentDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteProfileDocumentDelete$Response(params: {
    psp: number;
    profile: number;
    document: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EmptySuccessfulResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CandidateProfileService.DeleteProfileDocumentDeletePath, 'delete');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.path('profile', params.profile, {});
      rb.path('document', params.document, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmptySuccessfulResponse>;
      })
    );
  }

  /**
   * Delete a document.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_PROFILES'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteProfileDocumentDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteProfileDocumentDelete(params: {
    psp: number;
    profile: number;
    document: string;
    context?: HttpContext
  }
): Observable<EmptySuccessfulResponse> {

    return this.deleteProfileDocumentDelete$Response(params).pipe(
      map((r: StrictHttpResponse<EmptySuccessfulResponse>) => r.body as EmptySuccessfulResponse)
    );
  }

  /**
   * Path part for operation getProfileDocumentDownload
   */
  static readonly GetProfileDocumentDownloadPath = '/api/web/v1/psps/{psp}/profiles/{profile}/documents/{document}/file';

  /**
   * Download a file.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_PROFILES'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProfileDocumentDownload()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProfileDocumentDownload$Response(params: {
    psp: number;
    profile: number;
    document: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, CandidateProfileService.GetProfileDocumentDownloadPath, 'get');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.path('profile', params.profile, {});
      rb.path('document', params.document, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'multipart/form-data',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Download a file.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_PROFILES'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getProfileDocumentDownload$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProfileDocumentDownload(params: {
    psp: number;
    profile: number;
    document: string;
    context?: HttpContext
  }
): Observable<Blob> {

    return this.getProfileDocumentDownload$Response(params).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Path part for operation getPspProfileDocumentList
   */
  static readonly GetPspProfileDocumentListPath = '/api/web/v1/psps/{psp}/profiles/{profile}/documents';

  /**
   * Get list of documents.
   *
   * Accessible only for users with permission 'FEATURE_ADMIN_OFFERS'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPspProfileDocumentList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPspProfileDocumentList$Response(params: {
    psp: number;
    profile: number;

    /**
     * Filter public or private documents.
     */
    public?: any;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ListApiResponse & {
'data'?: Array<ProfileDocumentResponse>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, CandidateProfileService.GetPspProfileDocumentListPath, 'get');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.path('profile', params.profile, {});
      rb.query('public', params.public, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListApiResponse & {
        'data'?: Array<ProfileDocumentResponse>;
        }>;
      })
    );
  }

  /**
   * Get list of documents.
   *
   * Accessible only for users with permission 'FEATURE_ADMIN_OFFERS'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPspProfileDocumentList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPspProfileDocumentList(params: {
    psp: number;
    profile: number;

    /**
     * Filter public or private documents.
     */
    public?: any;
    context?: HttpContext
  }
): Observable<ListApiResponse & {
'data'?: Array<ProfileDocumentResponse>;
}> {

    return this.getPspProfileDocumentList$Response(params).pipe(
      map((r: StrictHttpResponse<ListApiResponse & {
'data'?: Array<ProfileDocumentResponse>;
}>) => r.body as ListApiResponse & {
'data'?: Array<ProfileDocumentResponse>;
})
    );
  }

  /**
   * Path part for operation getAppApiWebV1PspProfileExportContractExportIndex
   */
  static readonly GetAppApiWebV1PspProfileExportContractExportIndexPath = '/api/web/v1/psps/{psp}/profiles/export/contracts';

  /**
   * Export contracts for selected profiles.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_PROFILES'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAppApiWebV1PspProfileExportContractExportIndex()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAppApiWebV1PspProfileExportContractExportIndex$Response(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * PSP Branches  to filter profile by
     */
    'branches[]'?: any;

    /**
     * Qualification categories to filter profile by
     */
    'qualificationCategory[]'?: any;

    /**
     * Main Qualification  to filter profile by
     */
    mainQualification?: any;

    /**
     * Search profile.
     */
    search?: any;

    /**
     * Filter profile by location
     */
    location?: any;

    /**
     * Filter profile by radius
     */
    radius?: any;

    /**
     * Filter by profile ids
     */
    'candidates[]'?: any;

    /**
     * Start date, including.
     */
    from: string;

    /**
     * End date, including.
     */
    to: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, CandidateProfileService.GetAppApiWebV1PspProfileExportContractExportIndexPath, 'get');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.query('branches[]', params['branches[]'], {"style":"form"});
      rb.query('qualificationCategory[]', params['qualificationCategory[]'], {"style":"form"});
      rb.query('mainQualification', params.mainQualification, {"style":"form"});
      rb.query('search', params.search, {});
      rb.query('location', params.location, {});
      rb.query('radius', params.radius, {});
      rb.query('candidates[]', params['candidates[]'], {});
      rb.query('from', params.from, {});
      rb.query('to', params.to, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/zip',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Export contracts for selected profiles.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_PROFILES'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAppApiWebV1PspProfileExportContractExportIndex$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAppApiWebV1PspProfileExportContractExportIndex(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * PSP Branches  to filter profile by
     */
    'branches[]'?: any;

    /**
     * Qualification categories to filter profile by
     */
    'qualificationCategory[]'?: any;

    /**
     * Main Qualification  to filter profile by
     */
    mainQualification?: any;

    /**
     * Search profile.
     */
    search?: any;

    /**
     * Filter profile by location
     */
    location?: any;

    /**
     * Filter profile by radius
     */
    radius?: any;

    /**
     * Filter by profile ids
     */
    'candidates[]'?: any;

    /**
     * Start date, including.
     */
    from: string;

    /**
     * End date, including.
     */
    to: string;
    context?: HttpContext
  }
): Observable<Blob> {

    return this.getAppApiWebV1PspProfileExportContractExportIndex$Response(params).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Path part for operation getAppApiWebV1PspProfileExportWorktimeExportIndex
   */
  static readonly GetAppApiWebV1PspProfileExportWorktimeExportIndexPath = '/api/web/v1/psps/{psp}/profiles/export/work-times';

  /**
   * Export assignment dates for selected profiles.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_PROFILES'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAppApiWebV1PspProfileExportWorktimeExportIndex()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAppApiWebV1PspProfileExportWorktimeExportIndex$Response(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * PSP Branches  to filter profile by
     */
    'branches[]'?: any;

    /**
     * Qualification categories to filter profile by
     */
    'qualificationCategory[]'?: any;

    /**
     * Assignment date statuses for filtering profiles
     */
    'assignmentDateStatuses[]'?: any;

    /**
     * Main Qualification  to filter profile by
     */
    mainQualification?: any;

    /**
     * Search profile.
     */
    search?: any;

    /**
     * Filter profile by location
     */
    location?: any;

    /**
     * Filter profile by radius
     */
    radius?: any;

    /**
     * Filter by profile ids
     */
    'candidates[]'?: any;

    /**
     * Start date, including.
     */
    from: string;

    /**
     * End date, including.
     */
    to: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, CandidateProfileService.GetAppApiWebV1PspProfileExportWorktimeExportIndexPath, 'get');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.query('branches[]', params['branches[]'], {"style":"form"});
      rb.query('qualificationCategory[]', params['qualificationCategory[]'], {"style":"form"});
      rb.query('assignmentDateStatuses[]', params['assignmentDateStatuses[]'], {"style":"form"});
      rb.query('mainQualification', params.mainQualification, {"style":"form"});
      rb.query('search', params.search, {});
      rb.query('location', params.location, {});
      rb.query('radius', params.radius, {});
      rb.query('candidates[]', params['candidates[]'], {});
      rb.query('from', params.from, {});
      rb.query('to', params.to, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'text/csv',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Export assignment dates for selected profiles.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_PROFILES'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAppApiWebV1PspProfileExportWorktimeExportIndex$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAppApiWebV1PspProfileExportWorktimeExportIndex(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * PSP Branches  to filter profile by
     */
    'branches[]'?: any;

    /**
     * Qualification categories to filter profile by
     */
    'qualificationCategory[]'?: any;

    /**
     * Assignment date statuses for filtering profiles
     */
    'assignmentDateStatuses[]'?: any;

    /**
     * Main Qualification  to filter profile by
     */
    mainQualification?: any;

    /**
     * Search profile.
     */
    search?: any;

    /**
     * Filter profile by location
     */
    location?: any;

    /**
     * Filter profile by radius
     */
    radius?: any;

    /**
     * Filter by profile ids
     */
    'candidates[]'?: any;

    /**
     * Start date, including.
     */
    from: string;

    /**
     * End date, including.
     */
    to: string;
    context?: HttpContext
  }
): Observable<Blob> {

    return this.getAppApiWebV1PspProfileExportWorktimeExportIndex$Response(params).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Path part for operation getAppApiWebV1PspProfileLeavingdateConflictsIndex
   */
  static readonly GetAppApiWebV1PspProfileLeavingdateConflictsIndexPath = '/api/web/v1/psps/{psp}/profiles/{profile}/leaving-date/conflicts';

  /**
   * Get list of conflicted Assignments and Offers.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAppApiWebV1PspProfileLeavingdateConflictsIndex()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAppApiWebV1PspProfileLeavingdateConflictsIndex$Response(params: {
    psp: number;
    profile: number;

    /**
     * Leave date
     */
    leavingDate: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ListApiResponse & {
'data'?: Array<ProfileLeavingDateConflictsListResponse>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, CandidateProfileService.GetAppApiWebV1PspProfileLeavingdateConflictsIndexPath, 'get');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.path('profile', params.profile, {});
      rb.query('leavingDate', params.leavingDate, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListApiResponse & {
        'data'?: Array<ProfileLeavingDateConflictsListResponse>;
        }>;
      })
    );
  }

  /**
   * Get list of conflicted Assignments and Offers.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAppApiWebV1PspProfileLeavingdateConflictsIndex$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAppApiWebV1PspProfileLeavingdateConflictsIndex(params: {
    psp: number;
    profile: number;

    /**
     * Leave date
     */
    leavingDate: string;
    context?: HttpContext
  }
): Observable<ListApiResponse & {
'data'?: Array<ProfileLeavingDateConflictsListResponse>;
}> {

    return this.getAppApiWebV1PspProfileLeavingdateConflictsIndex$Response(params).pipe(
      map((r: StrictHttpResponse<ListApiResponse & {
'data'?: Array<ProfileLeavingDateConflictsListResponse>;
}>) => r.body as ListApiResponse & {
'data'?: Array<ProfileLeavingDateConflictsListResponse>;
})
    );
  }

  /**
   * Path part for operation putAppApiWebV1PspProfileLeavingdateReactivateIndex
   */
  static readonly PutAppApiWebV1PspProfileLeavingdateReactivateIndexPath = '/api/web/v1/psps/{psp}/profiles/{profile}/reactivation';

  /**
   * Reactivate profile, accessible only for users with permission 'FEATURE_ADMIN_PROFILES'.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putAppApiWebV1PspProfileLeavingdateReactivateIndex()` instead.
   *
   * This method doesn't expect any request body.
   */
  putAppApiWebV1PspProfileLeavingdateReactivateIndex$Response(params: {
    psp: number;
    profile: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EmptySuccessfulResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CandidateProfileService.PutAppApiWebV1PspProfileLeavingdateReactivateIndexPath, 'put');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.path('profile', params.profile, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmptySuccessfulResponse>;
      })
    );
  }

  /**
   * Reactivate profile, accessible only for users with permission 'FEATURE_ADMIN_PROFILES'.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putAppApiWebV1PspProfileLeavingdateReactivateIndex$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  putAppApiWebV1PspProfileLeavingdateReactivateIndex(params: {
    psp: number;
    profile: number;
    context?: HttpContext
  }
): Observable<EmptySuccessfulResponse> {

    return this.putAppApiWebV1PspProfileLeavingdateReactivateIndex$Response(params).pipe(
      map((r: StrictHttpResponse<EmptySuccessfulResponse>) => r.body as EmptySuccessfulResponse)
    );
  }

  /**
   * Path part for operation postAppApiWebV1PspProfileMobileaccessReactivateIndex
   */
  static readonly PostAppApiWebV1PspProfileMobileaccessReactivateIndexPath = '/api/web/v1/psps/{psp}/profiles/{profile}/reactivation';

  /**
   * Activates access to the mobile application, resets a password, and sends an email.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_PROFILES'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postAppApiWebV1PspProfileMobileaccessReactivateIndex()` instead.
   *
   * This method doesn't expect any request body.
   */
  postAppApiWebV1PspProfileMobileaccessReactivateIndex$Response(params: {

    /**
     * PSP Id.
     */
    psp: number;
    profile: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EmptySuccessfulResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CandidateProfileService.PostAppApiWebV1PspProfileMobileaccessReactivateIndexPath, 'post');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.path('profile', params.profile, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmptySuccessfulResponse>;
      })
    );
  }

  /**
   * Activates access to the mobile application, resets a password, and sends an email.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_PROFILES'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postAppApiWebV1PspProfileMobileaccessReactivateIndex$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  postAppApiWebV1PspProfileMobileaccessReactivateIndex(params: {

    /**
     * PSP Id.
     */
    psp: number;
    profile: number;
    context?: HttpContext
  }
): Observable<EmptySuccessfulResponse> {

    return this.postAppApiWebV1PspProfileMobileaccessReactivateIndex$Response(params).pipe(
      map((r: StrictHttpResponse<EmptySuccessfulResponse>) => r.body as EmptySuccessfulResponse)
    );
  }

  /**
   * Path part for operation putAppApiWebV1PspProfileLeavingdateUpdateIndex
   */
  static readonly PutAppApiWebV1PspProfileLeavingdateUpdateIndexPath = '/api/web/v1/psps/{psp}/profiles/{profile}/leaving-date';

  /**
   * Update profile leaving date, accessible only for users with permission 'FEATURE_ADMIN_PROFILES'.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putAppApiWebV1PspProfileLeavingdateUpdateIndex()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putAppApiWebV1PspProfileLeavingdateUpdateIndex$Response(params: {
    psp: number;
    profile: number;
    context?: HttpContext
    body: ProfileLeavingDateUpdateRequest
  }
): Observable<StrictHttpResponse<EmptySuccessfulResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CandidateProfileService.PutAppApiWebV1PspProfileLeavingdateUpdateIndexPath, 'put');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.path('profile', params.profile, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmptySuccessfulResponse>;
      })
    );
  }

  /**
   * Update profile leaving date, accessible only for users with permission 'FEATURE_ADMIN_PROFILES'.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putAppApiWebV1PspProfileLeavingdateUpdateIndex$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putAppApiWebV1PspProfileLeavingdateUpdateIndex(params: {
    psp: number;
    profile: number;
    context?: HttpContext
    body: ProfileLeavingDateUpdateRequest
  }
): Observable<EmptySuccessfulResponse> {

    return this.putAppApiWebV1PspProfileLeavingdateUpdateIndex$Response(params).pipe(
      map((r: StrictHttpResponse<EmptySuccessfulResponse>) => r.body as EmptySuccessfulResponse)
    );
  }

  /**
   * Path part for operation postAppApiWebV1PspProfileMobileaccessActivateIndex
   */
  static readonly PostAppApiWebV1PspProfileMobileaccessActivateIndexPath = '/api/web/v1/psps/{psp}/profiles/{profile}/mobile-access';

  /**
   * Activates access to the mobile application.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_PROFILES'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postAppApiWebV1PspProfileMobileaccessActivateIndex()` instead.
   *
   * This method doesn't expect any request body.
   */
  postAppApiWebV1PspProfileMobileaccessActivateIndex$Response(params: {

    /**
     * PSP Id.
     */
    psp: number;
    profile: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EmptySuccessfulResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CandidateProfileService.PostAppApiWebV1PspProfileMobileaccessActivateIndexPath, 'post');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.path('profile', params.profile, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmptySuccessfulResponse>;
      })
    );
  }

  /**
   * Activates access to the mobile application.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_PROFILES'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postAppApiWebV1PspProfileMobileaccessActivateIndex$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  postAppApiWebV1PspProfileMobileaccessActivateIndex(params: {

    /**
     * PSP Id.
     */
    psp: number;
    profile: number;
    context?: HttpContext
  }
): Observable<EmptySuccessfulResponse> {

    return this.postAppApiWebV1PspProfileMobileaccessActivateIndex$Response(params).pipe(
      map((r: StrictHttpResponse<EmptySuccessfulResponse>) => r.body as EmptySuccessfulResponse)
    );
  }

  /**
   * Path part for operation deleteAppApiWebV1PspProfileMobileaccessDeactivateIndex
   */
  static readonly DeleteAppApiWebV1PspProfileMobileaccessDeactivateIndexPath = '/api/web/v1/psps/{psp}/profiles/{profile}/mobile-access';

  /**
   * Deactivates access to the mobile application.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_PROFILES'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteAppApiWebV1PspProfileMobileaccessDeactivateIndex()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAppApiWebV1PspProfileMobileaccessDeactivateIndex$Response(params: {

    /**
     * PSP Id.
     */
    psp: number;
    profile: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EmptySuccessfulResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CandidateProfileService.DeleteAppApiWebV1PspProfileMobileaccessDeactivateIndexPath, 'delete');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.path('profile', params.profile, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmptySuccessfulResponse>;
      })
    );
  }

  /**
   * Deactivates access to the mobile application.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_PROFILES'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteAppApiWebV1PspProfileMobileaccessDeactivateIndex$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAppApiWebV1PspProfileMobileaccessDeactivateIndex(params: {

    /**
     * PSP Id.
     */
    psp: number;
    profile: number;
    context?: HttpContext
  }
): Observable<EmptySuccessfulResponse> {

    return this.deleteAppApiWebV1PspProfileMobileaccessDeactivateIndex$Response(params).pipe(
      map((r: StrictHttpResponse<EmptySuccessfulResponse>) => r.body as EmptySuccessfulResponse)
    );
  }

  /**
   * Path part for operation getAppApiWebV1PspProfileShowIndex
   */
  static readonly GetAppApiWebV1PspProfileShowIndexPath = '/api/web/v1/psps/{psp}/profiles/{profile}';

  /**
   * Get one profile.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_PROFILES'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAppApiWebV1PspProfileShowIndex()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAppApiWebV1PspProfileShowIndex$Response(params: {

    /**
     * PSP Id.
     */
    psp: number;
    profile: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<GeneralApiResponse & {
'data'?: ProfileShowResponse;
}>> {

    const rb = new RequestBuilder(this.rootUrl, CandidateProfileService.GetAppApiWebV1PspProfileShowIndexPath, 'get');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.path('profile', params.profile, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GeneralApiResponse & {
        'data'?: ProfileShowResponse;
        }>;
      })
    );
  }

  /**
   * Get one profile.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_PROFILES'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAppApiWebV1PspProfileShowIndex$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAppApiWebV1PspProfileShowIndex(params: {

    /**
     * PSP Id.
     */
    psp: number;
    profile: number;
    context?: HttpContext
  }
): Observable<GeneralApiResponse & {
'data'?: ProfileShowResponse;
}> {

    return this.getAppApiWebV1PspProfileShowIndex$Response(params).pipe(
      map((r: StrictHttpResponse<GeneralApiResponse & {
'data'?: ProfileShowResponse;
}>) => r.body as GeneralApiResponse & {
'data'?: ProfileShowResponse;
})
    );
  }

  /**
   * Path part for operation patchAppApiWebV1PspProfileUpdateIndex
   */
  static readonly PatchAppApiWebV1PspProfileUpdateIndexPath = '/api/web/v1/psps/{psp}/profiles/{profile}';

  /**
   * Update profile info.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_PROFILES'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchAppApiWebV1PspProfileUpdateIndex()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchAppApiWebV1PspProfileUpdateIndex$Response(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * Profile Id.
     */
    profile: number;
    context?: HttpContext
    body: PspProfileUpdateRequest
  }
): Observable<StrictHttpResponse<UpdatedResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CandidateProfileService.PatchAppApiWebV1PspProfileUpdateIndexPath, 'patch');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.path('profile', params.profile, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UpdatedResponse>;
      })
    );
  }

  /**
   * Update profile info.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_PROFILES'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchAppApiWebV1PspProfileUpdateIndex$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchAppApiWebV1PspProfileUpdateIndex(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * Profile Id.
     */
    profile: number;
    context?: HttpContext
    body: PspProfileUpdateRequest
  }
): Observable<UpdatedResponse> {

    return this.patchAppApiWebV1PspProfileUpdateIndex$Response(params).pipe(
      map((r: StrictHttpResponse<UpdatedResponse>) => r.body as UpdatedResponse)
    );
  }

  /**
   * Path part for operation getAppApiWebV1PspProfileUserListIndex
   */
  static readonly GetAppApiWebV1PspProfileUserListIndexPath = '/api/web/v1/psps/{psp}/profiles/{profile}/user';

  /**
   * Show candidate profile account information.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_PROFILES'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAppApiWebV1PspProfileUserListIndex()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAppApiWebV1PspProfileUserListIndex$Response(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * Profile Id.
     */
    profile: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<GeneralApiResponse & {
'data'?: ProfileUserShowResponse;
}>> {

    const rb = new RequestBuilder(this.rootUrl, CandidateProfileService.GetAppApiWebV1PspProfileUserListIndexPath, 'get');
    if (params) {
      rb.path('psp', params.psp, {});
      rb.path('profile', params.profile, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GeneralApiResponse & {
        'data'?: ProfileUserShowResponse;
        }>;
      })
    );
  }

  /**
   * Show candidate profile account information.
   *
   * **Accessible only for users with permission 'FEATURE_ADMIN_PROFILES'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAppApiWebV1PspProfileUserListIndex$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAppApiWebV1PspProfileUserListIndex(params: {

    /**
     * PSP Id.
     */
    psp: number;

    /**
     * Profile Id.
     */
    profile: number;
    context?: HttpContext
  }
): Observable<GeneralApiResponse & {
'data'?: ProfileUserShowResponse;
}> {

    return this.getAppApiWebV1PspProfileUserListIndex$Response(params).pipe(
      map((r: StrictHttpResponse<GeneralApiResponse & {
'data'?: ProfileUserShowResponse;
}>) => r.body as GeneralApiResponse & {
'data'?: ProfileUserShowResponse;
})
    );
  }

}
