import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import {
  AssignmentDateListItemResponse,
  ContractRequest,
  CustomerAssignmentListItemResponse,
  PaginatedApiResponse,
  PspAssignmentListItemResponse,
  ContractResponse,
  AssignmentPspResponse,
  AssignmentCustomerResponse,
  GeneralApiResponse,
} from '../../../api';
import { BaseRolesService } from '../../core/services/base-roles.service';
import { noop, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { BaseToastAlertClasses } from '../../shared/enums/base-toast-alert-classes.enum';
import { BaseTokenInterface } from '../../shared/interfaces/base-token-service.interface';
import { BaseAssignmentDateStatusEnum } from '../../modules/assignments/_enums/base-assignment-date-status.enum';
import { BaseAssignmentsService } from '../../modules/assignments/base-assignments-service';
import { baseAssignmentsActions, baseSharedActions } from '../actions';
import { BaseCoreState } from '../reducers';
import { baseAssignmentsSelectors, baseAuthSelectors } from '../selectors';
import { BaseSignContractOptions } from '../../modules/assignments/_enums/base-sign-contract-options.enum';
import { ExportPspContractsRequestInterface } from '../../modules/assignments/_interfaces/export-psp-contracts-request.interface';
import { BaseModalService } from '../../shared/services/base-modal.service';
import { BaseQueryParamService } from '../../shared/services/base-query-param.service';
import { BaseFilterService } from '../../shared/services/base-filter.service';
import { BaseSigningStatusBySide } from '../../modules/assignments/_enums/base-signing-status.enum';
import { BaseAvailableFileTypeEnum } from '../../shared/enums/base-available-file-type.enum';
import { ExportPspAssignmentInformationRequestInterface } from '../../modules/assignments/_interfaces/export-psp-assignment-information-request.interface';
import { ExportCustomerAssignmentInformationRequestInterface } from '../../modules/assignments/_interfaces/export-customer-assignment-information-request.interface';

@Injectable()
export class BaseAssignmentsEffects {
  loadAssignmentList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseAssignmentsActions.loadAssignmentList),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((state) => state.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        let source$: Observable<
          PaginatedApiResponse & {
            data?: Array<CustomerAssignmentListItemResponse> | Array<PspAssignmentListItemResponse>;
          }
        >;
        source$ = this.roleService.isPsp()
          ? this.assignmentsService.getPspAllAssignments(
              user.organizationId,
              action.payload.page,
              action.payload.size,
              action.payload.filters,
              action.payload.search,
              action.payload.orderKey,
              action.payload.orderDirection
            )
          : this.assignmentsService.getAllCustomerAssignments(
              user.organizationId,
              action.payload.page,
              action.payload.filters,
              action.payload.search,
              action.payload.orderKey,
              action.payload.orderDirection,
              action.payload.size
            );
        // @ts-ignore
        return source$?.pipe(
          map((response) => {
            return baseAssignmentsActions.setAssignmentList({ payload: response } as any);
          }),
          catchError(() => of(baseAssignmentsActions.errorAssignmentList()))
        );
      })
    );
  });

  loadAssignmentDates$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseAssignmentsActions.loadAssignmentDates),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((state) => state.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        let source$;
        if (this.roleService.isPsp()) {
          source$ = this.assignmentsService.getPspAssignmentDates(
            user.organizationId,
            action.payload.assignment,
            action.payload.status
          );
        } else {
          source$ = this.assignmentsService.getCustomerAssignmentDates(
            user.organizationId,
            action.payload.assignment,
            action.payload.status
          );
        }
        return source$.pipe(
          mergeMap((response) => [
            baseAssignmentsActions.setAssignmentDates({ payload: response } as any),
            baseAssignmentsActions.successAssignmentDates(),
          ]),
          catchError(() => of(baseAssignmentsActions.errorAssignmentDates()))
        );
      })
    );
  });

  loadAssignment$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseAssignmentsActions.loadAssignment),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((user) => user.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        const source$: Observable<any> = this.roleService.isPsp()
          ? this.assignmentsService.getPspAssignment(user.organizationId, action.payload.assignment)
          : this.assignmentsService.getCustomerAssignment(user.organizationId, action.payload.assignment);
        return source$.pipe(
          tap((response: GeneralApiResponse & { data?: AssignmentPspResponse | AssignmentCustomerResponse }) => {
            const validContract = response.data?.contracts?.find((contract) => contract.valid) as ContractResponse;

            if (action.payload.showDigitalSignStatus) {
              const signingStatus = this.roleService.isPsp()
                ? validContract.signatures?.statusPsp
                : validContract.signatures?.statusCustomer;

              if (signingStatus === BaseSigningStatusBySide.SIGNED) {
                const alert = `
                <h3 class="headline-20">${this.translateService.instant(
                  'namirialSigningProcess.signCompletedTitle'
                )}</h3>
                ${this.translateService.instant('namirialSigningProcess.signCompletedDescription')}
              `;
                this.store.dispatch(
                  baseSharedActions.addSystemAlert({
                    payload: {
                      class: BaseToastAlertClasses.success,
                      body: alert,
                      closeManually: true,
                    },
                  })
                );
              }

              if (signingStatus === BaseSigningStatusBySide.IN_QUEUE) {
                this.modalService
                  .open(
                    {
                      headText: 'operations.signatureIsBeingProcessed',
                      bodyText: 'operations.signaturePendingMessage',
                      okText: 'action.close',
                      hideCloseIcon: false,
                      cancelButtonDisplayed: false,
                      cancelButtonOnly: true,
                    },
                    {
                      size: 'lg',
                    }
                  )
                  .result.then(noop, noop);
              }

              if (signingStatus === BaseSigningStatusBySide.ERROR) {
                this.store.dispatch(
                  baseSharedActions.addSystemAlert({
                    payload: {
                      class: BaseToastAlertClasses.error,
                      body: 'operations.signErrorHeader',
                    },
                  })
                );
              }
            }
          }),
          mergeMap((response: any) => [baseAssignmentsActions.successLoadAssignment({ payload: response.data })]),
          catchError(() => of(baseAssignmentsActions.errorLoadAssignment()))
        );
      })
    );
  });

  reloadAssignmentData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseAssignmentsActions.reloadAssignmentData),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((user) => user.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        const source$: Observable<any> = this.roleService.isPsp()
          ? this.assignmentsService.getPspAssignment(user.organizationId, action.payload.assignment)
          : this.assignmentsService.getCustomerAssignment(user.organizationId, action.payload.assignment);
        return source$.pipe(
          mergeMap((response: any) => {
            const assignmentStatus = this.roleService.isPsp()
              ? response.data.contract.signatures.statusPsp
              : response.data.contract.signatures.statusCustomer;
            return assignmentStatus === BaseSigningStatusBySide.IN_QUEUE
              ? [baseAssignmentsActions.successLoadAssignment({ payload: response.data })]
              : [
                  baseAssignmentsActions.successLoadAssignment({ payload: response.data }),
                  baseAssignmentsActions.loadAssignmentList({
                    payload: {
                      page: 1,
                      size: 15,
                      filters: this.baseFilterService.getPspAssignmentFilters(
                        this.queryParamsService.getAllQueryParams()
                      ),
                    },
                  }),
                ];
          }),
          catchError(() => of(baseAssignmentsActions.errorLoadAssignment()))
        );
      })
    );
  });

  loadCustomerAllAssignments$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseAssignmentsActions.loadCustomerAllAssignments),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((user) => user.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        return this.assignmentsService
          .getAllCustomerAssignments(
            user.organizationId,
            action.payload.page,
            action.payload.filters,
            action.payload.search,
            action.payload.orderKey,
            action.payload.orderDirection,
            action.payload.size
          )
          .pipe(
            mergeMap((response: any) => [
              baseAssignmentsActions.setCustomerAllAssignments({ payload: response }),
              baseAssignmentsActions.successCustomerAllAssignment(),
            ]),
            catchError(() => of(baseAssignmentsActions.errorCustomerAllAssignment()))
          );
      })
    );
  });

  downloadCustomerContracts$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseAssignmentsActions.downloadCustomerContracts),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((state) => state.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        const payload = { ...action.payload, customer: user.organizationId };
        return this.assignmentsService.exportCustomerContracts(payload).pipe(
          tap((response: any) => {
            const blob = new Blob([response.body], { type: 'application/zip' });
            const objectUrl = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.setAttribute('href', objectUrl);
            link.setAttribute('download', response.fileName);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }),
          map(() => baseAssignmentsActions.successDownloadCustomerContracts()),
          catchError(() => of(baseAssignmentsActions.errorDownloadCustomerContracts()))
        );
      })
    );
  });

  downloadPspContracts$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseAssignmentsActions.downloadPspContracts),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((state) => state.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        const payload = {
          ...action.payload,
          ...{ psp: user.organizationId },
        } as ExportPspContractsRequestInterface;
        return this.assignmentsService.exportPspContracts(payload).pipe(
          tap((response: any) => {
            const blob = new Blob([response.body], { type: 'application/zip' });
            const objectUrl = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.setAttribute('href', objectUrl);
            link.setAttribute('download', response.fileName);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }),
          map(() => baseAssignmentsActions.successDownloadPspContracts()),
          catchError(() => of(baseAssignmentsActions.errorDownloadPspContracts()))
        );
      })
    );
  });

  downloadPspAssignmentInfo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseAssignmentsActions.downloadPspAssignmentInfo),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((state) => state.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        const payload = {
          ...action.payload,
          psp: user.organizationId,
        } as ExportPspAssignmentInformationRequestInterface;
        return this.assignmentsService.exportPspAssignmentInfo(payload).pipe(
          tap((response: any) => {
            const blob = new Blob([response.body], { type: 'text/csv' });
            const objectUrl = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.setAttribute('href', objectUrl);
            link.setAttribute('download', response.fileName);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }),
          map(() => baseAssignmentsActions.successDownloadPspAssignment()),
          catchError(() => of(baseAssignmentsActions.errorDownloadPspAssignment()))
        );
      })
    );
  });

  downloadCustomerAssignmentInfo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseAssignmentsActions.downloadCustomerAssignmentInfo),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((state) => state.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        const payload = {
          ...action.payload,
          ...{ customer: user.organizationId },
        } as ExportCustomerAssignmentInformationRequestInterface;
        return this.assignmentsService.exportCustomerAssignmentInfo(payload).pipe(
          tap((response: any) => {
            const blob = new Blob([response.body], { type: 'text/csv' });
            const objectUrl = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.setAttribute('href', objectUrl);
            link.setAttribute('download', response.fileName);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }),
          map(() => baseAssignmentsActions.successDownloadCustomerAssignmentInfo()),
          catchError(() => of(baseAssignmentsActions.errorDownloadCustomerAssignmentInfo()))
        );
      })
    );
  });

  confirmAssignment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(baseAssignmentsActions.confirmAsignment),
      withLatestFrom(this.store.select(baseAuthSelectors.getUserInfo).pipe(map((user) => user.data))),
      switchMap(([action, user]) =>
        this.assignmentsService
          .confirmAssignment(action.payload.assignment, action.payload.assignmentDate, user?.organizationId || -1)
          .pipe(
            withLatestFrom(
              this.store.select(baseAssignmentsSelectors.getAssignmentDates).pipe(map((dates) => dates.data.data))
            ),
            mergeMap(([_response, dates]) => [
              baseAssignmentsActions.updateAssignmentDate({
                payload: {
                  ...dates.find((date: AssignmentDateListItemResponse) => date.id === action.payload.assignmentDate),
                  status: BaseAssignmentDateStatusEnum.ACCEPTED,
                },
              }),
              baseAssignmentsActions.confirmAsignmentSuccess(),
            ]),
            catchError(() => {
              return of(baseAssignmentsActions.confirmAsignmentError());
            })
          )
      )
    )
  );

  createUpdateAssignmentTimes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(baseAssignmentsActions.createUpdateAssignmentTimes),
      withLatestFrom(this.store.select(baseAuthSelectors.getUserInfo).pipe(map((user) => user.data))),
      switchMap(([action, user]) => {
        return this.assignmentsService
          .createUpdateAssignmentTimes({
            psp: (user as BaseTokenInterface).organizationId,
            assignment: action.payload.assignment,
            assignmentDate: action.payload.assignmentDate,
            body: action.payload.body,
          })
          .pipe(
            mergeMap(() => [
              baseAssignmentsActions.createUpdateAssignmentTimesSuccess(),
              baseAssignmentsActions.loadAssignmentDates({ payload: { assignment: action.payload.assignment } }),
            ]),
            catchError(() => of(baseAssignmentsActions.createUpdateAssignmentTimesError()))
          );
      })
    )
  );

  signContractCustomer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(baseAssignmentsActions.signContract),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((user) => user.data as BaseTokenInterface))
      ),
      // @ts-ignore
      switchMap(([action, user]) => {
        let method;
        if (this.roleService.isPsp()) {
          method =
            (action.payload.signingType as BaseSignContractOptions) === BaseSignContractOptions.signExternal
              ? this.assignmentsService.pspSignContractExternal({
                  psp: user.organizationId,
                  contract: action.payload.contract,
                })
              : action.payload.signingType === BaseSignContractOptions.concretization
              ? this.assignmentsService.pspSignContractConcretization({
                  psp: user.organizationId,
                  contract: action.payload.contract,
                })
              : this.assignmentsService.pspSignContractDigitally({
                  psp: user.organizationId,
                  contract: action.payload.contract,
                  body: action.payload.body as ContractRequest,
                });
        } else {
          method =
            (action.payload.signingType as BaseSignContractOptions) === BaseSignContractOptions.signExternal
              ? this.assignmentsService.customerSignContractExternal({
                  customer: user.organizationId,
                  contract: action.payload.contract,
                })
              : this.assignmentsService.customerSignContractDigitally({
                  customer: user.organizationId,
                  contract: action.payload.contract,
                  body: action.payload.body as ContractRequest,
                });
        }

        const alertMessage =
          action.payload.signingType === BaseSignContractOptions.concretization
            ? 'operations.concretionSuccessMessage'
            : 'operations.signSuccess';

        return method?.pipe(
          withLatestFrom(this.translateService.get(alertMessage)),
          mergeMap(([_, message]) => {
            const isDigitalSignature = action.payload.signingType === BaseSignContractOptions.signDigital;
            return isDigitalSignature
              ? [
                  baseAssignmentsActions.signContractSuccess(),
                  baseAssignmentsActions.loadAssignment({
                    payload: {
                      assignment: action.payload.assignment,
                      showDigitalSignStatus: isDigitalSignature,
                    },
                  }),
                  baseAssignmentsActions.loadAssignmentDates({ payload: { assignment: action.payload.assignment } }),
                ]
              : [
                  baseAssignmentsActions.signContractSuccess(),

                  // This success message shouldn't be displayed for digital signature process
                  // Since digital signature is async process
                  // Displaying of message in case of digital signature is handled in effect for loadAssignment action based on showDigitalSignStatus payload field value
                  baseSharedActions.addSystemAlert({
                    payload: {
                      class: BaseToastAlertClasses.success,
                      body: message,
                    },
                  }),

                  baseAssignmentsActions.loadAssignment({
                    payload: {
                      assignment: action.payload.assignment,
                      showDigitalSignStatus: isDigitalSignature,
                    },
                  }),
                  baseAssignmentsActions.loadAssignmentDates({ payload: { assignment: action.payload.assignment } }),
                ];
          }),
          catchError(() => of(baseAssignmentsActions.signContractError()))
        );
      })
    )
  );

  assignmentCancellation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(baseAssignmentsActions.assignmentCancellation),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((user) => user.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        const method = this.roleService.isPsp()
          ? this.assignmentsService.cancelAssignmentPsp(
              user?.organizationId,
              action.payload.assignment,
              action.payload.body
            )
          : this.assignmentsService.cancelAssignmentCustomer(
              user?.organizationId,
              action.payload.assignment,
              action.payload.body
            );

        return method.pipe(
          mergeMap((_) => {
            const activeFilters = this.baseFilterService.getPspAssignmentFilters(
              this.queryParamsService.getAllQueryParams()
            );

            return [
              baseAssignmentsActions.loadAssignmentList({
                payload: {
                  type: this.router.url.split('?')[0].split('/').pop() as string,
                  filters: activeFilters,
                  page: 1,
                },
              }),
              baseAssignmentsActions.loadAssignment({ payload: { assignment: action.payload.assignment } }),
              baseAssignmentsActions.loadAssignmentDates({ payload: { assignment: action.payload.assignment } }),
              baseAssignmentsActions.assignmentCancellationSuccess(),
            ];
          }),
          catchError(() => of(baseAssignmentsActions.assignmentCancellationError()))
        );
      })
    )
  );

  assignmentCustomerCancellation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(baseAssignmentsActions.assignmentCustomerCancellation),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((user) => user.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        return this.assignmentsService
          .cancelAssignmentCustomer(user?.organizationId, action.payload.assignment, action.payload.body)
          .pipe(
            mergeMap((_) => [
              baseAssignmentsActions.loadCustomerAllAssignments({
                payload: {
                  page: 1,
                  size: 15,
                  filters: this.baseFilterService.getPspAssignmentFilters(this.queryParamsService.getAllQueryParams()),
                },
              }),
              baseAssignmentsActions.loadAssignment({ payload: { assignment: action.payload.assignment } }),
              baseAssignmentsActions.assignmentCancellationSuccess(),
            ]),
            catchError(() => of(baseAssignmentsActions.assignmentCancellationError()))
          );
      })
    )
  );

  loadCustomerendedAssignments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(baseAssignmentsActions.getCandidateEndedAssignments),
      withLatestFrom(this.store.select(baseAuthSelectors.getUserInfo).pipe(map((user) => user.data))),
      switchMap(([action, user]) => {
        return this.assignmentsService.fetchCandidateEndedAssignments(user?.organizationId || -1, action.payload).pipe(
          mergeMap((response) => [
            baseAssignmentsActions.loadCandidateEndedAssignments({ payload: response }),
            baseAssignmentsActions.loadCandidateEndedAssignmentsSuccess(),
          ]),
          catchError(() => of(baseAssignmentsActions.loadCandidateEndedAssignmentsError()))
        );
      })
    )
  );

  loadCustomerReview$ = createEffect(() =>
    this.actions$.pipe(
      ofType(baseAssignmentsActions.getCustomerReviews),
      withLatestFrom(this.store.select(baseAuthSelectors.getUserInfo).pipe(map((response) => response.data))),
      switchMap(([action, user]) => {
        return this.assignmentsService.fetchCandidateReviews(user?.organizationId || -1, action.payload).pipe(
          mergeMap((response) => [
            baseAssignmentsActions.loadCustomerReviews({ payload: response }),
            baseAssignmentsActions.loadCustomerReviewsSuccessfully(),
          ]),
          catchError(() => of(baseAssignmentsActions.loadCustomerReviewsError()))
        );
      })
    )
  );

  createCandidateReview$ = createEffect(() =>
    this.actions$.pipe(
      ofType(baseAssignmentsActions.createCandidateRating),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((user) => user.data)),
        this.store.select(baseAssignmentsSelectors.getAssignment).pipe(map((assignment) => assignment.data))
      ),
      switchMap(([action, user, assignment]) => {
        return this.assignmentsService
          .createCandidateRating(
            user?.organizationId || -1,
            assignment?.profile.id || action.payload.assignment?.profile.id || action.payload.profileId,
            action.payload.body
          )
          .pipe(
            mergeMap(() => [
              baseAssignmentsActions.createCandidateRatingSuccess(),
              baseAssignmentsActions.getCustomerReviews({
                payload: assignment?.profile.id || action.payload.assignment?.profile.id || action.payload.profileId,
              }),
            ]),
            catchError(() => of(baseAssignmentsActions.createCandidateRatingError()))
          );
      })
    )
  );

  updateCandidateReview$ = createEffect(() =>
    this.actions$.pipe(
      ofType(baseAssignmentsActions.updateCandidateRating),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((user) => user.data)),
        this.store.select(baseAssignmentsSelectors.getAssignment).pipe(map((assignment) => assignment.data))
      ),
      switchMap(([action, user, assignment]) => {
        return this.assignmentsService
          .updateCandidateRating(
            user?.organizationId || -1,
            assignment?.profile.id || action.payload.assignment?.profile.id || action.payload.profileId,
            action.payload.rating,
            action.payload.body
          )
          .pipe(
            mergeMap((_) => [
              baseAssignmentsActions.updateCandidateRatingSuccess(),
              baseAssignmentsActions.getCustomerReviews({
                payload: assignment?.profile.id || action.payload.assignment?.profile.id || action.payload.profileId,
              }),
            ]),
            catchError(() => of(baseAssignmentsActions.updateCandidateRatingError()))
          );
      })
    )
  );

  loadAssignmentCustomer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(baseAssignmentsActions.loadAssignmentCustomer),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((state) => state.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        const source$ = this.roleService.isPsp()
          ? this.assignmentsService.getPspsCustomer(user.organizationId, action.payload.customer)
          : this.assignmentsService.getCustomersCustomer(action.payload.customer);

        return source$.pipe(
          map((response) => baseAssignmentsActions.setAssignmentCustomer({ payload: response })),
          catchError(() => of(baseAssignmentsActions.assignmentCancellationError()))
        );
      })
    )
  );

  acceptSignConditions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(baseAssignmentsActions.acceptDigitalSignConditions),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((state) => state.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        const source$ = this.roleService.isPsp()
          ? this.assignmentsService.acceptPSPSignCondition(user.organizationId.toString())
          : this.assignmentsService.acceptCustomerSignCondition(user.organizationId);

        return source$.pipe(
          map((_) =>
            baseAssignmentsActions.loadDigitalSignVoucher({
              payload: { hideAlert: action.payload?.hideSuccessMessage },
            })
          )
        );
      })
    )
  );

  updateCustomerProfilePersonalId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(baseAssignmentsActions.updateCustomerProfilePersonalId),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((state) => state.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        return this.assignmentsService
          .updateCustomerProfileInternalPersonalId({
            customer: user.organizationId,
            profile: action.payload.profile,
            body: action.payload.body,
          })
          .pipe(
            mergeMap((_) => [
              baseAssignmentsActions.successUpdateCustomerProfilePersonalId(),
              baseAssignmentsActions.loadAssignment({ payload: { assignment: action.payload.assignment } }),
            ]),
            catchError(() => of(baseAssignmentsActions.errorUpdateCustomerProfilePersonalId()))
          );
      })
    )
  );

  loadDigitalSignVoucher$ = createEffect(() =>
    this.actions$.pipe(
      ofType(baseAssignmentsActions.loadDigitalSignVoucher),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((state) => state.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        const source$ = this.roleService.isPsp()
          ? this.assignmentsService.getPSPSignVoucher(user.organizationId.toString())
          : this.assignmentsService.getCustomerSignVoucher(user.organizationId);

        return source$.pipe(
          tap((response) => {
            if (action.payload?.hideAlert) {
              return;
            }
          }),
          mergeMap((response) => {
            const voucherLink = response.url;
            const body = this.translateService.instant('namirialLoginModal.registration.successRegisterMessage', {
              voucherLink: `<a class="voucher-link" target="_blank" href="${voucherLink}">${voucherLink}</a>`,
            });
            return action.payload?.hideAlert
              ? [
                  baseAssignmentsActions.setDigitalSignVoucher({ payload: response }),
                  baseAssignmentsActions.successDigitalSignVoucher(),
                ]
              : [
                  baseAssignmentsActions.setDigitalSignVoucher({ payload: response }),
                  baseAssignmentsActions.successDigitalSignVoucher(),
                  baseSharedActions.addSystemAlert({
                    payload: {
                      class: BaseToastAlertClasses.success,
                      body,
                      closeManually: true,
                    },
                  }),
                ];
          }),
          catchError(() => of(baseAssignmentsActions.errorDigitalSignVoucher()))
        );
      })
    )
  );

  downloadContract$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseAssignmentsActions.viewContract),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((user) => user.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        const source$: Observable<any> = this.roleService.isPsp()
          ? this.assignmentsService.downloadPspContract(
              user.organizationId,
              action.payload.assignment,
              action.payload.contract
            )
          : this.assignmentsService.downloadCustomerContact(
              user.organizationId,
              action.payload.assignment,
              action.payload.contract
            );
        return source$.pipe(
          mergeMap((response) => [
            baseAssignmentsActions.setDocumentView({
              payload: {
                document: new Blob([response.body], { type: BaseAvailableFileTypeEnum.applicationPDF }),
                fileName: response.fileName,
              },
            }),
            baseAssignmentsActions.successDownloadContract(),
          ]),
          catchError(() => of(baseAssignmentsActions.errorDownloadContract()))
        );
      })
    );
  });

  downloadDocumentFile$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseAssignmentsActions.downloadCustomerCandidateDocument),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((state) => state.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        return this.assignmentsService
          .downloadCustomerCandidateDocumentFile({
            assignment: action.payload.assignment,
            document: action.payload.document,
            customer: user.organizationId,
          })
          .pipe(
            tap((response: any) => {
              if (!action.payload.openInModal) {
                const blob = new Blob([response.body], { type: BaseAvailableFileTypeEnum.applicationPDF });
                const objectUrl = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.setAttribute('href', objectUrl);
                link.setAttribute('download', response.fileName);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
              }
            }),
            !action.payload.openInModal
              ? map(() => baseAssignmentsActions.successDownloadCustomerCandidateDocument())
              : mergeMap((response) => [
                  baseAssignmentsActions.setDocumentView({
                    payload: {
                      document:
                        action.payload.documentType === BaseAvailableFileTypeEnum.pdf
                          ? new Blob([response.body], { type: BaseAvailableFileTypeEnum.applicationPDF })
                          : this.sanitizer.bypassSecurityTrustUrl(
                              URL.createObjectURL(
                                new Blob([response.body], { type: `image/${action.payload.documentType}` })
                              )
                            ),
                    },
                  }),
                  baseAssignmentsActions.successDownloadCustomerCandidateDocument(),
                ]),
            catchError(() => of(baseAssignmentsActions.errorDownloadCustomerCandidateDocument()))
          );
      })
    );
  });

  regeneratePspAssignmentContract$ = createEffect(() =>
    this.actions$.pipe(
      ofType(baseAssignmentsActions.regeneratePspAssignmentContract),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((state) => state.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        return this.assignmentsService
          .regeneratePspAssignmentContract({
            assignment: action.payload.assignment,
            psp: user.organizationId,
          })
          .pipe(
            mergeMap((_) => [
              baseAssignmentsActions.successRegeneratePspAssignmentContract(),
              baseAssignmentsActions.loadAssignment({ payload: { assignment: action.payload.assignment } }),
              baseAssignmentsActions.loadAssignmentList({
                payload: {
                  page: 1,
                  size: 15,
                  filters: this.baseFilterService.getPspAssignmentFilters(this.queryParamsService.getAllQueryParams()),
                },
              }),
            ]),
            catchError(() => of(baseAssignmentsActions.errorRegeneratePspAssignmentContract()))
          );
      })
    )
  );

  regenerateCustomerAssignmentContract$ = createEffect(() =>
    this.actions$.pipe(
      ofType(baseAssignmentsActions.regenerateCustomerAssignmentContract),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((state) => state.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        return this.assignmentsService
          .regenerateCustomerAssignmentContract({
            assignment: action.payload.assignment,
            customer: user.organizationId,
          })
          .pipe(
            mergeMap((_) => [
              baseAssignmentsActions.successRegenerateCustomerAssignmentContract(),
              baseAssignmentsActions.loadAssignment({ payload: { assignment: action.payload.assignment } }),
              baseAssignmentsActions.loadCustomerAllAssignments({
                payload: {
                  page: 1,
                  size: 15,
                  filters: this.baseFilterService.getPspAssignmentFilters(this.queryParamsService.getAllQueryParams()),
                },
              }),
            ]),
            catchError(() => of(baseAssignmentsActions.errorRegenerateCustomerAssignmentContract()))
          );
      })
    )
  );

  regenerateCustomerAssignmentProfileIDCard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(baseAssignmentsActions.regenerateCustomerAssignmentProfileIDCard),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((state) => state.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        return this.assignmentsService
          .regenerateCustomerAssignmentProfileIDCard({
            customer: user.organizationId,
            assignment: action.payload.assignment,
            profile: action.payload.profile,
          })
          .pipe(
            mergeMap((_) => [baseAssignmentsActions.successRegenerateCustomerAssignmentProfileIDCard()]),
            catchError(() => of(baseAssignmentsActions.errorRegenerateCustomerAssignmentProfileIDCard()))
          );
      })
    )
  );

  downloadCustomerAssignmentProfileIDCard$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseAssignmentsActions.downloadCustomerAssignmentProfileIDCard),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((state) => state.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        return this.assignmentsService
          .getCustomerAssignmentProfileIDCard({
            customer: user.organizationId,
            assignment: action.payload.assignment,
            profile: action.payload.profile,
          })
          .pipe(
            tap((response: any) => {
              if (!action.payload.openInModal && action.payload.downloadOnly) {
                const blob = new Blob([response.body], { type: BaseAvailableFileTypeEnum.applicationPDF });
                const objectUrl = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.setAttribute('href', objectUrl);
                link.setAttribute('download', response.fileName);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
              }
            }),
            !action.payload.openInModal
              ? map(() => baseAssignmentsActions.successDownloadCustomerAssignmentProfileIDCard())
              : mergeMap((response) => [
                  baseAssignmentsActions.setCustomerAssignmentProfileIDCardView({
                    payload: {
                      document: new Blob([response.body], { type: BaseAvailableFileTypeEnum.applicationPDF }),
                      fileName: response.fileName,
                    },
                  }),
                  baseAssignmentsActions.successDownloadCustomerAssignmentProfileIDCard(),
                ]),
            catchError(() => of(baseAssignmentsActions.errorDownloadCustomerAssignmentProfileIDCard()))
          );
      })
    );
  });

  constructor(
    private actions$: Actions,
    private store: Store<BaseCoreState>,
    private assignmentsService: BaseAssignmentsService,
    private roleService: BaseRolesService,
    private translateService: TranslateService,
    private modalService: BaseModalService,
    private router: Router,
    private queryParamsService: BaseQueryParamService,
    private baseFilterService: BaseFilterService,
    private sanitizer: DomSanitizer
  ) {}
}
