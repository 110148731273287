import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { baseRelatedPspBranchActions } from '../actions';
import { BaseCoreState } from '../reducers';
import { baseAuthSelectors } from '../selectors';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { BaseTokenInterface } from '../../shared/interfaces/base-token-service.interface';
import { BaseRelatedPspBranchesService } from '../../modules/related-psp-branches/base-related-psp-branches.service';
import { BaseAvailableFileTypeEnum } from '../../shared/enums/base-available-file-type.enum';

@Injectable()
export class BaseRelatedPspBranchesEffects {
  loadRelatedPspBranches$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseRelatedPspBranchActions.loadRelatedPspBranchList),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((user) => user.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        return this.relatedPspBranchesService
          .relatedPspBranchesList({
            customer: user.organizationId,
            search: action.payload?.search,
            page: action.payload?.page,
            'customerLocations[]': action.payload?.locations?.length ? action.payload.locations.join() : undefined,
          })
          .pipe(
            mergeMap((response: any) => [
              baseRelatedPspBranchActions.setRelatedPspBranchList({ payload: response }),
              baseRelatedPspBranchActions.successRelatedPspBranchList(),
            ]),
            catchError(() => of(baseRelatedPspBranchActions.errorRelatedPspBranchList()))
          );
      })
    );
  });

  loadRelatedPspBranchDetails$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseRelatedPspBranchActions.loadRelatedPspBranchDetails),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((user) => user.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        return this.relatedPspBranchesService
          .relatedPspBranchDetails({
            customer: user.organizationId,
            psp: action.payload.psp,
            branch: action.payload.branch,
            location: action.payload.location,
          })
          .pipe(
            mergeMap((response: any) => [
              baseRelatedPspBranchActions.setRelatedPspBranchDetails({ payload: response }),
              baseRelatedPspBranchActions.successRelatedPspBranchDetails(),
            ]),
            catchError(() => of(baseRelatedPspBranchActions.errorRelatedPspBranchDetails()))
          );
      })
    );
  });

  loadRelatedPspBranchDocuments$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseRelatedPspBranchActions.loadRelatedPspBranchDocuments),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((user) => user.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        return this.relatedPspBranchesService
          .relatedPspBranchDocuments({
            customer: user.organizationId,
            psp: action.payload.psp,
          })
          .pipe(
            mergeMap((response: any) => [
              baseRelatedPspBranchActions.setRelatedPspBranchDocuments({ payload: response }),
              baseRelatedPspBranchActions.successRelatedPspBranchDocuments(),
            ]),
            catchError(() => of(baseRelatedPspBranchActions.errorRelatedPspBranchDocuments()))
          );
      })
    );
  });

  downloadRelatedPspBranchDocument$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseRelatedPspBranchActions.downloadRelatedPspBranchDocument),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((user) => user.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        return this.relatedPspBranchesService
          .downloadDocument({ ...action.payload, ...{ customer: user.organizationId } })
          .pipe(
            tap((response) => {
              if (!action.payload.openInNewWindow) {
                const blob = new Blob([response.body], { type: BaseAvailableFileTypeEnum.applicationPDF });
                const objectUrl = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.setAttribute('href', objectUrl);
                link.setAttribute('download', response.fileName);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
              }
            }),
            action.payload.openInNewWindow
              ? mergeMap((response) => [
                  baseRelatedPspBranchActions.setDownloadRelatedPspBranchDocument({
                    payload: {
                      document:
                        action.payload.documentType === BaseAvailableFileTypeEnum.pdf
                          ? new Blob([response.body], { type: BaseAvailableFileTypeEnum.applicationPDF })
                          : this.sanitizer.bypassSecurityTrustUrl(
                              URL.createObjectURL(
                                new Blob([response.body], { type: `image/${action.payload.documentType}` })
                              )
                            ),
                    },
                  }),
                  baseRelatedPspBranchActions.successDownloadRelatedPspBranchDocument(),
                ])
              : map(() => baseRelatedPspBranchActions.successDownloadRelatedPspBranchDocument()),
            catchError(() => of(baseRelatedPspBranchActions.errorDownloadRelatedPspBranchDocument()))
          );
      })
    );
  });

  downloadRelatedPspBranchContract$ = createEffect(() =>
    this.actions$.pipe(
      ofType(baseRelatedPspBranchActions.downloadRelatedPspBranchContract),
      withLatestFrom(this.store.select(baseAuthSelectors.getUserInfo).pipe(map((user) => user.data))),
      switchMap(([action, user]) => {
        return this.relatedPspBranchesService
          .downloadContract({
            ...action.payload,
            customer: user?.organizationId || -1,
          })
          .pipe(
            tap((response) => {
              const blob = new Blob([response], { type: BaseAvailableFileTypeEnum.applicationPDF });
              const objectUrl = URL.createObjectURL(blob);
              const link = document.createElement('a');
              link.setAttribute('href', objectUrl);
              link.setAttribute('download', !!action.payload.fileName ? action.payload.fileName : 'Contract');
              link.style.visibility = 'hidden';
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            })
          );
      })
    )
  );

  loadRelatedPspBranchSurcharges$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseRelatedPspBranchActions.loadSurcharges),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((user) => user.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        return this.relatedPspBranchesService
          .surchargesList({
            customer: user.organizationId,
            psp: action.payload,
          })
          .pipe(
            mergeMap((response: any) => [
              baseRelatedPspBranchActions.setSurcharges({ payload: response }),
              baseRelatedPspBranchActions.successSurcharges(),
            ]),
            catchError(() => of(baseRelatedPspBranchActions.errorSurcharges()))
          );
      })
    );
  });

  loadRelatedPspBranchData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseRelatedPspBranchActions.loadRelatedPspBranchData),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((user) => user.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        return this.relatedPspBranchesService
          .relatedPspBranchData({
            customer: user.organizationId,
            psp: action.payload.psp,
          })
          .pipe(
            mergeMap((response: any) => [
              baseRelatedPspBranchActions.setRelatedPspBranchData({ payload: response }),
              baseRelatedPspBranchActions.successRelatedPspBranchData(),
            ]),
            catchError(() => of(baseRelatedPspBranchActions.errorRelatedPspBranchData()))
          );
      })
    );
  });

  constructor(
    private actions$: Actions,
    private store: Store<BaseCoreState>,
    private relatedPspBranchesService: BaseRelatedPspBranchesService,
    private sanitizer: DomSanitizer
  ) {}
}
