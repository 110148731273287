import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BaseRolesService } from '../../../core/services/base-roles.service';
import { BaseSignContractOptions } from '../../../modules/assignments/_enums/base-sign-contract-options.enum';
import { BaseButtonSize } from '../button/base-button.component';
import { AssignmentCustomerResponse, AssignmentPspResponse, ContractResponse } from '../../../../api';
import { BaseContractSigningTypeEnum } from '../../../modules/assignments/_enums/base-contract-signing-type.enum';
import { BaseSigningStatusEnum } from '../../../modules/assignments/_enums/base-signing-status.enum';

@Component({
  selector: 'base-sign-contract-selection',
  templateUrl: './base-sign-contract-selection.component.html',
})
export class BaseSignContractSelectionComponent implements OnInit {
  @Input() modalRef: NgbModalRef;
  @Input() signingType: BaseSignContractOptions | undefined;
  @Input() currentAssignment: AssignmentPspResponse | AssignmentCustomerResponse;
  @Output() signingTypeChange = new EventEmitter<BaseSignContractOptions>();
  isPSP: boolean;
  baseContractSigningTypeEnum = BaseContractSigningTypeEnum;
  baseSigningStatusEnum = BaseSigningStatusEnum;
  buttonSizes = BaseButtonSize;
  signOptions = BaseSignContractOptions;

  constructor(private roleService: BaseRolesService) {}

  get validContract(): ContractResponse {
    return this.currentAssignment.contracts?.find((contract) => contract.valid) as ContractResponse;
  }

  get showSignDigital() {
    return this.currentAssignment?.psp?.signDigital ?? this.currentAssignment?.customer?.signDigital ?? false;
  }

  ngOnInit(): void {
    this.isPSP = this.roleService.isPsp();
  }

  signingTypeHasChanged(): void {
    this.signingTypeChange.emit(this.signingType);
  }
}
