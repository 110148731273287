<div class="listview-item no-pointer bg-lightest mb-3">
  <form
    [formGroup]="surchargeForm"
    [ngClass]="{ 'was-validated': submitted }"
  >
    <div
      class="row"
      [ngClass]="{ 'align-items-baseline': submitted && surchargeForm.get('rate')?.errors }"
    >
      <div class="col-3">
        <span class="fw-bold">{{ 'days.' + surcharge.type | translate }}</span>
      </div>
      <div class="col-3">
        <span *ngIf="!isEdited">{{ surcharge.fromTime }}-{{ surcharge.toTime }} {{ 'dateTime.hour' | translate }}</span>
        <div
          *ngIf="isEdited"
          class="d-flex flex-column flex-xxl-row align-items-center"
        >
          <input
            formControlName="fromTime"
            type="time"
            class="small-time-input-field less-padding clock-solid"
          />
          <span class="mx-2">-</span>
          <input
            formControlName="toTime"
            type="time"
            class="small-time-input-field less-padding clock-solid"
          />
        </div>
      </div>
      <div class="col-3">
        <span *ngIf="!isEdited">{{ surcharge.rate }}%</span>
        <div
          *ngIf="isEdited"
          class="d-flex align-items-center"
        >
          <base-input
            formControlName="rate"
            placeholder=""
            type="number"
            min="0"
            class="editable-input surcharge-number-field"
            [isInvalid]="!!surchargeForm.get('rate')?.errors"
          ></base-input>
          <span
            class="ms-2"
            [ngClass]="{ 'align-self-start mt-2': !!surchargeForm.get('rate')?.errors }"
            >%</span
          >
        </div>
        <span
          *ngIf="submitted && surchargeForm.get('rate')?.errors"
          class="invalid-feedback d-block"
        >
          {{ surchargeForm.get('rate')?.errors | baseFieldValidation | translate }}
        </span>
      </div>
      <div class="col-2">
        <span *ngIf="!isEdited">{{ (surcharge.cumulative ? 'action.yes' : 'action.no') | translate }}</span>
        <base-switch
          *ngIf="isEdited"
          formControlName="cumulative"
        ></base-switch>
      </div>
      <div class="col-1">
        <div class="d-flex gap-1">
          <base-button
            color="dark"
            icon="fa-solid {{ isEdited ? 'fa-check' : 'fa-pen' }}"
            [size]="buttonSizes.sm"
            [iconSize]="buttonSizes.sm"
            [disabled]="!isEdited ? disabled : false"
            [tooltip]="isEdited ? 'action.save' : 'action.edit'"
            (btnClick)="handleSurchargeForm()"
          ></base-button>
          <base-button
            *ngIf="isEdited"
            color="dark"
            icon="fa-solid fa-xmark"
            [size]="buttonSizes.sm"
            [iconSize]="buttonSizes.sm"
            tooltip="action.cancel"
            (btnClick)="cancelSurchargeEdit()"
          ></base-button>
        </div>
      </div>
    </div>
  </form>
</div>
