import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'baseIsWeekend',
})
export class BaseIsWeekendPipe implements PipeTransform {
  transform(index: number): boolean {
    return (index - 5) % 7 === 0 || (index - 6) % 7 === 0;
  }
}
