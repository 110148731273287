import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { noop, Subject } from 'rxjs';
import { BaseButtonSize } from '../../../../../shared/components/button/base-button.component';
import { DocumentListItemResponse, DocumentRequest } from '../../../../../../api';
import { Validators, FormGroup, NonNullableFormBuilder } from '@angular/forms';
import { baseFilesizeValidator } from '../../../../../shared/validators/base-filesize.validator';
import { baseFiletypeValidator } from '../../../../../shared/validators/base-filetype.validator';
import { BaseDateService } from '../../../../../shared/services/base-date.service';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BaseModalService } from '../../../../../shared/services/base-modal.service';
import { baseDocumentSelectors, baseDocumentActions, BaseCoreState } from '../../../../../_store';
import { Store } from '@ngrx/store';
import { BaseDocumentFormInterface } from '../../../_interfaces/base-master-data.interface';
import { filter, map, takeUntil } from 'rxjs/operators';
import { BaseAvailableFileTypeEnum } from '../../../../../shared/enums/base-available-file-type.enum';

@Component({
  selector: 'base-master-data-document',
  templateUrl: './base-master-data-document.component.html',
  styleUrls: ['./base-master-data-document.component.scss'],
})
export class BaseMasterDataDocumentComponent implements OnInit, OnDestroy {
  @ViewChild('documentModalBody') documentModalBody: ElementRef;
  @ViewChild('documentViewerTemplate') documentViewerTemplate: ElementRef;
  @ViewChild('deleteDocumentModalTemplate') deleteDocumentModalTemplate: ElementRef;

  @Input() document: { document: DocumentListItemResponse; categoryLabel: string | undefined };
  @Input() readonly = false;
  @Input() emitOnly = false;
  @Input() documentCategories: { value: number; label: string }[];

  @Output() selectDocumentEvent = new EventEmitter<{ id: string; action: string; type: string }>();

  modalRef: NgbModalRef;
  deleteModalRef: NgbModalRef;
  documentForm: FormGroup<BaseDocumentFormInterface>;
  buttonSizes = BaseButtonSize;
  documentFormSubmitted = false;
  activeDocumentSrc: Blob;
  activeDocument: DocumentListItemResponse | undefined;
  documentViewerModalRef: NgbModalRef;
  baseAvailableFileTypes = BaseAvailableFileTypeEnum;
  private destroy$ = new Subject();

  constructor(
    private fb: NonNullableFormBuilder,
    private modalService: BaseModalService,
    private store: Store<BaseCoreState>
  ) {}

  ngOnInit(): void {
    this.store
      .select(baseDocumentSelectors.getDownloadDocument)
      .pipe(
        takeUntil(this.destroy$),
        filter((state) => !!state.data),
        map((state) => state.data)
      )
      .subscribe((document) => {
        if (this.activeDocument && this.activeDocument.id === this.document.document.id) {
          this.openDocumentViewer(document as Blob);
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }

  openEditDocumentModal(): void {
    this.selectDocumentEvent.emit({ id: this.document.document.id, action: 'edit', type: this.document.document.type });

    if (this.emitOnly) {
      return;
    }

    this.documentForm = this.fb.group({
      name: [this.document.document.name, [Validators.required]],
      category: [
        this.documentCategories.find((category) => this.document.document.category === category.value) || null,
        [Validators.required],
      ],
      validUntil: [{ value: this.document.document.validUntil as string | null, disabled: true }],
      private: [false],
      file: [null as Blob | null, [baseFilesizeValidator(), baseFiletypeValidator()]],
      existingFile: [this.document.document as DocumentListItemResponse | null, [baseFilesizeValidator()]],
    });
    this.modalRef = this.modalService.open(
      {
        headText: 'document.info',
        bodyTemplate: this.documentModalBody,
        okText: 'action.save',
        canBeClosed: this.canDocumentModalBeClosed.bind(this),
      },
      {
        size: 'lg',
      }
    );

    this.modalRef.result.then(
      () => {
        if (this.documentForm.valid) {
          const documentFormValue = this.documentForm.getRawValue();
          const body: DocumentRequest = { name: documentFormValue.name };

          if (documentFormValue.category?.value) {
            body.category = documentFormValue.category.value;
          }

          if (documentFormValue.validUntil) {
            body.validUntil = BaseDateService.toServerFormatString(documentFormValue.validUntil);
          }

          this.store.dispatch(
            baseDocumentActions.updateDocument({
              payload: { document: this.document.document.id, body },
            })
          );

          if (documentFormValue.file) {
            this.store.dispatch(
              baseDocumentActions.changeDocumentFile({
                payload: {
                  document: this.document.document.id,
                  file: documentFormValue.file,
                },
              })
            );
          }
          this.resetDocumentForm();
        }
      },
      () => {
        this.resetDocumentForm();
      }
    );
  }

  downloadDocument(): void {
    this.selectDocumentEvent.emit({
      id: this.document.document.id,
      action: 'download',
      type: this.document.document.type,
    });

    if (this.emitOnly) {
      return;
    }

    this.store.dispatch(
      baseDocumentActions.downloadDocument({
        payload: { document: this.document.document.id, documentType: this.document.document.type },
      })
    );
  }

  openDocument(): void {
    this.activeDocument = this.document.document;
    this.selectDocumentEvent.emit({ id: this.document.document.id, action: 'open', type: this.document.document.type });

    if (this.emitOnly) {
      return;
    }

    this.store.dispatch(
      baseDocumentActions.downloadDocument({
        payload: { document: this.document.document.id, documentType: this.document.document.type, openInModal: true },
      })
    );
  }

  deleteDocument(): void {
    this.deleteModalRef = this.modalService.open(
      {
        headText: 'document.delete.title',
        bodyTemplate: this.deleteDocumentModalTemplate,
        okText: 'action.okDelete',
        cancelText: 'action.cancel',
      },
      {
        size: 'lg',
        backdrop: 'static',
      }
    );

    this.deleteModalRef.result.then(() => {
      this.selectDocumentEvent.emit({
        id: this.document.document.id,
        action: 'delete',
        type: this.document.document.type,
      });

      if (this.emitOnly) {
        return;
      }

      this.store.dispatch(baseDocumentActions.deleteDocument({ payload: { document: this.document.document.id } }));
    }, noop);
  }

  private resetDocumentForm(): void {
    this.documentForm.reset();
    this.documentFormSubmitted = false;
  }

  private canDocumentModalBeClosed(): boolean {
    this.documentFormSubmitted = true;
    return this.documentForm.valid;
  }

  private openDocumentViewer(document: Blob): void {
    this.activeDocumentSrc = document;
    this.documentViewerModalRef = this.modalService.open(
      {
        bodyTemplate: this.documentViewerTemplate,
        cancelButtonDisplayed: false,
        okButtonDisplayed: false,
        hideHeader: true,
        hideFooter: true,
      },
      { size: 'xl' }
    );
    this.documentViewerModalRef.result.then(
      () => this.cleanDocumentViewer(),
      () => this.cleanDocumentViewer()
    );
  }

  private cleanDocumentViewer() {
    this.store.dispatch(baseDocumentActions.clearDownloadDocument());
    this.activeDocument = undefined;
  }
}
