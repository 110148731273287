import { Component, Input } from '@angular/core';
import { ContractResponse } from '../../../../../api';
import { BaseSigningStatusBySide } from '../../_enums/base-signing-status.enum';

@Component({
  selector: 'base-sign-contract-status-tooltip',
  templateUrl: './base-sign-contract-status-tooltip.component.html',
})
export class BaseSignContractStatusTooltipComponent {
  @Input() contract: ContractResponse;
  @Input() isPsp: boolean;

  signingStatusBySide = BaseSigningStatusBySide;
}
