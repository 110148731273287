import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';
import { isString } from '@sentry/utils';
import * as Sentry from '@sentry/angular-ivy';
import { Scope } from '@sentry/angular-ivy';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { baseAuthSelectors } from '../../_store';
import { BaseTokenInterface } from '../../shared/interfaces/base-token-service.interface';
import { BaseGlobalErrorService } from '../services/base-global-error.service';
import { BaseRolesService } from '../services/base-roles.service';
import { EnvironmentUtils } from '../utilities/environment-utils';
import { BASE_CUSTOM_ERROR_ALERTS } from '../constants/base-custom-error-alerts';

type ErrorCandidate = {
  name?: unknown;
  message?: unknown;
  stack?: unknown;
};

@Injectable()
export class BaseGlobalErrorHandler implements ErrorHandler {
  constructor(private injector: Injector, private globalErrorService: BaseGlobalErrorService, private zone: NgZone) {}

  handleError(error: any): void {
    this.handleSentryError(error);

    const handlerFunc = this.globalErrorService.registerError(error);
    this.zone.run(() => {
      handlerFunc();
    });
  }

  private handleSentryError(error: any): void {
    const alertErrorIndex = BASE_CUSTOM_ERROR_ALERTS.findIndex(
      (alert) =>
        alert?.ignoreBySentry && alert?.errorMessage?.toLowerCase().includes(error?.message?.toLowerCase() + '')
    );
    if (EnvironmentUtils.getEnvironment() === 'dev' || alertErrorIndex > -1) {
      return;
    }

    const store = this.injector.get(Store);
    const roleService = this.injector.get(BaseRolesService);

    store
      .select(baseAuthSelectors.getUserInfo)
      .pipe(map((state) => state.data as BaseTokenInterface))
      .subscribe((user: BaseTokenInterface) => {
        Sentry.configureScope((scope: Scope) => {
          if (roleService.isPsp() || roleService.isCustomer()) {
            scope.setUser({
              userId: user?.id,
              roles: user?.roles.toString(),
              organizationId: user?.organizationId,
            });
          } else {
            scope.setUser(null);
          }
        });
        Sentry.captureException(this.extractHttpModuleError(error));
      });
  }

  private extractHttpModuleError(error: any): string | Error {
    if (this.isErrorOrErrorLikeObject(error.error)) {
      return error.error;
    }

    if (error.error instanceof ErrorEvent && error.error.message) {
      return error.error.message;
    }

    if (typeof error.error === 'string') {
      return `Server returned code ${error.status} with body "${error.error}"`;
    }

    return error.message;
  }

  private isErrorOrErrorLikeObject(value: unknown): value is Error {
    if (value instanceof Error) {
      return true;
    }

    if (value === null || typeof value !== 'object') {
      return false;
    }

    const candidate = value as ErrorCandidate;

    return (
      isString(candidate.name) &&
      isString(candidate.message) &&
      (undefined === candidate.stack || isString(candidate.stack))
    );
  }
}
