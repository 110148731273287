<div class="row overflow-hidden">
  <div class="col-2 d-flex align-items-center">
    <span
      baseTruncate
      class="fw-bold branch-name"
      >{{ branch.primaryData.name | baseIsEmpty }}</span
    >
  </div>

  <div class="col-1">
    <span baseTruncate>{{ branch.userCount | baseIsEmpty: '0' }}</span>
  </div>

  <div class="col-3">
    <span baseTruncate>{{ branch.businessAddress.street | baseIsEmpty }}</span
    ><br />
    <span baseTruncate
      >{{ branch.businessAddress.postCode | baseIsEmpty }} {{ branch.businessAddress.city | baseIsEmpty }}</span
    >
  </div>

  <div class="col-3">
    <span baseTruncate>{{ branch.contact.email | baseIsEmpty: ('noEmail' | translate) }}</span
    ><br />
    <span baseTruncate>{{ branch.contact.phone | baseIsEmpty: ('noPhone' | translate) }}</span>
  </div>

  <div class="col-2 d-flex">
    <base-switch
      [formControl]="isActive"
      (ngModelChange)="changeIsActive($event)"
    ></base-switch>
  </div>

  <div class="col-1 d-flex justify-content-end">
    <base-button
      class="ms-3"
      color="dark"
      icon="fa-solid fa-pen"
      stateClass="pe-1"
      [size]="buttonSizes.md"
      [iconSize]="buttonSizes.md"
      (btnClick)="editBranch()"
    ></base-button>
  </div>
</div>
