import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { baseCustomerDashboardActions, baseCustomerDashboardSelectors, BaseCoreState } from '../../../_store';

@Injectable()
export class BaseCustomerDashboardOffersGuard {
  constructor(private store: Store<BaseCoreState>) {}

  canActivate(): Observable<boolean> {
    this.store.dispatch(baseCustomerDashboardActions.loadCustomerOfferData());
    return this.store.select(baseCustomerDashboardSelectors.getCustomerOfferData).pipe(
      filter((state) => state !== undefined),
      map((state) => !!state)
    );
  }
}
