import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { BaseButtonSize } from '../../../shared/components/button/base-button.component';
import { BaseTabInterface } from '../../../shared/interfaces/base-tab.interface';
import { BaseRolesService } from '../../../core/services/base-roles.service';
import { BASE_PERMISSIONS } from '../../../core/constants/base-permissions';

@Component({
  selector: 'base-sidebar',
  templateUrl: './base-sidebar.component.html',
  styleUrls: ['./base-sidebar.component.scss'],
})
export class BaseSidebarComponent implements OnInit {
  @Input() logo: string;
  @Input() applicationSections: Array<BaseTabInterface & { featurePermissionKeys: string[] }>;
  buttonSizes = BaseButtonSize;
  tabs: Array<BaseTabInterface & { featurePermissionKeys: string[] }> = [];
  headerHeight = document.querySelector('base-header')?.getBoundingClientRect().height;
  sidebarScrollHeight = this.headerHeight;
  isSidebarLogoVisible = false;

  constructor(private offcanvasService: NgbOffcanvas, private roleService: BaseRolesService) {}

  @HostListener('window:scroll', ['$event'])
  onScroll() {
    const scrollTop = document.documentElement.scrollTop;
    const headerHeight = this.headerHeight as number;
    if (headerHeight - scrollTop > 20) {
      this.sidebarScrollHeight = headerHeight - scrollTop;
      this.isSidebarLogoVisible = false;
    } else {
      this.sidebarScrollHeight = 20;
      this.isSidebarLogoVisible = true;
    }
  }

  ngOnInit(): void {
    this.tabs = this.applicationSections?.filter(
      (option) =>
        !option.featurePermissionKeys.length ||
        option.featurePermissionKeys.some((permission) =>
          this.roleService.hasFeaturePermission(permission as BASE_PERMISSIONS)
        )
    );
  }
}
