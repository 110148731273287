import { BaseInquiryService } from '../_services/base-inquiry.service';
import { BaseFilterService } from '../../../shared/services/base-filter.service';
import { FormGroup } from '@angular/forms';
import { EventEmitter } from '@angular/core';

export abstract class BaseAbstractFiltersAndActionsClass {
  isLegendVisible = false;
  isFiltersVisible = false;
  activeFiltersAmount: number;
  searchTerm = '';
  filtersForm: FormGroup;
  searchTermChanged: EventEmitter<string> = new EventEmitter<string>();

  protected constructor(protected baseInquiryService: BaseInquiryService, public filterService: BaseFilterService) {}

  showSidebar(): void {
    this.baseInquiryService.toggleInquirySidebarSubject$.next(true);
  }

  toggleLegendVisibility(): void {
    this.isLegendVisible = !this.isLegendVisible;
    if (this.isLegendVisible) {
      this.isFiltersVisible = false;
    }
  }

  toggleFilterVisibility(): void {
    this.isFiltersVisible = !this.isFiltersVisible;
    if (this.isFiltersVisible) {
      this.isLegendVisible = false;
    }
    this.activeFiltersAmount = this.filterService.updateActiveFilters(this.filtersForm.value).length;
  }

  onSearchQueryChange(query: string): void {
    this.searchTerm = query;
    this.searchTermChanged.emit(query);
  }
}
