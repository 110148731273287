import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseFilterInterface } from '../../interfaces/base-filter.interface';

@Component({
  selector: 'base-filter-buttons',
  templateUrl: './base-filter-buttons.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BaseFilterButtonsComponent),
      multi: true,
    },
  ],
})
export class BaseFilterButtonsComponent implements ControlValueAccessor {
  private value = { id: '', title: '' };
  @Input() filters: Array<BaseFilterInterface> = [];
  @Input() selectedClass = 'btn-primary';
  private onChange = (value: any) => {};
  private onTouched = () => {};

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => {}): void {
    this.onTouched = fn;
  }

  writeValue(outsideValue: BaseFilterInterface): void {
    this.value = outsideValue;
  }

  get current(): BaseFilterInterface {
    return this.value;
  }

  set current(filter: BaseFilterInterface) {
    this.value = filter;
    this.onChange(this.value);
    this.onTouched();
  }
}
