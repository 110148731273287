import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Validators, NonNullableFormBuilder } from '@angular/forms';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { noop } from 'rxjs';
import { Store } from '@ngrx/store';
import { BaseButtonSize } from '../../../../../shared/components/button/base-button.component';
import { BaseFilterInterface } from '../../../../../shared/interfaces/base-filter.interface';
import { GroupListItemResponse, GroupUpdateRequest, PermissionItemResponse } from '../../../../../../api';
import { BaseModalService } from '../../../../../shared/services/base-modal.service';
import { BaseCoreState, baseUserActions } from '../../../../../_store';
import { BASE_PERMISSIONS } from '../../../../../core/constants/base-permissions';

@Component({
  selector: 'base-permission-groups',
  templateUrl: './base-permission-groups.component.html',
  styleUrls: ['./base-permission-groups.component.scss'],
})
export class BasePermissionGroupsComponent implements OnInit, OnChanges {
  @ViewChild('addPermissionGroupTemplate', { static: true }) addPermissionGroupTemplate: ElementRef;
  @ViewChild('editPermissionGroupTemplate', { static: true }) editPermissionGroupTemplate: ElementRef;
  @Input() permissionGroups: GroupListItemResponse[] = [];
  @Input() permissions: PermissionItemResponse[];
  @Output() selectGroup: EventEmitter<BaseFilterInterface> = new EventEmitter<BaseFilterInterface>();

  permissionGroupsForFilters: BaseFilterInterface[] = [];
  permissionsToSelect: (PermissionItemResponse & { isSelected?: boolean })[] = [];
  selectedGroup: BaseFilterInterface;
  modalRef: NgbModalRef;
  isFormSubmitted = false;
  buttonSizes = BaseButtonSize;
  addGroupForm = this.fb.group({
    name: ['', [Validators.required]],
    permissions: [[] as number[]],
  });

  constructor(
    private fb: NonNullableFormBuilder,
    private modalService: BaseModalService,
    private store: Store<BaseCoreState>
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.permissionGroups) {
      this.permissionGroupsForFilters = this.prepareGroupsForFilters(this.permissionGroups);
    }
  }

  ngOnInit() {
    this.selectedGroup = this.permissionGroupsForFilters[0];
    this.preparePermissions();
  }

  onSelectUserGroup(): void {
    this.selectGroup.emit(this.selectedGroup);
  }

  openAddGroupModal(): void {
    this.isFormSubmitted = false;
    this.preparePermissions();
    this.addGroupForm.reset({
      name: '',
      permissions: [],
    });

    this.modalRef = this.modalService.open(
      {
        headText: 'user.addPermissionGroup',
        bodyTemplate: this.addPermissionGroupTemplate,
        okButtonDisplayed: false,
        cancelButtonDisplayed: false,
        hideFooter: true,
      },
      {
        size: 'lg',
      }
    );

    this.modalRef.result.then(noop, noop);
  }

  openEditGroupModal(): void {
    this.modalRef = this.modalService.open(
      {
        headText: 'user.editPermissionGroup',
        bodyTemplate: this.editPermissionGroupTemplate,
        okButtonDisplayed: false,
        hideFooter: true,
      },
      {
        size: 'lg',
      }
    );
    this.modalRef.result.then(noop, noop);
  }

  addGroup(): void {
    this.filterSelectedPermissions();

    this.isFormSubmitted = true;
    if (this.addGroupForm.valid) {
      this.store.dispatch(
        baseUserActions.createGroupCrud({
          payload: { body: this.addGroupForm.value },
        })
      );
      this.modalRef.close();
    }
  }

  filterSelectedPermissions(): void {
    this.addGroupForm.controls.permissions.setValue(
      this.permissionsToSelect.filter((permission) => permission.isSelected).map((permission) => permission.id)
    );
  }

  cancelAddingGroup(): void {
    this.modalRef.close();
  }

  onPermissionSelect(event: boolean, permissionId: number): void {
    this.permissionsToSelect.forEach((permission) => {
      if (permission.id === permissionId) {
        permission.isSelected = event;
      }
    });
  }

  updatePermissionGroup(payload: { groupId: number; body: GroupUpdateRequest }): void {
    this.store.dispatch(baseUserActions.updateGroupCrud({ payload }));
  }

  private prepareGroupsForFilters(list: GroupListItemResponse[]): BaseFilterInterface[] {
    const groups: BaseFilterInterface[] = [
      { id: '', title: 'user.all' },
      { id: 'isAdmin', title: 'permission.isAdmin' },
    ];
    list?.map((item) => {
      groups.push({ id: item.id?.toString() as string, title: item.name as string });
    });
    return groups;
  }

  private preparePermissions(): void {
    this.permissionsToSelect = [...this.permissions].map((permission) => ({
      ...permission,
      isSelected: BASE_PERMISSIONS.FEATURE_PRESELECT_OFFERS === permission.key,
    }));
  }
}
