export enum BaseSigningStatusEnum {
  SIGNED = 'signed',
  UNSIGNED = 'unsigned',
  SIGNED_BY_CUSTOMER = 'signed_by_customer',
  SIGNED_BY_PSP = 'signed_by_psp',
}

export enum BaseSigningStatusBySide {
  SIGNED = 'signed',
  UNSIGNED = 'unsigned',
  IN_QUEUE = 'in_queue',
  ERROR = 'error',
}

export type BaseSigningStatusType = 'signed' | 'unsigned' | 'signed_by_customer' | 'signed_by_psp' | undefined;
