<div class="teaser">
  <div class="sidebar-content">
    <div class="d-flex flex-column">
      <span class="fw-bold lh-1 mb-3">{{ 'inquiry.created' | translate | uppercase }}</span>
      <div class="d-flex gap-2">
        <base-avatar
          [size]="avatarSize.medium"
          [image]="inquiry?.createdBy?.avatar"
        ></base-avatar>
        <div class="d-flex flex-column">
          <span class="fw-bold">{{ inquiry?.createdBy?.firstName + ' ' + inquiry?.createdBy?.lastName }}</span>
          <p>{{ inquiry?.customer?.name }}</p>
          <span>{{ inquiry?.createdAt | baseFormatDate: true }}</span>
        </div>
      </div>
    </div>
    <hr />

    <div class="d-flex flex-column">
      <span class="fw-bold lh-1 mb-3">{{ 'qualifications.singular' | translate | uppercase }}</span>
      <span class="main-qualification-text mb-2">{{
        inquiry?.mainQualification?.name +
          ' (' +
          ('externalEmployees.masterData.qualifications.primary' | translate) +
          ')'
      }}</span>
      <span
        class="alternative-qualification-text"
        *ngFor="let qualification of inquiry.qualifications"
        >{{ qualification.name }}</span
      >
    </div>
    <hr />

    <div
      class="d-flex flex-column"
      [ngClass]="{ 'was-validated': submitted }"
    >
      <div class="d-flex align-items-start">
        <label
          [ngClass]="{ required: isEditNote }"
          class="fw-bold lh-1 mb-3"
          >{{ 'jobDescription' | translate | uppercase }}</label
        >
        <div class="d-flex ms-5">
          <div
            class="d-flex"
            *ngIf="inquiryId"
          >
            <i
              class="fa-solid pointer {{ isEditNote ? 'fa-check me-2' : 'fa-pen small' }}"
              [ngbTooltip]="isEditNote ? ('action.save' | translate) : ('action.edit' | translate)"
              (click)="updateJobDescription()"
            ></i>
            <i
              *ngIf="isEditNote"
              class="pointer fa-solid fa-xmark"
              [ngbTooltip]="'action.cancel' | translate"
              (click)="cancelJobDescriptionUpdate()"
            ></i>
          </div>
        </div>
      </div>
      <base-textarea
        placeholder="placeholders.jobDescription"
        class="col mb-4"
        [previewClass]="isEditNote ? 'mb-0 p-0' : 'mb-0 px-0 pb-4'"
        [isRequired]="true"
        [formControl]="jobDescription"
        [isPreview]="!isEditNote"
        [isInvalid]="!!jobDescription.errors"
        [errorMessage]="jobDescription.errors | baseFieldValidation: { required: 'selectJobDescription' }"
      ></base-textarea>
    </div>

    <div class="d-flex flex-column">
      <span class="fw-bold lh-1 mb-3"
        >{{ 'customer.singular' | translate | uppercase }} | {{ 'location.singular' | translate | uppercase }}</span
      >
      <span class="text-dark-gray">{{ inquiry?.customer?.name }}</span>
      <span class="fw-bold">{{ inquiry?.customerLocation?.name }}</span>
    </div>

    <hr />

    <div class="d-flex flex-column">
      <span class="fw-bold lh-1 mb-3">{{ 'operations.location' | translate | uppercase }}</span>
      <span class="fw-bold">{{ inquiry?.customerDepartment?.name }}</span>
      <span baseTruncate>{{ inquiry?.customerDepartment?.businessAddress?.street | baseIsEmpty }}</span>
      <span baseTruncate
        >{{ inquiry?.customerDepartment?.businessAddress?.postCode | baseIsEmpty }}
        {{ inquiry?.customerDepartment?.businessAddress?.city | baseIsEmpty }}</span
      >
    </div>

    <hr />

    <div class="d-flex flex-column">
      <span class="fw-bold lh-1 mb-3">{{ 'inquiry.contactPerson.serviceProvider' | translate | uppercase }}</span>
      <span>{{ inquiry?.responsible?.firstName }} {{ inquiry?.responsible?.lastName }}</span>
      <span class="fw-bold">{{ inquiry?.responsible?.email }}</span>
      <span>{{ inquiry?.responsible?.phone }}</span>
    </div>

    <hr />

    <div class="d-flex flex-column">
      <span class="fw-bold lh-1 mb-3">{{ 'conditions' | translate | uppercase }}</span>
      <span class="text-justify"
        >{{ inquiry?.weeklyWorkingHours | baseIsEmpty }} {{ 'work.hoursPerWeek' | translate }}</span
      >
    </div>
    <hr />

    <div class="d-flex flex-column">
      <span class="fw-bold lh-1 mb-3">{{
        inquiry.unlimited
          ? ('readiness' | translate | uppercase)
          : ('externalEmployees.masterData.shiftReadiness.title' | baseBusinessKey | translate | uppercase)
      }}</span>
      <div
        class="d-flex align-items-center gap-2 mb-1"
        *ngFor="let shift of shiftTimes"
        [ngClass]="{
          'text-dark-gray text-decoration-line-through': inquiry.unlimited
            ? inquiry.dates[0]['shift' + shift.type.charAt(0).toUpperCase() + shift.type.slice(1)] === null
            : false
        }"
      >
        <i
          class="ms-2 fa-fw fa-regular {{ baseShiftIconsEnum[shift.type] }}"
          [title]="'shiftTimes.' + shift.type | baseBusinessKey | translate"
        ></i>
        <span>
          {{ 'externalEmployees.masterData.shiftReadiness.' + shift.type.toLowerCase() | baseBusinessKey | translate }}
          ({{ shift.startTime }} - {{ shift.endTime }})
        </span>
      </div>
      <div class="d-flex gap-1 ms-2 mt-3">
        <ng-container *ngFor="let weekday of weekdaysArray">
          <base-checkbox-weekdays
            [(ngModel)]="inquiryWeekdays[weekday]"
            [isDisabled]="true"
            [name]="'weekDays.' + weekday"
            [tooltip]="
              (inquiryWeekdays[weekday]
                ? ('tooltip.willingnessFor' | translate)
                : ('tooltip.noWillingnessFor' | translate)) + ('tooltip.weekDays.' + weekday | translate)
            "
          ></base-checkbox-weekdays>
        </ng-container>
      </div>
    </div>

    <hr />

    <div class="d-flex flex-column">
      <span class="fw-bold lh-1 mb-2">{{ 'costUnit' | translate | uppercase }}</span>
      <span class="text-justify">{{ inquiry?.costUnit | baseIsEmpty }}</span>
    </div>

    <hr />

    <div class="d-flex flex-column">
      <span class="fw-bold lh-1">{{ 'workEquipment' | translate | uppercase }}</span>
      <span class="fs-8 d-inline-block mb-2">{{ 'providedByUs' | translate }}</span>
      <span class="text-justify">{{ inquiry?.workEquipmentCustomer | baseIsEmpty }}</span>
    </div>

    <hr />

    <div class="d-flex flex-column">
      <span class="fw-bold lh-1">{{ 'workEquipment' | translate | uppercase }}</span>
      <span class="fs-8 d-inline-block mb-2">{{ 'providedByServiceProvider' | translate }}</span>
      <span class="text-justify">{{ inquiry?.workEquipmentPsp | baseIsEmpty }}</span>
    </div>

    <hr *ngIf="inquiry?.requirements?.length" />

    <div
      *ngIf="inquiry?.requirements?.length"
      class="d-flex flex-column"
    >
      <span class="fw-bold lh-1 mb-3">{{ 'work.conditions.alt' | translate | uppercase }}</span>
      <div
        class="row mb-2"
        *ngFor="let category of inquiry.requirements"
      >
        <span class="lh-2">{{ category.name }}</span>
        <div
          class="d-flex align-items-center gap-2 text-justify"
          *ngFor="let requirement of category.requirements"
        >
          <i class="fa-solid fa-check me-1 text-dark"></i>
          <span class="lh-2">{{ requirement.name }}</span>
        </div>
      </div>
    </div>

    <hr />

    <div class="d-flex flex-column">
      <span class="fw-bold lh-1 mb-2">{{ 'inquiry.remark' | translate | uppercase }}</span>
      <span class="text-justify">{{ inquiry?.remark | baseIsEmpty }}</span>
    </div>
  </div>
</div>
