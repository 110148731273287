<label
  *ngIf="label"
  [ngClass]="{ required: requiredLabel && !preview }"
  >{{ label | translate }}</label
>
<ng-container *ngIf="!preview">
  <ng-select
    [placeholder]="placeholder"
    [searchable]="true"
    [(ngModel)]="_value"
    (change)="change($event)"
    [required]="requiredLabel"
  >
    <ng-option
      *ngFor="let item of (countryList$ | async).data"
      [value]="item.code"
      >{{ item.name | translate }}</ng-option
    ></ng-select
  >
  <p class="invalid-feedback">
    {{ { required: true } | baseFieldValidation: { required: 'validation.country' } | translate }}
  </p>
</ng-container>
<base-form-field-preview
  *ngIf="preview"
  [value]="(countryList$ | async).data | baseCountryPreviewValue: _value"
></base-form-field-preview>
