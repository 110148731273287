import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { baseAuthEffects, baseAuthReducer } from '../../_store';
import { BaseAuthenticationService } from './base-auth.service';
import { BaseAuthWrapperComponent } from './_components/base-auth-wrapper/base-auth-wrapper.component';
import { BaseRegisterFormComponent } from './_components/base-register-form/base-register-form.component';
import { BaseLoginFormComponent } from './_components/base-login-form/base-login-form.component';
import { BaseResetPasswordComponent } from './_components/base-reset-password/base-reset-password.component';
import { BaseRegistrationVerificationComponent } from './_components/base-registration-verification/base-registration-verification.component';
import { BaseLogoutComponent } from './_components/base-logout/base-logout.component';
import { BaseSharedModule } from '../../shared/base-shared.module';
import { AppsSharedModule } from '../../apps-shared/apps-shared.module';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BaseImprintComponent } from './_components/base-imprint/base-imprint.component';
import { BaseAuthPageComponent } from './_components/base-auth-page/base-auth-page.component';
import { ImprintDeComponent } from './_components/base-imprint/imprint-de.component';
import { ImprintEnComponent } from './_components/base-imprint/imprint-en.component';

@NgModule({
  imports: [
    NgbModule,
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    BaseSharedModule,
    AppsSharedModule,
    StoreModule.forFeature('auth', baseAuthReducer.baseAuthReducer),
    EffectsModule.forFeature([baseAuthEffects.BaseAuthEffects]),
  ],
  declarations: [
    BaseAuthWrapperComponent,
    BaseRegisterFormComponent,
    BaseLoginFormComponent,
    BaseResetPasswordComponent,
    BaseRegistrationVerificationComponent,
    BaseLogoutComponent,
    BaseAuthPageComponent,
    BaseImprintComponent,
    ImprintDeComponent,
    ImprintEnComponent,
  ],
  exports: [
    BaseAuthWrapperComponent,
    BaseRegisterFormComponent,
    BaseLoginFormComponent,
    BaseResetPasswordComponent,
    BaseRegistrationVerificationComponent,
    BaseLogoutComponent,
    BaseAuthPageComponent,
    BaseImprintComponent,
  ],
  providers: [BaseAuthenticationService],
})
export class BaseAuthModule {}
