/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CreateCustomerLocationRequest } from '../models/create-customer-location-request';
import { CreatedResponseWithBody } from '../models/created-response-with-body';
import { CustomerLocationUpdateRequest } from '../models/customer-location-update-request';
import { EmptySuccessfulResponse } from '../models/empty-successful-response';
import { GeneralApiResponse } from '../models/general-api-response';
import { ListApiResponse } from '../models/list-api-response';
import { LocationListItemResponse } from '../models/location-list-item-response';
import { LocationResponse } from '../models/location-response';

@Injectable({
  providedIn: 'root',
})
export class CustomerLocationService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation postCustomerLocationActivate
   */
  static readonly PostCustomerLocationActivatePath = '/api/web/v1/customers/{customer}/locations/{location}/activation';

  /**
   * Activate customer location, accessible only for users with role 'ROLE_CUSTOMER_ADMIN'
   * or 'ROLE_CUSTOMER_LOCATION_ADMIN'.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postCustomerLocationActivate()` instead.
   *
   * This method doesn't expect any request body.
   */
  postCustomerLocationActivate$Response(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Location Id.
     */
    location: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EmptySuccessfulResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerLocationService.PostCustomerLocationActivatePath, 'post');
    if (params) {
      rb.path('customer', params.customer, {});
      rb.path('location', params.location, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmptySuccessfulResponse>;
      })
    );
  }

  /**
   * Activate customer location, accessible only for users with role 'ROLE_CUSTOMER_ADMIN'
   * or 'ROLE_CUSTOMER_LOCATION_ADMIN'.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postCustomerLocationActivate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  postCustomerLocationActivate(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Location Id.
     */
    location: number;
    context?: HttpContext
  }
): Observable<EmptySuccessfulResponse> {

    return this.postCustomerLocationActivate$Response(params).pipe(
      map((r: StrictHttpResponse<EmptySuccessfulResponse>) => r.body as EmptySuccessfulResponse)
    );
  }

  /**
   * Path part for operation deleteCustomerLocationDeactivate
   */
  static readonly DeleteCustomerLocationDeactivatePath = '/api/web/v1/customers/{customer}/locations/{location}/activation';

  /**
   * Deactivate customer location, accessible only for users with permissions 'ROLE_CUSTOMER_ADMIN'
   * or 'ROLE_CUSTOMER_LOCATION_ADMIN'.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteCustomerLocationDeactivate()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCustomerLocationDeactivate$Response(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Location Id.
     */
    location: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<EmptySuccessfulResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerLocationService.DeleteCustomerLocationDeactivatePath, 'delete');
    if (params) {
      rb.path('customer', params.customer, {});
      rb.path('location', params.location, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmptySuccessfulResponse>;
      })
    );
  }

  /**
   * Deactivate customer location, accessible only for users with permissions 'ROLE_CUSTOMER_ADMIN'
   * or 'ROLE_CUSTOMER_LOCATION_ADMIN'.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteCustomerLocationDeactivate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCustomerLocationDeactivate(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Location Id.
     */
    location: number;
    context?: HttpContext
  }
): Observable<EmptySuccessfulResponse> {

    return this.deleteCustomerLocationDeactivate$Response(params).pipe(
      map((r: StrictHttpResponse<EmptySuccessfulResponse>) => r.body as EmptySuccessfulResponse)
    );
  }

  /**
   * Path part for operation getCustomerLocationList
   */
  static readonly GetCustomerLocationListPath = '/api/web/v1/customers/{customer}/locations';

  /**
   * Get list of Customer Locations.
   *
   * **Accessible only for users with permissions 'FEATURE_VIEW_LOCATIONS',
   * 'ROLE_CUSTOMER_ADMIN', 'ROLE_CUSTOMER_USER', 'ROLE_CUSTOMER_LOCATION_ADMIN'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerLocationList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerLocationList$Response(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Filter active or inactive locations
     */
    active?: any;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ListApiResponse & {
'data'?: Array<LocationListItemResponse>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerLocationService.GetCustomerLocationListPath, 'get');
    if (params) {
      rb.path('customer', params.customer, {});
      rb.query('active', params.active, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListApiResponse & {
        'data'?: Array<LocationListItemResponse>;
        }>;
      })
    );
  }

  /**
   * Get list of Customer Locations.
   *
   * **Accessible only for users with permissions 'FEATURE_VIEW_LOCATIONS',
   * 'ROLE_CUSTOMER_ADMIN', 'ROLE_CUSTOMER_USER', 'ROLE_CUSTOMER_LOCATION_ADMIN'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCustomerLocationList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerLocationList(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Filter active or inactive locations
     */
    active?: any;
    context?: HttpContext
  }
): Observable<ListApiResponse & {
'data'?: Array<LocationListItemResponse>;
}> {

    return this.getCustomerLocationList$Response(params).pipe(
      map((r: StrictHttpResponse<ListApiResponse & {
'data'?: Array<LocationListItemResponse>;
}>) => r.body as ListApiResponse & {
'data'?: Array<LocationListItemResponse>;
})
    );
  }

  /**
   * Path part for operation postCustomerLocationCreate
   */
  static readonly PostCustomerLocationCreatePath = '/api/web/v1/customers/{customer}/locations';

  /**
   * Create customer location, accessible only for users with permission 'ROLE_CUSTOMER_ADMIN'.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postCustomerLocationCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postCustomerLocationCreate$Response(params: {

    /**
     * Customer Id.
     */
    customer: number;
    context?: HttpContext
    body: CreateCustomerLocationRequest
  }
): Observable<StrictHttpResponse<CreatedResponseWithBody>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerLocationService.PostCustomerLocationCreatePath, 'post');
    if (params) {
      rb.path('customer', params.customer, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CreatedResponseWithBody>;
      })
    );
  }

  /**
   * Create customer location, accessible only for users with permission 'ROLE_CUSTOMER_ADMIN'.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postCustomerLocationCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postCustomerLocationCreate(params: {

    /**
     * Customer Id.
     */
    customer: number;
    context?: HttpContext
    body: CreateCustomerLocationRequest
  }
): Observable<CreatedResponseWithBody> {

    return this.postCustomerLocationCreate$Response(params).pipe(
      map((r: StrictHttpResponse<CreatedResponseWithBody>) => r.body as CreatedResponseWithBody)
    );
  }

  /**
   * Path part for operation getCustomerLocationGet
   */
  static readonly GetCustomerLocationGetPath = '/api/web/v1/customers/{customer}/locations/{location}';

  /**
   * GET Customer Location.
   *
   * **Accessible only for users with permissions 'FEATURE_VIEW_LOCATIONS',
   * 'ROLE_CUSTOMER_ADMIN', 'ROLE_CUSTOMER_USER', 'ROLE_CUSTOMER_LOCATION_ADMIN'**
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerLocationGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerLocationGet$Response(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Location Id.
     */
    location: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<GeneralApiResponse & {
'data'?: LocationResponse;
}>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerLocationService.GetCustomerLocationGetPath, 'get');
    if (params) {
      rb.path('customer', params.customer, {});
      rb.path('location', params.location, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GeneralApiResponse & {
        'data'?: LocationResponse;
        }>;
      })
    );
  }

  /**
   * GET Customer Location.
   *
   * **Accessible only for users with permissions 'FEATURE_VIEW_LOCATIONS',
   * 'ROLE_CUSTOMER_ADMIN', 'ROLE_CUSTOMER_USER', 'ROLE_CUSTOMER_LOCATION_ADMIN'**
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCustomerLocationGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerLocationGet(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Location Id.
     */
    location: number;
    context?: HttpContext
  }
): Observable<GeneralApiResponse & {
'data'?: LocationResponse;
}> {

    return this.getCustomerLocationGet$Response(params).pipe(
      map((r: StrictHttpResponse<GeneralApiResponse & {
'data'?: LocationResponse;
}>) => r.body as GeneralApiResponse & {
'data'?: LocationResponse;
})
    );
  }

  /**
   * Path part for operation putCustomerLocationUpdate
   */
  static readonly PutCustomerLocationUpdatePath = '/api/web/v1/customers/{customer}/locations/{location}';

  /**
   * Update customer location, accessible only for users with permissions 'ROLE_CUSTOMER_ADMIN'
   * or 'ROLE_CUSTOMER_LOCATION_ADMIN'.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putCustomerLocationUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putCustomerLocationUpdate$Response(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Location Id.
     */
    location: number;
    context?: HttpContext
    body: CustomerLocationUpdateRequest
  }
): Observable<StrictHttpResponse<EmptySuccessfulResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerLocationService.PutCustomerLocationUpdatePath, 'put');
    if (params) {
      rb.path('customer', params.customer, {});
      rb.path('location', params.location, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmptySuccessfulResponse>;
      })
    );
  }

  /**
   * Update customer location, accessible only for users with permissions 'ROLE_CUSTOMER_ADMIN'
   * or 'ROLE_CUSTOMER_LOCATION_ADMIN'.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `putCustomerLocationUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  putCustomerLocationUpdate(params: {

    /**
     * Customer Id.
     */
    customer: number;

    /**
     * Location Id.
     */
    location: number;
    context?: HttpContext
    body: CustomerLocationUpdateRequest
  }
): Observable<EmptySuccessfulResponse> {

    return this.putCustomerLocationUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<EmptySuccessfulResponse>) => r.body as EmptySuccessfulResponse)
    );
  }

}
