import { Component, Input, OnInit } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NonNullableFormBuilder } from '@angular/forms';
import {
  baseAdministrationSelectors,
  baseAssignmentsActions,
  baseAssignmentsSelectors,
  BaseCoreState,
} from '../../../_store';
import { filter, map, takeUntil } from 'rxjs/operators';
import { CustomerResponse, PspResponse } from '../../../../api';
import { Store } from '@ngrx/store';
import { BaseRolesService } from '../../../core/services/base-roles.service';
import { Subject } from 'rxjs';
import { BaseButtonSize } from '../button/base-button.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'base-namirial-registration-modal',
  templateUrl: './base-namirial-registration-modal.component.html',
})
export class BaseNamirialRegistrationModalComponent implements OnInit {
  @Input() modalRef: NgbModalRef;
  @Input() hideSuccessMessage = false;

  isRegisterFormSubmitted = false;
  signFreeSignatureValue: number | string;
  voucherLinkMessage: string;
  registerForm = this.fb.group({ isConfirm: [false] });

  buttonSizes = BaseButtonSize;

  private destroy$ = new Subject();

  constructor(
    private fb: NonNullableFormBuilder,
    private store: Store<BaseCoreState>,
    private roleService: BaseRolesService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.store
      .select(baseAssignmentsSelectors.getDigitalSignVoucher)
      .pipe(
        takeUntil(this.destroy$),
        filter((state) => state.ok === true),
        map((state) => state.data)
      )
      .subscribe((voucherData) => {
        this.voucherLinkMessage = this.translateService.instant(
          'namirialLoginModal.registration.successRegisterMessage',
          {
            voucherLink: `<a class="voucher-link" target="_blank" href="${voucherData.url}">${voucherData.url}</a>`,
          }
        );
      });

    if (this.roleService.isPsp()) {
      this.store
        .select(baseAdministrationSelectors.getPspDataData)
        .pipe(
          takeUntil(this.destroy$),
          map((state) => state?.data as PspResponse)
        )
        .subscribe((result: PspResponse) => {
          this.signFreeSignatureValue = result?.signFreeSignatures || '';
        });
    } else {
      this.store
        .select(baseAdministrationSelectors.getCustomerDataData)
        .pipe(
          map((data) => data as CustomerResponse),
          takeUntil(this.destroy$)
        )
        .subscribe((result: CustomerResponse) => {
          this.signFreeSignatureValue = result?.signFreeSignatures || '';
        });
    }
  }

  submitRegisterForm(): void {
    this.isRegisterFormSubmitted = true;
    this.store.dispatch(
      baseAssignmentsActions.acceptDigitalSignConditions({ payload: { hideSuccessMessage: this.hideSuccessMessage } })
    );
    this.modalRef.close();
  }
}
