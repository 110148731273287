import produce from 'immer';
import { basePspPriceListActions } from '../actions';
import { Category, QualificationPricingListItemResponse } from '../../../api';

export interface BasePspPriceListState {
  categories: {
    data: Category[];
    ok: boolean | undefined;
  };
  qualifications: {
    data: QualificationPricingListItemResponse[];
    ok: boolean | undefined;
  };
}

export const INITIAL_PRICE_LIST_STATE: BasePspPriceListState = {
  categories: {
    data: [],
    ok: undefined,
  },
  qualifications: {
    data: [],
    ok: undefined,
  },
};

export function BasePspPriceListReducer(state: BasePspPriceListState = INITIAL_PRICE_LIST_STATE, action: any): any {
  return produce(state, (draftState) => {
    switch (action.type) {
      // Categories
      case basePspPriceListActions.loadCategories.type:
        draftState.categories.ok = undefined;
        return;

      case basePspPriceListActions.setCategories.type:
        draftState.categories.data = action.payload;
        return;

      case basePspPriceListActions.successCategories.type:
        draftState.categories.ok = true;
        return;

      case basePspPriceListActions.errorCategories.type:
        draftState.categories.ok = false;
        return;

      // Qualifications
      case basePspPriceListActions.loadQualifications.type:
        draftState.qualifications.ok = undefined;
        return;

      case basePspPriceListActions.setQualifications.type:
        draftState.qualifications.data = action.payload;
        return;

      case basePspPriceListActions.updateQualifications.type:
        draftState.qualifications.ok = undefined;
        return;

      case basePspPriceListActions.successQualifications.type:
        draftState.qualifications.ok = true;
        return;

      case basePspPriceListActions.errorQualifications.type:
        draftState.qualifications.ok = false;
        return;
    }
  });
}
