import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { BaseDashboardService } from './base-dashboard.service';
import {
  baseCustomerDashboardEffects,
  baseCustomerDashboardReducer,
  basePspDashboardEffects,
  basePspDashboardReducer,
} from '../../_store';
import { DashboardCardHeaderComponent } from './components/dashboard-card-header/dashboard-card-header.component';
import { CommonModule } from '@angular/common';
import { BaseSharedModule } from '../../shared/base-shared.module';
import { AppsSharedModule } from '../../apps-shared/apps-shared.module';
import { BasePspAssignmentsListGuard } from './_guards/base-psp-assignments-list.guard';
import { BaseCustomerDashboardDataGuard } from './_guards/base-customer-dashboard-data.guard';
import { BaseCustomerDashboardOffersGuard } from './_guards/base-customer-dashboard-offers.guard';
import { BaseCustomerDashboardInquiriesGuard } from './_guards/base-customer-dashboard-inquiries.guard';

@NgModule({
  imports: [
    NgbModule,
    CommonModule,
    BaseSharedModule,
    AppsSharedModule,
    StoreModule.forFeature('pspDashboard', basePspDashboardReducer.BasePspDashboardReducer),
    StoreModule.forFeature('customerDashboard', baseCustomerDashboardReducer.BaseCustomerDashboardReducer),
    EffectsModule.forFeature([
      basePspDashboardEffects.BasePspDashboardEffects,
      baseCustomerDashboardEffects.BaseCustomerDashboardEffects,
    ]),
  ],
  declarations: [DashboardCardHeaderComponent],
  exports: [DashboardCardHeaderComponent],
  providers: [
    BaseDashboardService,
    BaseCustomerDashboardDataGuard,
    BaseCustomerDashboardOffersGuard,
    BaseCustomerDashboardInquiriesGuard,
    BasePspAssignmentsListGuard,
  ],
})
export class BaseDashboardModule {}
