import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { concatMap, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { BaseRolesService } from '../../core/services/base-roles.service';
import { BaseTokenService } from '../../core/services/base-token.service';
import { BaseAuthenticationService } from '../../modules/auth/base-auth.service';
import { BaseToastAlertClasses } from '../../shared/enums/base-toast-alert-classes.enum';
import { baseAdministrationActions, baseAuthActions, baseFormsActions, baseSharedActions } from '../actions';
import { BaseCoreState } from '../reducers';
import { BaseLocalStorageService } from '../../core/services/base-local-storage.service';
import { BaseDeviceService } from '../../shared/services/base-device.service';
import { BASE_ROLES } from '../../core/constants/base-roles';
import { v4 as uuidv4 } from 'uuid';
import { baseAuthSelectors } from '../selectors';
import { BaseCustomizedUserParamEnum } from '../../shared/enums/base-customized-user-param.enum';

@Injectable()
export class BaseAuthEffects {
  refreshTokenTimer: any;
  activeTimers: any[] = [];

  setUserInfoFromToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(baseAuthActions.setUserInfoFromToken),
      mergeMap(() => {
        return [
          baseAuthActions.successUserInfo(),
          baseSharedActions.loadUserNotifications({ payload: { isRead: false } }),
        ];
      }),
      catchError((response) => of(baseAuthActions.errorUserInfo({ error: response })))
    )
  );

  loadToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(baseAuthActions.loadToken),
      switchMap((action) => {
        return this.authenticationService.login(action.payload).pipe(
          tap((response) => {
            this.tokenService.setTokens(response);
            const decodedToken = this.tokenService.decodeToken(response.token);
            this.rolesService.setRolesAndPermissions(decodedToken.roles, decodedToken.permissions);
            this.rolesService.setPermissions(decodedToken.permissions);
            this.store.dispatch(
              baseAuthActions.setFirstLoginIndicator({ payload: !this.localStorageService.get('login-id') })
            );
            if (
              !this.deviceService.isDesktop() &&
              !this.localStorageService.get('login-id') &&
              !this.rolesService.hasRole(BASE_ROLES.CUSTOMER_ADMIN)
            ) {
              // Modal should be open only on mobile device for the first time login if user does not have customer admin rights
              this.deviceService.openMobileDeviceInfoModal();
            }
            this.localStorageService.set('login-id', uuidv4());
            this.router.navigate(['/dashboard']);
          }),
          mergeMap((response) => {
            const userInfo = this.tokenService.decodeToken(response.token);
            return [
              baseAuthActions.setUserInfoFromToken({ payload: userInfo }),
              baseSharedActions.addSystemAlert({
                payload: {
                  body: 'auth.loginSuccess',
                  class: BaseToastAlertClasses.success,
                },
              }),
            ];
          }),
          catchError((response) => of(baseAuthActions.errorUserInfo({ error: response })))
        );
      })
    )
  );

  refreshUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(baseAuthActions.refreshUser),
      concatMap(() => {
        return this.authenticationService.refresh(this.tokenService.getRefreshToken()).pipe(
          tap((response) => {
            this.tokenService.setTokens(response);
            const decodedToken = this.tokenService.decodeToken(response.token);
            this.rolesService.setRolesAndPermissions(decodedToken.roles, decodedToken.permissions);
            this.rolesService.setPermissions(decodedToken.permissions);
            this.store.dispatch(
              baseAuthActions.setFirstLoginIndicator({ payload: !this.localStorageService.get('login-id') })
            );
          }),
          mergeMap((response) => {
            const decodedToken = this.tokenService.decodeToken(response.token);
            return [
              baseAuthActions.setUserInfoFromToken({ payload: decodedToken }),
              baseAuthActions.successRefreshUser(),
            ];
          }),
          catchError(() => {
            if (!this.tokenService.isRefreshTokenValid()) {
              this.store.dispatch(baseFormsActions.clearAllForms());
              baseAuthActions.logout();
            }
            return of(baseAuthActions.errorRefreshUser());
          })
        );
      })
    )
  );

  resetPasswordEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(baseAuthActions.resetPasswordEmail),
      switchMap((action) => {
        return this.authenticationService.resetPasswordEmail(action.payload).pipe(
          mergeMap(() => [
            baseAuthActions.successResetPasswordEmail(),
            baseSharedActions.addSystemAlert({
              payload: {
                body: 'auth.password.resetPassword.success',
                class: BaseToastAlertClasses.success,
              },
            }),
          ]),
          catchError(() => of(baseAuthActions.errorResetPasswordEmail()))
        );
      })
    )
  );

  resetPasswordConfirmation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(baseAuthActions.resetPasswordConfirmation),
      switchMap((action) => {
        return this.authenticationService.resetPassword(action.payload.body, action.payload.token).pipe(
          tap(() => {
            this.store.dispatch(baseAuthActions.navigateToLoginPage());
          }),
          mergeMap(() => [
            baseAuthActions.successResetPasswordConfirmation(),
            baseSharedActions.addSystemAlert({
              payload: {
                body: 'auth.password.resetPassword.success',
                class: BaseToastAlertClasses.success,
              },
            }),
          ]),
          catchError(() => of(baseAuthActions.errorResetPasswordConfirmation()))
        );
      })
    )
  );

  registerUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(baseAuthActions.registerUser),
      switchMap((action) => {
        return this.authenticationService.registerUser(action.payload).pipe(
          tap(() => {
            this.store.dispatch(baseAuthActions.navigateToLoginPage());
            this.store.dispatch(baseFormsActions.clearAccountRegisterForm());
          }),
          map(() => baseAuthActions.successRegisterUser()),
          catchError(() => of(baseAuthActions.errorRegisterUser()))
        );
      })
    )
  );

  registerPSP$ = createEffect(() =>
    this.actions$.pipe(
      ofType(baseAuthActions.registerPSP),
      switchMap((action) => {
        return this.authenticationService.registerPSP(action.payload).pipe(
          tap(() => {
            this.store.dispatch(baseAuthActions.navigateToLoginPage());
            this.store.dispatch(baseFormsActions.clearAccountRegisterForm());
          }),
          mergeMap(() => [
            baseAuthActions.successRegisterPSP(),
            baseSharedActions.addSystemAlert({
              payload: {
                body: 'auth.registerOrg.successMessage',
                class: BaseToastAlertClasses.success,
                duration: 60000,
              },
            }),
          ]),
          catchError(() => of(baseAuthActions.errorRegisterPSP()))
        );
      })
    )
  );

  registerMedicalCustomer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(baseAuthActions.registerCustomer),
      switchMap((action) => {
        return this.authenticationService.registerCustomer(action.payload).pipe(
          tap(() => {
            this.store.dispatch(baseAuthActions.navigateToLoginPage());
            this.store.dispatch(baseFormsActions.clearAccountRegisterForm());
          }),
          mergeMap(() => [
            baseAuthActions.successRegisterCustomer(),
            baseSharedActions.addSystemAlert({
              payload: {
                body: 'auth.registerOrg.successMessage',
                class: BaseToastAlertClasses.success,
                duration: 60000,
              },
            }),
          ]),
          catchError(() => of(baseAuthActions.errorRegisterCustomer()))
        );
      })
    )
  );

  registerBusinessCustomer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(baseAuthActions.registerBusinessCustomer),
      switchMap((action) => {
        return this.authenticationService.registerBusinessCustomer(action.payload).pipe(
          tap(() => {
            this.store.dispatch(baseAuthActions.navigateToLoginPage());
          }),
          mergeMap(() => [
            baseAuthActions.successRegisterBusinessCustomer(),
            baseSharedActions.addSystemAlert({
              payload: {
                body: 'auth.registerOrg.successMessage',
                class: BaseToastAlertClasses.success,
                duration: 60000,
              },
            }),
          ]),
          catchError(() => of(baseAuthActions.errorRegisterBusinessCustomer()))
        );
      })
    )
  );

  verifyRegistration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(baseAuthActions.verifyRegistration),
      switchMap((action) => {
        return this.authenticationService.verify(action.payload.id, action.payload.token, action.payload.type).pipe(
          tap(() => {
            this.store.dispatch(baseAuthActions.navigateToLoginPage());
          }),
          mergeMap(() => [
            baseAuthActions.verifyRegistrationSuccess(),
            baseSharedActions.addSystemAlert({
              payload: {
                body: 'auth.registered.success',
                class: BaseToastAlertClasses.success,
              },
            }),
          ]),
          catchError(() => {
            this.store.dispatch(baseAuthActions.navigateToLoginPage());
            return of(baseAuthActions.verifyRegistrationError());
          })
        );
      })
    )
  );

  logoutUser$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(baseAuthActions.logout),
        switchMap(() =>
          this.authenticationService.logout(this.tokenService.getRefreshToken()).pipe(
            tap((_) => {
              this.tokenService.deleteToken();
              this.rolesService.clearRolesAndPermissions();
              this.rolesService.clearPermissions();
              this.store.dispatch(baseAuthActions.navigateToLoginPage());
            }),
            tap((_) => {
              this.store.dispatch(baseAuthActions.setInitState());
              this.store.dispatch(baseSharedActions.setInitState());
              this.store.dispatch(baseAdministrationActions.setInitState());
              this.store.dispatch(baseFormsActions.clearAllForms());
              this.store.dispatch(baseSharedActions.setSidebarInitialOpening({ payload: true }));
            })
          )
        )
      ),
    { dispatch: false }
  );

  goToLoginPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(baseAuthActions.navigateToLoginPage),
        withLatestFrom(this.store.select(baseAuthSelectors.getCustomizedUserStatus).pipe(map((state) => !!state))),
        tap(([_, isCustomizedUser]) => {
          this.router.navigate(['/auth/login'], {
            queryParams: { network: isCustomizedUser ? BaseCustomizedUserParamEnum.emasters : undefined },
          });
        })
      ),
    { dispatch: false }
  );

  startRefreshTimer$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(baseAuthActions.successUserInfo),
        tap(() => {
          const decodedToken = this.tokenService.decodeToken(this.tokenService.getToken());
          const expires = new Date(decodedToken.exp * 1000);
          let timeout = expires.getTime() - Date.now() - 60 * 1000;

          // At some point (when computer foes to sleep mode) there was a case when there are few active timers exist
          // To avoid that every time refreshTokenTimer is defined it is added to activeTimers list
          // All previously created timers should be stopped before creating a new one
          // In such way we will have no more than one active timer at time
          this.activeTimers.forEach((timer, index) => {
            clearTimeout(timer);
          });
          this.activeTimers.splice(0, this.activeTimers.length);
          this.refreshTokenTimer = setTimeout(() => this.store.dispatch(baseAuthActions.refreshUser()), timeout);
          this.activeTimers.push(this.refreshTokenTimer);
        })
      ),
    { dispatch: false }
  );

  stopRefreshTimer$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(baseAuthActions.errorRefreshUser, baseAuthActions.logout),
        tap(() => {
          clearTimeout(this.refreshTokenTimer);
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private store: Store<BaseCoreState>,
    private authenticationService: BaseAuthenticationService,
    private tokenService: BaseTokenService,
    private rolesService: BaseRolesService,
    private router: Router,
    private localStorageService: BaseLocalStorageService,
    private deviceService: BaseDeviceService
  ) {}
}
