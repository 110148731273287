<div
  class="notifications-dropdown position-relative"
  (baseClickOutside)="close()"
>
  <div (click)="toggle()">
    <i
      class="fa-solid fa-bell notifications-icon"
      [ngbTooltip]="'notification.plural' | translate"
    ></i>
    <div
      *ngIf="totalUnreadNotifications > 0"
      class="notifications-dot"
    ></div>
  </div>

  <div
    class="notifications-dropdown-content px-4"
    *ngIf="isOpen"
    (click)="close()"
  >
    <ng-container *ngTemplateOutlet="menu"></ng-container>
  </div>
</div>

<ng-template #menu>
  <base-notifications-dropdown
    [totalUnreadNotifications]="totalUnreadNotifications"
    [unreadNotifications]="unreadNotifications"
    [modalRef]="modalRef"
  ></base-notifications-dropdown>
</ng-template>
