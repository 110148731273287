import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BaseRolesService } from '../../core/services/base-roles.service';
import { baseUserActions, baseSharedActions } from '../actions';
import { BaseCoreState } from '../reducers';
import { baseAuthSelectors } from '../selectors';
import { of, throwError } from 'rxjs';
import { catchError, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { BaseTokenInterface } from '../../shared/interfaces/base-token-service.interface';
import { BaseUsersService } from '../../modules/users/base-users.service';
import { BaseToastAlertClasses } from '../../shared/enums/base-toast-alert-classes.enum';
import { getUserInactiveFilter } from '../selectors/base-user.selectors';

@Injectable()
export class BaseUserEffects {
  loadAdminsList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseUserActions.loadAdminList),
      switchMap((action) => {
        const payload = { id: action.payload.id as string, role: 'admin', isPSP: this.roleService.isPsp() };
        return this.usersService.getUserList(payload).pipe(
          mergeMap((response: any) => [
            baseUserActions.setAdminList({ payload: response }),
            baseUserActions.successAdminList(),
          ]),
          catchError(() => of(baseUserActions.errorAdminList()))
        );
      })
    );
  });

  loadUsersList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseUserActions.loadUserList),
      switchMap((action) => {
        const payload = {
          id: action.payload.id as string,
          role: 'user',
          isPSP: this.roleService.isPsp(),
          search: action.payload.search,
        };
        return this.usersService.getUserList(payload).pipe(
          mergeMap((response: any) => [
            baseUserActions.setUserList({ payload: response }),
            baseUserActions.successUserList(),
          ]),
          catchError(() => of(baseUserActions.errorUserList()))
        );
      })
    );
  });

  loadAdminAndUsersList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseUserActions.loadAdminAndUserList),
      withLatestFrom(this.store.select(getUserInactiveFilter)),
      switchMap(([action, active]) => {
        const payload = {
          id: action.payload.id as string,
          isPSP: this.roleService.isPsp(),
          search: action.payload.search,
          active: active ? active : undefined,
        };

        return this.usersService.getUserList(payload).pipe(
          mergeMap((response: any) => [
            baseUserActions.setAdminAndUserList({ payload: response }),
            baseUserActions.successAdminAndUserList(),
          ]),
          catchError(() => of(baseUserActions.errorAdminAndUserList()))
        );
      })
    );
  });

  loadUserGroups$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseUserActions.loadUserGroups),
      switchMap((action) => {
        const source$ = this.roleService.isPsp()
          ? this.usersService.getPspUserGroups(action.payload)
          : this.roleService.isCustomer()
          ? this.usersService.getCustomerUserGroups(action.payload)
          : throwError(new Error());

        return source$.pipe(
          map((response) => (response?.length ? response : [])),
          mergeMap((response: any) => [
            baseUserActions.setUserGroups({ payload: response }),
            baseUserActions.successUserGroups(),
          ]),
          catchError(() => of(baseUserActions.errorUserGroups()))
        );
      })
    );
  });

  getGroup$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseUserActions.loadGroup),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((state) => state.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        const source$ = this.roleService.isPsp()
          ? this.usersService.getPspGroup(action.payload.groupId, user.organizationId)
          : this.usersService.getCustomerGroup(action.payload.groupId, user.organizationId);
        return source$.pipe(
          mergeMap((response) => [
            baseUserActions.setGroup({ payload: response.data }),
            baseUserActions.successGroup(),
          ]),
          catchError(() => of(baseUserActions.errorGroup))
        );
      })
    );
  });

  createGroupCrud$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseUserActions.createGroupCrud),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((state) => state.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        const source$ = this.roleService.isPsp()
          ? this.usersService.createPspGroup(user.organizationId, action.payload.body)
          : this.usersService.createCustomerGroup(user.organizationId, action.payload.body);
        return source$.pipe(
          mergeMap(() => [
            baseUserActions.successGroupCrud(),
            baseSharedActions.addSystemAlert({
              payload: {
                body: 'successMessage.createdPermissionGroup',
                class: BaseToastAlertClasses.success,
              },
            }),
            baseUserActions.loadUserGroups({ payload: user.organizationId }),
          ]),
          catchError(() => of(baseUserActions.errorGroupCrud()))
        );
      })
    );
  });

  updateGroupCrud$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseUserActions.updateGroupCrud),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((state) => state.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        const source$ = this.roleService.isPsp()
          ? this.usersService.updatePspGroup(action.payload.groupId, user.organizationId, action.payload.body)
          : this.usersService.updateCustomerGroup(action.payload.groupId, user.organizationId, action.payload.body);
        return source$.pipe(
          mergeMap(() => [
            baseUserActions.successGroupCrud(),
            baseSharedActions.addSystemAlert({
              payload: {
                body: 'successMessage.changesSaved',
                class: BaseToastAlertClasses.success,
              },
            }),
            baseUserActions.loadUserGroups({ payload: user.organizationId }),
            baseUserActions.loadUserList({ payload: { id: user.organizationId.toString() } }),
          ]),
          catchError(() => of(baseUserActions.errorGroupCrud()))
        );
      })
    );
  });

  deleteUsersGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(baseUserActions.deleteUsersGroup),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((user) => user.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        const source$ = this.roleService.isPsp()
          ? this.usersService.deletePspUsersGroup(user.organizationId, action.payload)
          : this.usersService.deleteCustomerUsersGroup(user.organizationId, action.payload);
        return source$.pipe(
          mergeMap((_) => [
            baseUserActions.loadUserGroups({ payload: (user as BaseTokenInterface).organizationId }),
            baseUserActions.deleteUsersGroupSuccessfully(),
          ])
        );
      })
    )
  );

  activateUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseUserActions.activateUser),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((state) => state.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        const source$ = this.roleService.isPsp()
          ? this.usersService.activatePSPUser({ user: action.payload, psp: user.organizationId })
          : this.usersService.activateCustomerUser({ user: action.payload, customer: user.organizationId });
        return source$.pipe(
          mergeMap(() =>
            action.loadCommonList
              ? [
                  baseUserActions.loadAdminAndUserList({
                    payload: { id: user.organizationId.toString() },
                  }),
                ]
              : [
                  baseUserActions.loadUserList({ payload: { id: user.organizationId.toString() } }),
                  baseUserActions.loadAdminList({ payload: { id: user.organizationId.toString() } }),
                ]
          )
        );
      })
    );
  });

  deactivateUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseUserActions.deactivateUser),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((state) => state.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        const source$ = this.roleService.isPsp()
          ? this.usersService.deactivatePSPUser({ user: action.payload, psp: user.organizationId })
          : this.usersService.deactivateCustomerUser({ user: action.payload, customer: user.organizationId });
        return source$.pipe(
          mergeMap(() =>
            action.loadCommonList
              ? [
                  baseUserActions.loadAdminAndUserList({
                    payload: { id: user.organizationId.toString() },
                  }),
                ]
              : [
                  baseUserActions.loadUserList({ payload: { id: user.organizationId.toString() } }),
                  baseUserActions.loadAdminList({ payload: { id: user.organizationId.toString() } }),
                ]
          )
        );
      })
    );
  });

  deleteInvitedUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(baseUserActions.deleteInvitedUser),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((user) => user.data as BaseTokenInterface))
      ),
      switchMap(([action, user]) => {
        const source$ = this.roleService.isPsp()
          ? this.usersService.deletePspUserInvitation({
              psp: user.organizationId,
              user: action.payload,
            })
          : this.usersService.deleteCustomerUserInvitation({
              customer: user.organizationId,
              user: action.payload,
            });
        return source$.pipe(
          mergeMap((_) =>
            action.fullList
              ? [
                  baseUserActions.successDeleteInvitedUser(),
                  baseUserActions.loadAdminAndUserList({ payload: { id: user.organizationId.toString() } }),
                ]
              : [
                  baseUserActions.successDeleteInvitedUser(),
                  baseUserActions.loadUserList({ payload: { id: user.organizationId.toString() } }),
                  baseUserActions.loadAdminList({ payload: { id: user.organizationId.toString() } }),
                ]
          ),
          catchError(() => of(baseUserActions.errorDeleteInvitedUser()))
        );
      })
    )
  );

  resendUserInvitation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(baseUserActions.resendUserInvitation),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((user) => user.data as BaseTokenInterface))
      ),
      switchMap(([action, loggedInUser]) => {
        const source$ = this.roleService.isPsp()
          ? this.usersService.resendPspUserInvitation({ user: action.payload.userId, psp: loggedInUser.organizationId })
          : this.usersService.resendCustomerUserInvitation({
              user: action.payload.userId,
              customer: loggedInUser.organizationId,
            });
        return source$.pipe(
          mergeMap((_) => [
            baseUserActions.resendUserInvitationSuccess(),
            baseSharedActions.addSystemAlert({
              payload: {
                body: 'notification.resendInvitation',
                bodyTranslateParams: { userName: action.payload.userName },
                class: BaseToastAlertClasses.success,
              },
            }),
            baseUserActions.loadAdminAndUserList({ payload: { id: loggedInUser.organizationId.toString() } }),
          ]),
          catchError(() => of(baseUserActions.resendUserInvitationError()))
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store<BaseCoreState>,
    private roleService: BaseRolesService,
    private usersService: BaseUsersService
  ) {}
}
