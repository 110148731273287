import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { AssignmentCustomerResponse, AssignmentPspResponse, ContractRequest, ContractResponse } from '../../../../api';
import { BaseSignContractOptions } from '../../../modules/assignments/_enums/base-sign-contract-options.enum';
import { baseAssignmentsActions, baseAssignmentsSelectors, BaseCoreState } from '../../../_store';

@Component({
  selector: 'base-sign-contracts-sign',
  templateUrl: './base-sign-contracts-sign.component.html',
})
export class BaseSignContractsSignComponent implements OnInit, OnDestroy {
  @Input() modalRef: NgbModalRef;
  @Input() credentials: ContractRequest;
  currentAssignment: AssignmentPspResponse | AssignmentCustomerResponse;
  validContract: ContractResponse;

  private destroy$ = new Subject();

  constructor(private store: Store<BaseCoreState>) {}

  ngOnInit(): void {
    this.store
      .select(baseAssignmentsSelectors.getAssignment)
      .pipe(
        takeUntil(this.destroy$),
        filter((assignmentState) => assignmentState.ok === true),
        map((assignmentState) => assignmentState.data)
      )
      .subscribe((assignment) => {
        this.currentAssignment = assignment;
        this.validContract = this.currentAssignment.contracts?.find((contract) => contract.valid) as ContractResponse;
      });
  }

  closeModal(): void {
    this.modalRef.dismiss();
  }

  submit() {
    this.store.dispatch(
      baseAssignmentsActions.signContract({
        payload: {
          assignment: this.currentAssignment.id,
          contract: this.validContract.id,
          signingType: BaseSignContractOptions.signDigital,
          body: this.credentials,
        },
      })
    );
    this.modalRef.close();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }
}
