<!-- Implementation for main functionality -->
<ng-container *ngIf="!preview">
  <label
    *ngIf="label"
    [ngClass]="{ required: requiredLabel }"
    >{{ label | translate }}</label
  >
  <ng-select
    *ngIf="!translateItems && !combineLabel && !customLayout && !markFavourites"
    [ngClass]="{ 'hide-selected': !showSelected, searchable: searchable }"
    [items]="itemsBuffer"
    [clearable]="false"
    [virtualScroll]="virtualScroll"
    [typeahead]="searchSubject$"
    [bindLabel]="bindOptionLabel"
    [bindValue]="bindOptionValue"
    [placeholder]="placeholder | translate"
    [multiple]="multiple"
    [searchable]="searchable"
    [closeOnSelect]="!multiple"
    [(ngModel)]="_value"
    [notFoundText]="requiredAnother[1] ? (requiredAnother[0] | translate) : ('noEntriesFound' | translate)"
    [compareWith]="compareFn"
    [dropdownPosition]="placement"
    (change)="change($event)"
    [required]="requiredLabel"
    [hideSelected]="!multiple"
    [markFirst]="false"
    [tabIndex]="tabIndex"
    (scroll)="onScroll($event?.end)"
    (scrollToEnd)="fetchMoreItems(selectVariant1.searchTerm ? selectVariant1.searchTerm : '')"
    #selectVariant1
  >
  </ng-select>

  <ng-select
    *ngIf="translateItems || combineLabel || customLayout || markFavourites"
    [ngClass]="{
      'hide-selected': !showSelected,
      'show-placeholder-constantly': !showSelected && placeholder,
      searchable: searchable
    }"
    [clearable]="false"
    [virtualScroll]="virtualScroll"
    [typeahead]="searchSubject$"
    [placeholder]="placeholder | translate"
    [multiple]="multiple"
    [searchable]="searchable"
    [closeOnSelect]="!multiple"
    [(ngModel)]="_value"
    [notFoundText]="requiredAnother[1] ? (requiredAnother[0] | translate) : ('noEntriesFound' | translate)"
    [compareWith]="compareFn"
    [dropdownPosition]="placement"
    (change)="change($event)"
    [required]="requiredLabel"
    [hideSelected]="!multiple"
    [markFirst]="false"
    [tabIndex]="tabIndex"
    (scroll)="onScroll($event?.end)"
    (scrollToEnd)="fetchMoreItems(selectVariant2.searchTerm ? selectVariant2.searchTerm : '')"
    #selectVariant2
  >
    <ng-option
      *ngFor="let item of itemsBuffer; trackBy: trackByFn"
      [value]="item"
    >
      <ng-container *ngIf="!customLayout">
        <ng-container *ngIf="!markFavourites">
          <span *ngIf="!combineLabel && item[bindOptionLabel]">{{ item[bindOptionLabel] | translate }}</span>
          <span *ngIf="combineLabel">{{ item[combineLabel[0]] + ' ' + item[combineLabel[1]] }}</span>
        </ng-container>
        <div
          *ngIf="markFavourites && item[bindOptionLabel]"
          class="d-flex flex-nowrap gap-2 align-items-center"
        >
          <i
            *ngIf="!!item['isFavorite']"
            class="fa-heart pointer fa-solid text-pink"
          ></i>
          <span>{{ item[bindOptionLabel] | translate }}</span>
        </div>
      </ng-container>

      <base-dropdown-custom-label
        *ngIf="customLayout"
        [item]="item"
        [isAssignmentView]="isAssignmentView"
        [bindOptionLabel]="bindOptionLabel"
        [bindOptionSubLabel]="bindOptionSubLabel"
      ></base-dropdown-custom-label>
    </ng-option>
  </ng-select>
  <p
    class="invalid-feedback"
    *ngIf="!isValidationMessageHidden"
  >
    {{ { required: true } | baseFieldValidation: { required: baseFieldValidationArgs } | translate }}
  </p>
</ng-container>

<!-- Form field preview implementation -->
<ng-container *ngIf="preview">
  <label *ngIf="label">{{ label | translate }}</label>
  <base-form-field-preview
    [value]="
      translateItems ? (_value[bindOptionLabel] | translate) : _value[bindOptionLabel] || (placeholder | translate)
    "
  ></base-form-field-preview>
</ng-container>
