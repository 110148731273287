<div class="d-flex justify-content-between flex-wrap">
  <h3>{{ 'user.plural' | translate }}</h3>
  <div class="d-flex justify-content-end gap-3 flex-wrap">
    <base-button
      [size]="buttonSizes.md"
      [iconSize]="buttonSizes.md"
      (btnClick)="filterInactiveUsers()"
      color="secondary"
      icon="fa-solid fa-filter"
      [label]="filterActiveUsers ? 'action.showInactive' : 'action.filterInactive'"
    ></base-button>
    <base-button
      [size]="buttonSizes.md"
      [iconSize]="buttonSizes.md"
      (btnClick)="openInviteUserModal()"
      color="primary"
      icon="fa-solid fa-plus fw-bold"
      label="user.invite"
    ></base-button>
  </div>
</div>
<hr class="m-0" />
<base-permission-groups
  [permissionGroups]="permissionGroups"
  [permissions]="permissions"
  (selectGroup)="selectGroup($event)"
></base-permission-groups>

<div class="listview">
  <div class="listview-header my-2">
    <div class="row">
      <div
        class="col-3"
        baseTruncate
      >
        {{ 'name' | translate }}
      </div>
      <div
        class="col-2"
        baseTruncate
      >
        {{ 'email' | translate }}
      </div>
      <div
        class="col-2"
        baseTruncate
      >
        {{ 'permission.group' | translate }}
      </div>
      <div
        class="col-2"
        baseTruncate
      >
        {{ 'position' | translate }}
      </div>
      <div
        class="col-3"
        baseTruncate
      >
        {{ 'state' | translate }}
      </div>
    </div>
  </div>
  <base-user-list-item
    *ngFor="let user of filteredUserList"
    [user]="user"
    [loggedInUserId]="loggedInUser.id"
    [groups]="permissionGroups"
  ></base-user-list-item>
  <base-empty-list-row
    *ngIf="userListLoaded && !filteredUserList?.length"
    [backgroundSecondary]="true"
    message="user.emptyList"
  ></base-empty-list-row>
</div>

<ng-template #inviteUserModalTemplate>
  <base-invite-user-form
    [form]="inviteUserForm"
    [modalRef]="modalRef"
    [isPSP]="isPSP"
    [isFormSubmitted]="isInviteUserFormSubmitted"
    [groups]="permissionGroups"
    [pspBranches]="pspBranches"
    [customerDepartments]="customerDepartments"
    (inviteUser)="confirmUserInvitation()"
  ></base-invite-user-form>
</ng-template>
