import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MainFormContactInterface } from '../../../_interfaces/base-administration.interface';
import { BaseRolesService } from '../../../../../core/services/base-roles.service';

@Component({
  selector: 'base-general-info-form',
  templateUrl: './base-general-info-form.component.html',
  styleUrls: ['./base-general-info-form.component.scss'],
})
export class BaseGeneralInfoFormComponent implements OnInit {
  @Input() form: FormGroup<MainFormContactInterface>;
  @Input() submitted = false;
  @Input() isEditMode = false;
  @Input() hideTitle = false;

  hasCollectiveAgreementField = false;

  constructor(private rolesService: BaseRolesService) {}

  ngOnInit() {
    this.hasCollectiveAgreementField = this.rolesService.isPsp();
  }
}
