<button
  [type]="type"
  [ngClass]="classes"
  (click)="clickHandler($event)"
  [tabindex]="tabindex"
  [disabled]="disabled"
  [ngbTooltip]="tooltip | translate"
>
  <div
    *ngIf="frontBadge"
    class="btn-badge"
    [ngClass]="badgeClasses"
  >
    <span>{{ frontBadge }}</span>
  </div>
  <i
    [ngClass]="iconClasses"
    *ngIf="icon"
  ></i>
  <span class="text-nowrap">{{ label | translate }}</span>
  <ng-content></ng-content>
</button>
