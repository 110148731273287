import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';

export const ENVIRONMENT = new InjectionToken<{ [key: string]: any }>('environment');

@Injectable({
  providedIn: 'root',
})
export class BaseEnvironmentService {
  private readonly environment: any;

  // We need @Optional to be able start app without providing environment file
  constructor(@Optional() @Inject(ENVIRONMENT) environment: any) {
    this.environment = environment !== null ? environment : {};
  }

  /**
   * Get value from "global" environment.
   */
  getValue(key: string, defaultValue?: any): any {
    if (defaultValue) {
      return this.environment.global[key] || defaultValue;
    } else {
      return this.environment.global[key];
    }
  }

  /**
   * Get local variable.
   */
  getLocalValue(key: string, defaultValue?: any): any {
    return this.environment[key] || defaultValue;
  }
}
