import { Directive, ElementRef, Output, EventEmitter, HostListener } from '@angular/core';

@Directive({
  selector: '[baseClickOutside]',
})
export class BaseClickOutsideDirective {
  constructor(private elementRef: ElementRef) {}

  @Output() baseClickOutside: EventEmitter<any> = new EventEmitter();

  @HostListener('document:click', ['$event.target']) clickOut(targetElement: HTMLElement): void {
    if (!this.elementRef.nativeElement.contains(targetElement)) {
      this.baseClickOutside.emit(null);
    }
  }
}
