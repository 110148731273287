import { createAction, props } from '@ngrx/store';
import { Category, PriceRequest, QualificationPricingListItemResponse } from '../../../api';
import { BaseApplicationTagType } from '../../shared/enums/base-application.enum';

// Price list categories
export const loadCategories = createAction(
  '[Price List] Load categories',
  props<{ payload: { tag?: BaseApplicationTagType } }>()
);
export const setCategories = createAction('[Price List] Set categories', props<{ payload: Category[] | undefined }>());
export const successCategories = createAction('[Price List] Success categories');
export const errorCategories = createAction('[Price List] Error categories');

// Price list qualifications
export const loadQualifications = createAction(
  '[Price List] Load Qualification',
  props<{ payload: { tag?: BaseApplicationTagType } }>()
);
export const setQualifications = createAction(
  '[Price List] Set Qualification',
  props<{ payload: QualificationPricingListItemResponse[] | undefined }>()
);
export const updateQualifications = createAction(
  '[Price List] Update Qualification',
  props<{ payload: { prices: PriceRequest[]; tag?: BaseApplicationTagType } }>()
);
export const successQualifications = createAction('[Price List] Success Qualification');
export const errorQualifications = createAction('[Price List] Error Qualification');
