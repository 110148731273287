import { Pipe, PipeTransform } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

@Pipe({
  name: 'baseFieldValidation',
})
export class BaseFieldValidationPipe implements PipeTransform {
  private errors: { [key: string]: string } = {
    required: 'validation.required',
    requiredTrue: 'validation.requiredTrue',
    passwordInvalid: 'validation.passwordInvalid',
    emailInvalid: 'validation.emailInvalid',
    emailRequired: 'validation.emailRequired',
    isMatching: 'validation.isMatching',
    minlength: 'validation.minLength',
    maxlength: 'validation.maxLength',
    numberTime: 'validation.numberTime',
    min: 'validation.min',
    pattern: 'validation.pattern',
    datesInvalid: 'validation.endDateInvalid',
    dateFormatInvalid: 'validation.wrongDateFormat',
    dateRangeInvalid: 'validation.dateRangeInvalid',
    countryUnsupported: 'validation.ibanCountryUnsupported',
    codeLengthInvalid: 'validation.ibanCodeLengthInvalid',
    ratingInvalid: 'validation.rating',
    patternInvalid: 'validation.ibanPatternInvalid',
    name: 'validation.name',
    gender: 'validation.gender',
    title: 'validation.title',
    firstName: 'validation.firstName',
    lastName: 'validation.lastName',
    email: 'validation.email',
    oldPassword: 'validation.oldPassword',
    password: 'validation.password',
    passwordInvalidLength: 'validation.passwordInvalidLength',
    passwordInvalidUpperCaseLetter: 'validation.passwordInvalidUpperCaseLetter',
    passwordInvalidLowerCaseLetter: 'validation.passwordInvalidLowerCaseLetter',
    passwordInvalidNumber: 'validation.passwordInvalidNumber',
    passwordInvalidSpecialCharacter: 'validation.passwordInvalidSpecialCharacter',
    confirmPasswordRequired: 'validation.confirmPasswordRequired',
    confirmPasswordInvalid: 'validation.confirmPasswordInvalid',
    yourEmail: 'validation.yourEmail',
    yourPassword: 'validation.yourPassword',
    yourFirstName: 'validation.yourFirstName',
    yourLastName: 'validation.yourLastName',
    fileSizeTooBig: 'validation.fileSizeTooBig',
    fileSizeTooBigWithParams: 'validation.fileSizeTooBigWithParams',
    fileTypeInvalid: 'validation.fileTypeInvalid',
    selectDepartment: 'validation.selectDepartment',
    selectResponsible: 'validation.selectResponsible',
    selectPrimaryJob: 'validation.selectPrimaryJob',
    selectJobDescription: 'validation.selectJobDescription',
    amountMinOne: 'validation.amountMinOne',
    file: 'validation.file',
    fileCategory: 'validation.fileCategory',
    pleaseAccept: 'validation.pleaseAccept',
    pleaseAcceptPrivacyPolicy: 'validation.pleaseAcceptPrivacyPolicy',
    phoneNumberInvalid: 'validation.phoneNumber',
    bankNameRequired: 'validation.bankNameRequired',
    ibanRequired: 'validation.ibanRequired',
    bicRequired: 'validation.bicRequired',
    permissionGroup: 'validation.permissionGroup',
    responsible: 'validation.responsible',
    branchRequired: 'validation.branchRequired',
    websiteInvalid: 'validation.websiteInvalid',
    timeOverlapping: 'validation.timeOverlapping',
    breaksOutsideAPeriod: 'validation.breaksOutsideAPeriod',
    timeIntervalIsInvalid: 'validation.timeIntervalIsInvalid',
    startTimeRequired: 'validation.startTimeRequired',
    endTimeRequired: 'validation.endTimeRequired',
    rejectReasonRequired: 'validation.rejectReasonRequired',
    partnerCodeInvalid: 'validation.partnerCodeInvalid',
    shiftRequired: 'validation.shiftRequired',
    breakTimeRequired: 'validation.breakTimeRequired',
  };

  transform(errors: ValidationErrors | null, args?: Record<string, string>): string {
    if (!errors) {
      return '';
    }

    return (
      errors &&
      Object.entries(errors)
        .filter((entry: [string, boolean]) => entry[1])
        .map((entry: [string, boolean]) =>
          args ? this.errors[args[entry[0]]] || this.errors[entry[0]] : this.errors[entry[0]]
        )[0]
    );
  }
}
