import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BaseModalService } from './base-modal.service';

@Injectable()
export class BaseUnsavedChangesService {
  constructor(private modalService: BaseModalService) {}
  confirmed$: BehaviorSubject<boolean | null> = new BehaviorSubject<boolean | null>(null);
  askForConfirmation$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  openConfirmation(): void {
    let modalRef: NgbModalRef;

    this.confirmed$.next(null);
    this.askForConfirmation$.next(true);

    modalRef = this.modalService.open({
      bodyText: 'unsavedData.message',
      headText: 'unsavedData.headText',
      okText: 'action.okProceed',
      cancelText: 'action.cancel',
    });

    modalRef.result.then(
      () => {
        this.confirm();
      },
      () => {
        this.cancel();
      }
    );
  }

  closeConfirmation(): void {
    this.confirmed$.next(null);
    this.askForConfirmation$.next(false);
  }

  confirm(): void {
    this.confirmed$.next(true);
    this.askForConfirmation$.next(false);
  }

  cancel(): void {
    this.confirmed$.next(false);
    this.askForConfirmation$.next(false);
  }
}
