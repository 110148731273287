import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { basePspBranchActions, baseAuthSelectors, basePspBranchSelectors, BaseCoreState } from '../../../_store';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { BaseTokenInterface } from '../../../shared/interfaces/base-token-service.interface';

@Injectable()
export class BasePspBranchListGuard {
  constructor(private store: Store<BaseCoreState>) {}

  canActivate(): Observable<boolean> {
    this.store
      .select(baseAuthSelectors.getUserInfo)
      .pipe(
        map((state) => state.data),
        take(1)
      )
      .subscribe((user) => {
        this.store.dispatch(
          basePspBranchActions.loadPspBranchList({
            payload: { orgId: (user as BaseTokenInterface).organizationId },
          })
        );
      });

    return this.store.select(basePspBranchSelectors.getPspUserList).pipe(
      filter((state) => state.ok !== undefined),
      map((state) => state.ok as boolean)
    );
  }
}
