<div
  class="dashboard-card-header"
  [ngClass]="{ 'mb-0 pb-0 border-bottom-0': !showBorder }"
>
  <base-amount-circle
    *ngIf="amount !== null"
    [amount]="amount"
    class="me-2"
  ></base-amount-circle>
  <h4 class="headline-20 mb-0">
    {{ header | translate }}
  </h4>

  <i
    class="fa-solid fa-fw ms-auto text-dark-gray fa-1-5x pointer"
    [ngClass]="icon"
    [ngbTooltip]="'action.refresh' | translate"
    *ngIf="!buttonLabel && icon"
    (click)="buttonClick.emit()"
  ></i>
  <base-button
    color="secondary"
    class="ms-auto"
    *ngIf="buttonLabel && isButtonAllowed"
    [label]="buttonLabel"
    (btnClick)="link ? goToLinkUrl() : buttonClick.emit()"
  >
  </base-button>
</div>
