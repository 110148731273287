import { Component, ElementRef, forwardRef, Input, ViewChild, OnChanges, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseFileTypes } from '../../constants/base-file-types';

@Component({
  selector: 'base-document-upload',
  templateUrl: './base-document-upload.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BaseDocumentUploadComponent),
      multi: true,
    },
  ],
})
export class BaseDocumentUploadComponent implements ControlValueAccessor {
  @Input() title = '';
  @Input() showMeta = true;
  @Input() multipleUpload = false;
  @Input() isInvalid = false;
  @Input() errorMessage: string | null = null;
  @Input() tabindex: number | null = null;
  @Input() isShowUploadButton = false;
  @Input() hideDragNDropArea = false;
  @ViewChild('file') file: ElementRef;
  value: any = null;
  acceptedFileTypes = BaseFileTypes;

  private onChange = (_: any) => {};
  private onTouched = () => {};

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => {}): void {
    this.onTouched = fn;
  }

  writeValue(outsideValue: any): void {
    this.value = outsideValue;
  }

  fileChanged(fileList: FileList | null): void {
    if (fileList === null) {
      return;
    }

    if (fileList[fileList.length - 1]) {
      if (this.multipleUpload) {
        this.value = (this.value || []).concat(fileList[fileList.length - 1]);
      } else {
        this.value = fileList[0];
      }
      this.onChange(this.value);
      this.file.nativeElement.value = null;
    }
  }

  clear(index?: number): void {
    if (Array.isArray(this.value) && typeof index === 'number') {
      this.value.splice(index, 1);
    } else if (this.value) {
      this.file.nativeElement.value = null;
      this.value = null;
    }
    this.onChange(this.value);
  }

  onDrop($event: DragEvent) {
    $event.preventDefault();
    $event.stopPropagation();

    if ($event.dataTransfer) {
      this.fileChanged($event.dataTransfer.files);
    }
  }

  downloadDocument(index?: number): void {
    if (Array.isArray(this.value) && typeof index === 'number') {
      window.open(URL.createObjectURL(this.value[index]), '_blank');
    } else if (this.value) {
      window.open(URL.createObjectURL(this.value), '_blank');
    }
  }

  viewDocument() {
    window.open(URL.createObjectURL(this.value), '_blank');
  }

  addFileManually(event: Event) {
    const element = event.currentTarget as HTMLInputElement;
    const fileList: FileList | null = element.files;
    if (fileList) {
      this.fileChanged(fileList);
    }
  }
}
