export enum BaseAbsenceTypeEnum {
  all = 'all',
  holiday = 'holiday',
  illness = 'illness',
  offline = 'offline',
  other = 'other',
  freeShift = 'freeShift',
  timeOffCompensation = 'timeOffCompensation',
  unexcused = 'unexcusedAbsence',
}

export enum BaseAbsencePayloadTypeEnum {
  all = 'all',
  holiday = 'holiday',
  illness = 'illness',
  offline = 'offline',
  other = 'other',
  freeShift = 'free_shift',
  timeOffCompensation = 'time_off_compensation',
  unexcusedAbsence = 'unexcused_absence',
}
