import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { BaseCandidateResponsibleUsersGuard } from './_guards/base-candidate-responsible-users.guard';
import { baseCandidatesEffects, baseCandidatesReducer } from '../../_store';
import { BaseCandidatesService } from './base-candidates.service';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature('candidates', baseCandidatesReducer.BaseCandidatesReducer),
    EffectsModule.forFeature([baseCandidatesEffects.BaseCandidatesEffects]),
    CommonModule,
  ],
  providers: [BaseCandidatesService, BaseCandidatesService, BaseCandidateResponsibleUsersGuard],
})
export class BaseCandidatesModule {}
