import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({ name: 'baseHoursToDays' })
export class BaseHoursToDaysPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(value: number | undefined): string {
    if (value === null || value === undefined) {
      return '';
    }

    const hours = value % 24;
    const days = (value - hours) / 24;

    const daysPart =
      days === 0 ? '' : `${days} ${this.translateService.instant(days > 1 ? 'days.plural' : 'days.singular')}`;
    const hoursPart =
      hours === 0 ? '' : `${hours} ${this.translateService.instant(hours > 1 ? 'hours.plural' : 'hours.singular')}`;

    return `${daysPart} ${hoursPart}`;
  }
}
