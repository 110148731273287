import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CustomerService, DashboardService } from '../../../api';
import { of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { BaseTokenInterface } from '../../shared/interfaces/base-token-service.interface';
import { baseCustomerDashboardActions } from '../actions';
import { BaseCoreState } from '../reducers';
import { baseAuthSelectors } from '../selectors';

@Injectable()
export class BaseCustomerDashboardEffects {
  loadCustomerDashboardData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseCustomerDashboardActions.loadCustomerDashboardData),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((state) => state.data as BaseTokenInterface))
      ),
      switchMap(([_action, user]) => {
        return this.customerService.getCustomerDashboardGet({ customer: user.organizationId }).pipe(
          map((response) => {
            return baseCustomerDashboardActions.setCustomerDashboardData({ payload: response?.data });
          }),
          catchError(() => of(baseCustomerDashboardActions.errorCustomerDashboard()))
        );
      })
    );
  });

  loadCustomerOfferData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(baseCustomerDashboardActions.loadCustomerOfferData),
      withLatestFrom(
        this.store.select(baseAuthSelectors.getUserInfo).pipe(map((state) => state.data as BaseTokenInterface))
      ),
      switchMap(([_action, user]) => {
        return this.dashboardService.getCustomerOffersList({ customer: user.organizationId, page: 1, size: 5 }).pipe(
          map((response) => {
            return baseCustomerDashboardActions.setCustomerOfferData({ payload: response?.data });
          }),
          catchError(() => of(baseCustomerDashboardActions.errorCustomerDashboard()))
        );
      })
    );
  });

  constructor(
    private actions$: Actions,
    private store: Store<BaseCoreState>,
    private customerService: CustomerService,
    private dashboardService: DashboardService
  ) {}
}
