<image-cropper
  [imageFile]="imageFile"
  [aspectRatio]="aspectRatio"
  [roundCropper]="roundCropper"
  [maintainAspectRatio]="maintainAspectRatio"
  [onlyScaleDown]="true"
  [resizeToWidth]="800"
  [resizeToHeight]="800"
  (imageCropped)="imageCropped.emit($event)"
></image-cropper>
