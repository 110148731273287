<!-- Digital Sign start -->
<div
  *ngIf="
    validContract.type === baseContractSigningTypeEnum.DIGITAL ||
    (!validContract.type && validContract.signingStatus === baseSigningStatusEnum.UNSIGNED && showSignDigital)
  "
  class="add-sign-item"
>
  <ng-container
    [ngTemplateOutlet]="signOptionRadio"
    [ngTemplateOutletContext]="{ value: signOptions.signDigital }"
  ></ng-container>
  <i class="fas fa-fw fa-laptop fa-3x me-4"></i>
  <div class="meta">
    <h4>{{ 'operations.sign.docusign.title' | translate }}</h4>
    <p>{{ 'operations.sign.docusign.descriptionNew' | translate }}</p>
  </div>
</div>
<!-- Digital Sign end -->

<!-- Sign by Concretisation start -->
<div
  *ngIf="
    isPSP &&
    validContract.signingStatus === baseSigningStatusEnum.UNSIGNED &&
    !!currentAssignment.psp?.signByConcretization &&
    validContract.type !== baseContractSigningTypeEnum.EXTERNAL &&
    validContract.type !== baseContractSigningTypeEnum.DIGITAL
  "
  class="add-sign-item"
>
  <ng-container
    [ngTemplateOutlet]="signOptionRadio"
    [ngTemplateOutletContext]="{ value: signOptions.concretization }"
  ></ng-container>
  <i class="fas fa-fw fa-envelope-open-text fa-3x me-4"></i>
  <div class="meta">
    <h4>{{ 'operations.sign.specification.title' | translate }}</h4>
    <p>{{ 'operations.sign.specification.description' | translate }}</p>
  </div>
</div>
<!-- Sign by Concretisation end -->

<!-- Sign Externally start -->
<div
  *ngIf="
    validContract.type === baseContractSigningTypeEnum.EXTERNAL ||
    (!validContract.type && validContract.signingStatus === baseSigningStatusEnum.UNSIGNED)
  "
  class="add-sign-item"
>
  <ng-container
    [ngTemplateOutlet]="signOptionRadio"
    [ngTemplateOutletContext]="{ value: signOptions.signExternal }"
  ></ng-container>
  <i class="fas fa-fw fa-file-pdf fa-3x me-4"></i>
  <div class="meta">
    <h4>{{ 'operations.sign.byhand.title' | translate }}</h4>
    <p>{{ 'operations.sign.byhand.descriptionNew' | translate }}</p>
  </div>
</div>
<!-- Sign Externally end -->

<div class="d-flex my-5 justify-content-between">
  <base-button
    [size]="buttonSizes.md"
    color="secondary"
    label="action.cancel"
    class="me-4"
    (btnClick)="modalRef.dismiss()"
  ></base-button>
  <base-button
    [size]="buttonSizes.md"
    [disabled]="!signingType"
    color="primary"
    label="action.confirm"
    (btnClick)="modalRef.close()"
  ></base-button>
</div>

<ng-template
  #signOptionRadio
  let-value="value"
  let-disabled="disabled"
>
  <base-radiobutton
    name="signType"
    [disabled]="disabled"
    [(ngModel)]="signingType"
    [value]="value"
    (ngModelChange)="signingTypeHasChanged()"
  ></base-radiobutton>
</ng-template>
