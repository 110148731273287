import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { BaseCoreState, basePspPriceListActions, basePspPriceListSelectors } from '../../../_store';

@Injectable()
export class BasePspPriceListGuard implements CanActivate {
  constructor(private store: Store<BaseCoreState>) {}

  canActivate(): Observable<boolean> {
    this.store.dispatch(basePspPriceListActions.loadQualifications({ payload: {} }));

    return this.store.select(basePspPriceListSelectors.getCategoryQualifications).pipe(
      filter((state) => state.ok !== undefined),
      map((state) => state.ok as boolean)
    );
  }
}
