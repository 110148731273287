<h6
  class="mb-5"
  *ngIf="!hideTitle"
>
  {{ 'generalInformation' | translate }}
</h6>
<form
  [formGroup]="form"
  [ngClass]="{ 'was-validated': submitted }"
>
  <div class="d-flex flex-column flex-lg-row gap-4 mb-0 mb-lg-7 me-7">
    <div class="col-12 col-md-8 col-lg-4">
      <base-input
        label="displayName"
        [isInvalid]="!!form.get('name')?.errors"
        [errorMessage]="form.get('name')?.errors ? 'validation.name' : ''"
        placeholder=""
        [isPreview]="!isEditMode"
        [isRequired]="true"
        formControlName="name"
      ></base-input>
    </div>
    <div class="col-12 col-md-8 col-lg-4">
      <base-input
        label="companyName"
        [isInvalid]="!!form.get('legalName')?.errors"
        [errorMessage]="form.get('legalName')?.errors ? 'validation.name' : ''"
        placeholder=""
        [isPreview]="!isEditMode"
        [isRequired]="true"
        formControlName="legalName"
      ></base-input>
    </div>
    <div class="col-4"></div>
  </div>
  <div class="d-flex flex-column flex-lg-row gap-4 mb-0 mb-lg-7 me-7">
    <div class="col-12 col-md-8 col-lg-4">
      <base-input
        label="idNum"
        [isInvalid]="!!form.get('vatId')?.errors"
        [errorMessage]="form.get('vatId')?.errors | baseFieldValidation"
        placeholder=""
        formControlName="vatId"
        [isPreview]="!isEditMode"
      ></base-input>
    </div>
    <div
      *ngIf="hasCollectiveAgreementField"
      class="col-12 col-md-8 col-lg-4"
    >
      <base-input
        label="consent"
        placeholder=""
        formControlName="collectiveAgreement"
        [isInvalid]="!!form.get('collectiveAgreement')?.errors"
        [errorMessage]="
          form.get('collectiveAgreement')?.errors?.required
            ? 'validation.collectiveAgreement'
            : (form.get('collectiveAgreement')?.errors | baseFieldValidation)
        "
        [isPreview]="!isEditMode"
        [isRequired]="true"
      ></base-input>
    </div>
    <div class="col-4"></div>
  </div>
  <div class="d-flex flex-column flex-lg-row gap-4 mb-0 mb-lg-7 me-7">
    <div class="col-12 col-md-8 col-lg-4">
      <base-input
        label="email"
        [isInvalid]="!!form.get('email')?.errors"
        [errorMessage]="form.get('email')?.errors | baseFieldValidation"
        placeholder=""
        formControlName="email"
        [isEmailField]="true"
        [isPreview]="!isEditMode"
      ></base-input>
    </div>
    <div class="col-12 col-md-8 col-lg-4">
      <base-input
        label="phone"
        [isInvalid]="!!form.get('phone')?.errors"
        [errorMessage]="form.get('phone')?.errors | baseFieldValidation"
        placeholder=""
        formControlName="phone"
        [isPreview]="!isEditMode"
      ></base-input>
    </div>
    <div class="col-12 col-md-8 col-lg-4">
      <base-input
        label="website"
        [isInvalid]="!!form.get('website')?.errors"
        [errorMessage]="form.get('website')?.errors | baseFieldValidation"
        placeholder=""
        formControlName="website"
        [isPreview]="!isEditMode"
      ></base-input>
    </div>
  </div>
</form>
