import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';

const DEFAULT_STICKY_CLASS: string = 'is-sticky';
const DEFAULT_STICKY_SCROLL_DISTANCE: number = 100;

@Directive({
  selector: '[baseIsSticky]',
})
export class BaseIsStickyDirective {
  @Input() stickyClass: string = DEFAULT_STICKY_CLASS;
  @Input() stickyScrollDistance: number = DEFAULT_STICKY_SCROLL_DISTANCE;
  @Output() stickyChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    if (window.scrollY > this.stickyScrollDistance) {
      this.elementRef.nativeElement.classList.add(this.stickyClass);
      this.stickyChange.emit(true);
    } else {
      this.elementRef.nativeElement.classList.remove(this.stickyClass);
      this.stickyChange.emit(false);
    }
  }

  constructor(private elementRef: ElementRef) {}
}
